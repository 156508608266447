import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    filled?: boolean
    height?: number
    width?: number
}

export const CatalogueItem17 = (props: Props) => {
    return (
        <Icon
            component={() => {
                if (props.filled) {
                    return (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1 4.5C1 3.39543 1.89543 2.5 3 2.5H9.5C9.79678 2.5 10.0782 2.63182 10.2682 2.85982L12.4684 5.5H21C22.1046 5.5 23 6.39542 23 7.5V19.5C23 20.6046 22.1046 21.5 21 21.5H3C1.89544 21.5 1 20.6046 1 19.5V4.5ZM3 10H21V12H3V10Z"
                            />
                        </svg>
                    )
                } else {
                    return (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1 4.5C1 3.39543 1.89543 2.5 3 2.5H9.5C9.79678 2.5 10.0782 2.63182 10.2682 2.85982L12.4684 5.5H21C22.1046 5.5 23 6.39542 23 7.5V19.5C23 20.6046 22.1046 21.5 21 21.5H3C1.89544 21.5 1 20.6046 1 19.5V4.5ZM9.03162 4.5H3V10H21V7.5H12C11.7032 7.5 11.4218 7.36818 11.2318 7.14018L9.03162 4.5ZM21 12H3V19.5H21V12Z"
                            />
                        </svg>
                    )
                }
            }}
            {...props}
        />
    )
}
