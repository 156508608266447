import Avatar from 'antd/es/avatar'
import { Link } from 'react-router-dom'
import { AvatarTuringo } from '@components/avatar/Avatar'
import dayjs from 'dayjs'
import { Colors } from '@util/color/Colors'
import { DegreeHat, Report, ViewList } from '@icon-park/react'
import { NotificationView, Notification, NotificationKind } from '@api/model/requests/Notification'
import { KindNameSingular } from '@util/string/ModelNames'
import { Icon } from '@icon-park/react/lib/runtime'
import strings from '@resources/localization'
import style from './Notification.scss'
import { InfoCircleOutlined } from '@ant-design/icons'
import useBreakpoints from '@hooks/useBreakpoints'
import { CatalogueIcon } from '@components/icons/CataloguesIcon'
import { ReactElement } from 'react'

interface NotificationProps {
    item: NotificationView
    compact?: boolean
    history: any
    dispatch: any
    language: any
    isPopup?: boolean
    onClose?: () => void
}

export const AvatarIcon = (props: {
    size: 'small' | 'normal'
    backgroundColor: string
    color: string
    iconPark?: ({ props }: any) => JSX.Element
    iconAnt?: ({ props }: any) => JSX.Element
    iconCustom?: JSX.Element
}) => {
    const { backgroundColor, color, iconPark, iconAnt, iconCustom, size } = props

    return (
        <Avatar
            style={{
                background: backgroundColor,
                height: size == 'normal' ? 40 : 32,
                width: size == 'normal' ? 40 : 32,
                minWidth: size == 'normal' ? 40 : 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: `${size == 'normal' ? 40 : 32}px`,
            }}
            icon={
                iconPark ? (
                    <props.iconPark theme="outline" size={size == 'normal' ? 23 : 18} fill={color} />
                ) : iconAnt ? (
                    <props.iconAnt style={{ fontSize: 18, color: color }} />
                ) : (
                    iconCustom
                )
            }
        ></Avatar>
    )
}

interface NotificationRuleSet {
    hasAvatar: boolean
    icon?: Icon | ReactElement
    iconKind?: 'ant' | 'iconpark' | 'custom'
    backgroundColor?: string
    color?: string
}
const NotificationRules: { [Kind in NotificationKind]: NotificationRuleSet } = {
    'usr.community.invited': {
        hasAvatar: true,
    },
    'usr.community.join': {
        hasAvatar: true,
    },
    'usr.community.post': {
        hasAvatar: true,
    },
    'usr.post.comment': {
        hasAvatar: true,
    },
    'usr.comment.reply': {
        hasAvatar: true,
    },
    'usr.comment.colateral': {
        hasAvatar: true,
    },
    'usr.post.like': {
        hasAvatar: true,
    },
    'usr.comment.like': {
        hasAvatar: true,
    },
    'usr.post.mention': {
        hasAvatar: true,
    },
    'admin.community.post': {
        hasAvatar: true,
    },
    'usr.comment.mention': {
        hasAvatar: true,
    },
    'usr.poll.vote': {
        hasAvatar: true,
    },
    'usr.classroom.complete': {
        hasAvatar: false,
    },
    'sys.poll.expired': {
        hasAvatar: false,
    },
    'sys.classroom.remind': {
        hasAvatar: false,
    },
    'sys.account.validation': {
        hasAvatar: false,
    },
    'mkt.classroom.launch': {
        hasAvatar: false,
    },
    'mkt.classroom.discount': {
        hasAvatar: false,
    },

    'usr.post.report': {
        hasAvatar: false,
        icon: Report,
        iconKind: 'iconpark',
        backgroundColor: Colors('VOLCANO_BACKGROUND'),
        color: Colors('VOLCANO_HIGHLIGHT'),
    },
    'com.team.added': {
        hasAvatar: false,
        icon: InfoCircleOutlined,
        iconKind: 'ant',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.community.invited': {
        hasAvatar: false,
        icon: InfoCircleOutlined,
        iconKind: 'ant',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.community.post': {
        hasAvatar: true,
    },
    'com.course.rate': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.course.quiz.approved': {
        hasAvatar: false,
        icon: ViewList,
        iconKind: 'iconpark',
        backgroundColor: 'var(--magenta-2)',
        color: 'var(--magenta-8)',
    },
    'com.course.quiz.approved.with.result.and.criteria': {
        hasAvatar: false,
        icon: ViewList,
        iconKind: 'iconpark',
        backgroundColor: 'var(--magenta-2)',
        color: 'var(--magenta-8)',
    },
    'com.course.quiz.approved.no.criteria': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.course.merit': {
        hasAvatar: false,
        icon: ViewList,
        iconKind: 'iconpark',
        backgroundColor: 'var(--magenta-2)',
        color: 'var(--magenta-8)',
    },
    'com.course.comment': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.course.remind': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('BLUE_BACKGROUND'),
        color: Colors('BLUE_HIGHLIGHT'),
    },
    'com.catalog.comment': {
        hasAvatar: false,
        icon: <CatalogueIcon height={16} width={16} color={'var(--ant-primary-color)'} style={{ fontSize: 16 }}></CatalogueIcon>,
        iconKind: 'custom',
        backgroundColor: 'var(--polar-green-2)',
        color: 'var(--polar-green-8)',
    },
    'com.admin.added': {
        hasAvatar: true,
    },
    'com.post.pinned.mandatory': {
        hasAvatar: true,
    },
    'com.post.pinned.normal': {
        hasAvatar: true,
    },
    'com.event.added': {
        hasAvatar: true,
    },
    'sys.convert.finished': {
        hasAvatar: false,
        icon: DegreeHat,
        iconKind: 'iconpark',
        backgroundColor: Colors('PURPLE_BACKGROUND'),
        color: Colors('PURPLE_HIGHLIGHT'),
    },
    'sys.poke': {
        hasAvatar: false,
    },
}

export const NotificationItem = (props: NotificationProps) => {
    const { item, compact, history, dispatch, language, onClose, isPopup } = props

    const { sender, predicate, state, dateFirstOcurred, dateLastOcurred, publicKey, count } = item
    const kind = item.kind as NotificationKind
    const senderAvatar = sender != 'protected' && sender != 'system' ? sender.avatarUrl : undefined
    const senderName = sender != 'protected' && sender != 'system' ? sender.name : 'SYSTEM'
    const senderPublicKey = sender != 'protected' && sender != 'system' ? sender.publicKey : 'SYSTEM'
    const breakpoints = useBreakpoints()

    const isMobile = breakpoints.isMobile
    const openNotification = () => {
        if (onClose) onClose()
    }

    const buildAvatar = (size: 'normal' | 'small') => {
        if (NotificationRules[kind]?.hasAvatar) {
            return (
                <AvatarTuringo
                    themeForced={'light'}
                    height={size == 'normal' ? 40 : 32}
                    width={size == 'normal' ? 40 : 32}
                    size={16}
                    avatar={senderAvatar}
                    name={senderName}
                    style={{ lineHeight: `${size == 'normal' ? 40 : 32}px` }}
                />
            )
        } else if (NotificationRules[kind]?.icon) {
            return (
                <AvatarIcon
                    size={size}
                    backgroundColor={NotificationRules[kind]?.backgroundColor}
                    color={NotificationRules[kind]?.color}
                    iconCustom={NotificationRules[kind]?.iconKind == 'custom' ? (NotificationRules[kind]?.icon as JSX.Element) : undefined}
                    iconPark={NotificationRules[kind]?.iconKind == 'iconpark' ? (NotificationRules[kind]?.icon as Icon) : undefined}
                    iconAnt={NotificationRules[kind]?.iconKind == 'ant' ? (NotificationRules[kind]?.icon as Icon) : undefined}
                />
            )
        } else {
            return <></>
        }
    }

    const getKey = (count: number) => {
        if (count == 1) return 'default'
        if (count == 2) return 'one'
        if (count > 2) return 'many'
    }

    const notificationStyle = {
        fontFamily: 'Roboto',
        fontDisplay: 'swap',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: isMobile ? 16 : 14,
        lineHeight: '22px',
        marginRight: isPopup ? 20 : 0,
    }

    const buildDescription = (username: string, name: string, kind: NotificationKind, count: number) => {
        switch (kind) {
            case 'usr.post.like':
                return (
                    <span style={notificationStyle}>
                        {language == 'es' && 'A '} <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)}
                    </span>
                )
            case 'usr.comment.like':
                return (
                    <span style={notificationStyle}>
                        {language == 'es' && 'A '} <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)}
                    </span>
                )
            case 'usr.post.comment':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)}
                    </span>
                )
            case 'usr.comment.reply':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)}
                    </span>
                )
            case 'usr.comment.mention':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)}
                    </span>
                )
            case 'usr.comment.colateral':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {'comentó en la publicación de'} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )
            case 'usr.post.mention':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)}
                    </span>
                )
            case 'usr.post.report':
                return <span style={notificationStyle}>{strings.notifications.kind[kind].default}</span>
            case 'usr.poll.vote':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)}
                    </span>
                )
            case 'usr.community.post':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)}
                    </span>
                )
            case 'admin.community.post':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name + ' '}</span>
                        {strings.formatString(strings.notifications.kind[kind][getKey(count)], count - 1)}
                        <span style={{ fontWeight: 500 }}>{' ' + (predicate as Notification.PostReference).name}</span>
                    </span>
                )
            case 'com.team.added':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{predicate.name}</span>
                    </span>
                )

            case 'com.community.invited':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )

            case 'com.community.post':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name}</span> {strings.notifications.kind[kind].default}{' '}
                        <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )
            case 'com.admin.added':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name}</span> {strings.notifications.kind[kind].default}
                    </span>
                )
            case 'com.course.rate':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )
            case 'com.course.quiz.approved':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )

            case 'com.course.quiz.approved.with.result.and.criteria':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )

            case 'com.course.quiz.approved.no.criteria':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )
            case 'com.course.merit':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )
            case 'com.course.comment':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )
            case 'com.course.remind':
                return (
                    <span style={notificationStyle}>
                        {strings.formatString(
                            strings.notifications.kind[kind].default,
                            <span style={{ fontWeight: 500 }}>{(predicate as Notification.BoardReference).name}</span>
                        )}
                    </span>
                )
            case 'com.catalog.comment':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                        {/* {' '}
                        {strings.notifications.kind[kind].default[1]}{' '}
                        <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).boardPk}</span> */}
                    </span>
                )
            case 'com.post.pinned.mandatory':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name}</span> {strings.notifications.kind[kind].default}
                    </span>
                )
            case 'com.post.pinned.normal':
                return (
                    <span style={notificationStyle}>
                        <span style={{ fontWeight: 500 }}>{name}</span> {strings.notifications.kind[kind].default}
                    </span>
                )
            case 'com.event.added':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name}</span>
                    </span>
                )
            case 'sys.convert.finished':
                return (
                    <span style={notificationStyle}>
                        {strings.notifications.kind[kind].default} <span style={{ fontWeight: 500 }}>{(predicate as Notification.PostReference).name} </span>
                        {strings.notifications.kind[kind].one}
                    </span>
                )

            default:
                return 'Under construction'
        }
    }

    const buildOnClick = () => {
        switch (kind) {
            case 'usr.post.like':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'usr.comment.like':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'usr.post.comment':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'com.post.pinned.mandatory':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'com.post.pinned.normal':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'admin.community.post':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'usr.poll.vote':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'usr.comment.reply':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'usr.comment.colateral':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'usr.post.mention':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'usr.comment.mention':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'usr.community.post':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'com.community.post':
                return `/${(predicate as Notification.PostReference).communityPk}/spaces/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'com.course.rate':
                return `/${(predicate as Notification.PostReference).communityPk}/courses/${(predicate as Notification.PostReference).boardPk}`
            case 'com.course.comment':
                return `/${(predicate as Notification.PostReference).communityPk}/courses/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'com.course.remind':
                return `/${(predicate as Notification.BoardReference).communityPk}/courses/${(predicate as Notification.BoardReference).boardPk}`
            case 'com.catalog.comment':
                return `/${(predicate as Notification.PostReference).communityPk}/catalogue/${(predicate as Notification.PostReference).boardPk}/${
                    (predicate as Notification.PostReference).publicKey
                }`
            case 'com.event.added':
                return `/${(predicate as Notification.EventReference).communityPk}/event/${(predicate as Notification.EventReference).eventPk}`

            case 'sys.convert.finished':
                return `/${(predicate as Notification.PostReference)?.communityPk}/settings/courses/${(predicate as Notification.PostReference)?.boardPk}/${
                    (predicate as Notification.PostReference)?.publicKey
                }`
            case 'com.course.quiz.approved':
                return `/cert/${(predicate as Notification.PostReference).publicKey}`
            case 'com.course.quiz.approved.with.result.and.criteria':
                return `/cert/${(predicate as Notification.PostReference).publicKey}`
            case 'com.course.quiz.approved.no.criteria':
                return `/${(predicate as Notification.PostReference).communityPk}/courses/${(predicate as Notification.PostReference).boardPk}`
            case 'com.course.merit':
                return `/cert/${(predicate as Notification.PostReference).publicKey}`
            case 'com.team.added':
                return `/${(predicate as Notification.PostReference).communityPk}`
            case 'com.community.invited':
                return `/${(predicate as Notification.PostReference).communityPk}`
            case 'com.admin.added':
                return `/${(predicate as Notification.PostReference).communityPk}`
            default:
                return ''
        }
    }
    if (compact) {
        return (
            <div onClick={buildOnClick} style={{ width: '100%', display: 'flex', alignItems: 'center', padding: 16 }}>
                {buildAvatar('small')}
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>{buildDescription(senderPublicKey, senderName, kind, count)}</div>
            </div>
        )
    } else {
        return (
            <Link to={buildOnClick} onClick={openNotification}>
                <div
                    className={style.notification}
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        padding: 16,
                        justifyContent: 'space-between',
                        border: `1px solid var(--border)`,
                        borderRadius: 4,
                        color: Colors('NEUTRAL_13'),
                        ...(state == 'new' && { backgroundColor: Colors('POSTED_POLL_BACKGROUND') }),
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {buildAvatar('normal')}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: 16,
                            }}
                        >
                            {buildDescription(senderPublicKey, senderName, kind, count)}
                            <span style={{ fontSize: isMobile ? 14 : 12, color: Colors('NEUTRAL_7') }}>
                                {' '}
                                {dayjs(dateLastOcurred).locale(language).fromNow()}
                            </span>
                        </div>
                    </div>
                    {state == 'new' && (
                        <div style={{ background: 'var(--ant-primary-color-hover)', borderRadius: 100, height: 8, width: 8, minWidth: 8, marginLeft: 16 }} />
                    )}
                </div>
            </Link>
        )
    }
}
