// Libraries

// React
import React, { ReactElement, useEffect } from 'react'

// Antd
import { Form } from 'antd'

// MARK: - Definition

namespace HelpeableWrapper {
    export interface Props {
        help: React.ReactNode;
        id?: string
        value?: any
        onChange?: (value: any) => void
        setHelp?: (value: any) => void
        children?: ReactElement<any, any>
        customOnChange?: (value: any, any?: any) => void
    }
}

// MARK: - Implementation

const HelpeableWrapper: React.FC<HelpeableWrapper.Props> = (props: HelpeableWrapper.Props) => {
    const { id, value = undefined, onChange, customOnChange, help, setHelp } = props

    const { status } = Form.Item.useStatus()

    const triggerChange = (e: React.ChangeEvent<HTMLInputElement>, any) => {

        if (e?.target?.value) {
            onChange?.(e.target.value)
            customOnChange?.(e.target.value, any)
        } else {
            onChange?.(e)
            customOnChange?.(e, any)

            
        }

    }

    useEffect(() => {
        if (status == 'error') {
            setHelp?.(undefined)
        } else {
            setHelp?.(help)
        }
    }, [status])


    return (
        <span id={id}>{React.cloneElement(props.children, { onChange: triggerChange, value: value, className: status != 'error' && 'no-margin-error' })}</span>
    )
}

export { HelpeableWrapper }
