// Libraries

// React
import React, { useContext, useState } from 'react'

// Antd
import { Alert, Button, Card, Row } from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { PostContext } from '@components/content/posts/Post'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'
import { ViewList } from '@icon-park/react'
import strings from '@resources/localization'
import Paragraph from 'antd/lib/typography/Paragraph'
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody'
import { get, set } from 'lodash'
import { useHistory, useLocation } from 'react-router'
import { KindNamePlural } from '@util/string/ModelNames'
import { API } from '@api/API'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { FooterModal } from '@components/modals/components/FooterModal'
import { CourseProgress } from '@components/content/certificate_supplementary/CourseProgress'
import { PostSequenceView } from '@api/model/responses/post/PostView'
import { PostQuizView } from '@api/model/responses/post/PostSubtypesViews'
import { QuizAnswerForm } from './QuizAnswerForm'
import { postList } from '@state/actions'
import { CertificateModal } from '@components/content/certificate_supplementary/CertificateModal'
import dayjs from 'dayjs'

// MARK: - Definition

namespace QuizResultsScreen {
    export interface Props {}
}

// MARK: - Implementation

const QuizResultsScreen: React.FC<QuizResultsScreen.Props> = (props: QuizResultsScreen.Props) => {
    const { cm_pk, b_pk, p_pk } = useContext(PostContext)

    const course = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk])
    const lesson = useAppSelector((state) => state.posts.detail[b_pk]?.items[p_pk])
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)
    const [showCertificate, setShowCertificate] = useState(false)

    const history = useHistory()
    const [score, setScore] = useState(lesson?.item?.quiz?.results?.score)

    const [isApproved, setIsApproved] = useState(score >= lesson?.item?.quiz?.scoring?.passing)
    const [haveAttempts, setHaveAttempts] = useState(
        lesson.item.quiz?.results?.repetition === undefined || lesson.item.quiz?.options?.repetitions >= lesson.item.quiz?.results?.repetition
    )
    const lessons = useAppSelector((state) => state.posts.all[b_pk])
    const [attempt, setAttempt] = useState(lesson.item.quiz?.results?.repetition)

    const attemptsLeft = lesson.item.quiz?.options?.repetitions + 1 - attempt

    const dontAllowRepetitions = lesson.item.quiz?.options?.repetitions == 0
    const showResults = lesson.item.quiz?.options?.showResults
    const showCorrectAnswers = lesson.item.quiz?.options?.showCorrectAnswers
    const isEvaluation = lesson.item.quiz?.scoring.passing != undefined && lesson.item.quiz?.scoring.passing != 0
    const dispatch = useAppDispatch()
    const [showProgress, setShowProgress] = useState<PostSequenceView>()
    const hasCertificate = lesson.item.board?.merits?.find((m) => m.merit.kind == 'certificate') && !!course?.item?.course?.assign?.cert
    const [quiz, setQuiz] = useState<{ data: PostQuizView; loading: boolean }>({ data: undefined, loading: false })
    const isBetweenLessons = quiz?.data?.lessonsLeft !== undefined ? quiz?.data?.lessonsLeft : lesson.item.quiz?.lessonsLeft
    const [quizComplete, setQuizComplete] = useState(false)
    const isReleased = lesson.item.quiz?.options?.dateFrom ? new Date(lesson.item.quiz?.options?.dateFrom) <= new Date() : true
    const isSequential = lesson.item.quiz?.options?.sequential
    const retrieveQuiz = () => {
        setQuiz({ data: undefined, loading: true })
        void API.quizGet({
            urlParams: {
                cm_pk,
                b_pk,
                p_pk,
            },
        }).then((result) => {
            setAttempt(0)
            if ('id' in result.data) {
                setQuiz({ data: result.data, loading: false })
            } else {
                setQuiz({ data: undefined, loading: false })

                setShowProgress(result.data)
            }
        })
    }

    type ButtonAction = 'repeat' | 'review' | 'finish' | 'continue' | 'certificate'

    const getActionText = (action: ButtonAction) => {
        switch (action) {
            case 'review':
                return strings.screens.admin.quizzes.review
            case 'repeat':
                return strings.screens.admin.quizzes.repeat
            case 'finish':
                return strings.screens.admin.quizzes.finish
            case 'continue':
                return strings.general.continue
            case 'certificate':
                return strings.screens.admin.quizzes.certificate
            default:
                return strings.general.continue
        }
    }

    const getAction = (action: ButtonAction) => {
        if (action == 'review') {
            // review lessons
            const firstLesson = Object.values(lessons?.items)?.[0]

            history.push(`/${cm_pk}/${KindNamePlural[course.item.kind]}/${course.item.publicKey}/${firstLesson.item.publicKey}`)
        }
        if (action == 'repeat') {
            // repeat quiz
            setQuizComplete(false)
            retrieveQuiz()
            window.scrollTo({ top: 0, behavior: 'smooth' })
        } else if (action == 'finish') {
            // go to course
            history.push(`/${cm_pk}/${KindNamePlural[course.item.kind]}/${course.item.publicKey}`)
        } else if (action == 'continue') {
            // go to next lesson
            let quizFound = false
            let quizPk = null
            const lessonsValues = lessons.items ? Object.values(lessons.items)?.sort((a, b) => a.item.contentSequence - b.item.contentSequence) : []
            for (const lesson of lessonsValues) {
                if (lesson.item.publicKey === p_pk) {
                    quizFound = true
                } else if (quizFound) {
                    quizPk = lesson.item.publicKey
                }
            }

            history.push(`/${cm_pk}/${KindNamePlural[course.item.kind]}/${course.item.publicKey}/${quizPk}`)
        } else if (action == 'certificate') {
            // go to certificate
            setShowCertificate(true)
        }
    }

    function getText(): { title: string; description: string; subdescription?: string; action?: ButtonAction[] } {
        const isComplete = isApproved || !isEvaluation || !showResults

        if (isComplete) {
            if (isBetweenLessons) {
                if (hasCertificate) {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.complete.title, currentEntity.name) as string,
                        description: strings.formatString(
                            strings.screens.evaluation.quiz.results.complete.betweenLesson.certificate,
                            course?.item?.name
                        ) as string,
                        action: ['continue'],
                    }
                } else {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.complete.title, currentEntity.name) as string,
                        description: strings.formatString(
                            strings.screens.evaluation.quiz.results.complete.betweenLesson.noCertificate,
                            course?.item?.name
                        ) as string,
                        action: ['continue'],
                    }
                }
            } else {
                if (hasCertificate) {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.complete.title, currentEntity.name) as string,
                        description: strings.formatString(strings.screens.evaluation.quiz.results.complete.finish.certificate, course?.item?.name) as string,
                        subdescription: strings.screens.evaluation.quiz.results.lookupCertificates,
                        action: ['certificate'],
                    }
                } else {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.complete.title, currentEntity.name) as string,
                        description: strings.formatString(strings.screens.evaluation.quiz.results.complete.finish.noCertificate, course?.item?.name) as string,
                        action: ['finish'],
                    }
                }
            }
        } else if (isApproved && showResults) {
            if (showCorrectAnswers) {
                if (isBetweenLessons) {
                    if (hasCertificate) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.approve.showCorrectAnswers.betweenLesson.certificate,
                                course?.item?.name,
                                score?.toString()
                            ) as string,
                            action: ['continue'],
                        }
                    } else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.approve.showCorrectAnswers.betweenLesson.noCertificate,
                                course?.item?.name,
                                score?.toString()
                            ) as string,
                            action: ['continue'],
                        }
                    }
                } else {
                    if (hasCertificate) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.approve.showCorrectAnswers.finish.certificate,
                                course?.item?.name,
                                score?.toString()
                            ) as string,
                            action: ['certificate'],
                        }
                    } else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.approve.showCorrectAnswers.finish.noCertificate,
                                course?.item?.name,
                                score?.toString()
                            ) as string,
                            action: ['finish'],
                        }
                    }
                }
            } else {
                if (isBetweenLessons) {
                    if (hasCertificate) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.approve.noShowCorrectAnswers.betweenLesson.certificate,
                                course?.item?.name
                            ) as string,
                            action: ['continue'],
                        }
                    } else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.approve.noShowCorrectAnswers.betweenLesson.noCertificate,
                                course?.item?.name
                            ) as string,
                            action: ['continue'],
                        }
                    }
                } else {
                    if (hasCertificate) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.approve.noShowCorrectAnswers.finish.certificate,
                                course?.item?.name
                            ) as string,
                            action: ['certificate'],
                        }
                    } else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.approve.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.approve.noShowCorrectAnswers.finish.noCertificate,
                                course?.item?.name
                            ) as string,
                            action: ['finish'],
                        }
                    }
                }
            }
        } else {
            if (showCorrectAnswers) {
                if (isBetweenLessons) {
                    if (isSequential) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.failure.showCorrectAnswers.betweenLesson.sequential,
                                score?.toString(),
                                course?.item?.name
                            ) as string,
                            subdescription: dontAllowRepetitions
                                ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                                : haveAttempts
                                ? isEvaluation
                                    ? (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.evaluation, attemptsLeft) as string)
                                    : (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft) as string)
                                : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                            action: dontAllowRepetitions || !haveAttempts ? ['review'] : isEvaluation && isSequential ? ['repeat'] : ['repeat', 'continue'],
                        }
                    } else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.failure.showCorrectAnswers.betweenLesson.noSequential,
                                score?.toString(),
                                course?.item?.name
                            ) as string,
                            subdescription: dontAllowRepetitions
                                ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                                : haveAttempts
                                ? isEvaluation
                                    ? (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft) as string)
                                    : (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft) as string)
                                : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                            action: dontAllowRepetitions || !haveAttempts ? ['continue'] : ['repeat', 'continue'],
                        }
                    }
                } else {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name) as string,
                        description: strings.formatString(
                            strings.screens.evaluation.quiz.results.failure.showCorrectAnswers.finish,
                            score?.toString(),
                            course?.item?.name
                        ) as string,
                        subdescription: dontAllowRepetitions
                            ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                            : haveAttempts
                            ? isEvaluation
                                ? (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.evaluation, attemptsLeft) as string)
                                : (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft) as string)
                            : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                        action: dontAllowRepetitions || !haveAttempts ? ['review'] : ['repeat'],
                    }
                }
            } else {
                if (isBetweenLessons) {
                    if (isSequential) {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.failure.noShowCorrectAnswers.betweenLesson.sequential,
                                course?.item?.name
                            ) as string,
                            subdescription: dontAllowRepetitions
                                ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                                : haveAttempts
                                ? isEvaluation
                                    ? (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.evaluation, attemptsLeft) as string)
                                    : (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft) as string)
                                : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                            action: dontAllowRepetitions || !haveAttempts ? ['review'] : isEvaluation && isSequential ? ['repeat'] : ['repeat', 'continue'],
                        }
                    } else {
                        return {
                            title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name) as string,
                            description: strings.formatString(
                                strings.screens.evaluation.quiz.results.failure.noShowCorrectAnswers.betweenLesson.noSequential,
                                course?.item?.name
                            ) as string,
                            subdescription: dontAllowRepetitions
                                ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                                : haveAttempts
                                ? isEvaluation
                                    ? (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft) as string)
                                    : (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft) as string)
                                : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                            action: dontAllowRepetitions || !haveAttempts ? ['continue'] : ['repeat', 'continue'],
                        }
                    }
                } else {
                    return {
                        title: strings.formatString(strings.screens.evaluation.quiz.results.failure.title, currentEntity.name) as string,
                        description: strings.formatString(
                            strings.screens.evaluation.quiz.results.failure.noShowCorrectAnswers.finish,
                            course?.item?.name
                        ) as string,
                        subdescription: dontAllowRepetitions
                            ? strings.screens.evaluation.quiz.results.failure.retry.noretries
                            : haveAttempts
                            ? isEvaluation
                                ? (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.evaluation, attemptsLeft) as string)
                                : (strings.formatString(strings.screens.evaluation.quiz.results.failure.retry.noEvaluation, attemptsLeft) as string)
                            : strings.screens.evaluation.quiz.results.failure.retry.maxRetriesReached,
                        action: dontAllowRepetitions || !haveAttempts ? ['review'] : ['repeat'],
                    }
                }
            }
        }
    }

    const [answers, setAnswers] = useState<{ data: { [questionId: string]: string }; loading: boolean }>({ data: undefined, loading: false })
    const language = useAppSelector((state) => state.ui.language)
    const onSubmit = (values: { [questionId: string]: string }) => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setAnswers({ data: values, loading: false })
    }

    const sendAnswers = () => {
        setAnswers((prev) => {
            return { ...prev, loading: true }
        })
        void API.answerCreate({
            urlParams: { cm_pk, b_pk, q_pk: p_pk },
            bodyParams: { answers: answers.data },
        }).then((result) => {
            setAnswers((prev) => {
                return { data: undefined, loading: false }
            })
            setScore(result.data.results?.score)
            setQuizComplete(true)
            setIsApproved(result.data.results?.score >= result.data?.scoring?.passing)
            setAttempt(result.data.results?.repetition)
            setHaveAttempts(result.data.options?.repetitions >= result.data.results?.repetition)
            setQuiz({ data: result.data, loading: false })
            void dispatch(postList({ urlParams: { cm_pk, b_pk: b_pk }, searchParams: { kind: 'meta_playlist' } }))
        })
    }

    return (
        <div>
            {/* Course progress Modal */}

            <CertificateModal
                download={true}
                open={showCertificate}
                hide={() => {
                    setShowCertificate(false)
                }}
            ></CertificateModal>

            {/* Confirmation Modal */}
            <TuringoModal
                title={strings.screens.evaluation.quiz.sendModal.title}
                open={!!answers.data}
                onCancel={() => {
                    setAnswers({ data: undefined, loading: false })
                }}
                width={572}
                footer={
                    <FooterModal
                        key={'footer_modal'}
                        primary={{
                            loading: answers.loading,
                            action: sendAnswers,
                            customText: strings.screens.evaluation.quiz.actions.send.title,
                        }}
                        secondary={{
                            action: () => {
                                setAnswers({ data: undefined, loading: false })
                            },
                        }}
                    />
                }
            >
                <Paragraph style={{ marginTop: 8, marginBottom: 16, fontWeight: 400 }}>
                    {showResults
                        ? strings.screens.evaluation.quiz.sendModal.descriptionwithResults
                        : strings.screens.evaluation.quiz.sendModal.descriptionwithoutResults}
                </Paragraph>
            </TuringoModal>

            {showProgress && (
                <TuringoModal
                    open={!!showProgress}
                    onCancel={() => setShowProgress(undefined)}
                    width={572}
                    footer={[
                        <FooterModal
                            key={'footer_modal'}
                            primary={{
                                action: () => setShowProgress(undefined),
                                customText: 'OK',
                            }}
                        />,
                    ]}
                >
                    <CourseProgress
                        isEvaluation
                        progress={showProgress.sequence}
                        title={strings.screens.boards.course.certificate.progress.title}
                        description={strings.screens.boards.course.certificate.progress.description}
                    />
                </TuringoModal>
            )}

            {(!attempt || attempt <= 0) && !quiz.data && (
                <>
                    <div
                        style={{
                            background: 'var(--card-secondary-background)',
                            height: 198,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {!isReleased && (
                                <Alert
                                    style={{ marginBottom: 16 }}
                                    showIcon
                                    message={`${strings.general.availableOn} ${dayjs(lesson.item.quiz?.options?.dateFrom)
                                        .locale(language)
                                        .format(strings.general.dateTimeFormat)}`}
                                />
                            )}
                            {!(lesson.item.quiz?.options?.repetitions <= lesson.item.quiz?.results?.repetition) && !isApproved && (
                                <TuringoButton disabled={!isReleased} onClick={retrieveQuiz} icon={<ViewList />} loading={quiz.loading}>
                                    {strings.screens.evaluation.action}
                                </TuringoButton>
                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            padding: '16px 24px',
                        }}
                    >
                        <div>{strings.screens.evaluation.title}</div>
                        <Paragraph className="turingo-title" style={{ wordWrap: 'break-word' }}>
                            {lesson?.item?.quiz?.title || lesson?.item?.title}
                        </Paragraph>
                    </div>
                </>
            )}

            {attempt > 0 && (
                <Card style={{ margin: '0px 24px 16px 24px' }}>
                    <InformationModalBody
                        forceLeftTextAlign
                        kind={!showResults || isApproved || !isEvaluation ? 'success' : 'error'}
                        title={getText().title}
                        description={getText().description}
                        subdescription={getText().subdescription}
                    />
                    <Row justify={'end'}>
                        {getText().action.map((action, i) => {
                            return (
                                <TuringoButton
                                    key={i}
                                    loading={quiz.loading}
                                    onClick={() => getAction(action)}
                                    style={{ marginRight: getText().action.length - 1 !== i ? 8 : 0 }}
                                    type={getText().action.length - 1 === i ? 'primary' : 'default'}
                                >
                                    {getActionText(action)}
                                </TuringoButton>
                            )
                        })}
                    </Row>
                </Card>
            )}

            {quiz.data && <QuizAnswerForm quiz={quiz.data} quizComplete={quizComplete} onSubmit={onSubmit} />}

            {quizComplete && showResults && (
                <Row className="lateral-padding" justify={'end'} style={{ marginBottom: 16 }}>
                    {getText().action.map((action, i) => {
                        return (
                            <TuringoButton key={i} loading={quiz.loading} onClick={() => getAction(action)} type="primary">
                                {getActionText(action)}
                            </TuringoButton>
                        )
                    })}
                </Row>
            )}
        </div>
    )
}

export { QuizResultsScreen }
