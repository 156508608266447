// Libraries

// React
import React, { useEffect, useState } from 'react'

// Antd
import { Skeleton, Tabs } from 'antd'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { useHistory, useParams } from 'react-router'
import { PostRouteParams } from '@typings/routing/Router'
import { AvatarTuringo } from '@components/avatar/Avatar'
import { PostReactionView } from '@api/model/responses/post/PostInteractionView'
import { Router } from '@Router'
import { PostView } from '@api/model/responses/post/PostView'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TuringoList } from '@components/list/TuringoList'
import Paragraph from 'antd/lib/typography/Paragraph'
import useBreakpoints from '@hooks/useBreakpoints'
import { ReactionKind } from '@api/model/requests/Post'
import { useAppDispatch } from '@hooks/useStore'
import strings from '@resources/localization'
import { postReactionList } from '@state/actions'
import { getMoniker } from './PostAuthor'
const { TabPane } = Tabs

// MARK: - Definition

namespace ReactionModal {
    export interface Props {
        kind: ReactionKind
        target: PostView
        open: boolean
        onCancel: () => void
    }
}

// MARK: - Implementation

const ReactionModal: React.FC<ReactionModal.Props> = (props: ReactionModal.Props) => {
    const { cm_pk, b_pk: br_pk, p_pk } = useParams<PostRouteParams>()
    const isFeed = !br_pk

    const { target, open, onCancel } = props
    const isMobile = useBreakpoints().isMobile

    const [reactionState, setReactionState] = useState<{ data: PostReactionView[]; loading: boolean; pagination: string }>({
        data: [],
        loading: true,
        pagination: 'first',
    })
    const [kind, setKind] = useState(props.kind)



    const history = useHistory()
    const dispatch = useAppDispatch()
    const afterAction = (result: { data: PostReactionView[]; pagination: string }, page: string) => {
        if (page == 'first') {
            setReactionState({ data: result.data, loading: false, pagination: result.pagination })
        } else {
            setReactionState({ data: [...reactionState.data, ...result.data], loading: false, pagination: result.pagination })
        }
    }
    const fetchReactions = (page: string) => {
        setReactionState({ ...reactionState, loading: true })

        void dispatch(
            postReactionList({
                urlParams: {
                    cm_pk,
                    b_pk: target.boardPk,
                    target_pk: target.publicKey,
                },
                extraParams: {
                    feed: isFeed,
                },
                headerParams: {
                    page: page == 'first' ? null : page,
                },
                searchParams: {
                    kind: kind,
                },
                options: {
                    then: {
                        action: (result: { data: PostReactionView[]; pagination: string }) => afterAction(result, page),
                    },
                },
            })
        )

        /*         dispatch(updateDerivated(kind, reactions.total))
         */
    }

    useEffect(() => {
        void fetchReactions('first')
    }, [kind])

    const listComponent = (
        <InfiniteScroll
            scrollableTarget={'current-modal'}
            className={'scrollStyle'}
            height={isMobile ? 340 : 320}
            dataLength={reactionState.loading && reactionState.pagination == 'first' ? 0 : reactionState.data.length}
            next={() => {
                void fetchReactions(reactionState.pagination)
            }}
            hasMore={reactionState.pagination && reactionState.pagination != 'first'}
            loader={
                <div key={'skeleton'} style={{ padding: isMobile ? '0 16px' : '0 24px', marginTop: 24, display: 'grid', gridAutoFlow: 'row', gap: 24 }}>
                    <Skeleton active title paragraph={null} />
                    <Skeleton active title paragraph={null} />
                    <Skeleton active title paragraph={null} />
                </div>
            }
        >
            <TuringoList
                emptyText=" "
                loading={reactionState.loading}
                showDivider={false}
                showArrow={false}
                itemRender={(item) => (
                    <div key={item.publicKey} style={{ display: 'flex', alignItems: 'center' }}>
                        <AvatarTuringo
                            size={24}
                            style={{
                                height: 48,
                                width: 48,
                                lineHeight: '48px',
                                marginRight: 8,
                            }}
                            name={item.name}
                            avatar={item.avatarUrl}
                        />

                        <div style={{ lineHeight: '16px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div className="turingo-text" style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                {item.name}
                            </div>

                            {getMoniker(item)}
                        </div>
                    </div>
                )}
                items={reactionState.data.map((item) => {
                    return { item: item.author, publicKey: item.author.publicKey }
                })}
                onClick={(value: string) => history.push(Router.Path.user_details({ u_pk: value, cm_pk }))}
            />
        </InfiniteScroll>
    )

    const getTitle = () => {
        if (props.kind == 'react') {
            return `${strings.general.like} (${target.totalReactions})`
        }
        if (props.kind == 'vote') {
            return strings.general.votes.title
        } else {
            return strings.screens.markasread.details.title
        }
    }

    return (
        <TuringoModal width={500} open={open} onCancel={onCancel} footer={null} closable title={null} bodyStyle={{ padding: '0px 0px 16px 0px' }}>
            <div className="turingo-card-header-padding">
                <Paragraph className="turingo-title" strong>
                    {getTitle()}
                </Paragraph>
            </div>

            {(kind == 'react' || kind == 'vote') && <div style={{ marginTop: 16 }}>{listComponent}</div>}
            {(kind == 'pin_read' || kind == 'pin_view') && (
                <Tabs className="turingo-card-tabs" onChange={(key) => setKind(key as ReactionKind)}>
                    <TabPane
                        tab={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {strings.screens.markasread.details.viewers.title} <div className={'tag'}>{target.totalViews}</div>
                            </div>
                        }
                        key={'pin_view'}
                    >
                        {listComponent}
                    </TabPane>
                    <TabPane
                        tab={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {strings.screens.markasread.details.readers.title} <div className={'tag'}>{target.totalReads}</div>
                            </div>
                        }
                        key={'pin_read'}
                    >
                        {listComponent}
                    </TabPane>
                </Tabs>
            )}
        </TuringoModal>
    )
}

export { ReactionModal }
