import { FC, useEffect, useState } from 'react'
import { Alert, Button, Affix } from 'antd'
import { authRevalidate, authValidateStatus } from '@state/actions'
import { useRequestState } from '@api/requests/AsyncRequests'
import strings from '@resources/localization'
import { validationBanner } from '@state/reducers/UserInterface/UserInterfaceReducer'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'

export const Banner = (props: { offsetTop?: number }) => {
    const { offsetTop = 48 } = props
    const [onlineStatus, setOnlineStatus] = useState<boolean>(true)

    useEffect(() => {
        window.addEventListener('offline', () => {
            setOnlineStatus(false)
        })
        window.addEventListener('online', () => {
            setOnlineStatus(true)
        })

        return () => {
            window.removeEventListener('offline', () => {
                setOnlineStatus(false)
            })
            window.removeEventListener('online', () => {
                setOnlineStatus(true)
            })
        }
    }, [])

    const isLogged = useAppSelector((state) => !!state.auth.authToken)
    const validation = useAppSelector((state) => state.auth.authValidation)

    const hideBanner =
        location.pathname.startsWith('/auth') ||
        location.pathname?.split('/')[2] == 'onboarding' ||
        !isLogged ||
        location.pathname.startsWith('/billing') ||
        location.pathname.startsWith('/blocked') ||
        (location.pathname.startsWith('/help') && validation == 'blocked')

    
    const isValidated = useAppSelector((state) => state.auth.authValidation == 'valid')
    const [isSended, setIsSended] = useState(false)
    const [resendStatus, setResendStatus] = useRequestState(false, false)

    useEffect(() => {
        dispatch(validationBanner(!isValidated && !hideBanner))
    }, [hideBanner, isValidated])

    const dispatch = useAppDispatch()

    const handleok = () => {
        setIsSended(true)
    }

    const handleUpdate = () => {
        window.location.reload()
    }

    const handleClose = () => {
        dispatch(validationBanner(false))
    }

    const resendEmail = (value) => {
        if (value.authValidation != 'valid') {
            void dispatch(
                authRevalidate({
                    options: {
                        redux: {
                            stateHandler: setResendStatus,
                        },
                        then: { action: handleok },
                        alerts: {
                            error: 'Ha sucedido un error al reenviar el email de validación',
                        },
                    },
                })
            )
        }
    }

    const getValidationStatus = () => {
        void dispatch(
            authValidateStatus({
                options: {
                    then: { action: resendEmail },
                },
            })
        )
    }
    if (isValidated && onlineStatus) {
        return <></>
    }

    return (
        <Affix offsetTop={offsetTop} style={{ zIndex: 8 }}>
            {!isValidated && !hideBanner && !isSended && onlineStatus && (
                <div style={{ backgroundColor: 'var(--yellow)' }}>
                    <Alert
                        showIcon
                        banner
                        onClose={handleClose}
                        style={{ maxWidth: 1290, marginLeft: 'auto', marginRight: 'auto', display: 'flex' }}
                        message={
                            <>
                                <span style={{ marginRight: 4 }}>
                                    {strings.screens.landing.bannerValidate.title} {strings.screens.landing.bannerValidate.description}{' '}
                                </span>

                                <Button
                                    className={'underline'}
                                    type="text"
                                    size="small"
                                    style={{ fontWeight: 500, padding: 0, height: 'unset' }}
                                    onClick={getValidationStatus}
                                    loading={resendStatus.editing}
                                >
                                    {strings.screens.landing.bannerValidate.action}
                                </Button>
                            </>
                        }
                        type="warning"
                        closable
                    />
                </div>
            )}

            {!isValidated && !hideBanner && isSended && onlineStatus && (
                <div style={{ backgroundColor: 'var(--green)' }}>
                    <Alert
                        style={{ maxWidth: 1290, marginLeft: 'auto', marginRight: 'auto' }}
                        showIcon
                        banner
                        message={strings.screens.landing.bannerValidate.sended}
                        type="success"
                        closable
                    />
                </div>
            )}

            {!onlineStatus && (
                <div style={{ backgroundColor: 'var(--red)' }}>
                    <Alert
                        showIcon
                        banner
                        style={{ maxWidth: 1290, marginLeft: 'auto', marginRight: 'auto' }}
                        message={
                            <>
                                <span style={{ marginRight: 4 }}>{'Conexión a internet débil. Te recomendamos revisar tu conexión.'}</span>

                                <Button className={'underline'} type="text" size="small" style={{ fontWeight: 'bold', padding: 0 }} onClick={handleUpdate}>
                                    {'Actualizar Página'}
                                </Button>
                            </>
                        }
                        type="error"
                    />
                </div>
            )}
        </Affix>
    )
}
