import { PostCommentKind, PostCreationModel } from '@api/model/requests/Post'
import { PostCommentView } from '@api/model/responses/post/PostCommentView'
import { PostAuthor } from '@components/content/posts/components/PostAuthor'
import { EntityModel } from '@model/index'
import strings from '@resources/localization'
import { commentEdit } from '@state/actions'
import { RootReducer } from '@state/reducers/Root'
import { PostRouteParams } from '@typings/routing/Router'
import { Colors } from '@util/color/Colors'
import { encodeTimeSeconds } from '@util/formatters/Time'
import { Button, Form, Input, Row } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { CommentReactAction } from '../../components/post_actions/CommentReactAction'
import { CommentMenu } from './components/CommentMenu'
import Linkify from 'react-linkify'
import { Content } from '../../components/Content'
import { TuringoEditor } from '@components/form/Editor/TuringoEditor'
import style from './style.scss'
import useBreakpoints from '@hooks/useBreakpoints'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'

interface Props {
    target: PostCommentView
    parents: string[]
    b_pk: string
    level: number
    replyFieldAction: (target: { name: string; publicKey: string }) => void
    showBorderReply?: boolean
}

export const CommentContent = (props: Props) => {
    const { target, parents, level, replyFieldAction, showBorderReply, b_pk } = props
    const isLogged = useAppSelector((state) => !!state.auth.authToken)
    const dispatch = useAppDispatch()

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const { cm_pk, b_pk: br_pk, p_pk } = useParams<PostRouteParams>()
    const isFeed = !br_pk
    const scope = p_pk ? 'detail' : 'all'

    const [isEditing, setIsEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mentions, setMentions] = useState<Array<PostCreationModel.Mention>>([])
    const childRef = useRef<{ resetEditor: () => void; setValue: (value: string, mentions: Array<PostCreationModel.Mention>) => void }>()

    /*     useEffect(() => {
        if (childRef && childRef.current) childRef.current.setValue(target.content, target.mentions)
    }, [isEditing]) */

    const location = useLocation()
    const inLesson = location.pathname.includes('courses') || location.pathname.includes('stories') || location.pathname.includes('forums')
    const [buttonState, setButtonState] = useState<boolean>(true)

    const onChange = (e) => {
        if (e && e.trim() != '') {
            setButtonState(true)
        } else {
            setButtonState(false)
        }
    }

    const handleEdit = (values: { content: string }) => {
        setLoading(true)

        void dispatch(
            commentEdit({
                urlParams: { cm_pk, b_pk, p_pk: parents[level == 2 ? 1 : 0], c_pk: target.publicKey },
                bodyParams: {
                    content: values.content.trim(),
                    mentions,
                },
                extraParams: {
                    parents: parents,
                    scope: scope,
                    feed: isFeed,
                },
                options: {
                    alerts: {
                        success: strings.alerts.comment.edit.success,
                        error: strings.alerts.comment.edit.failure,
                    },
                    then: {
                        action: () => {
                            setIsEditing(false)
                            setLoading(false)
                        },
                    },
                },
            })
        )
    }

    return (
        <div>
            <Row justify={'space-between'} style={{ flexFlow: 'nowrap' }}>
                {target.author && (
                    <PostAuthor
                        type={level > 1 ? 'reply' : 'comment'}
                        size={level > 1 ? 'small' : 'medium'}
                        author={target.author}
                        date={target.dateCreated}
                        postKey={target.publicKey}
                    />
                )}
                {isLogged && !isEditing && <CommentMenu b_pk={b_pk} parents={parents} onEdit={() => setIsEditing(true)} comment={target} />}
            </Row>

            <div
                style={{
                    alignItems: 'center',
                    paddingLeft: level <= 1 ? 31 : 24,
                    marginLeft: 24,
                    marginBottom: 0,
                    ...(((level == 1 && target.totalComments && target.totalComments > 0) || showBorderReply) && {
                        borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                    }),
                }}
            >
                {!isEditing && (
                    <Paragraph style={{ paddingTop: 8, whiteSpace: 'pre-wrap', marginBottom: 16 }}>
                        {inLesson &&
                            target.content.split(' ').map((word, index) => {
                                const regex = /^(?:(?:1[0-2]|0[0-9]):)?[0-5][0-9]:[0-5][0-9]$/
                                if (!regex.test(word.replace('\n', ''))) return `${word} `
                                else {
                                    const semicolonCount = word.split(':').length - 1
                                    let fullSeconds = 0

                                    if (semicolonCount == 1) {
                                        const [minutes, seconds] = word.split(':')

                                        fullSeconds = parseInt(minutes) * 60
                                        fullSeconds = fullSeconds + parseInt(seconds)
                                    } else {
                                        const [hours, minutes, seconds] = word.split(':')

                                        fullSeconds = parseInt(hours) * 3600
                                        fullSeconds = fullSeconds + parseInt(minutes) * 60
                                        fullSeconds = fullSeconds + parseInt(seconds)
                                    }

                                    return (
                                        <Link
                                            key={`${target.publicKey}_time_${index}`}
                                            //TOCHANGE
                                            to={`/${cm_pk}/courses/${b_pk}/${p_pk}#t=${encodeTimeSeconds(fullSeconds)}`}
                                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        >
                                            {word}
                                        </Link>
                                    )
                                }
                            })}
                        {!inLesson && <Content style={{ display: 'flex', flexDirection: 'column' }} text={target.content} mentions={target.mentions} />}
                    </Paragraph>
                )}
                {isEditing && (
                    <Form
                        layout="vertical"
                        initialValues={{
                            content: target.content,
                        }}
                        onFinish={handleEdit}
                    >
                        <Form.Item
                            name={['content']}
                            style={{
                                width: '100%',
                                marginBottom: 0,
                            }}
                        >
                            <TuringoEditor
                                ref={childRef}
                                onChange={onChange}
                                value={target.content}
                                initialMentions={target.mentions || []}
                                onMentionsChange={setMentions}
                            ></TuringoEditor>
                        </Form.Item>

                        <div
                            style={{
                                marginBottom: 20,
                                marginTop: 8,
                                display: isMobile ? 'grid' : 'flex',
                                justifyContent: 'end',
                                gridTemplateColumns: '1fr 1fr',
                                columnGap: 16,
                            }}
                        >
                            <Button block={isMobile} onClick={() => setIsEditing(false)}>
                                {strings.general.cancel}
                            </Button>
                            <Button block={isMobile} type="primary" htmlType="submit" loading={loading} disabled={!buttonState}>
                                {strings.general.save}
                            </Button>
                        </div>
                    </Form>
                )}
                <Row>
                    <CommentReactAction
                        b_pk={b_pk}
                        target_pk={target.publicKey}
                        reaction={target.reaction}
                        totalReactions={target.totalReactions}
                        parents={parents}
                    />
                    <div style={{ marginLeft: 8, marginRight: 8, lineHeight: '30px', color: 'var(--neutral-8)' }}>{'•'}</div>

                    <TuringoButton
                        onClick={() => replyFieldAction({ name: target.author.name, publicKey: target.author.publicKey })}
                        shape="standard"
                        size="small"
                        type="text"
                        icon={
                            <svg width="100%" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 15.1992L10.59 13.7492L12 12.289L14.17 10.0575H5C2.24 10.0575 0 7.75398 0 4.91574V0.802345H2V4.91574C2 6.61251 3.35 8.00078 5 8.00078H14.17L12 5.76927L10.59 4.3193L12 2.85904L18 9.02913L12 15.1992Z"
                                    fill={Colors('NEUTRAL_13')}
                                />
                            </svg>
                        }
                    >
                        {strings.general.replies.action}
                    </TuringoButton>
                </Row>
            </div>
        </div>
    )
}
