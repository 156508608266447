import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    filled?: boolean
    height?: number
    width?: number
}

export const CatalogueItem3 = (props: Props) => {
    return (
        <Icon
            component={() => {
                if (props.filled) {
                    return (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                                                        width={'100%'}

                            viewBox="0 0 24 24"
                            fill={'currentColor'}
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6 1C4.89541 1 4 1.89544 4 3V6H3C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H4V11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H4V16H3C2.44772 16 2 16.4477 2 17C2 17.5523 2.44772 18 3 18H4V21C4 22.1046 4.89542 23 6 23H20C21.1046 23 22 22.1046 22 21V3C22 1.89544 21.1046 1 20 1H6ZM16 3H18V21H16V3Z"
                            />
                        </svg>
                    )
                } else {
                    return (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                                                        width={'100%'}

                            viewBox="0 0 24 24"
                            fill={'currentColor'}
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4 3C4 1.89544 4.89541 1 6 1H20C21.1046 1 22 1.89544 22 3V21C22 22.1046 21.1046 23 20 23H6C4.89542 23 4 22.1046 4 21V18H3C2.44772 18 2 17.5523 2 17C2 16.4477 2.44772 16 3 16H4V13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H4V8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4V3ZM6 8H7C7.55228 8 8 7.55228 8 7C8 6.44772 7.55228 6 7 6H6V3H16V21H6V18H7C7.55228 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16H6V13H7C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11H6V8ZM18 21H20V3H18V21Z"
                            />
                        </svg>
                    )
                }
            }}
            {...props}
        />
    )
}
