import { TagKind } from '@components/form/Editor/TuringoEditor'

export function trim(s: string): string {
    const newValue = (s || '').replace(/\s+/g, ' ')

    return newValue.replace(/^\s+|\s+$/g, '')
}

export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
export const validateEmail = (email: string) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
        String(email).toLowerCase()
    )
}

export const getFirstURL = (input: string): string | undefined => {
    const scheme = '(?:https)'
    const hostname = '(\\:\\/\\/(?:www.|[a-zA-ZÀ-ž.]+)[a-zA-ZÀ-ž0-9\\-\\.]+\\.(?:[\\w]{1,}))?'
    const port = '(:[0-9][0-9]{0,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])?'
    const path = '([a-zA-ZÀ-ž0-9\\-_\\.\\/\\+]+)?'
    const query = '(?:\\?$|[^\\s"]*)?'
    const hash = '(?:#[^\\s"]*)?'

    // As named groups for when IE finally goes away:
    // const scheme = '(?<SCHEME>(?:https))';
    // const hostname = '(?<HOSTNAME>\\:\\/\\/(?:www.|[a-zA-ZÀ-ž.]+)[a-zA-ZÀ-ž0-9\\-\\.]+\\.(?:[\\w]{1,}))?';
    // const port = '(?<PORT>\:[0-9][0-9]{0,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])?'
    // const path = '(?<PATH>[a-zA-ZÀ-ž0-9\\-_\\.\\/\\+]+)?';
    // const query = '(?<QUERY>(?:\\?$|[^\\s"]*)?)?';
    // const hash = '(?<HASH>(?:#[^\\s"]*)?)?';

    const regex = new RegExp(`(${scheme}${hostname}${port}${path}${query}${hash})`, 'gim')
    const out = regex.exec(input)
    if (out) {
        return out[0]
    } else {
        return undefined
    }
}
export function formatMB(numMB: number): string {
    if (numMB < 1000) {
        return numMB.toString() + ' MB'
    } else {
        return (numMB / 1000).toFixed(2) + ' GB'
    }
}

export function formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

interface Replacement {
    kind: TagKind
    /** Text to insert */
    text?: string
    /** Offset from start for insertion */
    offset: number
    /** Length of characters to replace, if 0, it will be inserted without replacing anything at index */
    length?: number
    publicKey?: string
    id?: string
}

/**
 * Inserts text in multiple places preserving index stability
 *
 * @param text The original text to have new text injected into
 * @param replacements An array of inject operations, where all indices are based off the original text
 *
 * @returns the new text with replacements performed
 */
export function insert(text: string, replacements: Replacement[]): string {
    if (!replacements || replacements.length <= 0 || text.length <= 0) return text

    // Sort injections from left to right
    replacements.sort((a, b) => a.offset - b.offset)

    // Copied text and pivot
    let copy = ''
    let pivot = 0

    for (const replacement of replacements) {
        const afterText = text.substring(replacement.offset, replacement.offset + replacement.length)

        // Copy the in-between text
        copy += text.substring(pivot, replacement.offset)

        // Add the injection
        if (replacement.text) copy += `{${replacement.text}@${afterText}}`

        // Move the pivot to the offset
        pivot = replacement.offset + (replacement.length || 0)
    }

    // Copy the last part of the string
    if (pivot <= text.length) {
        copy += text.substring(pivot, undefined)
    }

    return copy
}

/**
 * Inserts text in multiple places preserving index stability
 *
 * @param text The original text to have new text injected into
 * @param replacements An array of inject operations, where all indices are based off the original text
 *
 * @param lastReplacement
 * @returns the new text with replacements performed
 */
export function insertMention(text: string, replacements: Replacement[], lastReplacement?: Replacement): string {
    if (!replacements || replacements.length <= 0 || text.length <= 0) return text

    // Sort injections from left to right
    replacements.sort((a, b) => a.offset - b.offset)

    // Copied text and pivot
    let copy = ''
    let pivot = 0

    for (const replacement of replacements) {
        // Copy the in-between text
        copy += text.substring(pivot, replacement.offset)
        // Add the injection

        if (replacement.kind == 'link' && replacement.text) copy += `<span id='${replacement.kind}' data-id='${replacement.id}'>${replacement.text}</span>`
        if (replacement.kind == 'mention' && replacement.text)
            copy += `<span id='${replacement.kind}' data-id='${replacement.id}'>${replacement.text}</span>${lastReplacement?.id == replacement.id ? ' ' : ''}`
        // Move the pivot to the offset
        pivot = replacement.offset + (replacement.length || 0)
    }

    // Copy the last part of the string
    if (pivot <= text.length) {
        copy += text.substring(pivot, undefined)
    }
    return copy
}

const intoPairs = (xs: any[]) => xs.slice(1).map((x: any, i: string | number) => [xs[i], x])
const breakAt = (places: any, str: string) => intoPairs([0, ...places, str.length]).map(([a, b]) => str.substring(a, b))
const breakWhere = (words: { offset: number; publicKey: string; length: number }[], str: string) =>
    breakAt(
        words.reduce((a: any, { offset, length }: any) => [...a, offset, offset + length], []),
        str
    )

export const createNodes = (links: { offset: number; publicKey: string; length: number }[], str: string) => {
    const sortedLinks = links.slice(0).sort(({ offset: o1 }, { offset: o2 }) => o1 - o2)

    return breakWhere(sortedLinks, str)
        .map((s: string, i: number) => (i % 2 == 0 ? { data: s, type: 'text' } : { data: s, type: 'mention', publicKey: sortedLinks[(i - 1) / 2].publicKey }))
        .filter(({ data }) => data.length > 0)
}

function reverseString(str: string): string {
    return str.split('').reverse().join('')
}

export function getCurrentLine(input: string, cursorPosition: number): number {
    return input.substring(0, cursorPosition).split('\n').length
}

export function getLastLink(input: string, cursorPosition: number, debug?: boolean): { link: string; range: number[] } {
    // recuperamos la posición actual del cursor
    if (cursorPosition === undefined || cursorPosition < 0) return { link: undefined, range: undefined }

    const regex = /((?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_\+.~#?&//=]*?)(?!.*[\r\n]*.*\1)(?!\n)(?!\s)$/gi
    const prefix = input.substring(0, cursorPosition)
    const linkRegex = regex.exec(prefix)
    if (linkRegex) {
        const link = linkRegex[0]
        const start = regex.lastIndex - linkRegex[0].length
        const end = regex.lastIndex - 1 + linkRegex[0].length
        return { link, range: [start, end] }
    }
    return { link: undefined, range: undefined }
}

export function getLastMention(
    input: string,
    lastMentionCursor: number,
    cursorPosition: number,
    debug?: boolean
): { word: string; range: number[]; valid: boolean } {
    // recuperamos la posición actual del cursor
    if (cursorPosition === undefined || cursorPosition < 0) return { word: undefined, range: undefined, valid: false }
    const regex = /^[a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ ]+[a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]@/
    const newRegex =
        /(\B@([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]{2,15}(\s?))([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]{1,15}(\s?)){1,2}\b)(?!.*[\r\n]*.*\1)(?!\n)/
    const prefix = input.substring(lastMentionCursor, cursorPosition)
    const mentions = newRegex.exec(prefix)
    if (mentions) {
        const word = mentions[0]
        const start = newRegex.lastIndex - mentions[0].length + prefix.length + lastMentionCursor
        const end = newRegex.lastIndex - 1 + mentions[0].length + lastMentionCursor
        return { word, range: [start, end], valid: end == cursorPosition - 1 && word.length > 3 }
    }
    return { word: undefined, range: undefined, valid: false }
}

export function getActiveToken(input: string, cursorPosition: number): { word: string; range: number[] } {
    // recuperamos la posición actual del cursor
    if (cursorPosition === undefined || cursorPosition < 0) return { word: undefined, range: undefined }
    // creamos un array temporal para guardar las palabras
    const words: { word: string; range: number[] }[] = []
    // recorremos el texto y lo separamos por espacios y saltos de línea
    input.split(/[\s\n]/).forEach((word, index) => {
        // recuperamos la palabra anterior
        const previous = words[index - 1]
        // calculamos el rango de la palabra
        // recuperamos el índice inicial de la palabra
        const start = index === 0 ? index : previous.range[1] + 1
        // recuperamos donde termina la palabra
        const end = start + word.length
        // guardamos la palabra y su rango en el texto
        words.push({ word, range: [start, end] })
    })

    // buscamos en qué palabra estamos dependiendo de la posición del cursor
    return words.find(({ range }) => range[0] <= cursorPosition && range[1] >= cursorPosition)
}

// "Hola @midudev"
// getActiveToken("Hola @midudev, cómo está @I", 27)
// { word: '@I', range: [ 25, 27 ] }

/* export const slugifier = (text?: string, publicKey?: string): string => {
    let result: string | undefined = undefined

    if (publicKey && PublicKeyRegex.exec(publicKey) !== null) {
        // Check the Public key first, and if it matches use it
        result = publicKey
    } else if (text && text.length > 0) {
        // If the pk was not good, then try the text
        // The slug function is always PK safe, we dont have to validate it
        result = slug(text, { lower: true })
    } else {
        // Nothing then? a random nanoid it is
        result = nanoid()
    }

    // If the final key clashes, add a random keyword at the end of it
    if (PublicKeyRestricted.includes(result)) {
        return result + '-' + nanoid(7)
    }

    return result
} */

export function dynamicTabName(input: string, notifications?: number): string {
    if (!notifications || notifications <= 0) return input

    return `(${notifications}) ` + input
}

export async function clientEncryptJWK(jwk: JsonWebKey, message: string): Promise<string> {
    // Public key import
    const publicKey = await window.crypto.subtle.importKey('jwk', jwk, { name: 'RSA-OAEP', hash: 'SHA-256' }, true, ['encrypt'])

    // Encryption
    const encrypted = await window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, publicKey, new TextEncoder().encode(message))

    const encryptedBase64 = window.btoa(ab2str(encrypted))
    return encryptedBase64.replace(/(.{64})/g, '$1\n')
}
function ab2str(buf: ArrayBuffer): string {
    return String.fromCharCode.apply(null, new Uint8Array(buf))
}



export function ensureHttp(link: string): string {
    // Expresión regular para verificar si el enlace ya tiene http:// o https://
    const httpRegex = /^(http:\/\/|https:\/\/)/i;

    // Si el enlace ya comienza con http:// o https://, lo devolvemos tal cual
    if (httpRegex.test(link)) {
        return link;
    }

    // Si el enlace no tiene http:// o https://, agregamos http://
    return `http://${link}`;
}
