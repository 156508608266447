import { Topic } from '@api/model/responses/post/PostView'
import useBreakpoints from '@hooks/useBreakpoints'
import { Check } from '@icon-park/react'
import strings from '@resources/localization'
import { Colors } from '@util/color/Colors'
import { Button, Col, Form, FormInstance, Input, message, Modal, Row } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import style from './FormSelectModalItem.scss'

type Mode = 'single' | 'multiple'
type Kind = 'list' | 'categorized'

interface Props {
    beforeAction?: () => void
    afterAction?: (publicKey?: any[]) => void
    mode?: Mode
    kind?: Kind
    items: any
    ref?: any
    isFullScreen?: boolean
    defaultValue?: any
    maxItems?: number
}

export const FormSelectModalItem = (props: Props) => {
    const { beforeAction, afterAction, items, mode = 'single', kind, isFullScreen, defaultValue, maxItems } = props

    const isMultiple = mode === 'multiple'
    const isCategorized = kind === 'categorized'
    const [selected, setSelected] = useState<any[]>(defaultValue ? defaultValue : [])
    const parentRef = useRef()
    useEffect(() => {
        setSelected(defaultValue ? defaultValue : [])
    }, [defaultValue])

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const onSelect = (item) => {
        if (isMultiple) {
            let newValue = []
            if (item) {
                if (isSelected(item)) newValue = selected.filter((itemFilter) => itemFilter != item)
                else {
                    if (selected.length >= maxItems) {
                        void message.destroy()
                        void message.error(`Solo puedes seleccionar hasta ${maxItems}`)
                        return
                    }
                    newValue = [...selected, item]
                }
                if (afterAction) afterAction(newValue)
            }
        } else {
            if (item) setSelected([item])
            if (afterAction) afterAction([item])
        }
    }

    const onDeselect = (item) => {
        if (isMultiple) {
            let newValue = []
            if (item) {
                if (isSelected(item)) newValue = selected.filter((itemFilter) => itemFilter != item)
                else {
                    if (selected.length >= 3) {
                        void message.destroy()
                        void message.error(`Solo puedes seleccionar hasta ${maxItems} servicios`)
                        return
                    }
                    newValue = [...selected, item]
                }
                if (afterAction) afterAction(newValue)
            }
        } else {
            if (item) setSelected([])
            if (afterAction) afterAction([])
        }
    }

    /*     if (isCategorized) {
        var cleanData = []
        for (const sector in items) {
            if (!(sector in cleanData)) cleanData.push(sector)
            for (const item of items[sector]) {
                if (!(item in cleanData)) cleanData.push(item)
            }
        }
    } */
    const isSelected = (item) => {
        if(!selected) return false
        if(selected.find((s) => s?.publicKey == item?.publicKey)) return true
        return false
    }
    return (
        <>
            <div className={'scrollStyle'} style={{ overflowY: 'auto', maxHeight: isMobile ? '60vh' : '293px' }}>
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {items?.map((item, index: number) => {
                        const itemValue = isCategorized ? items[index] : items[index]?.publicKey
                        const itemName = isCategorized ? items[index] : items[index]?.name

                        return (
                            <div
                                key={index}
                                onClick={() => isSelected(items[index]) ? onDeselect(items[index]) : onSelect(items[index])}
                                className={isSelected(items[index]) ? style.itemSelected : style.item}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: isMobile ? '10px 16px' : '4px 16px',
                                    width: 'fit-content',
                                    lineHeight: '20px',
                                    marginBottom: isMobile ? 24 : 16,
                                    paddingRight: isSelected(items[index]) ? (isMobile ? 40 : 34) : 16,
                                    position: 'relative',
                                }}
                            >
                                {isCategorized ? itemName : itemName}{' '}
                                {isSelected(items[index]) ? (
                                    <Check
                                        style={{
                                            marginLeft: 4,
                                            position: 'absolute',
                                            right: isMobile ? 14 : 13,
                                            top: isMobile ? 12 : 6,
                                        }}
                                        theme="outline"
                                        size={isMobile ? 16 : 14}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
