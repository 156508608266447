import React from 'react'
import { Typography } from 'antd'

import styles from './UnderlineButton.scss'
import { BaseType } from 'antd/lib/typography/Base'

interface Props {
    style?: React.CSSProperties
    children?: React.ReactNode
    onClick?: () => void
}
const { Text } = Typography

export const UnderlineButton = (props: Props) => {
    const { children, onClick, style } = props

    return (
        <span onClick={onClick} className={styles.text} style={style}>
            <p style={{marginBottom: 0, color: 'inherit'}}>{children}</p>
        </span>
    )
}
