// Libraries

// React
import React, { CSSProperties, useContext, useState } from 'react'

// Antd
import { Button } from 'antd'
import { AvatarTuringo } from '@components/avatar/Avatar'
import useBreakpoints from '@hooks/useBreakpoints'
import Paragraph from 'antd/lib/typography/Paragraph'
import strings from '@resources/localization'
import { CreatePostContext } from '../../../../PostCreateProvider'
import { DownOutlined } from '@ant-design/icons'
import { BoardRouteParams } from '@typings/routing/Router'
import { useParams } from 'react-router'
import { CrossPostingContext } from '../../CreationScreen'
import { Down } from '@icon-park/react'
import style from './Header.scss'
import { MaskSelectorModal } from '@components/content/masks/MaskSelectorModal'
import { useAppSelector } from '@hooks/useStore'
import { PostContext } from '@components/content/posts/Post'

// MARK: - Definition

namespace PostCreationHeader {
    export interface Props {
        style?: CSSProperties
    }
}

// MARK: - Implementation

const PostCreationHeader: React.FC<PostCreationHeader.Props> = (props: PostCreationHeader.Props) => {
    const breakpoints = useBreakpoints()

    const { consumer } = useContext(CrossPostingContext)
    const { mask } = useContext(CreatePostContext)

    const [showMasks, setShowMasks] = useState(false)

    const { boards, setScreen, form, editable } = useContext(CreatePostContext)
    const { cm_pk, b_pk } = useParams<BoardRouteParams>()

    const board_selected = form.getFieldValue(['form', 'turingo', 'board']) as string
    const board_item = boards.find((value) => value.publicKey == board_selected)

    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)
    const community = useAppSelector((state) => state.community.items[currentCommunity])
    const isAdmin = community?.item?.access?.includes('edit')

    return (
        <div style={{ display: 'flex', ...props.style }}>
            {showMasks && (
                <MaskSelectorModal
                    kind="post"
                    currentMask={mask.get}
                    setCurrentMask={mask.set}
                    open={showMasks}
                    onCancel={() => setShowMasks(false)}
                ></MaskSelectorModal>
            )}

            <div style={{ position: 'relative', marginRight: 8 }}>
                <AvatarTuringo
                    size={24}
                    style={{
                        height: 48,
                        width: 48,
                        lineHeight: '48px',
                        marginRight: 8,
                        marginBottom: 4,
                    }}
                    name={mask.get.name}
                    avatar={mask.get.avatarUrl}
                />

                {!editable && isAdmin && (
                    <div className={style.mask_button} onClick={() => setShowMasks(true)}>
                        <Down style={{ display: 'flex' }} theme="outline" size="16" strokeLinejoin="bevel" />
                    </div>
                )}
            </div>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Paragraph
                    strong
                    className={'turingo-text'}
                    style={{
                        lineHeight: '18px',
                        wordBreak: 'break-word',
                    }}
                    ellipsis={{ rows: 1 }}
                >
                    {mask.get.name}
                </Paragraph>

                {consumer == 'turingo' && boards.length > 1 && !editable && !b_pk && (
                    <Button
                        style={{
                            fontSize: breakpoints.isMobile ? 14 : 12,
                            height: 24,
                            lineHeight: '12px',
                            padding: '1px 8px 1px 0px',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            borderRadius: 4,
                            alignItems: 'center',
                        }}
                        onClick={() => setScreen('board_selection')}
                        icon={
                            <DownOutlined
                                style={{
                                    marginLeft: 4,
                                    fontSize: 10,
                                    display: 'flex',
                                }}
                            />
                        }
                    >
                        {board_item?.name || strings.screens.community.publicIn}
                    </Button>
                )}

                {consumer == 'turingo' && (boards.length == 1 || editable || b_pk) && (
                    <div
                        style={{
                            fontSize: 14,
                        }}
                    >
                        {`${strings.screens.community.publicIn} ${board_item?.name}`}
                    </div>
                )}
            </div>
        </div>
    )
}

export { PostCreationHeader }
