import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface RegistrationIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const RegistrationIcon = (props: RegistrationIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill={props.color ? props.color : 'var(--neutral-13)'}>
                    <path
                        d="M3.81818 10.5531C2.75758 10.5531 1.85606 10.1819 1.11364 9.43948C0.371212 8.69705 0 7.79554 0 6.73493C0 5.67432 0.371212 4.77281 1.11364 4.03038C1.85606 3.28796 2.75758 2.91675 3.81818 2.91675C4.64545 2.91675 5.3428 3.11561 5.91023 3.51334C6.47765 3.91107 6.89924 4.43872 7.175 5.09629H14V8.37357H12.3136V10.5531H9.35455V8.37357H7.175C6.89924 9.03114 6.47765 9.55879 5.91023 9.95652C5.3428 10.3542 4.64545 10.5531 3.81818 10.5531ZM3.81818 9.59857C4.57121 9.59857 5.18902 9.34932 5.67159 8.85084C6.15417 8.35235 6.43788 7.87508 6.52273 7.41902H10.3727V9.59857H11.3591V7.41902H13.0455V6.05084H6.52273C6.43788 5.59478 6.15417 5.11751 5.67159 4.61902C5.18902 4.12054 4.57121 3.87129 3.81818 3.87129C3.02273 3.87129 2.34659 4.1497 1.78977 4.70652C1.23295 5.26334 0.954545 5.93948 0.954545 6.73493C0.954545 7.53038 1.23295 8.20652 1.78977 8.76334C2.34659 9.32016 3.02273 9.59857 3.81818 9.59857ZM3.81818 7.81675C4.12576 7.81675 4.38295 7.71334 4.58977 7.50652C4.79659 7.2997 4.9 7.04251 4.9 6.73493C4.9 6.42735 4.79659 6.17016 4.58977 5.96334C4.38295 5.75652 4.12576 5.65311 3.81818 5.65311C3.51061 5.65311 3.25341 5.75652 3.04659 5.96334C2.83977 6.17016 2.73636 6.42735 2.73636 6.73493C2.73636 7.04251 2.83977 7.2997 3.04659 7.50652C3.25341 7.71334 3.51061 7.81675 3.81818 7.81675Z"
                    />
                </svg>
            )}
            {...props}
        />
    )
}
