// Libraries

// React
import React from 'react'

// Antd
import {} from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { markNotificationsAsSeen } from '@state/actions'
import { NotificationItem } from './NotificationItem'
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed'
import { Check } from '@icon-park/react'
import strings from '@resources/localization'
import { Link } from 'react-router-dom'
import { Router } from '@Router'

// MARK: - Definition

namespace NotificationPanel {
    export interface Props {
        onClose?: () => void
    }
}

// MARK: - Implementation

const NotificationPanel: React.FC<NotificationPanel.Props> = (props: NotificationPanel.Props) => {
    const { onClose } = props
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)
    const language = useAppSelector((state) => state.ui.language)

    const dispatch = useAppDispatch()
    const { general } = useAppSelector((state) => state.notification)
    const notificationCommunity = general.notifications.filter((n) => {
        if (n.sender == 'system') return n

        if (n.predicate?.communityPk == currentCommunity) {
            if (n.predicate?.kind == 'post' && n.predicate?.publicKey.startsWith('lesson')) {
                return
            } else {
                return n
            }
        }
    })

    const markAsSeen = () => {
        void dispatch(
            markNotificationsAsSeen({
                urlParams: {
                    notificationId: 'all',
                },
                bodyParams: {
                    communityPk: currentCommunity,
                },
            })
        )
        onClose()
    }

    return (
        <div>
            <div className={'scrollStyle'} style={{ width: 424, maxHeight: 500, overflow: 'auto', paddingBottom: 16 }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: 8, padding: 16 }}>
                    {notificationCommunity.map((notification, index) => {
                        return (
                            <NotificationItem onClose={markAsSeen} language={language} dispatch={dispatch} history={history} key={index} item={notification} />
                        )
                    })}

                    {notificationCommunity.length == 0 && (
                        <AlertDashed icon={<Check style={{ display: 'flex' }} />} message={strings.notifications.empty}></AlertDashed>
                    )}
                </div>
            </div>
            {notificationCommunity.length != 0 && (
                <div style={{ padding: '16px 0px' }}>
                    <Link style={{ margin: 16 }} to={Router.Path.notifications({})} onClick={onClose}>
                        {strings.notifications.showMore}
                    </Link>
                </div>
            )}
        </div>
    )
}

export { NotificationPanel }
