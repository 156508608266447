/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// Libraries

// React
import React, { ReactElement } from 'react'

// Antd
import { Button, Divider, List, Skeleton } from 'antd'
import ClassNames from '@util/style/ClassNames'
import { ArrowRightIcon } from '@components/icons/ArrowRightIcon'
import useBreakpoints from '@hooks/useBreakpoints'

// MARK: - Definition

type ItemKind = 'list' | 'button'

namespace TuringoList {
    export interface Props<DataType> {
        /** If the arrow should be shown. Can be `boolean` or a function that returns a `boolean` for a particualr item. */
        showArrow?: boolean | ((item: DataType) => boolean)
        itemKind?: ItemKind | ((item: DataType) => ItemKind)

        items: { item: DataType; icon?: any }[] | any[]
        onClick: (value: string, item?: DataType) => void
        onClickValueName?: string
        valueName?: string
        loading?: boolean
        exceptValues?: string[]
        itemRender?: (item: DataType) => ReactElement
        emptyText?: string
        showDivider?: boolean
    }
}

// MARK: - Implementation

export function TuringoList<T>(props: TuringoList.Props<T>) {
    const {
        items,
        onClick,
        onClickValueName = 'publicKey',
        valueName = 'name',
        loading = false,
        exceptValues = [],
        itemRender,
        emptyText,
        showArrow: showArrowOrFunction = true,
        itemKind: itemKindOrFunction = 'list',

        showDivider = true,
    } = props
    const isMobile = useBreakpoints().isMobile
    return (
        <>
            {(!items || loading) && (
                <div style={{ padding: isMobile ? '0 16px' : '0 24px', marginTop: 24, display: 'grid', gridAutoFlow: 'row', gap: 24 }}>
                    <Skeleton active title paragraph={null} />
                    <Skeleton active title paragraph={null} />
                    <Skeleton active title paragraph={null} />
                </div>
            )}
            {items && !loading && items.length == 0 && (
                <div className="turingo-text" style={{ padding: isMobile ? '0 16px' : '0 24px', color: 'var(--secondary-text)' }}>
                    {emptyText ? emptyText : 'Sin items'}{' '}
                </div>
            )}
            {items &&
                !loading &&
                items.map((i, index) => {
                    if (exceptValues.includes(i.item ? i.item[onClickValueName] : i[onClickValueName])) return

                    const showsArrow = typeof showArrowOrFunction === 'boolean' ? showArrowOrFunction : showArrowOrFunction(i.item)
                    const itemKind = typeof itemKindOrFunction === 'string' ? itemKindOrFunction : itemKindOrFunction(i.item)

                    return (
                        <>
                            {itemKind == 'list' && (
                                <List.Item
                                    key={i.item ? i.item[onClickValueName] : i[onClickValueName]}
                                    onClick={() => onClick(i.item ? i.item[onClickValueName] : i[onClickValueName], i.item)}
                                    className={ClassNames('turingo-list-item', 'hover-svg')}
                                    actions={
                                        showsArrow && [
                                            <span key="list-loadmore-edit" style={{ marginRight: 16 }}>
                                                <ArrowRightIcon />
                                            </span>,
                                        ]
                                    }
                                >
                                    <span
                                        key={i.item ? i.item[onClickValueName] : i[onClickValueName]}
                                        style={{ paddingLeft: isMobile ? 16 : 24, paddingRight: isMobile ? 16 : 24, maxWidth: 'calc(100% - 52px)', width: '100%' }}
                                        className={'turingo-list-item-text'}
                                    >
                                        {i.icon && i.icon}
                                        {!itemRender && (i.item ? i.item[valueName] : i[valueName])}
                                        {itemRender && (i.item ? itemRender(i.item) : itemRender(i))}
                                    </span>
                                </List.Item>
                            )}

                            {itemKind == 'button' && (
                                <Button
                                    type="primary"
                                    block
                                    disabled
                                    key={i.item ? i.item[onClickValueName] : i[onClickValueName]}
                                    style={{ marginLeft: isMobile ? 16 : 24, marginRight: isMobile ? 16 : 24, maxWidth: isMobile ? 'calc(100% - 32px)': 'calc(100% - 48px)'}}
                                >
                                        {i.icon && i.icon}
                                        {!itemRender && (i.item ? i.item[valueName] : i[valueName])}
                                        {itemRender && (i.item ? itemRender(i.item) : itemRender(i))}
                                </Button>
                            )}
                            {showDivider && items.length - 1 != index && (
                                <Divider
                                    style={{
                                        marginTop: 8,
                                        marginBottom: 8,
                                        marginLeft: 16,
                                        marginRight: 16,
                                        width: 'calc(100% - 32px)',
                                        minWidth: 'calc(100% - 32px)',
                                    }}
                                ></Divider>
                            )}
                        </>
                    )
                })}
        </>
    )
}
