// Libraries

// React
import React, { CSSProperties } from 'react'

// Antd
import { Button, message, Row, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { Property } from '@api/model/responses/community/CommunityView'
import { UserView } from '@api/model/responses/user/UserView'
import { ensureHttp, trim } from '@util/string/Functions'
import strings from '@resources/localization'
import { CheckOne, CloseOne, Copy, Stretching } from '@icon-park/react'
import { UserLocationView } from '@api/model/responses/user/UserLocationView'
import { clipboardWriteText } from '@util/browser/Copy'
import { TuringoTag } from '@components/content/tag/TuringoTag'
import dayjs from 'dayjs'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'
import { OutsideLinkIcon } from '@components/icons/OutsideLinkIcon'

// MARK: - Definition

namespace PropertyPreview {
    export interface Props {
        ownership?: 'self' | 'other'
        property: Property
        user: UserView
        style?: CSSProperties
    }
}

// MARK: - Implementation

const PropertyPreview: React.FC<PropertyPreview.Props> = (props: PropertyPreview.Props) => {
    const { property, user, ownership = 'self', style } = props
    const memberData = user.memberProperties
    const shareCopyText = (text: string, kind: 'email' | 'phone') => {
        clipboardWriteText(text)
        void message.destroy()
        void message.success({
            content: kind == 'email' ? strings.screens.profile.contactCard.email.message : strings.screens.profile.contactCard.phone.message,
        })
    }
    if (
        ownership == 'other' &&
        (!memberData?.[property.id]?.value || (property.type != 'geo:city:id' && trim(memberData?.[property.id].value as string) == ''))
    )
        return <></>

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...style }}>
            <div style={{ display: 'grid' }}>
                <Paragraph strong className="turingo-text" style={{ marginBottom: 4 }}>
                    {property.title}
                </Paragraph>

                {memberData?.[property.id] && (property.type == 'array:opt:multi' || property.type == 'array:opt:single') && (
                    <div>
                        {(memberData?.[property.id].value as string).length == 0 && (
                            <Paragraph style={{ color: 'var(--secondary-text)' }}>{strings.screens.profile.notAdded}</Paragraph>
                        )}
                        {(memberData?.[property.id].value as string).length > 0 && (
                            <Row gutter={[16, 16]} style={{ paddingLeft: 8 }}>
                                {(memberData?.[property.id].value as string).split(',').map((item) => (
                                    <TuringoTag kind={'tag'} color="neutral_2" size="small" style={{ marginRight: 8 }} key={`${property.id}-${item}`}>
                                        {item}
                                    </TuringoTag>
                                ))}
                            </Row>
                        )}
                    </div>
                )}

                {memberData?.[property.id] && property.type == 'string' && <Paragraph>{memberData?.[property.id].value}</Paragraph>}
                {memberData?.[property.id] && property.type == 'string:title' && <Paragraph>{memberData?.[property.id].value}</Paragraph>}

                {memberData?.[property.id] && property.type == 'integer' && <Paragraph>{memberData?.[property.id].value}</Paragraph>}
                {memberData?.[property.id] && property.type == 'boolean' && (
                    <Paragraph>
                        {memberData?.[property.id].value == 'true' ? <CheckOne theme="outline" size="16" /> : <CloseOne theme="outline" size="16" />}
                    </Paragraph>
                )}

                {memberData?.[property.id] && property.type == 'string:YYYY-MM-DD:maskyear' && (
                    <Paragraph>{dayjs(memberData?.[property.id].value as string).format('YYYY')}</Paragraph>
                )}

                {memberData?.[property.id] && property.type == 'string:YYYY-MM-DD' && (
                    <Paragraph>{dayjs(memberData?.[property.id].value as string).format('DD/MM/YYYY')}</Paragraph>
                )}

                {memberData?.[property.id] && property.type == 'string:email' && <Paragraph>{memberData?.[property.id].value}</Paragraph>}

                {memberData?.[property.id] && property.type == 'geo:city:id' && (
                    <Paragraph>
                        {(memberData?.[property.id].value as UserLocationView).name}, {(memberData?.[property.id].value as UserLocationView).countryName}
                    </Paragraph>
                )}
                {memberData?.[property.id] && property.type == 'string:url' && (
                    <Tooltip title={ensureHttp(memberData?.[property.id].value as string)}>
                        <Paragraph
                            style={{
                                overflow: 'hidden',
                            }}
                            ellipsis={{ rows: 1 }}
                        >
                            {ensureHttp(memberData?.[property.id].value as string)}
                        </Paragraph>
                    </Tooltip>
                )}

                {memberData?.[property.id] && (memberData?.[property.id].value as string).length > 0 && property.type == 'string:phone' && (
                    <Paragraph
                        style={{
                            overflow: 'hidden',
                        }}
                        ellipsis={{ rows: 1 }}
                    >
                        {memberData?.[property.id].value}
                    </Paragraph>
                )}
                {(!memberData?.[property.id] || (memberData?.[property.id].value as string).length == 0) && (
                    <Paragraph style={{ color: 'var(--secondary-text)' }}>{strings.screens.profile.notAdded}</Paragraph>
                )}
            </div>

            {memberData?.[property.id] &&
                (memberData?.[property.id].value as string).length > 0 &&
                (property.type == 'string:phone' || property.type == 'string:email') && (
                    <Tooltip title={strings.general.clipboard}>
                        <div>
                        <TuringoButton
                            icon={<Copy />}
                            style={{ display: 'flex', padding: 11, alignItems: 'center', marginLeft: 16 }}
                            onClick={() => shareCopyText(memberData?.[property.id].value as string, property.type == 'string:phone' ? 'phone' : 'email')}
                        ></TuringoButton>
                        </div>
                    </Tooltip>
                )}

            {memberData?.[property.id] && (memberData?.[property.id].value as string).length > 0 && property.type == 'string:url' && (
                <Tooltip title={strings.screens.profile.contactCard.website.tooltip}>
                     <div>
                    <TuringoButton
                        shape="square"
                        icon={<OutsideLinkIcon />}
                        onClick={() => window.open(ensureHttp(memberData?.[property.id].value as string), '_blank').focus()}
                        style={{ zIndex: 3, marginLeft: 16 }}
                    ></TuringoButton>
                    </div>
                </Tooltip>
            )}
        </div>
    )
}

export { PropertyPreview }
