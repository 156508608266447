import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

export const LockedIcon: React.FC<{ style: CSSProperties }> = (props: { style: CSSProperties }) => {
    return (
        <Icon
            component={() => (
                <svg
                    width={props.style?.width}
                    height={props.style?.height}
                    viewBox={`0 0 ${props.style?.width} ${props.style?.height}`}
                    fill={props.style?.color}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_2259_237671)">
                        <g clipPath="url(#clip1_2259_237671)">
                            <path d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM2.4 24C2.4 35.9293 12.0707 45.6 24 45.6C35.9293 45.6 45.6 35.9293 45.6 24C45.6 12.0707 35.9293 2.4 24 2.4C12.0707 2.4 2.4 12.0707 2.4 24Z" />
                            <path d="M24 1.2C24 0.537259 24.5377 -0.00312972 25.1996 0.0299893C29.5253 0.246427 33.7201 1.63021 37.3337 4.04473C41.2805 6.68188 44.3566 10.4302 46.1731 14.8156C47.9896 19.201 48.4649 24.0266 47.5388 28.6822C46.6128 33.3377 44.327 37.6141 40.9706 40.9706C37.6141 44.327 33.3377 46.6128 28.6822 47.5388C24.0266 48.4649 19.201 47.9896 14.8156 46.1731C10.4302 44.3566 6.68188 41.2805 4.04473 37.3337C1.63021 33.7201 0.246427 29.5253 0.0299893 25.1996C-0.00312972 24.5377 0.537259 24 1.2 24C1.86274 24 2.39652 24.5377 2.43332 25.1994C2.64744 29.0502 3.88949 32.7815 6.04026 36.0003C8.4137 39.5524 11.7872 42.3209 15.734 43.9558C19.6809 45.5906 24.024 46.0184 28.214 45.185C32.4039 44.3515 36.2527 42.2943 39.2735 39.2735C42.2943 36.2527 44.3515 32.4039 45.185 28.214C46.0184 24.024 45.5906 19.6809 43.9558 15.734C42.3209 11.7872 39.5524 8.4137 36.0003 6.04026C32.7815 3.88949 29.0502 2.64744 25.1994 2.43332C24.5377 2.39652 24 1.86274 24 1.2Z" />
                        </g>
                        <path d="M25 27C25 26.4477 24.5523 26 24 26C23.4477 26 23 26.4477 23 27V30C23 30.5523 23.4477 31 24 31C24.5523 31 25 30.5523 25 30V27Z" />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18 19V22H16C14.8954 22 14 22.8954 14 24V33C14 34.1046 14.8954 35 16 35H32C33.1046 35 34 34.1046 34 33V24C34 22.8954 33.1046 22 32 22H30V19C30 15.6863 27.3137 13 24 13C20.6863 13 18 15.6863 18 19ZM24 15C21.7909 15 20 16.7909 20 19V22H28V19C28 16.7909 26.2091 15 24 15ZM16 24H32V33H16V24Z"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_2259_237671">
                            <rect width="48" height="48" rx="24" fill="white" />
                        </clipPath>
                        <clipPath id="clip1_2259_237671">
                            <rect width="48" height="48" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )}
            {...props}
        />
    )
}
