import { PostRateView } from '@api/model/responses/post/PostRateView'
import { EntityModel } from '@model/index'
import strings from '@resources/localization'
import { postCreate, postEdit, rateCreate, rateDelete, rateEdit } from '@state/actions'
import { Form, FormInstance, Input, Rate } from 'antd'
import React, { ReactElement } from 'react'
import { useAppDispatch } from '@hooks/useStore'
import { ReviewModalType } from './ReviewModal'

interface RateForm {
    rate: number
    content: string
}

interface Props {
    form: FormInstance
    type: ReviewModalType
    onFinish: () => void
    cm_pk: string
    b_pk: string
    review?: PostRateView
}

export const ReviewForm = (props: Props): ReactElement => {
    const { cm_pk, form, onFinish, b_pk, review, type } = props
    const dispatch = useAppDispatch()

    const submit = (values: RateForm) => {
        const { rate, content } = values
        if (type == 'edit' && review) {
            dispatch(
                rateEdit({
                    urlParams: { cm_pk, b_pk, p_pk: review.publicKey },
                    bodyParams: {
                        kind: 'rate',
                        rate,
                        content,
                    },
                    extraParams: {
                        scope: 'all',
                    },
                    options: {
                        alerts: {
                            success: strings.alerts.rate.edit.success,
                            error: strings.alerts.rate.edit.failure,
                        },
                        then: {
                            action: onFinish,
                        },
                    },
                })
            )
        } else if (type == 'create') {
            dispatch(
                rateCreate({
                    urlParams: { cm_pk, b_pk },
                    bodyParams: {
                        kind: 'rate',
                        rate,
                        content,
                    },
                    extraParams: {},
                    options: {
                        alerts: {
                            success: strings.alerts.rate.create.success,
                            error: strings.alerts.rate.create.failure,
                        },
                        then: {
                            action: onFinish,
                        },
                    },
                })
            )
        } else if (type == 'delete' && review) {
            dispatch(
                rateDelete({
                    urlParams: { cm_pk, b_pk, target_pk: review.publicKey },
                    options: {
                        alerts: {
                            success: strings.alerts.rate.delete.success,
                            error: strings.alerts.rate.delete.failure,
                        },
                        then: {
                            action: onFinish,
                        },
                    },
                    extraParams: {
                        scope: 'all',
                        parents: [],
                    },
                })
            )
        }
    }

    return (
        <Form onFinish={submit} form={form} layout={'vertical'} initialValues={review ? { rate: review.rate, content: review.content } : undefined}>
            {type != 'delete' && (
                <>
                    <Form.Item
                        name={['rate']}
                        label={strings.rates.form.rate}
                        required
                        rules={[
                            {
                                validator: (rule: any, value: number) => {
                                    if (!value || value == 0) {
                                        return Promise.reject(strings.screens.boards.course.rates.form.error)
                                    } else {
                                        return Promise.resolve()
                                    }
                                },
                            },
                        ]}
                    >
                        <Rate style={{ color: 'var(--cyan-6)' }} />
                    </Form.Item>
                    <Form.Item name={['content']} label={strings.rates.form.content.label}>
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} showCount maxLength={500} />
                    </Form.Item>
                </>
            )}
        </Form>
    )
}
