import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { FooterModal } from '@components/modals/components/FooterModal'
import strings from '@resources/localization'
import { postDelete, postPin } from '@state/actions'
import { RootReducer } from '@state/reducers/Root'
import { PostRouteParams } from '@typings/routing/Router'
import React, { ReactElement, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { useParams } from 'react-router'
import { PostView } from '@api/model/responses/post/PostView'
import Paragraph from 'antd/lib/typography/Paragraph'
import { Switch } from 'antd'
import { Check, Close } from '@icon-park/react'

export type CommentModalType = 'delete' | 'edit'

interface Props {
    target: PostView
    open: boolean
    onCancel: () => void
}

export const PinModal = (props: Props): ReactElement => {
    const { cm_pk, b_pk, p_pk } = useParams<PostRouteParams>()

    const { open, onCancel, target } = props
    const dispatch = useAppDispatch()
    const community = useAppSelector((state) => state.community.items[cm_pk])
    const isWhatsAppActive = community?.item?.plugins?.whatsapp?.state == 'active'

    const [mandatory, setMandatory] = useState(true)
    const [sendEmail, setSendEmail] = useState(true)
    const [sendWhatsapp, setSendWhatsapp] = useState(isWhatsAppActive ? true : false)

    const scope = p_pk ? 'detail' : 'all'

    const isFeed = !b_pk

    const postState = useAppSelector((state) => state.posts[scope][isFeed ? 'feed' : b_pk]?.items[target.publicKey]?.state)
    const onPin = () => {
        void dispatch(
            postPin({
                urlParams: { cm_pk, b_pk: target.boardPk, p_pk: target.publicKey },
                bodyParams: {
                    mandatory: mandatory,
                    sendEmail: sendEmail,
                    sendWhatsapp: sendWhatsapp,
                },
                extraParams: {
                    scope: scope,
                    feed: isFeed,
                },
                options: {
                    alerts: {
                        success: 'La publicación ha sido destacada',
                        error: 'Error',
                    },
                    then: {
                        action: onCancel,
                    },
                },
            })
        )
    }
    return (
        <TuringoModal
            onCancel={onCancel}
            open={open}
            title={strings.screens.markasread.actions.highlight}
            width={572}
            footer={[
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        action: onPin,
                        customText: strings.screens.markasread.actions.highlight,
                        loading: postState?.loading,
                    }}
                    secondary={{
                        action: onCancel,
                        customText: strings.general.cancel,
                    }}
                />,
            ]}
        >
            <Paragraph style={{ marginBottom: 16 }}>{strings.screens.markasread.pin_modal.description}</Paragraph>

            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <p style={{ fontWeight: 500, marginBottom: 0 }}>{strings.screens.markasread.pin_modal.email.title}</p>
                    <Paragraph style={{ marginBottom: 16 }}>{strings.screens.markasread.pin_modal.email.description}</Paragraph>
                </span>
                <Switch
                    defaultChecked
                    onChange={(checked) => {
                        setSendEmail(checked)
                    }}
                    style={{ marginLeft: 16 }}
                    checkedChildren={<Check style={{ display: 'flex' }} />}
                    unCheckedChildren={<Close style={{ display: 'flex' }} />}
                />
            </div>

            {isWhatsAppActive && (
                <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>{strings.screens.markasread.pin_modal.whatsapp.title}</p>
                        <Paragraph style={{ marginBottom: 16 }}>{strings.screens.markasread.pin_modal.whatsapp.description}</Paragraph>
                    </span>
                    <Switch
                        defaultChecked
                        onChange={(checked) => {
                            setSendWhatsapp(checked)
                        }}
                        style={{ marginLeft: 16 }}
                        checkedChildren={<Check style={{ display: 'flex' }} />}
                        unCheckedChildren={<Close style={{ display: 'flex' }} />}
                    />
                </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <p style={{ fontWeight: 500, marginBottom: 0 }}>{strings.screens.markasread.pin_modal.read.title}</p>
                    <Paragraph style={{ marginBottom: 16 }}>{strings.screens.markasread.pin_modal.read.description}</Paragraph>
                </span>
                <Switch
                    defaultChecked
                    onChange={(checked) => {
                        setMandatory(checked)
                    }}
                    style={{ marginLeft: 16 }}
                    checkedChildren={<Check style={{ display: 'flex' }} />}
                    unCheckedChildren={<Close style={{ display: 'flex' }} />}
                />
            </div>
        </TuringoModal>
    )
}
