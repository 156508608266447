import { commentSection } from '@state/actions'
import { Divider, Spin } from 'antd'
import React, { ReactElement, useContext, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { UnderlineButton } from '@components/buttons/underline_button/UnderlineButton'
import { PostCommentField } from './components/PostCommentField'
import strings from '@resources/localization'
import { Comment } from './Comment'
import useBreakpoints from '@hooks/useBreakpoints'
import { PostContext } from '../../Post'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PostSkeleton } from '@components/skeletons/components/PostSkeleton/PostSkeleton'

interface Props {
    kind?: 'paginated' | 'scrolleable'
    childRef: React.MutableRefObject<{ getFocus: () => void }>
}

export const PostCommentSection = (props: Props): ReactElement => {
    const { childRef, kind = 'paginated' } = props

    const { mask, p_pk, b_pk, isFeed, scope, cm_pk } = useContext(PostContext)

    const post = useAppSelector((state) => state.posts[scope][isFeed ? 'feed' : b_pk]?.items[p_pk])
    const comments = useAppSelector((state) => state.posts[scope][isFeed ? 'feed' : b_pk]?.items[p_pk]?.childrens)
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)

    const dispatch = useAppDispatch()
    const breakpoints = useBreakpoints()

    useEffect(() => {
        fetchData(false)
    }, [b_pk, p_pk, mask.get])

    const fetchData = (pagination: boolean) => {
        if (!pagination) {
            void dispatch(
                commentSection({
                    urlParams: { cm_pk, b_pk, p_pk },
                    ...(currentEntity.publicKey != mask.get?.publicKey && { searchParams: { mask: mask.get?.publicKey } }),
                    headerParams: {
                        page: null,
                    },
                    extraParams: {
                        scope: scope,
                        feed: isFeed,
                    },
                })
            )
        } else {
            void dispatch(
                commentSection({
                    urlParams: { cm_pk, b_pk, p_pk },
                    ...(currentEntity.publicKey != mask.get?.publicKey && { searchParams: { mask: mask.get?.publicKey } }),
                    headerParams: {
                        page: comments?.listState.pagination,
                    },
                    extraParams: {
                        scope: scope,
                        feed: isFeed,
                    },
                })
            )
        }
    }
    return (
        <div>
            <div style={{ padding: breakpoints.isSmall ? '0px 16px' : '0px 24px' }}>
                <Divider style={{ margin: 0 }}></Divider>
            </div>

            <PostCommentField ref={childRef}></PostCommentField>

            {kind == 'paginated' && (
                <div>
                    {comments && comments.items && Object.keys(comments.items).map((key: string) => <Comment key={key} comment={comments.items[key].item} />)}

                    {comments?.listState.pagination && !comments?.listState.loading && post.item.totalComments > 5 && post.item.totalComments % 5 != 0 && (
                        <div style={{ padding: breakpoints.isSmall ? 16 : 24 }}>
                            <UnderlineButton onClick={() => fetchData(true)}>
                                {strings.formatString(strings.general.seeMore, strings.comments.title.toLowerCase())}
                            </UnderlineButton>
                        </div>
                    )}

                    {comments?.listState.loading && (
                        <div style={{ padding: breakpoints.isSmall ? 16 : 24, textAlign: 'center' }}>
                            <Spin></Spin>
                        </div>
                    )}
                </div>
            )}

            {comments && kind == 'scrolleable' && (
                <InfiniteScroll
                    dataLength={Object.keys(comments.items).length}
                    next={() => {
                        fetchData(true)
                    }}
                    hasMore={(comments?.listState.pagination && comments?.listState.pagination != 'first') || comments?.listState.loading}
                    loader={<PostSkeleton />}
                >
                    {comments && comments.items && Object.keys(comments.items).map((key: string) => <Comment key={key} comment={comments.items[key].item} />)}
                </InfiniteScroll>
            )}
        </div>
    )
}
