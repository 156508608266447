// Libraries

// React
import React, { useEffect, useState } from 'react'

// Antd
import { Alert, Divider, Row, Switch, Tooltip } from 'antd'
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useHistory } from 'react-router'
import { Router } from '@Router'
import useBreakpoints from '@hooks/useBreakpoints'
import { Check, Close } from '@icon-park/react'
import { communityMemberDetails, userEdit } from '@state/actions'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { hasError } from '@util/StateHandler'
import strings from '@resources/localization'
import { setSound } from '@state/reducers/UserInterface/UserInterfaceReducer'
import { API } from '@api/API'
import { InfoCircleOutlined } from '@ant-design/icons'

// MARK: - Implementation

const NotificationsScreen = () => {
    const history = useHistory()
    const breakpoints = useBreakpoints()

    const dispatch = useAppDispatch()
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)
    const currentUser = useAppSelector((state) => state.auth.currentEntity)

    useEffect(() => {
        void dispatch(communityMemberDetails({ urlParams: { cm_pk: currentCommunity, u_pk: currentUser.publicKey } }))
    }, [])
    const soundSetting = useAppSelector((state) => state.ui.sound)

    const isMobile = breakpoints.isMobile
    const [loadingChat, setLoadingChat] = useState(false)
    const [loadingDigest, setLoadingDigest] = useState(false)
    const [loadingEvents, setLoadingEvents] = useState(false)
    const [loadingWhatsapp, setLoadingWhatsapp] = useState(false)

    const changeSettings = (kind: 'notifyDigest' | 'notifyChat' | 'notifyEvent' | 'notifyMediumWhatsapp', checked: boolean) => {
        if (kind == 'notifyDigest') setLoadingDigest(true)
        if (kind == 'notifyChat') setLoadingChat(true)
        if (kind == 'notifyEvent') setLoadingEvents(true)
        if (kind == 'notifyMediumWhatsapp') setLoadingWhatsapp(true)

        void dispatch(
            userEdit({
                urlParams: {
                    cm_pk: currentCommunity,
                },
                bodyParams: {
                    settings: {
                        [kind]: checked,
                    }
                },
                extraParams: {
                    u_pk: currentUser.publicKey,
                },

                options: {
                    then: {
                        action: () => {
                            if (kind == 'notifyDigest') {
                                setLoadingDigest(false)

                                void API.analyticsAction({
                                    bodyParams: {
                                        action: checked ? 'notifications.digest.on' : 'notifications.digest.off',
                                        sender: 'settings',
                                    },
                                })
                            }
                            if (kind == 'notifyChat') {
                                setLoadingChat(false)

                                void API.analyticsAction({
                                    bodyParams: {
                                        action: checked ? 'notifications.chat.on' : 'notifications.chat.off',
                                        sender: 'settings',
                                    },
                                })
                            }
                            if (kind == 'notifyEvent') {
                                setLoadingEvents(false)

                                void API.analyticsAction({
                                    bodyParams: {
                                        action: checked ? 'notifications.events.on' : 'notifications.events.off',
                                        sender: 'settings',
                                    },
                                })
                            }
                            if (kind == 'notifyMediumWhatsapp') {
                                setLoadingWhatsapp(false)

                                void API.analyticsAction({
                                    bodyParams: {
                                        action: checked ? 'notifications.whatsapp.on' : 'notifications.whatsapp.off',
                                        sender: 'settings',
                                    },
                                })
                            }
                        },
                    },
                    alerts: {
                        /*                         success: strings.alerts.user.edit.success,
                         */ error: strings.alerts.user.edit.failure,
                    },
                },
            })
        )
    }

    if (!currentUser) {
        return <></>
    }
    else {
        return (
            <div className="lateral-padding" style={{ paddingBottom: 16 }}>
                <div style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
                    <GoBackButton
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}
                        type="default"
                        showText={false}
                        onClick={() => history.push(Router.Path.settings({}))}
                    />
                    <Paragraph strong className="turingo-text">
                        {strings.screens.settings.sections.notifications.title}
                    </Paragraph>
                </div>
                <Divider></Divider>

                <Paragraph strong className="turingo-text" style={{ marginBottom: 16 }}>
                    {strings.screens.settings.sections.notifications.activity.title}
                </Paragraph>
                <div style={{ padding: '0px 24px' }}>
                    {/*  <Row justify={'space-between'} style={{ marginBottom: 8 }}>
                        <span style={{ display: 'flex' }}>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>Notificaciones</p>
                        </span>
                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={true}
                            loading={false}
                
                        />
                    </Row>
                    <Row style={{ marginBottom: isMobile ? 32 : 0 }}>
                        <p>Recibe notificaciones de comentarios y menciones en tu comunidad.</p>
                    </Row>
                    {!isMobile && <Divider></Divider>} */}

                    <Row justify={'space-between'} style={{ marginBottom: 8 }}>
                        <span style={{ display: 'flex' }}>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>
                                {strings.screens.settings.sections.notifications.activity.sound.title}
                            </p>
                        </span>
                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={soundSetting}
                            loading={false}
                            onClick={(checked) => {
                                dispatch(setSound(checked))
                                void API.analyticsAction({
                                    bodyParams: {
                                        action: checked ? 'notifications.sound.on' : 'notifications.sound.off',
                                        sender: 'settings',
                                    },
                                })
                            }}
                        />
                    </Row>
                    <Row style={{ marginBottom: isMobile ? 32 : 0 }}>
                        <p>{strings.screens.settings.sections.notifications.activity.sound.description}</p>
                    </Row>
                </div>

                {!isMobile && <Divider></Divider>}

                <Paragraph strong className="turingo-text" style={{ marginBottom: 16 }}>
                    {strings.screens.settings.sections.notifications.email.title}
                </Paragraph>

                <Alert style={{ marginBottom: 16 }} showIcon message={strings.screens.settings.sections.notifications.email.alert} />

                <div style={{ padding: '0px 24px' }}>
                    <Row justify={'space-between'} style={{ marginBottom: 8 }}>
                        <span style={{ display: 'flex' }}>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>
                                {strings.screens.settings.sections.notifications.email.chat.title}
                            </p>
                        </span>
                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={currentUser?.settings?.ntf?.chat}
                            loading={loadingChat}
                            onClick={(checked) => {
                                changeSettings('notifyChat', checked)
                            }}
                        />
                    </Row>
                    <Row style={{ marginBottom: isMobile ? 32 : 0 }}>
                        <p>{strings.screens.settings.sections.notifications.email.chat.description}</p>
                    </Row>
                    {!isMobile && <Divider></Divider>}

                    <Row justify={'space-between'} style={{ marginBottom: 8 }}>
                        <span style={{ display: 'flex' }}>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>
                                {strings.screens.settings.sections.notifications.email.event.title}
                            </p>
                        </span>
                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={currentUser?.settings?.ntf?.event}
                            loading={loadingEvents}
                            onClick={(checked) => {
                                changeSettings('notifyEvent', checked)
                            }}
                        />
                    </Row>
                    <Row style={{ marginBottom: isMobile ? 32 : 0 }}>
                        <p>{strings.screens.settings.sections.notifications.email.event.description}</p>
                    </Row>

                    {!isMobile && <Divider></Divider>}

                    <Row justify={'space-between'} style={{ marginBottom: 8 }}>
                        <span style={{ display: 'flex' }}>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>
                                {strings.screens.settings.sections.notifications.email.digest.title}
                            </p>
                        </span>
                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={currentUser?.settings?.ntf?.digest}
                            loading={loadingDigest}
                            onClick={(checked) => {
                                changeSettings('notifyDigest', checked)
                            }}
                        />
                    </Row>
                    <Row style={{ marginBottom: isMobile ? 32 : 0 }}>
                        <p>{strings.screens.settings.sections.notifications.email.digest.description}</p>
                    </Row>
                </div>

                {!isMobile && <Divider></Divider>}

                <Paragraph strong className="turingo-text" style={{ marginBottom: 16 }}>
                    {strings.screens.settings.sections.notifications.direct_message.title}
                </Paragraph>
                <div style={{ padding: '0px 24px' }}>
                    {/*  <Row justify={'space-between'} style={{ marginBottom: 8 }}>
                        <span style={{ display: 'flex' }}>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>Notificaciones</p>
                        </span>
                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={true}
                            loading={false}
                
                        />
                    </Row>
                    <Row style={{ marginBottom: isMobile ? 32 : 0 }}>
                        <p>Recibe notificaciones de comentarios y menciones en tu comunidad.</p>
                    </Row>
                    {!isMobile && <Divider></Divider>} */}

                    <Row justify={'space-between'} style={{ marginBottom: 8 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>
                                {strings.screens.settings.sections.notifications.direct_message.whatsapp.title}
                            </p>

                            <Tooltip placement="right" title={strings.screens.settings.sections.notifications.direct_message.whatsapp.help}>
                                <InfoCircleOutlined
                                    onClick={(event) => event.stopPropagation()}
                                    style={{ fontSize: breakpoints.isMobile ? 18 : 16, marginLeft: 4 }}
                                />
                            </Tooltip>
                        </div>

                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={currentUser?.settings?.ntm?.whatsapp == undefined ? true : currentUser?.settings?.ntm?.whatsapp}
                            loading={loadingWhatsapp}
                            onClick={(checked) => {
                                changeSettings('notifyMediumWhatsapp', checked)
                            }}
                        />
                    </Row>
                    <Row style={{ marginBottom: isMobile ? 32 : 0 }}>
                        <p>{strings.screens.settings.sections.notifications.direct_message.whatsapp.description}</p>
                    </Row>
                </div>
            </div>
        )
    }
}

export { NotificationsScreen }
