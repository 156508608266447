// Libraries

// React
import React, { useContext } from 'react'

// Antd
import { useState } from 'react'
import { LinkButton } from '../link_button/LinkButton'
import { BuyButton } from '../buy_button/BuyButton'
import { useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import { KindNamePlural } from '@util/string/ModelNames'
import strings from '@resources/localization'
import { useHistory, useParams } from 'react-router'
import { Router } from '@Router'
import { ButtonSize } from 'antd/lib/button'
import { BoardView } from '@api/model/responses/board/BoardView'
import { BoardRouteParams, CommunitySettingsRouteParams } from '@typings/routing/Router'
import { API } from '@api/API'
import { BoardContext } from '@screens/community/boards/media/MediaDetail'

// MARK: - Definition

namespace WatchButton {
    export interface Props {
        entity: BoardView
        size?: ButtonSize
        showPrice?: boolean
        position?: BuyButton.Position
    }
}

// MARK: - Implementation

const WatchButton: React.FC<WatchButton.Props> = (props: WatchButton.Props) => {
    const { entity, size = 'middle', showPrice, position } = props
    const isSuscriptor = entity?.access?.posts?.includes('view')
    const isLogged = useAppSelector((state) => !!state.auth.authToken)

    const { cm_pk } = useParams<BoardRouteParams>()
    const { section } = useParams<CommunitySettingsRouteParams>()

    const boardContext = useContext(BoardContext)
    const [loading, setLoading] = useState(false)

    const progress = entity.progress

    const notStarted = !progress?.lessonCompletedCount || progress?.lessonCompletedCount == 0

    const history = useHistory()
    const watchText = () => {
        return notStarted ? strings.screens.boards[entity.kind].list.card.watchNow : strings.screens.boards[entity.kind].list.card.continue
    }

    const watchNow = (): string => {
        if (!progress?.lessonLastPublicKey) return ''
        if (boardContext.isOnboarding) {
            return Router.Path.community_settings_post_detail({
                cm_pk: cm_pk,
                section: 'onboarding',
                item_pk: entity.publicKey,
                p_pk: progress?.lessonLastPublicKey,
            })
        }

        return Router.Path[`${entity.kind}_post_details`]({ cm_pk: cm_pk, b_pk: entity.publicKey, p_pk: progress?.lessonLastPublicKey })
    }

    // Not logged in, can't watch, must log in
    if (!isLogged && !entity.product) {
        return (
            <LinkButton
                onClick={() => {
                    void API.analyticsAction({ bodyParams: { action: 'btn.signup', sender: boardContext?.b_pk ? 'course.detail.card' : 'course.card' } })
                }}
                block
                key={`cb#1`}
                size={size}
                type="primary"
                href={{ pathname: `/signup`, state: { b_pk: entity.publicKey, kind: KindNamePlural[entity.kind] } }}
            >
                {strings.screens.boards[entity.kind].list.card.watchNow}
            </LinkButton>
        )
    } else if (!isSuscriptor && entity.product) {
        return <BuyButton board={entity} size={size} showPrice={showPrice} position={position}></BuyButton>
    }

    // Is not subscribed, but its free
    else if (!isSuscriptor && !entity.product) {
        return (
            <LinkButton
                onClick={() => {
                    void API.analyticsAction({ bodyParams: { action: 'btn.play', sender: boardContext?.b_pk ? 'course.detail.card' : 'course.card' } })

                    void API.communityBoardStart({ urlParams: { cm_pk, b_pk: entity.publicKey } }).then((result) => {
                        if (boardContext?.isOnboarding) {
                            return history.push(Router.Path.community_settings_post_detail({
                                cm_pk: cm_pk,
                                section: 'onboarding',
                                item_pk: entity.publicKey,
                                p_pk: result.data.currentContentPk,
                            }))
                        } else {
                            history.push(
                                Router.Path[`${entity.kind}_post_details`]({ cm_pk: cm_pk, b_pk: entity.publicKey, p_pk: result.data.currentContentPk })
                            )
                        }
                        setLoading(false)
                    })
                }}
                width={'100%'}
                block
                key={`cb#1`}
                size={size}
                type="primary"
                href={watchNow()}
            >
                {strings.screens.boards[entity.kind].list.card.watchNow}
            </LinkButton>
        )
    }
    // It's subscribe, he can watch :D
    if (isSuscriptor) {
        return (
            <LinkButton
                onClick={() => {
                    void API.analyticsAction({ bodyParams: { action: 'btn.play', sender: boardContext?.b_pk ? 'course.detail.card' : 'course.card' } })
                    setLoading(true)
                    void API.communityBoardStart({ urlParams: { cm_pk, b_pk: entity.publicKey } }).then((result) => {

                        if (boardContext?.isOnboarding) {
                            return history.push(Router.Path.community_settings_post_detail({
                                cm_pk: cm_pk,
                                section: 'onboarding',
                                item_pk: entity.publicKey,
                                p_pk: result.data.currentContentPk,
                            }))
                        } else {
                            history.push(
                                Router.Path[`${entity.kind}_post_details`]({ cm_pk: cm_pk, b_pk: entity.publicKey, p_pk: result.data.currentContentPk })
                            )
                        }

                        setLoading(false)
                    })
                }}
                width={'100%'}
                block
                key={`cb#1`}
                type="primary"
                size={size}
                loading={loading}
                href={watchNow()}
            >
                {watchText()}
            </LinkButton>
        )
    }
}
export { WatchButton }
