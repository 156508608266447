import strings from '@resources/localization'
import { RootReducer } from '@state/reducers/Root'
import { isEntityLanding } from '@util/router/ReverseRouter'
import { Anchor } from 'antd'
import React, { useRef } from 'react'
import { useAppSelector } from '@hooks/useStore'

import { Colors } from '@util/color/Colors'
import AnchorLink from 'antd/lib/anchor/AnchorLink'
const { Link } = Anchor

export const EntityAnchor = () => {
    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme === 'dark'

    const isClassroomLanding = isEntityLanding('courses')

    const handleClick = (
        e: React.MouseEvent<HTMLElement>,
        link: {
            title: React.ReactNode
            href: string
        }
    ) => {
        e.preventDefault()
    }
    const certRef = useRef<any>()


    return (
        <Anchor
            onClick={handleClick}
            style={{ background: Colors('BACKGROUND'), boxShadow: isDark ? '0px 1px 0px #595959' : '0px 2px 8px #F0F1F2' }}
            offsetTop={64}
            targetOffset={180}
            /*             onChange={onChange}
             */
        >
            <Link href="#description" title={strings.general.description} />
            <Link href="#details" title={strings.general.detail} />
            <Link href="#mentors" title={isClassroomLanding ? strings.general.mentors : strings.general.participants} />
            <Link
                href="#lessons"
                title={isClassroomLanding ? strings.screens.boards.course.post.title.plural : strings.screens.boards.forum.post.title.plural}
            />
            {isClassroomLanding && <Link href="#audience" title={strings.general.audience} />}
            {isClassroomLanding && <Link href="#abilities" title={strings.general.abilities} />}
            {isClassroomLanding && <Link  href="#certificate" title={strings.general.certificate.title.singular} />}
            <Link href="#reviews" title={strings.rates.title} />
        </Anchor>
    )
}
