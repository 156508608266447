// Libraries

// React
import React, { ReactElement, useEffect, useState } from 'react'

// Antd
import { Form, FormItemProps } from 'antd'
import { HelpeableWrapper } from './HelpeableWrapper'

// MARK: - Definition

namespace FormHelpItem {
    export interface Props extends FormItemProps {
        children?: ReactElement<any, any>
        customOnChange?: (value: any, any?: any) => void
    }
}

// MARK: - Implementation

const FormHelpItem: React.FC<FormHelpItem.Props> = (props: FormHelpItem.Props) => {
    const { help: formHelp, customOnChange, ...rest } = props

  
    const [help, setHelp] = useState(formHelp)

    useEffect(() => {
        setHelp(formHelp)
    }, [formHelp])
    return (
        <Form.Item {...rest} help={help}>
            <HelpeableWrapper customOnChange={customOnChange} setHelp={setHelp} help={formHelp}>
                {props.children}
            </HelpeableWrapper>
        </Form.Item>
    )
}

export { FormHelpItem }
