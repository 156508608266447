// Libraries

// React
import React, { CSSProperties } from 'react'

// Antd
import { Button, Dropdown } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { MoreOne } from '@icon-park/react'
import useBreakpoints from '@hooks/useBreakpoints'
import { MoreOutlined } from '@ant-design/icons'
import { min } from 'lodash'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'

// MARK: - Definition

namespace MoreButton {
    export interface Props {
        items: ItemType[]
        type: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed'
        size?: 'small' | 'medium'
        style?: CSSProperties
    }
}

// MARK: - Implementation

const MoreButton: React.FC<MoreButton.Props> = (props: MoreButton.Props) => {
    const { items, type = 'default', size = 'medium', style } = props
    const isMobile = useBreakpoints().isMobile
    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <TuringoButton
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                shape="square"
                icon={<MoreOutlined />}
                type={type}
                size={size}
                style={{ zIndex: 3, ...style }}
            ></TuringoButton>
        </Dropdown>
    )
}

export { MoreButton }
