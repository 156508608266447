// Libraries

// React
import React, { CSSProperties, useContext, useEffect, useState } from 'react'

// Antd
import { Badge, Button, Dropdown, MenuProps } from 'antd'
import useBreakpoints from '@hooks/useBreakpoints'
import { AvatarTuringo } from '@components/avatar/Avatar'
import { UserView } from '@api/model/responses/user/UserView'
import { Colors } from '@util/color/Colors'
import Style from './styles/ChatListItem.scss'
import queryString from 'query-string'
import { useHistory, useLocation, useParams } from 'react-router'
import { Router } from '@Router'
import { CommunityRouteParams } from '@typings/routing/Router'
import { ChatRoomView } from '@api/model/responses/chat/ChatView'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import dayjs from 'dayjs'
import Paragraph from 'antd/lib/typography/Paragraph'
import { unset } from 'lodash'
import { Link } from 'react-router-dom'
import { MoreOutlined } from '@ant-design/icons'
import { markChatAsRead } from '@state/reducers/Notifications/NotificationReducer'
import { Check, CloseOne } from '@icon-park/react'
import { NotificationHistoryView } from '@api/model/requests/Notification'
import { SocketContext } from 'src/SocketComponent'
import { SilenceIcon } from '@components/icons/SilenceIcon'
import strings from '@resources/localization'
import { MoreButton } from '@components/buttons/more_button/MoreButton'

// MARK: - Definition

namespace ChatListItem {
    export interface Props {
        showOptions?: boolean
        item: ChatRoomView
        state?: number
        style?: CSSProperties
        onClose?: () => void
    }
}

// MARK: - Implementation

const ChatListItem: React.FC<ChatListItem.Props> = (props: ChatListItem.Props) => {
    const { cm_pk } = useParams<CommunityRouteParams>()
    const { item, state = 0, style = {}, onClose, showOptions } = props
    // RESPONSIVE
    const breakpoints = useBreakpoints()

    const isMobile = breakpoints.isMobile
    const isTablet = breakpoints.isTablet
    const isSmall = breakpoints.isSmall

    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)

    const location = useLocation<{ roomPk: string }>()
    const roomSelected = location.state?.roomPk
    const history = useHistory()
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)
    const language = useAppSelector((state) => state.ui.language)

    const onClick = () => {
        history.push(Router.Path.community_chat({ cm_pk: cm_pk || currentCommunity }), { roomPk: item.publicKey })
        if (onClose) onClose()
    }
    const dispatch = useAppDispatch()

    const participants = item?.users?.filter((u) => {
        return u.publicKey != currentEntity.publicKey
    })

    const hasNotificationSilence = !!item?.users?.find((u) => {
        if (u.publicKey == currentEntity.publicKey && u.roomNotifications == false) {
            return true
        }
        return false
    })

    const socket = useContext(SocketContext)

    const [isTyping, SetIsTyping] = useState(false)
    const [timer, setTimer] = useState<NodeJS.Timeout>()

    useEffect(() => {
        if (socket.lastJsonMessage?.channel?.id == 'chat.ctl') {
            const notification = socket.lastJsonMessage as NotificationHistoryView.ChatControl

            if (notification?.body?.roomPk == item.publicKey && notification?.body?.action == 'input.change') {
                SetIsTyping(true)
                if (timer) {
                    clearTimeout(timer)
                }
                const timerRef = setTimeout(() => SetIsTyping(false), 1000)
                setTimer(timerRef)
            }
        }
    }, [socket.lastJsonMessage])

    const items: MenuProps['items'] = [
        {
            label: strings.screens.chat.notification.options.markAsRead,
            key: '1',
            onClick: (e) => {
                e.domEvent.stopPropagation()
                const message = {
                    channel: 'chat.ctl',
                    body: {
                        action: 'readability.read',
                        communityPk: cm_pk || currentCommunity,
                        roomPk: item.publicKey,
                        userPk: currentEntity.publicKey,
                    },
                }
                dispatch(markChatAsRead({ cm_pk: cm_pk || currentCommunity, r_pk: item.publicKey }))
                socket.sendMessage(JSON.stringify(message))
            },
            icon: <Check style={{ display: 'flex', justifyContent: 'center' }} />,
        },
    ]

    if (!item) return <></>
    return (
        <Link
            to={{ pathname: Router.Path.community_chat({ cm_pk: cm_pk || currentCommunity }), state: { roomPk: item.publicKey } }}
            onClick={onClick}
            className={roomSelected == item.publicKey ? Style.itemActive : Style.item}
            style={{ display: 'flex', alignItems: 'center', padding: isMobile ? 16 : '16px 24px', ...style }}
        >
            <AvatarTuringo
                size={16}
                style={{
                    height: 40,
                    width: 40,
                    lineHeight: '40px',
                    marginRight: 16,
                }}
                name={participants && participants.length == 1 ? participants[0].name : item?.title}
                avatar={participants && participants.length == 1 && participants[0].avatarUrl}
            />

            <div style={{ lineHeight: '16px', flexGrow: 1 }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        maxWidth: isSmall
                            ? state > 0
                                ? 'calc(100vw - 128px)'
                                : 'calc(100vw - 104px)'
                            : isTablet
                            ? state > 0
                                ? 'calc(100vw - 164px)'
                                : 'calc(100vw - 136px)'
                            : state > 0
                            ? 159
                            : 200,
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Paragraph className={'turingo-text bold'} style={{ marginBottom: 0, wordBreak: 'break-word', marginRight: 4 }} ellipsis={{ rows: 1 }}>
                            {participants && (participants.length == 1 ? participants[0].name : item.title)}
                        </Paragraph>
                        {hasNotificationSilence && <SilenceIcon color="var(--neutral-8)" />}
                    </div>
                    {item.messageLast && !isTyping && (
                        <div style={{ display: 'flex' }}>
                            {item.messageLast.isDeleted && (
                                <CloseOne
                                    style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}
                                    fill={item.messageLast.isDeleted ? 'var(--neutral-7)' : 'var(--neutral-13)'}
                                />
                            )}
                            <Paragraph
                                className={'turingo-text'}
                                style={{
                                    marginBottom: 0,
                                    wordBreak: 'break-word',
                                    color: item.messageLast.isDeleted ? 'var(--neutral-7)' : 'var(--neutral-13)',
                                }}
                                ellipsis={{ rows: 1 }}
                            >
                                {item.messageLast.isDeleted
                                    ? strings.screens.chat.messages.deleteMessage
                                    : `${item.messageLast.userPk == currentEntity.publicKey ? 'Tú: ' : ''}${item.messageLast.content}`}
                            </Paragraph>
                        </div>
                    )}

                    {isTyping && (
                        <Paragraph
                            className={'turingo-text'}
                            style={{ marginBottom: 0, wordBreak: 'break-word', color: 'var(--ant-primary-color)' }}
                            ellipsis={{ rows: 1 }}
                        >
                            {strings.screens.chat.messages.isTyping.withoutName}
                        </Paragraph>
                    )}

                    {item.messageLast && <div className={'turingo-secondary'}>{dayjs(item.messageLast.dateSent).locale(language).fromNow()}</div>}
                </div>
            </div>
            {state > 0 && <Badge count={state} style={{ margin: 16 }} />}

            {showOptions && state > 0  && <MoreButton  size='small' type="text" items={items} />}
        </Link>
    )
}

export { ChatListItem }
