import { CommunityPluginView } from './../../../api/model/responses/community/CommunityPluginView'
import {
    communityEdit,
    communityTeamCreate,
    communityTeamDelete,
    communityTeamMemberAdd,
    communityTeamMemberDelete,
    communityBoardCreate,
    communityBoardDelete,
    communityTeamEdit,
    communityBoardIndex,
    communityBoardEdit,
    communityMemberDelete,
    communityInvitationDelete,
    communityMemberTeamEdit,
    authLogout,
    communityPluginGet,
    communityPluginDisconnect,
    communityPluginConnect,
    communityEventList,
    communityEventDetails,
    communityEventAttendanceAccept,
    communityEventAttendanceReject,
    communityEventAttendanceList,
    communityInvitationCreate,
    communityInvitationEdit,
    communityDirectory,
    communityChatList,
    communityChatCreate,
    communityChatMessages,
    communityChatExists,
    communityChatGet,
    communityChatAccept,
    communityChatSilence,
    postReaction,
    communityCertificateList,
    communityCertificateUpdate,
    communityCertificateCreate,
    communityCertificateDelete,
    communityBoardPropertyEdit,
    communityBoardPropertyDelete,
    communityBoardPropertyOrder,
    communityBoardPropertyCreate,
    communityBoardMentorCreate,
    communityBoardMentorEdit,
    communityBoardMentorDelete,
    communityBoardMentorOrder,
    boardOrder,
    analyticsProgress,
    communityInvitationLink,
    communityInvitationEmail,
    userEdit,
    authLogin,
} from './../../actions/index'
import { TeamView } from '@api/model/responses/team/TeamView'
import { BoardEditView, BoardView } from '@api/model/responses/board/BoardView'
import { CommunityView } from '@api/model/responses/community/CommunityView'
import { PostRateView } from '@api/model/responses/post/PostRateView'
import { UserView } from '@api/model/responses/user/UserView'
import { createSlice } from '@reduxjs/toolkit'
import {
    communityDetails,
    rateList,
    rateCreate,
    rateDelete,
    rateEdit,
    boardList,
    communityMembers,
    boardDetails,
    boardMembers,
    communityTeams,
    communityTeamDetail,
    communityTeamMembers,
} from '@state/actions'
import { DetailEntityItem, ListItem, ReducerCreateState, ReducerListState } from '@state/types/ReducerTypes'
import { InvitationView } from '@api/model/responses/invitation/InvitationView'
import { EventView } from '@api/model/responses/event/EventView'
import { ChatRoomView, ChatMessageView } from '@api/model/responses/chat/ChatView'
import { MeritView } from '@api/model/responses/merit/MeritView'
import { PostView } from '@api/model/responses/post/PostView'

namespace communitySlice {
    export interface State {
        items: {
            [community_pk: string]: {
                item: CommunityView
                state: ReducerCreateState
                members?: {
                    total?: number
                    items: {
                        [pk: string]: {
                            item: InvitationView
                            state: ReducerCreateState
                        }
                    }
                    createState?: ReducerCreateState
                    listState?: ReducerListState
                }
                invitations: {
                    link?: {
                        total?: number
                        items: {
                            [pk: string]: {
                                item: InvitationView
                                state: ReducerCreateState
                            }
                        }
                        createState?: ReducerCreateState
                        listState?: ReducerListState
                    }

                    email?: {
                        total?: number
                        items: {
                            [pk: string]: {
                                item: InvitationView
                                state: ReducerCreateState
                            }
                        }
                        createState?: ReducerCreateState
                        listState?: ReducerListState
                    }
                }
                teams: {
                    items: {
                        [pk: string]: {
                            item: TeamView
                            state: ReducerCreateState
                        }
                    }
                    createState?: ReducerCreateState
                    listState?: ReducerListState
                }
                chats: {
                    items: {
                        [pk: string]: {
                            item: ChatRoomView
                            state: ReducerCreateState
                        }
                    }
                    createState?: ReducerCreateState
                    listState?: ReducerListState
                }

                messages: {
                    items: {
                        [pk: string]: {
                            item: ChatRoomView
                            messages: {
                                items: {
                                    [pk: string]: {
                                        item: ChatMessageView
                                        state: ReducerCreateState
                                    }
                                }
                                createState?: ReducerCreateState
                                listState?: ReducerListState
                            }
                            state: ReducerCreateState
                        }
                    }
                    createState?: ReducerCreateState
                    listState?: ReducerListState
                }

                events: {
                    items: {
                        [pk: string]: {
                            item: EventView
                            state: ReducerCreateState
                            attendance: {
                                accepted: ListItem<UserView>
                                rejected: ListItem<UserView>
                                invited: ListItem<UserView>
                            }
                        }
                    }
                    createState?: ReducerCreateState
                    listState?: ReducerListState
                }
                certificates: {
                    items: {
                        [pk: string]: {
                            item: MeritView
                            state: ReducerCreateState
                        }
                    }
                    createState?: ReducerCreateState
                    listState?: ReducerListState
                }
                team_members: {
                    [teamPk: string]: {
                        total: number
                        items: {
                            [pk: string]: {
                                item: UserView
                                state: ReducerCreateState
                            }
                        }
                        createState?: ReducerCreateState
                        listState?: ReducerListState
                    }
                }
                plugins: {
                    items: {
                        [pk: string]: CommunityPluginView
                    }
                    createState?: ReducerCreateState
                    listState?: ReducerListState
                }
                boards: {
                    all: { [kind in BoardView.Kind]?: ListItem<BoardView> }
                    detail: { [b_pk: string]: DetailEntityItem<BoardView, PostView> }
                }
            }
        }
        createState?: ReducerCreateState
        listState?: ReducerListState

        currentCommunity: string
    }
}

const defaultState: communitySlice.State = {
    currentCommunity: 'turingo',

    items: {},
    createState: {
        loading: false,
    },
    listState: {
        loading: false,
    },
}

const communitySlice = createSlice({
    name: 'community',
    initialState: defaultState,
    reducers: {
        addPin: (state, { payload }: { payload: string }) => {
            if (state.items[payload]?.item) {
                state.items[payload].item.pinneds = {
                    ...state.items[payload]?.item.pinneds,
                    ...(state.items[payload]?.item?.pinneds?.readMandatory && { readMandatory: state.items[payload]?.item?.pinneds?.readMandatory + 1 }),
                    ...(!state.items[payload]?.item?.pinneds?.readMandatory && { readMandatory: 1 }),
                }
            }
        },
        changeCurrentCommunity: (state, { payload }: { payload: string }) => {
            state.currentCommunity = payload
        },
        deleteMessage: (state, { payload }: { payload: { messageId: string; communityPk: string; roomPk: string } }) => {
            if (state.items[payload.communityPk]?.messages?.items?.[payload.roomPk]?.messages?.items?.[payload.messageId]) {
                state.items[payload.communityPk].messages.items[payload.roomPk].messages.items[payload.messageId].item = {
                    ...state.items[payload.communityPk].messages.items[payload.roomPk].messages?.items[payload.messageId].item,
                    isDeleted: true,
                    content: '[REDACTED]',
                }
            }
            if (state.items[payload.communityPk]?.chats?.items?.[payload.roomPk]?.item?.messageLast?.publicKey == payload.messageId) {
                state.items[payload.communityPk].chats.items[payload.roomPk].item.messageLast = {
                    ...state.items[payload.communityPk].chats.items[payload.roomPk].item.messageLast,
                    isDeleted: true,
                    content: '[REDACTED]',
                }
            }
        },
        addMessage: (state, { payload }: { payload: { message: ChatMessageView; localId?: string } }) => {
            if (state.items[payload.message.communityPk].messages.items[payload.message.roomPk]) {
                if (!payload.localId)
                    delete state.items[payload.message.communityPk].messages.items[payload.message.roomPk].messages?.items[payload.message.localId]

                const messages = Object.assign({}, state.items[payload.message.communityPk].messages.items)
                delete messages[payload.message.roomPk]

                state.items[payload.message.communityPk].messages = {
                    ...state.items[payload.message.communityPk].messages,
                    items: {
                        [payload.message.roomPk]: {
                            ...state.items[payload.message.communityPk].messages.items[payload.message.roomPk],

                            item: {
                                ...state.items[payload.message.communityPk].messages.items[payload.message.roomPk].item,
                                messageCount: state.items[payload.message.communityPk].messages.items[payload.message.roomPk].item.messageCount + 1,
                                messageLast: payload.message,
                            },
                            messages: {
                                ...state.items[payload.message.communityPk].messages.items[payload.message.roomPk].messages,
                                items: {
                                    ...state.items[payload.message.communityPk].messages.items[payload.message.roomPk].messages?.items,
                                    [payload.message.publicKey]: {
                                        item: payload.message,
                                        state: { loading: false },
                                    },
                                },
                            },
                        },
                        ...messages,
                    },
                }

                state.items[payload.message.communityPk].chats = {
                    ...state.items[payload.message.communityPk].chats,
                    items: {
                        ...state.items[payload.message.communityPk].chats.items,
                        [payload.message.roomPk]: {
                            ...state.items[payload.message.communityPk].chats.items[payload.message.roomPk],
                            item: {
                                ...state.items[payload.message.communityPk].chats.items[payload.message.roomPk].item,
                                messageCount: state.items[payload.message.communityPk].chats.items[payload.message.roomPk].item.messageCount + 1,
                                messageLast: payload.message,
                            },
                        },
                    },
                }
            } else {
                state.items[payload.message.communityPk].messages = {
                    ...state.items[payload.message.communityPk].messages,
                    items: {
                        [payload.message.roomPk]: {
                            item: {
                                ...state.items[payload.message.communityPk].chats.items[payload.message.roomPk].item,
                                messageCount: 1,
                                messageLast: payload.message,
                            },
                            state: { loading: false },

                            messages: {
                                items: {
                                    [payload.message.publicKey]: {
                                        item: payload.message,
                                        state: { loading: false },
                                    },
                                },
                                listState: {
                                    loading: false,
                                },
                            },
                        },
                        ...state.items[payload.message.communityPk].messages.items,
                    },
                }
            }
        },
        addNewPost: (state, action: { payload: { communityPk: string; boardPk: string } }) => {
            if (state.items?.[action.payload.communityPk]?.item) {
                const index = state.items[action.payload.communityPk].item.boards?.findIndex(
                    (loopVariable) => loopVariable.publicKey === action.payload.boardPk
                )

                if (state.items[action.payload.communityPk].item?.boards?.[index]?.postCount) {
                    state.items[action.payload.communityPk].item.boards[index].postCount += 1
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authLogin.fulfilled, (state, action) => {
            state.items = {}
        })
        builder.addCase(authLogout, (state) => {
            state.currentCommunity = 'turingo'
        })
        builder.addCase(rateList.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                    rates: { ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].rates, listState: { loading: true } },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                    rates: { items: {}, listState: { loading: true } },
                }
            }
        })

        builder.addCase(rateList.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                rates: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].rates,
                    listState: { loading: true, error: payload },
                },
            }
        })

        builder.addCase(rateList.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                rates: {
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].rates?.items,
                        ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(boardMembers.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                    members: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members,
                        listState: { ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members.listState, loading: true },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                    members: { items: {}, listState: { loading: true } },
                }
            }
        })

        builder.addCase(boardMembers.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                members: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members,
                    listState: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members.listState,
                        loading: false,
                        error: payload,
                    },
                },
            }
        })

        builder.addCase(boardMembers.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                members: {
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].members?.items,
                        ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(communityInvitationDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    [meta.arg.extraParams.kind]: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations?.[meta.arg.extraParams.kind],
                        createState: { loading: false, editing: true },
                    },
                },
            }
        })

        builder.addCase(communityInvitationDelete.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    [meta.arg.extraParams.kind]: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations?.[meta.arg.extraParams.kind],
                        createState: { loading: false, error: payload },
                    },
                },
            }
        })

        builder.addCase(communityInvitationDelete.fulfilled, (state, { meta }) => {
            const invState = state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items[meta.arg.urlParams.i_pk].item.invState
            delete state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items[meta.arg.urlParams.i_pk]

            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                ...(meta.arg.extraParams.kind == 'email' && {
                    item: {
                        ...state.items[meta.arg.urlParams.cm_pk].item,
                        invitations: {
                            ...state.items[meta.arg.urlParams.cm_pk].item.invitations,
                            email: {
                                ...state.items[meta.arg.urlParams.cm_pk].item.invitations.email,

                                [invState]: state.items[meta.arg.urlParams.cm_pk].item.invitations.email[invState] - 1,
                            },
                        },
                    },
                }),

                ...(meta.arg.extraParams.kind == 'link' && {
                    item: {
                        ...state.items[meta.arg.urlParams.cm_pk].item,
                        invitations: {
                            ...state.items[meta.arg.urlParams.cm_pk].item.invitations,
                            link: {
                                ...state.items[meta.arg.urlParams.cm_pk].item.invitations.link,
                                [invState]: state.items[meta.arg.urlParams.cm_pk].item.invitations.link[invState] - 1,
                            },
                        },
                    },
                }),
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    [meta.arg.extraParams.kind]: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations?.[meta.arg.extraParams.kind],
                        createState: { loading: false },
                    },
                },
            }
        })

        builder.addCase(communityInvitationCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    [meta.arg.bodyParams.kind]: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations?.[meta.arg.bodyParams.kind],
                        createState: { loading: false, editing: true },
                    },
                },
            }
        })

        builder.addCase(communityInvitationCreate.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    [meta.arg.bodyParams.kind]: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations?.[meta.arg.bodyParams.kind],
                        createState: { loading: false, error: payload },
                    },
                },
            }
        })

        builder.addCase(communityInvitationCreate.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                ...(meta.arg.bodyParams.kind == 'email' && {
                    item: {
                        ...state.items[meta.arg.urlParams.cm_pk].item,
                        invitations: {
                            ...state.items[meta.arg.urlParams.cm_pk].item.invitations,
                            ...(meta.arg.bodyParams.saveMessage && { message: meta.arg.bodyParams.message }),
                            email: {
                                ...state.items[meta.arg.urlParams.cm_pk].item.invitations.email,

                                sent: state.items[meta.arg.urlParams.cm_pk].item.invitations.email.sent + meta.arg.bodyParams.users.length,
                            },
                        },
                    },
                }),

                ...(meta.arg.bodyParams.kind == 'link' && {
                    item: {
                        ...state.items[meta.arg.urlParams.cm_pk].item,
                        invitations: {
                            ...state.items[meta.arg.urlParams.cm_pk].item.invitations,
                            link: {
                                ...state.items[meta.arg.urlParams.cm_pk].item.invitations.link,
                                active: state.items[meta.arg.urlParams.cm_pk].item.invitations.link.active + 1,
                            },
                        },
                    },
                }),
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    [meta.arg.bodyParams.kind]: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations?.[meta.arg.bodyParams.kind],

                        items: {
                            ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                            ...state.items[meta.arg.urlParams.cm_pk].invitations?.[meta.arg.bodyParams.kind]?.items,
                        },
                        createState: { loading: false },
                    },
                },
            }
        })

        builder.addCase(communityInvitationEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,

                    [meta.arg.extraParams.kind]: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind],
                        items: {
                            ...state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items,
                            [meta.arg.urlParams.i_pk]: {
                                ...state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items[meta.arg.urlParams.i_pk],
                                state: {
                                    loading: false,
                                    editing: true,
                                },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityInvitationEdit.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,

                    [meta.arg.extraParams.kind]: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind],
                        items: {
                            ...state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items,
                            [meta.arg.urlParams.i_pk]: {
                                ...state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items[meta.arg.urlParams.i_pk],
                                state: {
                                    loading: false,
                                    editing: false,
                                },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityInvitationEdit.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                ...(meta.arg.extraParams.kind == 'email' && {
                    item: {
                        ...state.items[meta.arg.urlParams.cm_pk].item,
                        invitations: {
                            ...state.items[meta.arg.urlParams.cm_pk].item.invitations,

                            email: {
                                ...state.items[meta.arg.urlParams.cm_pk].item.invitations.email,
                                resent:
                                    meta.arg.bodyParams.resendEmail && meta.arg.extraParams.state != 'resent'
                                        ? state.items[meta.arg.urlParams.cm_pk].item.invitations.email.resent + 1
                                        : state.items[meta.arg.urlParams.cm_pk].item.invitations.email.resent,

                                sent:
                                    meta.arg.bodyParams.resendEmail && meta.arg.extraParams.state != 'resent'
                                        ? state.items[meta.arg.urlParams.cm_pk].item.invitations.email.sent - 1
                                        : state.items[meta.arg.urlParams.cm_pk].item.invitations.email.sent,
                            },
                        },
                    },
                }),

                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,

                    [meta.arg.extraParams.kind]: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind],
                        items: {
                            ...state.items[meta.arg.urlParams.cm_pk].invitations[meta.arg.extraParams.kind].items,
                            [payload.data.publicKey]: {
                                item: payload.data,
                                state: {
                                    loading: false,
                                    editing: false,
                                },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityMemberTeamEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    ...state.items[meta.arg.urlParams.cm_pk].members,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].members.items,
                        [meta.arg.urlParams.u_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk],
                            state: {
                                loading: false,
                                editing: true,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityMemberTeamEdit.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    ...state.items[meta.arg.urlParams.cm_pk].members,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].members.items,
                        [meta.arg.urlParams.u_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk],
                            state: {
                                loading: false,
                                editing: false,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityMemberTeamEdit.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    ...state.items[meta.arg.urlParams.cm_pk].members,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].members.items,
                        [meta.arg.urlParams.u_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk],

                            item: {
                                ...state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk].item,
                                teams: payload.data,
                            },
                            state: {
                                loading: false,
                                editing: false,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityMemberDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    ...state.items[meta.arg.urlParams.cm_pk].members,
                    createState: { loading: false, editing: true },
                },
            }
        })

        builder.addCase(communityMemberDelete.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    ...state.items[meta.arg.urlParams.cm_pk].members,
                    createState: { loading: false, error: payload },
                },
            }
        })

        builder.addCase(communityMemberDelete.fulfilled, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].members.items[meta.arg.urlParams.u_pk]
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    ...state.items[meta.arg.urlParams.cm_pk].members,

                    createState: { loading: false },
                },
            }
        })

        builder.addCase(communityMembers.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    members: {
                        ...state.items[meta.arg.urlParams.cm_pk].members,
                        listState: { ...state.items[meta.arg.urlParams.cm_pk].members.listState, loading: true },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    members: { total: undefined, items: {}, listState: { loading: true } },
                }
            }
        })

        builder.addCase(communityMembers.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    ...state.items[meta.arg.urlParams.cm_pk].members,
                    listState: { ...state.items[meta.arg.urlParams.cm_pk].members.listState, loading: false, error: payload },
                },
            }
        })

        builder.addCase(communityMembers.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    total:
                        meta.arg.headerParams.page == 'first'
                            ? payload.total
                                ? payload.total
                                : state.items[meta.arg.urlParams.cm_pk].members?.total
                            : state.items[meta.arg.urlParams.cm_pk].members?.total,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].members?.items,
                        ...payload.data.reduce((a, v) => {
                            if (v.user.publicKey) return { ...a, [v.user.publicKey]: { item: v, loading: false } }

                            return { ...a, [v.publicKey]: { item: v, loading: false } }
                        }, {}),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(communityInvitationLink.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],

                    invitations: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations,
                        link: {
                            ...state.items[meta.arg.urlParams.cm_pk].invitations.link,
                            listState: { ...state.items[meta.arg.urlParams.cm_pk].invitations.link.listState, loading: true },
                        },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    invitations: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations,
                        link: { total: undefined, items: {}, listState: { loading: true } },
                    },
                }
            }
        })

        builder.addCase(communityInvitationLink.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    link: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations.link,
                        listState: { ...state.items[meta.arg.urlParams.cm_pk].invitations.link.listState, loading: false, error: payload },
                    },
                },
            }
        })

        builder.addCase(communityInvitationLink.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    link: {
                        total:
                            meta.arg.headerParams.page == 'first'
                                ? payload.total
                                    ? payload.total
                                    : state.items[meta.arg.urlParams.cm_pk].invitations?.link?.total
                                : state.items[meta.arg.urlParams.cm_pk].invitations?.link?.total,
                        items: {
                            ...state.items[meta.arg.urlParams.cm_pk].invitations?.link?.items,
                            ...payload.data.reduce((a, v) => {
                                return { ...a, [v.publicKey]: { item: v, loading: false } }
                            }, {}),
                        },
                        listState: { loading: false, pagination: payload.pagination },
                    },
                },
            }
        })

        builder.addCase(communityInvitationEmail.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],

                    invitations: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations,
                        email: {
                            ...state.items[meta.arg.urlParams.cm_pk].invitations.email,
                            listState: { ...state.items[meta.arg.urlParams.cm_pk].invitations.email.listState, loading: true },
                        },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    invitations: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations,
                        email: { total: undefined, items: {}, listState: { loading: true } },
                    },
                }
            }
        })

        builder.addCase(communityInvitationEmail.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    email: {
                        ...state.items[meta.arg.urlParams.cm_pk].invitations.email,
                        listState: { ...state.items[meta.arg.urlParams.cm_pk].invitations.email.listState, loading: false, error: payload },
                    },
                },
            }
        })

        builder.addCase(communityInvitationEmail.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                invitations: {
                    ...state.items[meta.arg.urlParams.cm_pk].invitations,
                    email: {
                        total:
                            meta.arg.headerParams.page == 'first'
                                ? payload.total
                                    ? payload.total
                                    : state.items[meta.arg.urlParams.cm_pk].invitations?.email?.total
                                : state.items[meta.arg.urlParams.cm_pk].invitations?.email?.total,
                        items: {
                            ...state.items[meta.arg.urlParams.cm_pk].invitations?.email?.items,
                            ...payload.data.reduce((a, v) => {
                                return { ...a, [v.publicKey]: { item: v, loading: false } }
                            }, {}),
                        },
                        listState: { loading: false, pagination: payload.pagination },
                    },
                },
            }
        })

        builder.addCase(analyticsProgress.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                    analytics: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics,
                        listState: { ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics.listState, loading: true },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                    analytics: { items: {}, listState: { loading: true } },
                }
            }
        })

        builder.addCase(analyticsProgress.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                analytics: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics,
                    listState: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics.listState,
                        loading: false,
                        error: payload,
                    },
                },
            }
        })

        builder.addCase(analyticsProgress.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                analytics: {
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].analytics?.items,
                        ...payload.data.reduce((a, v) => {
                            if (v.user.publicKey) return { ...a, [v.user.publicKey]: { item: v, loading: false } }

                            return { ...a, [v.user.publicKey]: { item: v, loading: false } }
                        }, {}),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(communityDirectory.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    members: {
                        ...state.items[meta.arg.urlParams.cm_pk].members,
                        listState: { ...state.items[meta.arg.urlParams.cm_pk].members.listState, loading: true },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    members: { total: undefined, items: {}, listState: { loading: true } },
                }
            }
        })

        builder.addCase(communityDirectory.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    ...state.items[meta.arg.urlParams.cm_pk].members,
                    listState: { ...state.items[meta.arg.urlParams.cm_pk].members.listState, loading: false, error: payload },
                },
            }
        })

        builder.addCase(communityDirectory.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                members: {
                    total:
                        meta.arg.headerParams.page == 'first'
                            ? payload.total
                                ? payload.total
                                : state.items[meta.arg.urlParams.cm_pk].members?.total
                            : state.items[meta.arg.urlParams.cm_pk].members?.total,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].members?.items,
                        ...payload.data.reduce((a, v) => {
                            if (v.publicKey) return { ...a, [v.publicKey]: { item: v, loading: false } }

                            return { ...a, [v.publicKey]: { item: v, loading: false } }
                        }, {}),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(communityTeams.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: undefined,
            }
        })

        builder.addCase(communityTeams.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: undefined,
            }
        })

        builder.addCase(communityTeams.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: {
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].teams?.items,
                        ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(communityEventList.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                events: {
                    items:
                        meta.arg.headerParams?.page && meta.arg.headerParams?.page != 'first'
                            ? {
                                  ...state.items[meta.arg.urlParams.cm_pk].events.items,
                              }
                            : {},
                    listState: { loading: true },
                },
            }
        })

        builder.addCase(communityEventList.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                events: {
                    items: {},
                    listState: { loading: false },
                },
            }
        })

        builder.addCase(communityEventList.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                events: {
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].events?.items,
                        ...payload.data.reduce(
                            (a, v) => ({
                                ...a,
                                [v.publicKey]: {
                                    item: v,
                                    loading: false,
                                    attendance: {
                                        accepted: {
                                            items: {},
                                            listState: {
                                                loading: false,
                                            },
                                        },
                                        rejected: {
                                            items: {},
                                            listState: {
                                                loading: false,
                                            },
                                        },
                                        invited: {
                                            items: {},
                                            listState: {
                                                loading: false,
                                            },
                                        },
                                    },
                                },
                            }),
                            {}
                        ),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(communityCertificateList.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                certificates: {
                    items:
                        meta.arg.headerParams?.page && meta.arg.headerParams?.page != 'first'
                            ? {
                                  ...state.items[meta.arg.urlParams.cm_pk].certificates.items,
                              }
                            : {},
                    listState: { loading: true },
                },
            }
        })

        builder.addCase(communityCertificateList.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                certificates: {
                    items: {},
                    listState: { loading: false },
                },
            }
        })

        builder.addCase(communityCertificateList.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                certificates: {
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].certificates?.items,
                        ...payload.data.reduce(
                            (a, v) => ({
                                ...a,
                                [v.publicKey]: {
                                    item: v,
                                    loading: false,
                                },
                            }),
                            {}
                        ),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(communityCertificateUpdate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                certificates: {
                    ...state.items[meta.arg.urlParams.cm_pk].certificates,
                    listState: { ...state.items[meta.arg.urlParams.cm_pk].certificates.listState, loading: true },
                },
            }
        })

        builder.addCase(communityCertificateUpdate.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,

                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.bodyParams.boardPk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.bodyParams.boardPk],
                            item: {
                                ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.bodyParams.boardPk].item,
                                merits: [
                                    ...(state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.bodyParams.boardPk].item?.merits
                                        ? state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.bodyParams.boardPk].item?.merits?.map((m) => {
                                              return {
                                                  ...m,
                                                  merit: payload.data,
                                              }
                                          })
                                        : [
                                              {
                                                  merit: payload.data,
                                              },
                                          ]),
                                ],
                            },
                        },
                    },
                },

                certificates: {
                    ...state.items[meta.arg.urlParams.cm_pk].certificates,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].certificates.items,
                        [meta.arg.urlParams.cert_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.urlParams.cert_pk],
                            item: {
                                ...state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.urlParams.cert_pk].item,
                                ...payload.data,
                            },
                        },
                        ...(meta.arg.extraParams.oldCertPk && {
                            [meta.arg.extraParams.oldCertPk]: {
                                ...state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.extraParams.oldCertPk],
                                item: {
                                    ...state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.extraParams.oldCertPk].item,
                                    boards: state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.extraParams.oldCertPk].item.boards.filter(
                                        (b) => b.publicKey != meta.arg.extraParams.b_pk
                                    ),
                                },
                            },
                        }),
                    },
                    listState: { ...state.items[meta.arg.urlParams.cm_pk].certificates.listState, loading: false },
                },
            }
        })

        builder.addCase(communityCertificateCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                certificates: {
                    ...state.items[meta.arg.urlParams.cm_pk].certificates,
                    createState: { ...state.items[meta.arg.urlParams.cm_pk].certificates.createState, loading: true },
                },
            }
        })
        builder.addCase(communityCertificateCreate.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                certificates: {
                    ...state.items[meta.arg.urlParams.cm_pk].certificates,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].certificates.items,
                        [payload.data.publicKey]: {
                            item: payload.data,
                            state: { loading: false },
                        },
                    },
                    createState: { ...state.items[meta.arg.urlParams.cm_pk].certificates.createState, loading: false },
                },
            }
        })

        builder.addCase(communityCertificateDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                certificates: {
                    ...state.items[meta.arg.urlParams.cm_pk].certificates,

                    createState: { ...state.items[meta.arg.urlParams.cm_pk].certificates.listState, loading: true },
                },
            }
        })

        builder.addCase(communityCertificateDelete.fulfilled, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].certificates.items[meta.arg.urlParams.cert_pk]

            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,

                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.extraParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.extraParams.b_pk],
                            item: {
                                ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.extraParams.b_pk].item,
                                merits: undefined,
                            },
                        },
                    },
                },
                certificates: {
                    ...state.items[meta.arg.urlParams.cm_pk].certificates,

                    createState: { ...state.items[meta.arg.urlParams.cm_pk].certificates.listState, loading: false },
                },
            }
        })

        builder.addCase(communityEventAttendanceAccept.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                events: {
                    ...state.items[meta.arg.urlParams.cm_pk].events,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].events?.items,
                        [meta.arg.urlParams.e_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk],

                            item: {
                                ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item,
                                derivedAttendants: state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.derivedAttendants + 1,
                                derivedRejectAttendants:
                                    state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.attendance.self == 'rejected'
                                        ? state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.derivedRejectAttendants - 1
                                        : state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.derivedAttendants,
                                attendance: {
                                    ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.attendance,
                                    self: 'accepted',
                                },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityEventAttendanceReject.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                events: {
                    ...state.items[meta.arg.urlParams.cm_pk].events,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].events?.items,
                        [meta.arg.urlParams.e_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk],

                            item: {
                                ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item,
                                derivedAttendants:
                                    state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.attendance.self == 'accepted'
                                        ? state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.derivedAttendants - 1
                                        : state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.derivedAttendants,
                                derivedRejectAttendants:
                                    state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.derivedRejectAttendants + 1,

                                attendance: {
                                    ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].item.attendance,
                                    self: 'rejected',
                                },
                            },
                        },
                    },
                },
            }
        })
        builder.addCase(communityEventDetails.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                events: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].events?.items,
                        [meta.arg.urlParams.e_pk]: {
                            item: undefined,
                            state: {
                                loading: true,
                            },
                            attendance: {
                                accepted: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                rejected: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                invited: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                            },
                        },
                    },
                },
            }
        })
        builder.addCase(communityEventDetails.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                events: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].events?.items,
                        [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                            attendance: {
                                accepted: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                rejected: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                                invited: {
                                    items: {},
                                    listState: {
                                        loading: false,
                                    },
                                },
                            },
                        },
                    },
                },
            }
        })
        builder.addCase(communityEventAttendanceList.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    events: {
                        ...state.items[meta.arg.urlParams.cm_pk].teams,
                        items: {
                            ...state.items[meta.arg.urlParams.cm_pk].events?.items,
                            [meta.arg.urlParams.e_pk]: {
                                ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk],
                                attendance: {
                                    ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].attendance,
                                    [meta.arg.searchParams.kind]: {
                                        ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].attendance[meta.arg.searchParams.kind],
                                        listState: {
                                            ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].attendance[
                                                meta.arg.searchParams.kind
                                            ].listState,
                                            loading: true,
                                        },
                                    },
                                },
                            },
                        },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    events: {
                        ...state.items[meta.arg.urlParams.cm_pk].teams,
                        items: {
                            ...state.items[meta.arg.urlParams.cm_pk].events?.items,
                            [meta.arg.urlParams.e_pk]: {
                                ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk],
                                attendance: {
                                    ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].attendance,
                                    [meta.arg.searchParams.kind]: {
                                        items: {},
                                        listState: {
                                            ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].attendance[
                                                meta.arg.searchParams.kind
                                            ].listState,
                                            loading: true,
                                        },
                                    },
                                },
                            },
                        },
                    },
                }
            }
        })

        builder.addCase(communityEventAttendanceList.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                events: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].events?.items,
                        [meta.arg.urlParams.e_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk],
                            attendance: {
                                ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].attendance,
                                [meta.arg.searchParams.kind]: {
                                    items: {},
                                    listState: {
                                        ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].attendance[meta.arg.searchParams.kind]
                                            .listState,
                                        error: payload,
                                        loading: false,
                                    },
                                },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityEventAttendanceList.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                events: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].events?.items,
                        [meta.arg.urlParams.e_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk],
                            attendance: {
                                ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].attendance,
                                [meta.arg.searchParams.kind]: {
                                    items: {
                                        ...state.items[meta.arg.urlParams.cm_pk].events?.items[meta.arg.urlParams.e_pk].attendance[meta.arg.searchParams.kind]
                                            .items,
                                        ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                                    },
                                    listState: { loading: false, pagination: payload.pagination },
                                },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityTeamMembers.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk],
                    listState: { ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].listState, loading: true },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                    total: undefined,
                    items: {},
                    listState: { loading: true },
                }
            }
        })

        builder.addCase(communityTeamMembers.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk],
                listState: {
                    ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].listState,
                    loading: false,
                    error: payload,
                },
            }
        })

        builder.addCase(communityTeamMembers.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk],
                total:
                    meta.arg.headerParams.page == 'first'
                        ? payload.total
                            ? payload.total
                            : state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].total
                        : state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].total,
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].items,
                    ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                },
                listState: { loading: false, pagination: payload.pagination },
            }
        })

        builder.addCase(communityTeamMemberAdd.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk],
                createState: {
                    loading: true,
                },
            }
        })

        builder.addCase(communityTeamMemberAdd.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk],
                createState: {
                    loading: false,
                },
            }
        })
        builder.addCase(communityTeamMemberAdd.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk],
                items: {
                    ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                    ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk].items,
                },
                createState: {
                    loading: false,
                },
            }
        })
        builder.addCase(communityTeamMemberDelete.pending, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]?.items[meta.arg.extraParams?.target_pk]

            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk],
                createState: {
                    loading: true,
                },
            }
        })

        builder.addCase(communityTeamMemberDelete.rejected, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]?.items[meta.arg.extraParams?.target_pk]

            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk],
                createState: {
                    loading: false,
                },
            }
        })

        builder.addCase(communityTeamMemberDelete.fulfilled, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk]?.items[meta.arg.extraParams?.target_pk]

            state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].team_members[meta.arg.urlParams.t_pk],
                createState: {
                    loading: false,
                },
            }
        })

        builder.addCase(communityTeamDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].teams.items[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].teams?.items[meta.arg.urlParams.t_pk],
                state: {
                    loading: false,
                    editing: true,
                },
            }
        })

        builder.addCase(communityTeamDelete.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].teams.items[meta.arg.urlParams.t_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk].teams?.items[meta.arg.urlParams.t_pk],
                state: {
                    loading: false,
                },
            }
        })
        builder.addCase(communityTeamDelete.fulfilled, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].teams?.items[meta.arg.urlParams.t_pk]
        })

        builder.addCase(communityTeamCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,

                    createState: {
                        loading: true,
                    },
                },
            }
        })
        builder.addCase(communityTeamCreate.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,
                    createState: {
                        loading: false,
                    },
                },
            }
        })
        builder.addCase(communityTeamCreate.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].teams?.items,
                        [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        },
                    },
                    createState: {
                        loading: false,
                    },
                },
            }
        })
        builder.addCase(communityTeamEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,

                    createState: {
                        loading: true,
                    },
                },
            }
        })
        builder.addCase(communityTeamEdit.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,
                    createState: {
                        loading: false,
                    },
                },
            }
        })
        builder.addCase(communityTeamEdit.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].teams?.items,
                        [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        },
                    },
                    createState: {
                        loading: false,
                    },
                },
            }
        })
        builder.addCase(communityTeamDetail.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                teams: {
                    ...state.items[meta.arg.urlParams.cm_pk].teams,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].teams?.items,
                        [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityChatMessages.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                state.items[meta.arg.urlParams.cm_pk].messages = {
                    ...state.items[meta.arg.urlParams.cm_pk].messages,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].messages.items,
                        [meta.arg.urlParams.r_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk],
                            messages: {
                                ...state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk].messages,
                                listState: { loading: true },
                            },
                        },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk].messages = {
                    ...state.items[meta.arg.urlParams.cm_pk].messages,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].messages.items,
                        [meta.arg.urlParams.r_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk],
                            messages: {
                                items: {},
                                listState: { loading: true },
                            },
                        },
                    },
                }
            }
        })

        builder.addCase(communityChatMessages.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = {
                ...state.items[meta.arg.urlParams.cm_pk].messages,
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].messages.items,
                    [meta.arg.urlParams.r_pk]: {
                        ...state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk],
                        messages: {
                            ...state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk].messages,
                            listState: { loading: false, error: payload },
                        },
                    },
                },
            }
        })

        builder.addCase(communityChatMessages.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = {
                ...state.items[meta.arg.urlParams.cm_pk].messages,
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].messages.items,
                    [meta.arg.urlParams.r_pk]: {
                        ...state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk],
                        messages: {
                            ...state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk].messages,
                            items: {
                                ...payload.data.reverse().reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, state: { loading: false } } }), {}),
                                ...state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk].messages.items,
                            },
                            listState: { loading: false, pagination: payload.pagination },
                        },
                    },
                },
            }
        })

        builder.addCase(communityChatSilence.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].chats.items[meta.arg.urlParams.r_pk].item = payload.data

            state.items[meta.arg.urlParams.cm_pk].messages = {
                ...state.items[meta.arg.urlParams.cm_pk].messages,
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].messages.items,
                    [meta.arg.urlParams.r_pk]: {
                        ...state.items[meta.arg.urlParams.cm_pk].messages.items[meta.arg.urlParams.r_pk],
                        item: payload.data,
                    },
                },
            }
        })

        builder.addCase(communityChatExists.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].chats = {
                ...state.items[meta.arg.urlParams.cm_pk].chats,
                listState: {
                    loading: false,
                },
                items: {
                    [payload.data.publicKey]: {
                        item: payload.data,
                        state: {
                            local: false,
                            loading: false,
                        },
                    },
                    ...state.items[meta.arg.urlParams.cm_pk]?.chats?.items,
                },
            }

            state.items[meta.arg.urlParams.cm_pk].messages = {
                ...state.items[meta.arg.urlParams.cm_pk].messages,
                listState: {
                    loading: false,
                },
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].messages?.items,
                    [payload.data.publicKey]: {
                        item: payload.data,

                        messages: {
                            items: {},
                            listState: { loading: false },
                        },
                        state: {
                            local: false,
                            loading: false,
                        },
                    },
                },
            }
        })
        builder.addCase(communityChatExists.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].chats = {
                ...state.items[meta.arg.urlParams.cm_pk].chats,
                listState: {
                    loading: false,
                },
                items: {
                    [meta.arg.extraParams.localId]: {
                        item: {
                            publicKey: meta.arg.extraParams.localId,
                            users: meta.arg.extraParams.users,
                            title: 'string',
                            messageCount: 0,
                        },
                        state: {
                            local: true,
                            loading: false,
                        },
                    },
                    ...state.items[meta.arg.urlParams.cm_pk]?.chats?.items,
                },
            }

            state.items[meta.arg.urlParams.cm_pk].messages = {
                ...state.items[meta.arg.urlParams.cm_pk].messages,
                listState: {
                    loading: false,
                },
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].messages?.items,
                    [meta.arg.extraParams.localId]: {
                        item: {
                            publicKey: meta.arg.extraParams.localId,
                            users: meta.arg.extraParams.users,
                            title: 'string',
                            messageCount: 0,
                        },
                        messages: {
                            items: {},
                            listState: { loading: false },
                        },
                        state: {
                            local: true,
                            loading: false,
                        },
                    },
                },
            }
        })

        builder.addCase(communityChatGet.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = {
                ...state.items[meta.arg.urlParams.cm_pk].messages,
                listState: { loading: true },
            }
        })
        builder.addCase(communityChatGet.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = {
                ...state.items[meta.arg.urlParams.cm_pk].messages,
                listState: { loading: false },
            }
        })
        builder.addCase(communityChatGet.fulfilled, (state, { meta, payload }) => {
            if (meta.arg.extraParams?.isFirstTime) {
                state.items[meta.arg.urlParams.cm_pk].chats = {
                    ...state.items[meta.arg.urlParams.cm_pk].chats,
                    listState: { loading: false },
                    items: {
                        [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        },
                        ...state.items[meta.arg.urlParams.cm_pk].chats.items,
                    },
                }
            }

            state.items[meta.arg.urlParams.cm_pk].messages = {
                ...state.items[meta.arg.urlParams.cm_pk].messages,
                listState: { loading: false },
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].messages.items,
                    [payload.data.publicKey]: {
                        item: payload.data,
                        messages: {
                            items: {},
                            listState: { loading: false },
                        },
                        state: {
                            loading: false,
                        },
                    },
                },
            }
        })

        builder.addCase(communityChatList.pending, (state, { meta }) => {
            if (meta.arg.headerParams.page != 'first') {
                if (state.items[meta.arg.urlParams.cm_pk]) {
                    state.items[meta.arg.urlParams.cm_pk] = {
                        ...state.items[meta.arg.urlParams.cm_pk],
                        chats: {
                            ...state.items[meta.arg.urlParams.cm_pk].chats,
                            listState: { ...state.items[meta.arg.urlParams.cm_pk].chats.listState, loading: true },
                        },
                        messages: {
                            items: {
                                ...(state.items[meta.arg.urlParams.cm_pk].messages?.items ? state.items[meta.arg.urlParams.cm_pk].messages?.items : {}),
                            },
                            listState: { loading: false },
                        },
                    }
                } else {
                    state.items[meta.arg.urlParams.cm_pk] = {
                        ...state.items[meta.arg.urlParams.cm_pk],
                        chats: {
                            items: {},
                            listState: { loading: true },
                        },
                        messages: {
                            items: {
                                ...(state.items[meta.arg.urlParams.cm_pk].messages?.items ? state.items[meta.arg.urlParams.cm_pk].messages?.items : {}),
                            },
                            listState: { loading: false },
                        },
                    }
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    chats: {
                        items: {},
                        listState: { loading: true },
                    },
                    messages: {
                        items: {
                            ...(state.items[meta.arg.urlParams.cm_pk].messages?.items ? state.items[meta.arg.urlParams.cm_pk].messages?.items : {}),
                        },
                        listState: { loading: false },
                    },
                }
            }
        })

        builder.addCase(communityChatList.rejected, (state, { meta, payload }) => {
            if (state.items[meta.arg.urlParams.cm_pk]) {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    chats: {
                        ...state.items[meta.arg.urlParams.cm_pk].chats,
                        listState: {
                            loading: false,
                            error: payload,
                        },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    chats: {
                        items: {},
                        listState: {
                            loading: false,
                            error: payload,
                        },
                    },
                }
            }
        })

        builder.addCase(communityChatList.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                chats: {
                    ...state.items[meta.arg.urlParams.cm_pk].chats,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].chats.items,
                        ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, messages: { items: {} }, state: { loading: false } } }), {}),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(communityChatCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].chats = {
                ...state.items[meta.arg.urlParams.cm_pk].chats,
                createState: { ...state.items[meta.arg.urlParams.cm_pk].chats.createState, loading: true },
            }
        })

        builder.addCase(communityChatCreate.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].chats = {
                ...state.items[meta.arg.urlParams.cm_pk].chats,
                createState: {
                    ...state.items[meta.arg.urlParams.cm_pk].chats.createState,
                    loading: false,
                    error: payload,
                },
            }
        })

        builder.addCase(communityChatCreate.fulfilled, (state, { meta, payload }) => {
            delete state.items[meta.arg.urlParams.cm_pk].chats.items[meta.arg.extraParams.localId]

            state.items[meta.arg.urlParams.cm_pk].chats = {
                ...state.items[meta.arg.urlParams.cm_pk].chats,
                items: {
                    [payload.data.publicKey]: { item: payload.data, state: { loading: false } },
                    ...state.items[meta.arg.urlParams.cm_pk].chats.items,
                },
                createState: { loading: false },
            }
        })

        builder.addCase(communityChatAccept.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].messages = {
                ...state.items[meta.arg.urlParams.cm_pk].messages,
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].messages.items,
                    [payload.data.publicKey]: {
                        ...state.items[meta.arg.urlParams.cm_pk].messages.items[payload.data.publicKey],
                        item: payload.data,
                    },
                },
                createState: { loading: false },
            }
        })

        builder.addCase(communityDetails.pending, (state, { meta }) => {
            state.createState.loading = true
            state.currentCommunity = meta.arg.urlParams.cm_pk

            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                item: undefined,
                state: {
                    loading: true,
                },
                team_members: {},
                invitations: {},
                boards: {
                    all: {
                        discussion: {
                            items: {},
                        },
                        catalog: {
                            items: {},
                        },
                        course: {
                            items: {},
                        },
                        forum: {
                            items: {},
                        },
                        story: {
                            items: {},
                        },
                    },
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk]?.boards?.detail,
                    },
                },
            }
        })

        builder.addCase(communityDetails.rejected, (state, { meta, payload }) => {
            state.createState.loading = false
            state.currentCommunity = 'turingo'
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                item: undefined,
                state: {
                    loading: false,
                    error: payload,
                },
                team_members: {},
                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk]?.boards,
                },
            }
        })

        builder.addCase(communityDetails.fulfilled, (state, { payload, meta }) => {
            state.createState.loading = false
            state.currentCommunity = payload.data.publicKey
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                item: payload.data,
                state: {
                    loading: false,
                    error: undefined,
                },
                team_members: {},
            }
            if (payload.data.boards) {
                for (const board of payload.data.boards) {
                    if (!state.items[meta.arg.urlParams.cm_pk].boards.detail[board.publicKey]) {
                        state.items[meta.arg.urlParams.cm_pk].boards.detail[board.publicKey] = {
                            item: board,
                            state: { loading: false },
                        }
                    }
                }
            }
        })

        builder.addCase(communityBoardIndex.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.all,
                        [meta.arg.searchParams.kind]: {
                            items:
                                meta.arg.headerParams?.page && meta.arg.headerParams?.page != 'first'
                                    ? {
                                          ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.searchParams.kind].items,
                                      }
                                    : {},
                            listState: {
                                loading: true,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardIndex.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.all,
                        [meta.arg.searchParams.kind]: {
                            items: {},
                            listState: {
                                loading: false,
                                error: 'true',
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardIndex.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.all,
                        [meta.arg.searchParams.kind]: {
                            items: {
                                ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.searchParams.kind].items,
                                ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                            },
                            listState: {
                                loading: false,
                                pagination: payload.pagination,
                            },
                        },
                    },
                },
            }
        })
        builder.addCase(boardList.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk]?.boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk]?.boards?.all,
                        [meta.arg.searchParams.kind]: {
                            items:
                                meta.arg.headerParams?.page && meta.arg.headerParams?.page != 'first'
                                    ? {
                                          ...state.items[meta.arg.urlParams.cm_pk]?.boards?.all?.[meta.arg.searchParams.kind]?.items,
                                      }
                                    : {},
                            listState: {
                                loading: true,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(boardList.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk]?.boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk]?.boards?.all,
                        [meta.arg.searchParams.kind]: {
                            items: {},
                            listState: {
                                loading: false,
                                error: 'true',
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(boardList.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk]?.boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk]?.boards?.all,
                        [meta.arg.searchParams.kind]: {
                            items: {
                                ...state.items[meta.arg.urlParams.cm_pk]?.boards?.all?.[meta.arg.searchParams.kind]?.items,
                                ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                            },
                            listState: {
                                loading: false,
                                pagination: payload.pagination,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(boardDetails.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail?.[meta.arg.urlParams.b_pk],
                            item: undefined,
                            state: {
                                loading: true,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(boardDetails.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...(state.items?.[meta.arg.urlParams.cm_pk]?.boards || {
                        all: {},
                    }),
                    detail: {
                        ...(state.items?.[meta.arg.urlParams.cm_pk]?.boards?.detail || {}),
                        [meta.arg.urlParams.b_pk]: {
                            ...(state.items?.[meta.arg.urlParams.cm_pk]?.boards?.detail?.[meta.arg.urlParams.b_pk] || {}),
                            item: undefined,
                            state: {
                                loading: false,
                                error: payload,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(boardDetails.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                state: {
                    loading: false,
                    editing: true,
                },
            }
        })
        builder.addCase(communityEdit.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                state: {
                    loading: false,
                    editing: false,
                    error: payload,
                },
            }
        })

        builder.addCase(communityEdit.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                item: {
                    ...state.items[meta.arg.urlParams.cm_pk].item,
                    ...payload.data,
                    invitations: state.items[meta.arg.urlParams.cm_pk].item.invitations,
                    logoUrl: payload.data.logoUrl ? payload.data.logoUrl : undefined,
                    bannerUrl: payload.data.bannerUrl ? payload.data.bannerUrl : undefined,
                    boards: state.items[meta.arg.urlParams.cm_pk].item.boards,
                    boardCount: state.items[meta.arg.urlParams.cm_pk].item.boardCount,
                },
                state: {
                    loading: false,
                    editing: false,
                },
            }
        })

        builder.addCase(communityBoardPropertyOrder.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: true },
                        },
                    },
                },
            }
        })
        builder.addCase(communityBoardPropertyOrder.fulfilled, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property] =
                meta.arg.bodyParams.properties
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: false },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardPropertyDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: true },
                        },
                    },
                },
            }
        })
        builder.addCase(communityBoardPropertyDelete.fulfilled, (state, { meta }) => {
            const index = parseInt(meta.arg.bodyParams.propertyId) - 1
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property].splice(index, 1)
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: false },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardPropertyEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: true },
                        },
                    },
                },
            }
        })
        builder.addCase(communityBoardPropertyEdit.fulfilled, (state, { meta }) => {
            const index = parseInt(meta.arg.bodyParams.propertyId) - 1
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property][index] =
                meta.arg.bodyParams.value
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: false },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardPropertyCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: true },
                        },
                    },
                },
            }
        })
        builder.addCase(communityBoardPropertyCreate.fulfilled, (state, { meta }) => {
            if (state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property]) {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property].push(
                    meta.arg.bodyParams.value
                )
            } else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.course[meta.arg.urlParams.property] = [
                    meta.arg.bodyParams.value,
                ]
            }

            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: false },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardMentorCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: true },
                        },
                    },
                },
            }
        })
        builder.addCase(communityBoardMentorCreate.fulfilled, (state, { payload, meta }) => {
            if (state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item?.partners?.mentor) {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor.push(payload.data)
            } else {
                state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners = {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners,
                    mentor: [payload.data],
                }
            }

            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: false },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardMentorEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: true },
                        },
                    },
                },
            }
        })
        builder.addCase(communityBoardMentorEdit.fulfilled, (state, { payload, meta }) => {
            const index = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor.findIndex(
                (x) => x.publicKey === payload.data.publicKey
            )
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor[index] = payload.data
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: false },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardMentorDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: true },
                        },
                    },
                },
            }
        })
        builder.addCase(communityBoardMentorDelete.fulfilled, (state, { meta }) => {
            const index = state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor.findIndex(
                (x) => x.publicKey === meta.arg.urlParams.m_pk
            )
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor.splice(index, 1)
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: false },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardMentorOrder.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: true },
                        },
                    },
                },
            }
        })
        builder.addCase(communityBoardMentorOrder.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item.partners.mentor = payload.data
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk],
                            state: { loading: false },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardEdit.fulfilled, (state, { payload, meta }) => {
            if (meta.arg.extraParams?.scope != 'all') {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    /*  item: {
                        ...state.items[meta.arg.urlParams.cm_pk].item,
                        boardCount: {
                            ...state.items[meta.arg.urlParams.cm_pk].item.boardCount,
                            ...(payload.data.kind === 'course' &&
                            meta.arg.bodyParams.hidden === true &&
                            state.items[meta.arg.urlParams.cm_pk]?.item?.boardCount.course <= 0
                                ? { course: 1 }
                                : meta.arg.bodyParams.hidden === false && state.items[meta.arg.urlParams.cm_pk]?.item?.boardCount.course > 0
                                ? { course: state.items[meta.arg.urlParams.cm_pk].item.boardCount.course - 1 }
                                : {}),
                        },
                        boards: state.items[meta.arg.urlParams.cm_pk]?.item?.boards.map((board) =>
                            board.publicKey === meta.arg.urlParams.b_pk ? { ...board, hidden: meta.arg.bodyParams.hidden } : board
                        ),
                        ...(!state.items[meta.arg.urlParams.cm_pk]?.item?.boards.find((board) => board.publicKey === meta.arg.urlParams.b_pk) &&
                            meta.arg.bodyParams.hidden === false && {
                                boards: [...state.items[meta.arg.urlParams.cm_pk].item.boards, payload.data],
                            }),
                    }, */
                    boards: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards,
                        detail: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                            [payload.data.publicKey]: {
                                item: {
                                    ...state.items[meta.arg.urlParams.cm_pk].boards.detail[payload.data.publicKey]?.item,
                                    ...payload.data,
                                    bannerUrl: payload.data.bannerUrl ? payload.data.bannerUrl : undefined,
                                },
                                state: { loading: false },
                            },
                        },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    item: {
                        ...state.items[meta.arg.urlParams.cm_pk].item,
                        boardCount: {
                            ...state.items[meta.arg.urlParams.cm_pk].item.boardCount,
                            ...(payload.data.kind === 'course' &&
                            meta.arg.bodyParams.hidden === true &&
                            state.items[meta.arg.urlParams.cm_pk]?.item?.boardCount.course <= 0
                                ? { course: 1 }
                                : meta.arg.bodyParams.hidden === false && state.items[meta.arg.urlParams.cm_pk]?.item?.boardCount.course > 0
                                ? { course: state.items[meta.arg.urlParams.cm_pk].item.boardCount.course - 1 }
                                : {}),
                        },
                        boards: state.items[meta.arg.urlParams.cm_pk]?.item?.boards.map((board) =>
                            board.publicKey === meta.arg.urlParams.b_pk ? { ...board, hidden: meta.arg.bodyParams.hidden } : board
                        ),
                        ...(!state.items[meta.arg.urlParams.cm_pk]?.item?.boards.find((board) => board.publicKey === meta.arg.urlParams.b_pk) &&
                            meta.arg.bodyParams.hidden === false && {
                                boards: [...state.items[meta.arg.urlParams.cm_pk].item.boards, payload.data],
                            }),
                    },
                    boards: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards,
                        all: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.all,

                            [meta.arg.extraParams.kind]: {
                                items: {
                                    ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items,

                                    [payload.data.publicKey]: {
                                        item: {
                                            ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items[payload.data.publicKey]?.item,
                                            ...payload.data,
                                            bannerUrl: payload.data.bannerUrl ? payload.data.bannerUrl : undefined,
                                        },
                                        state: { loading: false },
                                    },
                                },
                                createState: {
                                    loading: false,
                                },
                            },
                        },
                    },
                }
            }

            const index = state.items[meta.arg.urlParams.cm_pk].item?.boards?.findIndex((item) => item.publicKey == payload.data.publicKey)
            if (index >= 0 && payload.data.hidden === false) {
                state.items[meta.arg.urlParams.cm_pk].item?.boards.splice(index, 1)
            }

            if (index < 0 && payload.data.hidden === true) {
                state.items[meta.arg.urlParams.cm_pk].item?.boards.push(payload.data)
            }

            if (payload.data.hidden === false && payload.data.kind == 'course' && state.items[meta.arg.urlParams.cm_pk]?.item?.boardCount.course <= 0) {
                state.items[meta.arg.urlParams.cm_pk].item.boardCount.course = 1
            }

            if (payload.data.hidden === true && payload.data.kind == 'course' && state.items[meta.arg.urlParams.cm_pk]?.item?.boardCount.course > 0) {
                state.items[meta.arg.urlParams.cm_pk].item.boardCount.course = state.items[meta.arg.urlParams.cm_pk].item.boardCount.course - 1
            }
        })
        builder.addCase(communityBoardEdit.rejected, (state, { meta }) => {
            if (meta.arg.extraParams?.scope != 'all') {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    boards: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards,
                        detail: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                            [meta.arg.urlParams.b_pk]: {
                                item: state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk].item,
                                state: { loading: false },
                            },
                        },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],

                    boards: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards,
                        all: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.all,

                            [meta.arg.extraParams.kind]: {
                                items: {
                                    ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items,
                                    [meta.arg.urlParams.b_pk]: {
                                        ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items[meta.arg.urlParams.b_pk].item,
                                        state: { loading: false },
                                    },
                                },
                                createState: {
                                    loading: false,
                                },
                            },
                        },
                    },
                }
            }
        })
        builder.addCase(communityBoardEdit.pending, (state, { meta }) => {
            if (meta.arg.extraParams?.scope != 'all') {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],
                    boards: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards,
                        detail: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                            [meta.arg.urlParams.b_pk]: {
                                item: state.items[meta.arg.urlParams.cm_pk].boards.detail[meta.arg.urlParams.b_pk]?.item,
                                state: { loading: true },
                            },
                        },
                    },
                }
            } else {
                state.items[meta.arg.urlParams.cm_pk] = {
                    ...state.items[meta.arg.urlParams.cm_pk],

                    boards: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards,
                        all: {
                            ...state.items[meta.arg.urlParams.cm_pk].boards.all,

                            [meta.arg.extraParams.kind]: {
                                items: {
                                    ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items,
                                    [meta.arg.urlParams.b_pk]: {
                                        ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items[meta.arg.urlParams.b_pk]?.item,
                                        state: { loading: true },
                                    },
                                },
                                createState: {
                                    loading: false,
                                },
                            },
                        },
                    },
                }
            }
        })

        builder.addCase(communityBoardCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.all,
                        [meta.arg.bodyParams.kind]: {
                            items: {
                                ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.bodyParams.kind].items,
                            },
                            createState: {
                                loading: true,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardCreate.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.all,
                        [meta.arg.bodyParams.kind]: {
                            items: {
                                ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.bodyParams.kind].items,
                            },
                            createState: {
                                loading: false,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardCreate.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                item: {
                    ...state.items[meta.arg.urlParams.cm_pk].item,
                    boards: [...state.items[meta.arg.urlParams.cm_pk].item.boards, payload.data],
                },

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.all,
                        [meta.arg.bodyParams.kind]: {
                            items: {
                                ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.bodyParams.kind].items,
                                [payload.data.publicKey]: { item: payload.data, state: { loading: false } },
                            },
                            createState: {
                                loading: false,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardDelete.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.all,
                        [meta.arg.extraParams.kind]: {
                            items: {
                                ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items,
                            },
                            createState: {
                                loading: false,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.all,
                        [meta.arg.extraParams.kind]: {
                            items: {
                                ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items,
                            },
                            createState: {
                                loading: true,
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityBoardDelete.fulfilled, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                item: {
                    ...state.items[meta.arg.urlParams.cm_pk].item,
                    boards: state.items[meta.arg.urlParams.cm_pk].item.boards.filter(function (value) {
                        return value.publicKey != meta.arg.urlParams.b_pk
                    }),
                },

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    all: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.all,
                        [meta.arg.extraParams.kind]: {
                            items: {
                                ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items,
                            },
                            createState: {
                                loading: false,
                            },
                        },
                    },
                },
            }
            delete state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.extraParams.kind].items[meta.arg.urlParams.b_pk]
        })

        builder.addCase(rateCreate.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],
                            rates: {
                                ...(state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk] && {
                                    ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates,
                                }),
                                createState: { loading: true },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(rateCreate.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],
                            rates: {
                                ...(state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk] && {
                                    ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates,
                                }),
                                createState: { loading: false, error: payload },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(rateCreate.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],
                            item: {
                                ...(state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk] && {
                                    ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].item,
                                }),
                                userRated: meta.arg.bodyParams.rate,
                            },
                            rates: {
                                ...(state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk] && {
                                    ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates,
                                }),
                                items: {
                                    [payload.data.publicKey]: { item: payload.data, state: { loading: false } },
                                    ...(state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk] && {
                                        ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates.items,
                                    }),
                                },
                                createState: { loading: false },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(rateDelete.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],
                            rates: {
                                ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates,
                                listState: { loading: true },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(rateDelete.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],
                            rates: {
                                ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates,
                                listState: { loading: false },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(rateDelete.fulfilled, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates.items[meta.arg.urlParams.target_pk]

            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],

                            item: {
                                ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].item,
                                userRated: undefined,
                            },
                            rates: {
                                ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates,
                                listState: { loading: false },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(rateEdit.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],
                            rates: {
                                ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates,
                                listState: { loading: true },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(rateEdit.rejected, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],
                            rates: {
                                ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates,
                                listState: { loading: false },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(rateEdit.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],

                boards: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards,
                    detail: {
                        ...state.items[meta.arg.urlParams.cm_pk].boards.detail,
                        [meta.arg.urlParams.b_pk]: {
                            ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk],
                            rates: {
                                items: {
                                    ...state.items[meta.arg.urlParams.cm_pk]?.boards.detail[meta.arg.urlParams.b_pk].rates.items,
                                    [meta.arg.urlParams.p_pk]: {
                                        item: payload.data,
                                        state: {
                                            loading: false,
                                        },
                                    },
                                },
                                listState: { loading: false },
                            },
                        },
                    },
                },
            }
        })

        builder.addCase(communityPluginGet.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                plugins: {
                    ...state.items[meta.arg.urlParams.cm_pk].plugins,
                    listState: { loading: true },
                },
            }
        })

        builder.addCase(communityPluginGet.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                plugins: {
                    ...state.items[meta.arg.urlParams.cm_pk].plugins,
                    listState: { loading: false, error: payload },
                },
            }
        })

        builder.addCase(communityPluginGet.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                plugins: {
                    ...state.items[meta.arg.urlParams.cm_pk].plugins,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].plugins.items,
                        [payload.data.plugin]: payload.data,
                    },
                    listState: { loading: false },
                },
            }
        })

        builder.addCase(communityPluginConnect.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                plugins: {
                    ...state.items[meta.arg.urlParams.cm_pk].plugins,
                    createState: { loading: true },
                },
            }
        })

        builder.addCase(communityPluginConnect.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                plugins: {
                    ...state.items[meta.arg.urlParams.cm_pk].plugins,
                    createState: { loading: false, error: payload },
                },
            }
        })

        builder.addCase(communityPluginConnect.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                plugins: {
                    ...state.items[meta.arg.urlParams.cm_pk].plugins,
                    items: {
                        ...state.items[meta.arg.urlParams.cm_pk].plugins.items,
                        [payload.data.plugin]: payload.data,
                    },
                    createState: { loading: false },
                },
            }
        })

        builder.addCase(communityPluginDisconnect.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                plugins: {
                    ...state.items[meta.arg.urlParams.cm_pk].plugins,
                    listState: { loading: false, editing: true },
                },
            }
        })

        builder.addCase(communityPluginDisconnect.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                plugins: {
                    ...state.items[meta.arg.urlParams.cm_pk].plugins,
                    listState: { loading: false, error: payload },
                },
            }
        })

        builder.addCase(communityPluginDisconnect.fulfilled, (state, { meta }) => {
            delete state.items[meta.arg.urlParams.cm_pk].plugins.items[meta.arg.urlParams.plugin]

            state.items[meta.arg.urlParams.cm_pk] = {
                ...state.items[meta.arg.urlParams.cm_pk],
                plugins: {
                    ...state.items[meta.arg.urlParams.cm_pk].plugins,
                    listState: { loading: false },
                },
            }
        })

        builder.addCase(postReaction.fulfilled, (state, { meta }) => {
            if (meta.arg.bodyParams.kind == 'pin_read' && state.items[meta.arg.urlParams.cm_pk]?.item) {
                state.items[meta.arg.urlParams.cm_pk].item.pinneds = {
                    ...state.items[meta.arg.urlParams.cm_pk]?.item.pinneds,
                    read: state.items[meta.arg.urlParams.cm_pk]?.item.pinneds.read + 1,
                }
            }
        })

        builder.addCase(boardOrder.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind] = {
                items: {
                    ...payload.data.boards.reduce(
                        (a, v) => ({
                            ...a,
                            [v.publicKey]: {
                                item: state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind].items[v.publicKey].item,
                                loading: false,
                            },
                        }),
                        {}
                    ),
                },
                listState: {
                    loading: false,
                },
            }
        })

        builder.addCase(boardOrder.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind] = {
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind].items,
                },
                listState: {
                    loading: false,
                },
            }
        })

        builder.addCase(boardOrder.rejected, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind] = {
                items: {
                    ...state.items[meta.arg.urlParams.cm_pk].boards.all[meta.arg.urlParams.kind].items,
                },
                listState: {
                    loading: false,
                    error: payload,
                },
            }
        })
        builder.addCase(userEdit.fulfilled, (state, { meta, payload }) => {
            state.items[meta.arg.urlParams.cm_pk].item = {
                ...state.items[meta.arg.urlParams.cm_pk].item,
                onboardingCompleted: payload.data.onboardingCompleted,
            }
        })
    },
})
export const { changeCurrentCommunity, addMessage, deleteMessage, addNewPost, addPin } = communitySlice.actions

export { communitySlice }
