// Libraries

// React
import React, { useContext, useEffect, useState } from 'react'

// Antd
import { Alert, Card, Checkbox, Form, Input, Radio, Row, Space } from 'antd'
import { PostQuizQuestionView, PostQuizView } from '@api/model/responses/post/PostSubtypesViews'
import strings from '@resources/localization'
import { RatioImage } from '@components/image/RatioImage'
import Paragraph from 'antd/lib/typography/Paragraph'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'
import { FormContext } from 'antd/es/form/context'
import { FieldError } from 'rc-field-form/lib/interface'
import { Close } from '@icon-park/react'
// MARK: - Definition

namespace QuizAnswerForm {
    export interface Props {
        quiz: PostQuizView
        quizComplete: boolean
        onSubmit: (values: any) => void
    }
}

const QuestionFormItem = (props: {
    question: PostQuizQuestionView
    index: number
    quiz: PostQuizView
    quizComplete: boolean
    errors: { get: FieldError[]; set: (value: FieldError[]) => void }
    submitError: { get: boolean; set: (value: boolean) => void }
}) => {
    const { question, index, quiz, errors, quizComplete, submitError } = props
    const { form } = useContext(FormContext)
    const value = Form.useWatch([question.id], form)
    const [firstRender, setFirstRender] = useState(true)

    const showCorrectAnswers = quiz.options.showCorrectAnswers
    const showResults = quiz.options.showResults

    let isCorrect = false

    const answer = quiz.pages?.[0]?.questions.find((q) => q.id == question.id)?.answer
    if (question.kind == 'choice.multiple') {
        isCorrect = (answer as string[])?.sort()?.join(',') === value?.sort()?.join(',')
    } else {
        isCorrect = answer == value
    }

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false)
            return
        } else {
            form.validateFields([question.id])
                .then((values) => {
                    const tempErrors = [...errors.get]

                    const index = errors.get.findIndex((error) => error?.name?.includes(question.id))

                    if (index >= 0) {
                        tempErrors.splice(index, 1)
                    }
                    submitError.set(false)
                    errors.set(tempErrors)
                })
                .catch((error: { errorFields: FieldError[] }) => {
                    const tempErrors = [...errors.get]

                    const index = tempErrors.findIndex((error) => error?.name?.includes(question.id))

                    if (index >= 0) {
                        tempErrors.splice(index, 1)
                    }

                    if (error.errorFields) {
                        errors.set([...tempErrors, ...error.errorFields])
                    } else {
                        errors.set(tempErrors.filter((error) => !!error))
                    }
                })
        }
    }, [value])

    const hasError = errors.get.find((error) => error?.name?.includes(question.id))?.errors?.length > 0

    const label = (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Paragraph className="turingo-text turingo-required" style={{ marginBottom: 8 }} strong>
                {question.title}
            </Paragraph>

            {showResults && quizComplete && (
                <Alert
                    showIcon
                    type={isCorrect ? 'success' : 'error'}
                    message={isCorrect ? strings.screens.admin.quizzes.rightAnswer : strings.screens.admin.quizzes.wrongAnswer}
                    style={{
                        borderRadius: 4,
                        marginBottom: 16,
                    }}
                />
            )}

            {question.assetUrl && <RatioImage style={{ marginBottom: 16 }} src={question.assetUrl}></RatioImage>}
        </div>
    )
    if (question.kind == 'choice.multiple') {
        return (
            <div style={{ marginBottom: 16 }}>
                <Card
                    style={{
                        transition: 'all 0.3s',
                        borderColor: hasError ? 'red' : 'var(--border)',
                    }}
                    bodyStyle={{ padding: 16 }}
                >
                    <div style={{ marginBottom: 8 }} className="turingo-secondary">
                        {strings.formatString(strings.screens.evaluation.quiz.progress, index + 1, quiz.pages?.[0]?.questions.length) as string}
                    </div>
                    <Form.Item
                        className="quiz-form-item"
                        requiredMark={false}
                        required={false}
                        style={{ marginBottom: 0 }}
                        name={[question.id]}
                        label={label}
                        rules={[{ required: true, message: '' }]}
                    >
                        <Checkbox.Group>
                            {question?.alternatives?.map((alternative, alternativeIndex) => {
                                return (
                                    <div
                                        key={alternativeIndex}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            marginBottom: alternativeIndex !== question?.alternatives?.length - 1 ? 8 : 0,
                                            width: '100%',
                                        }}
                                    >
                                        <Checkbox
                                            className={
                                                showCorrectAnswers && (answer as string[])?.includes(alternative.id)
                                                    ? (answer as string[])?.includes((value as string[])?.find((v) => v == alternative.id))
                                                        ? 'correct'
                                                        : 'incorrect'
                                                    : quizComplete
                                                    ? 'none'
                                                    : ''
                                            }
                                            key={alternativeIndex}
                                            value={alternative.id}
                                        >
                                            <Paragraph className="turingo-text">{alternative.title}</Paragraph>
                                        </Checkbox>
                                    </div>
                                )
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                </Card>
                <Paragraph style={{ color: 'red', transition: 'all 0.3s' }}>{hasError ? strings.screens.evaluation.quiz.error.empty : ''}</Paragraph>
            </div>
        )
    }

    if (question.kind == 'choice.single') {
        return (
            <div style={{ marginBottom: 16 }}>
                <Card
                    style={{
                        transition: 'all 0.3s',
                        borderColor: hasError ? 'red' : 'var(--border)',
                    }}
                    bodyStyle={{ padding: 16 }}
                >
                    <div style={{ marginBottom: 8 }} className="turingo-secondary">
                        {strings.formatString(strings.screens.evaluation.quiz.progress, index + 1, quiz.pages?.[0]?.questions.length) as string}
                    </div>
                    <Form.Item
                        className="quiz-form-item"
                        requiredMark={false}
                        required={false}
                        style={{ marginBottom: 0, width: '100%' }}
                        name={[question.id]}
                        label={label}
                        rules={[{ required: true, message: '' }]}
                    >
                        <Radio.Group style={{ width: '100%' }}>
                            {question?.alternatives?.map((alternative, alternativeIndex) => (
                                <div
                                    key={alternativeIndex}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        marginBottom: alternativeIndex !== question?.alternatives?.length - 1 ? 8 : 0,
                                        width: '100%',
                                    }}
                                >
                                    <Radio
                                        value={alternative.id}
                                        style={{
                                            marginRight: '8px',
                                        }}
                                    >
                                        <Paragraph className="turingo-text">{alternative.title}</Paragraph>
                                    </Radio>
                                </div>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                </Card>
                <Paragraph style={{ color: 'red', transition: 'all 0.3s' }}>{hasError ? strings.screens.evaluation.quiz.error.empty : ''}</Paragraph>
            </div>
        )
    }
}

// MARK: - Implementation

const QuizAnswerForm: React.FC<QuizAnswerForm.Props> = (props: QuizAnswerForm.Props) => {
    const { quiz, onSubmit, quizComplete } = props
    const [form] = Form.useForm()
    const [submitError, setSubmitError] = useState<boolean>()
    const [error, setError] = useState<FieldError[]>([])
    const onClick = () => {
        form.validateFields()
            .then((values) => {
                onSubmit(values)
            })
            .catch((errors: { errorFields: FieldError[] }) => {
                setError(errors.errorFields)
                setSubmitError(true)
            })
    }
    const showResults = quiz.options.showResults

    return (
        <div className="lateral-padding">
            {!quizComplete && (
                <Card bodyStyle={{ padding: 16 }} style={{ marginBottom: 16 }}>
                    <div className="turingo-title" style={{ marginBottom: 8 }}>
                        {quiz.title}
                    </div>
                    <div className="turingo-text">{quiz.description}</div>
                </Card>
            )}
            {(!quizComplete || showResults) && (
                <Form layout="vertical" form={form} style={{ marginBottom: 16 }} disabled={quizComplete}>
                    {quiz.pages?.[0]?.questions?.map((question, index) => {
                        return (
                            <QuestionFormItem
                                errors={{ get: error, set: setError }}
                                submitError={{ get: submitError, set: setSubmitError }}
                                quizComplete={quizComplete}
                                key={question.id}
                                question={question}
                                index={index}
                                quiz={quiz}
                            />
                        )
                    })}

                    {submitError && (
                        <Alert
                            showIcon
                            type="error"
                            message={strings.screens.evaluation.quiz.error.help1}
                            style={{
                                borderRadius: 4,
                                marginBottom: 16,
                            }}
                        />
                    )}

                    {!quizComplete && (
                        <Row justify="end">
                            <TuringoButton type="primary" expandInMobile onClick={onClick}>
                                {strings.general.continue}
                            </TuringoButton>
                        </Row>
                    )}
                </Form>
            )}
        </div>
    )
}

export { QuizAnswerForm }
