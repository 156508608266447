import { PostRouteParams } from '@typings/routing/Router'
import ClassNames from '@util/style/ClassNames'
import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import style from './AlanEditor.scss'
import { CheckOne } from '@icon-park/react'
import useBreakpoints from '@hooks/useBreakpoints'
import { Alert, Button, Divider, Select, Spin, Tooltip } from 'antd'
import { SendIcon } from '@components/icons/SendIcon'
import { SocketContext } from 'src/SocketComponent'
import { StopIcon } from '@components/icons/StopIcon'
import { NotificationHistoryView } from '@api/model/requests/Notification'
import { useAppSelector } from '@hooks/useStore'
import { RetryIcon } from '@components/icons/RetryIcon'
import { LoadingOutlined } from '@ant-design/icons'
import strings from '@resources/localization'
import { CreatePostContext } from '../../../PostCreateProvider'

export type AlanStateType = 'inactive' | 'running' | 'stoping' | 'stop'

export interface AlanStateInterface {
    state: AlanStateType
    times: number
    voice?: string
}

export const AlanEditor = (props: {
    maxLength?: number
    showCount?: boolean
    submitOnEnter?: () => void
    minHeight?: number | string
    maxHeight?: number | string
    placeholder?: string
    bordered?: boolean
    onChange?: (value: string) => void
    value?: string
    onAlanState?: (value: AlanStateInterface) => void
}) => {
    const { maxLength, showCount, bordered = true, onChange, value, placeholder, minHeight, maxHeight } = props

    const alanVoices = [
        { value: 'grateful', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.grateful },
        { value: 'uplifting', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.uplifting },
        { value: 'casual', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.casual },
        { value: 'inspirational', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.inspirational },
        { value: 'informative', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.informative },
        { value: 'optimistic', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.optimistic },
        { value: 'professional', label: strings.screens.boards.discussion.post.alan.fieldVoice.items.professional },
    ]

    const context = useContext(CreatePostContext)

    const alanState = context?.alanState?.get
    const setAlanState = context?.alanState?.set

    const { cm_pk, b_pk } = useParams<PostRouteParams>()

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const inputRef = useRef<HTMLDivElement>()
    const socket = useContext(SocketContext)

    const [numberCharacters, setNumberCharacters] = useState(0)
    const [fitState, setFitState] = useState(false)
    const [alanVoice, setAlanVoice] = useState<string>(undefined)

    const [error, setError] = useState<{ code: string; message?: string }>(undefined)
    const [alanId, setAlanId] = useState<string>(undefined)
    const [usage, setUsage] = useState<number>(undefined)

    const language = useAppSelector((state) => state.ui.language)

    /* useEffects */

    useEffect(() => {
        const lastMessage = socket.lastJsonMessage as NotificationHistoryView.SocketMessage
        if (lastMessage?.channel?.id == 'ai.chat.autocomplete') {
            const errorMessage = socket.lastJsonMessage as NotificationHistoryView.Error
            if (errorMessage?.error) {



                setAlanState((prev) => {
                    return { ...prev, state: 'inactive' }
                })
                setError({ code: errorMessage?.error.error, message: errorMessage?.error.message })
            } else {
                const message = socket.lastJsonMessage as NotificationHistoryView.AlanMessage
                if (message.body.state == 'stop.done' || message.body.state == 'stop.length') {

                    setAlanState((prev) => {
                        return { ...prev, state: 'stop' }
                    })
                    setFitState(true)
                } else if (message.body.state == 'start') {
                    setAlanId(message.body.id)
                    setError(undefined)
                } else {
                    setUsage(Math.round((message.body.tokens.remaining * 100) / message.body.tokens.allotment))
                }
            }
        }
    }, [socket.lastJsonMessage])

    useEffect(() => {
        if (usage <= 0) {
            setError({ code: 'application.feat.cycle.exceed' })
        }
    }, [usage])

    useEffect(() => {
        socket.sendJsonMessage({
            channel: 'ai.chat.autocomplete',
            body: {
                action: 'prompt.allotment',
                communityPk: cm_pk,
            },
        })
    }, [])



    useEffect(() => {
        inputRef.current.addEventListener('paste', onPastePlainText)
    }, [])

    const onPastePlainText = (e: ClipboardEvent) => {
        e.preventDefault()
        const text = e.clipboardData.getData('text/plain')

        if (maxLength) {
            const textToPaste = text.substring(0, maxLength - inputRef.current.innerText.length)
            document.execCommand('insertText', false, textToPaste)
            setNumberCharacters(inputRef.current.innerText.length)
        } else {
            document.execCommand('insertText', false, text)
        }

        return false
    }

    // Call when a key is pressed
    const handleDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            document.execCommand('insertLineBreak', false, null)
            e.preventDefault()
        }

        // If max characters is reached, dont let add more text
        if (numberCharacters >= maxLength && e.keyCode != 46 && e.keyCode != 8) {
            e.preventDefault()
            return
        }

        // If Alan is running, dont let change the text
        if (alanState.state == 'running') {
            e.preventDefault()
            return
        }

        // if Tab key is pressed, add 4 spaces
        if (e.keyCode === 9) {
            e.preventDefault()
            const doc = inputRef.current.ownerDocument.defaultView
            const sel = doc.getSelection()
            const range = sel.getRangeAt(0)

            const tabNode = document.createTextNode('\u00a0\u00a0\u00a0\u00a0')
            range.insertNode(tabNode)

            range.setStartAfter(tabNode)
            range.setEndAfter(tabNode)
            sel.removeAllRanges()
            sel.addRange(range)
        }
    }

    // This is called only when a key that change the text is pressed
    const handleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const valueText = inputRef.current.innerText
        setNumberCharacters(valueText.length)

        if (onChange) onChange(valueText)
    }

    const alanRequest = () => {
        if (alanState.state == 'running') {
            setAlanState({ state: 'stoping', times: alanState.times, voice: alanVoice })

            const message = {
                channel: 'ai.chat.autocomplete',
                body: {
                    action: 'prompt.stop',
                    id: alanId,
                },
            }
            socket.sendMessage(JSON.stringify(message))
        } else {
            setAlanState({ state: 'running', times: alanState.times + 1, voice: alanVoice })

            const message = {
                channel: 'ai.chat.autocomplete',
                body: {
                    action: 'prompt.start',
                    communityPk: cm_pk,
                    prompt: value,
                    ...(alanVoice && { voice: alanVoice }),
                    locale: language,
                },
            }

            socket.sendMessage(JSON.stringify(message))
        }
    }

    const onFocus = () => {
        if (alanState.state == 'stop') {
            setFitState(false)
        }
    }

    const onBlur = () => {
        if (alanState.state == 'stop') {
            setFitState(true)
        }
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {usage <= 20 && usage > 0 && (
                <Alert
                    showIcon
                    closable
                    style={{ marginBottom: 10 }}
                    message={strings.formatString(strings.screens.boards.discussion.post.alan.warning, usage)}
                    type="warning"
                />
            )}
            <div
                className={bordered ? style.editorWrapperBordered : style.editorWrapper}
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'text',
                }}
            >
                <p
                    style={{
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        display: 'inline-block',
                        width: fitState ? '88%' : '100%',
                        transition: '0.3s all',

                        ...((!fitState || !isMobile) && {
                            maxHeight: maxHeight ? maxHeight : 'unset',
                            overflow: 'auto',
                            minHeight: minHeight ? minHeight : 'unset',
                        }),
                        ...(fitState &&
                            isMobile && {
                            minHeight: 16,
                            maxHeight: 22,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }),
                    }}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={ClassNames(style.editor, 'scrollStyle')}
                    id="editor"
                    contentEditable
                    onKeyDown={handleDown}
                    onInput={handleInput}
                    ref={inputRef}
                    placeholder={placeholder}
                ></p>

                <div>
                    {(alanState.state == 'running' || alanState.state == 'stoping') && (
                        <Spin indicator={antIcon} style={{ position: 'absolute', right: 12, bottom: 66, color: 'var( --neutral-7)' }}></Spin>
                    )}
                    {alanState.state == 'stop' && (
                        <CheckOne
                            style={{ position: 'absolute', right: 12, bottom: 66 }}
                            theme="filled"
                            size="16"
                            fill="var(--polar-green-7)"
                            strokeLinejoin="bevel"
                        />
                    )}

                    <Divider style={{ marginTop: 8, marginBottom: 8 }}></Divider>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Select
                            allowClear
                            onChange={(value) => {
                                setAlanVoice(value)
                            }}
                            style={{ overflow: 'hidden' }}
                            dropdownStyle={{ zIndex: 2000 }}
                            options={alanVoices}
                            placeholder={strings.screens.boards.discussion.post.alan.fieldVoice.placeholder}
                        ></Select>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {showCount && maxLength && (
                                <p
                                    style={{ marginBottom: 0, color: 'var(--neutral-6)', fontSize: 14, lineHeight: '22px', marginRight: 16 }}
                                >{`${numberCharacters}/${maxLength}`}</p>
                            )}
                            <Tooltip
                                title={
                                    alanState.state == 'running' || alanState.state == 'stoping'
                                        ? 'Detener'
                                        : alanState.state == 'stop'
                                            ? 'Actualizar'
                                            : 'Generar texto'
                                }
                            >
                                <Button
                                    loading={alanState.state == 'stoping'}
                                    onClick={alanRequest}
                                    className="hover-svg"
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    icon={
                                        alanState.state == 'running' || alanState.state == 'stoping' ? (
                                            <StopIcon style={{ width: '16px', height: '16px' }} />
                                        ) : alanState.state == 'stop' ? (
                                            <RetryIcon style={{ width: '14px', height: '14px' }} />
                                        ) : (
                                            <SendIcon style={{ width: '14px', height: '14px' }} />
                                        )
                                    }
                                    shape="circle"
                                ></Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            {error && (
                <Alert
                    showIcon
                    style={{ marginTop: 10 }}
                    message={strings.screens.boards.discussion.post.alan.errors[error.code] || error.message}
                    type="error"
                />
            )}
        </div>
    )
}
