// Libraries

// React
import React, { CSSProperties, useEffect, useState } from 'react'

// Antd
import { Row, Tooltip } from 'antd'
import { TuringoTag } from '@components/content/tag/TuringoTag'
import { Topic } from '@api/model/responses/post/PostView'
import { useWindowSize } from 'react-use'
import Paragraph from 'antd/lib/typography/Paragraph'
import useBreakpoints from '@hooks/useBreakpoints'
import { debounce, throttle } from 'lodash'

// MARK: - Definition

namespace MonikerComponent {
    export interface Props {
        uniquePk: string
        tags: string[]
        style?: CSSProperties
        offset?: number
        maxDivSize?: number | string
    }
}

// MARK: - Implementation

const MonikerComponent: React.FC<MonikerComponent.Props> = (props: MonikerComponent.Props) => {
    const { tags, uniquePk, style, maxDivSize, offset = 32 } = props
    const [visibleTags, setVisibleTags] = useState<string[]>([])
    const [hiddenTags, setHiddenTags] = useState<string[]>([])

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    useEffect(() => {
        setVisibleTags([])
        setHiddenTags([])
    }, [isMobile])

    useEffect(() => {
        const handleResize = debounce(() => {
            truncate() // Llamas a tu función truncate aquí
        }, 1000) // 300 ms de debounce

        truncate()
        window.addEventListener('resize', handleResize) // Recalcular en redimensionamiento de la ventana

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [tags])

    const truncate = () => {
        const div = document.getElementById(`tags-container-${uniquePk}`)
        if (div) {
            let totalSize = 0
            const visibleTags = []
            const hiddenTags = []
            tags.forEach((topic) => {
                const etiquetaElement = document.getElementById(`topic-${uniquePk}-${topic}`)

                // Guarda el estilo original
                const originalDisplay = etiquetaElement.style.display

                // Cambia temporalmente el estilo a un valor visible
                etiquetaElement.style.display = 'block'

                // Obtén el ancho
                const width = etiquetaElement.offsetWidth

                // Restaura el estilo original
                etiquetaElement.style.display = originalDisplay

                if (etiquetaElement) {
                    const etiquetaSize = width

                    if (totalSize + etiquetaSize <= div.offsetWidth - offset) {
                        totalSize += etiquetaSize
                        visibleTags.push(topic)
                    } else {
                        hiddenTags.push(topic)
                    }
                } else {
                    hiddenTags.push(topic)
                }
            })

            setVisibleTags(visibleTags)
            setHiddenTags(hiddenTags)
        }
    }
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap', width: '100%', ...style }}>
                {tags.map((topic, index) => {
                    return (
                        <Paragraph style={{ marginRight: 4, display: 'none' }} id={`topic-${uniquePk}-${topic}`} key={index}>
                            {topic} {index < tags.length - 1 && ' •'}
                        </Paragraph>
                    )
                })}
            </div>

            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    maxWidth: maxDivSize ? maxDivSize : 'calc(100vw - ' + 32 + 'px)',
                    ...style,
                }}
                id={`tags-container-${uniquePk}`}
            >
                {visibleTags.map((topic, index) => {
                    return (
                        <Paragraph style={{ marginRight: 4 }} key={index}>
                            {topic} {index < visibleTags.length - 1 && ' •'}
                        </Paragraph>
                    )
                })}
                {hiddenTags.length > 0 && (
                    <Tooltip
                        trigger={['click', 'hover']}
                        style={{ cursor: 'pointer' }}
                        title={hiddenTags.map((topic) => {
                            return <div key={`hidden-${uniquePk}-${topic}`}> {topic}</div>
                        })}
                    >
                        <span style={{ cursor: 'pointer' }}>+{hiddenTags.length}</span>
                    </Tooltip>
                )}
            </Row>
        </>
    )
}

export { MonikerComponent }
