import { ReactElement, useCallback, useContext } from 'react'
import { Button, Skeleton, Typography } from 'antd'
import dayjs from 'dayjs'
import { LinkUnderline } from '@components/buttons/link_underline/LinkUnderline'
import { AvatarTuringo } from '@components/avatar/Avatar'
import { useAppSelector } from '@hooks/useStore'
import { Router } from '@Router'
import { Colors } from '@util/color/Colors'
import { UserView } from '@api/model/responses/user/UserView'
import { CommunityView, PropertyView } from '@api/model/responses/community/CommunityView'
import { BoardView } from '@api/model/responses/board/BoardView'
import { DownOutlined } from '@ant-design/icons'
import strings from '@resources/localization'
import useBreakpoints from '@hooks/useBreakpoints'
import Paragraph from 'antd/lib/typography/Paragraph'
import { TuringoTag } from '@components/content/tag/TuringoTag'
import { capitalizeFirstLetter } from '@util/string/Functions'
import { PostContext } from '../Post'
import { useParams } from 'react-router'
import { PostRouteParams } from '@typings/routing/Router'
import { UserLocationView } from '@api/model/responses/user/UserLocationView'
import { CheckOne, CloseOne } from '@icon-park/react'
import { MonikerComponent } from './MonikerComponent'
import { v4 } from 'uuid'

type AuthorSize = 'small' | 'medium'

interface Props {
    author: UserView
    community?: CommunityView
    showCommunity?: boolean
    date?: Date
    type?: 'post' | 'comment' | 'reply' | 'review'
    postKey?: string
    size?: AuthorSize
    board?: BoardView
    creation?: {
        isEdit: boolean
        loading: boolean
        boardSelected: { name: string; publicKey: string }
        onBoard: () => void
    }
}

export const getMonikerTitle = (user: UserView, maxWidth?: number | string) => {
    const firstKey = user?.memberProperties ? Object.keys(user?.memberProperties)[0] : undefined
    const moniker = firstKey ? user?.memberProperties?.[firstKey] : undefined
    if (!moniker) return <></>
    return (
        <Paragraph
            strong
            className={'turingo-subtext'}
            style={{ wordBreak: 'break-word', maxWidth: maxWidth }}
            ellipsis={{ rows: 1 }}
        >{`${moniker?.title}`}</Paragraph>
    )
}

export const getMoniker = (user: UserView, maxWidth?: number | string, hideTitle?: boolean) => {
    const firstKey = user?.memberProperties ? Object.keys(user?.memberProperties)[0] : undefined
    const moniker = firstKey ? user?.memberProperties?.[firstKey] : undefined
    const uniquePk = v4()

    if (!moniker) return <></>

    if (moniker?.type == 'geo:city:id') {
        return (
            <Paragraph className={'turingo-subtext'} style={{ wordBreak: 'break-word' }} ellipsis={{ rows: 1 }}>{`${
                (moniker?.value as UserLocationView).name
            }, ${(moniker?.value as UserLocationView).countryName}`}</Paragraph>
        )
    }
    if (moniker?.type == 'integer') {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!hideTitle && (
                    <Paragraph
                        className={'turingo-subtext'}
                        strong
                        style={{ wordBreak: 'break-word', marginRight: 4 }}
                        ellipsis={{ rows: 1 }}
                    >{` ${moniker?.title}`}</Paragraph>
                )}

                <Paragraph className={'turingo-subtext'} style={{ wordBreak: 'break-word' }} ellipsis={{ rows: 1 }}>{` ${moniker?.value as string}`}</Paragraph>
            </div>
        )
    }

    if (moniker?.type == 'string:YYYY-MM-DD:maskyear') {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!hideTitle && (
                    <Paragraph
                        className={'turingo-subtext'}
                        strong
                        style={{ wordBreak: 'break-word', marginRight: 4 }}
                        ellipsis={{ rows: 1 }}
                    >{` ${moniker?.title}`}</Paragraph>
                )}
                <Paragraph className={'turingo-subtext'} style={{ wordBreak: 'break-word' }} ellipsis={{ rows: 1 }}>{`${dayjs(moniker?.value as string).format(
                    'YYYY'
                )}`}</Paragraph>
            </div>
        )
    }

    if (moniker?.type == 'string:YYYY-MM-DD') {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!hideTitle && (
                    <Paragraph
                        className={'turingo-subtext'}
                        strong
                        style={{ wordBreak: 'break-word', marginRight: 4 }}
                        ellipsis={{ rows: 1 }}
                    >{` ${moniker?.title}`}</Paragraph>
                )}

                <Paragraph className={'turingo-subtext'} style={{ wordBreak: 'break-word' }} ellipsis={{ rows: 1 }}>{`${dayjs(moniker?.value as string).format(
                    'D MMM YYYY'
                )}`}</Paragraph>
            </div>
        )
    }
    if (moniker?.type == 'array:opt:multi') {
        const tags = (moniker?.value as string).split(',')

        return (
            <Paragraph className={'turingo-subtext'} style={{ wordBreak: 'break-word' }} ellipsis={{ rows: 1 }}>
                <MonikerComponent key={tags.length} maxDivSize={maxWidth} uniquePk={uniquePk} tags={tags} />
            </Paragraph>
        )
    }
    if (moniker?.type == 'boolean') {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!hideTitle && (
                    <Paragraph
                        className={'turingo-subtext'}
                        strong
                        style={{ wordBreak: 'break-word', marginRight: 4 }}
                        ellipsis={{ rows: 1 }}
                    >{` ${moniker?.title}`}</Paragraph>
                )}

                {moniker?.value == 'true' ? (
                    <CheckOne style={{ display: 'flex' }} theme="outline" size="14" />
                ) : (
                    <CloseOne style={{ display: 'flex' }} theme="outline" size="14" />
                )}
            </div>
        )
    }
    return (
        <Paragraph className={'turingo-subtext'} style={{ wordBreak: 'break-word' /* ß */ }} ellipsis={{ rows: 1 }}>{`${moniker.value as string}`}</Paragraph>
    )
}
export const PostAuthor = (props: Props): ReactElement => {
    const { author, postKey, date, size = 'medium', type = 'post', creation, board } = props
    const language = useAppSelector((state) => state.ui.language)

    const postContext = useContext(PostContext)
    const { isFeed } = postContext
    const { cm_pk, p_pk: pr_pk } = useParams<PostRouteParams>()

    let { b_pk } = postContext

    const badges = author?.teams?.map((t) => t.badge)

    const breakpoints = useBreakpoints()
    if (isFeed) b_pk = creation?.boardSelected?.publicKey ? creation?.boardSelected?.publicKey : 'b_pk'

    const moniker = useCallback(
        (user: UserView, maxWidth?: number | string) => {
            return getMoniker(user, maxWidth)
        },
        [author]
    )

    return (
        <div style={{ display: 'flex', alignItems: !date && !postKey ? 'center' : 'start', width: 'calc(100% - 54px)' }}>
            <AvatarTuringo
                size={size == 'medium' ? 24 : 16}
                style={{
                    height: size == 'medium' ? 48 : 40,
                    width: size == 'medium' ? 48 : 40,
                    lineHeight: size == 'medium' ? '48px' : '40px',
                    marginRight: breakpoints.isMobile ? 16 : 8,
                }}
                name={author.isDeleted ? 'Cuenta eliminada' : author?.name}
                avatar={author?.avatarUrl}
            />

            <div style={{ lineHeight: '16px', display: 'flex', flexDirection: 'column', width: 'inherit' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: creation ? 4 : 0 }}>
                    <LinkUnderline
                        href={!author.isDeleted && Router.Path.user_details({ u_pk: author.publicKey, cm_pk })}
                        style={{ color: Colors('NEUTRAL_13'), maxWidth: breakpoints.isMobile ? 'calc(100vw - 130px)' : 300 }}
                    >
                        <Paragraph
                            className={'turingo-text bold'}
                            style={{
                                lineHeight: '18px',
                                wordBreak: 'break-word',
                            }}
                            ellipsis={{ rows: 1 }}
                        >
                            {author.isDeleted ? 'Cuenta eliminada' : author.name}
                        </Paragraph>
                    </LinkUnderline>
                    {badges &&
                        badges.map((b) => {
                            if (b && b.title) {
                                return (
                                    <TuringoTag key={b.title} style={{ marginLeft: 8 }} kind="tag" color="neutral" size="small">
                                        {capitalizeFirstLetter(b.title)}{' '}
                                    </TuringoTag>
                                )
                            }
                        })}
                </div>

                {postKey && moniker(author)}

                {creation?.loading && <Skeleton title={false} active paragraph={{ rows: 1 }} style={{ marginBottom: 0 }} />}

                {!postKey && isFeed && creation?.onBoard && !creation.isEdit && !creation.loading && (
                    <Button
                        style={{
                            fontSize: breakpoints.isMobile ? 14 : 12,
                            height: 24,
                            lineHeight: '12px',
                            padding: '1px 8px 1px 0px',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            borderRadius: 4,
                            alignItems: 'center',
                        }}
                        onClick={creation.onBoard}
                        icon={
                            <DownOutlined
                                style={{
                                    marginLeft: 4,
                                    fontSize: 10,
                                    display: 'flex',
                                }}
                            />
                        }
                    >
                        {!creation.boardSelected?.publicKey ? strings.screens.community.publicIn : creation.boardSelected?.name}
                    </Button>
                )}

                {!postKey && (!creation?.onBoard || !isFeed || creation?.isEdit) && !creation.loading && (
                    <div
                        style={{
                            fontSize: 14,
                        }}
                    >
                        {`${strings.screens.community.publicIn} ${creation.boardSelected?.name}`}
                    </div>
                )}

                {date && type == 'post' && postKey && !pr_pk && (
                    <LinkUnderline
                        type={'secondary'}
                        href={Router.Path.community_post_details({ cm_pk, b_pk: board ? board.publicKey : b_pk, p_pk: postKey })}
                        style={{ fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: Colors('ICON') }}
                    >
                        {board && strings.formatString(strings.screens.community.postedIn, board.name, dayjs(date).locale(language).fromNow())}
                    </LinkUnderline>
                )}

                {date && type == 'post' && !isFeed && pr_pk && (
                    <Typography.Text type={'secondary'} style={{ fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: Colors('ICON') }}>
                        {dayjs(date).locale(language).fromNow()}
                    </Typography.Text>
                )}
                {date && type == 'post' && !isFeed && !pr_pk && (
                    <LinkUnderline
                        type={'secondary'}
                        href={Router.Path.community_post_details({ cm_pk, b_pk: board ? board.publicKey : b_pk, p_pk: postKey })}
                        style={{ fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: Colors('ICON') }}
                    >
                        {dayjs(date).locale(language).fromNow()}
                    </LinkUnderline>
                )}

                {date && type != 'post' && (
                    <div style={{ fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: Colors('ICON') }}>
                        {dayjs(date).locale(language).fromNow()}
                    </div>
                )}
            </div>
        </div>
    )
}
