import { PostCommentView } from '@api/model/responses/post/PostCommentView'
import { EntityModel } from '@model/index'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { ReplySection } from './ReplySection'
import { useRef } from 'react'
import { CommentContent } from './CommentContent'
import { PlusOutlined } from '@ant-design/icons'
import { Colors } from '@util/color/Colors'
import strings from '@resources/localization'
import useBreakpoints from '@hooks/useBreakpoints'
import { PostContext } from '../../Post'

interface Props {
    comment: PostCommentView
}
export const Comment = (props: Props): ReactElement => {
    const { comment } = props
    const [showReplies, setShowReplies] = useState(false)
    const [showReplyField, setshowReplyField] = useState(false)
    const { p_pk, b_pk, mask } = useContext(PostContext)

    const childRef = useRef<{ getFocus: () => void; replyMention: (target: { name: string; publicKey: string }) => void }>()

    const focus = (target: { name: string; publicKey: string }) => {
        childRef?.current?.getFocus()
        childRef?.current?.replyMention(target)
    }
    const replyField = (target: { name: string; publicKey: string }) => {
        setShowReplies(true)
        setshowReplyField(true)
        setTimeout(() => focus(target), 100)
    }
    const reply = () => {
        setShowReplies(true)
    }

    useEffect(() => {
        setShowReplies(false)
    }, [mask.get])

    const breakpoints = useBreakpoints()

    return (
        <div style={{ padding: breakpoints.isSmall ? '16px 16px 0px 16px' : '16px 24px 0px 24px' }}>
            <CommentContent b_pk={b_pk} showBorderReply={showReplies} parents={[p_pk]} target={comment} level={1} replyFieldAction={replyField} />
            {!showReplies && comment.totalComments > 0 && (
                <div style={{ position: 'relative', paddingLeft: 54, paddingTop: 12 }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 24,
                            height: 24,
                            width: 24,
                            borderBottom: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderBottomLeftRadius: 6,
                        }}
                    ></div>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={reply}>
                        <PlusOutlined style={{ marginRight: 8 }} />
                        {comment.totalComments == 1 && strings.formatString(strings.general.replies.one)}
                        {comment.totalComments != 1 && strings.formatString(strings.general.replies.nary, comment.totalComments)}
                    </div>
                </div>
            )}
            {showReplies && (
                <ReplySection
                    replyFieldAction={replyField}
                    showReplyField={showReplyField}
                    childRef={childRef}
                    total={comment.totalComments}
                    c_pk={comment.publicKey}
                />
            )}
        </div>
    )
}
