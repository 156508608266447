// Libraries

// React
import { useState } from 'react'

// Antd
import { Alert, Divider, Form, Input, Switch } from 'antd'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import useBreakpoints from '@hooks/useBreakpoints'
import { useForm } from 'antd/lib/form/Form'
import { FooterModal } from '@components/modals/components/FooterModal'
import strings from '@resources/localization'
import Paragraph from 'antd/lib/typography/Paragraph'
import { postCreate, postDelete, postEdit } from '@state/actions'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { CommunitySettingsPostDetailRouteParams } from '@typings/routing/Router'
import { useHistory, useParams } from 'react-router'
import { Scope } from '@api/model/requests/Upload'
import { AssetView, PostView } from '@api/model/responses/post/PostView'
import linkifyIt from 'linkify-it'
import tlds from 'tlds'
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody'
import { urlRules } from '@components/content/entity/community_header/components/community_edit_modal/CommunityEditModal'
import { TuringoUpload, UploadFile } from '@components/form/TuringoUpload/TuringoUpload'
import { Check, Close } from '@icon-park/react'
import { Router } from '@Router'
import { AttachmentUpload, formatAsset } from '@api/requests/AsyncRequests'

const linkify = linkifyIt().tlds(tlds)

// MARK: - Definition

namespace CatalogueItemModal {
    export interface CreateProps {
        action: 'create'
        open: boolean
        onCancel: () => void
        extra?: () => void
    }

    export interface EditDeleteProps {
        action: 'edit' | 'delete'
        open: boolean
        target: Partial<PostView>
        onCancel: () => void
        extra?: () => void
    }
}

// MARK: - Implementation

const CatalogueItemModal = (props: CatalogueItemModal.CreateProps | CatalogueItemModal.EditDeleteProps) => {
    const { onCancel, open, action, extra } = props

    const target = (props as CatalogueItemModal.EditDeleteProps).target

    const breakpoints = useBreakpoints()
    const [form] = useForm()
    const { cm_pk, section, item_pk, p_pk } = useParams<CommunitySettingsPostDetailRouteParams>()
    const [allowComments, setAllowComments] = useState(target?.config?.comments == true ? true : false)

    const submitCreateOrEdit = () => {
        form.submit()
    }

    const scope = p_pk ? 'detail' : 'all'

    const [error, setError] = useState<string>(undefined)
    const dispatch = useAppDispatch()
    const post = useAppSelector((state) => state.posts[scope][item_pk]?.items[target?.publicKey])
    const createState = useAppSelector((state) => state.posts[scope][item_pk]?.createState)
    const deleteState = useAppSelector((state) => state.posts[scope][item_pk]?.listState)
    const history = useHistory()

    const [hiddenModal, setHiddenModal] = useState(false)
    const afterAction = () => {
        form.resetFields()

        if (action == 'delete') {
            history.push(Router.Path.community_settings_detail({ cm_pk: cm_pk, section: section, item_pk: item_pk }))
        }

        onCancel()
    }

    const submitDelete = () => {
        void dispatch(
            postDelete({
                urlParams: { cm_pk, b_pk: item_pk, target_pk: target.publicKey },
                extraParams: {
                    parents: [],
                    scope: scope,
                },
                options: {
                    then: {
                        action: afterAction,
                    },
                    alerts: {
                        success: strings.alerts.catalog_item.delete.success,
                        error: strings.alerts.catalog_item.delete.failure,
                    },
                },
            })
        )
    }

    const processAttachments = (attachments: UploadFile[], old_attachments: AssetView[]): AttachmentUpload[] => {
        const new_attachments: AttachmentUpload[] = attachments.map((attachment) => {
            return { scope: 'attachment' as Scope, item: attachment.file, id: attachment.id }
        })
        return new_attachments
    }

    const afterError = (e) => {
        if (e.error == 'application.post.unique.title') {
            setError('No pueden existir dos catálogos con el mismo título')
        } else {
            setError(strings.alerts.post.edit.failure)
        }
    }

    const submitForm = (values: {
        title: string
        description: string
        url: string
        thumbnailLarge: UploadFile[]
        mainVideo: UploadFile[]
        attachments: UploadFile[]
    }) => {
        const { thumbnailLarge, mainVideo, attachments } = values
        setError(undefined)
        const upload_attachments = processAttachments(values.attachments, post?.item?.assets?.attachments)
        switch (action) {
            case 'edit':
                void dispatch(
                    postEdit({
                        urlParams: { cm_pk, b_pk: item_pk, p_pk: target.publicKey },
                        bodyParams: {
                            kind: 'catalog_item',
                            content: values.description,
                            url: values.url?.length > 0 ? values.url : null,
                            ...(values.title != post.item.title && { title: values.title }),
                            allowComments: allowComments,
                        },
                        uploads: {
                            assets: [...formatAsset(thumbnailLarge, 'banner', 'thumbnailLarge'), ...formatAsset(mainVideo, 'catalogMain', 'main')],
                            attachments: upload_attachments.length > 0 ? upload_attachments : null,
                        },

                        extraParams: {
                            scope: scope,
                        },
                        options: {
                            redux: {
                                retrieveData: true,
                            },
                            then: {
                                action: afterAction,
                                error: afterError,
                            },
                            alerts: {
                                success: strings.alerts.catalog_item.edit.success,
                                error: strings.alerts.catalog_item.edit.failure,
                            },
                        },
                    })
                )
                break
            case 'create':
                void dispatch(
                    postCreate({
                        urlParams: { cm_pk, b_pk: item_pk },
                        bodyParams: {
                            kind: 'catalog_item',
                            title: values.title,
                            content: values.description,
                            url: values.url?.length > 0 ? values.url : undefined,
                            allowComments: allowComments,
                        },
                        extraParams: {
                            position: 'last',
                        },
                        uploads: {
                            assets: [...formatAsset(thumbnailLarge, 'banner', 'thumbnailLarge'), ...formatAsset(mainVideo, 'catalogMain', 'main')],
                            attachments: upload_attachments,
                        },
                        options: {
                            then: {
                                action: afterAction,
                                error: afterError,
                            },
                            alerts: {
                                success: strings.alerts.catalog_item.create.success,
                                error: strings.alerts.catalog_item.create.failure,
                            },
                        },
                    })
                )
                break
        }
    }

    const getTitle = () => {
        switch (action) {
            case 'create':
                return strings.screens.admin.catalogues.items.modalAdd.title
            case 'edit':
                return strings.screens.admin.catalogues.items.modalAdd.editTitle
        }
    }

    const getDeleteText = () => {
        return {
            title: strings.screens.admin.catalogues.items.modalDelete.title,
            description: strings.screens.admin.catalogues.items.modalDelete.preambleConfirm,
            subdescription: strings.screens.admin.catalogues.items.modalDelete.preambleConsequence,
        }
    }

    const getCTA = () => {
        switch (action) {
            case 'create':
                return strings.general.create
            case 'edit':
                return strings.general.save
            case 'delete':
                return strings.general.delete
        }
    }

    return (
        <TuringoModal
            title={getTitle()}
            open={open}
            onCancel={afterAction}
            width={650}
            hidden={hiddenModal}
            footer={
                <FooterModal
                    key={'footer_modal'}
                    back={
                        extra && {
                            action: extra,
                            customText: getDeleteText().title,
                            kind: 'link',
                        }
                    }
                    primary={{
                        action: action == 'delete' ? submitDelete : submitCreateOrEdit,
                        loading: action == 'edit' ? post?.state?.editing : action == 'create' ? createState?.loading : deleteState?.loading,
                        customText: getCTA(),
                    }}
                    secondary={{
                        action: afterAction,
                        customText: strings.general.cancel,
                    }}
                />
            }
        >
            {action == 'delete' && <InformationModalBody kind={'warning'} {...getDeleteText()} />}

            {action == 'create' && (
                <Paragraph style={{ lineHeight: '22px', marginBottom: 16 }}>{strings.screens.admin.catalogues.items.modalAdd.preamble}</Paragraph>
            )}

            {(action == 'edit' || action == 'create') && (
                <Form
                    initialValues={
                        action == 'edit' && {
                            title: post?.item.title,
                            description: post?.item.content,
                            thumbnailLarge: post?.item?.assets?.thumbnailLargeUrl ? [{ preview: post?.item?.assets?.thumbnailLargeUrl }] : undefined,
                            url: post?.item?.url,
                            mainVideo: post?.item?.assets?.main ? [{ preview: post?.item?.assets?.main?.url }] : undefined,
                            attachments:
                                post?.item?.assets?.attachments?.map((file) => {
                                    return {
                                        file: {
                                            name: file.name,
                                            type: file.mimeType,
                                            size: file.size,
                                        },
                                        id: file.id,
                                        status: 'success',
                                        percent: 100,
                                        preview: file.url,
                                    }
                                }) || [],
                        }
                    }
                    className={'scrollStyle'}
                    onFinish={submitForm}
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                >
                    <Form.Item
                        name={'title'}
                        label={strings.screens.admin.catalogues.items.modalAdd.fieldName.label}
                        rules={[
                            {
                                required: true,
                                message: strings.screens.admin.boards.modalAdd.fieldName.validations.required,
                            },
                            {
                                pattern: /^[a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ'\- ]*$/,
                                message: strings.screens.admin.boards.modalAdd.fieldName.validations.pattern,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject(strings.screens.admin.boards.modalAdd.fieldName.validations.required)
                                    }
                                    return Promise.resolve()
                                },
                            }),
                        ]}
                    >
                        <Input showCount maxLength={30} />
                    </Form.Item>
                    <TuringoUpload
                        strongLabel={false}
                        label={strings.general.image.singular}
                        uploadArea={{
                            showDescription: true,
                            style: {
                                height: 252,
                            },
                        }}
                        editOptions={{
                            aspect_ratio: '16:9',
                            active: true,
                        }}
                        rules={{
                            ant: [{ required: true, message: 'La imagen no puede quedar en blanco' }],
                            maxFiles: 1,
                            maxSize: 10,
                        }}
                        style={{ marginBottom: 16 }}
                        name={'thumbnailLarge'}
                        type={'image'}
                    />

                    <Form.Item name={'description'} label={strings.screens.admin.catalogues.items.modalAdd.fieldDesc.label}>
                        <Input.TextArea showCount maxLength={1000} placeholder={''} />
                    </Form.Item>

                    <Form.Item name={'url'} label={strings.screens.admin.catalogues.items.modalAdd.fieldLink.label} rules={urlRules}>
                        <Input placeholder={''} />
                    </Form.Item>

                    <TuringoUpload
                        strongLabel={false}
                        label={'Video'}
                        uploadArea={{
                            showDescription: true,
                            style: {
                                height: 252,
                            },
                        }}
                        editOptions={{
                            aspect_ratio: '16:9',
                            active: true,
                        }}
                        rules={{
                            maxFiles: 1,
                            maxSize: 100,
                        }}
                        style={{ marginBottom: 16 }}
                        name={'mainVideo'}
                        type={'video'}
                    />

                    <TuringoUpload
                        strongLabel={false}
                        label={strings.general.downloadableContent}
                        uploadArea={{
                            showDescription: true,
                            style: {
                                height: 252,
                            },
                        }}
                        editOptions={{
                            aspect_ratio: '16:9',
                            active: true,
                        }}
                        rules={{
                            maxFiles: 20,
                            maxSize: 10,
                        }}
                        style={{ marginBottom: 16 }}
                        name={'attachments'}
                        type={'attachment'}
                        includeCompressFiles
                    />

                    <Divider></Divider>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', flexDirection: 'column' }}>
                            <p style={{ fontWeight: 500, marginBottom: 0 }}>{'Agregar comentarios'}</p>
                            <Paragraph style={{ marginBottom: 16 }}>{'Al activar esta opción, la comunidad podrá comentar sobre este ítem.'}</Paragraph>
                        </span>
                        <Switch
                            defaultChecked={allowComments}
                            onChange={(checked) => {
                                setAllowComments(checked)
                            }}
                            style={{ marginLeft: 16 }}
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                        />
                    </div>
                    {error && <Alert showIcon message={error} type="error" closable />}
                </Form>
            )}
        </TuringoModal>
    )
}

export { CatalogueItemModal }
