import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    height?: number
    width?: number
    filled?: boolean
}

export const CatalogueIcon = (props: Props) => {
    return (
        <Icon
            component={() => {
                if (props.filled) {
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 24 24" fill={'currentColor'}>
                            <path d="M7.97587 10.8823H3.26158V6.59654H7.97587V10.8823Z" />
                            <path d="M14.4044 10.8823H9.69015V6.59654H14.4044V10.8823Z" />
                            <path d="M20.833 10.8823H16.1187V6.59654H20.833V10.8823Z" />
                            <path d="M20.833 16.8823H16.1187V12.5965H20.833V16.8823Z" />
                            <path d="M14.4044 16.8823H9.69015V12.5965H14.4044V16.8823Z" />
                            <path d="M7.97587 16.8823H3.26158V12.5965H7.97587V16.8823Z" />
                        </svg>
                    )
                } else {
                    return (
                        <svg width="100%" viewBox="0 0 22 15" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.8696 0.666016H1.22497C0.732115 0.666016 0.333008 1.04905 0.333008 1.52316V13.9517C0.333008 14.4258 0.732115 14.8089 1.22497 14.8089H20.8669C21.3598 14.8089 21.7589 14.4258 21.7589 13.9517V1.52316C21.7616 1.04905 21.3625 0.666016 20.8696 0.666016ZM6.97587 12.8803H2.26158V8.59459H6.97587V12.8803ZM6.97587 6.8803H2.26158V2.59459H6.97587V6.8803ZM13.4044 12.8803H8.69015V8.59459H13.4044V12.8803ZM13.4044 6.8803H8.69015V2.59459H13.4044V6.8803ZM19.833 12.8803H15.1187V8.59459H19.833V12.8803ZM19.833 6.8803H15.1187V2.59459H19.833V6.8803Z" />
                        </svg>
                    )
                }
            }}
            {...props}
        />
    )
}
