import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { FooterModal } from '@components/modals/components/FooterModal'
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody'
import strings from '@resources/localization'
import React from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { communityExit } from '@state/actions'
import { CommunityRouteParams } from '@typings/routing/Router'
import { useHistory, useParams } from 'react-router'
import { Router } from '@Router'

export type CommentModalType = 'delete' | 'edit'

interface Props {
    open: boolean
    onCancel: () => void
}

export const CommunityExitModal = (props: Props) => {
    const { open, onCancel } = props

    const { cm_pk } = useParams<CommunityRouteParams>()
    const dispatch = useAppDispatch()
    const history = useHistory()

    const memberState = useAppSelector((state) => state.community.items[cm_pk].members?.createState)
    const afterAction = () => {
        history.push(Router.Path.home({})), onCancel()
    }
    const onDelete = () => {
        void dispatch(
            communityExit({
                urlParams: { cm_pk },
                options: {
                    alerts: {
                        success: 'Has abandonado exitosamente esta comunidad.',
                    },
                    then: {
                        action: afterAction,
                    },
                },
            })
        )
    }
    return (
        <TuringoModal
            onCancel={onCancel}
            open={open}
            title={null}
            width={572}
            footer={[
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        action: onDelete,
                        customText: 'Abandonar',
                        loading: memberState?.editing,
                    }}
                    secondary={{
                        action: onCancel,
                        customText: strings.general.cancel,
                    }}
                />,
            ]}
        >
            <InformationModalBody
                kind={'warning'}
                title={'Abandonar comunidad'}
                description={'¿Quieres dejar de ser parte de esta comunidad?'}
                subdescription={'Al hacerlo, ya no tendrás acceso a ninguno de sus espacios.'}
            />
        </TuringoModal>
    )
}
