import React from 'react'
import { Alert, Skeleton, Divider, Card, Row, Col } from 'antd'

interface Props {
    breadcrumb?: boolean
}

// TODO: Accept number prop to determine how many Skeleton items to have
export const HeaderSkeleton = ({ breadcrumb = false }: Props) => {
    return (
        <Card
            bordered={false}
            bodyStyle={{
                padding: '24px 24px',
            }}
            cover={
                <>
                    {breadcrumb && <div style={{padding: '16px 24px'}}><Skeleton title active paragraph={null} style={{ maxWidth: 250 }} /></div>}
                    <div style={{ paddingTop: '40%', position: 'relative', overflow: 'hidden' }}>
                        <div
                            className="turingo-skeleton"
                            style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}
                        />
                    </div>
                </>
            }
        >
            <Skeleton title active paragraph={null} style={{ maxWidth: 200 }} />
        </Card>
    )
}
