// Libraries

// React
import React, { useState } from 'react'

// Antd
import {} from 'antd'
import { useAppSelector } from '@hooks/useStore'
import { Post, PostContext } from '../Post'
import { PostView } from '@api/model/responses/post/PostView'
import { PostRouteParams } from '@typings/routing/Router'
import { useParams } from 'react-router'

// MARK: - Definition

namespace ListPostItem {
    export interface Props {
        item: PostView
    }
}

// MARK: - Implementation

const ListPostItem: React.FC<ListPostItem.Props> = (props: ListPostItem.Props) => {
    const { cm_pk, b_pk, p_pk } = useParams<PostRouteParams>()

    const currentEntity = useAppSelector((state) => state.auth.currentEntity)

    const [currentMask, setCurrentMask] = useState(currentEntity)

 
    const community = useAppSelector((state) => state.community.items[cm_pk])
    const isAdmin = community?.item?.access?.includes('edit')
    const isFeed = !b_pk
    const scope = p_pk ? 'detail' : 'all'

    return (
        <PostContext.Provider
            value={{
                mask: { get: currentMask, set: setCurrentMask },
                post: props.item,
                isAdmin,
                cm_pk: cm_pk,
                b_pk: props.item?.boardPk,
                p_pk: props.item?.publicKey,
                isFeed: isFeed,
                scope: scope,
            }}
        >
            <Post />
        </PostContext.Provider>
    )
}

export { ListPostItem }
