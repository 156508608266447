// Libraries

// React
import React from 'react'

// Antd
import {} from 'antd'
import useBreakpoints from '@hooks/useBreakpoints'

import Ampliando from '@bundle/assets/landing_b2b/ampliandocirculos.png'
import Cicmex from '@bundle/assets/landing_b2b/cicmex2.png'
import Conectagro from '@bundle/assets/landing_b2b/sna2.png'
import Dadneo from '@bundle/assets/landing_b2b/dadneo2.png'
import Drsimi from '@bundle/assets/landing_b2b/drsimi2.png'
import InnovativeGroup from '@bundle/assets/landing_b2b/innovative-group2.png'
import Innspiral from '@bundle/assets/landing_b2b/innspiral2.png'
import Italika from '@bundle/assets/landing_b2b/italika.png'
import Liquimoly from '@bundle/assets/landing_b2b/liqui2.png'
import MCI from '@bundle/assets/landing_b2b/mci.png'
import Monterrey from '@bundle/assets/landing_b2b/monterrey2.png'
import Netbridge from '@bundle/assets/landing_b2b/netbridge2.png'
import Pista8 from '@bundle/assets/landing_b2b/pista82.png'
import ProChile from '@bundle/assets/landing_b2b/prochile2.png'
import UAutonoma from '@bundle/assets/landing_b2b/uautonoma.png'
import USM from '@bundle/assets/landing_b2b/usm2.png'
import VerboDivino from '@bundle/assets/landing_b2b/verbodivino2.png'

// MARK: - Definition

namespace Logos {
    export interface Props {}
}

// MARK: - Implementation

const Logos: React.FC<Logos.Props> = (props: Logos.Props) => {
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: breakpoints.xxs || breakpoints.xs ? 'column' : 'row',
                    marginBottom: breakpoints.xxs || breakpoints.xs ? 32 : isMobile ? 16 : 24,
                    maxWidth: breakpoints.xxs || breakpoints.xs ? '100%' : isMobile ? 576 : 939,
                    flexWrap: 'wrap',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <img
                    src={Innspiral}
                    alt=""
                    style={{
                        marginRight: 48,
                        marginBottom: 16,
                        height: 80,
                    }}
                />
                <img
                    src={Liquimoly}
                    alt=""
                    style={{
                        marginRight: 48,
                        marginBottom: 16,

                        height: 80,
                    }}
                />
                <img
                    src={MCI}
                    alt=""
                    style={{
                        marginRight: 48,
                        marginBottom: 16,

                        height: 80,
                    }}
                />
                <img
                    src={USM}
                    alt=""
                    style={{
                        marginBottom: 16,
                        height: 80,
                    }}
                />
                <img
                    src={Monterrey}
                    alt=""
                    style={{
                        marginBottom: 16,
                        marginRight: 48,

                        height: 80,
                    }}
                />

                <img
                    src={Ampliando}
                    alt=""
                    style={{
                        marginRight: 48,
                        marginBottom: 16,

                        height: 80,
                    }}
                />

                <img
                    src={Conectagro}
                    alt=""
                    style={{
                        marginRight: 48,
                        height: 80,
                        marginBottom: 16,
                    }}
                />
                <img
                    src={Italika}
                    alt=""
                    style={{
                        marginRight: 48,
                        height: 80,
                        marginBottom: 16,
                    }}
                />
                <img
                    src={VerboDivino}
                    alt=""
                    style={{
                        height: 80,
                        marginBottom: 16,
                    }}
                />

                <img
                    src={ProChile}
                    alt=""
                    style={{
                        height: 80,
                        marginBottom: 16,

                        marginRight: 48,
                    }}
                />

                <img
                    src={Dadneo}
                    alt=""
                    style={{
                        marginRight: 48,
                        height: 80,
                        marginBottom: 16,
                    }}
                />

                <img
                    src={Pista8}
                    alt=""
                    style={{
                        marginRight: 48,
                        height: 80,
                        marginBottom: 16,
                    }}
                />

                <img
                    src={Netbridge}
                    alt=""
                    style={{
                        height: 80,
                        marginBottom: 16,
                    }}
                />
                <img
                    src={InnovativeGroup}
                    alt=""
                    style={{
                        marginRight: 48,
                        height: 80,
                    }}
                />
                <img
                    src={Drsimi}
                    alt=""
                    style={{
                        marginRight: 48,
                        height: 80,
                    }}
                />

                <img
                    src={Cicmex}
                    alt=""
                    style={{
                        marginRight: 48,
                        height: 80,
                    }}
                />
                <img
                    src={UAutonoma}
                    alt=""
                    style={{
                        height: 80,
                    }}
                />
            </div>
        </>
    )
}

export { Logos }
