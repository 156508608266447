import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface AddAttachmentIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const AddAttachmentIcon = (props: AddAttachmentIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 24 24" fill={'currentColor' }>
                    <g clipPath="url(#clip0_2574_578)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.3052 1.84618H19.382V0.923092C19.382 0.678269 19.2847 0.443481 19.1117 0.270364C18.9386 0.097257 18.7037 0 18.4589 0C18.2141 0 17.9792 0.097257 17.8061 0.270364C17.6331 0.443481 17.5358 0.678269 17.5358 0.923092V1.84618H16.6126C16.3678 1.84618 16.1329 1.94344 15.9598 2.11655C15.7868 2.28966 15.6895 2.52445 15.6895 2.76928C15.6895 3.0141 15.7868 3.24889 15.9598 3.422C16.1329 3.59511 16.3678 3.69237 16.6126 3.69237H17.5358V4.61546C17.5358 4.86028 17.6331 5.09507 17.8061 5.26819C17.9792 5.44129 18.2141 5.53855 18.4589 5.53855C18.7037 5.53855 18.9386 5.44129 19.1117 5.26819C19.2847 5.09507 19.382 4.86028 19.382 4.61546V3.69237H20.3052C20.55 3.69237 20.7849 3.59511 20.958 3.422C21.131 3.24889 21.2283 3.0141 21.2283 2.76928C21.2283 2.52445 21.131 2.28966 20.958 2.11655C20.7849 1.94344 20.55 1.84618 20.3052 1.84618ZM6.5 1.84375H2.79136C2.35364 1.84375 2 2.19739 2 2.63511V23.2106C2 23.6483 2.35364 24.0019 2.79137 24.0019L13.2967 24.0019C13.5069 24.0019 13.7072 23.9178 13.8556 23.7695L19.1775 18.4475C19.3259 18.2992 19.41 18.0964 19.41 17.8862L19.41 10.0019H19.4099V9.4C19.4099 8.84772 19.0114 8.4 18.5199 8.4C18.0283 8.4 17.6299 8.84772 17.6299 9.4V10.0019H17.6294V15.841H12.2877C12.0123 15.841 11.7481 15.9504 11.5533 16.1452C11.3585 16.34 11.2491 16.6042 11.2491 16.8797L11.2491 22.2214H3.78057L3.78057 3.62432L12 3.62432V3.62375H12.5C13.0523 3.62375 13.5 3.22528 13.5 2.73375C13.5 2.24222 13.0523 1.84375 12.5 1.84375H12H6.5ZM12.9307 17.5226H17.5849L12.9307 22.1768L12.9307 17.5226ZM5.7583 9.19781V10.3846C5.7583 10.4934 5.84732 10.5824 5.95612 10.5824H15.4514C15.5602 10.5824 15.6492 10.4934 15.6492 10.3846V9.19781C15.6492 9.08901 15.5602 9 15.4514 9H5.95612C5.84732 9 5.7583 9.08901 5.7583 9.19781ZM10.5059 12.3627H5.95612C5.84732 12.3627 5.7583 12.4517 5.7583 12.5605V13.7473C5.7583 13.8561 5.84732 13.9451 5.95612 13.9451H10.5059C10.6147 13.9451 10.7037 13.8561 10.7037 13.7473V12.5605C10.7037 12.4517 10.6147 12.3627 10.5059 12.3627Z"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_2574_578">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )}
            {...props}
        />
    )
}
