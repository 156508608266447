// Libraries

// React
import React, { useEffect, useState } from 'react'
import Meta from 'react-meta-tags'

// Antd
import { Card, DatePicker, Form, Input, Tooltip } from 'antd'
import strings from '@resources/localization'
import { AvatarTuringo } from '@components/avatar/Avatar'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { Redirect, useParams } from 'react-router'
import Paragraph from 'antd/lib/typography/Paragraph'
import useBreakpoints from '@hooks/useBreakpoints'
import { FormNameItem } from '@components/form/FormNameItem/FormNameItem'
import { FooterModal } from '@components/modals/components/FooterModal'
import { communityMemberSchema, userEdit } from '@state/actions'
import { Router } from '@Router'
import dayjs from 'dayjs'
import localeES from 'antd/es/date-picker/locale/es_ES'
import localeEN from 'antd/es/date-picker/locale/en_US'
import { FormHelpItem } from '@components/form/HelpeableWrapper/FormHelpItem'
import { CommunitySettingsRouteParams } from '@typings/routing/Router'
import { useRequestState } from '@api/requests/AsyncRequests'
import { CommunityMemberSchemaView } from '@api/model/responses/community/CommunityView'
import {
    addCategoryIdToProperties,
    getAllPropertiesOrderedByOnboardingOrder,
    getOrderedProperties,
    schemaOnboarding,
} from '@screens/community/admin_panel/screens/profiles/Utils'
import { CustomFormItem } from '@components/content/entity/user_header/components/user_modal/CustomFormItem'
import { FormPhoneItem } from '@components/form/FormPhoneItem/FormPhoneItem'
import { InfoCircleOutlined } from '@ant-design/icons'
import { SpinLoader } from '@components/content/spin_loader/SpinLoader'
require('dayjs/locale/es')
require('dayjs/locale/en')
// MARK: - Definition

namespace Onboarding {
    export interface Props {}
}

// MARK: - Implementation

const Onboarding: React.FC<Onboarding.Props> = (props: Onboarding.Props) => {
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)

    const community = useAppSelector((state) => state.community.items[currentCommunity]?.item)
    const breakpoints = useBreakpoints()

    const isMobile = breakpoints.isMobile

    const [form] = Form.useForm()

    const language = useAppSelector((state) => state.ui.language)
    const { cm_pk } = useParams<CommunitySettingsRouteParams>()

    dayjs.locale(language)
    const [welcome, setWelcome] = useState(false)
    const locale = language == 'es' ? localeES : localeEN
    const [loadingOnboarding, setLoadingOnboarding] = useState(true)
    const dispatch = useAppDispatch()
    const [schema, setSchema] = useRequestState<CommunityMemberSchemaView>(undefined, true, true)
    const communityProperties = schema?.data?.categories ? getOrderedProperties(addCategoryIdToProperties(schema?.data?.categories)) : []

    const submit = (values: { name: string; memberProperties: { [key: string]: any }; phone: string }) => {
        const { memberProperties } = values

        for (const key in memberProperties) {
            communityProperties.map((property) => {
                if (property.id == key) {
                    if (property.type == 'geo:city:id') {
                        if (memberProperties[key]) {
                            memberProperties[key] = parseInt(memberProperties[key].value as string)
                        }
                    }
                    if (property.type == 'array:opt:single') {
                        if (typeof memberProperties[key] === 'string') {
                            memberProperties[key] = [memberProperties[key] as string]
                        }
                    }
                    if (property.type == 'integer') {
                        if (typeof memberProperties[key] === 'string') {
                            memberProperties[key] = parseInt(memberProperties[key] as string)
                        }
                    }
                    if (property.type == 'boolean') {
                        if (typeof memberProperties[key] === 'string') {
                            memberProperties[key] = memberProperties[key] == 'true'
                        }
                    }
                }
            })
        }

        void dispatch(
            userEdit({
                urlParams: { cm_pk: currentCommunity },
                bodyParams: {
                    name: values.name,
                    phoneNumber: values.phone?.length > 0 ? values.phone : null,
                    memberProperties: { ...memberProperties },
                },
                options: {
                    then: {
                        action: () => setWelcome(true),
                    },
                },
            })
        )
    }
    useEffect(() => {
        void dispatch(
            communityMemberSchema({
                urlParams: { cm_pk },
                searchParams: { onboarding: true },
                options: { redux: { stateHandler: setSchema, retrieveData: true }, then: { action: () => setLoadingOnboarding(false) } },
            })
        )
    }, [])

    const currentUser = useAppSelector((state) => state.auth.currentEntity)
    const loading = useAppSelector((state) => state.auth.loading)
    const orderedProperties = schemaOnboarding(schema.data)
    const emailAsName = useAppSelector((state) => state.auth.authEmail)?.replace('@', ' ')

    if (community && community?.onboardingCompleted) {
        const paths = location?.pathname.split('/')
        return <Redirect to={{ pathname: Router.Path.community_home({ cm_pk: paths[1] }), state: { welcome: welcome } }} />
    }

    return (
        <>
            <Meta>
                <title>{strings.screens.auth.signup.browserTitle}</title>
                <meta name="description" content={strings.screens.auth.signup.browserDescription} />
            </Meta>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignItems: 'center',
                    minHeight: '80vh',
                    paddingLeft: breakpoints.xxs ? 0 : 16,
                    paddingRight: breakpoints.xxs ? 0 : 16,
                }}
            >
                {(schema.editing || loadingOnboarding) && <SpinLoader showIcon loading></SpinLoader>}
                {schema.data && !schema.editing && !loadingOnboarding && (
                    <Card style={{ height: 'fit-content', minWidth: 'min(590px,100%)' }}>
                        <div style={{ maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                {community && (
                                    <AvatarTuringo
                                        conteinerStyle={{ display: 'flex', justifyContent: 'center' }}
                                        avatar={community.logoUrl}
                                        color={community.ui?.style?.color}
                                        size={16}
                                        width={56}
                                        height={56}
                                        style={{
                                            border: ' 1px solid var(--hover)',
                                            lineHeight: '56px',
                                            cursor: 'pointer',
                                        }}
                                        name={community.name}
                                    ></AvatarTuringo>
                                )}
                                <Paragraph
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 500,
                                        fontSize: isMobile ? 24 : 30,
                                        lineHeight: isMobile ? '32px' : '38px',
                                        marginTop: 16,
                                        marginBottom: 16,
                                    }}
                                >
                                    {strings.screens.admin.profiles.tabs.onboarding.subtitle}
                                </Paragraph>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Form
                                        initialValues={{
                                            name: currentUser.name === emailAsName ? undefined : currentUser.name,
                                        }}
                                        onFinish={submit}
                                        form={form}
                                        style={{ maxWidth: isMobile ? '100%' : 400, minWidth: isMobile ? '100%' : 400, marginBottom: 40 }}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        layout="vertical"
                                    >
                                        <FormNameItem
                                            name={'name'}
                                            label={strings.screens.profile.editModal.name.label}
                                            validations={['required', 'format', 'long', 'short']}
                                        />

                                        {orderedProperties
                                            .filter((value) => value.highlight)
                                            .map((property) => {
                                                return <CustomFormItem key={property.id} property={property} />
                                            })}
                                        {orderedProperties
                                            .filter((value) => !value.highlight)
                                            .map((property) => {
                                                return <CustomFormItem key={property.id} property={property} />
                                            })}

                                        <FormPhoneItem
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Paragraph>{strings.screens.settings.sections.signup.changePhone.title}</Paragraph>

                                                    <Tooltip placement="right" title={strings.screens.settings.sections.signup.changePhone.help}>
                                                        <InfoCircleOutlined
                                                            onClick={(event) => event.stopPropagation()}
                                                            style={{ fontSize: breakpoints.isMobile ? 16 : 14, marginLeft: 4 }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            }
                                            name={['phone']}
                                        />
                                    </Form>
                                </div>

                                <FooterModal
                                    key={'footer_modal'}
                                    primary={{
                                        loading: loading,
                                        action: form.submit,
                                        customText: strings.general.next,
                                    }}
                                />
                            </div>
                        </div>
                    </Card>
                )}
            </div>
        </>
    )
}

export { Onboarding }
