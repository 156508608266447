// Libraries

// React
import React, { useContext } from 'react'

// Antd
import { Row } from 'antd'
import { kindToLayout } from '@screens/posts/PostDetail'
import { PostContext } from '../../Post'
import { PostAuthor } from '../PostAuthor'
import { PostMenu } from '../PostMenu'
import { useAppSelector } from '@hooks/useStore'
import useBreakpoints from '@hooks/useBreakpoints'
import { TopicComponent } from '../../post_modal/topics/TopicComponent'

// MARK: - Definition

namespace PostHeader {
    export interface Props {}
}

// MARK: - Implementation

const PostHeader: React.FC<PostHeader.Props> = (props: PostHeader.Props) => {
    const { post } = useContext(PostContext)
    const isLogged = useAppSelector((state) => !!state.auth.authToken)
    const layout = kindToLayout[post.kind]

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    return (
        <div style={{ paddingTop: 16, paddingLeft: isMobile ? 16 : 24, paddingRight: isMobile ? 16 : 24 }}>
            {layout.autor == 'header' && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16, flexFlow: 'nowrap' }}>
                    {post.author && (
                        <PostAuthor
                            type={post.kind == 'rate' ? 'review' : 'post'}
                            author={post.author}
                            date={post.dateCreated}
                            postKey={post.publicKey}
                            board={post.board}
                        />
                    )}
                    {isLogged && (
                        <span>
                            <PostMenu />
                        </span>
                    )}
                </div>
            )}
            {layout.topics == 'header' && post.topics && (
                <TopicComponent
                    key={post.topics.length}
                    style={{ marginBottom: 16, maxWidth: 'calc(100% - 48px)' }}
                    maxDivSize={isMobile ? 300 : 500}
                    postPk={post.publicKey}
                    topics={post.topics}
                />
            )}
        </div>
    )
}

export { PostHeader }
