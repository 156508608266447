// Libraries

// React
import React, { useContext } from 'react'

// Antd
import { CatalogueItem1 } from '@components/icons/CatalogueItem1'
import { CatalogueItem2 } from '@components/icons/CatalogueItem2'
import { CatalogueItem3 } from '@components/icons/CatalogueItem3'
import { CatalogueItem4 } from '@components/icons/CatalogueItem4'
import { CatalogueItem5 } from '@components/icons/CatalogueItem5'
import { CatalogueItem6 } from '@components/icons/CatalogueItem6'
import { CatalogueItem7 } from '@components/icons/CatalogueItem7'
import { CatalogueItem8 } from '@components/icons/CatalogueItem8'
import { CatalogueItem9 } from '@components/icons/CatalogueItem9'
import { CatalogueItem10 } from '@components/icons/CatalogueItem10'
import { CatalogueItem11 } from '@components/icons/CatalogueItem11'
import { CatalogueItem12 } from '@components/icons/CatalogueItem12'
import { CatalogueItem13 } from '@components/icons/CatalogueItem13'
import { CatalogueItem14 } from '@components/icons/CatalogueItem14'
import { CatalogueItem15 } from '@components/icons/CatalogueItem15'
import { CatalogueItem16 } from '@components/icons/CatalogueItem16'
import { CatalogueItem17 } from '@components/icons/CatalogueItem17'
import { FormContext } from 'antd/es/form/context'
import { Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'

// CSS
import style from './IconSelector.scss'

// MARK: - Definition

namespace IconSelector {
    export interface Props {
        name: NamePath
    }
}

export const CatalogueIcons = {
    catalog_icon_1: CatalogueItem1,
    catalog_icon_2: CatalogueItem2,
    catalog_icon_3: CatalogueItem3,
    catalog_icon_4: CatalogueItem4,
    catalog_icon_5: CatalogueItem5,
    catalog_icon_6: CatalogueItem6,
    catalog_icon_7: CatalogueItem7,
    catalog_icon_8: CatalogueItem8,
    catalog_icon_9: CatalogueItem9,
    catalog_icon_10: CatalogueItem10,
    catalog_icon_11: CatalogueItem11,
    catalog_icon_12: CatalogueItem12,
    catalog_icon_13: CatalogueItem13,
    catalog_icon_14: CatalogueItem14,
    catalog_icon_15: CatalogueItem15,
    catalog_icon_16: CatalogueItem16,
    catalog_icon_17: CatalogueItem17,
}

// MARK: - Implementation

const IconSelector: React.FC<IconSelector.Props> = (props: IconSelector.Props) => {
    const { name } = props
    const { form } = useContext(FormContext)
    const icon = Form.useWatch(name, form) as string

    const selectIcon = (value: string) => {
        form.setFieldValue(name, value)
    }

    const icons = [
        { icon: CatalogueItem1, value: 'catalog_icon_1' },
        { icon: CatalogueItem2, value: 'catalog_icon_2' },
        { icon: CatalogueItem3, value: 'catalog_icon_3' },
        { icon: CatalogueItem4, value: 'catalog_icon_4' },
        { icon: CatalogueItem5, value: 'catalog_icon_5' },
        { icon: CatalogueItem6, value: 'catalog_icon_6' },
        { icon: CatalogueItem7, value: 'catalog_icon_7' },
        { icon: CatalogueItem8, value: 'catalog_icon_8' },
        { icon: CatalogueItem9, value: 'catalog_icon_9' },
        { icon: CatalogueItem10, value: 'catalog_icon_10' },
        { icon: CatalogueItem11, value: 'catalog_icon_11' },
        { icon: CatalogueItem12, value: 'catalog_icon_12' },
        { icon: CatalogueItem13, value: 'catalog_icon_13' },
        { icon: CatalogueItem14, value: 'catalog_icon_14' },
        { icon: CatalogueItem15, value: 'catalog_icon_15' },
        { icon: CatalogueItem16, value: 'catalog_icon_16' },
        { icon: CatalogueItem17, value: 'catalog_icon_17' },
    ]
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
            {icons.map((item, index) => {
                return (
                    <div key={index} style={{ position: 'relative' }}>
                        <div onClick={() => selectIcon(item.value)} className={icon == item.value ? style.iconSelected : style.icon}>
                            <item.icon filled={icon == item.value} style={{ fontSize: 24, height: 24, width: 24, display: 'flex' }} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export { IconSelector }
