// Libraries

// React
import React, { useContext, useState } from 'react'

// Antd
import { Checkbox, Form, Tabs } from 'antd'
import { CreationScreen, CrossPostingConsumer } from './CreationScreen'
import { CreatePostContext } from '../../PostCreateProvider'

// MARK: - Definition

namespace CrossPostingScreen {
    export interface Props { }
}



// MARK: - Implementation

const CrossPostingScreen: React.FC<CrossPostingScreen.Props> = (props: CrossPostingScreen.Props) => {
    const [activeKey, setActiveKey] = useState('turingo')
    const { form } = useContext(CreatePostContext)
    const errorCommunity = Form.useWatch(['form', 'turingo', 'error'], form)
    const errorLinkedin = Form.useWatch(['form', 'linkedin', 'error'], form)
    const errorX = Form.useWatch(['form', 'X', 'error'], form)

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey)
    }
    const initialItems = [
        {
            label: (
                <div className='turingo-text'>
                    <Checkbox style={{ marginRight: 16 }} disabled defaultChecked /> Comunidad 
                </div>
            ),
            children: <CreationScreen currentTab={activeKey as CrossPostingConsumer} consumer={'turingo'} />,
            key: 'turingo',
            closable: false,
            forceRender: true,
        },
        {
            label: (
                <div className='turingo-text'>
                    <Checkbox onChange={(e) => form.setFieldValue(['form', 'linkedin', 'active'], e.target.checked)} style={{ marginRight: 16 }} /> Linkedin
                </div>
            ),
            children: <CreationScreen currentTab={activeKey as CrossPostingConsumer} consumer={'linkedin'} />,
            key: 'linkedin',
            closable: false,
            forceRender: true,
        },
        {
            label: (
                <div className='turingo-text'>
                    <Checkbox onChange={(e) => form.setFieldValue(['form', 'X', 'active'], e.target.checked)} style={{ marginRight: 16 }} /> X {errorX && 'Error'}
                </div>
            ),
            children: <CreationScreen currentTab={activeKey as CrossPostingConsumer} consumer={'X'} />,
            key: 'X',
            closable: false,
            forceRender: true,
        },
    ]
    return <CreationScreen currentTab={activeKey as CrossPostingConsumer} consumer={'turingo'} />

}
/*     return <Tabs hideAdd type="editable-card" onChange={onChange} activeKey={activeKey} items={initialItems} />
 */


export { CrossPostingScreen }
