// Libraries

// React
import React from 'react'

// Antd
import { Alert } from 'antd'
import { useAppSelector } from '@hooks/useStore'
import strings from '@resources/localization'

// MARK: - Definition

namespace VideoBlockedScreen {
    export interface Props {}
}

// MARK: - Implementation

const VideoBlockedScreen: React.FC<VideoBlockedScreen.Props> = (props: VideoBlockedScreen.Props) => {
    const theme = useAppSelector((state) => state.ui.theme)

    return (
        <div
            style={{
                background: theme == 'dark' ? 'var(--neutral-3)' : 'var(--neutral-4)',
                height: 198,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Alert showIcon message={strings.screens.evaluation.quiz.lessonNotAvailable}></Alert>
        </div>
    )
}

export { VideoBlockedScreen }
