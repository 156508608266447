import { CollapseStyled } from '@components/collapse/CollapseStyled'
import strings from '@resources/localization'
import { Router } from '@Router'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { RootReducer } from '@state/reducers/Root'
import { CommunityRouteParams, BoardRouteParams } from '@typings/routing/Router'
import { Colors } from '@util/color/Colors'
import { Button, Card, Collapse } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import React from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { useHistory, useParams } from 'react-router'

export interface Rule {
    order: number
    description: string
    title: string
}

interface Props {
    isCompact?: boolean
    rules?: Array<Rule>
}

export const CommunityRules = (props: Props) => {
    const { isCompact, rules } = props
    const { cm_pk } = useParams<CommunityRouteParams>()

    const history = useHistory()
    const isLogged = useAppSelector((state) => !!state.auth.authToken)

    const dispatch = useAppDispatch()
    const onClick = () => {
        if (isLogged) {
            history.push(Router.Path.community_about({ cm_pk }))
        } else {
            dispatch(modalAction({ modal: 'see_more', state: true }))
        }
    }

    return (
        <Card title={strings.screens.boards.discussion.details.rules.title} bordered={false} bodyStyle={{ padding: 16 }}>
            <CollapseStyled ghost>
                {rules &&
                    rules.map((rule: Rule, index: number) => {
                        const isLastItem = index === rules.length - 1

                        return (
                            <>
                                <Collapse.Panel
                                    key={`rulesPanel#${index}`}
                                    style={{
                                        padding: '4px 0px',
                                        margin: !isLastItem ? '0px 0px 16px 0px' : '0px',
                                        borderBottom: !isLastItem ? `1px solid ${Colors('DIVIDER')}` : 'unset',
                                    }}
                                    header={<span className="ant-collapse-header-title">{`${rule.title}`}</span>}
                                >
                                    <p style={{marginBottom: 0}}>{rule.description}</p>
                                </Collapse.Panel>
                            </>
                        )
                    })}
            </CollapseStyled>
        </Card>
    )
}
