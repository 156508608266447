import SVG from '@components/assets/SVG'
import { IIconProps } from '@icon-park/react/lib/runtime'
import { RootReducer } from '@state/reducers/Root'
import { CSSProperties, ReactElement } from 'react'
import { useAppSelector } from '@hooks/useStore'
import FeatureStyle from './FeatureCard.scss'

// Antd
import { Button, Card } from 'antd'
import Title from 'antd/lib/typography/Title'
import Paragraph from 'antd/lib/typography/Paragraph'
import useBreakpoints from '@hooks/useBreakpoints'
import { ExpandableButton } from '@components/buttons/expandable_button/ExpandableButton'

type Mode = 'horizontal' | 'vertical'
type FeatureKind = 'antd' | 'iconpark' | 'svg'

export interface FeatureProps {
    svg?: string
    action?: {
        title: string
        href: string
    }
    Icon?: ({ className, size }: IIconProps) => JSX.Element
    kind: FeatureKind
    title: string
    description: string
    style?: CSSProperties
    mode?: Mode
}

export const FeatureCard = (props: FeatureProps): ReactElement => {
    const { svg, title, description, Icon, kind, mode = 'horizontal', style, action } = props

    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme === 'dark'
    const breakpoints = useBreakpoints()
    const isSmall = breakpoints.isSmall || breakpoints.md
    const isMobile = breakpoints.xxs

    if (mode === 'horizontal') {
        return (
            <Card
                bordered={false}
                style={{   height: '100%', borderRadius: 4, ...style }}
                bodyStyle={{ padding: 16 }}
            >
                <div>
                    {svg && <SVG content={svg} className={FeatureStyle.icon} />}
                    {kind == 'iconpark' && <Icon size={isSmall ? '42' : isMobile ? '28' : '56'} fill={'var(--cyan-6)'} />}
                    {kind == 'antd' && <Icon style={{ fontSize: isSmall ? 42 : isMobile ? 28 : 56, color: 'var(--cyan-6)' }} />}

                    <p className={FeatureStyle.title} style={{ marginTop: kind == 'iconpark' ||  kind == 'svg' ? 12 : 20, fontSize: isMobile? 18 : 16 }}>
                        {title}
                    </p>
                    <p style={{ marginBottom: 0, fontSize: isMobile? 16 : 14, lineHeight: '22px' }} className={FeatureStyle.subtitle}>
                        {description}
                    </p>
                </div>
                {action && action.href && action.title && (
                    <ExpandableButton type="primary" href={action.href} style={{ marginTop: 16 }}>
                        {action.title}{' '}
                    </ExpandableButton>
                )}
            </Card>
        )
    } else {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', padding: 16, alignItems: 'center' }}>
                <div style={{ display: 'flex', marginRight: 16 }}>
                    {svg && <SVG content={svg} className={FeatureStyle.icon} />}
                    {kind == 'iconpark' && <Icon size={isSmall ? '42' : isMobile ? '28' : '56'} fill={isDark ? '#58D1C9' : '#13C2C2'} />}
                    {kind == 'antd' && <Icon style={{ fontSize: isSmall ? 42 : isMobile ? 28 : 56, color: isDark ? '#58D1C9' : '#13C2C2' }} />}
                </div>
                <div style={{ overflow: 'hidden', alignSelf: 'center', textAlign: 'start' }}>
                    <Title style={{ marginBottom: 8, fontSize: isMobile? 18 : 16, }}>{title}</Title>
                    <p style={{ marginBottom: 0, fontSize: isMobile? 16 : 14, lineHeight: '22px' }}>{description}</p>
                </div>
            </div>
        )
    }
}
