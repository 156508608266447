/*
 * Post Selection
 *
 */

import { Avatar, Tooltip } from 'antd'
import { ReactElement } from 'react'
import useBreakpoints from '@hooks/useBreakpoints'
import { useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import { Colors } from '@util/color/Colors'

export const PostKindSelection = (props: {
    action: React.MouseEventHandler<HTMLDivElement>
    iconPark?: ({ error }: any) => JSX.Element
    iconAnt?: ({ error }: any) => JSX.Element
    disabled?: boolean
    selected?: boolean
    title: string
    tooltip: string
    highlightColor: string
    backgroundColor: string
    compact?: boolean
    showText?: boolean
    modal?: boolean
    firstSelection?: boolean
    showTooltip?: boolean
}): ReactElement => {
    const {
        action,
        iconPark,
        disabled,
        title,
        tooltip,
        highlightColor,
        backgroundColor,
        selected,
        compact,
        firstSelection,
        showText = true,
        modal = true,
        showTooltip = true,
    } = props

    const inputProps: { visible?: boolean } = {}
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme == 'dark'

    if (disabled || selected) inputProps.visible = false

    const Component = (
        <div
            onClick={disabled || selected ? null : action}
            style={{
                fontSize: isMobile ? 16 : 14,
                cursor: disabled || selected ? 'default' : 'pointer',
                color: disabled ? (isDark ? '#595959' : '#BFBFBF') : highlightColor,
            }}
        >
            <Avatar
                style={{
                    background: disabled ? Colors('NEUTRAL_3') : backgroundColor,
                    height: !isMobile || !compact ? 32 : 44,
                    width: !isMobile || !compact ? 32 : 44,
                    display: !isMobile || compact ? 'inline-flex' : 'flex',
                    marginRight: (!modal && !compact) || firstSelection ? 8 : !isMobile ? 4 : 0,
                    marginLeft: compact && !modal ? 8 : 0,

                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                icon={
                    iconPark ? (
                        <props.iconPark
                            style={{ position: 'flex', alignItems: 'center' }}
                            theme="outline"
                            size={isMobile ? 24 : 18}
                            fill={disabled ? (isDark ? '#595959' : '#D9D9D9') : highlightColor}
                        />
                    ) : (
                        <props.iconAnt style={{ fontSize: isMobile ? 20 : 16, color: disabled ? (isDark ? '#595959' : '#D9D9D9') : highlightColor }} />
                    )
                }
            ></Avatar>
            {(!isMobile || (compact && showText)) && title}
            {firstSelection && tooltip}
        </div>
    )
    if (showTooltip && !disabled && !selected) {
        return (
            <Tooltip {...inputProps} title={tooltip}>
                {Component}
            </Tooltip>
        )
    } else {
        return Component
    }
}
