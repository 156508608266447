// Libraries

// React
import React, { useContext, useEffect, useRef } from 'react'

// Antd
import { Alert, Form } from 'antd'
import { MentionEditorView, TuringoEditor } from '@components/form/Editor/TuringoEditor'
import { CreatePostContext } from '@components/content/posts/post_modal/PostCreateProvider'
import useBreakpoints from '@hooks/useBreakpoints'
import { useParams } from 'react-router'
import { BoardRouteParams } from '@typings/routing/Router'
import strings from '@resources/localization'
import { CrossPostingContext } from '../../CreationScreen'

// MARK: - Definition

namespace PostEditor {
    export interface Props {
        topicsHeight: number
        onFocus: () => void
    }
}

// MARK: - Implementation

const PostEditor: React.FC<PostEditor.Props> = (props: PostEditor.Props) => {
    const { form, boards, changeText } = useContext(CreatePostContext)
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const { consumer } = useContext(CrossPostingContext)
    const content = Form.useWatch(['form', consumer, 'content'], form)
    const mentions = Form.useWatch(['form', consumer, 'mentions'], form)
    const kind = Form.useWatch(['form', consumer, 'kind'], form)
    const board = Form.useWatch(['form', consumer, 'board'], form)

    const { b_pk } = useParams<BoardRouteParams>()

    const isFeed = !b_pk

    const { topicsHeight, onFocus } = props
    const childRef = useRef<{ focus: () => void; resetEditor: () => void }>()

    useEffect(() => {
        if (!isMobile) {
            childRef?.current?.focus()
        }
    }, [])

    if (content == undefined || mentions == undefined) return <></>

    return (
        <>
            <TuringoEditor
                kind="post"
                minHeight={
                    kind == 'basic' ? (mentions && mentions.length > 0 && isFeed ? (isMobile ? `calc(100dvh - 412px - ${topicsHeight}px)` : 112) : 172) : 70
                }
                maxLength={consumer == 'X' ? 280 : consumer == 'linkedin' ? 3000 : undefined}
                showCount={consumer != 'turingo'}
                ref={childRef}
                hasMentions={consumer == 'turingo'}
                initialMentions={mentions}
                onMentionsChange={(value) => form.setFieldValue(['form', 'turingo', 'mentions'], value)}
                onError={(value) => form.setFieldValue(['form', consumer, 'error'], value)}
                value={content}
                placeholder={strings.screens.boards.discussion.post.create.placeholder}
                bordered={false}
                boardSelected={board}
                onFocus={onFocus}
                onChange={(value) => {
                    form.setFieldValue(['form', consumer, 'content'], value)

                    if (consumer != 'turingo') {
                        changeText.set(true)
                    }

                    if (consumer == 'turingo' && !changeText.get) {
                        form.setFieldValue(['form', 'linkedin', 'content'], value)
                        form.setFieldValue(['form', 'X', 'content'], value)
                    }
                }}
            ></TuringoEditor>

            {mentions && mentions.length > 0 && isFeed && boards.length > 1 && (
                <Alert
                    style={{ marginTop: 16 }}
                    className={'blue'}
                    message={'Si etiquetas a personas que no son parte del espacio seleccionado, no serán notificadas.'}
                    type="info"
                    showIcon
                    closable
                />
            )}
        </>
    )
}

export { PostEditor }
