// Libraries

// React
import React from 'react'

// Antd
import { Typography } from 'antd'
import { EventView } from '@api/model/responses/event/EventView'
import { addWebp } from '@util/browser/addWebp'
import ImagePlaceholder from '@bundle/assets/general/placeholder-image.png'
import { EventDetails } from '@screens/community/event/EventDetails'
import { Colors } from '@util/color/Colors'
import { CalendarOutlined } from '@ant-design/icons'
import { Link, useParams } from 'react-router-dom'
import { Router } from '@Router'
import { BoardRouteParams } from '@typings/routing/Router'
import { ConditionalWrap } from '@components/content/conditional_wrap/ConditionalWrap'

// MARK: - Definition

namespace PreviewEvent {
    export interface Props {
        item: EventView
        clickeable?: boolean
    }
}

// MARK: - Implementation

const PreviewEvent: React.FC<PreviewEvent.Props> = (props: PreviewEvent.Props) => {
    const { item, clickeable } = props
    const { cm_pk } = useParams<BoardRouteParams>()
    if (!item) return <></>
    return (
        <ConditionalWrap condition={clickeable} wrap={(children) => <Link to={Router.Path.event_details({ cm_pk, e_pk: item.publicKey })}>{children}</Link>}>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    marginTop: 20,
                    color: 'var(--neutral-13)',
                }}
            >
                <div style={{ paddingTop: '40%', position: 'relative' }}>
                    {item.bannerUrl && (
                        <img
                            alt="example"
                            style={{ objectFit: 'cover', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                            src={addWebp(item.bannerUrl)}
                            loading="lazy"
                        />
                    )}
                    {!item.bannerUrl && (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'var(--image-empty-background)',
                            }}
                        >
                            <CalendarOutlined style={{ fontSize: 56, alignSelf: 'center', color: 'var(--neutral-6)' }} />
                        </div>
                    )}
                </div>
                <div
                    style={{
                        padding: 16,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: Colors('POSTED_POLL_BACKGROUND'),
                    }}
                >
                    <div>
                        <Typography.Paragraph style={{ marginBottom: 8 }} strong>
                            {item.title}{' '}
                        </Typography.Paragraph>
                        <EventDetails item={item} compact />
                    </div>
                </div>
            </div>
        </ConditionalWrap>
    )
}

export { PreviewEvent }
