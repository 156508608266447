// Libraries

// React
import React, { useContext, useState } from 'react'

// Antd
import { Button, Divider, Dropdown, MenuProps } from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { PostContext } from '../../Post'
import { MoreOne, Stretching } from '@icon-park/react'
import Title from 'antd/lib/typography/Title'
import { completeLesson } from '@state/actions'
import useBreakpoints from '@hooks/useBreakpoints'
import strings from '@resources/localization'
import { CatalogueItemModal } from '@screens/community/admin_panel/components/catalogue_item_modal/CatalogueItemModal'
import { OutsideLinkIcon } from '@components/icons/OutsideLinkIcon'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ensureHttp } from '@util/string/Functions'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'

// MARK: - Definition

namespace PostTitle {
    export interface Props {}
}

// MARK: - Implementation

const PostTitle: React.FC<PostTitle.Props> = (props: PostTitle.Props) => {
    const { cm_pk, b_pk, p_pk, isPanelAdmin, post } = useContext(PostContext)

    const kind = post?.board?.kind
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)
    const isAdmin = currentEntity?.privilige == 'superadmin'

    const breakpoints = useBreakpoints()
    const isSmall = breakpoints.isSmall

    const [showDelete, setShowDelete] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const dispatch = useAppDispatch()
    const completeLessonAction = () => {
        void dispatch(
            completeLesson({
                urlParams: {
                    cm_pk,
                    b_pk,
                    p_pk,
                },
                extraParams: {
                    scope: 'detail',
                },
            })
        )
    }

    const items: MenuProps['items'] = [
        {
            label: 'Completar lección',
            key: '0',
            onClick: completeLessonAction,
        },
    ]

    if(!post) return <></>

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px 24px' }}>
                {showEdit && (
                    <CatalogueItemModal
                        extra={() => {
                            setShowDelete(true)
                            setShowEdit(undefined)
                        }}
                        action="edit"
                        open={showEdit}
                        target={post}
                        onCancel={() => setShowEdit(false)}
                    />
                )}
                {showDelete && <CatalogueItemModal action="delete" open={showDelete} target={post} onCancel={() => setShowDelete(false)} />}

                <div
                    style={
                        isSmall
                            ? {
                                  display: 'grid',
                                  gridRowGap: '8px',
                                  gridTemplateColumns: '1fr',
                              }
                            : {
                                  display: 'flex',
                                  justifyContent: 'space-between',
                              }
                    }
                >
                    <span style={!isSmall ? { flex: '1 1 200px' } : {}}>
                        {post.kind != 'quiz' && kind != 'story' && kind != 'catalog' && !((cm_pk == 'alena' || cm_pk == 'usm') && kind == 'forum') && (
                            <div>
                                {strings.screens.boards[kind]?.post?.title?.singular} {post?.contentSequence + 1}
                            </div>
                        )}

                        {post.kind == 'quiz' && <div>{strings.screens.evaluation.title}</div>}
                        <div>
                            <Title level={4} style={{ wordWrap: 'break-word', marginBottom: 0 }}>
                                {post?.lesson?.title || post?.title}
                            </Title>
                        </div>
                    </span>
                </div>

                {isAdmin && kind == 'course' && (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <Button
                            type="default"
                            icon={<MoreOne size="14" style={{ display: 'flex', justifyContent: 'center' }} />}
                            style={{ height: 38, width: 38 }}
                        />
                    </Dropdown>
                )}

                {isPanelAdmin && kind == 'catalog' && (
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setShowEdit(true)
                        }}
                        type="default"
                    >
                        {!breakpoints.isMobile && strings.general.edit}
                    </Button>
                )}
            </div>

            {post.url && <Divider style={{ margin: 0 }}></Divider>}
            {post.url && (
                <div style={{ padding: '16px 24px' }}>
                    <TuringoButton icon={<OutsideLinkIcon />} block onClick={() => window.open(ensureHttp(post.url), '_blank').focus()}>
                        {strings.screens.admin.catalogues.items.goToSite}
                    </TuringoButton>
                </div>
            )}
        </div>
    )
}

export { PostTitle }
