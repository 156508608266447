import React from 'react'
import { useParams } from 'react-router'
import { boardMembers } from '@state/actions'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { useEffect } from 'react'
import { RootReducer } from '@state/reducers/Root'
import { BoardRouteParams } from 'src/typings/routing/Router'
import useBreakpoints from '@hooks/useBreakpoints'
import { UserProfileCard } from '@components/content/entity/user_profile_card/UserProfileCard'
import { EntityMemberSkeleton } from '@components/skeletons/EntityMemberSkeleton'
import InfiniteCards from '@components/infinite/InfiniteCards'

export const AllMembersCard = (props) => {
    const dispatch = useAppDispatch()
    const { cm_pk, b_pk } = useParams<BoardRouteParams>()

    const board = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk])
    const members = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk]?.members?.items)
    const membersState = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk]?.members?.listState)

    // RESPONSIVE
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.xxs || breakpoints.xs || breakpoints.sm
    const isTablet = breakpoints.md || breakpoints.mdl

    useEffect(() => {
        void dispatch(
            boardMembers({
                urlParams: {
                    cm_pk,
                    b_pk,
                },
                headerParams: {
                    page: 'first',
                },
            })
        )
    }, [])

    return (
        <InfiniteCards
            dataLength={!membersState ? undefined : Object.keys(members).length}
            loading={membersState ? membersState.loading : true}
            next={() => {
                void dispatch(
                    boardMembers({
                        urlParams: {
                            cm_pk,
                            b_pk,
                        },
                        headerParams: {
                            page: board.members?.listState?.pagination,
                        },
                    })
                )
            }}
            hasMore={(membersState?.pagination && membersState?.pagination != 'first') || membersState?.loading}
            loader={<EntityMemberSkeleton key={'loader'} />}
        >
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr',
                    rowGap: 16,
                    placeContent: 'center',
                    columnGap: 16,
                }}
            >
                {members &&
                    Object.keys(members).map((key, index) => {
                        return (
                            <UserProfileCard
                                options={{
                                    moniker: {
                                        show: true,
                                        title: true,
                                    },
                                    clickeable: true,
                                    mode: isMobile ? 'horizontal' : 'vertical',
                                }}
                                key={members[key].item.publicKey}
                                item={members[key].item}
                            />
                        )
                    })}
            </div>
        </InfiniteCards>
    )
}
