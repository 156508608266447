// Libraries

// React
import React, { useContext } from 'react'

// Antd
import { Checkbox, Form, Input, InputNumber, Select } from 'antd'
import { Property } from '@api/model/responses/community/CommunityView'
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem'
import { urlRules } from '@components/content/entity/community_header/components/community_edit_modal/CommunityEditModal'
import strings from '@resources/localization'
import { FormDeferredItem } from '@components/form/FormDeferredItem/FormDeferredItem'
import { API } from '@api/API'
import { UserLocationView } from '@api/model/responses/user/UserLocationView'
import { FormPhoneItem } from '@components/form/FormPhoneItem/FormPhoneItem'
import { useParams } from 'react-router'
import { UserRouteParams } from '@typings/routing/Router'
import { useAppSelector } from '@hooks/useStore'
import { UserView } from '@api/model/responses/user/UserView'
import { FormHelpItem } from '@components/form/HelpeableWrapper/FormHelpItem'
import { Validations } from '@components/form/Validations'
import { FormContext } from 'antd/es/form/context'
import dayjs, { Dayjs } from 'dayjs'
import DatePicker from '@components/form/DayJS/DatePicker'
import localeES from 'antd/es/date-picker/locale/es_ES'
import localeEN from 'antd/es/date-picker/locale/en_US'
require('dayjs/locale/es')
require('dayjs/locale/en')
// MARK: - Definition

namespace CustomFormItem {
    export interface Props {
        property: Property
        entity?: UserView
    }
}

const dateRestrictions = (restriction: 'only_past_dates' | 'only_future_dates' | 'any', current: Dayjs) => {
    if (restriction == 'only_past_dates') {
        return current > dayjs().add(-1, 'days')
    }
    if (restriction == 'only_future_dates') {
        return dayjs() > current
    }
    return undefined
}

// MARK: - Implementation

const CustomFormItem: React.FC<CustomFormItem.Props> = (props: CustomFormItem.Props) => {
    const language = useAppSelector((state) => state.ui.language)

    dayjs.locale(language)
    const locale = language == 'es' ? localeES : localeEN

    const { property, entity } = props
    const { form } = useContext(FormContext)

    if (property.type == 'array:opt:multi') {
        return (
            <FormHelpItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                key={property.id}
                help={property.hint}
                name={['memberProperties', property.id]}
                label={property.title}
            >
                <Select
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
                    dropdownStyle={{ zIndex: 2000 }}
                    mode="multiple"
                    allowClear
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                    options={property.options
                        ?.sort((a, b) => (property.optionOrder == 'az' ? a.title.localeCompare(b.title) : a.order - b.order))
                        .map((option) => {
                            return { label: option.title, value: option.title }
                        })}

           
                    style={{ width: '100%' }}
                />
            </FormHelpItem>
        )
    } else if (property.type == 'array:opt:single') {
        return (
            <FormHelpItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                key={property.id}
                help={property.hint}
                name={['memberProperties', property.id]}
                label={property.title}
            >
                <Select
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
                    dropdownStyle={{ zIndex: 2000 }}
                    allowClear
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    options={property.options
                        ?.sort((a, b) => (property.optionOrder == 'az' ? a.title.localeCompare(b.title) : a.order - b.order))
                        .map((option) => {
                            return { label: option.title, value: option.title }
                        })}
                    style={{ width: '100%' }}
                />
            </FormHelpItem>
        )
    } else if (property.type == 'string:email') {
        return (
            <FormEmailItem
                key={property.id}
                help={property.hint}
                name={['memberProperties', property.id]}
                label={property.title}
                customErrors={{
                    required: strings.general.fieldBlank,
                }}
                validations={['format', 'long', 'short', ...(property.required ? ['required' as Validations] : [])]}
            />
        )
    } else if (property.type == 'string') {
        return (
            <FormHelpItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                key={property.id}
                help={property.hint}
                name={['memberProperties', property.id]}
                label={property.title}
            >
                <Input.TextArea showCount maxLength={500} />
            </FormHelpItem>
        )
    } else if (property.type == 'string:title') {
        return (
            <FormHelpItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                key={property.id}
                help={property.hint}
                name={['memberProperties', property.id]}
                label={property.title}
            >
                <Input showCount maxLength={30} />
            </FormHelpItem>
        )
    } else if (property.type == 'string:url') {
        return (
            <FormHelpItem
                key={property.id}
                help={property.hint}
                name={['memberProperties', property.id]}
                label={property.title}
                rules={[...urlRules, ...(property.required ? [{ required: true, message: strings.general.fieldBlank }] : [])]}
            >
                <Input />
            </FormHelpItem>
        )
    } else if (property.type == 'geo:city:id') {
        return (
            <FormHelpItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                key={property.id}
                help={property.hint}
                name={['memberProperties', property.id]}
                label={property.title}
            >
                <FormDeferredItem
                    name={['memberProperties', property.id]}
                    onSearchDeferred={async (term: string) => {
                        const results = await API.citySearch({ searchParams: { term } })

                        const data = results['data']

                        if (!data || !data.cities) return 'wait'
                        return data.cities.map((city) => ({
                            key: `city-${city.id}`,
                            label: `${city.name}, ${city.countryName}`,
                            value: `${city.id}`,
                        }))
                    }}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
                    placeholder={strings.screens.profile.editModal.location.placeholder}
                    value={
                        entity?.memberProperties?.[property.id] && (entity?.memberProperties[property.id].value as UserLocationView).id
                            ? {
                                  key: `city-${(entity?.memberProperties[property.id].value as UserLocationView).id}`,
                                  label: `${(entity?.memberProperties[property.id].value as UserLocationView).name}, ${
                                      (entity?.memberProperties[property.id].value as UserLocationView).countryName
                                  }`,
                                  value: `${(entity?.memberProperties[property.id].value as UserLocationView).id}`,
                              }
                            : undefined
                    }
                />
            </FormHelpItem>
        )
    } else if (property.type == 'string:phone') {
        return (
            <FormPhoneItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                help={property.hint}
                defaultValue={entity?.memberProperties?.[property.id]?.value as string}
                key={property.id}
                label={property.title}
                name={['memberProperties', property.id]}
            />
        )
    } else if (property.type == 'integer') {
        return (
            <FormHelpItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                key={property.id}
                help={property.hint}
                name={['memberProperties', property.id]}
                label={property.title}
            >
                <InputNumber min={-999999999999999} pattern="[0-9]" inputMode="numeric" max={999999999999999} type="number" style={{ width: '100%' }} />
            </FormHelpItem>
        )
    } else if (property.type == 'boolean') {
        return (
            <FormHelpItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                key={property.id}
                help={property.hint}
                name={['memberProperties', property.id]}
                valuePropName="checked"
            >
                <Checkbox defaultChecked={form.getFieldValue(['memberProperties', property.id])}> {property.title}</Checkbox>
            </FormHelpItem>
        )
    } else if (property.type == 'string:YYYY-MM-DD') {
        return (
            <FormHelpItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                help={property.hint}
                getValueProps={(e: string) => ({
                    value: e ? dayjs(e) : '',
                })}
                name={['memberProperties', property.id]}
                label={property.title}
            >
                <DatePicker
                    disabledDate={(current) => {
                        return dateRestrictions(property.restriction, current)
                    }}
                    locale={locale}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
                    placeholder=""
                    style={{ width: '100%' }}
                    format={'DD/MM/YYYY'}
                />
            </FormHelpItem>
        )
    } else if (property.type == 'string:YYYY-MM-DD:maskyear') {
        return (
            <FormHelpItem
                rules={[{ required: property.required, message: strings.general.fieldBlank }]}
                help={property.hint}
                getValueProps={(e: string) => ({
                    value: e ? dayjs(e) : '',
                })}
                name={['memberProperties', property.id]}
                label={property.title}
            >
                <DatePicker
                    disabledDate={(current) => {
                        return dateRestrictions(property.restriction, current)
                    }}
                    locale={locale}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
                    placeholder=""
                    style={{ width: '100%' }}
                    format={'YYYY'}
                    picker="year"
                />
            </FormHelpItem>
        )
    } else return <></>
}

export { CustomFormItem }
