import React, { useContext } from 'react'
import { Button, Row, Tooltip } from 'antd'
import Icon from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { postReaction, postReactionDelete } from '@state/actions'
import { EntityModel } from '@model/index'
import style from './Style.scss'
import { PostReaction } from '@api/model/responses/post/PostCommentView'
import { debounce } from 'lodash'
import { PostCommentKind } from '@api/model/requests/Post'
import { Colors } from '@util/color/Colors'
import { PostRouteParams } from '@typings/routing/Router'
import { useParams } from 'react-router'
import strings from '@resources/localization'
import { LikeIcon } from '@components/icons/LikeIcon'
import { PostContext } from '../../Post'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'

interface Props {
    reaction?: PostReaction
    target_pk: string
    b_pk: string
    totalReactions: number
    parents: string[]
}

export const CommentReactAction = (props: Props) => {
    const { reaction, target_pk, b_pk, totalReactions, parents } = props
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)

    const dispatch = useAppDispatch()
    reaction?.content == 'like'
    const { mask } = useContext(PostContext)

    const { cm_pk, b_pk: br_pk, p_pk: pr_pk } = useParams<PostRouteParams>()
    const isFeed = !br_pk

    const scope = pr_pk ? 'detail' : 'all'

    const like = () => {
        void dispatch(
            postReaction({
                urlParams: { cm_pk, b_pk, target_pk: target_pk },
                bodyParams: {
                    ...(currentEntity.publicKey != mask.get?.publicKey && { mask: mask.get?.publicKey }),
                    kind: 'react',
                    content: 'like',
                    parents,
                },
                extraParams: { scope: scope, feed: isFeed },
            })
        )
    }

    const dislike = () => {
        void dispatch(
            postReactionDelete({
                urlParams: { cm_pk, b_pk, target_pk: target_pk },
                bodyParams: {
                    ...(currentEntity.publicKey != mask.get?.publicKey && { mask: mask.get?.publicKey }),
                    kind: 'react',
                    content: 'like',
                    parents,
                },
                extraParams: { scope: scope, feed: isFeed },
            })
        )
    }
    const likeDebounce = debounce(like, 0)
    const dislikeDebounce = debounce(dislike, 0)

    return (
        <TuringoButton
            style={{
                ...(reaction?.content == 'like' && { color: 'var(--ant-primary-color)' }),
            }}
            onClick={reaction?.content == 'like' ? dislikeDebounce : likeDebounce}
            shape="standard"
            size="small"
            type="reaction"
            icon={<LikeIcon filled={reaction?.content == 'like'} />}
        >
            {totalReactions > 0 && <div style={{ marginLeft: 2 }}>{totalReactions}</div>}
        </TuringoButton>
    )
}
