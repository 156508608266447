// Libraries

// React
import React from 'react'

// Antd
import {} from 'antd'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { EntityModel } from '@model/index'
import Title from 'antd/lib/typography/Title'
import useBreakpoints from '@hooks/useBreakpoints'

// MARK: - Definition

namespace EntityName {
    export interface Props {
        visibility?: string
        name: string
    }
}

// MARK: - Implementation

const EntityName: React.FC<EntityName.Props> = (props: EntityName.Props) => {
    const { visibility, name } = props
    const breakpoints = useBreakpoints()
    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: breakpoints.isSmall ? '0px 16px' : '0px 24px' }}>
            {visibility == 'private' && <LockOutlined style={{ fontSize: 16, marginRight: 8 }} />}
            {visibility == 'public' && <UnlockOutlined style={{ fontSize: 16, marginRight: 8 }} />}

            <Title level={4} style={{ marginBottom: 0 }}>
                {name}
            </Title>
        </div>
    )
}

export { EntityName }
