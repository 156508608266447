import React, { CSSProperties } from 'react'
import { Button, Row, Col } from 'antd'
import useBreakpoints from '@hooks/useBreakpoints'
import strings from '@resources/localization'
import { LinkColor } from '@components/buttons/link_color/LinkColor'
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'

interface ModalButton {
    action: () => void
    customText?: string | React.ReactNode
    loading?: boolean
    disabled?: boolean
}

interface BackButton extends ModalButton {
    kind: 'back' | 'link' | 'button'
}

interface Props {
    back?: BackButton
    primary?: ModalButton
    secondary?: ModalButton
    style?: CSSProperties
}

export const FooterModal = (props: Props) => {
    const { style, back, primary, secondary } = props

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const btns = (secondary || primary) && (
        <Row justify="end" style={style}>
            {secondary && secondary.action && (
                <Col span={isMobile ? 24 : null} order={isMobile ? 2 : 1}>
                    <TuringoButton
                        type={'default'}
                        expandInMobile
                        style={{ marginBottom: isMobile && back && back.action ? 16 : 0 }}
                        onClick={secondary.action}
                    >
                        {secondary.customText || strings.general.cancel}
                    </TuringoButton>
                </Col>
            )}

            {primary && primary.action && (
                <Col span={isMobile ? 24 : null} order={isMobile ? 1 : 2}>
                    <TuringoButton
                        disabled={primary.disabled}
                        loading={primary.loading}
                        expandInMobile
                        style={{ marginBottom: isMobile ? 16 : 0, marginLeft: isMobile ? 0 : 16 }}
                        type={'primary'}
                        onClick={primary.action}
                    >
                        {primary.customText || strings.general.continue}
                    </TuringoButton>
                </Col>
            )}
        </Row>
    )

    return (
        <div
            style={{
                backgroundColor: 'var(--background)',
                display: 'flex',
                justifyContent: back && back.action ? 'space-between' : 'end',
                alignItems: isMobile ? 'normal' : 'center',
                flexDirection: isMobile ? 'column-reverse' : 'initial',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : back && back.action ? 'space-between' : 'end',
                    width: '100%',
                    flexDirection: isMobile ? 'column-reverse' : 'row',
                }}
            >
                {back && back.kind == 'link' && back.action && (
                    <TuringoButton
                        type="link"
                        style={{ alignSelf: isMobile ? 'center' : 'center', marginTop: isMobile ? 26 : 0, whiteSpace: 'nowrap' }}
                        onClick={back.action}
                    >
                        {back.customText}
                    </TuringoButton>
                )}

                {back && back.kind == 'button' && back.action && (
                    <TuringoButton
                        disabled={back.disabled}
                        expandInMobile
                        type="link"
                        style={{ alignSelf: isMobile ? 'center' : 'center', whiteSpace: 'nowrap' }}
                        onClick={back.action}
                    >
                        {back.customText}
                    </TuringoButton>
                )}

                {back && back.kind == 'back' && <GoBackButton showText onClick={back.action} block={isMobile} />}

                {btns}
            </div>
        </div>
    )
}
