import React, { FC } from 'react'
import { Divider, Radio, Space } from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { LanguageType, setCurrentLanguage } from '@state/reducers/UserInterface/UserInterfaceReducer'
import { RootReducer } from '@state/reducers/Root'
import strings from '@resources/localization'
import useBreakpoints from '@hooks/useBreakpoints'
import { API } from '@api/API'

export const LanguageSelector: FC = () => {
    const language = useAppSelector((state) => state.ui.language)

    const setLanguage = (language: string) => {
        strings.setLanguage(language)
        dispatch(setCurrentLanguage(language as LanguageType))
        void API.analyticsSetting({ bodyParams: { language: language } })

    }

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const dispatch = useAppDispatch()
    return (
        <div>

            <Radio.Group style={{ ...(isMobile && { width: '100%' }) }} onChange={(e) => setLanguage(e.target.value)} value={language} className='turingo-radio-list' >
                <Space direction="vertical" size={0} >
                    <Radio value="es">{strings.screens.settings.sections.language.options.es}</Radio>
                    <Divider></Divider>
                    <Radio value="en">{strings.screens.settings.sections.language.options.en}</Radio>
                </Space>
            </Radio.Group>
        </div>
    )
}
