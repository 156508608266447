import React from 'react'

interface Props {
    seen?: boolean
    color?: string
}

export const Progress = (props: Props) => {
    const { seen, color } = props
    return <div style={{ backgroundColor: seen ? color : color, height: 4, borderRadius: 2, opacity: !seen ? 0.3 : 1 }} />
}
