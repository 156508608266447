import { useEffect } from 'react'
import { PostView } from '@api/model/responses/post/PostView'
import { KeyItemList } from '@state/types/ReducerTypes'
import { PostCommentView } from '@api/model/responses/post/PostCommentView'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { postManyReaction } from '@state/actions'
import { BoardRouteParams } from '@typings/routing/Router'
import { useParams } from 'react-router'
import { ListPostItem } from './ListPostItem'
import { Divider } from 'antd'

interface Props {
    items: KeyItemList<PostView, PostCommentView>
    divider?: boolean
    profile?: boolean
}

export const ListPost = (props: Props) => {
    const { items, divider } = props
    const { cm_pk, b_pk } = useParams<BoardRouteParams>()

    const isLogged = useAppSelector((state) => !!state.auth.authToken)
    const dispatch = useAppDispatch()
    const isFeed = !b_pk

    useEffect(() => {
        if (items) {
            const handleScroll = () => {
                const posts = document.querySelectorAll('.post')
                let postIdInView: string = null

                posts.forEach((post) => {
                    const rect = post.getBoundingClientRect()
                    if (rect.top <= window.innerHeight / 2 || rect.bottom <= window.innerHeight) {
                        postIdInView = post.getAttribute('data-post-id')
                    }
                })
                if (postIdInView) {
                    const actual_post = items[postIdInView]?.item
                    if (actual_post && !actual_post.interactions?.pinView) {
                        seeDebounced({ boardPk: actual_post.boardPk, postPk: actual_post.publicKey })
                    }
                }
            }

            window.addEventListener('scroll', handleScroll)

            return () => {
                window.removeEventListener('scroll', handleScroll)
            }
        }
    }, [items])

    const see = (
        posts: {
            boardPk: string
            postPk: string
        }[]
    ) => {
        if (isLogged) {
            void dispatch(
                postManyReaction({
                    urlParams: { cm_pk },
                    bodyParams: { postReferences: posts },
                    extraParams: { scope: 'all', feed: isFeed },
                })
            )
        }
    }

    // debounces a given function for wait ms
    // (aggregating up the arguments and then calling the fn function)
    function debounceAgg(fn, wait = 3000) {
        let timeout = null
        let update: {
            boardPk: string
            postPk: string
        }[] = []

        function processUpdate() {
            timeout = null
            fn(update)
            update = []
        }

        return (value: { boardPk: string; postPk: string }) => {
            if (
                !update.some((upt) => {
                    return upt.boardPk == value.boardPk && upt.postPk == value.postPk
                })
            )
                update = [...update, value]
            if (timeout == null) timeout = setTimeout(processUpdate, wait)
        }
    }

    const seeDebounced = debounceAgg(see)

    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, minmax(0, 1fr))', rowGap: 16, columnGap: 16 }}>
            {items &&
                Object.keys(items).map((key, index) => {
                    if (items[key]?.item) {
                        return (
                            <div className="post" key={items[key]?.item?.publicKey} data-post-id={items[key]?.item?.publicKey}>
                                <ListPostItem item={items[key].item} />
                                {divider && index != Object.keys(items).length - 1 && (
                                    <Divider
                                        style={{
                                            width: '92%',
                                            minWidth: '92%',
                                            margin: '0px 0px 0px 4%',
                                        }}
                                    ></Divider>
                                )}
                            </div>
                        )
                    }
                })}
        </div>
    )
}
