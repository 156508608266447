import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface SendIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const SendIcon = (props: SendIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill={props.color ? props.color : 'var(--neutral-13)'}>
                    <path d="M13.8572 6.78806L0.373439 0.0268788C0.318634 -0.000527072 0.255769 -0.00697546 0.196128 0.00753352C0.129935 0.0238993 0.0729229 0.0658302 0.0375754 0.124145C0.00222793 0.18246 -0.00857483 0.252407 0.00753262 0.318671L1.39701 5.99652C1.41797 6.08196 1.48083 6.15128 1.56465 6.17869L3.94546 6.99603L1.56626 7.81337C1.48244 7.84238 1.41958 7.91009 1.40023 7.99553L0.00753262 13.6814C-0.00697469 13.7411 -0.000526923 13.804 0.0268758 13.8572C0.0897408 13.9845 0.244485 14.0361 0.373439 13.9732L13.8572 7.25074C13.9072 7.22656 13.9474 7.18464 13.9732 7.13628C14.0361 7.00731 13.9845 6.85255 13.8572 6.78806Z" />
                </svg>
            )}
            {...props}
        />
    )
}
