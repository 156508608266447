// Libraries

// React
import React from 'react'

// Antd
import { Tooltip } from 'antd'
import ClassNames from '@util/style/ClassNames'
import Style from './ButtonPost.scss'
import { ChartHistogramTwo, PictureOne, VideoTwo } from '@icon-park/react'
import { FileTextOutlined } from '@ant-design/icons'
import strings from '@resources/localization'
import Paragraph from 'antd/lib/typography/Paragraph'
import useBreakpoints from '@hooks/useBreakpoints'
// MARK: - Definition

namespace ButtonPost {
    export interface Props {
        kind: 'image' | 'video' | 'poll' | 'attachment'
        disabled?: boolean
        onClick?: () => void
        hideTitle?: boolean
        style?: React.CSSProperties
    }
}

// MARK: - Implementation

const ButtonPost: React.FC<ButtonPost.Props> = (props: ButtonPost.Props) => {
    const { kind, disabled, onClick, hideTitle, style } = props
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    return (
        <Tooltip title={!disabled && strings.screens.boards.discussion.post.kind[kind].placeholder}>
            <div
                onClick={!disabled && onClick}
                style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'start' : 'center', fontSize: isMobile? 16:14, ...style }}
                className={ClassNames(Style.button, disabled ? Style.disabled_color : Style[`color_${kind}`])}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: isMobile? 14: 12,
                    }}
                    className={ClassNames(Style.circle, disabled ? Style.disabled_background : Style[`background_${kind}`])}
                >
                    {kind == 'image' && <PictureOne style={{ display: 'flex', alignItems: 'center' }}></PictureOne>}
                    {kind == 'video' && <VideoTwo style={{ display: 'flex', alignItems: 'center' }}></VideoTwo>}
                    {kind == 'poll' && <ChartHistogramTwo style={{ display: 'flex', alignItems: 'center' }}></ChartHistogramTwo>}
                    {kind == 'attachment' && <FileTextOutlined style={{ display: 'flex', alignItems: 'center' }}></FileTextOutlined>}
                </div>
                {kind == 'image' && !isMobile && !hideTitle && (
                    <Paragraph style={{ marginLeft: 4, color: 'inherit' }}>{strings.screens.boards.discussion.post.kind.image.name}</Paragraph>
                )}
                {kind == 'video' && !isMobile && !hideTitle && (
                    <Paragraph style={{ marginLeft: 4, color: 'inherit' }}>{strings.screens.boards.discussion.post.kind.video.name}</Paragraph>
                )}
                {kind == 'poll' && !isMobile && !hideTitle  && (
                    <Paragraph style={{ marginLeft: 4, color: 'inherit' }}>{strings.screens.boards.discussion.post.kind.poll.name}</Paragraph>
                )}
                {kind == 'attachment' && !isMobile && !hideTitle  && (
                    <Paragraph style={{ marginLeft: 4, color: 'inherit' }}>{strings.screens.boards.discussion.post.kind.attachment.name}</Paragraph>
                )}
            </div>
        </Tooltip>
    )
}

export { ButtonPost }
