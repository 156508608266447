import { API } from '@api/API'
import { CommunitySettingsDetailRouteParams } from '@typings/routing/Router'
import { Select, SelectProps, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

export interface DebounceCommunitySelectProps<ValueType> extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    debounceTimeout?: number
}

export function DebounceCommunitySelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number }>({
    ...props
}: DebounceCommunitySelectProps<ValueType>) {
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState<ValueType[]>([])
    const { cm_pk, item_pk } = useParams<CommunitySettingsDetailRouteParams>()

    useEffect(() => {
        setFetching(true)
        void API.communityList({}).then((response) => {
            const data = response.data
                .filter((board) => board.publicKey != item_pk)
                .map((team) => {
                    return { label: team.name, value: team.publicKey }
                })
            setOptions(data as ValueType[])
            setFetching(false)
        })
    }, [])
    if (fetching) {
        return (
            <div style={{ padding: 24, textAlign: 'center' }}>
                <Spin></Spin>
            </div>
        )
    }
    return <Select labelInValue notFoundContent={fetching ? <Spin size="small" /> : null} {...props} options={options} />
}
