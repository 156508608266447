// Libraries

// React
import React, { useContext } from 'react'

// Antd
import { CreatePostContext } from '../PostCreateProvider'
import { CreationScreen } from './creation/CreationScreen'
import { SpaceSelectionScreen } from './spaces/SpaceSelectionScreen'
import { TopicSelectionScreen } from './topics/TopicSelectionScreen'
import { PollScreen } from './poll/PollScreen'
import { AlanScreen } from './alan/AlanScreen'
import { CrossPostingScreen } from './creation/CrossPostingScreen'

// MARK: - Definition

namespace MainScreen {
    export interface Props {}
}

// MARK: - Implementation

const MainScreen: React.FC<MainScreen.Props> = (props: MainScreen.Props) => {
    const { screen } = useContext(CreatePostContext)

    const getScreen = () => {
        if (screen == 'creation') return <CrossPostingScreen />
        if (screen == 'board_selection' || screen == 'board_final') {
            return <SpaceSelectionScreen />
        }
        if (screen == 'topic_selection' || screen == 'topic_final') {
            return <TopicSelectionScreen />
        }
        if (screen == 'poll_creation') {
            return <PollScreen />
        }
        if (screen == 'alan') {
            return <AlanScreen />
        }
    }

    return getScreen()
}

export { MainScreen }
