// Libraries

// React
import React from 'react'

// Antd
import {} from 'antd'
import { ShareModal } from '@components/modals/ShareModal'
import { PasswordChangeModal } from '@components/modals/PasswordChangeModal'
import { PasswordCreateModal } from '@components/modals/PasswordCreateModal'
import { EmailCreateModal } from '@components/modals/EmailCreateModal'
import { ConflictModal } from '@components/modals/ConflictModal'
import { AuthExpiredModal } from '@components/modals/AuthExpiredModal'
import { SeeMoreModal } from '@components/modals/SeeMoreModal'
import { EmailChangeModal } from '@components/modals/EmailChangeModal'
import { LogoutModal } from '@components/modals/LogoutModal'
import { ChatModal } from '@components/modals/chat_modal/ChatModal'
import { WhatsAppModal } from '@components/modals/WhatsAppModal'
import { DeleteAccountModal } from '@components/modals/DeleteAccountModal'

// MARK: - Implementation

const GlobalModals = () => {
    return (
        <>
            <DeleteAccountModal />
            <WhatsAppModal />
            <ShareModal />
            <PasswordChangeModal />
            <PasswordCreateModal />
            <EmailCreateModal />
            <ConflictModal />
            <AuthExpiredModal />
            <SeeMoreModal />
            <EmailChangeModal />
            <LogoutModal />
            <ChatModal />
        </>
    )
}

export { GlobalModals }
