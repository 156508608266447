// Libraries

// React
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

// Antd
import { Card, Form } from 'antd'
import { PostCreationHeader } from './components/header/Header'
import strings from '@resources/localization'
import { CreatePostContext } from '../../PostCreateProvider'
import { PostCreationTopics } from './components/Topics'
import { PostCreationFooter } from './components/Footer'
import useBreakpoints from '@hooks/useBreakpoints'
import { PreviewLink } from './components/preview/link/PreviewLink'
import { PreviewPoll } from './components/preview/poll/PreviewPoll'
import { PreviewEvent } from './components/preview/event/PreviewEvent'
import { PostEditor } from './components/editor/PostEditor'
import { TuringoUpload } from '@components/form/TuringoUpload/TuringoUpload'

export type CrossPostingConsumer = 'turingo' | 'linkedin' | 'X'

// MARK: - Definition

namespace CreationScreen {
    export interface Props {
        consumer: CrossPostingConsumer
        currentTab: CrossPostingConsumer
    }
}

// MARK: - Implementation
export const CrossPostingContext = createContext<{
    consumer: CrossPostingConsumer
    currentTab: CrossPostingConsumer
}>(null)

const CreationScreen: React.FC<CreationScreen.Props> = (props: CreationScreen.Props) => {
    const { form, editable, changeAttachments } = useContext(CreatePostContext)
    const { consumer, currentTab } = props

    const kind = Form.useWatch(['form', consumer, 'kind'], form)
    const topics = Form.useWatch(['form', consumer, 'topics'], form)
    const attachments = Form.useWatch(['form', consumer, 'attachments'], form)

    const event = Form.useWatch(['form', consumer, 'event'], form)
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const innerRef = useRef<HTMLDivElement>()

    const [topicsHeight, setTopicsHeight] = useState(innerRef?.current?.clientHeight)
    useEffect(() => {
        setTopicsHeight(innerRef?.current?.clientHeight)
    }, [topics])

    useEffect(() => {}, [attachments])

    const [focused, setFocused] = useState(false)

    const onFocus = () => {
        if (!focused) {
            setFocused(true)
        }
    }
    return (
        <CrossPostingContext.Provider value={{ consumer: consumer, currentTab: currentTab }}>
            <div>
                <PostCreationHeader style={{ marginBottom: 16 }} />
                {consumer == 'turingo' && <PostCreationTopics innerRef={innerRef} style={{ marginBottom: 16 }} />}

                <div
                    style={{
                        position: 'relative',
                        overflowY: 'auto',
                        maxHeight: isMobile ? `calc(100dvh - 240px - ${topicsHeight}px)` : `calc(100dvh - 272px - ${topicsHeight}px)`,
                        ...(isMobile && {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: `calc(100dvh - 240px - ${topicsHeight}px)`,
                        }),
                    }}
                >
                    <PostEditor onFocus={onFocus} topicsHeight={topicsHeight} />

                    <PreviewLink />

                    {kind == 'poll' && <PreviewPoll />}
                    {kind == 'event' && <PreviewEvent item={event} />}

                    {(kind == 'image' || kind == 'video' || kind == 'attachment') && (
                        <Card style={{ marginTop: 16 }} bodyStyle={{ padding: 8 }}>
                            <TuringoUpload
                                label={strings.screens.boards.discussion.post.kind[kind].name}
                                isEditing={editable}
                                name={['form', consumer, 'attachments']}
                                uploadArea={{
                                    showDescription: true,
                                }}
                                rules={{
                                    maxFiles: kind == 'image' ? (consumer == 'X' ? 4 : 5) : 1,
                                    maxSize: kind == 'video' ? 100 : 10,
                                }}
                                fileListDefault={attachments}
                                onChange={(fileList) => {
                                    form.setFieldValue(['form', consumer, 'attachments'], fileList)

                                    if (consumer != 'turingo' && currentTab != 'turingo' && fileList && fileList.length > 0) {
                                        changeAttachments.set(true)
                                    }
                                    if (consumer == 'turingo' && !changeAttachments.get) {
                                        form.setFieldValue(['form', 'linkedin', 'attachments'], fileList)
                                        form.setFieldValue(['form', 'X', 'attachments'], fileList)
                                    }
                                }}
                                type={kind}
                                style={{ marginBottom: 0 }}
                                onClose={() => {
                                    form.setFieldValue(['form', consumer, 'kind'], 'basic')
                                    form.setFieldValue(['form', consumer, 'attachments'], undefined)

                                    if (consumer != 'turingo' && currentTab != 'turingo') {
                                        changeAttachments.set(true)
                                    }
                                    if (consumer == 'turingo' && !changeAttachments.get) {
                                        form.setFieldValue(['form', 'linkedin', 'kind'], 'basic')
                                        form.setFieldValue(['form', 'linkedin', 'attachments'], undefined)

                                        form.setFieldValue(['form', 'X', 'kind'], 'basic')
                                        form.setFieldValue(['form', 'X', 'attachments'], undefined)
                                    }
                                }}
                            />
                        </Card>
                    )}
                </div>

                <div style={{ marginBottom: 0, ...(isMobile && { position: 'fixed', bottom: 0, left: 0, width: '100%' }) }}>
                    <PostCreationFooter focused={focused}></PostCreationFooter>
                </div>
            </div>
        </CrossPostingContext.Provider>
    )
}

export { CreationScreen }
