// Libraries

// React
import React, { useContext, useState } from 'react'

// Antd
import { Button, Divider, Row } from 'antd'
import { PostContext } from '../../Post'
import { LikeIcon } from '@components/icons/LikeIcon'
import strings from '@resources/localization'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import useBreakpoints from '@hooks/useBreakpoints'
import { postReaction, postReactionDelete } from '@state/actions'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { debounce } from 'lodash'
import { ShareAltOutlined } from '@ant-design/icons'
import { Router } from '@Router'
import { API } from '@api/API'
import { useParams } from 'react-router'
import { PostRouteParams } from '@typings/routing/Router'
import { ReactionModal } from '../ReactionModal'
import { PostViewReadModal } from '../PostViewReadModal'
import { PostCommentsTag } from '../PostCommentsTag'
import { EyeOutlined } from '@ant-design/icons'
import { PostReactionsTag } from '../PostReactionsTag'
import { LinkColor } from '@components/buttons/link_color/LinkColor'
import { ButtonSelect } from 'src/turingo_components/buttons/button_select/ButtonSelect'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'
import { CommentIcon } from '@components/icons/CommentIcon'

// MARK: - Definition

namespace PostFooter {
    export interface Props {
        setShowCommentArea: React.Dispatch<React.SetStateAction<boolean>>
        setShowMasks: (value: boolean) => void
        commentInputRef: React.MutableRefObject<{ getFocus: () => void }>
    }
}

// MARK: - Implementation

const PostFooter: React.FC<PostFooter.Props> = (props: PostFooter.Props) => {
    const { setShowCommentArea, setShowMasks, commentInputRef } = props

    const { mask, post, isAdmin } = useContext(PostContext)

    const isLogged = useAppSelector((state) => !!state.auth.authToken)

    const dispatch = useAppDispatch()
    const isLiked = post.interactions?.react == 'like'
    const isRead = post.interactions?.pinRead

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const currentEntity = useAppSelector((state) => state.auth.currentEntity)

    const { cm_pk, b_pk: br_pk, p_pk } = useParams<PostRouteParams>()
    const isFeed = !br_pk
    const scope = p_pk ? 'detail' : 'all'

    const [showReactionModal, setShowReactionModal] = useState(false)
    const [showReadStatModal, setReadStatModal] = useState(false)

    const like = () => {
        if (isLogged) {
            void dispatch(
                postReaction({
                    urlParams: { cm_pk, b_pk: post.boardPk, target_pk: post.publicKey },
                    bodyParams: {
                        ...(currentEntity.publicKey != mask.get.publicKey && { mask: mask.get.publicKey }),
                        kind: 'react',
                        content: 'like',
                    },
                    extraParams: { scope: scope, feed: isFeed },
                })
            )
        } else {
            dispatch(modalAction({ modal: 'see_more', state: true }))
        }
    }
    const dislike = () => {
        void dispatch(
            postReactionDelete({
                urlParams: { cm_pk, b_pk: post.boardPk, target_pk: post.publicKey },
                bodyParams: {
                    ...(currentEntity.publicKey != mask.get.publicKey && { mask: mask.get.publicKey }),
                    kind: 'react',
                    content: 'like',
                },
                extraParams: { scope: scope, feed: isFeed },
            })
        )
    }

    const focus = () => {
        commentInputRef?.current?.getFocus()
    }

    const onComment = () => {
        if (isLogged) {
            setShowCommentArea(true)
            setTimeout(focus, 100)
        } else {
            dispatch(modalAction({ modal: 'see_more', state: true }))
        }
    }

    const onShowComments = () => {
        if (isLogged) {
            setShowCommentArea((prev) => {
                return !prev
            })
        } else {
            dispatch(modalAction({ modal: 'see_more', state: true }))
        }
    }

    const onShowReactions = () => {
        if (isLogged) {
            setShowReactionModal(true)
        } else {
            dispatch(modalAction({ modal: 'see_more', state: true }))
        }
    }

    const likeDebounce = debounce(like, 0)
    const dislikeDebounce = debounce(dislike, 0)

    const openShareModal = () => {
        dispatch(
            modalAction({
                modal: 'share',
                active: true,
                link: window.location.origin + Router.Path.community_post_details({ cm_pk: cm_pk, b_pk: post.boardPk, p_pk: post.publicKey }),
                referenceCommunity: cm_pk,
                referenceBoard: post.boardPk,
                referencePost: post.publicKey,
                e_kind: 'community',
                kind: 'discussion.post',
            })
        )

        void API.analyticsModal({ bodyParams: { modal: 'modal.share', state: 'open' } })
    }

    const read = () => {
        if (isLogged) {
            void dispatch(
                postReaction({
                    urlParams: { cm_pk, b_pk: post.boardPk, target_pk: post.publicKey },
                    bodyParams: {
                        ...(currentEntity.publicKey != mask.get.publicKey && { mask: mask.get.publicKey }),
                        kind: 'pin_read',
                    },
                    extraParams: { scope: scope, feed: isFeed },
                })
            )
        } else {
            dispatch(modalAction({ modal: 'see_more', state: true }))
        }
    }

    return (
        <>
            {(post.totalReactions > 0 || post.totalComments > 0 || (post.totalViews > 0 && isAdmin)) && (
                <Row justify="space-between" style={{ padding: '0px 24px 16px 24px', marginTop: 16 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PostReactionsTag totalReactions={post.totalReactions} onClick={onShowReactions} />

                        {post.pin != 'mandatory' && isAdmin && (
                            <>
                                {post.totalReactions > 0 && <div style={{ marginLeft: 8, marginRight: 8, lineHeight: '17px' }}>{'•'}</div>}
                                <EyeOutlined style={{ marginRight: 4 }} />
                                <div style={{ lineHeight: '17px' }}>{post.totalViews}</div>
                            </>
                        )}
                    </div>
                    <PostCommentsTag totalComments={post.totalComments} onClick={onShowComments} />
                </Row>
            )}
            {(post.totalReactions > 0 || post.totalComments > 0 || post.kind == 'basic' || (post.totalViews > 0 && isAdmin)) && (
                <div style={{ padding: breakpoints.isMobile ? '0px 16px' : '0px 24px' }}>
                    <Divider style={{ margin: 0 }}></Divider>
                </div>
            )}

            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: isMobile ? 16 : 24 }}>
                {showReactionModal && (
                    <ReactionModal kind="react" target={post} open={showReactionModal} onCancel={() => setShowReactionModal(false)}></ReactionModal>
                )}
                {showReadStatModal && (
                    <PostViewReadModal target={post} kind="pin_view" open={showReadStatModal} onCancel={() => setReadStatModal(false)}></PostViewReadModal>
                )}

                {isAdmin && <ButtonSelect onClick={() => setShowMasks(true)} />}
                <div
                    style={{
                        display: 'grid',
                        padding: isMobile ? '16px' : '16px 24px 16px 16px',
                        flexGrow: 1,
                        gap: breakpoints.lg ? 4: 16,
                        gridTemplateColumns: isLogged ? '1fr 1fr 1fr' : '1fr 1fr',
                    }}
                >
                    <TuringoButton
                        style={{
                            ...(isLiked && { color: 'var(--ant-primary-color)' }),
                        }}
                        block
                        type={'reaction'}
                        shape={(breakpoints.xs || breakpoints.xxs) ? 'vertical' : 'standard'}
                        onClick={isLiked ? () => dislikeDebounce() : () => likeDebounce()}
                        icon={<LikeIcon filled={isLiked} />}
                    >
                        {strings.general.like}
                    </TuringoButton>

                    <TuringoButton block type={'reaction'} shape={(breakpoints.xs || breakpoints.xxs) ? 'vertical' : 'standard'} onClick={onComment} icon={<CommentIcon />}>
                        {strings.general.comments.action}
                    </TuringoButton>

                    {isLogged && (
                        <TuringoButton block type={'reaction'} shape={(breakpoints.xs || breakpoints.xxs) ? 'vertical' : 'standard'} onClick={openShareModal} icon={<ShareAltOutlined />}>
                            {strings.general.share}
                        </TuringoButton>
                    )}
                </div>
            </div>

            {post.pin == 'mandatory' && (
                <div style={{ padding: '0px 24px 16px 24px' }}>
                    <Divider style={{ marginTop: 0 }}></Divider>

                    <Button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} disabled={isRead} type="primary" onClick={read} block>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <EyeOutlined style={{ display: 'flex', marginRight: 4 }} color={isRead ? undefined : 'white'} />{' '}
                            {isRead ? strings.screens.markasread.actions.read : strings.screens.markasread.actions.unread}
                        </div>
                    </Button>
                </div>
            )}
            {post.pin == 'mandatory' && isAdmin && (
                <div style={{ padding: '0px 24px 16px 24px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ lineHeight: '17px' }}>
                            {post.totalViews == 1
                                ? strings.screens.markasread.details.viewers.singular
                                : strings.formatString(strings.screens.markasread.details.viewers.plural, post.totalViews?.toString() || '0')}
                        </div>

                        <div style={{ marginLeft: 8, marginRight: 8, lineHeight: '17px' }}>{'•'}</div>
                        <div style={{ lineHeight: '17px' }}>
                            {post.totalReads == 1
                                ? strings.screens.markasread.details.readers.singular
                                : strings.formatString(strings.screens.markasread.details.readers.plural, post.totalReads?.toString() || '0')}
                        </div>
                        <div style={{ marginLeft: 8, marginRight: 8, lineHeight: '17px' }} />

                        <LinkColor
                            onClick={() => {
                                setReadStatModal(true)
                            }}
                        >
                            {strings.general.moreDetails}
                        </LinkColor>
                    </div>
                </div>
            )}
        </>
    )
}

export { PostFooter }
