// Modules
import React, { useEffect, useState } from 'react'
import { Form, Input, Modal, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import strings from '@resources/localization'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { RootReducer } from '@state/reducers/Root'
import { authAddEmail, communityChatExists } from '@state/actions'
import { useRequestState } from '@api/requests/AsyncRequests'
import { useHistory } from 'react-router'
import { Router } from '@Router'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { UserMentionView } from '@api/model/responses/user/Mentions'
import { API } from '@api/API'
import { UserView } from '@api/model/responses/user/UserView'
import { v4 } from 'uuid'
import { UserListItem } from '@components/content/entity/user_list_item/UserListItem'
import { ChatListSkeleton } from '@components/skeletons/ChatSkeleton/components/ChatListSkeleton'
import useBreakpoints from '@hooks/useBreakpoints'
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed'
import { ChatRoomView } from '@api/model/responses/chat/ChatView'

export const ChatModal = () => {
    const chatModal = useAppSelector((state) => state.modal.chat)

    const cm_pk = useAppSelector((state) => state.community.currentCommunity)

    const [loading, setLoading] = useState(false)
    const [suggestions, setSuggestions] = useState<UserMentionView[]>(undefined)
    const [timer, setTimer] = useState<NodeJS.Timeout>()

    const currentEntity = useAppSelector((state) => state.auth.currentEntity)

    const dispatch = useAppDispatch()

    const history = useHistory<{ roomPk: string }>()

    const closeModal = () => {
        dispatch(modalAction({ modal: 'chat', state: false }))
    }
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const fetchFirstPage = () => {
        setLoading(true)
        void API.communityDirectory({
            urlParams: { cm_pk },
        }).then((response) => {
            if (response.data.length > 0) {
                const data = response.data
                setLoading(false)
                setSuggestions(data)
            } else {
                setLoading(false)
                setSuggestions([])
            }
        })
    }

    useEffect(() => {
        if (chatModal) {
            fetchFirstPage()
        } else {
            setSuggestions(undefined)
        }
    }, [chatModal])

    const callAPI = (wordText: string) => {
        if (wordText.length >= 3) {
            setLoading(true)
            void API.userChattable({
                urlParams: { cm_pk },
                searchParams: {
                    term: wordText,
                },
            }).then((response) => {
                if (response.data.length > 0) {
                    const data = response.data
                    setLoading(false)
                    setSuggestions(data)
                } else {
                    setLoading(false)
                    setSuggestions([])
                }
            })
        } else {
            fetchFirstPage()
        }
    }

    const onChange = (e) => {
        if (timer) {
            clearTimeout(timer)
        }

        setLoading(true)
        const timerRef = setTimeout(() => callAPI(e.target.defaultValue), 1100)
        setTimer(timerRef)
    }

    const afterCreate = (result: { data: ChatRoomView }) => {
        history.push(Router.Path.community_chat({ cm_pk }), { roomPk: result.data.publicKey })
        closeModal()
    }
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))

    const efterError = async (e, params) => {
        if (e.error == 'application.chat.room.notfound') {
            await delay(100)

            closeModal()
            history.push(Router.Path.community_chat({ cm_pk }), { roomPk: params.extraParams.localId })

            /* dispatch(
                communityChatCreate({
                    urlParams: { cm_pk },
                    bodyParams: params.bodyParams,
                    options: {
                        then: {
                            action: afterCreate,
                        },
                    },
                })
            ) */
        }
    }

    const onClick = (user: UserView) => {
        dispatch(
            communityChatExists({
                urlParams: {
                    cm_pk,
                },
                bodyParams: { users: [user.publicKey] },
                extraParams: {
                    localId: v4(),
                    users: [
                        { ...user, roomAccess: 'member', roomState: 'invited' },
                        { ...currentEntity, roomAccess: 'owner', roomState: 'present' },
                    ],
                },
                options: {
                    then: {
                        action: afterCreate,
                        error: efterError,
                    },
                },
            })
        )
    }

    return (
        <TuringoModal bodyStyle={{ padding: 0 }} open={chatModal} onCancel={closeModal} width={650} footer={null}>
            <div style={{ padding: isMobile ? 16 : '16px 24px' }}>
                <Typography.Paragraph strong style={{ fontSize: isMobile ? 18 : 16, marginBottom: 16, marginRight: 24 }}>
                    {strings.screens.chat.room.create.title}
                </Typography.Paragraph>

                <p>{strings.screens.chat.room.create.description}</p>
                <Input.Search autoFocus onChange={onChange} placeholder={strings.screens.chat.search} />
            </div>
            {suggestions && (
                <div style={{ maxHeight: 320, overflow: 'auto' }} className="scrollStyle">
                    {suggestions && suggestions.length > 0 && !loading && (
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 8, paddingBottom: 16 }}>
                            {suggestions.map((user) => {
                                return <UserListItem key={user.publicKey} item={user} onClick={() => onClick(user)} />
                            })}
                        </div>
                    )}

                    {suggestions && suggestions.length == 0 && !loading && (
                        <div style={{ padding: isMobile ? 16 : '16px 24px' }}>
                            <AlertDashed message={strings.general.noResults} showIcon={false} />
                        </div>
                    )}
                </div>
            )}

            {loading && (
                <div style={{ padding: 16 }}>
                    <ChatListSkeleton nElements={5}></ChatListSkeleton>
                </div>
            )}
        </TuringoModal>
    )
}
