// Libraries

// React
import React, { CSSProperties } from 'react'

// Antd
import Paragraph from 'antd/lib/typography/Paragraph'

import useBreakpoints from '@hooks/useBreakpoints'
import { Icon } from '@icon-park/react/lib/runtime'
import { Dropdown, MenuProps, Space } from 'antd'
import { TuringoButton, TuringoButtonType } from 'src/turingo_components/buttons/button/TuringoButton'
import { Down } from '@icon-park/react'

// MARK: - Definition

namespace SettingItem {
    export interface Props {
        style?: CSSProperties
        iconPark?: ({ error }: any) => JSX.Element
        iconAnt?: ({ error }: any) => JSX.Element
        icon?: ({ error }: any) => JSX.Element
        action?: {
            disabled?: boolean
            dropdownItems?: MenuProps
            btnType?: TuringoButtonType
            icon?: Icon
            onClick: () => void
            title: string
            loading?: boolean
        }[]
        buttonAlign?: string
        title: string | React.ReactNode
        gap?: number
        description?: string
        descriptionStyle?: React.CSSProperties
    }
}

// MARK: - Implementation

const SettingItem: React.FC<SettingItem.Props> = (props: SettingItem.Props) => {
    const { buttonAlign = 'flex-end', gap = 16, style } = props
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const items = isMobile ? props.action?.reverse() : props.action
    return (
        <div style={{ ...(!isMobile && { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }), ...style }}>
            <div style={{ marginRight: isMobile ? 0 : 24 }}>
                <div style={{ display: 'flex', marginBottom: isMobile ? 16 : 0, alignItems: isMobile ? 'start' : 'center' }}>
                    {/*   {props.iconPark && <props.iconPark size={16} style={{ marginRight: 4, alignItems: 'center', display: 'flex' }} />}
                    {props.iconAnt && <props.iconAnt style={{ fontSize: 16, marginRight: 8, paddingTop: isMobile ? 4 : 0 }} />}
                    {props.icon && <props.icon height={16} width={16} style={{ fontSize: 16, marginRight: 8, paddingTop: isMobile ? 4 : 0 }} />}

 */}
                    {props.icon && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 16,
                                height: 24,
                                minWidth: 16,
                                fontSize: 16,
                                marginRight: 8,
                            }}
                        >
                            <props.icon />
                        </div>
                    )}

                    <Paragraph style={{ marginBottom: 0, fontWeight: props.description ? 500 : 400 }}>{props.title}</Paragraph>
                </div>
                {props.description && (
                    <Paragraph style={{ marginTop: isMobile ? 0 : gap, marginBottom: isMobile ? gap : 0, ...props.descriptionStyle }}>
                        {props.description}
                    </Paragraph>
                )}
            </div>
            <div style={{ display: 'grid', gap: 16, gridAutoFlow: isMobile ? 'row' : 'column', alignSelf: buttonAlign }}>
                {items?.map((item, i) => {
                    if (item.dropdownItems) {
                        return (
                            <Dropdown key={`dropdown_${i}`} menu={item.dropdownItems} trigger={['click']}>
                                <TuringoButton
                                    disabled={item.disabled}
                                    key={`btn_${i}`}
                                    icon={item.icon ? <item.icon /> : undefined}
                                    type={item.btnType || 'primary'}
                                >
                                    <Space>
                                        {item.title}
                                        <Down />
                                    </Space>
                                </TuringoButton>
                            </Dropdown>
                        )
                    }
                    return (
                        <TuringoButton
                            expandInMobile
                            loading={item.loading}
                            disabled={item.disabled}
                            key={i}
                            icon={item.icon ? <item.icon /> : undefined}
                            type={item.btnType || 'primary'}
                            onClick={item.onClick}
                        >
                            {item.title}
                        </TuringoButton>
                    )
                })}
            </div>
        </div>
    )
}

export { SettingItem }
