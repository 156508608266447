// Libraries

// React
import React, { CSSProperties } from 'react'

// Antd
import { useHistory } from 'react-router'
import strings from '@resources/localization'
import { ArrowLeftIcon } from '@components/icons/ArrowLeftIcon'
import { TuringoButton, TuringoButtonSize } from 'src/turingo_components/buttons/button/TuringoButton'

// MARK: - Definition

namespace GoBackButton {
    export interface Props {
        size?: TuringoButtonSize
        style?: CSSProperties
        type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed'
        onClick?: () => void
        showText?: boolean
        block?: boolean
    }
}

// MARK: - Implementation

const GoBackButton: React.FC<GoBackButton.Props> = (props: GoBackButton.Props) => {
    const { showText = true, onClick, style, type = 'text', block, size } = props
    const history = useHistory()
    const goBack = () => {
        if (onClick) {
            onClick()
        } else {
            history.goBack()
        }
    }

    return (
        <TuringoButton
            size={size}
            shape={showText ? 'standard': 'square'}
            block={block}
            type={type}
            onClick={goBack}
            icon={<ArrowLeftIcon />}
            style={{
                ...style,
            }}
        >
            {showText && <span>{strings.screens.auth.signup.goBack}</span>}
        </TuringoButton>
    )
}

export { GoBackButton }
