import React from 'react'
import { Alert, Skeleton, Divider, Card, Row, Col, Avatar } from 'antd'
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout'
import useBreakpoints from '@hooks/useBreakpoints'
import { useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import { Colors } from '@util/color/Colors'
import { PostSkeleton } from './components/PostSkeleton/PostSkeleton'
import { HeaderSkeleton } from './components/HeaderSkeleton/HeaderSkeleton'

interface IProps {
    error?: boolean
    mentors?: boolean
}

// TODO: Accept number prop to determine how many Skeleton items to have
export const CommunityScreenSkeleton = (props: IProps) => {
    const { error, mentors } = props

    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme === 'dark'
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const isSmall = breakpoints.xs || breakpoints.sm

    const errorAlert = error ? <Alert type="error" message="An Error occcured fetching data, please try again..." className="listings-skeleton__alert" /> : null
    return (
        <div className="listings-skeleton">
            {errorAlert}

            <ContentLayout
                left={
                    <div style={{ display: 'grid', rowGap: isMobile ? 16 : 24 }}>
                        <HeaderSkeleton />
                    
                        <Card bordered={false}>
                            <div style={{ display: 'flex', alignContent: 'center', marginBottom: 16 }}>
                                <Skeleton.Avatar active size={48} shape={'circle'} style={{ marginRight: 18 }} />
                                <Skeleton title active paragraph={null} />
                            </div>

                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', columnGap: '16px' }}>
                                <Skeleton title active paragraph={null} />
                                <Skeleton title active paragraph={null} />
                                <Skeleton title active paragraph={null} />
                                <Skeleton title active paragraph={null} />
                            </div>
                        </Card>

                        <Card bordered={false}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Skeleton title active paragraph={null} style={{ maxWidth: 250 }} />
                                <Skeleton title active paragraph={null} style={{ maxWidth: 80 }} />
                            </div>
                        </Card>

                        <PostSkeleton />
                        <PostSkeleton />
                    </div>
                }
                right={
                    <Row gutter={[isSmall ? 0 : 16, isMobile ? 16 : 24]}>
                        <Col span={24}>
                            <Card bordered={false} title={<Skeleton title active paragraph={null} style={{ maxWidth: 120 }} />}>
                                <Skeleton title={false} active paragraph={{ rows: 3 }} />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card bordered={false} title={<Skeleton title active paragraph={null} style={{ maxWidth: 120 }} />}>
                                <Skeleton title={false} active paragraph={{ rows: 3 }} />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card bordered={false} title={<Skeleton title active paragraph={null} style={{ maxWidth: 120 }} />}>
                                <Skeleton title={false} active paragraph={{ rows: 3 }} />
                            </Card>
                        </Col>
                    </Row>
                }
            />
        </div>
    )
}
