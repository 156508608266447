// Libraries

// React
import React from 'react'

// Antd
import {} from 'antd'
import { useAppSelector } from '@hooks/useStore'
import { Redirect } from 'react-router'
import { Router } from '@Router'
import { CommunityView } from '@api/model/responses/community/CommunityView'

// MARK: - Definition

namespace RedirectComponent {
    export interface Props {
        to?: string
    }
}

export const getFirstCommunity = (
    to: string,
    communities: {
        items: CommunityView[]
        loading: boolean
    }
) => {
    const copyCommunities = communities.items ? [...communities.items] : []
    const communitiesOrdered = copyCommunities?.sort((a, b) => a.name.localeCompare(b.name))

    if (to) {
        return to
    }

    if (communitiesOrdered?.length == 1) {
        return communitiesOrdered[0].publicKey
    } else if (communitiesOrdered?.length > 1) {
        const communityWithoutTuringo = communitiesOrdered.filter((c) => c.publicKey != 'turingo')

        if (communityWithoutTuringo.length == 0) {
            return 'turingo'
        } else {
            return communityWithoutTuringo[0].publicKey
        }
    }
}

export const redirectFunction = (
    to: string,
    communities: {
        items: CommunityView[]
        loading: boolean
    }
) => {
    const copyCommunities = communities.items ? [...communities.items] : []
    const communitiesOrdered = copyCommunities?.sort((a, b) => a.name.localeCompare(b.name))

    if (to) {
        return to
    }

    if (communitiesOrdered?.length == 1) {
        return Router.Path.community_home({ cm_pk: communitiesOrdered[0].publicKey })
    } else if (communitiesOrdered?.length > 1) {
        const communityWithoutTuringo = communitiesOrdered.filter((c) => c.publicKey != 'turingo')

        if (communityWithoutTuringo.length == 0) {
            return Router.Path.community_home({ cm_pk: 'turingo' })
        } else {
            return Router.Path.community_home({ cm_pk: communityWithoutTuringo[0].publicKey })
        }
    }
}

// MARK: - Implementation

export const RedirectComponent: React.FC<RedirectComponent.Props> = (props: RedirectComponent.Props) => {
    const communities = useAppSelector((state) => state.auth.communities)
    const copyCommunities = communities.items ? [...communities.items] : []
    const communitiesOrdered = copyCommunities?.sort((a, b) => a.name.localeCompare(b.name))
    const { to } = props

    if (to) {
        return <Redirect to={to} />
    }

    if (communitiesOrdered?.length == 1) {
        return <Redirect to={{ pathname: Router.Path.community_home({ cm_pk: communitiesOrdered[0].publicKey }) }} />
    } else if (communitiesOrdered?.length > 1) {
        const communityWithoutTuringo = communitiesOrdered.filter((c) => c.publicKey != 'turingo')

        if (communityWithoutTuringo.length == 0) {
            return <Redirect to={{ pathname: Router.Path.community_home({ cm_pk: 'turingo' }) }} />
        } else {
            return <Redirect to={{ pathname: Router.Path.community_home({ cm_pk: communityWithoutTuringo[0].publicKey }) }} />
        }
    }

    return <></>
}
