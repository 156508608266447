import { API } from '@api/API'
import { PostCreationModel } from '@api/model/requests/Post'
import { UserMentionView } from '@api/model/responses/user/Mentions'
import { generateRandomString } from '@hooks/oauth/useOAuth'
import useScrollPosition from '@hooks/useScrollPosition'
import { PostRouteParams } from '@typings/routing/Router'
import { getLastMention, insertMention } from '@util/string/Functions'
import ClassNames from '@util/style/ClassNames'
import { isEqual, differenceWith, isEmpty } from 'lodash'
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useWindowSize } from 'react-use'
import { Autocomplete } from './AutoComplete'
import style from './TuringoEditor.scss'
import linkifyIt, { LinkifyIt } from 'linkify-it'
import tlds from 'tlds'
import Picker from '@emoji-mart/react'
import { SlightlySmilingFace } from '@icon-park/react'
import useBreakpoints from '@hooks/useBreakpoints'
import { Alert, Button, Form, Tooltip } from 'antd'
import { SocketContext } from 'src/SocketComponent'
import { AlanIcon } from '@components/icons/AlanIcon'
import { useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import { CreatePostContext } from '@components/content/posts/post_modal/PostCreateProvider'
import { LoadingOutlined } from '@ant-design/icons'
import { CrossPostingContext } from '@components/content/posts/post_modal/screens/creation/CreationScreen'
import { createPortal } from 'react-dom'

const linkify: LinkifyIt = linkifyIt().tlds(tlds)

const isArrayEqual = function (x, y) {
    return isEmpty(differenceWith(x, y, isEqual))
}

const getTextSelection = function (editor) {
    const selection = window.getSelection()

    if (selection != null && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)

        return {
            start: getTextLength(editor, range.startContainer, range.startOffset),
            end: getTextLength(editor, range.endContainer, range.endOffset),
        }
    } else return null
}

const getTextLength = function (parent, node: Node, offset) {
    let textLength = 0
    if (!node) return textLength
    if (node.nodeName == '#text') textLength += offset
    else for (let i = 0; i < offset; i++) textLength += getNodeTextLength(node.childNodes[i])

    if (node != parent) textLength += getTextLength(parent, node.parentNode, getNodeOffset(node))

    return textLength
}

const getNodeTextLength = function (node: Node) {
    let textLength = 0

    if (node.nodeName == 'BR') textLength = 1
    else if (node.nodeName == '#text') textLength = node.nodeValue.length
    else if (node.childNodes != null) for (let i = 0; i < node.childNodes.length; i++) textLength += getNodeTextLength(node.childNodes[i])

    return textLength
}

const getNodeOffset = function (node: Node): number {
    return node == null ? -1 : 1 + getNodeOffset(node.previousSibling)
}

/* Get absolute position X, Y of cursor in text input */
function getCaretCoordinates() {
    let x = 0,
        y = 0
    const isSupported = typeof window.getSelection !== 'undefined'
    if (isSupported) {
        const selection = window.getSelection()
        if (selection.rangeCount !== 0) {
            const range = selection.getRangeAt(0).cloneRange()
            range.collapse(true)
            const rect = range.getClientRects()[0]
            if (rect) {
                x = rect.left
                y = rect.top
            }
        }
    }
    return { x, y }
}

/*  Place the cursor in the end of text input */

function placeCaretAtEnd(el: HTMLElement) {
    if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
        const range = document.createRange()
        range.selectNodeContents(el)
        range.collapse(false)
        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
    }
}

// find the child node and relative position and set it on range
function setCursorPosition(parent: Node, range: Range, stat: { done: boolean; pos: number }): Range {
    if (stat.done) return range

    if (parent.nodeType === Node.TEXT_NODE) {
        const textNode = parent as Text
        if (textNode.textContent.length >= stat.pos) {
            range.setStart(textNode, stat.pos)
            stat.done = true
        } else {
            stat.pos -= textNode.textContent.length
        }
    } else if (parent.nodeType === Node.ELEMENT_NODE) {
        const elementNode = parent as Element
        for (let i = 0; i < elementNode.childNodes.length && !stat.done; i++) {
            const currentNode = elementNode.childNodes[i]
            setCursorPosition(currentNode, range, stat)
        }
    }

    return range
}

type Position = 'relative' | 'absolute'
export type TagKind = 'mention' | 'link'

export interface MentionEditorView {
    id: string
    kind: TagKind
    publicKey: string
    text: string
    length: number
    offset: number
}

interface MentionInterface {
    [id: string]: MentionEditorView
}

export const TuringoEditor = forwardRef(function editor(
    props: {
        kind?: 'post' | 'comment'
        showEmojis?: boolean
        maxLength?: number
        showCount?: boolean
        submitOnEnter?: () => void
        minHeight?: number | string
        maxHeight?: number | string
        placeholder?: string
        hasMentions?: boolean
        position?: Position
        bordered?: boolean
        boardSelected?: string
        onChange?: (value: string) => void
        value?: string
        onError?: (error: string) => void
        onMentionsChange?: (value: Array<PostCreationModel.Mention>) => void
        initialMentions?: Array<PostCreationModel.Mention>
        onFocus?: () => void
    },
    ref
) {
    const {
        kind = 'comment',
        maxLength,
        showCount,
        boardSelected,
        bordered = true,
        onChange,
        value,
        onMentionsChange,
        initialMentions,
        hasMentions = true,
        placeholder,
        minHeight,
        maxHeight,
        showEmojis,
        onError,
        onFocus,
    } = props

    const context = useContext(CreatePostContext)
    const contextCrossPosting = useContext(CrossPostingContext)

    const { cm_pk, b_pk } = useParams<PostRouteParams>()
    const community = useAppSelector((state) => state.community.items[cm_pk])
    const isAdmin = community?.item?.access?.includes('edit')

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const inputRef = useRef<HTMLDivElement>()
    const [showAutoComplete, setShowAutoComplete] = useState(false)
    const windowSize = useWindowSize()

    const [mentions, setMentions] = useState<MentionInterface>()

    const [leftOffset, setLeftOffset] = useState(0)
    const [editorWidth, setEditorWidth] = useState(0)
    const [length, setLength] = useState(value ? value.length : 0)
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)

    const emojiButtonRef = useRef<HTMLDivElement>()

    const [emojiOffsetTop, setEmojiOffsetTop] = useState(0)
    const [emojiOffsetLeft, setEmojiOffsetLeft] = useState(0)
    const [numberCharacters, setNumberCharacters] = useState(0)
    const [emojiData, setEmojiData] = useState()

    const [suggestions, setSuggestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [lastPosition, setLastPosition] = useState<{ start?: number; end: number }>({ start: 0, end: 0 })

    const [textPosition, setTextPosition] = useState({ start: 0, end: 0 })

    const [lastCaret, setLastCaret] = useState({ start: 0, end: 0 })

    const [topOffset, setTopOffset] = useState(0)

    const isActive = Form.useWatch(['form', contextCrossPosting?.consumer, 'active'], context?.form)

    const scrollPosition = useScrollPosition()
    const [error, setError] = useState<string>(undefined)

    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const language = useAppSelector((state) => state.ui.language)

    const [links, setLinks] = useState<{ kind: TagKind; publicKey: string; text: string; length: number; offset: number }[]>(
        value && linkify.match(value)
            ? linkify.match(value).map((item) => {
                  return {
                      kind: 'link' as TagKind,
                      publicKey: item.url,
                      text: item.text,
                      length: item.lastIndex - item.index,
                      offset: item.index,
                  }
              })
            : []
    )

    /* useEffects */

    useEffect(() => {
        setEmojiOffsetTop(emojiButtonRef.current?.getBoundingClientRect().top + window.scrollY - 435 - 22)
        setEmojiOffsetLeft(emojiButtonRef.current?.getBoundingClientRect().left + window.scrollX)
    })

    useEffect(() => {
        if (value) {
            changeHighlights(
                initialMentions
                    ?.map((mention) => {
                        return {
                            id: mention.id ? mention.id : generateRandomString(7),
                            publicKey: mention.publicKey,
                            kind: 'mention' as TagKind,
                            text: mention.text ? mention.text : value.substring(mention.offset, mention.offset + mention.length),
                            length: mention.length,
                            offset: mention.offset,
                        }
                    })
                    .reduce((obj, item) => Object.assign(obj, { [item.id]: { ...item } }), {}),
                links,
                value,
                value.length
            )
        }
    }, [])

    useEffect(() => {
        if (inputRef && inputRef.current) {
            setLeftOffset(inputRef.current.getBoundingClientRect().left)
            setTopOffset(getCaretCoordinates().y + scrollPosition)
            setEditorWidth(inputRef.current.offsetWidth)
        }
    }, [windowSize])

    useEffect(() => {
        if (value) {
            handleLinks(value)
            setLength(value.length)
        }
        /*   if (!hasMentions && value) {
              inputRef.current.innerText = value
              setLength(value.length)
              setNumberCharacters(value.length)
  
              if (contextCrossPosting?.consumer == contextCrossPosting?.currentTab) {
                  placeCaretAtEnd(inputRef.current)
              }
          } */
    }, [value])

    useEffect(() => {
        if (isActive) {
            if (numberCharacters > maxLength) {
                if (!error) {
                    if (onError) onError('La publicación en X debe tener máximo 280 caracteres')
                    setError('La publicación en X debe tener máximo 280 caracteres')
                }
            } else {
                if (error) {
                    if (onError) onError(undefined)
                    setError(undefined)
                }
            }
        } else {
            if (error) {
                if (onError) onError(undefined)
                setError(undefined)
            }
        }
    }, [numberCharacters, isActive])

    useEffect(() => {
        void fetchEmojiData()
        inputRef.current.addEventListener('paste', onPastePlainText)
    }, [])

    /* Functions */

    const fetchEmojiData = async () => {
        const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')

        setEmojiData(await response.json())
    }

    // Functions to provide to external components
    useImperativeHandle(ref, () => ({
        getMentions() {
            return mentions
        },
        getValue() {
            return inputRef.current.innerText
        },
        focus() {
            inputRef.current.focus()
            placeCaretAtEnd(inputRef.current)
        },

        replyMention(target: { name: string; publicKey: string }) {
            const id = generateRandomString(7)
            inputRef.current.innerHTML = `<span id='${'mention'}' data-id='${id}' >${target.name}</span>`
            const newMention = {
                id: id,
                publicKey: target.publicKey,
                kind: 'mention' as TagKind,
                text: target.name,
                length: target.name.length,
                offset: 0,
            }
            changeHighlights(
                {
                    [id]: newMention,
                },
                links,
                inputRef.current.innerText,
                target.name.length,
                newMention
            )

            setLength(target.name.length + 1)
        },
        resetEditor() {
            if (onMentionsChange) {
                onMentionsChange([])
            }
            setNumberCharacters(0)
            inputRef.current.innerText = ''
            /*             changeHighlights({}, '')
             */ placeCaretAtEnd(inputRef.current)
        },
    }))

    const onPastePlainText = (e: ClipboardEvent) => {
        e.preventDefault()
        const text = e.clipboardData.getData('text/plain')

        if (maxLength) {
            const textToPaste = text.substring(0, maxLength - inputRef.current.innerText.length)
            document.execCommand('insertText', false, textToPaste)
            setNumberCharacters(inputRef.current.innerText.length)
        } else {
            document.execCommand('insertText', false, text)
        }

        return false
    }

    // Read the mentions and links, and insert them in the innerHTML, after that, restore the cursor position
    const changeHighlights = (
        data: MentionInterface,
        links: {
            kind: TagKind
            publicKey: string
            text: string
            length: number
            offset: number
        }[],
        text: string,
        lenghtDifference: number,
        lastReplacement?: MentionEditorView,
        lastPosition?: number,
        lastKey?: string
    ) => {
        if (lastKey != 'insertCompositionText') {
            const mentionsArray = Object.values(data || {})
            setMentions(data || {})
            if (onMentionsChange) onMentionsChange(mentionsArray)

            inputRef.current.innerHTML = insertMention(text, [...mentionsArray, ...links], lastReplacement)
            if (lastPosition != 0) {
                const sel = window.getSelection()
                sel.removeAllRanges()
                let position = lastPosition ? lastPosition : lastCaret.start
                position = lastReplacement ? position + 1 : position

                const range = setCursorPosition(inputRef.current, document.createRange(), { pos: position + lenghtDifference, done: false })
                range.collapse(true)
                sel.addRange(range)
            }
        }
    }

    // Function to handle a mention change, is called in every input to calculate if is in a mention to delete them or
    // is after a mention to add the corresponding index changes
    const handleMentionChanges = (
        currentLength: number,
        newLength: number,
        selection: {
            start?: number
            end: number
        },
        isMentionInsert?: boolean,
        isDeletingForward?: boolean
    ): MentionInterface => {
        let data = mentions
        if (currentLength != newLength) setLength(newLength)
        const isDeleting = currentLength > newLength
        const diff = newLength - currentLength
        if (data) {
            Object.keys(data).forEach((key) => {
                const mentionStart = data[key].offset
                const mentionEnd = data[key].offset + data[key].length
                // Is not a selection
                if (selection.start == undefined || selection.end == selection.start) {
                    // Is before the mention, add the difference in the offset
                    if (selection.end <= mentionStart && !isDeletingForward) {
                        data = {
                            ...data,
                            [key]: {
                                ...data[key],
                                offset: mentionStart + diff,
                                length: data[key].length,
                            },
                        }
                        // Is in the mention, delete it
                    } else if (selection.end == mentionEnd && isDeleting) {
                        const newData = { ...data }
                        delete newData[key]
                        data = newData
                    } else if (selection.end == mentionEnd && !isDeleting) {
                        return
                    } else if (selection.end > mentionStart && selection.end <= mentionEnd) {
                        const newData = { ...data }
                        delete newData[key]
                        data = newData
                    } else if (selection.end == mentionStart && isDeletingForward) {
                        const newData = { ...data }
                        delete newData[key]
                        data = newData
                    }
                } else {
                    // Is a selection
                    let start = 0
                    let end = 0
                    if (selection.end > selection.start) {
                        start = selection.start
                        end = selection.end
                    } else {
                        start = selection.end
                        end = selection.start
                    }

                    if (start < mentionStart && end < mentionStart) {
                        data = {
                            ...data,
                            [key]: {
                                ...data[key],
                                offset: mentionStart + diff,
                                length: data[key].length,
                            },
                        }
                    } else if (
                        (start >= mentionStart && start <= mentionEnd && isMentionInsert) ||
                        (start <= mentionStart && end >= mentionStart && isMentionInsert)
                    ) {
                        data = {
                            ...data,
                            [key]: {
                                ...data[key],
                                offset: mentionStart + diff,
                                length: data[key].length,
                            },
                        }
                    } else if ((start > mentionStart && start <= mentionEnd) || (start <= mentionStart && end >= mentionStart)) {
                        const newData = { ...data }
                        delete newData[key]
                        data = newData
                    }
                }
            })
        }

        return data
    }

    // Call when a key is unpressed
    const handleUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Dead') {
            e.preventDefault()
            return
        }
    }

    // Call when a key is unpressed
    const handlePress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Dead') {
            e.preventDefault()
            return
        }
    }

    // Call when a key is pressed
    const handleDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            document.execCommand('insertLineBreak', false, null)
            e.preventDefault()
        }

        if (e.key === 'Dead') {
            e.preventDefault()
            return
        }

        // If max characters is reached, dont let add more text
        if (numberCharacters >= maxLength && e.keyCode != 46 && e.keyCode != 8) {
            e.preventDefault()
            return
        }

        // if submit to Enter (like in chat messages), dont create a line break and submit
        if (props.submitOnEnter && e.keyCode === 13 && !e.shiftKey) {
            props.submitOnEnter()
            if (onMentionsChange) {
                onMentionsChange([])
            }
            inputRef.current.innerText = ''
            /*             changeHighlights({}, '')
             */ placeCaretAtEnd(inputRef.current)
            e.preventDefault()
            return
        }

        // if Tab key is pressed, add 4 spaces
        if (e.keyCode === 9) {
            e.preventDefault()
            const doc = inputRef.current.ownerDocument.defaultView
            const sel = doc.getSelection()
            const range = sel.getRangeAt(0)

            const tabNode = document.createTextNode('\u00a0\u00a0\u00a0\u00a0')
            range.insertNode(tabNode)

            range.setStartAfter(tabNode)
            range.setEndAfter(tabNode)
            sel.removeAllRanges()
            sel.addRange(range)
        }

        // Save the current state of the selection to use later in other functions
        const sel = window.getSelection()
        if (sel.toString() != '') {
            setLastPosition(getTextSelection(inputRef.current))
        } else {
            setLastPosition({ end: getTextSelection(inputRef.current).end })
        }
    }

    const fetchMentions = (wordText: string) => {
        void API.userMentions({
            urlParams: { cm_pk },
            searchParams: {
                ...(b_pk && { boardPk: b_pk }),
                ...(boardSelected && { boardPk: boardSelected }),
                term: wordText.slice(1),
            },
        }).then((response) => {
            if (response.data.length > 0) {
                const data = response.data
                setLoading(false)
                setSuggestions(data)
            } else {
                setLoading(false)
                setSuggestions([])
                setShowAutoComplete(false)
            }
        })
    }

    const handleLinks = (text: string) => {
        const links = linkify.match(text)
        let formattedLinks = []
        if (links) {
            formattedLinks = links.map((item) => {
                return {
                    kind: 'link' as TagKind,
                    publicKey: item.url,
                    text: item.text,
                    length: item.lastIndex - item.index,
                    offset: item.index,
                }
            })
        }
        if (!links) {
            setLinks([])
            return []
        } else {
            if (!isArrayEqual(formattedLinks, links)) {
                setLinks(formattedLinks)
                return formattedLinks
            } else {
                false
            }
        }
    }

    // This is called only when a key that change the text is pressed
    const handleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        setLastCaret(getTextSelection(inputRef.current))
        setEditorWidth(inputRef.current.offsetWidth)

        const selection = getTextSelection(inputRef.current)

        const valueText = inputRef.current.innerText
        setNumberCharacters(valueText.length)
        // See if are link in the text and save them
        const new_links = handleLinks(valueText)
        const mentionsAfterInput = handleMentionChanges(
            length,
            valueText.length,
            lastPosition,
            false,
            (e.nativeEvent as unknown as InputEvent).inputType == 'deleteContentForward'
        )
        changeHighlights(
            mentionsAfterInput,
            new_links,
            inputRef.current.innerText,
            0,
            undefined,
            selection.end,
            (e.nativeEvent as unknown as InputEvent).inputType
        )

        const { word: wordText, valid } = getLastMention(valueText, valueText.substring(0, selection.end).lastIndexOf('@'), selection.end, true)

        const shouldOpenAutoComplete = hasMentions
            ? /^@([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]{2,15}(\s?))([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]{1,15}(\s?)){1,2}$/.test(wordText)
            : false

        if (shouldOpenAutoComplete && valid) {
            if (timer) {
                clearTimeout(timer)
            }

            setLoading(true)
            const timerRef = setTimeout(() => fetchMentions(wordText), 1100)
            setTimer(timerRef)

            const selection = getTextSelection(inputRef.current)
            setTopOffset(getCaretCoordinates().y + scrollPosition)
            setTextPosition(selection)
        }

        setShowAutoComplete(shouldOpenAutoComplete && valid)

        if (onChange) onChange(valueText)
    }

    const handleSelection = (userHandle: UserMentionView) => {
        const text = inputRef.current.innerText

        const { word, range } = getLastMention(text, text.substring(0, textPosition.end + 1).lastIndexOf('@'), textPosition.end + 1, true)

        if (range) {
            const [offset] = range
            const mentionId = generateRandomString(7)
            const prefix = value.substring(0, offset)
            const suffix = value.substring(offset + word.length + 1)
            inputRef.current.innerText = prefix + userHandle.name + suffix

            setLastCaret({ start: lastCaret.start - word.length + userHandle.name.length, end: lastCaret.end - word.length + userHandle.name.length })

            const mentionsAfterInsert = handleMentionChanges(
                inputRef.current.innerText.length - userHandle.name.length + word.length,
                inputRef.current.innerText.length,
                {
                    start: textPosition.start - word.length,
                    end: textPosition.start - word.length + userHandle.name.length,
                },
                true
            )
            const newMention = {
                id: mentionId,
                kind: 'mention' as TagKind,
                publicKey: userHandle.publicKey,
                offset: offset,
                length: userHandle.name.length,
                text: userHandle.name,
            }
            changeHighlights(
                {
                    ...mentionsAfterInsert,
                    [mentionId]: newMention,
                },
                links,
                inputRef.current.innerText,
                0,
                newMention,
                lastCaret.start - word.length + userHandle.name.length
            )

            setShowAutoComplete(false)
            if (onChange) onChange(inputRef.current.innerText)
        }
    }

    const onMouse = () => {
        setShowAutoComplete(false)
        setLastCaret(getTextSelection(inputRef.current))
    }

    const onEmojiSelect = (e: { native: string }) => {
        const prefix = inputRef.current.innerText.substring(0, lastCaret.start)
        const suffix = inputRef.current.innerText.substring(lastCaret.end)

        const newText = prefix + e.native + suffix
        inputRef.current.innerText = newText

        setNumberCharacters(newText.length)

        setLastCaret({ start: lastCaret.end + e.native.length, end: lastCaret.end + e.native.length })
        inputRef.current.focus()

        const sel = window.getSelection()

        // restore the position
        sel.removeAllRanges()
        const range = setCursorPosition(inputRef.current, document.createRange(), { pos: lastCaret.end + e.native.length, done: false })
        range.collapse(true)
        sel.addRange(range)
        setShowEmojiPicker(false)
        if (onChange) onChange(newText)
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div
                className={bordered ? style.editorWrapperBordered : style.editorWrapper}
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: kind != 'comment' ? 'column' : 'row',
                    cursor: 'text',
                }}
            >
                {showEmojiPicker &&
                    createPortal(
                        <div
                            style={{
                                top: emojiOffsetTop + 12,
                                left: emojiOffsetLeft,
                                position: 'absolute',
                                width: editorWidth,
                                zIndex: 1002,
                            }}
                        >
                            <Picker
                                previewPosition={'none'}
                                skinTonePosition={'search'}
                                emojiButtonSize={32}
                                emojiSize={24}
                                locale={language}
                                data={emojiData}
                                onEmojiSelect={onEmojiSelect}
                                onClickOutside={() => setShowEmojiPicker(false)}
                            />
                        </div>,
                        document.body
                    )}
                {showEmojis && !isMobile && (
                    <div ref={emojiButtonRef} style={{ display: 'flex' }}>
                        <SlightlySmilingFace
                            className={style.emoji}
                            style={{ marginRight: 8, display: 'flex' }}
                            theme="outline"
                            size="22"
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        />
                    </div>
                )}

                <p
                    style={{
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        display: 'inline-block',
                        width: '100%',

                        ...(!isMobile && {
                            maxHeight: maxHeight ? maxHeight : 'unset',
                            overflow: 'auto',
                            minHeight: minHeight ? minHeight : 'unset',
                        }),
                    }}
                    onFocus={onFocus}
                    className={ClassNames(style.editor, 'scrollStyle')}
                    id="editor"
                    contentEditable
                    onKeyUp={handleUp}
                    onInput={handleInput}
                    onKeyPress={handlePress}
                    onKeyDown={handleDown}
                    ref={inputRef}
                    onMouseUp={onMouse}
                    placeholder={placeholder}
                ></p>

                {showAutoComplete && (
                    <Autocomplete
                        loading={loading}
                        items={suggestions}
                        width={editorWidth}
                        handleSelection={handleSelection}
                        top={topOffset + 22}
                        left={leftOffset}
                    />
                )}
            </div>
            {showCount && maxLength && (
                <p
                    style={{ alignSelf: 'end', marginBottom: 0, color: 'var(--neutral-6)', fontSize: 14, lineHeight: '22px' }}
                >{`${numberCharacters}/${maxLength}`}</p>
            )}
            {context?.setScreen && kind == 'post' && (
                <div style={{ display: 'flex', justifyContent: isAdmin ? (isMobile ? 'end' : 'space-between') : 'start', marginTop: 16 }}>
                    {!isMobile && (
                        <Tooltip title="Emoji">
                            <Button
                                icon={
                                    <SlightlySmilingFace
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        theme="outline"
                                        size="14"
                                    />
                                }
                                ref={emojiButtonRef}
                                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                shape="circle"
                                type="default"
                            ></Button>
                        </Tooltip>
                    )}
                    {isAdmin && (
                        <Tooltip title="Generador de texto">
                            <Button
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                className="hover-svg"
                                onClick={() => context?.setScreen('alan')}
                                shape="circle"
                                type="default"
                                icon={<AlanIcon style={{ width: '14px', height: '14px' }} />}
                            ></Button>
                        </Tooltip>
                    )}
                </div>
            )}
            {error && <Alert style={{ marginTop: 10 }} message={error} type="error" />}
        </div>
    )
})
