import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface AlanIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const AlanIcon = (props: AlanIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 14 14" fill={'currentColor'}>
                    <path d="M5.83333 0L6.30599 1.27734L7.58333 1.75L6.30599 2.22266L5.83333 3.5L5.36067 2.22266L4.08333 1.75L5.36067 1.27734L5.83333 0Z" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.27974 1.7181C9.43454 1.71807 9.58299 1.77956 9.69242 1.88904L11.823 4.02057C12.0507 4.24836 12.0507 4.61756 11.823 4.84535L4.5531 12.1179C4.4437 12.2274 4.29528 12.2889 4.14053 12.2889H2.00635C1.68418 12.2889 1.42301 12.0277 1.42301 11.7055V9.58217C1.42301 9.42758 1.48438 9.27932 1.59362 9.16994L8.86713 1.8892C8.97652 1.77968 9.12495 1.71813 9.27974 1.7181ZM9.28001 3.12673L8.24195 4.16603C8.61272 4.5427 8.95687 4.88196 9.32489 5.24475L9.55118 5.46792L10.5857 4.43296L9.28001 3.12673ZM2.58968 9.82359L7.42271 4.98626C7.82548 5.39024 8.33266 5.89863 8.72678 6.29267L3.89883 11.1222H2.58968V9.82359Z"
                    />
                    <path d="M1.70312 4.03645L0 4.66667L1.70312 5.29688L2.33333 7L2.96355 5.29688L4.66667 4.66667L2.96355 4.03645L2.33333 2.33333L1.70312 4.03645Z" />
                    <path d="M10.2956 10.2956L8.16667 11.0833L10.2956 11.8711L11.0833 14L11.8711 11.8711L14 11.0833L11.8711 10.2956L11.0833 8.16667L10.2956 10.2956Z" />
                </svg>
            )}
            {...props}
        />
    )
}
