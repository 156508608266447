import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface ArrowIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const BackIcon = (props: ArrowIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={props.color ? props.color : 'var(--neutral-13)'}>
                    <path d="M7.24992 11.2934C6.8594 11.6839 6.8594 12.3171 7.24992 12.7076L14.2934 19.7511C14.6839 20.1416 15.3171 20.1416 15.7076 19.7511C16.0981 19.3606 16.0981 18.7274 15.7076 18.3369L9.37124 12.0005L15.7076 5.66414C16.0981 5.27361 16.0981 4.64045 15.7076 4.24992C15.3171 3.8594 14.6839 3.8594 14.2934 4.24992L7.24992 11.2934Z" />
                </svg>
            )}
            {...props}
        />
    )
}
