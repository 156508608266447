// Libraries

// React
import React, { CSSProperties } from 'react'

// Antd
import { LoadingOutlined } from '@ant-design/icons'
import { Down } from '@icon-park/react'
import Style from './TuringoButton.scss'
import useBreakpoints from '@hooks/useBreakpoints'
import ClassNames from '@util/style/ClassNames'
import { Spin } from 'antd'

// MARK: - Definition

export type TuringoButtonType = 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed' | 'reaction'
export type TuringoButtonShape = 'standard' | 'circle' | 'square' | 'vertical' | 'social_media'
export type TuringoButtonSize = 'small' | 'medium'

namespace TuringoButton {
    export interface Props {
        onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
        icon?: JSX.Element
        suffixIcon?: JSX.Element
        shape?: TuringoButtonShape
        type?: TuringoButtonType
        size?: 'small' | 'medium'
        expandInMobile?: boolean
        block?: boolean
        children?: React.ReactNode
        disabled?: boolean
        loading?: boolean
        style?: CSSProperties
    }
}

// MARK: - Implementation

const TuringoButton: React.FC<TuringoButton.Props> = (props: TuringoButton.Props) => {
    const { onClick, icon, shape = 'standard', type = 'default', size = 'medium', children, expandInMobile, block, loading, disabled, style, suffixIcon } = props
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    return (
        <div
            style={{
                ...style,
                width: (expandInMobile && isMobile) || block ? '100%' : 'fit-content',
                textAlign: 'center',
                flexDirection: shape == 'vertical' ? 'column' : 'row',
                opacity: loading? 0.65 : 1,
            }}
            className={ClassNames(
                Style.button,
                disabled ? Style.disabled : Style.active,
                Style[shape],
                Style[size],
                Style[type],
                type != 'primary' && type != 'text' && !disabled && 'tgo-hover-button'
            )}
            onClick={disabled || loading ? undefined : onClick}
        >
            {loading && (
                <div
                    className="ant-btn-loading-icon"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        minWidth: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        height: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        fontSize: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        marginRight: children && shape != 'vertical' ? 4 : 0,
                        marginBottom: shape == 'vertical' ? 4 : 0,
                    }}
                >
                    <Spin indicator={<LoadingOutlined style={{ color: 'var(--neutral-6)', fontSize: isMobile ? 16 : 14, paddingRight: 4 }} spin />}></Spin>
                </div>
            )}

            {icon && !loading && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        minWidth: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,

                        height: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        fontSize: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        marginRight: children && shape != 'vertical' ? 4 : 0,
                        marginBottom: shape == 'vertical' ? 4 : 0,
                    }}
                >
                    {icon}
                </div>
            )}
            <div className="turingo-text">{children}</div>

            {suffixIcon && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        minWidth: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,

                        height: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        fontSize: isMobile ? (shape == 'social_media' ? 24 : 16) : shape == 'social_media' ? 22 : 14,
                        marginLeft: children && shape != 'vertical' ? 4 : 0,
                        marginBottom: shape == 'vertical' ? 4 : 0,
                    }}
                >
                    {suffixIcon}
                </div>
            )}
        </div>
    )
}

export { TuringoButton }
