// Libraries

// Antd
import { Form, Radio, Skeleton, Space } from 'antd'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { FooterModal } from '@components/modals/components/FooterModal'
import Paragraph from 'antd/lib/typography/Paragraph'
import strings from '@resources/localization'
import { UserView } from '@api/model/responses/user/UserView'
import { getRequest, useRequestState } from '@api/requests/AsyncRequests'
import { API } from '@api/API'
import { CommunityRouteParams } from '@typings/routing/Router'
import { useParams } from 'react-router'
import { useAppSelector } from '@hooks/useStore'
import { AvatarTuringo } from '@components/avatar/Avatar'
import { useForm } from 'antd/lib/form/Form'

// MARK: - Definition

namespace MaskSelectorModal {
    export interface Props {
        kind?: 'post' | 'interaction'
        open: boolean
        onCancel?: () => void
        currentMask: UserView
        setCurrentMask: (mask: UserView) => void
        onChange?: (maskPk: string) => void
        loading?: boolean
    }
}

// MARK: - Implementation

const MaskSelectorModal: React.FC<MaskSelectorModal.Props> = (props: MaskSelectorModal.Props) => {
    const { open, onCancel, currentMask, setCurrentMask, kind = 'post', onChange, loading } = props
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)
    const [form] = useForm()
    const [masks, setMasks] = useRequestState<UserView[]>()

    const { cm_pk } = useParams<CommunityRouteParams>()

    /*     const postState = useAppSelector((state) => state.posts[scope]?.[isFeed ? 'feed' : b_pk]?.items?.[post?.publicKey].state)
    const comments = useAppSelector((state) => state.posts.detail[b_pk]?.items?.[p_pk]?.childrens)
 */
    void getRequest(API.communityMasks, setMasks)({ urlParams: { cm_pk } })

    const submitForm = (values: { mask: string }) => {
        let newMask = masks?.data?.find((m) => m.publicKey == values.mask)
        if (!newMask) newMask = currentEntity
        setCurrentMask(newMask)
        if (onChange) {
            onChange(newMask.publicKey)
        } else {
            onCancel()
        }
    }
    const RadioSelector = (mask: UserView) => {
        return (
            <Radio value={mask.publicKey}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AvatarTuringo
                        size={24}
                        style={{
                            height: 48,
                            width: 48,
                            lineHeight: '48px',
                            marginRight: 8,
                        }}
                        name={mask.name}
                        avatar={mask.avatarUrl}
                    />

                    <div style={{ lineHeight: '16px' }}>
                        <div className="turingo-text" style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                            {mask.name}
                        </div>
                        {mask.profile?.job && (
                            <div className="turingo-text" style={{ display: 'flex', alignItems: 'center' }}>
                                {mask.profile?.job}
                            </div>
                        )}
                    </div>
                </div>
            </Radio>
        )
    }

    return (
        <TuringoModal
            open={open}
            onCancel={onCancel}
            /*  onOk={onClick} */
            width={600}
            title={strings.modals.mask.title}
            footer={[
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        disabled: masks.loading,
                        loading: loading,
                        action: form.submit,
                        customText: kind == 'post' ? strings.general.continue : 'Cambiar',
                    }}
                    secondary={
                        kind == 'interaction' && {
                            action: onCancel,
                            customText: strings.general.cancel,
                        }
                    }
                    back={
                        kind == 'post' && {
                            kind: 'back',
                            action: onCancel,
                            customText: strings.general.back,
                        }
                    }
                />,
            ]}
        >
            <Paragraph style={{ marginBottom: 16 }} className="turingo-text">
                {strings.modals.mask.description}
            </Paragraph>

            {masks.loading && (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Skeleton className="large" active title paragraph={null} style={{ marginBottom: 24 }}></Skeleton>
                    <Skeleton className="large" active title paragraph={null}></Skeleton>
                </div>
            )}

            {!masks.loading && (
                <Form
                    initialValues={{
                        mask: currentMask.publicKey,
                    }}
                    scrollToFirstError
                    className={'scrollStyle'}
                    onFinish={submitForm}
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                >
                    <Form.Item name={['mask']}>
                        <Radio.Group defaultValue={'general'}>
                            <Space direction="vertical" size={24}>
                                {RadioSelector(currentEntity)}

                                {masks?.data?.map((m) => RadioSelector(m))}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            )}
        </TuringoModal>
    )
}

export { MaskSelectorModal }
