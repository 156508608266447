import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { FooterModal } from '@components/modals/components/FooterModal'
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody'
import strings from '@resources/localization'
import { postDelete } from '@state/actions'
import { RootReducer } from '@state/reducers/Root'
import { PostRouteParams } from '@typings/routing/Router'
import { Button, Modal } from 'antd'
import React, { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { useParams } from 'react-router'
import { PostView } from '@api/model/responses/post/PostView'

export type CommentModalType = 'delete' | 'edit'

interface Props {
    target: PostView
    parents?: string[]
    open: boolean
    onCancel: () => void
}

export const DeleteModal = (props: Props): ReactElement => {
    const { cm_pk, b_pk, p_pk } = useParams<PostRouteParams>()

    const { open, onCancel, target, parents = [] } = props
    const dispatch = useAppDispatch()

    const scope = p_pk ? 'detail' : 'all'

    const isFeed = !b_pk

    const isPost = parents.length == 0
    const isComment = parents.length == 1
    const isReply = parents.length == 1

    const commentListState = useAppSelector((state) =>
        isPost
            ? state.posts[scope][isFeed ? 'feed' : b_pk]?.listState
            : isComment
            ? state.posts[scope][isFeed ? 'feed' : b_pk]?.items[parents[0]]?.childrens?.listState
            : state.posts[scope][isFeed ? 'feed' : b_pk]?.items[parents[0]]?.childrens?.items[parents[1]].childrens.listState
    )
    const onDelete = () => {
        void dispatch(
            postDelete({
                urlParams: { cm_pk, b_pk: target.boardPk, target_pk: target.publicKey },
                extraParams: {
                    parents: parents,
                    scope: scope,
                    feed: isFeed,
                },
                options: {
                    alerts: {
                        success: isComment && !isPost ? strings.alerts.comment.delete.success : strings.alerts.post.delete.success,
                        error: isComment && !isPost ? strings.alerts.comment.delete.failure : strings.alerts.post.delete.failure,
                    },
                    then: {
                        action: onCancel,
                    },
                },
            })
        )
    }
    return (
        <TuringoModal
            onCancel={onCancel}
            open={open}
            title={null}
            width={572}
            footer={[
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        action: onDelete,
                        customText: strings.general.delete,
                        loading: commentListState?.loading,
                    }}
                    secondary={{
                        action: onCancel,
                        customText: strings.general.cancel,
                    }}
                />,
            ]}
        >
            <InformationModalBody
                kind={'warning'}
                title={isComment && !isPost ? strings.comments.delete.title : strings.screens.boards.discussion.post.delete.title}
                description={isComment && !isPost ? strings.comments.delete.description : strings.screens.boards.discussion.post.delete.description}
            />
        </TuringoModal>
    )
}
