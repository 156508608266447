import { Button, Progress } from 'antd'
import { formatBytes } from '@util/string/Functions'
import { Colors } from '@util/color/Colors'
import { DeleteOutlined } from '@ant-design/icons'
import { UploadFile } from './TuringoUpload'
import { CheckOne } from '@icon-park/react'
import { GetTypeIcon } from '@components/content/posts/post_subtypes/attachments/AttachmentComponent'

interface Props {
    file: UploadFile
    onRemove: () => void
    isEditing?: boolean
}

export const FileComponent = (props: Props) => {
    const { file, onRemove, isEditing } = props
    const type = file.file?.type

    return (
        <div>
            <div style={{ position: 'relative' }}>
                {onRemove && !isEditing && (
                    <Button
                        onClick={onRemove}
                        shape="circle"
                        icon={<DeleteOutlined style={{ display: 'flex', justifyContent: 'center' }} />}
                        style={{ position: 'absolute', zIndex: 100, right: 14, top: 14 }}
                    ></Button>
                )}

                <div
                    style={{
                        backgroundColor: Colors('FORM_INPUT_AREA_BACKGROUND'),
                        height: 68,
                        borderRadius: 4,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ position: 'relative' }}>
                        {GetTypeIcon(type, { fontSize: 36, padding: 16, color: 'var(--neutral-7)' })}
                        {file.status == 'success' && (
                            <CheckOne
                                theme="two-tone"
                                size="16"
                                fill={['#ffffff', 'var(--polar-green-5)']}
                                strokeLinejoin="bevel"
                                style={{ position: 'absolute', bottom: 5, right: 13 }}
                            />
                        )}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 50, wordBreak: 'break-all' }}>
                        <div style={{ fontWeight: 500, lineHeight: '22px' }}>{file.file.name}</div>
                        <div style={{ fontSize: 12, lineHeight: '20px' }}>{formatBytes(file.file.size)}</div>
                    </div>
                </div>
            </div>
            {file.status == 'uploading' && (
                <Progress
                    showInfo={false}
                    type="line"
                    percent={file.percent}
                    strokeWidth={6}
                    success={{ strokeColor: 'var(--ant-primary-color)' }}
                    strokeColor={{ from: 'var(--ant-primary-color)', to: 'var(--ant-primary-color)' }}
                />
            )}
        </div>
    )
}
