import { MyKnownError } from '@api/requests/AsyncRequests';
export const isLoading = (states: Array<{ loading: boolean }>): boolean => {

    states.forEach((element) => {
        if (!element) return true
        if (element.loading) return true
    })
    return false
}

export const hasError = (states: Array<{ error?: MyKnownError }>): string => {
    let error: string = undefined
    states.forEach((element) => {
        if (!element) return undefined
        error = element.error?.error || error
    })

    return error
}
