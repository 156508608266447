import { Spin } from 'antd'
import { ReactElement, ReactNode } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

export const SpinLoader = (props: { loading: boolean; children?: ReactNode | ReactNode[]; showIcon?: boolean }) => {
    const { loading, children, showIcon = true } = props
    return (
        <Spin spinning={loading} indicator={showIcon ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : <></>}>
            {children}
        </Spin>
    )
}
