import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface RetryIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const RetryIcon = (props: RetryIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill={props.color ? props.color : 'var(--neutral-13)'}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.1248 2.87505C10.0699 1.82006 8.61072 1.1665 7.00004 1.1665C3.77839 1.1665 1.16671 3.77818 1.16671 6.99984C1.16671 10.2215 3.77839 12.8332 7.00004 12.8332C8.61072 12.8332 10.0699 12.1796 11.1248 11.1246C11.3526 10.8968 11.3526 10.5275 11.1248 10.2997C10.897 10.0719 10.5277 10.0719 10.2999 10.2997C9.45474 11.1448 8.28888 11.6665 7.00004 11.6665C4.42272 11.6665 2.33337 9.57716 2.33337 6.99984C2.33337 4.42252 4.42272 2.33317 7.00004 2.33317C8.28888 2.33317 9.45474 2.85487 10.2999 3.70001L10.2999 3.70002C10.4628 3.86294 10.6983 4.11458 10.9374 4.37484H9.91671C9.59454 4.37484 9.33337 4.636 9.33337 4.95817C9.33337 5.28034 9.59454 5.5415 9.91671 5.5415H12.25C12.5722 5.5415 12.8334 5.28034 12.8334 4.95817V2.62484C12.8334 2.30267 12.5722 2.0415 12.25 2.0415C11.9279 2.0415 11.6667 2.30267 11.6667 2.62484V3.4447C11.4663 3.22814 11.2719 3.02216 11.1248 2.87505Z"
                    />
                </svg>
            )}
            {...props}
        />
    )
}
