import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    height?: number
    width?: number
}

export const CommentIcon = (props: Props) => {
    return (
        <Icon
            component={() => {
                return (
                    <svg viewBox="0 0 20 20" width={'100%'} fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M.833 2.5c0-.46.373-.833.834-.833h16.666c.46 0 .834.373.834.833V15c0 .46-.373.834-.834.834H9.78l-3.99 1.995a.833.833 0 01-1.207-.745v-1.25H1.667A.833.833 0 01.833 15V2.5zm1.667.834v10.833h2.917c.46 0 .833.373.833.833v.735l2.96-1.48a.833.833 0 01.373-.088H17.5V3.334h-15z"
                            fillOpacity="1"
                        ></path>
                    </svg>
                )
            }}
            {...props}
        />
    )
}
