import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { FooterModal } from '@components/modals/components/FooterModal'
import strings from '@resources/localization'
import { postUnpin } from '@state/actions'
import { RootReducer } from '@state/reducers/Root'
import { PostRouteParams } from '@typings/routing/Router'
import React, { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { useLocation, useParams } from 'react-router'
import { PostView } from '@api/model/responses/post/PostView'
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody'

export type CommentModalType = 'delete' | 'edit'

interface Props {
    target: PostView
    open: boolean
    onCancel: () => void
}

export const UnpinModal = (props: Props): ReactElement => {
    const { cm_pk, b_pk, p_pk } = useParams<PostRouteParams>()

    const { open, onCancel, target } = props
    const dispatch = useAppDispatch()
    const pathname = useLocation().pathname.split('/')
    
    const isHighlight = pathname[pathname.length -1] == 'highlights'
    const scope = p_pk ? 'detail' : 'all'

    const isFeed = !b_pk
    const postState = useAppSelector((state) => state.posts[scope][isFeed ? 'feed' : b_pk]?.items[target.publicKey]?.state)

    const onPin = () => {
        dispatch(
            postUnpin({
                urlParams: { cm_pk, b_pk: target.boardPk, p_pk: target.publicKey },
                extraParams: {
                    scope: scope,
                    feed: isFeed,
                    highlight: isHighlight
                },
                options: {
                    alerts: {
                        success: 'La publicación ha sido removida como destacada',
                        error: 'Error',
                    },
                    then: {
                        action: onCancel,
                    },
                },
            })
        )
    }
    return (
        <TuringoModal
            onCancel={onCancel}
            open={open}
            title={null}
            width={572}
            footer={[
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        action: onPin,
                        customText: strings.screens.markasread.actions.unhighlight,
                        loading: postState?.loading,
                    }}
                    secondary={{
                        action: onCancel,
                        customText: strings.general.cancel,
                    }}
                />,
            ]}
        >
            <InformationModalBody
                kind={'warning'}
                title={strings.screens.markasread.unpin_modal.title}
                description={strings.screens.markasread.unpin_modal.description}
            />
        </TuringoModal>
    )
}
