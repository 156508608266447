// Libraries
// React
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'

// Ant
import { Row, Divider, Switch, Alert, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'

// Resources
import strings from '@resources/localization'

// Images
import ImageLogoFacebook from '@bundle/assets/social/small/logo-facebook.svg'
import ImageLogoGoogle from '@bundle/assets/social/small/logo-google.svg'
import ImageLogoLinkedin from '@bundle/assets/social/small/logo-linkedin.svg'

// Hooks
import { Provider } from '@hooks/oauth/types'
import useBreakpoints from '@hooks/useBreakpoints'

// State

import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { authAccounts, authAddAccount, authRemoveAccount } from '@state/actions'

// Utils
import { capitalizeFirstLetter } from '@util/string/Functions'

// Components
import SVG from '@components/assets/SVG'
import { InformationModal } from '@components/modals/information_modal/InformationModal'
import { FacebookLogin, GoogleLogin, linkedInLogin } from '@components/content/social/Social'
import { Check, Close } from '@icon-park/react'
import { useHistory } from 'react-router'
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton'
import { Router } from '@Router'
import { InfoCircleOutlined } from '@ant-design/icons'
import { SettingItem } from '@components/content/settings/SettingItem'
import { PhoneModal } from '../modals/PhoneModal'
import { set } from 'lodash'

// MARK: - Implementation

const AccountScreen = () => {
    const history = useHistory()
    const authEmail = useAppSelector((state) => state.auth.authEmail)
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)

    const settingStrings = strings.screens.settings
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const dispatch = useAppDispatch()

    const openPasswordModal = () => {
        dispatch(modalAction({ modal: 'password', state: true }))
    }
    const openDeleteAccountModal = () => {
        dispatch(modalAction({ modal: 'delete_account', state: true }))
    }

    const openPasswordCreateModal = () => {
        dispatch(modalAction({ modal: 'password_create', state: true }))
    }

    const openEmailModal = () => {
        dispatch(modalAction({ modal: 'email', state: true }))
    }

    const openEmailCreateModal = () => {
        dispatch(modalAction({ modal: 'email_create', state: true }))
    }

    const accounts = useAppSelector((state) => state.auth.connected_accounts.data)

    useEffect(() => {
        void dispatch(authAccounts({}))
    }, [])

    const [googleState, setGoogleState] = useState(false)
    const [googleStateLoading, setGoogleStateLoading] = useState(false)

    const [facebookState, setFacebookState] = useState(false)
    const [facebookStateLoading, setFacebookStateLoading] = useState(false)

    const [linkedinState, setLinkedinState] = useState(false)
    const [linkedinStateLoading, setLinkedinStateLoading] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [phoneModal, setPhoneModal] = useState(false)
    useEffect(() => {
        setGoogleState(accounts?.social.google)
        setFacebookState(accounts?.social.facebook)
        setLinkedinState(accounts?.social.linkedin)
    }, [accounts])

    const [errorAdded, setErrorAdded] = useState(false)
    const [successAdded, setSuccessAdded] = useState(false)
    const [providerAdded, setProviderAdded] = useState<Provider>('google')
    const [showAlert, setShowAlert] = useState(false)

    const onCloseAlert = () => {
        setShowAlert(false)
    }
    const onAddedError = (provider: Provider, showModal?: boolean) => {
        if (provider == 'facebook') {
            setFacebookState(false)
            setFacebookStateLoading(false)
        } else if (provider == 'google') {
            setGoogleState(false)
            setGoogleStateLoading(false)
        } else if (provider == 'linkedin') {
            setLinkedinState(false)
            setLinkedinStateLoading(false)
        }
        if (showModal) {
            setErrorAdded(true)
        }
    }

    const onAddedSuccess = (provider: Provider) => {
        if (provider == 'facebook') {
            setFacebookStateLoading(false)
        } else if (provider == 'google') {
            setGoogleStateLoading(false)
        } else if (provider == 'linkedin') {
            setLinkedinStateLoading(false)
        }
        setProviderAdded(provider)
        setSuccessAdded(true)
    }

    const onRemoveSuccess = (provider: Provider) => {
        if (provider == 'facebook') {
            setFacebookStateLoading(false)
        } else if (provider == 'google') {
            setGoogleStateLoading(false)
        } else if (provider == 'linkedin') {
            setLinkedinStateLoading(false)
        }
    }

    const onSuccessLinkedIn = (code: string) => {
        void dispatch(
            authAddAccount({
                urlParams: { provider: 'linkedin' },
                bodyParams: { token: code, redirectUri: `${window.location.origin}/linkedin` },
                options: {
                    then: {
                        error: () => onAddedError('linkedin', true),
                        action: () => onAddedSuccess('linkedin'),
                    },
                },
            })
        )
    }

    const onSuccessFacebook = (code: string) => {
        void dispatch(
            authAddAccount({
                urlParams: { provider: 'facebook' },
                bodyParams: {
                    token: code,
                    redirectUri: `${window.location.origin}/facebook`,
                },
                options: {
                    then: {
                        error: () => onAddedError('facebook', true),
                        action: () => onAddedSuccess('facebook'),
                    },
                },
            })
        )
    }

    const onSuccessGoogle = (code: string) => {
        void dispatch(
            authAddAccount({
                urlParams: { provider: 'google' },
                bodyParams: {
                    token: code,
                    redirectUri: `${window.location.origin}/google`,
                },
                options: {
                    then: {
                        error: () => onAddedError('google', true),
                        action: () => onAddedSuccess('google'),
                    },
                },
            })
        )
    }

    const onDisconnect = (provider: Provider) => {
        if (canEdit) {
            if (provider == 'facebook') {
                setFacebookState(false)
                setFacebookStateLoading(true)
            } else if (provider == 'google') {
                setGoogleState(false)
                setGoogleStateLoading(true)
            } else if (provider == 'linkedin') {
                setLinkedinState(false)
                setLinkedinStateLoading(true)
            }

            void dispatch(
                authRemoveAccount({
                    urlParams: { provider: provider },
                    options: {
                        then: {
                            action: () => onRemoveSuccess(provider),
                        },
                    },
                })
            )
        } else {
            window.scrollTo(0, document.body.scrollHeight)
            setShowAlert(true)
        }
    }

    const linkedIn = linkedInLogin(onSuccessLinkedIn, () => onAddedError('linkedin'))
    const google = GoogleLogin(onSuccessGoogle, () => onAddedError('google'))
    const facebook = FacebookLogin(onSuccessFacebook, () => onAddedError('facebook'))

    const onClick = (provider: Provider) => {
        if (provider == 'facebook') {
            facebook()
            setFacebookState(true)
            setFacebookStateLoading(true)
        } else if (provider == 'google') {
            google()
            setGoogleState(true)
            setGoogleStateLoading(true)
        } else if (provider == 'linkedin') {
            linkedIn()
            setLinkedinState(true)
            setLinkedinStateLoading(true)
        }
    }

    useEffect(() => {
        let count = 0
        if (linkedinState) count += 1
        if (googleState) count += 1
        if (facebookState) count += 1
        if (accounts?.password) count += 1

        setCanEdit(count >= 2)
    }, [accounts, linkedinState, googleState, facebookState])

    return (
        <div className="lateral-padding" style={{ paddingBottom: 16 }}>
            <InformationModal
                kind={'success'}
                open={successAdded}
                onClick={() => setSuccessAdded(false)}
                title={settingStrings.sections.accounts.modals.added.success.title}
                description={
                    strings.formatString(settingStrings.sections.accounts.modals.added.success.description, capitalizeFirstLetter(providerAdded)) as string
                }
            />
            <InformationModal
                kind={'warning'}
                open={errorAdded}
                onClick={() => setErrorAdded(false)}
                title={settingStrings.sections.accounts.modals.added.warning.title}
                description={
                    strings.formatString(settingStrings.sections.accounts.modals.added.warning.description, capitalizeFirstLetter(providerAdded)) as string
                }
            />

            <PhoneModal open={phoneModal} onCancel={() => setPhoneModal(false)} />

            <div style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
                <GoBackButton
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}
                    type="default"
                    showText={false}
                    onClick={() => history.push(Router.Path.settings({}))}
                />
                <Paragraph strong className="turingo-text">
                    {strings.screens.settings.sections.signup.title}
                </Paragraph>
            </div>
            <Divider></Divider>

            <SettingItem
                gap={2}
                buttonAlign="start"
                description={authEmail}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Paragraph strong>{strings.screens.settings.sections.signup.changeEmail.description}</Paragraph>

                        <Tooltip placement="right" title={strings.screens.settings.sections.signup.changeEmail.help}>
                            <InfoCircleOutlined
                                onClick={(event) => event.stopPropagation()}
                                style={{ fontSize: breakpoints.isMobile ? 16 : 14, marginLeft: 4 }}
                            />
                        </Tooltip>
                    </div>
                }
                action={[
                    {
                        title: strings.screens.settings.sections.signup.changeEmail.action,
                        btnType: 'default',
                        onClick: accounts?.password ? openEmailModal : openEmailCreateModal,
                    },
                ]}
            />

            <Divider></Divider>

            <SettingItem
                gap={2}
                buttonAlign="start"
                descriptionStyle={{ color: accounts?.password ? 'var(--text)' : 'var(--secondary-text)' }}
                description={
                    accounts?.password
                        ? strings.screens.settings.sections.signup.changePassword.description
                        : strings.screens.settings.sections.signup.changePassword.description_not_setted
                }
                title={strings.screens.settings.sections.signup.changePassword.title}
                action={[
                    {
                        title: accounts?.password ? strings.screens.settings.sections.signup.changePassword.action : strings.general.create,
                        btnType: 'default',
                        onClick: accounts?.password ? openPasswordModal : openPasswordCreateModal,
                    },
                ]}
            />

            <Divider></Divider>

            <SettingItem
                gap={2}
                buttonAlign="start"
                descriptionStyle={{ color: currentEntity?.phoneNumber ? 'var(--text)' : 'var(--secondary-text)' }}
                description={
                    currentEntity?.phoneNumber ? '+' + currentEntity?.phoneNumber : strings.screens.settings.sections.signup.changePhone.description_not_setted
                }
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Paragraph strong>{strings.screens.settings.sections.signup.changePhone.title}</Paragraph>

                        <Tooltip placement="right" title={strings.screens.settings.sections.signup.changePhone.help}>
                            <InfoCircleOutlined
                                onClick={(event) => event.stopPropagation()}
                                style={{ fontSize: breakpoints.isMobile ? 16 : 14, marginLeft: 4 }}
                            />
                        </Tooltip>
                    </div>
                }
                action={[
                    {
                        title: currentEntity?.phoneNumber ? strings.general.edit : strings.general.add,
                        btnType: 'default',
                        onClick: () => setPhoneModal(true),
                    },
                ]}
            />

            <Divider></Divider>

            <Paragraph style={{ marginBottom: 16 }} strong>
                {strings.screens.settings.sections.accounts.title}
            </Paragraph>

            {accounts && (
                <div style={{ ...(!isMobile && { paddingLeft: 24 }) }}>
                    <Row justify={'space-between'} style={{ marginBottom: 2 }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <SVG
                                style={{ display: 'flex', width: isMobile ? 16 : 14, height: isMobile ? 16 : 14, marginRight: 4 }}
                                content={ImageLogoLinkedin}
                            ></SVG>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>LinkedIn</p>
                        </span>
                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={linkedinState}
                            loading={linkedinStateLoading}
                            onClick={linkedinState ? () => onDisconnect('linkedin') : () => onClick('linkedin')}
                        />
                    </Row>
                    <Row style={{ marginBottom: isMobile ? 32 : 0 }}>
                        <p>
                            {linkedinState && !linkedinStateLoading
                                ? (strings.formatString(strings.screens.settings.sections.accounts.social.connected, 'LinkedIn') as string)
                                : (strings.formatString(strings.screens.settings.sections.accounts.social.disconnected, 'LinkedIn') as string)}
                        </p>
                    </Row>

                    {!isMobile && <Divider></Divider>}
                    <Row justify={'space-between'} style={{ marginBottom: 2 }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <SVG
                                style={{ display: 'flex', width: isMobile ? 16 : 14, height: isMobile ? 16 : 14, marginRight: 4 }}
                                content={ImageLogoGoogle}
                            ></SVG>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>Google</p>
                        </span>
                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={googleState}
                            loading={googleStateLoading}
                            onClick={googleState ? () => onDisconnect('google') : () => onClick('google')}
                        />
                    </Row>
                    <Row style={{ marginBottom: isMobile ? 32 : 0 }}>
                        <p>
                            {googleState && !googleStateLoading
                                ? (strings.formatString(strings.screens.settings.sections.accounts.social.connected, 'Google') as string)
                                : (strings.formatString(strings.screens.settings.sections.accounts.social.disconnected, 'Google') as string)}
                        </p>
                    </Row>
                    {!isMobile && <Divider></Divider>}

                    <Row justify={'space-between'} style={{ marginBottom: 2 }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <SVG
                                style={{ display: 'flex', width: isMobile ? 16 : 14, height: isMobile ? 16 : 14, marginRight: 4 }}
                                content={ImageLogoFacebook}
                            ></SVG>
                            <p style={{ fontWeight: 500, marginBottom: 0, alignSelf: 'center' }}>Facebook</p>
                        </span>
                        <Switch
                            checkedChildren={<Check style={{ display: 'flex' }} />}
                            unCheckedChildren={<Close style={{ display: 'flex' }} />}
                            checked={facebookState}
                            loading={facebookStateLoading}
                            onClick={facebookState ? () => onDisconnect('facebook') : () => onClick('facebook')}
                        />
                    </Row>
                    <Row>
                        <p>
                            {facebookState && !facebookStateLoading
                                ? (strings.formatString(strings.screens.settings.sections.accounts.social.connected, 'Facebook') as string)
                                : (strings.formatString(strings.screens.settings.sections.accounts.social.disconnected, 'Facebook') as string)}
                        </p>
                    </Row>

                    {showAlert && !canEdit && (
                        <Alert
                            message={
                                <span>
                                    {strings.screens.settings.sections.accounts.error.part1}{' '}
                                    <a onClick={openPasswordCreateModal}>{strings.screens.settings.sections.accounts.error.part2}</a>{' '}
                                    {strings.screens.settings.sections.accounts.error.part3}
                                </span>
                            }
                            style={{ marginTop: 10 }}
                            type="error"
                            closable
                            onClose={onCloseAlert}
                        />
                    )}
                </div>
            )}
            <Divider></Divider>

            <SettingItem
                gap={2}
                buttonAlign="start"
                title={<Paragraph style={{ marginBottom: 0, fontWeight: 500 }}>{strings.screens.settings.sections.accounts.delete.title}</Paragraph>}
                action={[
                    {
                        title: strings.general.delete,
                        btnType: 'default',
                        onClick: openDeleteAccountModal,
                    },
                ]}
            />
        </div>
    )
}

export { AccountScreen }
