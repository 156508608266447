import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'


interface MenuToggleIcon {
    style?: CSSProperties
    className: string
    onClick(): void
}

export const MenuLeftIcon: React.FC<MenuToggleIcon> = (props) => (
    <Icon
        component={() => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--neutral-13)" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.3314 12.8687C20.8837 12.8687 21.3314 12.421 21.3314 11.8687C21.3314 11.3164 20.8837 10.8687 20.3314 10.8687L8.74578 10.8687L9.86695 9.7475C10.2575 9.35697 10.2575 8.7238 9.86694 8.33328C9.47641 7.94276 8.84324 7.94277 8.45272 8.33329L5.63411 11.152C5.55111 11.2327 5.48209 11.3278 5.43103 11.4332C5.37575 11.5473 5.3415 11.6734 5.33335 11.8067C5.32991 11.8624 5.3311 11.9183 5.33692 11.9738C5.34306 12.0326 5.35429 12.0898 5.37016 12.1451C5.41534 12.3029 5.50006 12.4517 5.62432 12.5759L8.45272 15.4044C8.84324 15.7949 9.47641 15.7949 9.86694 15.4044C10.2575 15.0139 10.2575 14.3807 9.86695 13.9902L8.74545 12.8687L20.3314 12.8687Z"
                    fill="current"
                />
                <path
                    d="M2.00001 18.4999C2.00001 19.0522 2.44773 19.4999 3.00001 19.4999C3.5523 19.4999 4.00001 19.0522 4.00001 18.4999V5.49988C4.00001 4.94759 3.5523 4.49988 3.00001 4.49988C2.44773 4.49988 2.00001 4.94759 2.00001 5.49988V11.9999V18.4999Z"
                    fill="current"
                />
            </svg>
        )}
        {...props}
    />
)