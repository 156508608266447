import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    filled?: boolean
    height?: number
    width?: number
}

export const CatalogueItem5 = (props: Props) => {
    return (
        <Icon
            component={() => {
                if (props.filled) {
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" width={'100%'} viewBox="0 0 24 24" fill={'currentColor'}>
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.2806 1.42676C11.6786 0.857748 12.5212 0.857747 12.9193 1.42676L14.5712 3.78788C14.8123 4.1326 15.2434 4.28951 15.6498 4.18046L18.4329 3.43352C19.1036 3.25351 19.749 3.79513 19.6882 4.4869L19.4359 7.35742C19.3991 7.7765 19.6285 8.17382 20.0098 8.35146L22.6219 9.5682C23.2514 9.86143 23.3977 10.6912 22.9065 11.1821L20.8681 13.2188C20.5705 13.5162 20.4908 13.968 20.6688 14.3492L21.8876 16.9603C22.1814 17.5896 21.7601 18.3193 21.0682 18.3795L18.1975 18.6295C17.7784 18.666 17.427 18.9609 17.3182 19.3673L16.5736 22.151C16.3941 22.8219 15.6023 23.1101 15.0336 22.7115L12.6738 21.0577C12.3293 20.8163 11.8705 20.8163 11.526 21.0577L9.16625 22.7115C8.59756 23.1101 7.80577 22.8219 7.62631 22.151L6.88163 19.3673C6.77291 18.9609 6.42146 18.666 6.00235 18.6295L3.13163 18.3795C2.43981 18.3193 2.01851 17.5896 2.31225 16.9603L3.53112 14.3492C3.70907 13.968 3.6294 13.5162 3.3318 13.2188L1.29339 11.1821C0.802147 10.6912 0.948464 9.86143 1.57795 9.5682L4.19005 8.35146C4.5714 8.17382 4.8008 7.7765 4.76396 7.35742L4.51165 4.4869C4.45084 3.79513 5.09632 3.25351 5.76702 3.43352L8.55011 4.18046C8.95643 4.28951 9.38754 4.1326 9.62871 3.78788L11.2806 1.42676ZM10.8578 4.64775L12.0999 2.87223L13.3421 4.64775C13.945 5.50953 15.0228 5.90182 16.0386 5.62919L18.1314 5.06751L17.9417 7.22607C17.8496 8.27377 18.4231 9.26708 19.3764 9.71118L21.3407 10.6261L19.8078 12.1577C19.0639 12.9011 18.8647 14.0307 19.3096 14.9837L20.2261 16.9472L18.0674 17.1352C17.0196 17.2264 16.141 17.9637 15.8692 18.9797L15.3092 21.073L13.5347 19.8294C12.6734 19.2258 11.5265 19.2258 10.6652 19.8294L8.89066 21.073L8.33068 18.9797C8.05888 17.9637 7.18025 17.2264 6.13247 17.1352L3.97376 16.9472L4.89032 14.9837C5.33519 14.0307 5.13603 12.9011 4.39203 12.1577L2.85918 10.6261L4.82342 9.71118C5.77681 9.26708 6.35029 8.27378 6.2582 7.22607L6.06846 5.06751L8.16129 5.62919C9.17708 5.90182 10.2549 5.50953 10.8578 4.64775Z"
                            />
                            <path d="M10.1276 9.98239C10.5513 9.55867 10.5513 8.87168 10.1276 8.44796C9.70385 8.02424 9.01686 8.02424 8.59314 8.44796C8.16943 8.87168 8.16943 9.55867 8.59314 9.98239C9.01686 10.4061 9.70385 10.4061 10.1276 9.98239Z" />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.0999 2.87223L10.8578 4.64775C10.2549 5.50953 9.17708 5.90182 8.16129 5.62919L6.06846 5.06751L6.2582 7.22607C6.35029 8.27378 5.77681 9.26708 4.82342 9.71118L2.85918 10.6261L4.39203 12.1577C5.13603 12.9011 5.33519 14.0307 4.89032 14.9837L3.97376 16.9472L6.13247 17.1352C7.18025 17.2264 8.05888 17.9637 8.33068 18.9797L8.89066 21.073L10.6652 19.8294C11.5265 19.2258 12.6734 19.2258 13.5347 19.8294L15.3092 21.073L15.8692 18.9797C16.141 17.9637 17.0196 17.2264 18.0674 17.1352L20.2261 16.9472L19.3096 14.9837C18.8647 14.0307 19.0639 12.9011 19.8078 12.1577L21.3407 10.6261L19.3764 9.71118C18.4231 9.26708 17.8496 8.27377 17.9417 7.22607L18.1314 5.06751L16.0386 5.62919C15.0228 5.90182 13.945 5.50953 13.3421 4.64775L12.0999 2.87223ZM10.9587 7.61682C11.8415 8.49957 11.8415 9.93078 10.9587 10.8135C10.076 11.6963 8.64475 11.6963 7.762 10.8135C6.87925 9.93078 6.87925 8.49957 7.762 7.61682C8.64475 6.73407 10.076 6.73407 10.9587 7.61682ZM8.10769 14.9043C7.93114 15.0808 7.93114 15.3671 8.10769 15.5436L8.4913 15.9272C8.66785 16.1038 8.95409 16.1038 9.13064 15.9272L16.1634 8.89446C16.34 8.71791 16.34 8.43167 16.1634 8.25512L15.7798 7.87151C15.6033 7.69496 15.317 7.69496 15.1405 7.87151L8.10769 14.9043ZM16.4379 13.096C17.3206 13.9787 17.3206 15.4099 16.4379 16.2927C15.5551 17.1754 14.1239 17.1754 13.2412 16.2927C12.3584 15.4099 12.3584 13.9787 13.2412 13.096C14.1239 12.2132 15.5551 12.2132 16.4379 13.096Z"
                            />
                            <path d="M15.6067 15.4616C16.0305 15.0378 16.0305 14.3508 15.6067 13.9271C15.183 13.5034 14.496 13.5034 14.0723 13.9271C13.6486 14.3508 13.6486 15.0378 14.0723 15.4616C14.496 15.8853 15.183 15.8853 15.6067 15.4616Z" />
                        </svg>
                    )
                } else {
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" width={'100%'} viewBox="0 0 24 24" fill={'currentColor'}>
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.9587 7.61682C11.8415 8.49957 11.8415 9.93078 10.9587 10.8135C10.076 11.6963 8.64475 11.6963 7.762 10.8135C6.87925 9.93078 6.87925 8.49957 7.762 7.61682C8.64475 6.73407 10.076 6.73407 10.9587 7.61682ZM10.1276 8.44796C10.5513 8.87168 10.5513 9.55867 10.1276 9.98239C9.70385 10.4061 9.01686 10.4061 8.59314 9.98239C8.16943 9.55867 8.16943 8.87168 8.59314 8.44796C9.01686 8.02424 9.70385 8.02424 10.1276 8.44796Z"
                            />
                            <path d="M8.10769 14.9043C7.93114 15.0808 7.93114 15.3671 8.10769 15.5436L8.4913 15.9272C8.66785 16.1038 8.95409 16.1038 9.13064 15.9272L16.1634 8.89446C16.34 8.71791 16.34 8.43167 16.1634 8.25512L15.7798 7.87151C15.6033 7.69496 15.317 7.69496 15.1405 7.87151L8.10769 14.9043Z" />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.4379 13.096C17.3206 13.9787 17.3206 15.4099 16.4379 16.2927C15.5551 17.1754 14.1239 17.1754 13.2412 16.2927C12.3584 15.4099 12.3584 13.9787 13.2412 13.096C14.1239 12.2132 15.5551 12.2132 16.4379 13.096ZM15.6067 13.9271C16.0305 14.3508 16.0305 15.0378 15.6067 15.4616C15.183 15.8853 14.496 15.8853 14.0723 15.4616C13.6486 15.0378 13.6486 14.3508 14.0723 13.9271C14.496 13.5034 15.183 13.5034 15.6067 13.9271Z"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.2806 1.42676C11.6786 0.857748 12.5212 0.857747 12.9193 1.42676L14.5712 3.78788C14.8123 4.1326 15.2434 4.28951 15.6498 4.18046L18.4329 3.43352C19.1036 3.25351 19.749 3.79513 19.6882 4.4869L19.4359 7.35742C19.3991 7.7765 19.6285 8.17382 20.0098 8.35146L22.6219 9.5682C23.2514 9.86143 23.3977 10.6912 22.9065 11.1821L20.8681 13.2188C20.5705 13.5162 20.4908 13.968 20.6688 14.3492L21.8876 16.9603C22.1814 17.5896 21.7601 18.3193 21.0682 18.3795L18.1975 18.6295C17.7784 18.666 17.427 18.9609 17.3182 19.3673L16.5736 22.151C16.3941 22.8219 15.6023 23.1101 15.0336 22.7115L12.6738 21.0577C12.3293 20.8163 11.8705 20.8163 11.526 21.0577L9.16625 22.7115C8.59756 23.1101 7.80577 22.8219 7.62631 22.151L6.88163 19.3673C6.77291 18.9609 6.42146 18.666 6.00235 18.6295L3.13163 18.3795C2.43981 18.3193 2.01851 17.5896 2.31225 16.9603L3.53112 14.3492C3.70907 13.968 3.6294 13.5162 3.3318 13.2188L1.29339 11.1821C0.802147 10.6912 0.948464 9.86143 1.57795 9.5682L4.19005 8.35146C4.5714 8.17382 4.8008 7.7765 4.76396 7.35742L4.51165 4.4869C4.45084 3.79513 5.09632 3.25351 5.76702 3.43352L8.55011 4.18046C8.95643 4.28951 9.38754 4.1326 9.62871 3.78788L11.2806 1.42676ZM10.8578 4.64775L12.0999 2.87223L13.3421 4.64775C13.945 5.50953 15.0228 5.90182 16.0386 5.62919L18.1314 5.06751L17.9417 7.22607C17.8496 8.27377 18.4231 9.26708 19.3764 9.71118L21.3407 10.6261L19.8078 12.1577C19.0639 12.9011 18.8647 14.0307 19.3096 14.9837L20.2261 16.9472L18.0674 17.1352C17.0196 17.2264 16.141 17.9637 15.8692 18.9797L15.3092 21.073L13.5347 19.8294C12.6734 19.2258 11.5265 19.2258 10.6652 19.8294L8.89066 21.073L8.33068 18.9797C8.05888 17.9637 7.18025 17.2264 6.13247 17.1352L3.97376 16.9472L4.89032 14.9837C5.33519 14.0307 5.13603 12.9011 4.39203 12.1577L2.85918 10.6261L4.82342 9.71118C5.77681 9.26708 6.35029 8.27378 6.2582 7.22607L6.06846 5.06751L8.16129 5.62919C9.17708 5.90182 10.2549 5.50953 10.8578 4.64775Z"
                            />
                        </svg>
                    )
                }
            }}
            {...props}
        />
    )
}
