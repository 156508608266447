import { useContext, useEffect, useRef, useState } from 'react'
import { Affix, Breadcrumb } from 'antd'
import { Redirect, useHistory, useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { Post, PostContext } from '@components/content/posts/Post'
import { boardDetails, postDetails, postList, postReaction, setErrorAction } from '@state/actions'
import { CommunitySettingsPostDetailRouteParams, PostRouteParams } from 'src/typings/routing/Router'
import { Colors } from '@util/color/Colors'
import { Link } from 'react-router-dom'
import { Router } from '@Router'
import { PostScreenSkeleton } from '@components/skeletons/PostScreenSkeleton'
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout'
import { CommunityDetails } from '@screens/community/components/community_details/CommunityDetails'
import { CommunityRules } from '@screens/community/components/community_rules/CommunityRules'
import useBreakpoints from '@hooks/useBreakpoints'
import strings from '@resources/localization'
import Meta from 'react-meta-tags'
import { CommunityHeader } from '@components/content/entity/community_header/CommunityHeader'
import { dynamicTabName } from '@util/string/Functions'
import { NotificationContext } from 'src/SocketComponent'
import { PostKind, PostView } from '@api/model/responses/post/PostView'
import queryString from 'query-string'
import { Playlist } from '@screens/community/boards/media/media_post/components/playlist/Playlist'
import { CertificateCard } from '@screens/community/components/certificate_card/CertificateCard'
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton'
import Paragraph from 'antd/lib/typography/Paragraph'

namespace PostDetail {
    export interface Props {
        isPanelAdmin?: boolean
    }
}

interface Layout {
    autor: 'none' | 'header'
    topics: 'none' | 'header'
    content: 'none' | 'header' | 'body'
    title: 'none' | 'header' | 'footer'

    likes: 'none' | 'footer'
    comments: 'none' | 'inner' | 'out'
    materials: 'none' | 'body'
    right?: {
        community?: boolean
        rules?: boolean
        playlist?: boolean
        certificate?: boolean
    }
}

const defaultDiscussionLayout: Layout = {
    autor: 'header',
    topics: 'header',
    content: 'header',
    likes: 'footer',
    title: 'none',
    comments: 'inner',
    materials: 'none',
    right: {
        community: true,
        rules: true,
    },
}

const defaultRateLayout: Layout = {
    autor: 'header',
    topics: 'none',
    content: 'header',
    likes: 'none',
    title: 'none',
    comments: 'none',
    materials: 'none',
}

const defaultMediaLayout: Layout = {
    autor: 'none',
    topics: 'none',
    content: 'body',
    likes: 'none',
    title: 'footer',

    comments: 'out',
    materials: 'body',
    right: {
        playlist: true,
        certificate: true,
    },
}

const defaultCatalogLayout: Layout = {
    autor: 'none',
    topics: 'none',
    content: 'body',
    title: 'footer',
    materials: 'body',
    likes: 'none',
    comments: 'out',
}

export const kindToLayout: { [key in PostKind]: Layout | undefined } = {
    basic: defaultDiscussionLayout,
    image: defaultDiscussionLayout,
    attachment: defaultDiscussionLayout,
    article: defaultMediaLayout,
    poll: defaultDiscussionLayout,
    event: defaultDiscussionLayout,
    preview: defaultDiscussionLayout,
    video: defaultDiscussionLayout,
    rate: defaultRateLayout,
    quiz: { ...defaultMediaLayout, comments: 'none', title: 'none' },
    lesson: defaultMediaLayout,
    catalog_item: defaultCatalogLayout,
}

export const PostDetail = (props: PostDetail.Props) => {
    const { isPanelAdmin } = props

    const postProps = useParams<PostRouteParams>()
    const panelAdminProps = useParams<CommunitySettingsPostDetailRouteParams>()

    let cm_pk = postProps.cm_pk
    let b_pk = postProps.b_pk
    let p_pk = postProps.p_pk
    const isFeed = !b_pk

    if (isPanelAdmin) {
        cm_pk = panelAdminProps.cm_pk
        b_pk = panelAdminProps.item_pk
        p_pk = panelAdminProps.p_pk
    }
    const scope = p_pk ? 'detail' : 'all'

    const dispatch = useAppDispatch()
    const community = useAppSelector((state) => state.community.items[cm_pk])
    const isLogged = useAppSelector((state) => !!state.auth.authToken)

    const lessons = useAppSelector((state) => state.posts.all[b_pk])
    const post = useAppSelector((state) => state.posts.detail[b_pk]?.items[p_pk])
    const history = useHistory()

    const notifications = useContext(NotificationContext)
    const kind = post?.item?.board?.kind
    const board = useAppSelector((state) => state.community.items[cm_pk]?.boards?.detail?.[b_pk])

    // RESPONSIVE
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const isSmall = breakpoints.isSmall
    const queryParams = queryString.parse(location.search)
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)

    const isAdmin = community?.item?.access?.includes('edit')

    const [currentMask, setCurrentMask] = useState(currentEntity)

    const see = (result: { data: PostView }) => {
        if (isLogged && kind == 'discussion' && result.data.interactions?.pinView == undefined) {
            void dispatch(
                postReaction({
                    urlParams: { cm_pk, b_pk, target_pk: p_pk },
                    bodyParams: { kind: 'pin_view', content: (queryParams?.utm_medium as string) || undefined },
                    extraParams: { scope: 'detail' },
                })
            )
        }
    }

    useEffect(() => {
        void dispatch(
            boardDetails({
                urlParams: { cm_pk, b_pk },
            })
        )
        void dispatch(
            postDetails({
                urlParams: { cm_pk, b_pk, p_pk },
                extraParams: { scope: 'detail' },
                options: {
                    then: {
                        action: see,
                        error: (params) => dispatch(setErrorAction(params.error)),
                    },
                },
            })
        )
    }, [b_pk, p_pk])

    useEffect(() => {
        if (kind == 'course' || kind == 'forum') {
            void dispatch(postList({ urlParams: { cm_pk, b_pk: b_pk }, searchParams: { kind: 'meta_playlist' } }))
        }
    }, [b_pk, p_pk])

    const postRef = useRef<HTMLDivElement>()

    useEffect(() => {
        if (postRef && postRef.current) {
            const headerOffset = 80
            const elementPosition = postRef.current.getBoundingClientRect().top
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset

            window.scrollTo({
                top: offsetPosition,
            })
        }
    }, [])
    if (post && post.state.error) {
        return <PostScreenSkeleton />
    } else if (
        !community ||
        !community.item ||
        community.state?.loading ||
        !post ||
        !post.item ||
        post.state?.loading ||
        !board ||
        !board.item ||
        board.state?.loading
    ) {
        return <PostScreenSkeleton />
    } else {
        const showCertificate =
            post.item.board?.merits?.find((m) => m.merit.kind == 'certificate') && community.item?.ui?.showcert !== false && !!board?.item?.course?.assign?.cert

        const layout = kindToLayout[post?.item?.kind]

        const rightPanel = () => {
            if (layout.right) {
                return (
                    <Affix offsetTop={72} style={{ zIndex: 0 }}>
                        <div
                            style={{
                                display: 'grid',
                                columnGap: isSmall ? 0 : 16,
                                rowGap: isMobile ? 16 : 24,
                                flexDirection: 'column',
                            }}
                        >
                            {layout.right.community && (
                                <CommunityDetails
                                    memberCount={community.item?.memberCount}
                                    description={community.item?.profile?.bio}
                                    contact={community.item?.contact}
                                />
                            )}

                            {layout.right.rules && community.item?.rule && <CommunityRules isCompact={true} rules={community.item?.rule} />}

                            {layout.right.playlist && lessons && <Playlist lessons={lessons?.items} kind={kind} />}

                            {layout.right.certificate && !isMobile && showCertificate && !isPanelAdmin && (
                                <CertificateCard entityPk={b_pk} pricing={post?.item?.board?.pricing} isStarted={true} />
                            )}
                        </div>
                    </Affix>
                )
            }

            return null
        }

        const breadcrumbItems = () => {
            if (kind == 'discussion') {
                return [
                    {
                        to: Router.Path.community_home({ cm_pk }),
                        title: community.item.name,
                    },
                    {
                        to: Router.Path.community_board({ cm_pk, b_pk }),
                        title: post?.item?.board?.name,
                    },
                    {
                        to: Router.Path.community_post_details({ cm_pk, b_pk, p_pk }),
                        title: strings.general.post.singular,
                    },
                ]
            }
            if (kind == 'course') {
                return [
                    {
                        to: Router.Path.course_list({ cm_pk }),
                        title: strings.general.course.plural,
                    },
                    {
                        to: Router.Path.course_details({ cm_pk, b_pk }),
                        title: post?.item?.board?.name,
                    },
                    ...(post.item.kind == 'quiz'
                        ? [
                              {
                                  to: Router.Path.course_post_details({ cm_pk, b_pk, p_pk }),
                                  title: strings.screens.evaluation.title,
                              },
                          ]
                        : []),
                    ...(post.item.kind != 'quiz'
                        ? [
                              {
                                  to: Router.Path.course_post_details({ cm_pk, b_pk, p_pk }),
                                  title: `${strings.screens.boards[kind]?.post?.title?.singular} ${post.item?.contentSequence + 1}`,
                              },
                          ]
                        : []),
                ]
            }

            if (kind == 'forum') {
                return [
                    {
                        to: Router.Path.forum_list({ cm_pk }),
                        title: strings.general.forum.plural,
                    },
                    {
                        to: Router.Path.forum_details({ cm_pk, b_pk }),
                        title: post?.item?.board?.name,
                    },
                    {
                        to: Router.Path.forum_post_details({ cm_pk, b_pk, p_pk }),
                        title: `${strings.screens.boards[kind]?.post?.title?.singular} ${post.item?.contentSequence + 1}`,
                    },
                ]
            }

            if (kind == 'story') {
                return [
                    {
                        to: Router.Path.story_list({ cm_pk }),
                        title: strings.general.forum.plural,
                    },
                    {
                        to: Router.Path.story_post_details({ cm_pk, b_pk, p_pk }),
                        title: `${post?.item?.lesson?.title}`,
                    },
                ]
            }

            if (kind == 'catalog') {
                return [
                    {
                        to: Router.Path.catalogue({ cm_pk, b_pk }),
                        title: post?.item?.board?.name,
                    },
                    {
                        to: Router.Path.catalogue_details({ cm_pk, b_pk, p_pk }),
                        title: post?.item?.title,
                    },
                ]
            }
        }

        return (
            <PostContext.Provider
                value={{
                    mask: { get: currentMask, set: setCurrentMask },
                    post: post.item,
                    isPanelAdmin,
                    isAdmin,
                    cm_pk,
                    b_pk,
                    p_pk,
                    isFeed: isFeed,
                    scope: scope,
                }}
            >
                <Meta>
                    <title>{dynamicTabName(strings.screens.community.browserTitle, notifications)}</title>
                    <meta name="description" content={strings.screens.community.browserDescription} />
                </Meta>

                <ContentLayout
                    single={kind == 'catalog' || kind == 'story'}
                    left={
                        <div style={{ display: 'grid', rowGap: isLogged ? 0 : isMobile ? 16 : 24, position: 'relative' }}>
                            {!isLogged && <CommunityHeader entity={community} />}
                            {isLogged && (
                                <div style={{ padding: isMobile ? 16 : '16px 24px', borderRadius: '4px 4px 0px 0px', background: Colors('BACKGROUND') }}>
                                    {!isPanelAdmin && (
                                        <Breadcrumb>
                                            {breadcrumbItems().map((i, index) => {
                                                return (
                                                    <Breadcrumb.Item key={index}>
                                                        <Link to={i.to}>{i.title}</Link>
                                                    </Breadcrumb.Item>
                                                )
                                            })}
                                        </Breadcrumb>
                                    )}

                                    {isPanelAdmin && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <GoBackButton
                                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}
                                                type="default"
                                                showText={false}
                                                onClick={() => {
                                                    history.push(
                                                        Router.Path.community_settings_detail({
                                                            cm_pk,
                                                            section: post.item.board.kind == 'catalog' ? 'catalogues' : 'onboarding',
                                                            item_pk: post.item.boardPk,
                                                        })
                                                    )
                                                }}
                                            />

                                            <Paragraph strong className="turingo-text">
                                                {post.item.title || post.item?.lesson?.title}
                                            </Paragraph>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div ref={postRef}>
                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, minmax(0, 1fr))', rowGap: 16, columnGap: 16 }}>
                                    <Post />
                                </div>
                            </div>
                        </div>
                    }
                    right={rightPanel()}
                />
            </PostContext.Provider>
        )
    }
}
