// Libraries

// React
import React, { CSSProperties, useEffect, useState } from 'react'

// Antd
import { Row, Tooltip } from 'antd'
import { TuringoTag } from '@components/content/tag/TuringoTag'
import { Topic } from '@api/model/responses/post/PostView'
import { useWindowSize } from 'react-use'

// MARK: - Definition

namespace TopicComponent {
    export interface Props {
        postPk: string
        topics: Topic[]
        style?: CSSProperties
        offset?: number
        maxDivSize?: number
    }
}

// MARK: - Implementation

const TopicComponent: React.FC<TopicComponent.Props> = (props: TopicComponent.Props) => {
    const { topics, postPk, style, maxDivSize = 0, offset = 80 } = props
    const [etiquetasVisibles, setEtiquetasVisibles] = useState<Topic[]>(topics)
    const [etiquetasOcultas, setEtiquetasOcultas] = useState<Topic[]>([])
    const [divSize, setDivSize] = useState(maxDivSize)

    const [change, setChange] = useState<boolean>(false)

    const windowSize = useWindowSize()

    useEffect(() => {
        setChange(!change)
        setEtiquetasVisibles(topics)
    }, [topics])

    useEffect(() => {
        const div = document.getElementById(`topics-container-${postPk}`)

        if (div) {
            if (div.offsetWidth != divSize) {
                setDivSize(div.offsetWidth)
                setEtiquetasVisibles(topics)
            }
        }
    }, [windowSize, topics, change])

    useEffect(() => {
        if (divSize) {
            let totalSize = 0
            const etiquetasVisibles = []
            const etiquetasOcultas = []
            topics.forEach((topic) => {
                const etiquetaElement = document.getElementById(`topic-${postPk}-${topic.publicKey}`)

                if (etiquetaElement) {
                    const etiquetaSize = etiquetaElement.offsetWidth

                    if (totalSize + etiquetaSize <= divSize - offset) {
                        totalSize += etiquetaSize
                        etiquetasVisibles.push(topic)
                    } else {
                        etiquetasOcultas.push(topic)
                    }
                } else {
                    etiquetasOcultas.push(topic)
                }
            })

            setEtiquetasVisibles(etiquetasVisibles)
            setEtiquetasOcultas(etiquetasOcultas)
        }
    }, [topics, divSize, change])

    return (
        <Row style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap', ...style }} id={`topics-container-${postPk}`}>
            {etiquetasVisibles.map((topic, index) => {
                return (
                    <TuringoTag style={{ marginRight: 8 }} kind="topic" id={`topic-${postPk}-${topic.publicKey}`} key={index}>
                        {topic.name}
                    </TuringoTag>
                )
            })}
            {etiquetasOcultas.length > 0 && (
                <Tooltip
                    trigger={['click', 'hover']}
                    style={{ cursor: 'pointer' }}
                    title={etiquetasOcultas.map((topic) => {
                        return <div key={`hidden-${postPk}-${topic.publicKey}`}> {topic.name}</div>
                    })}
                >
                    <span style={{ cursor: 'pointer' }} >+{etiquetasOcultas.length}</span>
                </Tooltip>
            )}
        </Row>
    )
}

export { TopicComponent }
