// Modules
import React, { useState } from 'react'
import { Checkbox } from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { userDelete } from '@state/actions'
import { InformationModal } from './information_modal/InformationModal'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useHistory } from 'react-router'
import { Router } from '@Router'
import strings from '@resources/localization'

export const DeleteAccountModal = () => {
    const emailModal = useAppSelector((state) => state.modal.delete_account)
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)
    const communities = useAppSelector((state) => state.auth?.currentEntity?.communities)

    const dispatch = useAppDispatch()
    const history = useHistory()

    const closeEmailModal = () => {
        setConfirmDelete(false)
        dispatch(modalAction({ modal: 'delete_account', state: false }))
    }

    const changeComplete = () => {
        history.push(Router.Path.community_exit({ cm_pk: currentCommunity }), { exit: true })
        closeEmailModal()
    }

    const [confirmDelete, setConfirmDelete] = useState<boolean>(false)

    const onSubmit = () => {
        void dispatch(
            userDelete({
                options: {
                    then: {
                        action: changeComplete,
                    },
                },
            })
        )
    }

    const onChangeMessage = (e) => {
        setConfirmDelete(e.target.checked)
    }

    return (
        <>
            <InformationModal
                kind={'warning'}
                open={emailModal}
                onClick={onSubmit}
                onCancel={closeEmailModal}
                onClickDisabled={!confirmDelete}
                onCancelText={strings.screens.settings.sections.accounts.delete.keep}
                onClickText={strings.general.delete}
                title={strings.screens.settings.sections.accounts.delete.title}
                description={strings.screens.settings.sections.accounts.delete.description}
                subdescription={
                    communities?.length > 1
                        ? strings.screens.settings.sections.accounts.delete.subdescriptionMany
                        : strings.screens.settings.sections.accounts.delete.subdescriptionOne
                }
            >
                <Paragraph className="turingo-text" style={{ marginBottom: 16 }}>
                    {strings.screens.settings.sections.accounts.delete.content}
                </Paragraph>

                <Checkbox style={{ marginBottom: 10 }} value={confirmDelete} onChange={onChangeMessage}>
                    {strings.screens.settings.sections.accounts.delete.confirm}
                </Checkbox>
            </InformationModal>
        </>
    )
}
