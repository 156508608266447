import { API } from '@api/API'
import { UserView } from '@api/model/responses/user/UserView'
import { getRequest, useRequestState } from '@api/requests/AsyncRequests'
import { AvatarTuringo } from '@components/avatar/Avatar'
import strings from '@resources/localization'
import { Router } from '@Router'
import { BoardRouteParams } from '@typings/routing/Router'
import { Button, Card, Skeleton, Tooltip } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router'

export const MembersCard = () => {
    const { cm_pk, b_pk } = useParams<BoardRouteParams>()
    const history = useHistory()

    const [members, setMembers] = useRequestState<UserView[]>()

    void getRequest(API.boardMembers, setMembers, [cm_pk, b_pk])({ urlParams: { cm_pk, b_pk } })

    if (!members || members.loading) {
        return (
            <Card title={strings.screens.boards.discussion.subscribers} bordered={false}>
                {/*                 <Paragraph>{strings.formatString(strings.screens.community.members.description, memberCount)}</Paragraph>
                 */}{' '}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16, flexDirection: 'column' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: 16, columnGap: 8 }}>
                        {[...(Array(12) as number[]).fill(undefined)].map((member, index) => {
                            return <Skeleton.Avatar key={`skeleton-member-${index}`} active size={40} shape={'circle'} />
                        })}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                    <Button onClick={() => history.push(Router.Path.community_board_members({ cm_pk, b_pk }))}>
                        {strings.screens.community.members.action}
                    </Button>
                </div>
            </Card>
        )
    }
    return (
        <Card title={strings.screens.boards.discussion.subscribers} bordered={false}>
            {/*             <Paragraph>{strings.formatString(strings.screens.community.members.description, memberCount)}</Paragraph>
             */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16, flexDirection: 'column' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: 16, columnGap: 8 }}>
                    {members &&
                        members.data?.map((member, index) => {
                            if (index < 12)
                                return (
                                    <Tooltip placement="top" title={member.name} getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}>
                                        <AvatarTuringo
                                            key={`member-${member.publicKey}`}
                                            kind="circle"
                                            name={member.name}
                                            avatar={member.avatarUrl}
                                            size={16}
                                            width={40}
                                            height={40}
                                            style={{ lineHeight: '40px', cursor: 'pointer' }}
                                            onClick={() => history.push(Router.Path.user_details({ u_pk: member.publicKey, cm_pk }))}
                                        ></AvatarTuringo>{' '}
                                    </Tooltip>
                                )
                        })}
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                <Button onClick={() => history.push(Router.Path.community_board_members({ cm_pk, b_pk }))}>{strings.screens.community.members.action}</Button>
            </div>
        </Card>
    )
}
