// Modules
import React, { useState } from 'react'
import { Alert, Button, Form, Input, Modal, Row } from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { setCurrentLanguage } from '@state/reducers/UserInterface/UserInterfaceReducer'
import strings from '@resources/localization'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { RootReducer } from '@state/reducers/Root'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { authUpdateEmail } from '@state/actions'
import { useRequestState } from '@api/requests/AsyncRequests'
import { InformationModal } from './information_modal/InformationModal'
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem'
import { FooterModal } from './components/FooterModal'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'

export const EmailCreateModal = () => {
    const emailModal = useAppSelector((state) => state.modal.email_create)

    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    const closeEmailModal = () => {
        dispatch(modalAction({ modal: 'email_create', state: false }))
    }

    const changeComplete = (res) => {
        closeEmailModal()
        setInfoModal(true)
    }

    const [infoModal, setInfoModal] = useState(false)

    const [state, setState] = useRequestState(undefined, false, false)

    const onSubmit = (values) => {
        dispatch(
            authUpdateEmail({
                bodyParams: {
                    email: values.email,
                    password: values.password,
                },
                options: {
                    redux: {
                        stateHandler: setState,
                    },
                    then: {
                        action: changeComplete,
                    },
                },
            })
        )
    }

    return (
        <>
            <InformationModal
                kind={'success'}
                open={infoModal}
                onClick={() => setInfoModal(false)}
                title={strings.screens.settings.sections.signup.changeEmail.success.title}
                description={strings.screens.settings.sections.signup.changeEmail.success.description}
            />
            <TuringoModal
                width={572}
                title={strings.screens.settings.sections.signup.changeEmail.modal_create.title}
                open={emailModal}
                onCancel={closeEmailModal}
                footer={[
                    <FooterModal
                        key={'footer_modal'}
                        primary={{
                            action: () => {
                                form.validateFields()
                                    .then((values) => {
                                        form.resetFields()
                                        onSubmit(values)
                                    })
                                    .catch((info) => {})
                            },
                            customText: strings.screens.settings.sections.signup.changeEmail.modal_create.actions.accept,
                            loading: state.editing,
                        }}
                        secondary={{
                            action: closeEmailModal,
                            customText: strings.screens.settings.sections.signup.changeEmail.modal_create.actions.cancel,
                        }}
                    />,
                ]}
            >
                <p>{strings.screens.settings.sections.signup.changeEmail.modal_create.description} </p>
                <Form
                    form={form}
                    layout={'vertical'}
                    // onFinish={onFinish}
                >
                    <FormEmailItem
                        name={'email'}
                        label={strings.screens.settings.sections.signup.changeEmail.modal_create.email.label}
                        validations={['format', 'required']}
                        placeholder={strings.screens.settings.sections.signup.changeEmail.modal_create.email.placeholder}
                    />

                    <Form.Item
                        name="password"
                        label={strings.screens.settings.sections.signup.changeEmail.modal_create.password.label}
                        rules={[{ required: true, message: strings.forms.password.validations.required }]}
                    >
                        <Input.Password
                            type="password"
                            placeholder={strings.screens.settings.sections.signup.changeEmail.modal_create.password.placeholder}
                            autoComplete="off"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    {state.error && <Alert showIcon style={{ marginBottom: 10 }} message={state.error} type="error" closable />}
                </Form>
            </TuringoModal>
        </>
    )
}
