import style from './Notification.scss'
import strings from '@resources/localization'
import useBreakpoints from '@hooks/useBreakpoints'

import { useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { Badge, Popover, notification } from 'antd'
import { NotificationItem } from './NotificationItem'
import { BellOutlined, BellFilled } from '@ant-design/icons'
import { communityChatGet, getLastNotifications, markNotificationsAsSeen } from '@state/actions'
import { addChatNotification, addNotification, addUpdate, markChatAsRead } from '@state/reducers/Notifications/NotificationReducer'
import { useHistory, useLocation } from 'react-router'
import { Notification, NotificationHistoryView, NotificationView } from '@api/model/requests/Notification'
import { Router } from '@Router'
import { NotificationPanel } from './NotificationPanel'
import { addMessage, addNewPost, addPin } from '@state/reducers/Community/CommunityReducer'
import { ChatListSkeleton } from '@components/skeletons/ChatSkeleton/components/ChatListSkeleton'
import { SocketContext } from 'src/SocketComponent'
import useSound from 'use-sound';

import notificationSound from '@bundle/audio/message.mp3';

export const NotificationList = (): JSX.Element => {
    const language = useAppSelector((state) => state.ui.language)
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)

    const { general } = useAppSelector((state) => state.notification)

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const dispatch = useAppDispatch()
    const history = useHistory()
    const location = useLocation<{ roomPk: string }>()
    const roomSelected = location.state?.roomPk
    const isNotificationCenter = location.pathname.startsWith('/notifications')
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)
    const chats = useAppSelector((state) => state.community.items[currentCommunity].chats)
    const [visibleNotifications, setVisibleNotifications] = useState(false)
    const soundSetting = useAppSelector((state) => state.ui.sound)

    const socket = useContext(SocketContext)
    const [play] = useSound(notificationSound, {
        volume: 0.6,
        interrupt: true,
    });

    const showNotification = (notificationData: NotificationView) => {
        if (!isMobile && currentCommunity == notificationData.predicate?.communityPk) {
            notification.destroy()
            notification.open({
                message: (
                    <NotificationItem
                        onClose={() => {
                            void dispatch(
                                markNotificationsAsSeen({
                                    urlParams: {
                                        notificationId: notificationData.publicKey,
                                    },
                                    bodyParams: {
                                        communityPk: currentCommunity,
                                    },
                                })
                            )
                            setVisibleNotifications(false)
                        }}
                        isPopup
                        language={language}
                        dispatch={dispatch}
                        history={history}
                        compact={true}
                        item={notificationData}
                    />
                ),
                placement: 'topRight',
                top: 88,
            })
        }
    }


    /* Activates when a new message is recived from the socket, if is a community post,
       dispatch an action that updates the sidebar count, if not, show the notification and dispatch 
       an action that add to current notification list */

    useEffect(() => {
        if (socket.lastJsonMessage?.channel?.id == 'notification') {
            const notification = socket.lastJsonMessage as NotificationHistoryView.Notification

            if (notification.body.kind == 'usr.community.post' && notification.body.predicate?.kind == 'post') {
                dispatch(addUpdate({ publicKey: notification.body.predicate?.communityPk }))
            }
            if (notification.body.kind == 'usr.chat.message') {
                if (roomSelected != (notification.body.predicate as Notification.RoomReference).roomPk) {
                    dispatch(addChatNotification(notification.body))
                }
            } else {
                if (notification.body.kind == 'com.post.pinned.mandatory') {
                    dispatch(addPin(notification.body.predicate.communityPk))
                }
                dispatch(addNotification(notification.body))
                showNotification(notification.body)
            }
        }

        if (socket.lastJsonMessage?.channel?.id == 'update') {
            const notification = socket.lastJsonMessage as NotificationHistoryView.Update

            dispatch(addNewPost({ communityPk: notification.body.communityPk, boardPk: notification.body.boardPk }))

        }

        if (socket.lastJsonMessage?.channel?.id == 'chat') {
            const notification = socket.lastJsonMessage as NotificationHistoryView.ChatMessage

            if (chats?.items && chats.items[notification.body.roomPk]) {
                dispatch(addMessage({ message: notification.body }))
            } else {
                void dispatch(
                    communityChatGet({
                        urlParams: {
                            cm_pk: notification.body.communityPk,
                            r_pk: notification.body.roomPk,
                        },
                        extraParams: {
                            isFirstTime: true,
                        },
                    })
                )
            }

            if (notification.body?.roomPk == roomSelected) {
                const message = {
                    channel: 'chat.ctl',
                    body: {
                        action: 'readability.read',
                        communityPk: currentCommunity,
                        roomPk: roomSelected,
                        userPk: currentEntity.publicKey,
                    },
                }
                dispatch(markChatAsRead({ cm_pk: currentCommunity, r_pk: roomSelected }))
                socket.sendMessage(JSON.stringify(message))
            }

            if (notification.body?.userPk != currentEntity.publicKey && soundSetting) {
                play()
            }
        }
    }, [socket.lastJsonMessage])

    /* Action when the notification menu is clicked, mark all notification as seen, and open the menu, 
       if is mobile, redirect to notification center  */

    const handleMenuClick = (open: boolean) => {
        if (!open) {
            void dispatch(
                markNotificationsAsSeen({
                    urlParams: {
                        notificationId: 'all',
                    },
                    bodyParams: {
                        communityPk: currentCommunity,
                    },
                })
            )
        }
        if (!isNotificationCenter && !isMobile) {
            setVisibleNotifications(open)
        } else if (isMobile) {
            history.push(Router.Path.notifications({}))
        }
    }
    useEffect(() => {
        void dispatch(getLastNotifications({}))
    }, [])

    return (
        <Popover
            style={{ padding: '0px 12px' }}
            content={
                visibleNotifications ? (
                    <NotificationPanel onClose={() => setVisibleNotifications(false)} />
                ) : (
                    <div style={{ width: 424, padding: 16, paddingBottom: 32 }}>
                        <ChatListSkeleton nElements={2} />
                    </div>
                )
            }
            title={<div style={{ fontSize: 16 }}>{strings.notifications.title}</div>}
            trigger="click"
            placement="bottomRight"
            open={visibleNotifications}
            onOpenChange={handleMenuClick}
            arrowPointAtCenter
            getPopupContainer={(trigger) => trigger.parentElement}
        >
            <Badge count={general.aggregate[currentCommunity]?.notifications?.newly} color={'var(--notification-dot)'} offset={[-14, 14]}>
                <div className={visibleNotifications || isNotificationCenter ? style.iconActive : style.icon}>
                    {!visibleNotifications && !isNotificationCenter && <BellOutlined style={{ fontSize: 24 }} />}
                    {(visibleNotifications || isNotificationCenter) && <BellFilled style={{ fontSize: 24 }} />}
                </div>
            </Badge>
        </Popover>
    )
}
