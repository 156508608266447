import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    filled?: boolean
    height?: number
    width?: number
}

export const CatalogueItem8 = (props: Props) => {
    return (
        <Icon
            component={() => {
                if (props.filled) {
                    return (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8619 1.49298C12.6823 1.18754 12.3544 1 12 1C11.6456 1 11.3177 1.18754 11.1381 1.49298L6.13807 9.99298C5.95619 10.3022 5.95386 10.6851 6.13195 10.9965C6.31005 11.3079 6.64128 11.5 7 11.5H17C17.3587 11.5 17.69 11.3079 17.868 10.9965C18.0461 10.6851 18.0438 10.3022 17.8619 9.99298L12.8619 1.49298Z" />
                            <path d="M3 13.5C2.44772 13.5 2 13.9477 2 14.5V21.5C2 22.0523 2.44772 22.5 3 22.5H10C10.5523 22.5 11 22.0523 11 21.5V14.5C11 13.9477 10.5523 13.5 10 13.5H3Z" />
                            <path d="M18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13Z" />
                        </svg>
                    )
                } else {
                    return (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 1C12.3544 1 12.6823 1.18754 12.8619 1.49298L17.8619 9.99298C18.0438 10.3022 18.0461 10.6851 17.868 10.9965C17.69 11.3079 17.3587 11.5 17 11.5H7C6.64128 11.5 6.31005 11.3079 6.13195 10.9965C5.95386 10.6851 5.95619 10.3022 6.13807 9.99298L11.1381 1.49298C11.3177 1.18754 11.6456 1 12 1ZM8.74842 9.5H15.2516L12 3.97231L8.74842 9.5ZM18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15ZM13 18C13 15.2386 15.2386 13 18 13C20.7614 13 23 15.2386 23 18C23 20.7614 20.7614 23 18 23C15.2386 23 13 20.7614 13 18ZM2 14.5C2 13.9477 2.44772 13.5 3 13.5H10C10.5523 13.5 11 13.9477 11 14.5V21.5C11 22.0523 10.5523 22.5 10 22.5H3C2.44772 22.5 2 22.0523 2 21.5V14.5ZM4 15.5V20.5H9V15.5H4Z"
                            />
                        </svg>
                    )
                }
            }}
            {...props}
        />
    )
}
