import { RootReducer } from '@state/reducers/Root'
import { Button, Form, Input, List, Modal, Row, Spin } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import strings from '@resources/localization'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import { CheckOne } from '@icon-park/react'

import style from './ReportModal.scss'
import { chatMessageReport, chatReport, postReport } from '@state/actions'
import { ReportReason } from '@api/model/requests/Post'
import { useRequestState } from '@api/requests/AsyncRequests'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { CommunityRouteParams } from '@typings/routing/Router'
import { useParams } from 'react-router'
import { InformationModalBody } from '../information_modal/InformationModalBody'
import { ExpandableButton } from '@components/buttons/expandable_button/ExpandableButton'
import { FooterModal } from '../components/FooterModal'
import useBreakpoints from '@hooks/useBreakpoints'
import { ArrowRightIcon } from '@components/icons/ArrowRightIcon'

interface ReportForm {
    title: string
    explanation: string
}

interface PostProps {
    kind: 'comment' | 'rate' | 'post'
    open: boolean
    onCancel: () => void
    reference: PostReference
}

interface RoomProps {
    kind: 'room'
    open: boolean
    onCancel: () => void
    reference: RoomReference
}
interface MessageProps {
    kind: 'message'
    open: boolean
    onCancel: () => void
    reference: MessageReference
}

interface MessageReference {
    r_pk: string
    m_pk: string
}

interface RoomReference {
    r_pk: string
}

interface PostReference {
    b_pk: string
    p_pk: string
}

type Step = 'selection' | 'confirm' | 'finish'

export const ReportModal = (props: PostProps | RoomProps | MessageProps): ReactElement => {
    const { open, reference, onCancel, kind } = props

    const [step, setStep] = useState<Step>('selection')
    const [choice, setChoice] = useState<string | undefined>()
    const { cm_pk } = useParams<CommunityRouteParams>()
    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme == 'dark'
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const [state, setState] = useRequestState(undefined, false)

    const handleSelect = (item: string) => {
        setStep('confirm')
        setChoice(item)
    }

    const thenAction = () => {
        setStep('finish')
    }

    const goBack = () => {
        setStep('selection')
        setChoice(undefined)
    }

    const submit = (values: ReportForm) => {
        if (props.kind == 'post' || props.kind == 'rate' || props.kind == 'comment') {
            const referencePost = reference as PostReference

            dispatch(
                postReport({
                    bodyParams: {
                        communityPk: cm_pk,
                        boardPk: referencePost.b_pk,
                        postPk: referencePost.p_pk,
                        reason: choice as ReportReason,
                        title: values.title,
                        explanation: values.explanation,
                        notifyEmail: true,
                    },
                    options: {
                        redux: {
                            stateHandler: setState,
                        },
                        then: {
                            action: thenAction,
                        },
                    },
                })
            )
        }
        if (props.kind == 'room') {
            const referenceRoom = reference as RoomReference
            dispatch(
                chatReport({
                    bodyParams: {
                        communityPk: cm_pk,
                        roomPk: referenceRoom.r_pk,
                        reason: choice as ReportReason,
                        title: values.title,
                        explanation: values.explanation,
                        notifyEmail: true,
                    },
                    options: {
                        redux: {
                            stateHandler: setState,
                        },
                        then: {
                            action: thenAction,
                        },
                    },
                })
            )
        }
        if (props.kind == 'message') {
            const referenceMessage = reference as MessageReference
            dispatch(
                chatMessageReport({
                    bodyParams: {
                        communityPk: cm_pk,
                        roomPk: referenceMessage.r_pk,
                        messagePk: referenceMessage.m_pk,
                        reason: choice as ReportReason,
                        title: values.title,
                        explanation: values.explanation,
                        notifyEmail: true,
                    },
                    options: {
                        redux: {
                            stateHandler: setState,
                        },
                        then: {
                            action: thenAction,
                        },
                    },
                })
            )
        }
    }

    useEffect(() => {
        setStep('selection')
        setChoice(undefined)
    }, [open])

    const data = Object.entries(strings.reports.kinds)

    return (
        <TuringoModal
            width={572}
            closable
            title={null}
            open={open}
            onCancel={onCancel}
            footer={null}
            bodyStyle={{ textAlign: 'start', padding: step == 'selection' ? '4px 0px 16px 0px' : 0 }}
        >
            {step == 'selection' && (
                <div>
                    <div style={{ padding: 24 }}>
                        <ExclamationCircleOutlined style={{ color: '#FAAD14', fontSize: 56, marginBottom: 20 }} />
                        <Paragraph style={{ marginBottom: 16 }} strong>
                            {strings.reports.initial.title}
                        </Paragraph>
                        <Paragraph>{strings.reports.initial.description[kind]}</Paragraph>
                    </div>

                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item
                                className={'turingo-list-item'}
                                onClick={() => handleSelect(item[0])}
                                actions={[
                                    <span key="list-loadmore-edit" style={{ marginRight: 16 }}>
                                        <ArrowRightIcon />
                                    </span>,
                                ]}
                            >
                                <span style={{ paddingLeft: 24, paddingRight: 24 }} className={'turingo-list-item-text'}>
                                    {item[1]}
                                </span>
                            </List.Item>
                        )}
                    />
                </div>
            )}

            {step == 'confirm' && (
                <div>
                    <div style={{ padding: 24 }}>
                        <ExclamationCircleOutlined style={{ color: '#FAAD14', fontSize: 56, marginBottom: 20 }} />
                        <Paragraph style={{ marginBottom: 16 }} strong>
                            {strings.reports.initial.title}
                        </Paragraph>
                        <Paragraph style={{ marginBottom: 16 }}>
                            {strings.reports.selected.title} <span style={{ fontWeight: 500 }}>{(strings.reports.kinds[choice] as string) + '.'}</span>
                        </Paragraph>
                        <Paragraph style={{ marginBottom: 24 }}>{strings.reports.selected.description}</Paragraph>
                        {choice != 'other' && <Paragraph style={{ marginBottom: 56 }}>{strings.reports.selected.continue}</Paragraph>}

                        <Form onFinish={submit} form={form} layout={'vertical'}>
                            {choice == 'other' && (
                                <>
                                    <Form.Item
                                        name={['title']}
                                        rules={[
                                            { required: true, message: strings.forms.report },
                                            { min: 5, message: strings.forms.report },
                                        ]}
                                        label={strings.reports.selected.other.label}
                                    >
                                        <Input placeholder={strings.reports.selected.other.prompt} />
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: 56 }} name={['explanation']} label={strings.reports.selected.other_details.label}>
                                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} showCount maxLength={500} />
                                    </Form.Item>
                                </>
                            )}
                        </Form>

                        <FooterModal
                            primary={{
                                action: form.submit,
                                customText: strings.general.continue,
                            }}
                            secondary={{
                                action: onCancel,
                                customText: strings.general.cancel,
                            }}
                            back={{
                                action: goBack,
                                kind: 'back',
                            }}
                        />
                    </div>
                </div>
            )}

            {step == 'finish' && (
                <div>
                    <div style={{ padding: isMobile ? '24px 16px' : 24 }}>
                        <InformationModalBody
                            kind={'success'}
                            title={strings.reports.finish.success.title}
                            description={strings.reports.finish.success.description}
                        />
                        <FooterModal
                            key={'footer_modal'}
                            primary={{
                                action: onCancel,
                                customText: strings.general.finish,
                            }}
                        />
                    </div>
                </div>
            )}
        </TuringoModal>
    )
}
