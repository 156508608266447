// Libraries

// React

// Antd
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { useForm } from 'antd/lib/form/Form'
import { FooterModal } from '@components/modals/components/FooterModal'
import strings from '@resources/localization'
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody'
import Paragraph from 'antd/lib/typography/Paragraph'
import { Form } from 'antd'
import { FormPhoneItem } from '@components/form/FormPhoneItem/FormPhoneItem'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { userEdit } from '@state/actions'

// MARK: - Definition

namespace PhoneModal {
    export interface Props {
        open: boolean
        onCancel: () => void
        extra?: () => void
    }
}

const PhoneModal = (props: PhoneModal.Props) => {
    const { onCancel, open } = props

    const [form] = useForm()
    const [optionsForm] = useForm()
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)
    const cancel = () => {
        form.resetFields()
        optionsForm.resetFields()
        onCancel()
    }
    const dispatch = useAppDispatch()
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)
    const edit = (values) => {
        void dispatch(
            userEdit({
                urlParams: {
                    cm_pk: currentCommunity,
                },
                bodyParams: {
                    phoneNumber: values.phone.length > 0 ? values.phone : null,
                },
                options: {
                    then: { action: onCancel },
                    alerts: { success: strings.alerts.user.edit.success },
                },
            })
        )
    }

    const getText = () => {
        return {
            title: strings.screens.settings.sections.signup.changePhone.modal.title,
            description: strings.screens.settings.sections.signup.changePhone.modal.description,
        }
    }

    return (
        <TuringoModal
            open={open}
            onCancel={cancel}
            width={572}
            title={getText().title}
            footer={
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        action: form.submit,
                        customText: strings.general.save,
                    }}
                    secondary={{
                        action: cancel,
                        customText: strings.general.cancel,
                    }}
                />
            }
        >
            <Paragraph className="turingo-text" style={{ marginBottom: 16 }}>
                {getText().description}
            </Paragraph>

            <Form initialValues={{ phone: currentEntity?.phoneNumber }} onFinish={edit} form={form} layout={'vertical'}>
                <FormPhoneItem defaultValue={currentEntity?.phoneNumber} label={strings.screens.settings.sections.signup.changePhone.modal.phone.label} name={'phone'} />
            </Form>
        </TuringoModal>
    )
}

export { PhoneModal }
