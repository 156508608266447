import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface ArrowIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const ArrowRightIcon = (props: ArrowIcon) => {
    return (
        <Icon
            component={() => (
                <svg width="14" height="14" viewBox="0 0 14 14" fill={props.color ? props.color : 'var(--neutral-13)'} xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.76857 7.4131C9.99638 7.1853 9.99638 6.81595 9.76857 6.58814L5.65988 2.47945C5.43207 2.25164 5.06272 2.25164 4.83492 2.47945C4.60711 2.70725 4.60711 3.0766 4.83492 3.30441L8.53113 7.00062L4.83492 10.6968C4.60711 10.9246 4.60711 11.294 4.83492 11.5218C5.06272 11.7496 5.43207 11.7496 5.65987 11.5218L9.76857 7.4131Z"
                        fill="current"
                    />
                </svg>
            )}
            {...props}
        />
    )
}
