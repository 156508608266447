import strings from '@resources/localization'
import { Form, Input } from 'antd'
import React, { useState } from 'react'
import { Validations } from '../Validations'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

interface Props {
    name: string
    label: string | React.ReactNode
    validations?: Array<Validations>
    style?: React.CSSProperties
    className?: string
    help?: string
}

export const FormPasswordItem = (props: Props) => {
    const { name, label, validations, style, className, help } = props
    const [error, setError] = useState(help)
    return (
        <Form.Item
            style={style}
            className={className}
            name={[name]}
            label={label}
            help={error}
            rules={[
                ...(validations.includes('required')
                    ? [
                          () => ({
                              validator(_, value) {
                                  if (!value) {
                                      setError(strings.forms.password.validations.required)
                                      return Promise.reject(new Error())
                                  }
                                  setError(help)
                                  return Promise.resolve()
                              },
                          }),
                      ]
                    : []),

                ...(validations.includes('required') ? [{ required: true }] : []),

                ...(validations.includes('short')
                    ? [
                          () => ({
                              validator(_, value: string) {
                                  if (value.length < 8) {
                                      setError(strings.forms.password.validations.short)
                                      return Promise.reject(new Error())
                                  }
                                  setError(help)
                                  return Promise.resolve()
                              },
                          }),
                      ]
                    : []),

                ...(validations.includes('long')
                    ? [
                          () => ({
                              validator(_, value: string) {
                                  if (value.length > 20) {
                                      setError(strings.forms.password.validations.long)
                                      return Promise.reject(new Error())
                                  }
                                  setError(help)
                                  return Promise.resolve()
                              },
                          }),
                      ]
                    : []),

                ...(validations.includes('confirm')
                    ? [
                          ({ getFieldValue }) => ({
                              validator(_, value: string) {
                                  if (getFieldValue('password') === value) {
                                      return Promise.resolve()
                                  }
                                  return Promise.reject(new Error('The two passwords that you entered do not match!'))
                              },
                          }),
                      ]
                    : []),
            ]}
        >
            <Input.Password
                autoComplete="current-password"
                placeholder={strings.forms.password.placeholder}
                iconRender={(visible) => (visible ? <EyeTwoTone twoToneColor="#FF3862" /> : <EyeInvisibleOutlined twoToneColor="#FF3862" />)}
            />
        </Form.Item>
    )
}
