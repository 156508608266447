import React, { useState } from 'react'
import { Button, Card, Image, Menu, Progress, Row, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import { postVote } from '@state/actions'
import { useParams } from 'react-router'
import { PostRouteParams } from '@typings/routing/Router'
import { PostPollView } from '@api/model/responses/post/PostSubtypesViews'
import { Colors } from '@util/color/Colors'
import { useRequestState } from '@api/requests/AsyncRequests'
import { infoBetweenDates } from '@util/dates/Functions'
import strings from '@resources/localization'
import { LinkColor } from '@components/buttons/link_color/LinkColor'
import useBreakpoints from '@hooks/useBreakpoints'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { ReactionModal } from '../../components/ReactionModal'
import { PostView } from '@api/model/responses/post/PostView'
import { ConditionalWrap } from '@components/content/conditional_wrap/ConditionalWrap'
interface Props {
    target: PostView
    isCreator: boolean
}

export const PollPost = (props: Props) => {
    const { target, isCreator } = props
    const [isVoted, setIsVoted] = useState(target?.poll.isVoted)

    const [showResults, setShowResults] = useState(target?.poll.isVoted)

    const afterVote = () => {
        setIsVoted(true)
    }
    const dispatch = useAppDispatch()

    const { cm_pk, b_pk: br_pk, p_pk: pr_pk } = useParams<PostRouteParams>()
    const scope = pr_pk ? 'detail' : 'all'
    const isFeed = !br_pk
    const [state, setState] = useRequestState(undefined, false, false)
    const isLogged = useAppSelector((state) => !!state.auth.authToken)
    const [showReactionModal, setShowReactionModal] = useState(false)

    const vote = (id: number) => {
        if (isLogged) {
            dispatch(
                postVote({
                    urlParams: { cm_pk, b_pk: target.boardPk, target_pk: target.publicKey },
                    bodyParams: { kind: 'vote', choice: id },
                    options: {
                        redux: {
                            stateHandler: setState,
                        },
                        then: { action: afterVote },
                    },
                    extraParams: { scope: scope, feed: isFeed },
                })
            )
        } else {
            dispatch(modalAction({ modal: 'see_more', state: true }))
        }
    }

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const todayDate = new Date()
    const dateFinish = new Date(target?.poll.dateFinish)
    const daysLeft = infoBetweenDates(todayDate, dateFinish)

    return (
        <Spin spinning={state.editing}>
            {showReactionModal && (
                <ReactionModal kind="vote" target={target} open={showReactionModal} onCancel={() => setShowReactionModal(false)}></ReactionModal>
            )}

            <Card bordered={false} bodyStyle={{ backgroundColor: Colors('POSTED_POLL_BACKGROUND') }}>
                <p style={{ fontWeight: 500, overflowWrap: 'break-word' }}>{target?.poll.question}</p>

                {!isVoted &&
                    !showResults &&
                    daysLeft &&
                    target?.poll.options
                        .slice()
                        .sort((a, b) => a.choice_id - b.choice_id)
                        .map((item, index) => {
                            return (
                                <Button
                                    block
                                    onClick={() => vote(item.choice_id)}
                                    style={{
                                        marginBottom: index != target?.poll.options.length - 1 ? 8 : 0,
                                        borderRadius: 4,
                                        borderColor: Colors('OPTION_POLL_BORDER'),
                                    }}
                                    key={`pollOptions#${index}`}
                                >
                                    {item.answer}
                                </Button>
                            )
                        })}

                {(isVoted || !daysLeft || showResults) &&
                    target?.poll.options
                        .slice()
                        .sort((a, b) => a.choice_id - b.choice_id)
                        .map((item, index) => {
                            return (
                                <div key={`pollOptions#${index}`} style={{ marginBottom: 8 }}>
                                    {item.answer}
                                    <Progress
                                        status="normal"
                                        strokeColor={'#FA8C16'}
                                        success={{ strokeColor: '#FA8C16' }}
                                        percent={Math.trunc((item.dv_total_votes * 100.0) / target?.poll.dv_total_votes)}
                                    />
                                </div>
                            )
                        })}
                <Row style={{ fontSize: isMobile ? 14 : 12, color: Colors('NEUTRAL_8'), marginTop: 24 }}>
                    <ConditionalWrap condition={isCreator} wrap={(children) => <LinkColor onClick={() => setShowReactionModal(true)}>{children}</LinkColor>}>
                        <>
                            {target?.poll.dv_total_votes == 1
                                ? strings.formatString(strings.general.votes.one, target?.poll.dv_total_votes)
                                : strings.formatString(strings.general.votes.nary, target?.poll.dv_total_votes)}
                        </>
                    </ConditionalWrap>{' '}
                    <div style={{ marginLeft: 8, marginRight: 8, lineHeight: '17px' }}>{'•'}</div>
                    {daysLeft ? daysLeft : strings.general.votes.finish}{' '}
                    {isCreator && !isVoted && daysLeft && <div style={{ marginLeft: 8, marginRight: 8, lineHeight: '17px' }}>{'•'}</div>}
                    {isCreator && !isVoted && daysLeft && (
                        <LinkColor onClick={() => setShowResults(!showResults)}>
                            {showResults
                                ? strings.screens.boards.discussion.post.kind.poll.results.hide
                                : strings.screens.boards.discussion.post.kind.poll.results.show}
                        </LinkColor>
                    )}
                </Row>
            </Card>
        </Spin>
    )
}
