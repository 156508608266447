// Libraries

// React
import React from 'react'

// Antd
import {} from 'antd'
import { UserMentionView } from '@api/model/responses/user/Mentions'
import { AvatarTuringo } from '@components/avatar/Avatar'
import style from './UserListItem.scss'
import { UserView } from '@api/model/responses/user/UserView'

// MARK: - Definition

namespace UserListItem {
    export interface Props {
        item: UserView
        onClick?: () => void
    }
}

// MARK: - Implementation

const UserListItem: React.FC<UserListItem.Props> = (props: UserListItem.Props) => {
    const { item, onClick } = props
    return (
        <div key={item.publicKey} className={style.item} style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }} onClick={onClick}>
            <AvatarTuringo
                size={16}
                style={{
                    height: 40,
                    width: 40,
                    minWidth: 40,
                    lineHeight: '40px',
                    marginRight: 8,
                }}
                name={item.name}
                avatar={item.avatarUrl}
            />

            <div style={{ lineHeight: '22px' }}>
                <div className="turingo-text bold">{item.name}</div>
                {item.profile?.job && <div className="turingo-subtext">{item.profile?.job}</div>}
            </div>
        </div>
    )
}

export { UserListItem }
