import { Col, Row, Slider } from 'antd'
import { useCallback, useState } from 'react'
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../canvasUtils'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { FooterModal } from '@components/modals/components/FooterModal'
import { v4 } from 'uuid'
import { EditAspectRatios } from '@components/form/TuringoUpload/TuringoUpload'

interface Props {
    title: string
    open: boolean
    onCancel: () => void
    afterEdit?: Function
    file: any
    setFile: any
    aspect_ratio: EditAspectRatios
    restrictPosition?: boolean
}

const AssetOptions: {
    [key in EditAspectRatios]: {
        size: {
            width: number
            height: number
        }
        aspectRatio: number
        fileSize: number
        cropShape: 'rect' | 'round'
    }
} = {
    '1:1': {
        size: {
            width: 320,
            height: 320,
        },
        aspectRatio: 1,
        fileSize: 0.128,
        cropShape: 'round',
    },
    '5:2': {
        size: {
            width: 1800,
            height: 720,
        },
        fileSize: 5,
        aspectRatio: 5 / 2,
        cropShape: 'rect',
    },
    '16:9': {
        size: {
            width: 1280,
            height: 720,
        },
        fileSize: 5,
        aspectRatio: 16 / 9,
        cropShape: 'rect',
    },
    '16:10': {
        size: {
            width: 1280,
            height: 800,
        },
        fileSize: 5,
        aspectRatio: 16 / 10,
        cropShape: 'rect',
    },
    'letter': {
        size: {
            width: 2004,
            height: 1548,
        },
        fileSize: 5,
        aspectRatio: 1.294 / 1,
        cropShape: 'rect',
    },
}

export const EditImage = (props: Props) => {
    const { open, onCancel, file, setFile, afterEdit, aspect_ratio, title, restrictPosition = true } = props

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const [scale, setScale] = useState(1)

    function onChangeScale(value) {
        setScale(value)
    }

    const onClickSave = async () => {
        try {
            const croppedImage: { imagePreview: string; blob: Blob } = await getCroppedImg(file, croppedAreaPixels, AssetOptions[aspect_ratio].size)
            const croppedFile = new File([croppedImage.blob], 'Avatar', { type: 'image/png' }) as any

            const uploadCropped = {
                file: croppedFile,
                status: 'initial',
                percent: 0,
                preview: croppedImage.imagePreview
            }
            setFile({
                [v4()] : uploadCropped
            })
            onCancel()
            setScale(1)
            if (afterEdit) {
                afterEdit()
            }

        } catch (e) {
            console.error(e)
        }
    }
    const onCancelCrop = () => {
        try {
            setFile({})
            onCancel()
            setScale(1)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <TuringoModal
            title={title}
            open={open}
            onOk={() => { }}
            onCancel={onCancel}
            width={572}
            style={{ top: 30 }}
            footer={[
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        action: onClickSave,
                        customText: 'Guardar',
                    }}
                    secondary={{
                        action: onCancelCrop,
                        customText: 'Cancelar',
                    }}
                />,
            ]}
        >
            {file && (
                <div style={{ position: 'relative', width: '100%', height: '300px' }}>
                    <Cropper
                        showGrid={false}
                        image={file}
                        crop={crop}
                        zoom={scale}
                        restrictPosition={restrictPosition}
                        minZoom={-0.5}
                        aspect={AssetOptions[aspect_ratio].aspectRatio}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape={AssetOptions[aspect_ratio].cropShape}
                    />
                </div>
            )}
            <Row justify={'center'} style={{ alignItems: 'center' }}>
                <Col>
                    <ZoomOutOutlined style={{ margin: 16 }} />
                </Col>
                <Col flex="1 1 auto">
                <Slider tooltip={{ open: false }} min={0.5} step={0.01} max={2} value={scale} onChange={onChangeScale} />
                </Col>
                <Col>
                    <ZoomInOutlined style={{ margin: 16 }} />
                </Col>
            </Row>
        </TuringoModal>
    )
}
