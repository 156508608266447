import strings from '@resources/localization'
import { Router } from '@Router'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { RootReducer } from '@state/reducers/Root'
import { BoardRouteParams } from '@typings/routing/Router'
import { Button, Card } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import React from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { useHistory, useParams } from 'react-router'

namespace CommunityAbout {
    export interface Props {
        compact?: boolean
        description?: string
    }
}

const CommunityAbout: React.FC<CommunityAbout.Props> = (props: CommunityAbout.Props) => {
    const { compact = false, description } = props
    const { cm_pk } = useParams<BoardRouteParams>()

    const isLogged = useAppSelector((state) => !!state.auth.authToken)

    const history = useHistory()
    const dispatch = useAppDispatch()
    const onClick = () => {
        if (isLogged) {
            history.push(Router.Path.community_about({ cm_pk }))
        } else {
            dispatch(modalAction({ modal: 'see_more', state: true }))
        }
    }
    return (
        <Card title={strings.general.about} bordered={false}>
            <Paragraph ellipsis={compact ? { rows: 2 } : false}>{description}</Paragraph>

            {compact && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                    <Button onClick={onClick}>{strings.general.read_more}</Button>
                </div>
            )}
        </Card>
    )
}

export { CommunityAbout }
