import { API } from '@api/API'
import { APIResponse } from '@api/core/Endpoint'
import { CommunityRouteParams, CommunitySettingsDetailRouteParams } from '@typings/routing/Router'
import { Select, SelectProps, Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'

export interface DebounceGenericSelectProps<ValueType = any> extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    debounceTimeout?: number
    dispatch: ()=> Promise<APIResponse<any[]>>
    valueField: string
    nameField: string
}

export function DebounceGenericSelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any>({
    debounceTimeout = 800,
    ...props
}: DebounceGenericSelectProps<ValueType>) {

    const { dispatch, valueField, nameField, disabled } = props
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState<ValueType[]>([])
    const { cm_pk, item_pk } = useParams<CommunitySettingsDetailRouteParams>()

    useEffect(() => {
        if (!disabled) {
            setFetching(true)
            void dispatch().then((response) => {
                const data = response.data
                    .map((item) => {
                        return { label: item[nameField], value: item[valueField] }
                    })
                setOptions(data as ValueType[])
                setFetching(false)
            })
        }

    }, [disabled])
    if (fetching) {
        return (
            <div style={{ padding: 24, textAlign: 'center' }}>
                <Spin></Spin>
            </div>
        )
    }
    return <Select labelInValue notFoundContent={fetching ? <Spin size="small" /> : null} {...props} options={options} />
}
