import useBreakpoints from '@hooks/useBreakpoints'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { LeftC, RightC } from '@icon-park/react'
import style from './Carrousel.scss'
import { RootReducer } from '@state/reducers/Root'
import { useAppSelector } from '@hooks/useStore'
import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

interface Props {
    showArrows?: boolean
    slidesToShow?: number
    children: ReactNode[] | ReactNode[][]
}

export const Carrousel = (props: Props) => {
    const { showArrows = true, slidesToShow } = props
    const [page, setPage] = useState(0)
    const breakpoints = useBreakpoints()
    const [lastSlide, setLastSlide] = useState(0)
    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme === 'dark'
    useEffect(() => {
        if (props.children[1]) {
            setLastSlide((props.children[1] as ReactNode[]).length - slides)
        } else {
            setLastSlide(props.children.length - slides)
        }
    }, [props.children])
    const isMobile = breakpoints.isMobile

    const sliderRef = useRef<Slider>(null)

    const nextSlide = () => {
        sliderRef.current.slickNext()
    }

    const prevSlide = () => {
        sliderRef.current.slickPrev()
    }

    const afterChangeHandler = (currentSlide, nextSlide) => {
        setPage(nextSlide)
    }

    const slides = slidesToShow ? slidesToShow : isMobile ? 1 : 3

    const slideDots = (dots) => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 20,
                    padding: isMobile ? 0 : 10,
                }}
            >
                {isMobile && showArrows && (
                    <div style={{ ...(page == 0 && { visibility: 'hidden' }), marginRight: 12 }}>
                        <Button onClick={prevSlide} shape="circle" icon={<LeftOutlined />} />
                    </div>
                )}
                <ul style={{ margin: '0px', paddingInlineStart: 0, alignSelf: 'center' }}> {dots} </ul>
                {isMobile && showArrows && (
                    <div style={{ ...(page >= lastSlide && { visibility: 'hidden' }), marginLeft: 12 }}>
                        <Button onClick={nextSlide} shape="circle" icon={<RightOutlined />} />
                    </div>
                )}
            </div>
        )
    }

    const dot = (i) => {
        return (
            <div
                style={{
                    opacity: 0.3,
                    width: 24,
                    height: 4,
                    borderRadius: 100,
                }}
            ></div>
        )
    }
    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: slides,
        slidesToScroll: slides,
        arrows: false,
        appendDots: slideDots,
        customPaging: dot,
    }

    return (
        <div className={'turingoCarousel'} style={{ display: 'flex', justifyContent: 'center' }}>
            {!isMobile && showArrows && (
                <div style={{ alignSelf: 'center', paddingBottom: 70, ...(page == 0 && { visibility: 'hidden' }) }}>
                    <Button onClick={prevSlide} shape="circle" icon={<LeftOutlined />} />
                </div>
            )}
            <div style={{ maxWidth: 1284, marginBottom: 70 }}>
                <Slider ref={sliderRef} {...settings} beforeChange={afterChangeHandler} className={!showArrows ? style.maxWidth : ''}>
                    {props.children}
                </Slider>
            </div>
            {!isMobile && showArrows && (
                <div style={{ alignSelf: 'center', paddingBottom: 70, ...(page >= lastSlide && { visibility: 'hidden' }) }}>
                    <Button onClick={nextSlide} shape="circle" icon={<RightOutlined />} />
                </div>
            )}
        </div>
    )
}
