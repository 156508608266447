import { PartnerView } from '@api/model/responses/board/BoardView'
import { EntityBasicView } from '@api/model/responses/entity/EntityBasicView'
import { SponsorIcon } from '@components/content/entity/sponsor_icon/SponsorIcon'
import strings from '@resources/localization'
import { Card, Col, Row } from 'antd'
import React from 'react'

type Kind = 'sponsor' | 'supporter' | 'promoter' | 'contributor'

interface Props {
    kind: Kind
    items: Array<PartnerView>
}

export const SponsorsCard = (props: Props) => {
    const { items, kind } = props

    if (items.length == 0) return null
    return (
        <Col span={24}>
            <Card title={strings.general[kind]} bordered={false}>
                {items?.map((item, index) => {
                    return (
                        <Row key={`${kind}#${index}`}>
                            <Col span={24} style={{ marginBottom: 10 }}>
                                <SponsorIcon avatarUrl={item.logoUrl}></SponsorIcon>
                            </Col>
                        </Row>
                    )
                })}
            </Card>
        </Col>
    )
}
