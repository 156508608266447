import { replyList } from '@state/actions'
import { PostRouteParams } from '@typings/routing/Router'
import { Spin } from 'antd'
import React, { ReactElement, useContext, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { useParams } from 'react-router'
import strings from '@resources/localization'
import { ReplyField } from './components/ReplyField'
import { CommentContent } from './CommentContent'
import { Colors } from '@util/color/Colors'
import { PlusOutlined } from '@ant-design/icons'
import { PostContext } from '../../Post'

interface Props {
    c_pk: string
    total: number
    childRef: React.MutableRefObject<{ getFocus: () => void }>
    showReplyField: boolean
    replyFieldAction: (target: { name: string; publicKey: string }) => void
}

export const ReplySection = (props: Props): ReactElement => {
    const { cm_pk, p_pk, b_pk, mask, scope, isFeed } = useContext(PostContext)

    const { c_pk, childRef, showReplyField, replyFieldAction, total } = props

    const currentEntity = useAppSelector((state) => state.auth.currentEntity)

    const replies = useAppSelector((state) => state.posts[scope][isFeed ? 'feed' : b_pk]?.items[p_pk].childrens.items[c_pk].childrens)
    const dispatch = useAppDispatch()

    useEffect(() => {
        fetchData(false)
    }, [b_pk, p_pk])

    const fetchData = (pagination: boolean) => {
        if (!pagination) {
            void dispatch(
                replyList({
                    urlParams: { cm_pk, b_pk, c_pk },
                    ...(currentEntity.publicKey != mask.get?.publicKey && { searchParams: { mask: mask.get?.publicKey } }),

                    extraParams: { p_pk, scope: scope, feed: isFeed },
                    headerParams: {
                        page: null,
                    },
                })
            )
        } else {
            void dispatch(
                replyList({
                    urlParams: { cm_pk, b_pk, c_pk },
                    ...(currentEntity.publicKey != mask.get?.publicKey && { searchParams: { mask: mask.get?.publicKey } }),

                    extraParams: { p_pk, scope: scope, feed: isFeed },
                    headerParams: {
                        page: replies?.listState.pagination,
                    },
                })
            )
        }
    }

    const totalReplies = replies.items ? total - Object.keys(replies.items).length : total
    return (
        <div>
            {replies?.listState.pagination && !replies?.listState.loading && (
                <div style={{ position: 'relative', paddingLeft: 54, paddingTop: 12 }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 24,
                            height: 24,
                            width: 24,
                            borderBottom: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderBottomLeftRadius: 6,
                        }}
                    ></div>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 24,
                            height: '100%',
                            width: 24,
                            borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                        }}
                    ></div>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => fetchData(true)}>
                        <PlusOutlined style={{ marginRight: 8 }} />

                        {totalReplies == 1 && strings.formatString(strings.general.replies_previuos.one)}
                        {totalReplies != 1 && strings.formatString(strings.general.replies_previuos.nary, totalReplies)}
                    </div>
                </div>
            )}

            {replies?.listState.loading && (
                <div style={{ padding: 24, textAlign: 'center' }}>
                    <Spin></Spin>
                </div>
            )}

            {replies &&
                replies.items &&
                Object.keys(replies.items).map((key: string, index: number) => (
                    <div key={index} style={{ paddingBottom: 0, position: 'relative', paddingLeft: 54, paddingTop: 16 }}>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 24,
                                height: 36,
                                width: 24,
                                borderBottom: `1px solid ${Colors('NEUTRAL_5')}`,
                                borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                                borderBottomLeftRadius: 6,
                            }}
                        ></div>
                        {(index != Object.keys(replies.items).length - 1 || showReplyField) && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 24,
                                    height: '100%',
                                    width: 24,
                                    borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                                }}
                            ></div>
                        )}
                        <CommentContent
                            b_pk={b_pk}
                            parents={[p_pk, c_pk]}
                            replyFieldAction={replyFieldAction}
                            level={2}
                            key={key}
                            target={replies.items[key].item}
                        />
                    </div>
                ))}

            {showReplyField && (
                <div style={{ position: 'relative', paddingLeft: 54, paddingTop: 13 }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 24,
                            height: 36,
                            width: 24,
                            borderBottom: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderBottomLeftRadius: 6,
                        }}
                    ></div>
                    <ReplyField ref={childRef} b_pk={b_pk} p_pk={p_pk} c_pk={c_pk}></ReplyField>
                </div>
            )}
        </div>
    )
}
