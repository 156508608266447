// Libraries

// React
import React, { useState } from 'react'

// Antd
import { Button, Divider, Typography } from 'antd'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import strings from '@resources/localization'
import { FormSelectModalItem } from '@components/form/FormSelect/FormSelectModalItem'
import { FooterModal } from '@components/modals/components/FooterModal'
import { Topic } from '@api/model/responses/post/PostView'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { communityPosts, postList } from '@state/actions'
import { FilterOutlined, InfoCircleFilled } from '@ant-design/icons'
import { RootReducer } from '@state/reducers/Root'
import useBreakpoints from '@hooks/useBreakpoints'
import { useParams } from 'react-router'
import { BoardRouteParams } from '@typings/routing/Router'
import { TopicDeleteButton } from '@components/content/posts/post_modal/topics/TopicDeleteButton'

// MARK: - Definition

namespace FilterTopic {
    export interface Props {
        topics: Topic[]
        b_pk?: string
    }
}

// MARK: - Implementation

const FilterTopic: React.FC<FilterTopic.Props> = (props: FilterTopic.Props) => {
    const { topics } = props

    const { cm_pk, b_pk = props.b_pk } = useParams<BoardRouteParams>()

    const isLogged = useAppSelector((state) => !!state.auth.authToken)

    const [filterModal, setFilterModal] = useState(false)
    const [topicSelected, setTopicSelected] = useState<Topic[]>([])
    const [topicSelectedForm, setTopicSelectedForm] = useState<Topic[]>([])
    const dispatch = useAppDispatch()
    const breakpoints = useBreakpoints()

    const onTopicSelected = (topic?: Topic[]) => {
        setTopicSelectedForm(topic)
    }
    const onFilter = () => {
        setTopicSelected(topicSelectedForm)
        dispatchFilterByTopic(topicSelectedForm)
        setFilterModal(false)
    }

    const onRemoveFilter = () => {
        setTopicSelected(undefined)
        dispatchFilterByTopic()
    }
    const dispatchFilterByTopic = (topics?: Topic[]) => {
        let topicToFilter = { publicKey: 'all' }
        if (topics && topics.length > 0) topicToFilter = topics[0]

        if (b_pk == 'feed') {
            communityPosts({
                urlParams: {
                    cm_pk,
                },
                ...(topicToFilter &&
                    topicToFilter.publicKey != 'all' &&
                    topicToFilter.publicKey != '' && {
                        searchParams: {
                            topic: topicToFilter.publicKey,
                        },
                    }),
                headerParams: {
                    page: 'first',
                },
                extraParams: {
                    section: 'feed'
                }
            })
        } else {
            dispatch(
                postList({
                    urlParams: { cm_pk, b_pk },
                    ...(topicToFilter &&
                        topicToFilter.publicKey != 'all' &&
                        topicToFilter.publicKey != '' && {
                            searchParams: {
                                topic: topicToFilter.publicKey,
                            },
                        }),
                    headerParams: {
                        page: 'first',
                    },
                })
            )
        }
        dispatch(
            postList({
                urlParams: { cm_pk, b_pk },
                ...(topicToFilter &&
                    topicToFilter.publicKey != 'all' &&
                    topicToFilter.publicKey != '' && {
                        searchParams: {
                            topic: topicToFilter.publicKey,
                        },
                    }),
                headerParams: {
                    page: 'first',
                },
            })
        )
    }
    return (
        <>
            <TuringoModal
                title={strings.screens.boards.discussion.details.filter.description}
                width={572}
                open={filterModal}
                onOk={onFilter}
                footer={
                    <FooterModal
                        key={'footer_modal'}
                        primary={{
                            action: onFilter,
                            customText: strings.screens.boards.discussion.details.filter.action,
                        }}
                    />
                }
                onCancel={() => setFilterModal(false)}
            >
                <FormSelectModalItem defaultValue={topicSelected} kind="list" mode="single" items={topics} afterAction={onTopicSelected} />
            </TuringoModal>

            {topics.length > 0 && (
                <>
                    <Divider></Divider>
                    {isLogged && (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: breakpoints.xxs ? 'start' : 'center', marginRight: 8 }}>
                                    <FilterOutlined style={{ marginRight: 8, fontSize: 16, marginTop: breakpoints.xxs ? 4 : 0 }} />
                                    <Typography.Paragraph style={{ marginBottom: 0 }}>
                                        {strings.screens.boards.discussion.details.filter.title}{' '}
                                    </Typography.Paragraph>
                                </div>

                                <Button onClick={() => setFilterModal(true)}>{strings.screens.boards.discussion.details.filter.action}</Button>
                            </div>

                            {topicSelected && topicSelected.length > 0 && (
                                <div style={{ display: 'flex', marginTop: 16 }}>
                                    {topicSelected.map((topic) => {
                                        return <TopicDeleteButton key={topic.publicKey} title={topic.name} onClick={onRemoveFilter} />
                                    })}
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export { FilterTopic }
