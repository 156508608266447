// Libraries

// React
import React from 'react'

// Ant
import { Button } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'

// API

// State

// Hooks

// Resources
import strings from '@resources/localization'

// Utils
import { Colors } from '@util/color/Colors'

// Components
import { Router } from '@Router'
import { AvatarTuringo } from '@components/avatar/Avatar'
import { AlertActions } from '@components/alerts/alert_actions/AlertActions'
import { RootReducer } from '@state/reducers/Root'
import { useAppSelector } from '@hooks/useStore'
import useBreakpoints from '@hooks/useBreakpoints'
import { useHistory, useParams } from 'react-router'
import { CommunityRouteParams } from '@typings/routing/Router'
import { TuringoButton } from 'src/turingo_components/buttons/button/TuringoButton'

// MARK: - Definition

namespace CompleteProfile {
    export interface Props {}
}

// MARK: - Implementation

const CompleteProfile: React.FC<CompleteProfile.Props> = (props: CompleteProfile.Props) => {
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)
    const { cm_pk } = useParams<CommunityRouteParams>()

    // RESPONSIVE
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const history = useHistory()

    return (
        <AlertActions
            style={{ marginBottom: isMobile ? 16 : 0 }}
            message={
                <div style={{ display: 'grid', gridAutoFlow: 'column', alignItems: 'center', gap: 12, marginBottom: isMobile ? 16 : 0 }}>
                    <InfoCircleFilled style={{ color: '#1890FF' }} />
                    <AvatarTuringo name={currentEntity?.name} avatar={currentEntity?.avatarUrl} size={13} width={32} height={32}></AvatarTuringo>
                    <p style={{ marginBottom: 0, lineHeight: '22px' }}>{strings.screens.boards.discussion.details.complete_profile.title}</p>
                </div>
            }
            onOk={
                <TuringoButton
                    onClick={() => history.push(Router.Path.user_details({ u_pk: currentEntity.publicKey, cm_pk }))}
                    expandInMobile
                    type="default"
                >
                    {strings.screens.boards.discussion.details.complete_profile.action}
                </TuringoButton>
            }
        />
    )
}

export { CompleteProfile }
