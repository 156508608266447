import React from 'react'
import { Card, Row, Col, Avatar, Skeleton } from 'antd'
import useBreakpoints from '@hooks/useBreakpoints'
import { RootReducer } from '@state/reducers/Root'
import { useAppSelector } from '@hooks/useStore'

export const EntityMemberSkeleton = () => {
    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme === 'dark'
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.xxs || breakpoints.xs || breakpoints.sm
    const isTablet = breakpoints.md || breakpoints.mdl

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' :  '1fr 1fr 1fr',
                gap: 16,
                marginTop: 16,
                placeContent: 'center',
            }}
        >
            <Card bordered style={{ height: 144 }} bodyStyle={{ display: 'flex', flexDirection: 'column', padding: 16 }}>
                <Skeleton.Avatar active size={64}></Skeleton.Avatar>
                <Skeleton style={{ marginTop: 20, width: '80%', }} title active paragraph={null} />
            </Card>

            <Card bordered style={{ height: 144 }} bodyStyle={{ display: 'flex', flexDirection: 'column', padding: 16 }}>
                <Skeleton.Avatar active size={64}></Skeleton.Avatar>
                <Skeleton style={{ marginTop: 20, width: '80%', }} title active paragraph={null} />
            </Card>
            <Card bordered style={{ height: 144 }} bodyStyle={{ display: 'flex', flexDirection: 'column',padding: 16 }}>
                <Skeleton.Avatar active size={64}></Skeleton.Avatar>
                <Skeleton style={{ marginTop: 20, width: '80%', }} title active paragraph={null} />
            </Card>

          
        </div>
    )
}
