import { Attachment } from '@api/model/responses/post/PostView'
import { AttachmentComponent } from './AttachmentComponent'

interface Props {
    attachments: Array<Attachment>
    showPreview?: boolean
}

export const AttachmentsPost = (props: Props) => {
    const { attachments, showPreview = true } = props

    return (
        <div style={{ width: '100%'}}>
            {attachments.map((attachment: Attachment, index: number) => {
                return <AttachmentComponent showPreview={showPreview} key={`attachment#${index}`} attachment={attachment} />
            })}
        </div>
    )
}
