// MARK: - Imports

// React
import React, { useCallback, useEffect, useState } from 'react'

// Antd
import { Divider, Skeleton, Tabs } from 'antd'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { useHistory, useParams } from 'react-router'
import { PostRouteParams } from '@typings/routing/Router'
import { AvatarTuringo } from '@components/avatar/Avatar'
import { PostReactionView } from '@api/model/responses/post/PostInteractionView'
import { Router } from '@Router'
import { PostView } from '@api/model/responses/post/PostView'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TuringoList } from '@components/list/TuringoList'
import Paragraph from 'antd/lib/typography/Paragraph'
import useBreakpoints from '@hooks/useBreakpoints'
import { ReactionContent, ReactionKind } from '@api/model/requests/Post'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import strings from '@resources/localization'
import { postReactionList } from '@state/actions'
import { APIResponse } from '@api/core/Endpoint'
import { getMoniker } from './PostAuthor'
import { UserView } from '@api/model/responses/user/UserView'

const { TabPane } = Tabs

// MARK: - Definition

namespace PostViewReadModal {
    export interface Props {
        kind: ReactionKind
        target: PostView
        open: boolean
        onCancel: () => void
    }
}

// MARK: - Implementation

const PostViewReadModal: React.FC<PostViewReadModal.Props> = (props: PostViewReadModal.Props) => {
    const { cm_pk, b_pk: br_pk, p_pk } = useParams<PostRouteParams>()
    const isFeed = !br_pk

    const { target, open, onCancel } = props
    const isMobile = useBreakpoints().isMobile

    const community = useAppSelector((state) => state.community.items[cm_pk])

    const isWhatsAppActive = community?.item?.plugins?.whatsapp?.state == 'active'

    const [readState, setReadState] = useState<{ data: PostReactionView[]; loading: boolean; pagination: string; total: number }>({
        data: [],
        loading: true,
        pagination: 'first',
        total: 0,
    })

    const [communityState, setCommunityState] = useState<{ data: PostReactionView[]; loading: boolean; pagination: string; total: number }>({
        data: [],
        loading: true,
        pagination: 'first',
        total: 0,
    })

    const [whatsappState, setWhatsappState] = useState<{ data: PostReactionView[]; loading: boolean; pagination: string; total: number }>({
        data: [],
        loading: true,
        pagination: 'first',
        total: 0,
    })

    const [emailState, setEmailState] = useState<{ data: PostReactionView[]; loading: boolean; pagination: string; total: number }>({
        data: [],
        loading: true,
        pagination: 'first',
        total: 0,
    })

    const [kind, setKind] = useState(props.kind)

    const history = useHistory()
    const dispatch = useAppDispatch()
    const afterAction = (result: APIResponse<PostReactionView[]>, page, kind, content: ReactionContent) => {
        if (kind == 'pin_view') {
            if (page == 'first') {
                if (content == 'email') setEmailState({ data: result.data, loading: false, pagination: result.pagination, total: result.total || 0 })
                if (content == 'whatsapp') setWhatsappState({ data: result.data, loading: false, pagination: result.pagination, total: result.total || 0 })
                if (content == 'none') setCommunityState({ data: result.data, loading: false, pagination: result.pagination, total: result.total || 0 })
            } else {
                if (content == 'email')
                    setEmailState({ data: [...emailState.data, ...result.data], loading: false, pagination: result.pagination, total: result.total || 0 })
                if (content == 'whatsapp')
                    setWhatsappState({ data: [...whatsappState.data, ...result.data], loading: false, pagination: result.pagination, total: result.total || 0 })
                if (content == 'none')
                    setCommunityState({
                        data: [...communityState.data, ...result.data],
                        loading: false,
                        pagination: result.pagination,
                        total: result.total || 0,
                    })
            }
        } else {
            if (page == 'first') {
                setReadState({ data: result.data, loading: false, pagination: result.pagination, total: result.total || 0 })
            } else {
                setReadState({ data: [...readState.data, ...result.data], loading: false, pagination: result.pagination, total: result.total || 0 })
            }
        }
    }
    const fetchReactions = (content: ReactionContent, page: string) => {
        if (kind == 'pin_view') {
            if (content == 'email') setEmailState({ ...emailState, loading: true })
            if (content == 'whatsapp') setWhatsappState({ ...whatsappState, loading: true })
            if (content == 'none') setCommunityState({ ...communityState, loading: true })
        } else {
            setReadState({ ...readState, loading: true })
        }

        void dispatch(
            postReactionList({
                urlParams: {
                    cm_pk,
                    b_pk: target.boardPk,
                    target_pk: target.publicKey,
                },
                extraParams: {
                    feed: isFeed,
                },
                headerParams: {
                    page: page == 'first' ? null : page,
                },
                searchParams: {
                    kind: kind,
                    ...(content && { content: content }),
                },
                options: {
                    then: {
                        action: (result: APIResponse<PostReactionView[]>) => afterAction(result, page, kind, content),
                    },
                },
            })
        )
    }

    useEffect(() => {
        const myDiv2 = document.getElementById('pin_read')

        if (myDiv2) {
            myDiv2.scrollTop = 0
        }

        const myDiv = document.getElementById('pin_view')

        if (myDiv) {
            myDiv.scrollTop = 0
        }

        if (kind == 'pin_view') {
            void fetchReactions('none', 'first')
            void fetchReactions('whatsapp', 'first')
            void fetchReactions('email', 'first')
        } else {
            void fetchReactions(undefined, 'first')
        }
    }, [kind])


    const moniker = useCallback(
        (user: UserView, maxWidth?: number | string) => {
            return getMoniker(user, maxWidth)
        },
        [communityState, whatsappState, emailState, readState]
    )
    
    const listComponent = (content: ReactionContent, target: 'pin_read' | 'pin_view') => {
        const reactionState = content ? (content == 'none' ? communityState : content == 'whatsapp' ? whatsappState : emailState) : readState

        return (
            <InfiniteScroll
                scrollableTarget={target}
                style={{ marginTop: reactionState?.data?.length > 0 ? 16 : 0, maxHeight: 'inherit' }}
                dataLength={reactionState.loading && reactionState.pagination == 'first' ? 0 : reactionState.data.length}
                next={() => {
                    void fetchReactions(content, reactionState.pagination)
                }}
                hasMore={reactionState.pagination && reactionState.pagination != 'first'}
                loader={
                    <div style={{ padding: isMobile ? '0 16px' : '0 24px', marginTop: 24, display: 'grid', gridAutoFlow: 'row', gap: 24 }}>
                        <Skeleton active title paragraph={null} />
                        <Skeleton active title paragraph={null} />
                        <Skeleton active title paragraph={null} />
                    </div>
                }
            >
                <TuringoList
                    emptyText=" "
                    showDivider={false}
                    showArrow={false}
                    itemRender={(item) => (

                        <div key={item.publicKey} style={{ display: 'flex', alignItems: 'center' }}>
                            <AvatarTuringo
                                size={24}
                                style={{
                                    height: 48,
                                    width: 48,
                                    lineHeight: '48px',
                                    marginRight: 8,
                                }}
                                name={item.name}
                                avatar={item.avatarUrl}
                            />

                            <div style={{ lineHeight: '16px', width: '100%' }}>
                                <div className="turingo-text" style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                    {item.name}
                                </div>
                                {moniker(item)}
                            </div>
                        </div>
                    )}
                    items={reactionState.data.map((item) => {
                        return { item: item.author, publicKey: item.author.publicKey }
                    })}
                    onClick={(value: string) => history.push(Router.Path.user_details({ u_pk: value, cm_pk }))}
                />
            </InfiniteScroll>
        )
    }

    const getTitle = () => {
        if (props.kind == 'react') {
            return `${strings.general.like} (${target.totalReactions})`
        }
        if (props.kind == 'vote') {
            return strings.general.votes.title
        } else {
            return strings.screens.markasread.details.title
        }
    }

    return (
        <TuringoModal
            width={572}
            open={open}
            scrolleable={false}
            onCancel={onCancel}
            footer={null}
            closable
            customTitle={
                <div className="turingo-card-header-padding">
                    <Paragraph style={{ marginBottom: 16 }} className="turingo-title" strong>
                        {getTitle()}
                    </Paragraph>
                    <Paragraph className="turingo-text">{strings.screens.markasread.details.description}</Paragraph>
                </div>
            }
            bodyStyle={{ padding: 0, minHeight: 500 }}
        >
            {(kind == 'pin_read' || kind == 'pin_view') && (
                <Tabs
                    className="turingo-card-tabs"
                    onChange={(key) => {
                        setKind(key as ReactionKind)
                    }}
                >
                    <TabPane
                        tab={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {strings.screens.markasread.details.viewers.title} <div className={'tag'}>{target.totalViews}</div>
                            </div>
                        }
                        key={'pin_view'}
                    >
                        <div
                            id={'pin_view'}
                            className="scrollStyle"
                            style={{
                                maxHeight: /* isMobile ?  */ 'calc(90vh - 154px)' /* :  'calc(72vh - 154px)' */,
                                overflowY: 'auto',
                            }}
                        >
                            <div
                                style={{
                                    marginLeft: isMobile ? 16 : 24,
                                    marginTop: 16,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                {strings.screens.markasread.details.medium.community} <div className={'tag'}>{communityState.total}</div>
                            </div>
                            {listComponent('none', 'pin_view')}
                            {isWhatsAppActive && (
                                <div className="lateral-padding">
                                    <Divider></Divider>
                                </div>
                            )}
                            {isWhatsAppActive && (
                                <div
                                    style={{
                                        marginLeft: isMobile ? 16 : 24,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {strings.screens.markasread.details.medium.whatsapp} <div className={'tag'}>{whatsappState.total}</div>
                                </div>
                            )}
                            {isWhatsAppActive && listComponent('whatsapp', 'pin_view')}
                            <div className="lateral-padding">
                                <Divider></Divider>
                            </div>
                            <div
                                style={{
                                    marginLeft: isMobile ? 16 : 24,
                                    marginBottom: 16,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                {strings.screens.markasread.details.medium.email} <div className={'tag'}>{emailState.total}</div>
                            </div>
                            {listComponent('email', 'pin_view')}
                        </div>
                    </TabPane>
                    <TabPane
                        disabled={target.totalReads == 0}
                        tab={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {strings.screens.markasread.details.readers.title} <div className={'tag'}>{target.totalReads}</div>
                            </div>
                        }
                        key={'pin_read'}
                    >
                        <div
                            id={'pin_read'}
                            className="scrollStyle"
                            style={{
                                maxHeight: /* isMobile ?  */ 'calc(90vh - 154px)' /* :  'calc(72vh - 154px)' */,

                                overflowY: 'auto',
                            }}
                        >
                            {listComponent(undefined, 'pin_read')}
                        </div>
                    </TabPane>
                </Tabs>
            )}
        </TuringoModal>
    )
}

export { PostViewReadModal }
