

// Antd
import { Card, Col, Row } from 'antd'
import Title from 'antd/lib/typography/Title'

// Model
import { LocalizedVariantView } from '@api/model/responses/common/Localization'
import { AvatarTuringo } from '@components/avatar/Avatar'

namespace SponsorIcon {
    export interface Props {
        avatarUrl?: string
        name?: string | LocalizedVariantView<string>
    }
}

const SponsorIcon: React.FC<SponsorIcon.Props> = (props: SponsorIcon.Props) => {
    const { avatarUrl, name } = props

    return (
        <Card bodyStyle={{ padding: 0 }} bordered={false} style={{ minHeight: 'unset' }}>
            <Row justify={'center'}>
                <Col style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <AvatarTuringo
                        kind={'square'}
                        name={name as string}
                        avatar={avatarUrl}
                        size={32}
                        width={180}
                        height={60}
                        style={{ marginBottom: 10, lineHeight: '60px' }}
                    />
                </Col>
            </Row>
        </Card>
    )
}

export { SponsorIcon }
