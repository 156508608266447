import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

interface MenuToggleIcon {
    style?: CSSProperties
    className: string
    onClick(): void
}

export const MenuRightIcon: React.FC<MenuToggleIcon> = (props) => (
    <Icon
        component={() => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--neutral-13)" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.66845 11.1313C3.11616 11.1313 2.66845 11.579 2.66845 12.1313C2.66845 12.6836 3.11616 13.1313 3.66845 13.1313H15.2541L14.1329 14.2525C13.7424 14.643 13.7424 15.2762 14.1329 15.6667C14.5235 16.0572 15.1566 16.0572 15.5472 15.6667L18.3658 12.848C18.4488 12.7673 18.5178 12.6722 18.5688 12.5668C18.6241 12.4527 18.6584 12.3266 18.6665 12.1933C18.67 12.1376 18.6688 12.0817 18.663 12.0262C18.6568 11.9674 18.6456 11.9102 18.6297 11.8549C18.5845 11.6971 18.4998 11.5483 18.3756 11.4241L15.5472 8.5956C15.1566 8.20507 14.5235 8.20507 14.1329 8.59559C13.7424 8.98611 13.7424 9.61928 14.1329 10.0098L15.2544 11.1313H3.66845Z"
                    fill="current"
                />
                <path
                    d="M21.9999 5.50012C21.9999 4.94784 21.5522 4.50012 20.9999 4.50012C20.4476 4.50012 19.9999 4.94784 19.9999 5.50012V18.5001C19.9999 19.0524 20.4476 19.5001 20.9999 19.5001C21.5522 19.5001 21.9999 19.0524 21.9999 18.5001V12.0001V5.50012Z"
                    fill="current"
                />
            </svg>
        )}
        {...props}
    />
)
