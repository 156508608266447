import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface LanguageIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const LanguageIcon = (props: LanguageIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill={props.color ? props.color : 'var(--neutral-13)'}>
                    <path
                        d="M4.08334 4.08325C3.76118 4.08325 3.50001 4.34442 3.50001 4.66659C3.50001 4.98875 3.76118 5.24992 4.08334 5.24992H5.83334C6.15551 5.24992 6.41668 4.98875 6.41668 4.66659C6.41668 4.34442 6.15551 4.08325 5.83334 4.08325H4.08334Z"
                    />
                    <path
                        d="M3.50001 6.99992C3.50001 6.67775 3.76118 6.41658 4.08334 6.41658H4.66668C4.98884 6.41658 5.25001 6.67775 5.25001 6.99992C5.25001 7.32208 4.98884 7.58325 4.66668 7.58325H4.08334C3.76118 7.58325 3.50001 7.32208 3.50001 6.99992Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.98009 3.8225C8.91391 3.69013 8.80418 3.59392 8.67696 3.54255C8.6057 3.51365 8.53154 3.49987 8.45831 3.49992C8.38509 3.49987 8.31093 3.51365 8.23967 3.54255C8.11244 3.59392 8.00272 3.69013 7.93654 3.8225L6.48361 6.72836C6.47976 6.73566 6.47606 6.74305 6.47252 6.75053L5.89487 7.90584C5.75079 8.19399 5.86759 8.54438 6.15574 8.68846C6.4439 8.83254 6.79429 8.71574 6.93837 8.42759L7.36054 7.58325H9.55609L9.97826 8.42759C10.1223 8.71574 10.4727 8.83254 10.7609 8.68846C11.049 8.54438 11.1658 8.19399 11.0218 7.90584L10.4451 6.75259C10.441 6.74372 10.4366 6.73497 10.432 6.72635L8.98009 3.8225ZM8.97276 6.41658L8.45831 5.38769L7.94387 6.41658H8.97276Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.16668 1.45825C0.844511 1.45825 0.583344 1.71942 0.583344 2.04159V10.7916C0.583344 11.1138 0.844511 11.3749 1.16668 11.3749H5.30005L6.58753 12.6624C6.81534 12.8902 7.18468 12.8902 7.41249 12.6624L8.69997 11.3749H12.8333C13.1555 11.3749 13.4167 11.1138 13.4167 10.7916V2.04159C13.4167 1.71942 13.1555 1.45825 12.8333 1.45825H1.16668ZM1.75001 10.2083V2.62492H12.25V10.2083H8.45834C8.30363 10.2083 8.15526 10.2697 8.04587 10.3791L7.00001 11.425L5.95416 10.3791C5.84476 10.2697 5.69639 10.2083 5.54168 10.2083H1.75001Z"
                    />
                </svg>
            )}
            {...props}
        />
    )
}
