// Libraries

// React
import React, { useState } from 'react'
import { useAppDispatch } from '@hooks/useStore'

// Antd
import { Alert, Button, Card, Divider, Form, Input, Tooltip } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import style from './CommunityEditModal.scss'
import { FormInstance } from 'antd/lib/form/Form'
import strings from '@resources/localization'
import { useParams } from 'react-router'
import { BoardRouteParams } from '@typings/routing/Router'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { FooterModal } from '@components/modals/components/FooterModal'
import { buildCommunityEditRequest } from '@components/form/Builders/CommunityEditRequestBuilder'
import { communityEdit } from '@state/actions'
import { DetailCommunityEntityItem } from '@state/types/ReducerTypes'
import { PostRateView } from '@api/model/responses/post/PostRateView'
import { LinkedinFilled, YoutubeFilled, FacebookFilled, InstagramFilled, GlobalOutlined } from '@ant-design/icons'
import Paragraph from 'antd/lib/typography/Paragraph'
import useBreakpoints from '@hooks/useBreakpoints'
import { CommunityEdit, CommunityView } from '@api/model/responses/community/CommunityView'
import { Mail } from '@icon-park/react'
import { ColorSelector } from '@components/form/ColorSelector/ColorSelector'
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem'
import { LinkColor } from '@components/buttons/link_color/LinkColor'
import { XIcon } from '@components/icons/XIcon'
import { TuringoUpload } from '@components/form/TuringoUpload/TuringoUpload'
import { formatAsset } from '@api/requests/AsyncRequests'

// MARK: - Definition

namespace CommunityEditModal {
    export interface Props {
        entity: DetailCommunityEntityItem<CommunityView, PostRateView>
        open: boolean
        setVisibility: React.Dispatch<React.SetStateAction<boolean>>
        formInstance: FormInstance
    }
}

export const urlRules = [
    {
        pattern: /^(?:https?:\/\/)?(?:[a-z0-9._/~%\-+&#?!=()@]+\.)(?:\.?[a-z0-9._/~%\-+&#?!=()@]{2,})+$/gim,
        message: strings.screens.profile.editModal.website.validations.pattern,
    },
]

// MARK: - Implementation

const CommunityEditModal: React.FC<CommunityEditModal.Props> = (props: CommunityEditModal.Props) => {
    const { entity, open, setVisibility, formInstance } = props
    const { cm_pk, b_pk } = useParams<BoardRouteParams>()

    const [hiddenModal, setHiddenModal] = useState(false)
    const [colorSelected, setColorSelected] = useState(entity.item?.ui?.style?.color)

    const cancel = () => {
        setVisibility(false)
    }
    const dispatch = useAppDispatch()
    const breakpoints = useBreakpoints()
    const [hiddenEditProfile, setEditProfileHidden] = useState(false)
    const [avatarError, setAvatarError] = useState<{ message?: string; new: boolean }>({ message: undefined, new: false })

    const submit = (values: CommunityEdit) => {
        const { bannerUrl, logoUrl } = values

        const { body: bodyParams, count } = buildCommunityEditRequest(values, entity.item, colorSelected)
        // If no changes, just leave
        if (count === 0) {
            cancel()
            return
        }
        void dispatch(
            communityEdit({
                urlParams: { cm_pk },
                bodyParams: bodyParams,
                uploads: {
                    assets: [...formatAsset(bannerUrl, 'banner', 'banner'), ...formatAsset(logoUrl, 'avatar', 'avatar')],
                },

                options: {
                    then: { action: cancel },
                    alerts: {
                        success: strings.alerts.community.edit.success,
                        error: strings.alerts.community.edit.failure,
                    },
                },
            })
        )
    }

    return (
        <TuringoModal
            open={open}
            title={strings.screens.admin.general.modalEdit.title}
            onOk={formInstance.submit}
            onCancel={cancel}
            width={806}
            hidden={hiddenModal}
            footer={[
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        action: formInstance.submit,
                        loading: entity.state.editing,
                        customText: strings.screens.profile.editModal.actions.accept,
                    }}
                    secondary={{
                        action: cancel,
                        customText: strings.screens.profile.editModal.actions.cancel,
                    }}
                />,
            ]}
        >
            <div className={'scrollStyle'} style={{ overflow: 'auto', height: '66vh' }}>
                <Form
                    onFinish={submit}
                    form={formInstance}
                    initialValues={{
                        bannerUrl: entity?.item?.bannerUrl ? [{ preview: entity?.item?.bannerUrl }] : undefined,
                        logoUrl: entity?.item?.logoUrl ? [{ preview: entity?.item?.logoUrl }] : undefined,
                        name: entity.item.name,
                        profile: entity.item.profile,
                        ...entity.item.contact,
                        rules:
                            entity.item.rule?.length > 0
                                ? entity.item.rule
                                : [
                                      {
                                          title: '',
                                          description: '',
                                      },
                                  ],

                        ...(entity.item.visibility && {
                            visibility: entity.item.visibility == 'public' ? false : true,
                        }),
                    }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                >
                    <div className={style.imgContainer} style={{ marginBottom: breakpoints.xxs || breakpoints.xs ? 50 : 100 }}>
                        <TuringoUpload
                            label={strings.general.image.plural}
                            isEditing={false}
                            name={'bannerUrl'}
                            rules={{
                                maxFiles: 1,
                                maxSize: 10,
                            }}
                            type={'image'}
                            style={{ marginBottom: 0 }}
                            uploadArea={{
                                showDescription: true,
                                style: {
                                    height: 252,
                                },
                            }}
                            editOptions={{
                                aspect_ratio: '5:2',
                                active: true,
                            }}
                            setError={setAvatarError}
                        />

                        <TuringoUpload
                            setError={setAvatarError}
                            uploadArea={{
                                btnShape: 'circle',
                                showDescription: false,
                                style: {
                                    border: '4px solid var(--background)',
                                    borderRadius: 100,
                                    height: breakpoints.xxs || breakpoints.xs ? 116 : 160,
                                    width: breakpoints.xxs || breakpoints.xs ? 116 : 160,
                                },
                            }}
                            fileOptions={{
                                style: {
                                    border: '4px solid var(--background)',
                                    borderRadius: 100,
                                    height: breakpoints.xxs || breakpoints.xs ? 116 : 160,
                                    width: breakpoints.xxs || breakpoints.xs ? 116 : 160,
                                },
                            }}
                            editOptions={{
                                aspect_ratio: '1:1',
                                active: true,
                            }}
                            rules={{
                                maxFiles: 1,
                                maxSize: 10,
                            }}
                            style={{ position: 'absolute', bottom: breakpoints.xxs || breakpoints.xs ? -80 : -100, left: 24, width: 160, zIndex: 2 }}
                            name={'logoUrl'}
                            type={'image'}
                        />
                    </div>
                    {avatarError?.message && (
                        <Alert
                            showIcon
                            type="error"
                            message={avatarError.message}
                            style={{
                                /*                                 ...(isMobile ? { marginBottom: 4 } : { marginTop: 4 }),
                                 */ borderRadius: 4,
                                marginBottom: 16,
                            }}
                        ></Alert>
                    )}
                    <Form.Item
                        name={'name'}
                        label={<Paragraph strong>{strings.screens.admin.general.modalEdit.fieldName.label}</Paragraph>}
                        rules={[
                            {
                                required: true,
                                message: strings.screens.admin.general.modalEdit.fieldName.validations.required,
                            },
                            {
                                pattern: /^[a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ'\- ]*$/,
                                message: strings.screens.admin.general.modalEdit.fieldName.validations.pattern,
                            },
                            {
                                min: 3,
                                message: strings.screens.admin.general.modalEdit.fieldName.validations.short,
                            },
                        ]}
                    >
                        <Input autoComplete="off" showCount maxLength={50} />
                    </Form.Item>

                    <Form.Item name={['profile', 'bio']} label={<Paragraph strong>{strings.screens.admin.general.sectionAboutTitle}</Paragraph>}>
                        <Input.TextArea
                            autoSize={{ minRows: breakpoints.isMobile ? 8 : 5, maxRows: 10 }}
                            autoComplete="off"
                            showCount
                            maxLength={400}
                            placeholder={''}
                        />
                    </Form.Item>
                    <Divider style={{ margin: '40px 0px 16px 0px' }} />

                    <Form.Item
                        style={{ marginBottom: 0 }}
                        name={['color']}
                        label={
                            <Paragraph style={{ marginBottom: 8 }} strong>
                                {strings.screens.admin.general.sectionColorTitle}
                            </Paragraph>
                        }
                    >
                        <ColorSelector selected={colorSelected} setSelected={setColorSelected}></ColorSelector>
                    </Form.Item>
                    <Divider style={{ margin: '0px 0px 16px 0px' }} />

                    <Paragraph strong style={{ marginBottom: 24 }}>
                        {strings.screens.admin.general.sectionRulesTitle}
                    </Paragraph>

                    <Form.List
                        name={['rules']}
                        rules={[
                            {
                                validator: async (_, names: string[]) => {
                                    if (!names || names.length < 1) {
                                        return Promise.reject(new Error('At least 1 options'))
                                    }
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <div style={{ display: 'grid', gridAutoFlow: 'row', gap: 16 }}>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <div key={`sign${index}`}>
                                        <Card key={key} bodyStyle={{ position: 'relative' }}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'title']}
                                                label={
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                        <p style={{ marginBottom: 0 }}>
                                                            {strings.formatString(strings.screens.admin.general.modalEdit.formRuleTitlePrefix, index + 1)}
                                                        </p>

                                                        {fields.length > 1 && (
                                                            <Tooltip title={strings.general.delete}>
                                                                <Button
                                                                    style={{ marginLeft: 16 }}
                                                                    shape="circle"
                                                                    icon={<DeleteOutlined />}
                                                                    onClick={() => remove(name)}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                }
                                                rules={[
                                                    { required: true, message: strings.screens.admin.general.modalEdit.fieldRuleTitle.validations.required },
                                                    { min: 3, message: strings.screens.admin.general.modalEdit.fieldRuleTitle.validations.short },
                                                ]}
                                                style={{ flexGrow: 1 }}
                                            >
                                                <Input autoComplete="off" showCount maxLength={50} />
                                            </Form.Item>

                                            <Form.Item
                                                rules={[
                                                    { required: true, message: strings.screens.admin.general.modalEdit.fieldRuleBody.validations.required },
                                                    { min: 3, message: strings.screens.admin.general.modalEdit.fieldRuleBody.validations.short },
                                                ]}
                                                {...restField}
                                                name={[name, 'description']}
                                                label={strings.screens.admin.general.modalEdit.fieldRuleBody.label}
                                                style={{ flexGrow: 1 }}
                                            >
                                                <Input.TextArea autoComplete="off" showCount maxLength={200} />
                                            </Form.Item>
                                        </Card>
                                        <div>
                                            {index == fields.length - 1 && fields.length < 5 && (
                                                <LinkColor style={{ marginTop: 16 }} onClick={() => add()}>
                                                    <PlusOutlined style={{ marginRight: 6 }} />
                                                    {strings.screens.admin.general.modalEdit.buttonAddRule.title}
                                                </LinkColor>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {/*                               <Form.Item>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item> */}
                            </div>
                        )}
                    </Form.List>
                    <Divider style={{ margin: '16px 0px' }} />

                    <Paragraph strong style={{ marginBottom: 24 }}>
                        {strings.screens.admin.general.sectionNetworkTitle}
                    </Paragraph>

                    <Form.Item
                        name={['twitter']}
                        rules={urlRules}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                <XIcon width={18} height={18} style={{ fontSize: 18, marginRight: 8 }} />
                                {strings.screens.admin.general.network.twitterTitle}
                            </div>
                        }
                    >
                        <Input autoComplete="off" placeholder={'https://twitter.com/username'} />
                    </Form.Item>
                    <Form.Item
                        name={['linkedin']}
                        rules={urlRules}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                <LinkedinFilled style={{ fontSize: 18, marginRight: 8 }} />
                                {strings.screens.admin.general.network.linkedinTitle}
                            </div>
                        }
                    >
                        <Input autoComplete="off" placeholder={'https://linkedin.com/username'} />
                    </Form.Item>
                    <Form.Item
                        name={['facebook']}
                        rules={urlRules}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                <FacebookFilled style={{ fontSize: 18, marginRight: 8 }} />
                                {strings.screens.admin.general.network.facebookTitle}
                            </div>
                        }
                    >
                        <Input autoComplete="off" placeholder={'https://facebook.com/username'} />
                    </Form.Item>
                    <Form.Item
                        name={['youtube']}
                        rules={urlRules}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                <YoutubeFilled style={{ fontSize: 18, marginRight: 8 }} />
                                {strings.screens.admin.general.network.youtubeTitle}
                            </div>
                        }
                    >
                        <Input autoComplete="off" placeholder={'https://youtube.com/username'} />
                    </Form.Item>
                    <Form.Item
                        name={['instagram']}
                        rules={urlRules}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                <InstagramFilled style={{ fontSize: 18, marginRight: 8 }} />
                                {strings.screens.admin.general.network.instagramTitle}
                            </div>
                        }
                    >
                        <Input autoComplete="off" placeholder={'https://instagram.com/username'} />
                    </Form.Item>

                    <FormEmailItem
                        autoComplete={false}
                        name={'email'}
                        placeholder={strings.screens.admin.general.modalEdit.fieldEmail.placeholder}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                <Mail theme="filled" size="18" style={{ marginRight: 8 }} />
                                {strings.screens.admin.general.network.emailTitle}
                            </div>
                        }
                        validations={['format', 'long', 'short']}
                    />

                    <Form.Item
                        name={['profile', 'url']}
                        rules={urlRules}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                <GlobalOutlined style={{ fontSize: 18, marginRight: 8 }} />
                                {strings.screens.admin.general.modalEdit.fieldWebsite.label}
                            </div>
                        }
                    >
                        <Input autoComplete="off" placeholder={strings.screens.admin.general.modalEdit.fieldWebsite.placeholder} />
                    </Form.Item>
                    {/*                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 24 }}>
                        <div>
                            <LockOutlined style={{ fontSize: 18, marginRight: 8 }} />
                            {'Comunidad privada'}
                        </div>

                        <Form.Item name="visibility" style={{ marginBottom: 0 }}>
                            <Switch
                                defaultChecked={entity.item.visibility == 'private' ? true : false}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                            />
                        </Form.Item>
                    </div> */}
                </Form>
            </div>
        </TuringoModal>
    )
}

export { CommunityEditModal }
