import useBreakpoints from '@hooks/useBreakpoints'
import { Icon } from '@icon-park/react/lib/runtime'
import { Button, ButtonProps } from 'antd'
import React, { CSSProperties } from 'react'

interface ExpandableButtonProps extends ButtonProps {
    ByteIcon?: Icon
    innerStyle?: CSSProperties
}

export const ExpandableButton = (props: ExpandableButtonProps) => {
    const breakpoints = useBreakpoints()
    const {innerStyle, ByteIcon, ...rest} = props;

    const isMobile = breakpoints.isMobile
    return (
        <Button {...rest} style={{ ...props.style, borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }} block={isMobile ? true : false} >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...props.innerStyle }}>
                {props.ByteIcon && <props.ByteIcon style={{ display: 'flex', marginRight: 4 }} />}
                {props.children}
            </div>
        </Button>
    )
}
