import { Peoples } from '@icon-park/react'
import { Card, Row } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import React from 'react'
import strings from '@resources/localization'
import styles from '@components/navegation/sider/Siderbar/SidebarItems.scss'
import { Link, useParams } from 'react-router-dom'
import { API } from '@api/API'
import { CommunityRouteParams } from '@typings/routing/Router'
import { LinkedinFilled, TwitterOutlined, YoutubeFilled, FacebookFilled, InstagramFilled, MailFilled } from '@ant-design/icons'
import { RootReducer } from '@state/reducers/Root'
import { useAppSelector } from '@hooks/useStore'
import { LinkButton } from '@components/buttons/link_button/LinkButton'
import { XIcon } from '@components/icons/XIcon'

interface Props {
    memberCount: number
    description: string
    contact: {
        instagram?: string
        facebook?: string
        linkedin?: string
        youtube?: string
        twitter?: string
        email?: string
    }
}

export const CommunityDetails = (props: Props) => {
    const { memberCount, description, contact } = props

    const { cm_pk } = useParams<CommunityRouteParams>()
    const theme = useAppSelector((state) => state.ui.theme)

    return (
        <Card title={strings.general.detail} bordered={false}>
            <Row style={{ alignItems: 'center', marginBottom: 16, flexFlow: 'row' }}>
                <Peoples style={{ marginRight: 8, display: 'flex' }} theme="outline" size="14" strokeWidth={4} />

                <Paragraph style={{ marginRight: 8, marginBottom: 0 }} strong>
                    {strings.screens.boards.discussion.subscribers}{' '}
                </Paragraph>
                <Paragraph style={{ marginBottom: 0, wordBreak: 'break-word' }}>{memberCount}</Paragraph>
            </Row>

            <Paragraph style={{ marginBottom: 0, wordBreak: 'break-word' }}>{description}</Paragraph>
            {cm_pk == 'turingo' && (
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                    <a href={'https://blog.turingo.com'} target={'_blank'} style={{ marginBottom: 8, fontSize: 12 }} rel="noreferrer">
                        {strings.navigation.usermenu.options.blog}
                    </a>
                    <Link
                        to={{ pathname: '/help', search: '?page=general' }}
                        style={{ marginBottom: 8, fontSize: 12 }}
                        onClick={() => {
                            void API.analyticsAction({ bodyParams: { action: 'support.faq', sender: 'main.nav.menu' } })
                        }}
                    >
                        {strings.navigation.usermenu.options.faq}
                    </Link>
                    <Link to={'/aboutUs'} style={{ marginBottom: 8, fontSize: 12 }}>
                        {strings.navigation.usermenu.options.aboutus}
                    </Link>
                </div>
            )}

            <div style={{ display: 'grid', gridAutoFlow: 'column', columnGap: 5, width: 'fit-content', marginTop: 16 }}>
                {contact?.linkedin && (
                    <LinkButton
                        type="text"
                        externalHref={contact.linkedin}
                        title={APP_MANIFEST.sites.social.linkedin.network}
                        target={'_blank'}
                        btnStyle={{ padding: 6, height: 32 }}
                        rel="noreferrer"
                    >
                        <LinkedinFilled style={{ fontSize: 20, height: 20, width: 20 }} />
                    </LinkButton>
                )}
                {contact?.twitter && (
                    <LinkButton
                        type="text"
                        externalHref={contact?.twitter}
                        title={APP_MANIFEST.sites.social.twitter.network}
                        target={'_blank'}
                        btnStyle={{ padding: 6, height: 32 }}
                        rel="noreferrer"
                    >
                        <XIcon  height={20} width={20}   style={{ fontSize: 20}} />
                    </LinkButton>
                )}
                {contact?.youtube && (
                    <LinkButton
                        type="text"
                        externalHref={contact?.youtube}
                        title={APP_MANIFEST.sites.social.youtube.network}
                        target={'_blank'}
                        btnStyle={{ padding: 6, height: 32 }}
                        rel="noreferrer"
                    >
                        <YoutubeFilled style={{ fontSize: 20, height: 20, width: 20 }} />
                    </LinkButton>
                )}
                {contact?.facebook && (
                    <LinkButton
                        type="text"
                        externalHref={contact?.facebook}
                        title={APP_MANIFEST.sites.social.facebook.network}
                        target={'_blank'}
                        btnStyle={{ padding: 6, height: 32 }}
                        rel="noreferrer"
                    >
                        <FacebookFilled style={{ fontSize: 20, height: 20, width: 20 }} />
                    </LinkButton>
                )}
                {contact?.instagram && (
                    <LinkButton
                        type="text"
                        externalHref={contact?.instagram}
                        title={APP_MANIFEST.sites.social.instagram.network}
                        target={'_blank'}
                        btnStyle={{ padding: 6, height: 32 }}
                        rel="noreferrer"
                    >
                        <InstagramFilled style={{ fontSize: 20, height: 20, width: 20 }} />
                    </LinkButton>
                )}

                {cm_pk == 'turingo' && (
                    <LinkButton
                        type="text"
                        onClick={() => {
                            void API.analyticsAction({ bodyParams: { action: 'support.contact', sender: 'main.nav.menu' } })
                        }}
                        href={{
                            pathname: '/help',
                            search: '?page=contact',
                        }}
                        btnStyle={{ padding: 6, height: 32 }}
                    >
                        <MailFilled style={{ fontSize: 20, height: 20, width: 20 }} />
                    </LinkButton>
                )}

                {contact?.email && (
                    <LinkButton
                        type="text"
                        externalHref={`mailto: ${contact?.email}`}
                        title={'Email'}
                        target={'_blank'}
                        btnStyle={{ padding: 6, height: 32 }}
                        rel="noreferrer"
                    >
                        <MailFilled style={{ fontSize: 20, height: 20, width: 20 }} />
                    </LinkButton>
                )}
            </div>
        </Card>
    )
}
