import React from 'react'
import { Skeleton } from 'antd'
import useBreakpoints from '@hooks/useBreakpoints'

interface IProps {
    nElements?: number
}

export const ChatListSkeleton = (props: IProps) => {
    const {nElements = 9} = props
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    
    return (
        <div style={{
            maxHeight: isMobile? 'unset': 'calc(100vh - 310px)' ,
            overflow: 'auto',
        }}>
            {Array(nElements)
                .fill(undefined)
                .map((e, i) => {
                    return (
                        <div key={i} style={{ display: 'flex', alignContent: 'center', marginBottom: i == nElements - 1 ? 0 : 32 }}>
                            <Skeleton.Avatar active size={32} shape={'circle'} style={{ marginRight: 18 }} />
                            <div style={{ display: 'grid', gap: 8, width: '100%' }}>
                                <Skeleton style={{ width: 100 }} title active paragraph={null} />
                                <Skeleton style={{ width: '100%' }} title active paragraph={null} />
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}
