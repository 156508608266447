import { useEffect, useState } from 'react'
import { parse, STATES } from './utils'
import { Modal, Spin } from 'antd'

type ParamsType = {
    state: string
    code?: string
    error?: string
    error_description?: string
}

export function LinkedInCallback() {
    const [errorMessage, setErrorMessage] = useState<string>('')
    useEffect(() => {
        const params = parse(window.location.search) as ParamsType
        if (params.state !== localStorage.getItem(STATES['linkedin'])) {
            setErrorMessage('State does not match')
        } else if (params.error) {
            const errorMessage = params.error_description || 'Login failed. Please try again.'
            window.opener &&
                window.opener.postMessage(
                    {
                        error: params.error,
                        state: params.state,
                        errorMessage,
                        from: 'Turingo',
                    },
                    window.location.origin
                )
            // Close tab if user cancelled login
            if (params.error === 'user_cancelled_login') {
                window.close()
            }
        }
        if (params.code) {
            window.opener && window.opener.postMessage({ code: params.code, state: params.state, from: 'Turingo' }, window.location.origin)
        }
    }, [])

    return <Spin></Spin>
}

export function GoogleCallback() {
    const [errorMessage, setErrorMessage] = useState<string>('')
    useEffect(() => {
        const params = parse(window.location.search) as ParamsType
        if (params.state !== localStorage.getItem(STATES['google'])) {
            setErrorMessage('State does not match')
        } else if (params.error) {
            const errorMessage = params.error_description || 'Login failed. Please try again.'
            window.opener &&
                window.opener.postMessage(
                    {
                        error: params.error,
                        state: params.state,
                        errorMessage,
                        from: 'Turingo',
                    },
                    window.location.origin
                )
            // Close tab if user cancelled login
            if (params.error === 'user_cancelled_login') {
                window.close()
            }
        }
        if (params.code) {
            window.opener && window.opener.postMessage({ code: params.code, state: params.state, from: 'Turingo' }, window.location.origin)
        }
    }, [])

    return <Spin></Spin>
}

export function FacebookCallback() {
    const [errorMessage, setErrorMessage] = useState<string>('')
    useEffect(() => {
        const params = parse(window.location.search) as ParamsType
        if (params.state !== localStorage.getItem(STATES['facebook'])) {
            setErrorMessage('State does not match')
        } else if (params.error) {
            const errorMessage = params.error_description || 'Login failed. Please try again.'
            window.opener &&
                window.opener.postMessage(
                    {
                        error: params.error,
                        state: params.state,
                        errorMessage,
                        from: 'Turingo',
                    },
                    window.location.origin
                )
            // Close tab if user cancelled login
            if (params.error === 'user_cancelled_login') {
                window.close()
            }
        }
        if (params.code) {
            window.opener && window.opener.postMessage({ code: params.code, state: params.state, from: 'Turingo' }, window.location.origin)
        }
    }, [])

    return <Spin></Spin>
}

export function ZoomCallback() {
    const [errorMessage, setErrorMessage] = useState<string>('')
    useEffect(() => {
        const params = parse(window.location.search) as ParamsType
        if (params.state !== localStorage.getItem(STATES['zoom'])) {
            setErrorMessage('State does not match')
        } else if (params.error) {
            const errorMessage = params.error_description || 'Login failed. Please try again.'
            window.opener &&
                window.opener.postMessage(
                    {
                        error: params.error,
                        state: params.state,
                        errorMessage,
                        from: 'Turingo',
                    },
                    window.location.origin
                )
            // Close tab if user cancelled login
            if (params.error === 'user_cancelled_login') {
                window.close()
            }
        }
        if (params.code) {
            window.opener && window.opener.postMessage({ code: params.code, state: params.state, from: 'Turingo' }, window.location.origin)
        }
    }, [])

    return <Spin></Spin>
}
