// Libraries

// React
import React, { useContext } from 'react'

// Antd
import { AvatarTuringo } from '@components/avatar/Avatar'
import { Down } from '@icon-park/react'
import style from './ButtonSelect.scss'
import useBreakpoints from '@hooks/useBreakpoints'
import ClassNames from '@util/style/ClassNames'
import { PostContext } from '@components/content/posts/Post'

// MARK: - Definition

namespace ButtonSelect {
    export interface Props {
        onClick: () => void
        icon?: JSX.Element
        shape?: 'circle' | 'square'
    }
}

// MARK: - Implementation

const ButtonSelect: React.FC<ButtonSelect.Props> = (props: ButtonSelect.Props) => {
    const { onClick, icon, shape = 'circle' } = props
    const context = useContext(PostContext)
    const mask = context?.mask?.get
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    return (
        <div
            className={ClassNames(style.button_select, 'tgo-hover-button')}
            onClick={onClick}
            style={{
                padding: icon ? (isMobile ? '8px' : '7px') : isMobile ? '1px 7px 1px 3px' : '2px 7px 2px 3px',
                borderRadius: shape == 'circle' ? '100px' : '4px',
            }}
        >
            {!icon && (
                <AvatarTuringo
                    size={16}
                    style={{
                        height: isMobile ? 40 : 32,
                        width: isMobile ? 40 : 32,
                        marginRight: 4,
                        lineHeight: isMobile ? '40px' : '32px',
                        minWidth: isMobile ? 40 : 32,
                        border: '1px solid var(--border)',
                    }}
                    name={mask?.name}
                    avatar={mask?.avatarUrl}
                />
            )}
            {icon && <div style={{ display: 'flex', alignItems: 'center', width: isMobile ? 16 : 14, height: isMobile ? 16 : 14, marginRight: 4 }}>{icon}</div>}
            <Down style={{ display: 'flex' }} theme="outline" size={isMobile ? (icon ? 16 : 18) : icon ? 14 : 16} strokeLinejoin="bevel" />
        </div>
    )
}

export { ButtonSelect }
