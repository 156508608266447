import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    filled?: boolean
    height?: number
    width?: number
}

export const CatalogueItem9 = (props: Props) => {
    return (
        <Icon
            component={() => {
                if (props.filled) {
                    return (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.75 7.23554C9.4406 7.0569 9.0594 7.0569 8.75 7.23554C8.4406 7.41417 8.25 7.7443 8.25 8.10156V15.8958C8.25 16.253 8.4406 16.5832 8.75 16.7618C9.0594 16.9404 9.4406 16.9404 9.75 16.7618L16.5 12.8647C16.8094 12.6861 17 12.3559 17 11.9987C17 11.6414 16.8094 11.3113 16.5 11.1326L9.75 7.23554ZM14 11.9987L10.25 14.1637V9.83361L14 11.9987Z"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.5 2C3.11929 2 2 3.11929 2 4.5V19.5C2 20.8807 3.11929 22 4.5 22H19.5C20.8807 22 22 20.8807 22 19.5V4.5C22 3.11929 20.8807 2 19.5 2H4.5ZM4 4.5C4 4.22386 4.22386 4 4.5 4H19.5C19.7761 4 20 4.22386 20 4.5V19.5C20 19.7761 19.7761 20 19.5 20H4.5C4.22386 20 4 19.7761 4 19.5V4.5Z"
                            />
                            <path d="M10.25 14.1637L14 11.9987L10.25 9.83361V14.1637Z" />
                        </svg>
                    )
                } else {
                    return (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.75 7.23554C9.4406 7.0569 9.0594 7.0569 8.75 7.23554C8.4406 7.41417 8.25 7.7443 8.25 8.10156V15.8958C8.25 16.253 8.4406 16.5832 8.75 16.7618C9.0594 16.9404 9.4406 16.9404 9.75 16.7618L16.5 12.8647C16.8094 12.6861 17 12.3559 17 11.9987C17 11.6414 16.8094 11.3113 16.5 11.1326L9.75 7.23554ZM14 11.9987L10.25 14.1637V9.83361L14 11.9987Z"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.5 2C3.11929 2 2 3.11929 2 4.5V19.5C2 20.8807 3.11929 22 4.5 22H19.5C20.8807 22 22 20.8807 22 19.5V4.5C22 3.11929 20.8807 2 19.5 2H4.5ZM4 4.5C4 4.22386 4.22386 4 4.5 4H19.5C19.7761 4 20 4.22386 20 4.5V19.5C20 19.7761 19.7761 20 19.5 20H4.5C4.22386 20 4 19.7761 4 19.5V4.5Z"
                            />
                        </svg>
                    )
                }
            }}
            {...props}
        />
    )
}
