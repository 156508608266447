import React from 'react'
import { LikeIcon } from '@components/icons/LikeIcon'
import Paragraph from 'antd/lib/typography/Paragraph'

export const PostReactionsTag = (props: { totalReactions: number; onClick: () => void }) => {
    if (props.totalReactions == 0) return <span></span>
    return (
        <div
            onClick={props.onClick}
            style={{
                display: 'flex',
                cursor: 'pointer',
                padding: '4px 8px',
                background: 'var(--ant-primary-1)',
                border: '1px solid var(--ant-primary-color)',
                borderRadius: '20px',
                alignItems: 'center',
                color:"var(--ant-primary-color)"
            }}
        >
            <LikeIcon  style={{ fontSize: 16, height: 16, width: 16, marginRight: 4, display: 'flex' }} />
            <Paragraph className='turingo-text' style={{ color: 'var(--ant-primary-color)' }}> {props.totalReactions}</Paragraph>
        </div>
    )
}
