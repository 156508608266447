import { Button, Modal, Row } from 'antd'
import React, { ReactNode } from 'react'
import Paragraph from 'antd/lib/typography/Paragraph'
import strings from '@resources/localization'
import { InformationModalBody } from './InformationModalBody'
import { FooterModal } from '@components/modals/components/FooterModal'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'

type ModalKind = 'success' | 'warning' | 'error'

interface Props {
    open: boolean
    onClick: () => void
    onCancel?: () => void
    kind: ModalKind
    title: string
    description?: string
    onClickText?: string
    onClickDisabled?: boolean
    onCancelText?: string
    subdescription?: string
    loading?: boolean
    children?: ReactNode | ReactNode[]
}

export const InformationModal = (props: Props) => {
    const { open, onCancel, kind, title, description, onClick, onClickText, subdescription, children, onCancelText, onClickDisabled } = props

    return (
        <TuringoModal
            open={open}
            onCancel={onCancel}
            onOk={onClick}
            width={600}
            footer={[
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        action: onClick,
                        disabled: onClickDisabled,
                        customText: onClickText ? onClickText : 'OK',
                    }}
                    secondary={{
                        action: onCancel,
                        customText: onCancelText ? onCancelText : strings.general.cancel,
                    }}
                />,
            ]}
        >
            <InformationModalBody forceLeftTextAlign kind={kind} title={title} description={description} subdescription={subdescription} />
            {children}
        </TuringoModal>
    )
}
