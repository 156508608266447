import { Category, CommunityMemberSchemaView, Property } from '@api/model/responses/community/CommunityView'

export interface CategoriesByPlacement {
    [placement: string]: Category[]
}

// Función para filtrar categorías y propiedades
export function filterArchived(categories: Category[]): Category[] {
    return categories
        .filter((category) => !category.archived)
        .map((category) => ({
            ...category,
            properties: category.properties.filter((property) => !property.archived),
        }))
}

export const addCategoryIdToProperties = (categories: Category[]): Category[] => {
    if (!categories) return undefined
    return categories.map((category) => {
        const updatedProperties = category.properties.map((property) => ({
            ...property,
            categoryId: category.id,
            placement: category.placement,
        }))
        return {
            ...category,
            properties: updatedProperties,
        }
    })
}

// Group the categories by placement
export const separateByPlacement = (categories: Category[]): CategoriesByPlacement => {
    return categories.reduce((acc: CategoriesByPlacement, category: Category) => {
        const { placement } = category

        if (placement == 'primary') {
            if (!acc['center']) {
                acc['center'] = []
            }
            acc['center'].push(category)
        } else if (placement == 'blocked') {
            if (!acc['right']) {
                acc['right'] = []
            }
            acc['right'].push(category)
        } else {
            if (!acc[placement]) {
                acc[placement] = []
            }
            acc[placement].push(category)
        }

        return acc
    }, {})
}

// Función para filtrar categorías y propiedades según las condiciones dadas
export function filterCategoriesAndProperties(categories: Category[]): (Category | Property)[] {
    const result: (Category | Property)[] = []

    categories.forEach((category) => {
        if (category.archived) {
            // Si la categoría está archivada, agregar toda la categoría
            result.push(category)
        } else {
            // Si la categoría no está archivada, agregar solo las propiedades archivadas
            category.properties.forEach((property) => {
                if (property.archived) {
                    result.push({ ...property, placement: category.placement, categoryId: category.id })
                }
            })
        }
    })

    return result
}

export const formatPlacement = (placement: string) => {
    if (placement == 'primary') {
        return 'center'
    } else if (placement == 'blocked') {
        return 'right'
    } else {
        return placement
    }
}

export const formatResponse = (schema: CategoriesByPlacement, archived: (Category | Property)[]) => {
    const schemaCopy = JSON.parse(JSON.stringify(schema))

    const result: Category[] = []
    Object.keys(schemaCopy).forEach((placement) => {
        schemaCopy[placement].forEach((category) => {
            result.push(category)
        })
    })

    archived.forEach((archive) => {
        if (!('categoryId' in archive)) {
            result.push(archive as Category)
        }
    })

    archived.forEach((archive) => {
        if ('categoryId' in archive) {
            const category = result.find((category) => category.id == archive.categoryId)
            if (category) {
                category.properties.push(archive)
            }
        }
    })

    return result
}

export const extractCategories = (data: CategoriesByPlacement) => {
    const categories = [...(data?.center || []), ...(data?.right || [])]
    return categories.map((category) => ({
        value: category.id,
        label: category.title,
        title: category.placement,
    }))
}

export function getOrderedProperties(sections: Category[]): Property[] {
    if (!sections) return undefined
    let allProperties: Property[] = []

    sections.forEach((section) => {
        allProperties = allProperties.concat(section.properties)
    })

    return allProperties.sort((a, b) => a.orderEditor - b.orderEditor).filter((property) => !property.archived)
}

export function getAllPropertiesOrderedByOrderEditor(config: CategoriesByPlacement): Property[] {
    // Recolectar todas las propiedades de las secciones
    const allProperties: Property[] = []

    // Función para recolectar propiedades de una sección
    function collectProperties(sections: Category[]) {
        sections?.forEach((section) => {
            section.properties?.forEach((property) => {
                allProperties.push(property)
            })
        })
    }

    // Recolectar propiedades de las secciones 'center' y 'right'
    collectProperties(config?.center)
    collectProperties(config?.right)

    // Ordenar las propiedades por 'orderEditor'
    allProperties.sort((a, b) => a.orderEditor - b.orderEditor)

    return allProperties
}

export function getAllPropertiesOrderedByOnboardingOrder(config: CategoriesByPlacement): Property[] {
    // Recolectar todas las propiedades de las secciones
    const allProperties: Property[] = []

    // Función para recolectar propiedades de una sección
    function collectProperties(sections: Category[]) {
        sections?.forEach((section) => {
            section.properties?.forEach((property) => {
                if (property.onboarding) {
                    allProperties.push(property)
                }
            })
        })
    }

    // Recolectar propiedades de las secciones 'center' y 'right'
    collectProperties(config?.center)
    collectProperties(config?.right)

    // Ordenar las propiedades por 'orderEditor'
    allProperties.sort((a, b) => a.orderOnboarding - b.orderOnboarding)

    return allProperties
}

export function exceedLimit(schema: CommunityMemberSchemaView): boolean {
    // Recolectar todas las propiedades de las secciones
    const allProperties: Property[] = []

    // Función para recolectar propiedades de una sección
    function collectProperties(sections: Category[]) {
        sections?.forEach((section) => {
            section.properties?.forEach((property) => {
                allProperties.push(property)
            })
        })
    }
    // Recolectar propiedades de las secciones 'center' y 'right'
    collectProperties(schema?.categories)

    return allProperties.length >= schema.metadata.propertyLimit
}

export function schemaOnboarding(schema: CommunityMemberSchemaView): Property[] {
    // Recolectar todas las propiedades de las secciones
    const allProperties: Property[] = []

    // Función para recolectar propiedades de una sección
    function collectProperties(sections: Category[]) {
        sections?.forEach((section) => {
            section.properties?.forEach((property) => {
                if (property.onboarding) {
                    allProperties.push(property)
                }
            })
        })
    }

    // Recolectar propiedades de las secciones 'center' y 'right'
    collectProperties(schema?.categories)

    // Ordenar las propiedades por 'orderEditor'
    allProperties.sort((a, b) => a.orderOnboarding - b.orderOnboarding)

    return allProperties
}
export function updateOrderEditor(data: CategoriesByPlacement, propertyId: string, newOrder: number): CategoriesByPlacement {
    let allProperties: Property[] = []

    // Recolecta todas las propiedades
    for (const section of [data?.center, data?.right]) {
        if (section) {
            for (const category of section) {
                allProperties = allProperties.concat(category.properties)
            }
        }
    }

    // Encuentra la propiedad y actualiza su orderEditor
    const property = allProperties.find((prop) => prop.id === propertyId)
    if (!property) {
        throw new Error('Property not found')
    }

    const editorProperties = getAllPropertiesOrderedByOrderEditor(data).filter((prop) => !prop.highlight)

    const oldIndex = editorProperties.findIndex((prop) => prop.id === propertyId)

    editorProperties.splice(oldIndex, 1)
    editorProperties.splice(newOrder, 0, property)

    editorProperties.forEach((prop, index) => {
        prop.orderEditor = index
    })

    allProperties.sort((a, b) => a.orderProfile - b.orderProfile)

    // Reasigna las propiedades actualizadas a sus respectivas categorías
    const updatedData: CategoriesByPlacement = { center: [], right: [] }

    for (const section of [data.center, data.right]) {
        if(section){
            const updatedSection: Category[] = section.map((category) => {
                const updatedCategory = { ...category, properties: [] }
                for (const prop of allProperties) {
                    const inForm = editorProperties.find((p) => p.id === prop.id)
                    if (inForm) {
                        if (category.properties.find((p) => p.id === prop.id)) {
                            updatedCategory.properties.push(inForm)
                        }
                    } else {
                        if (category.properties.find((p) => p.id === prop.id)) {
                            updatedCategory.properties.push(prop)
                        }
                    }
                }
                return updatedCategory
            })
    
            if (section === data.center) {
                updatedData.center = updatedSection
            } else {
                updatedData.right = updatedSection
            }
        }
        
    }

    return updatedData
}

export function updateOrderOnboarding(data: CategoriesByPlacement, propertyId: string, newOrder: number): CategoriesByPlacement {
    let allProperties: Property[] = []

    // Recolecta todas las propiedades
    for (const section of [data?.center, data?.right]) {
        if (section) {
            for (const category of section) {
                allProperties = allProperties.concat(category.properties)
            }
        }
    }

    // Encuentra la propiedad y actualiza su orderOnboarding
    const property = allProperties.find((prop) => prop.id === propertyId)
    if (!property) {
        throw new Error('Property not found')
    }
    const onboardingProperties = getAllPropertiesOrderedByOnboardingOrder(data).filter((prop) => !prop.highlight)

    const oldIndex = onboardingProperties.findIndex((prop) => prop.id === propertyId)

    onboardingProperties.splice(oldIndex, 1)
    onboardingProperties.splice(newOrder, 0, property)

    onboardingProperties.forEach((prop, index) => {
        prop.orderOnboarding = index
    })

    allProperties.sort((a, b) => a.orderProfile - b.orderProfile)

    // Reasigna las propiedades actualizadas a sus respectivas categorías
    const updatedData: CategoriesByPlacement = { center: [], right: [] }

    for (const section of [data.center, data.right]) {
        if(section){
            const updatedSection: Category[] = section.map((category) => {
                const updatedCategory = { ...category, properties: [] }
                for (const prop of allProperties) {
                    const inBoarding = onboardingProperties.find((p) => p.id === prop.id)
                    if (inBoarding) {
                        if (category.properties.find((p) => p.id === prop.id)) {
                            updatedCategory.properties.push(inBoarding)
                        }
                    } else {
                        if (category.properties.find((p) => p.id === prop.id)) {
                            updatedCategory.properties.push(prop)
                        }
                    }
                }
                return updatedCategory
            })
    
            if (section === data.center) {
                updatedData.center = updatedSection
            } else {
                updatedData.right = updatedSection
            }
        }
       
    }

    return updatedData
}
