import useBreakpoints from '@hooks/useBreakpoints'
import { Check } from '@icon-park/react'
import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import style from './FormSelectModalItem.scss'

interface Props<DataType> {
    afterAction?: (value?: string | string[]) => void
    items: DataType[]
    valueField: string
    nameField: string
    defaultValue?: string | string[]
    maxSelection?: number
}

export function FormSelect<T>(props: Props<T>) {
    const { afterAction, items, defaultValue, maxSelection = 1, valueField = 'publicKey', nameField = 'name' } = props

    const [selected, setSelected] = useState(defaultValue)

    useEffect(() => {
        setSelected(defaultValue ? defaultValue : [])
    }, [defaultValue])

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const onSelect = (item: T) => {
        if (maxSelection > 1) {
            const selection = selected as string[]
            let newValue = []
            if (item) {
                if (isSelected(item)) newValue = selection.filter((itemFilter) => itemFilter != item[valueField])
                else {
                    if (selection.length >= maxSelection) {
                        void message.destroy()
                        void message.error(`Solo puedes seleccionar hasta ${maxSelection}`)
                        return
                    }
                    newValue = [...selection, item[valueField]]
                }
                if (afterAction) afterAction(newValue)
            }
        } else {
            if (item) setSelected(item[valueField])
            if (afterAction) afterAction(item[valueField])
        }
    }

    const onDeselect = (item: T) => {
        if (maxSelection > 1) {
            const selection = selected as string[]

            let newValue = []
            if (item) {
                if (isSelected(item)) newValue = selection.filter((itemFilter) => itemFilter != item[valueField])
                else {
                    if (selected.length >= 3) {
                        void message.destroy()
                        void message.error(`Solo puedes seleccionar hasta ${maxSelection} servicios`)
                        return
                    }
                    newValue = [...selection, item[valueField]]
                }
                if (afterAction) afterAction(newValue)
            }
        } else {
            if (item) setSelected(undefined)
            if (afterAction) afterAction(undefined)
        }
    }

    const isSelected = (item: T) => {
        if (!selected) return false

        if (maxSelection > 1) {
            const selection = selected as string[]

            if (selection.find((s) => s == item[valueField])) return true
        } else {
            const selection = selected as string

            return selection == item[valueField]
        }
        return false
    }
    return (
        <>
            <div>
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {items?.map((item, index: number) => {
                        const itemName = items[index]?.[nameField] as string

                        return (
                            <div
                                key={index}
                                onClick={() => (isSelected(items[index]) ? onDeselect(items[index]) : onSelect(items[index]))}
                                className={isSelected(items[index]) ? style.itemSelected : style.item}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: isMobile ? '10px 16px' : '4px 16px',
                                    width: 'fit-content',
                                    lineHeight: '20px',
                                    marginBottom: isMobile ? 24 : 16,
                                    paddingRight: isSelected(items[index]) ? (isMobile ? 40 : 34) : 16,
                                    position: 'relative',
                                }}
                            >
                                {itemName}
                                {isSelected(items[index]) ? (
                                    <Check
                                        style={{
                                            marginLeft: 4,
                                            position: 'absolute',
                                            right: isMobile ? 14 : 13,
                                            top: isMobile ? 12 : 6,
                                        }}
                                        theme="outline"
                                        size={isMobile ? 16 : 14}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
