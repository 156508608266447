import useBreakpoints from '@hooks/useBreakpoints'
import { useEffect, useRef } from 'react'
import { isSafari } from 'react-device-detect'
import { useAppSelector } from '@hooks/useStore'

interface Props {
    left: JSX.Element
    right?: JSX.Element | null
    single?: boolean
}

export const ContentLayout = (props: Props) => {
    const { left, right, single } = props

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const validationBanner = useAppSelector((state) => state.ui.validationBanner)

    const refInner = useRef<HTMLDivElement>()

    useEffect(() => {
        const aside = refInner.current

        if (aside) {
            const startScroll = validationBanner ? 104 : 72
            let endScroll = window.innerHeight - aside.offsetHeight - 500
            let currPos = window.scrollY
            let screenHeight = window.innerHeight
            let asideHeight = aside.offsetHeight

            aside.style.top = `${startScroll}px`

            // check height screen and aside on resize
            window.addEventListener('resize', () => {
                screenHeight = window.innerHeight
                asideHeight = aside.offsetHeight
            })
            // main function
            document.addEventListener(
                'scroll',
                () => {
                    screenHeight = window.innerHeight
                    asideHeight = aside.offsetHeight

                    endScroll = window.innerHeight - aside.offsetHeight
                    const asideTop = parseInt(aside.style.top.replace('px;', ''))
                    if (asideHeight > screenHeight) {
                        if (window.scrollY < currPos) {
                            // scroll up
                            if (asideTop < startScroll) {
                                aside.style.top = `${asideTop + currPos - window.scrollY}px`
                            } else if (asideTop >= startScroll && asideTop != startScroll) {
                                aside.style.top = `${startScroll}px`
                            }
                        } else {
                            // scroll down
                            if (asideTop > endScroll) {
                                aside.style.top = `${asideTop + currPos - window.scrollY}px`
                            } else if (asideTop < endScroll && asideTop != endScroll) {
                                aside.style.top = `${endScroll}px`
                            }
                        }
                    } else {
                        aside.style.top = `${startScroll}px`
                    }
                    currPos = window.scrollY
                },
                {
                    capture: true,
                    passive: true,
                }
            )
        }
    }, [validationBanner])
    if (single) {
        return (
            <div
                style={
                    isMobile
                        ? {}
                        : {
                              minHeight: '100%',
                              display: 'grid',
                              gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
                              columnGap: 24,
                              maxWidth: 968,
                              marginLeft: 'auto',
                              marginRight: 'auto',
                          }
                }
            >
                <div style={{ position: 'relative', maxWidth: isMobile ? 'inherit' : 614, justifySelf: 'center', width: '100%' }}>{left}</div>
            </div>
        )
    }
    return (
        <div
            style={
                isMobile
                    ? {}
                    : {
                          minHeight: '100%',
                          display: 'grid',
                          gridTemplateColumns: right ? 'repeat(1, minmax(0, 1fr)) 328px' : 'repeat(1, minmax(0, 1fr))',
                          columnGap: 24,
                          maxWidth: 968,
                          marginLeft: 'auto',
                          marginRight: 'auto',
                      }
            }
        >
            <div style={{ position: 'relative' }}>{left}</div>
            {right && !isMobile && (
                <div>
                    <div style={{ position: 'sticky', top: validationBanner ? 112 : isSafari ? 72 : 72 }} ref={refInner}>
                        <div style={{ paddingBottom: 24 }}>{right}</div>
                    </div>
                </div>
            )}
        </div>
    )
}
