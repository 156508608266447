// Modules
import React, { useState } from 'react'
import { Button, Form, Input, Modal, Row } from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { setCurrentLanguage } from '@state/reducers/UserInterface/UserInterfaceReducer'
import strings from '@resources/localization'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { RootReducer } from '@state/reducers/Root'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { authUpdatePassword } from '@state/actions'
import { InformationModal } from './information_modal/InformationModal'
import { useRequestState } from '@api/requests/AsyncRequests'
import { FooterModal } from './components/FooterModal'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'

export const PasswordChangeModal = () => {
    const passwordModal = useAppSelector((state) => state.modal.password)

    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    const closePasswordModal = () => {
        dispatch(modalAction({ modal: 'password', state: false }))
    }

    const changeComplete = (res) => {
        closePasswordModal()
        setInfoModal(true)
    }

    const [infoModal, setInfoModal] = useState(false)
    const [state, setState] = useRequestState(undefined, false, false)

    const onSubmit = (values) => {
        dispatch(
            authUpdatePassword({
                bodyParams: {
                    passwordNew: values.passwordNew,
                    passwordOld: values.passwordOld,
                },
                options: {
                    redux: {
                        stateHandler: setState,
                    },
                    then: {
                        action: changeComplete,
                    },
                },
            })
        )
    }

    return (
        <>
            <InformationModal
                kind={'success'}
                open={infoModal}
                onClick={() => setInfoModal(false)}
                title={strings.screens.settings.sections.signup.changePassword.success.title}
                description={strings.screens.settings.sections.signup.changePassword.success.description}
            />
            <TuringoModal
                width={572}
                title={strings.screens.settings.sections.signup.changePassword.modal.title}
                open={passwordModal}
                onCancel={closePasswordModal}
                footer={[
                    <FooterModal
                        key={'footer_modal'}
                        primary={{
                            action: () => {
                                form.validateFields()
                                    .then((values) => {
                                        form.resetFields()
                                        onSubmit(values)
                                    })
                                    .catch((info) => {})
                            },
                            customText: strings.screens.settings.sections.signup.changePassword.modal.actions.accept,
                            loading: state.editing,
                        }}
                        secondary={{
                            action: closePasswordModal,
                            customText: strings.screens.settings.sections.signup.changePassword.modal.actions.cancel,
                        }}
                    />,
                ]}
                okText={strings.screens.settings.sections.signup.changePassword.modal.actions.accept}
                cancelText={strings.screens.settings.sections.signup.changePassword.modal.actions.cancel}
            >
                <p>{strings.screens.settings.sections.signup.changePassword.modal.description} </p>
                <Form form={form} layout={'vertical'}>
                    <Form.Item
                        name="passwordOld"
                        label={strings.screens.settings.sections.signup.changePassword.modal.oldPassword.label}
                        rules={[{ required: true, message: strings.forms.password.validations.required }]}
                    >
                        <Input.Password
                            type="password"
                            placeholder={strings.screens.settings.sections.signup.changePassword.modal.oldPassword.placeholder}
                            autoComplete="off"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="passwordNew"
                        label={strings.screens.settings.sections.signup.changePassword.modal.newPassword.label}
                        rules={[
                            { required: true, message: strings.forms.password.validations.required },
                            { min: 5, message: strings.screens.settings.sections.signup.changePassword.error.insufficientChar },
                        ]}
                    >
                        <Input.Password
                            type="password"
                            placeholder={strings.screens.settings.sections.signup.changePassword.modal.newPassword.placeholder}
                            autoComplete="off"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                </Form>
            </TuringoModal>
        </>
    )
}
