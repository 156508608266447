import style from './Messages.scss'
import useBreakpoints from '@hooks/useBreakpoints'

import { RootReducer } from '@state/reducers/Root'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { Badge, Button, Popover } from 'antd'
import { MessageFilled, MessageOutlined } from '@ant-design/icons'
import { markNotificationsAsSeen } from '@state/actions'
import { useHistory, useLocation } from 'react-router'
import { Router } from '@Router'
import { Plus } from '@icon-park/react'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { MessagesPanel } from './MessagesPanel'
import { ChatListSkeleton } from '@components/skeletons/ChatSkeleton/components/ChatListSkeleton'
import strings from '@resources/localization'
import { useState } from 'react'

export const MessagesList = (): JSX.Element => {
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)

    const { general } = useAppSelector((state) => state.notification)

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const dispatch = useAppDispatch()
    const history = useHistory()
    const location = useLocation<{ roomPk: string }>()
    const roomSelected = location.state?.roomPk
    const isChatScreen = location.pathname.includes('/messages')

    const [visibleMessages, setVisibleMessages] = useState(false)

    const newChat = () => {
        dispatch(modalAction({ modal: 'chat', state: true }))
        setVisibleMessages(false)
    }

    /* Action when the notification menu is clicked, mark all notification as seen, and open the menu, 
       if is mobile, redirect to notification center  */

    const handleMenuClick = (open: boolean) => {
        if (!isChatScreen && !isMobile) {
            setVisibleMessages(open)
        } else if (isMobile) {
            history.push({ pathname: Router.Path.community_chat({ cm_pk: currentCommunity }), state: { roomPk: roomSelected } })
        }
    }
    return (
        <Popover
            style={{ padding: '0px 12px' }}
            content={
                visibleMessages ? (
                    <MessagesPanel onClose={() => setVisibleMessages(false)} />
                ) : (
                    <div style={{ width: 424, padding: 16, paddingBottom: 32 }}>
                        <ChatListSkeleton nElements={2} />
                    </div>
                )
            }
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: 16 }}>{strings.screens.chat.title}</div>{' '}
                    <Button
                        onClick={newChat}
                        type="default"
                        icon={<Plus theme="outline" size="14" style={{ display: 'flex', justifyContent: 'center' }} />}
                        style={{ height: 38, width: 38 }}
                    />
                </div>
            }
            trigger="click"
            placement="bottomRight"
            open={visibleMessages}
            onOpenChange={handleMenuClick}
            arrowPointAtCenter
            getPopupContainer={(trigger) => trigger.parentElement}
        >
            <Badge count={general.aggregate[currentCommunity]?.messages?.newly} color={'var(--notification-dot)'} offset={[-14, 14]}>
                <div className={visibleMessages || isChatScreen ? style.iconActive : style.icon}>
                    {!visibleMessages && !isChatScreen && <MessageOutlined style={{ fontSize: 24 }} />}
                    {(visibleMessages || isChatScreen) && <MessageFilled style={{ fontSize: 24 }} />}
                </div>
            </Badge>
        </Popover>
    )
}
