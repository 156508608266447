// Libraries

// React
import React from 'react'

// Antd
import { Divider } from 'antd'
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useHistory } from 'react-router'
import { Router } from '@Router'
import { LanguageSelector } from '../LanguageSelector'
import strings from '@resources/localization'

// MARK: - Definition

namespace LanguageScreen {
    export interface Props { }
}

// MARK: - Implementation

const LanguageScreen: React.FC<LanguageScreen.Props> = (props: LanguageScreen.Props) => {
    const history = useHistory()

    return (
        <div className="lateral-padding" style={{ paddingBottom: 16 }}>
            <div style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
                <GoBackButton
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}
                    type="default"
                    showText={false}
                    onClick={() => history.push(Router.Path.settings({}))}
                />
                <Paragraph strong className="turingo-text">
                    {strings.screens.settings.sections.language.title}
                </Paragraph>
            </div>
            <Divider></Divider>
            <Paragraph style={{ marginBottom: 16 }} >
                {strings.screens.settings.sections.language.description}
            </Paragraph>
            <LanguageSelector />
        </div>
    )
}

export { LanguageScreen }
