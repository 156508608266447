// Libraries

// React
import React, { useContext } from 'react'
import { CreatePostContext } from '../../PostCreateProvider'
import Paragraph from 'antd/lib/typography/Paragraph'
import strings from '@resources/localization'
import { Form } from 'antd'
import { FormSelect } from '@components/form/FormSelectValue/FormSelectModalItem'

// MARK: - Definition

namespace SpaceSelectionScreen {
    export interface Props {}
}

// MARK: - Implementation

const SpaceSelectionScreen: React.FC<SpaceSelectionScreen.Props> = (props: SpaceSelectionScreen.Props) => {
    const { boards, screen, setScreen, form } = useContext(CreatePostContext)
    const board = Form.useWatch(['form','turingo', 'board'], form)

    return (
        <div>
            <Paragraph style={{ marginBottom: 16 }} className="turingo-text">
                {strings.screens.boards.discussion.post.spaces.description}
            </Paragraph>
            <FormSelect
                defaultValue={board}
                items={boards}
                valueField="publicKey"
                nameField="name"
                afterAction={(board) => {
                    form.setFieldValue(['form','turingo', 'topics'], [])
                    form.setFieldValue(['form','turingo', 'board'], board)
                    if (screen == 'board_selection') setScreen('creation')
                }}
            />
        </div>
    )
}

export { SpaceSelectionScreen }
