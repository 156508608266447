import React, { useState } from 'react'
import { Image } from 'antd'
import { Attachment } from '@api/model/responses/post/PostView'
import useBreakpoints from '@hooks/useBreakpoints'
import { Colors } from '@util/color/Colors'
import { addWebp } from '@util/browser/addWebp'

interface Props {
    attachments: Array<Attachment>
}

export const ImagePost = (props: Props) => {
    const { attachments } = props

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const imageWidth = isMobile ? 320 : 800

    const gallery: {
        [length: number]: {
            [indexedDB: number]: {
                ratio: string
                grid: string
            }
        }
    } = {
        [1]: {
            [0]: { ratio: '100%', grid: '1 / 7' },
        },
        [2]: {
            [0]: { ratio: '100%', grid: '1 / 4' },
            [1]: { ratio: '100%', grid: '4 / 7' },
        },
        [3]: {
            [0]: { ratio: '66.6%', grid: '1 / 7' },
            [1]: { ratio: '100%', grid: '1 / 4' },
            [2]: { ratio: '100%', grid: '4 / 7' },
        },
        [4]: {
            [0]: { ratio: '100%', grid: '1 / 4' },
            [1]: { ratio: '100%', grid: '4 / 7' },
            [2]: { ratio: '100%', grid: '1 / 4' },
            [3]: { ratio: '100%', grid: '4 / 7' },
        },
        [5]: {
            [0]: { ratio: '100%', grid: '1 / 4' },
            [1]: { ratio: '100%', grid: '4 / 7' },
            [2]: { ratio: '100%', grid: '1 / 3' },
            [3]: { ratio: '100%', grid: '3 / 5' },
            [4]: { ratio: '100%', grid: '5 / 7' },
        },
    }
    return (
        <div
            style={{
                width: '100%',
                position: 'relative',
                display: attachments.length == 1 ? 'flex' : 'grid',
                backgroundColor: attachments.length == 1 ? 'var(--image-background)' : 'transparent',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                columnGap: 8,
                rowGap: 8,
                marginBottom: 8,
            }}
        >
            <Image.PreviewGroup>
                {attachments.map((file, index) => {
                    if (attachments.length == 1) {
                        return (
                            <div style={{ position: 'relative', width: '100%', height: 616 }}>
                                <Image
                                    preview={{
                                        mask: '',
                                    }}
                                    style={{ display: 'none' }}
                                    width={'100%'}
                                    height={616}
                                    wrapperStyle={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        overflow: 'hidden',
                                    }}
                                    src={addWebp(file.url, imageWidth)}
                                />

                                <div

                                    key={index}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundImage: `url(${addWebp(file.url, imageWidth)})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: `contain`,
                                        backgroundPositionX: `center`,
                                        backgroundPositionY: `center`,
                                        width: '100%',
                                        height: 616,
                                    }}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <Image
                                key={index}
                                preview={{
                                    mask: '',
                                }}
                                width={'100%'}
                                wrapperStyle={{
                                    position: 'relative',
                                    paddingTop: gallery[attachments.length][index].ratio,
                                    gridColumn: gallery[attachments.length][index].grid,
                                    overflow: 'hidden',
                                }}
                                src={addWebp(file.url, imageWidth)}
                                placeholder={
                                    <div
                                        style={{
                                            width: '100%',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            objectFit: 'cover',
                                            objectPosition: 'center center',
                                            height: '100%',
                                            cursor: 'pointer',
                                            background: Colors('SKELETON'),
                                        }}
                                    />
                                }
                            />
                        )
                        {
                            /* <div
                                key={index}
                                style={{
                                    position: 'relative',
                                    backgroundColor: 'black',
                                    gridColumn: gallery[attachments.length][index].grid,
                                    overflow: 'hidden',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${addWebp(file.url, imageWidth)})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: `contain`,
                                        backgroundPositionX: `center`,
                                        backgroundPositionY: `center`,
                                        width: '100%',
                                        height: 256,
                                    }}
                                />
                            </div> */
                        }
                    }
                })}
            </Image.PreviewGroup>
        </div>
    )
}
