// Libraries

// React
import React, { CSSProperties, useContext, useEffect, useState } from 'react'

// Antd
import { Alert, Button, Progress, Spin } from 'antd'
import { Colors } from '@util/color/Colors'
import SVG from '@components/assets/SVG'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

// Images
import ImagePlayButton from '@bundle/assets/media/video/icon-play-round-white.svg'
import useBreakpoints from '@hooks/useBreakpoints'
import { UploadArea, UploadFile } from './TuringoUpload'
import { FileComponent } from './FileComponent'
import Paragraph from 'antd/lib/typography/Paragraph'
import { SocketContext } from 'src/SocketComponent'
import { NotificationHistoryView } from '@api/model/requests/Notification'
import strings from '@resources/localization'
// MARK: - Definition
export type ObjectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
type UploadType = 'sync' | 'async'

namespace ListItem {
    export interface Props {
        uploadType?: UploadType
        uploadArea: UploadArea
        showAdd: boolean
        index: number
        maxFiles: number
        file: UploadFile
        items: UploadFile[]
        onClose: (file: UploadFile) => void
        filetype?: keyof typeof FILE_SUPPORT
        isEditing?: boolean
        fileStyle?: CSSProperties
        cancelUpload?: () => void
        iconFitMode?: 'topright' | 'centered'
    }
}

// MARK: - Implementation

const ListItem: React.FC<ListItem.Props> = (props: ListItem.Props) => {
    const {
        file,
        onClose,
        items,
        index,
        maxFiles,
        filetype,
        isEditing,
        fileStyle,
        showAdd,
        uploadArea,
        cancelUpload,
        iconFitMode = 'topright',
        uploadType = 'sync',
    } = props

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const [progress, setProgress] = useState({})
    const [complete, setComplete] = useState({})

    const gallery = {
        [1]: {
            [0]: '1 / 7',
        },
        [2]: {
            [0]: '1 / 4',
            [1]: '4 / 7',
        },
        [3]: {
            [0]: '1 / 7',
            [1]: '1 / 4',
            [2]: '4 / 7',
        },
        [4]: {
            [0]: '1 / 4',
            [1]: '4 / 7',
            [2]: '1 / 4',
            [3]: '4 / 7',
        },
        [5]: {
            [0]: '1 / 4',
            [1]: '4 / 7',
            [2]: '1 / 3',
            [3]: '3 / 5',
            [4]: '5 / 7',
        },
    }
    const galleryAdd = {
        [1]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
        },
        [2]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 7',
        },
        [3]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 4',
            [2]: '4 / 7',
        },
        [4]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 3',
            [2]: '3 / 5',
            [3]: '5 / 7',
        },
    }

    const socket = useContext(SocketContext)
    /* useEffects */

    useEffect(() => {
        const lastMessage = socket.lastJsonMessage as NotificationHistoryView.SocketMessage
        if (lastMessage?.channel?.id == 'asset.convert') {
            const message = socket.lastJsonMessage as NotificationHistoryView.Conversion
            if (message?.body?.progress) {
                setProgress({ ...progress, [message.body.conversionPk]: message.body.progress })
                if (message?.body?.state == 'complete') {
                    setComplete({ ...complete, [message.body.conversionPk]: true })
                }
            }
        }
    }, [socket.lastJsonMessage])

    useEffect(() => {
        if (file?.conversion?.publicKey) {
            socket.sendJsonMessage({
                channel: 'asset.convert',
                body: {
                    conversionPk: file.conversion.publicKey,
                },
            })
        }
    }, [file])

    const url = filetype == 'video' && !file.preview ? window.URL.createObjectURL(file.file) : filetype == 'video' && file.preview ? file.preview : undefined
    const uploadSuccess = uploadType == 'async' && file.status == 'success' && file.conversion?.publicKey && complete[file.conversion?.publicKey]
    return (
        <div
            style={{
                position: 'relative',
                ...(items && {
                    gridColumn:
                        filetype == 'attachment'
                            ? '1 / 7'
                            : showAdd
                            ? galleryAdd[items && items.length > maxFiles ? maxFiles : items && items.length][index]
                            : gallery[items && items.length > maxFiles ? maxFiles : items && items.length][index],
                }),

                ...(items &&
                    items.length == 1 &&
                    !showAdd && {
                        display: filetype != 'image' ? 'block' : 'flex',
                        alignItems: uploadArea?.btnShape == 'circle' ? 'center' : 'inherit',
                        /*                     backgroundColor: !fileStyle?.borderRadius && filetype != 'attachment' && 'var(--image-background)',
                         */ width: fileStyle?.width || '100%',
                        justifyContent: 'center',
                    }),
            }}
        >
            {filetype != 'attachment' &&
                !isEditing &&
                (!file.status || file.status == 'success') &&
                (!file.conversion || complete[file.conversion?.publicKey]) && (
                    <>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                position: 'absolute',
                                zIndex: 1,
                                background: 'rgba(0,0,0,0.4)',
                                ...fileStyle,
                            }}
                        ></div>
                        <div
                            style={{
                                position: 'absolute',
                                ...(uploadArea?.btnShape == 'circle' && { marginRight: 'auto', marginLeft: 'auto' }),
                                ...(uploadArea?.btnShape != 'circle' && { right: 8, top: 8, display: 'flex', alignItems: 'center' }),
                            }}
                        >
                            <Button
                                onClick={() => onClose(file)}
                                shape={uploadArea?.btnShape != 'circle' && !isMobile ? 'default' : 'circle'}
                                icon={<DeleteOutlined style={{ display: 'flex', justifyContent: 'center' }} />}
                                style={{
                                    zIndex: 2,
                                    background: Colors('OPTION_POLL_BACKGROUND'),
                                    ...(uploadArea?.btnShape != 'circle' && !isMobile && { display: 'flex', alignItems: 'center' }),
                                }}
                            >
                                {uploadArea?.btnShape != 'circle' && !isMobile && strings.general.delete}
                            </Button>
                        </div>
                    </>
                )}

            {filetype == 'image' && (items.length > 1 || showAdd) && (
                <div style={{ position: 'relative' }}>
                    {file.status == 'uploading' && (
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0,0,0,0.6)',
                            }}
                        >
                            <Progress className="turingo" type="circle" percent={file.percent} />
                        </div>
                    )}
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                            objectPosition: 'center center',
                            ...fileStyle,
                            height: fileStyle?.height || 256,
                            objectFit: fileStyle?.objectFit || 'cover',
                            borderRadius: 4,
                        }}
                        src={file.preview}
                    />
                </div>
            )}
            {filetype == 'image' && items.length == 1 && !showAdd && (
                <>
                    {(uploadType == 'sync' || (file.status == 'success' && !uploadSuccess) || !file.file) && (
                        <div style={{ position: 'relative' }}>
                            <img
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                    borderRadius: 4,
                                    ...fileStyle,
                                    height: fileStyle?.height || '100%',
                                }}
                                src={file.preview}
                            />
                        </div>
                    )}

                    {uploadType == 'async' && file.status == 'uploading' && (
                        <div
                            style={{
                                padding: 16,
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: 'var(--neutral-3)',
                                borderRadius: 4,
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 175,
                            }}
                        >
                            <Paragraph style={{ marginBottom: 16 }}>{`${strings.general.processing} ${file.percent}%`} </Paragraph>
                            <Progress
                                showInfo={false}
                                type="line"
                                percent={file.percent}
                                strokeWidth={6}
                                success={{ strokeColor: 'var(--ant-primary-color)' }}
                                strokeColor={{ from: 'var(--ant-primary-color)', to: 'var(--ant-primary-color)' }}
                            />

                            <Button onClick={cancelUpload}>{strings.general.cancel}</Button>
                        </div>
                    )}
                </>

                /*  <div
                     style={{
                         backgroundImage: `url(${file.preview || file.url})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: `contain`,
                         backgroundPositionX: `center`,
                         backgroundPositionY: `center`,
                         width: '100%',
                         ...fileStyle,
                         height: fileStyle?.height || 256,
                     }}
                 /> */
            )}

            {filetype == 'video' && (
                <>
                    {(uploadType == 'sync' || uploadSuccess) && (
                        <div>
                            <div
                                style={{ width: '100%', height: '100%', top: 0, left: 0, position: 'absolute', zIndex: 1, background: 'rgba(0,0,0,0.4)' }}
                            ></div>
                            <SVG
                                style={{
                                    width: '56px',
                                    height: '56px',
                                    top: 'calc(50% - 28px)',
                                    left: 'calc(50% - 28px)',
                                    position: 'absolute',
                                    zIndex: 1,
                                }}
                                content={ImagePlayButton}
                            ></SVG>
                            <div style={{ position: 'relative', ...(!isMobile && { maxHeight: 572, overflow: 'hidden' }) }}>
                                <video
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectPosition: 'center center',
                                        borderRadius: 4,
                                    }}
                                    src={url}
                                />
                            </div>
                        </div>
                    )}

                    {uploadType == 'async' && file.status == 'uploading' && !uploadSuccess && (
                        <>
                            <div
                                style={{
                                    padding: 16,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    justifyContent: 'center',
                                    backgroundColor: 'var(--neutral-3)',
                                    borderRadius: 4,

                                    alignItems: 'center',
                                    height: 345,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: '100%',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Paragraph style={{ marginBottom: 16 }}>
                                        {strings.general.uploading} {file.percent}%
                                    </Paragraph>

                                    <Progress
                                        showInfo={false}
                                        type="line"
                                        percent={file.percent || 0}
                                        strokeWidth={6}
                                        success={{ strokeColor: 'var(--ant-primary-color)' }}
                                        strokeColor={{ from: 'var(--ant-primary-color)', to: 'var(--ant-primary-color)' }}
                                    />
                                    <Button onClick={cancelUpload}>{strings.general.cancel}</Button>
                                </div>
                            </div>
                        </>
                    )}

                    {uploadType == 'async' && file.status == 'success' && !uploadSuccess && (
                        <>
                            <div
                                style={{
                                    padding: 16,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    justifyContent: 'center',
                                    backgroundColor: 'var(--neutral-3)',
                                    borderRadius: 4,

                                    alignItems: 'center',
                                    height: 345,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: '100%',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Paragraph style={{ marginBottom: 16 }}>
                                        {strings.general.processing} {progress[file.conversion?.publicKey]}%
                                    </Paragraph>

                                    <Progress
                                        showInfo={false}
                                        type="line"
                                        percent={progress[file.conversion?.publicKey] || 0}
                                        strokeWidth={6}
                                        success={{ strokeColor: 'var(--ant-primary-color)' }}
                                        strokeColor={{ from: 'var(--ant-primary-color)', to: 'var(--ant-primary-color)' }}
                                    />
                                </div>

                                <Alert style={{ width: '100%' }} showIcon message={strings.general.videoProcessing} />
                            </div>
                        </>
                    )}
                </>
            )}
            {filetype == 'attachment' && <FileComponent isEditing={isEditing} key={`attachment#${index}`} file={file} onRemove={() => onClose(file)} />}
        </div>
    )
}

export { ListItem }
