// Libraries

// React
import React, { ReactNode, createContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { BoardView } from '@api/model/responses/board/BoardView'
import { boardDiscussion, postCreate, postEdit } from '@state/actions'
import { useParams } from 'react-router'
import { PostRouteParams } from '@typings/routing/Router'
import { FormInstance, useForm } from 'antd/lib/form/Form'
import { Form } from 'antd'
import { PostKind, PostView } from '@api/model/responses/post/PostView'
import { MentionEditorView } from '@components/form/Editor/TuringoEditor'
import strings from '@resources/localization'
import { PollCreationModel, PostCreationModel } from '@api/model/requests/Post'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { unlockScroll } from '@hooks/useBlockScroll'
import { FooterModal } from '@components/modals/components/FooterModal'
import { Scope } from '@api/model/requests/Upload'
import { MainScreen } from './screens/MainScreen'
import { EventView } from '@api/model/responses/event/EventView'
import { AlanStateInterface } from './screens/alan/components/AlanEditor'
import { CrossPostingConsumer } from './screens/creation/CreationScreen'
import { UploadFile } from '@components/form/TuringoUpload/TuringoUpload'
import { UserView } from '@api/model/responses/user/UserView'

// MARK: - Definition
export type PostModalStep = 'creation' | 'topic_selection' | 'topic_final' | 'poll_creation' | 'edit' | 'board_selection' | 'board_final' | 'alan'

namespace PostCreateProvider {
    export interface Props {
        open: boolean
        setVisible: (value: boolean) => void
        item?: Partial<PostView>
        editable?: boolean
        defaultMask?: UserView
        initialScreen?: PostModalStep
        children?: ReactNode
    }
}
interface PostForm {
    form: {
        [key in CrossPostingConsumer]: {
            active: boolean
            error: boolean
            content: string
            board: string
            topics: string[]
            kind: PostKind
            attachments: UploadFile[]
            mentions: MentionEditorView[]
            links: string[]
            event: EventView
            poll: {
                question: string
                choices: {
                    choiceId: number
                    answer: string
                }[]
                dateFinish: PollCreationModel.DateKind
            }
            alan: string
        }
    }
}
// MARK: - Implementation
export const CreatePostContext = createContext<{
    mask: {
        get: UserView
        set: (any: UserView) => void
    }
    boards: BoardView[]
    editable: boolean
    screen: PostModalStep
    setScreen: (value: PostModalStep) => void
    form: FormInstance<PostForm>
    pollForm: FormInstance
    alanState: {
        get: AlanStateInterface
        set: React.Dispatch<React.SetStateAction<AlanStateInterface>>
    }
    item?: Partial<PostView>
    changeText: {
        get: boolean
        set: (any: boolean) => void
    }
    changeAttachments: {
        get: boolean
        set: (any: boolean) => void
    }
}>(null)

const PostCreateProvider: React.FC<PostCreateProvider.Props> = (props: PostCreateProvider.Props) => {
    const { initialScreen = 'creation', item, editable, open, setVisible, defaultMask } = props
    const [changeText, setChangeText] = useState(false)
    const [changeAttachments, setChangeAttachments] = useState(false)

    const [boards, setBoards] = useState<{ data: BoardView[]; loading: boolean; editing: boolean }>({ data: undefined, loading: false, editing: false })
    const { cm_pk, b_pk, p_pk } = useParams<PostRouteParams>()
    const scope = p_pk ? 'detail' : 'all'
    const [screen, setScreen] = useState<PostModalStep>(initialScreen)
    const [form] = useForm<PostForm>()
    const [pollForm] = useForm()

    const boardDetail = useAppSelector((state) => state.community.items[cm_pk]?.boards?.detail?.[b_pk])

    const board = Form.useWatch(['form', 'turingo', 'board'], form)
    const alanText = Form.useWatch(['form', 'turingo', 'alan'], form)

    const [alanState, setAlanState] = useState<AlanStateInterface>({ state: 'inactive', times: 0 })
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)
    const [currentMask, setCurrentMask] = useState(defaultMask ?? currentEntity)
    const alan = {
        get: alanState,
        set: setAlanState,
    }

    const topics = Form.useWatch(['form', 'turingo', 'topics'], form)
    const board_item = boards?.data?.find((value) => value.publicKey == board)
    const isFeed = !b_pk

    const isLoading = useAppSelector((state) => state.posts.all[isFeed ? 'feed' : b_pk]?.createState)?.loading
    const isEditing = editable ? useAppSelector((state) => state.posts[scope][isFeed ? 'feed' : b_pk].items[item?.publicKey]?.state?.editing) : undefined

    const closeModal = () => {
        unlockScroll()
        setVisible(false)
    }

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isFeed) {
            if (item?.event?.invitation?.kind == 'boards' && !editable) {
                setBoards({ data: item.event?.invitation?.boards, loading: false, editing: false })
            } else {
                void dispatch(
                    boardDiscussion({
                        urlParams: { cm_pk },
                        searchParams: { kind: 'discussion' },
                        options: {
                            redux: {
                                retrieveData: true,
                                overrideData: true,
                                stateHandler: setBoards,
                            },
                        },
                    })
                )
            }
        } else {
            setBoards({ data: [boardDetail?.item], loading: false, editing: false })
        }
    }, [])

    const getTitle = () => {
        if (!editable) {
            if (screen == 'creation' || screen == 'alan') return strings.screens.boards.discussion.post.create.title
        } else {
            if (screen == 'creation' || screen == 'alan') return strings.screens.boards.discussion.post.edit.title
        }

        if (screen == 'board_selection' || screen == 'board_final') return strings.screens.boards.discussion.post.spaces.title
        if (screen == 'topic_selection' || screen == 'topic_final') return strings.screens.boards.discussion.post.topic.title
        if (screen == 'poll_creation') return strings.screens.boards.discussion.post.kind.poll.form.title
    }

    const getFooter = () => {
        if (screen == 'creation') return

        const getBackAction = () => setScreen('creation')
        const primaryAction =
            screen === 'board_final'
                ? (!topics || topics.length === 0) && board_item?.topics.length > 0
                    ? () => setScreen('topic_final')
                    : form.submit
                : screen === 'topic_final'
                ? form.submit
                : screen === 'poll_creation'
                ? () => {
                      pollForm.submit()
                  }
                : screen === 'alan'
                ? () => {
                      form.setFieldValue(['form', 'turingo', 'content'], alanText)
                      form.setFieldValue(['form', 'turingo', 'alan'], '')
                      setAlanState((prev) => {
                          return { ...prev, state: 'inactive' }
                      })
                      setScreen('creation')
                  }
                : undefined

        return (
            <FooterModal
                key={'footer_modal'}
                back={{ action: getBackAction, kind: 'back' }}
                primary={{
                    action: primaryAction,
                    loading: editable ? isEditing : isLoading,
                    disabled: (screen == 'board_final' && !board) || (screen == 'alan' && (!alanText || alanState.state != 'stop')),
                    customText:
                        screen === 'poll_creation'
                            ? strings.general.continue
                            : screen === 'alan'
                            ? strings.screens.boards.discussion.post.alan.action
                            : editable
                            ? strings.general.save
                            : strings.general.topost,
                }}
            />
        )
    }
    const submit = (values: PostForm) => {
        const { content, links, mentions, kind, attachments, topics, board, poll } = values.form.turingo
        const creation: PostCreationModel.CreationAttributes = {
            ...(currentEntity.publicKey != currentMask.publicKey && { mask: currentMask.publicKey }),
            kind: (kind == 'image' || kind == 'video' || kind == 'attachment') && attachments.length == 0 ? 'basic' : kind,
            content: content.trim(),
            topics: topics.length > 0 ? topics : undefined,
            mentions: mentions.length > 0 ? mentions : undefined,
            poll: kind == 'poll' && !editable ? poll : undefined,
            links: links,
            eventPk: kind == 'event' && !editable ? item?.event?.publicKey : undefined,
            alan: {
                voice: alan.get.voice,
                uses: alan.get.times,
            },
            /*  attachments: attachments.map((a, order) => {
                 return { intentToken: a.token, order }
             }), */
        }

        const uploads = editable
            ? []
            : attachments?.slice(0, 5).map((file) => ({
                  scope: 'attachment' as Scope,
                  item: file.file,
                  name: file.file.name,
                  size: file.file.size,
              }))
        if (!board) return
        if (!editable) {
            void dispatch(
                postCreate({
                    urlParams: { cm_pk, b_pk: board },
                    bodyParams: creation,
                    extraParams: { feed: isFeed },
                    ...(uploads?.length > 0 && {
                        uploads: {
                            attachments: uploads,
                        },
                    }),

                    options: {
                        then: {
                            action: closeModal,
                        },
                        alerts: {
                            success: strings.alerts.post.create.success,
                            error: strings.alerts.post.create.failure,
                        },
                    },
                })
            )
        } else {
            void dispatch(
                postEdit({
                    urlParams: { cm_pk, b_pk: item.boardPk, p_pk: item.publicKey },
                    bodyParams: creation,
                    extraParams: { feed: isFeed, scope: scope },
                    options: {
                        then: {
                            action: closeModal,
                        },
                        alerts: {
                            success: strings.alerts.post.edit.success,
                            error: strings.alerts.post.edit.failure,
                        },
                    },
                })
            )
        }
    }

    if (!boards.data || boards.loading || boards.editing) return <></>
    return (
        <CreatePostContext.Provider
            value={{
                boards: boards.data,
                screen,
                setScreen,
                form,
                alanState: alan,
                pollForm,
                editable,
                item: item,
                changeText: { get: changeText, set: setChangeText },
                changeAttachments: { get: changeAttachments, set: setChangeAttachments },
                mask: {
                    get: currentMask,
                    set: setCurrentMask,
                },
            }}
        >
            <TuringoModal
                scrolleable={screen != 'creation'}
                fullScreenMobile
                width={600}
                title={getTitle()}
                open={open}
                confirmLoading={isLoading}
                onCancel={closeModal}
                maskClosable={false}
                footer={getFooter()}
            >
                <Form
                    layout="vertical"
                    onFinish={submit}
                    style={{ height: '100%' }}
                    form={form}
                    initialValues={{
                        form: {
                            turingo: {
                                content: item?.content || '',
                                kind: item?.kind || 'basic',
                                mentions: item?.mentions || [],
                                attachments:
                                    item?.assets?.attachments?.map((file) => {
                                        return {
                                            file: {
                                                name: file.name,
                                                type: file.mimeType,
                                                size: file.size,
                                            },
                                            status: 'success',
                                            percent: 100,
                                            preview: file.url,
                                        }
                                    }) || [],
                                topics: item?.topics?.map((topic) => topic.publicKey) || [],
                                board:
                                    item?.boardPk ||
                                    (boards?.data.length == 1 && boards?.data[0].publicKey) ||
                                    (item?.event?.invitation?.boards?.length == 1 && item?.event?.invitation?.boards[0].publicKey) ||
                                    undefined,
                                poll: {
                                    question: item?.poll ? item?.poll.question : '',
                                    choices: item?.poll ? item?.poll.options : ['', ''],
                                    dateFinish: item?.poll ? item?.poll.dateFinish : 'week',
                                },
                                event: item?.event || undefined,
                            },
                            linkedin: {
                                content: '',
                                kind: 'basic',
                                mentions: [],
                                attachments: [],
                            },
                            X: {
                                content: '',
                                kind: 'basic',
                                mentions: [],
                                attachments: [],
                            },
                        },
                    }}
                >
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'alan']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'event']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'board']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'topics']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'mentions']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'content']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'links']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'poll']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'attachments']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'kind']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'turingo', 'error']} />

                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'active']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'error']} />

                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'alan']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'event']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'board']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'topics']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'mentions']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'content']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'links']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'poll']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'attachments']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'linkedin', 'kind']} />

                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'active']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'error']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'alan']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'event']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'board']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'topics']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'mentions']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'content']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'links']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'poll']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'attachments']} />
                    <Form.Item style={{ display: 'none' }} name={['form', 'X', 'kind']} />

                    {props.children}
                </Form>
            </TuringoModal>
        </CreatePostContext.Provider>
    )
}

const PostModal = (props: PostCreateProvider.Props) => {
    const { open, setVisible, item, editable, initialScreen, defaultMask } = props

    return (
        <PostCreateProvider defaultMask={defaultMask} item={item} editable={editable} initialScreen={initialScreen} open={open} setVisible={setVisible}>
            <MainScreen></MainScreen>
        </PostCreateProvider>
    )
}

export { PostModal }
