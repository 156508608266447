import { PostCreationModel } from '@api/model/requests/Post'
import strings from '@resources/localization'
import { Router } from '@Router'
import { Colors } from '@util/color/Colors'
import { createNodes, insert } from '@util/string/Functions'
import Paragraph from 'antd/lib/typography/Paragraph'
import _ from 'lodash'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import Linkify from 'react-linkify'
import { Link } from 'react-router-dom'
import { emojize } from './Emoji/emoji'
import { useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'

interface Props {
    text: string
    mentions?: PostCreationModel.Mention[]
    style?: CSSProperties
    color?: string
}

export const Content = (props: Props) => {
    const { style, text, mentions, color } = props
    const [expanded, setExpanded] = useState(false)
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)

    const nRows = 7
    const onClose = () => {
        setExpanded(false)
    }
    const onExpand = () => {
        setExpanded(true)
    }

    const componentDecorator = (href: string, text: string, key: number) => (
        <a
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', wordBreak: 'break-word' }}
            className="linkify__text"
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
        >
            {text}
        </a>
    )
    const tokens = mentions && mentions.length > 0 ? createNodes(mentions, text) : null

    const contents = _.map(tokens, (token, i) => {
        if (token.type == 'mention' && currentCommunity) {
            return (
                <Link
                    key={i}
                    to={Router.Path.user_details({ u_pk: token.publicKey, cm_pk: currentCommunity })}
                    style={{ color: 'var(--ant-primary-color)', fontWeight: 500 }}
                >
                    {token.data}
                </Link>
            )
        } else {
            return token.data
        }
    })

    const hasMoreRows = (text.match(/\n/g) || '').length + 1 >= nRows

    const ref = useRef<HTMLDivElement>()

    return (
        <div ref={ref} style={style}>
            <Paragraph
                style={{ whiteSpace: 'pre-wrap', marginBottom: 0, overflowWrap: 'break-word', wordBreak: 'break-word', color: color }}
                ellipsis={!expanded && hasMoreRows ? { rows: nRows } : false}
            >
                <Linkify componentDecorator={componentDecorator}>{tokens ? contents : text /*  emojize(text, true) */} </Linkify>
            </Paragraph>
            {!expanded && hasMoreRows && <a onClick={onExpand}>{strings.general.seeMoreText}</a>}{' '}
            {/*             {expanded && hasMoreRows && <a onClick={onClose}>{strings.general.seeLessText}</a>}
             */}{' '}
        </div>
    )
}
