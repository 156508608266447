import { EntityModel } from '@model/index'
import { useLocation } from 'react-router'

const useEntityKind = () => {
    const location = useLocation()

    let kind: EntityModel.Kind = undefined
    if (location.pathname.split('/')[2] == 'courses') kind = 'course'
    if (location.pathname.split('/')[2] == 'talks') kind = 'forum'
    if (location.pathname.split('/')[2] == 'stories') kind = 'story'

    return kind
}

export default useEntityKind
