// Libraries

// React
import React from 'react'

// Antd
import { RootReducer } from '@state/reducers/Root'
import { useAppSelector } from '@hooks/useStore'
import { useHistory } from 'react-router'
import { Router } from '@Router'
import { AvatarTuringo } from '@components/avatar/Avatar'
import { CommunityView } from '@api/model/responses/community/CommunityView'
import useBreakpoints from '@hooks/useBreakpoints'
import Sider from 'antd/lib/layout/Sider'
import { Badge, Tooltip } from 'antd'
import { ConditionalWrap } from '@components/content/conditional_wrap/ConditionalWrap'

// MARK: - Definition

namespace SidebarCommunities {
    export interface Props {
        communities: CommunityView[]
        kind: 'sidebar' | 'drawer'
        onChange?: () => void
    }
}

// MARK: - Implementation

const SidebarCommunities: React.FC<SidebarCommunities.Props> = (props: SidebarCommunities.Props) => {
    const { onChange, kind, communities } = props
    const history = useHistory()
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)
    const { general } = useAppSelector((state) => state.notification)
    // Communities
    const breakpoints = useBreakpoints()

    const isMobile = breakpoints.isMobile
    const communityCopy = [...(communities || [])]
    const component = communityCopy
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((community) => {
            return (
                <ConditionalWrap
                    key={community.publicKey}
                    condition={!isMobile}
                    wrap={(children) => (
                        <Tooltip zIndex={10} title={community.name} placement="right">
                            {children}
                        </Tooltip>
                    )}
                >
                    <Badge
                        count={general.aggregate[community.publicKey]?.notifications?.newly + general.aggregate[community.publicKey]?.messages?.newly || 0}
                        color={'var(--notification-dot)'}
                    >
                        <AvatarTuringo
                            onClick={() => {
                                if (onChange) onChange()
                                history.push(Router.Path.community_home({ cm_pk: community.publicKey }))
                            }}
                            avatar={community.logoUrl}
                            size={16}
                            width={isMobile ? 44 : 32}
                            height={isMobile ? 44 : 32}
                            isSelected={currentCommunity == community.publicKey}
                            style={{
                                lineHeight: isMobile ? '44px' : '32px',
                                border: currentCommunity == community.publicKey ? '1px solid var(--ant-primary-color)' : '1px solid var(--neutral-1)',
                                cursor: 'pointer',
                            }}
                            color={community.ui?.style?.color}
                            name={community.name}
                            isSidebar
                        ></AvatarTuringo>
                    </Badge>
                </ConditionalWrap>
            )
        })
    if (kind == 'sidebar') {
        return (
            <Sider
                className="community"
                style={{
                    overflow: 'auto',
                    height: 'calc(100dvh - 48px)',
                    backgroundColor: 'var(--sidebar)',
                }}
                width={64}
                collapsedWidth={64}
                trigger={null}
                collapsed={false}
            >
                <div style={{ marginTop: 16, display: 'grid', alignSelf: 'center', gridTemplateColumns: '1fr', gap: 16 }}>{component}</div>
            </Sider>
        )
    } else {
        return (
            <div style={{ backgroundColor: 'var(--sidebar)', display: 'inline', gap: 16, maxWidth: 72, minWidth: 72, width: 72 }}>
                <div
                    style={{
                        display: 'grid',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        paddingTop: 16,
                        gap: 16,
                        paddingBottom: 16,
                        maxHeight: '100dvh',
                        overflow: 'auto',
                    }}
                >
                    {component}
                </div>
            </div>
        )
    }
}

export { SidebarCommunities }
