import useBreakpoints from '@hooks/useBreakpoints'
import { Colors } from '@util/color/Colors'
import { CSSProperties, ReactElement, useState } from 'react'
import { useAppSelector } from '@hooks/useStore'
import { Close } from '@icon-park/react'

interface Props {
    message: ReactElement
    onOk?: ReactElement
    onCancel?: ReactElement
    style?: CSSProperties
    bodyStyle?: CSSProperties
    banner?: boolean
    closable?: boolean
}

export const AlertActions = (props: Props) => {
    const { message, onOk, onCancel, style, bodyStyle, banner, closable = true } = props

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme == 'dark'

    const [visible, setVisible] = useState(true)
    if (!visible) return <></>
    return (
        <div
            style={{
                background: Colors('ALERT_BLUE_BACKGROUND'),
                border: banner ? 'none' : `1px solid ${Colors('ALERT_BLUE_BORDER')}`,
                borderRadius: banner ? 0 : 2,
                padding: '16px 16px 16px 16px',
                position: 'relative',

                ...style,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: isMobile || !banner ? 'start' : 'center',
                    flexDirection: isMobile || !banner ? 'column' : 'row',
                    justifyContent: isMobile || !banner ? 'start' : 'space-between',
                    ...bodyStyle,
                }}
            >
                <div style={{ marginRight: isMobile ? 32 : 0, marginLeft: banner && !isMobile ? 32 : 0 }}>{message}</div>
                <div
                    style={{
                        marginRight: banner && !isMobile ? 32 : 0,
                        width: isMobile || !banner ? '100%' : 'initial',
                        display: !isMobile && !banner ? 'flex' : 'grid',
                        flexWrap: 'nowrap',
                        justifyContent: !isMobile && !banner ? 'center' : 'unset',
                        alignItems: 'center',
                        gap: 16,
                        marginTop: !isMobile && !banner ? 16 : 0,
                    }}
                >
                    {onOk}
                    {onCancel}

                    {closable && (
                        <Close
                            onClick={() => setVisible(false)}
                            style={{
                                cursor: 'pointer',
                                color: isDark ? 'var(--NEUTRAL-9)' : 'rgba(0, 0, 0, 0.85)',
                                fontSize: 14,
                                position: isMobile || banner || !banner ? 'absolute' : 'initial',
                                marginLeft: 12,
                                zIndex: 9,
                                right: 16,
                                top: 16,
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
