// Modules
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import strings from '@resources/localization'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { authAddEmail } from '@state/actions'
import { useRequestState } from '@api/requests/AsyncRequests'
import { InformationModal } from './information_modal/InformationModal'

export const ConflictModal = () => {
    const conflictModal = useAppSelector((state) => state.modal.conflict_modal)
    const conflictInfo = useAppSelector((state) => state.modal.conflict_accounts)

    const dispatch = useAppDispatch()

    const closeModal = () => {
        dispatch(modalAction({ modal: 'conflict_modal', state: false }))
    }

    const changeComplete = () => {
        closeModal()
        setInfoModal(true)
    }

    const [infoModal, setInfoModal] = useState(false)
    const [state, setState] = useRequestState(undefined, false, false)

    const onSubmit = () => {
        void dispatch(
            authAddEmail({
                urlParams: { token: conflictInfo.token },
                options: {
                    redux: {
                        stateHandler: setState,
                    },
                    then: {
                        action: changeComplete,
                    },
                },
            })
        )
    }

    return (
        <>
            <InformationModal
                open={conflictModal}
                kind={'warning'}
                onCancel={closeModal}
                onClick={onSubmit}
                loading={state.editing}
                onClickText={strings.screens.auth.signup.conflict_modal.action}
                title={strings.screens.auth.signup.conflict_modal.title}
                description={
                    strings.formatString(strings.screens.auth.signup.conflict_modal.description, conflictInfo.userEmail ? conflictInfo.userEmail : '') as string
                }
                subdescription={strings.screens.auth.signup.conflict_modal.subdescription}
            ></InformationModal>

            <InformationModal
                open={infoModal}
                kind={'success'}
                onClick={() => setInfoModal(false)}
                title={strings.screens.auth.signup.conflict_modal_success.title}
                description={strings.screens.auth.signup.conflict_modal_success.description}
            ></InformationModal>
        </>
    )
}
