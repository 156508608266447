// Libraries

// Antd
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { ChatListItem } from '@screens/community/chat/components/ChatListItem'
import { Router } from '@Router'
import { Link } from 'react-router-dom'
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed'
import { Check } from '@icon-park/react'
import { Notification } from '@api/model/requests/Notification'
import { communityChatList } from '@state/actions'
import { ChatListSkeleton } from '@components/skeletons/ChatSkeleton/components/ChatListSkeleton'
import { Colors } from '@util/color/Colors'
import strings from '@resources/localization'
import { useEffect } from 'react'

// MARK: - Definition

namespace MessagesPanel {
    export interface Props {
        onClose?: () => void
    }
}

// MARK: - Implementation

const MessagesPanel: React.FC<MessagesPanel.Props> = (props: MessagesPanel.Props) => {
    const { onClose } = props
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)

    const chats = useAppSelector((state) => state.community.items[currentCommunity]?.chats)
    const { general } = useAppSelector((state) => state.notification)

    const messagesCommunity = general.messages.filter((n) => {
        if (n.sender == 'system') return n
        if (n.predicate?.communityPk == currentCommunity) {
            return n
        }
    })

    const dispatch = useAppDispatch()

    useEffect(() => {
        void dispatch(
            communityChatList({
                urlParams: {
                    cm_pk: currentCommunity,
                },
                headerParams: {
                    page: 'first',
                },
            })
        )
    }, [currentCommunity])

    return (
        <div>
            <div className={'scrollStyle'} style={{ width: 424, maxHeight: 500, overflow: 'auto', paddingBottom: 16 }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: 8, padding: 16 }}>
                    {chats?.items &&
                        Object.keys(chats.items).map((chatPk: string, i: number) => {
                            const state = messagesCommunity
                                .filter((u) => {
                                    const predicate = u.predicate as Notification.RoomReference
                                    return predicate.roomPk == chatPk && u.state == 'new'
                                })
                                .reduce((accumulator, object) => {
                                    return accumulator + object.unseen
                                }, 0)
                            return (
                                <ChatListItem
                                    style={{
                                        border: `1px solid var(--border)`,
                                        borderRadius: 4,
                                        color: 'var(--neutral-13)',
                                        ...(state > 0 && { backgroundColor: Colors('POSTED_POLL_BACKGROUND') }),
                                        padding: 16,
                                    }}
                                    showOptions
                                    onClose={onClose}
                                    state={state}
                                    key={i}
                                    item={chats.items[chatPk].item}
                                />
                            )
                        })}

                    {chats?.items && chats?.listState?.loading && <ChatListSkeleton nElements={2} />}
                    {chats?.items && Object.keys(chats.items).length == 0 && !chats?.listState?.loading && (
                        <AlertDashed icon={<Check style={{ display: 'flex' }} />} message={strings.screens.chat.empty}></AlertDashed>
                    )}
                </div>
            </div>

            {chats?.items && !chats?.listState?.loading && (
                <div style={{ padding: '16px 0px' }}>
                    <Link style={{ margin: 16 }} to={{ pathname: Router.Path.community_chat({ cm_pk: currentCommunity }) }} onClick={onClose}>
                        {strings.screens.chat.showAll}
                    </Link>
                </div>
            )}
        </div>
    )
}

export { MessagesPanel }
