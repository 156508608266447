import { communityMemberDetails, userDetails, userEdit } from '@state/actions'
import { createSlice } from '@reduxjs/toolkit'
import { UserView } from '@api/model/responses/user/UserView'
import { ReducerCreateState, ReducerListState } from '@state/types/ReducerTypes'

namespace userSlice {
    export interface State {
        items: {
            [user_pk: string]: {
                item: UserView
                state: ReducerCreateState
            }
        }
        createState?: ReducerCreateState
        listState?: ReducerListState
    }
}

const defaultState: userSlice.State = {
    items: {},
    createState: {
        loading: false,
    },
    listState: {
        loading: false,
    },
}

const userSlice = createSlice({
    name: 'user',
    initialState: defaultState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(userDetails.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.u_pk] = {
                ...state.items[meta.arg.urlParams.u_pk],

                item: undefined,
                state: {
                    loading: true,
                },
            }
        })

        builder.addCase(userDetails.rejected, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.u_pk] = {
                ...state.items[meta.arg.urlParams.u_pk],

                item: undefined,
                state: {
                    loading: false,
                    error: payload,
                },
            }
        })

        builder.addCase(userDetails.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.u_pk] = {
                ...state.items[meta.arg.urlParams.u_pk],

                item: payload.data,
                state: {
                    loading: false,
                },
            }
        })

        builder.addCase(communityMemberDetails.pending, (state, { meta }) => {
            state.items[meta.arg.urlParams.u_pk] = {
                ...state.items[meta.arg.urlParams.u_pk],

                item: undefined,
                state: {
                    loading: true,
                },
            }
        })

        builder.addCase(communityMemberDetails.rejected, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.u_pk] = {
                ...state.items[meta.arg.urlParams.u_pk],

                item: undefined,
                state: {
                    loading: false,
                    error: payload,
                },
            }
        })

        builder.addCase(communityMemberDetails.fulfilled, (state, { payload, meta }) => {
            state.items[meta.arg.urlParams.u_pk] = {
                ...state.items[meta.arg.urlParams.u_pk],

                item: payload.data,
                state: {
                    loading: false,
                },
            }
        })
        builder.addCase(userEdit.pending, (state, { meta }) => {
            if (meta.arg.extraParams?.u_pk) {
                state.items[meta.arg.extraParams.u_pk] = {
                    ...state.items[meta.arg.extraParams.u_pk],
                    state: {
                        editing: true,
                        loading: false,
                    },
                }
            }
        })

        builder.addCase(userEdit.rejected, (state, { payload, meta }) => {
            if (meta.arg.extraParams?.u_pk) {
                state.items[meta.arg.extraParams.u_pk] = {
                    ...state.items[meta.arg.extraParams.u_pk],
                    state: {
                        editing: false,
                        loading: false,
                        error: payload,
                    },
                }
            }
        })

        builder.addCase(userEdit.fulfilled, (state, { payload, meta }) => {
            if (meta.arg.extraParams?.u_pk) {
                state.items[meta.arg.extraParams.u_pk] = {
                    item: payload.data,
                    state: {
                        editing: false,
                        loading: false,
                    },
                }
            }
        })
    },
})

export { userSlice }
