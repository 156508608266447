import React from 'react'
import { Close } from '@icon-park/react'
import { Colors } from '@util/color/Colors'
import useBreakpoints from '@hooks/useBreakpoints'
import style from './style.scss'

export const TopicDeleteButton = (props: { title: string; onClick: () => void }) => {
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    return (
        <div style={{ marginRight: 8, marginBottom: 8 }} className={isMobile ? style.itemMobile : style.item} >
            {props.title} <Close onClick={props.onClick} className={style.closeBtn}  style={{ marginLeft: isMobile ? 4 : 3, display: 'flex' }} theme="outline" size={isMobile ? 14 : 10} />
        </div>
    )
}
