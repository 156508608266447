// Libraries

// React
import React, { useContext } from 'react'
import { CreatePostContext } from '../../PostCreateProvider'
import Paragraph from 'antd/lib/typography/Paragraph'
import strings from '@resources/localization'
import { Form } from 'antd'
import { FormSelect } from '@components/form/FormSelectValue/FormSelectModalItem'

// MARK: - Definition

namespace TopicSelectionScreen {
    export interface Props {}
}

// MARK: - Implementation

const TopicSelectionScreen: React.FC<TopicSelectionScreen.Props> = () => {
    const { boards, form } = useContext(CreatePostContext)
    const board = form.getFieldValue(['form','turingo', 'board']) as string
    const topics = Form.useWatch(['form','turingo', 'topics'], form)
    const board_item = boards.find((value) => value.publicKey == board)
    return (
        <div>
            <Paragraph style={{ marginBottom: 16 }} className="turingo-text">
                {strings.screens.boards.discussion.post.topic.description}
            </Paragraph>
            <FormSelect
                defaultValue={topics}
                items={board_item?.topics}
                valueField="publicKey"
                nameField="name"
                maxSelection={10}
                afterAction={(selection) => {
                    form.setFieldValue(['form','turingo', 'topics'], selection)
                }}
            />
        </div>
    )
}

export { TopicSelectionScreen }
