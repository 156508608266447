import { UserMentionView } from '@api/model/responses/user/Mentions'
import { AvatarTuringo } from '@components/avatar/Avatar'
import style from './AutoComplete.scss'
import { Skeleton } from 'antd'
import { createPortal } from 'react-dom'

export const Autocomplete = (props: {
    height?: number
    width?: number
    items: Array<UserMentionView>
    loading: boolean
    handleSelection: (id: UserMentionView) => void
    top: number
    left: number
}) => {
    const { items, handleSelection, top, left, loading, height, width } = props

    if (loading)
        return createPortal(
            <div className={style.autocompletePanel} style={{ top, height: height, left, width }}>
                <ul className={style.autocompleteItems}>
                    <li className={style.autocompleteSkeleton} key={'#s1'}>
                        <Skeleton avatar active title={true} paragraph={null} />
                    </li>
                    <li className={style.autocompleteSkeleton} key={'#s2'}>
                        <Skeleton avatar active title={true} paragraph={null} />
                    </li>
                    <li className={style.autocompleteSkeleton} key={'#s3'}>
                        <Skeleton avatar active title={true} paragraph={null} />
                    </li>
                    <li className={style.autocompleteSkeleton} key={'#s4'}>
                        <Skeleton avatar active title={true} paragraph={null} />
                    </li>
                    <li className={style.autocompleteSkeleton} key={'#s5'}>
                        <Skeleton avatar active title={true} paragraph={null} />
                    </li>
                </ul>
            </div>,
            document.body
        )

    return createPortal(
        <div className={style.autocompletePanel} style={{ top, height: height, left, width }}>
            {items.length > 0 && (
                <ul className={style.autocompleteItems}>
                    {items.map((hit) => (
                        <li key={hit.publicKey} className={style.autocompleteItem}>
                            <button style={{ background: 'none', border: 'none', cursor: 'pointer' }} onClick={() => handleSelection(hit)}>
                                <Item mention={hit} />
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>,
        document.body
    )
}

const Item = (props: { mention: UserMentionView }) => {
    const { mention } = props

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <AvatarTuringo
                size={16}
                style={{
                    height: 40,
                    width: 40,
                    minWidth: 40,
                    lineHeight: '40px',
                    marginRight: 8,
                }}
                name={mention.name}
                avatar={mention.avatarUrl}
            />

            <div style={{ lineHeight: '22px' }}>
                <div style={{ fontSize: 14, fontWeight: 400, lineHeight: '22px', textAlign: 'start' }}>{mention.name}</div>
                <div style={{ fontSize: 12, fontWeight: 400, lineHeight: '22px', textAlign: 'start' }}>{mention.job}</div>
            </div>
        </div>
    )
}
