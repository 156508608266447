// Libraries

// React
import React, { useContext } from 'react'

// Antd
import { Button, Col, Divider, Form, Row } from 'antd'
import strings from '@resources/localization'
import { ChartHistogramTwo, PictureOne, VideoTwo } from '@icon-park/react'
import { FileTextOutlined } from '@ant-design/icons'
import useBreakpoints from '@hooks/useBreakpoints'
import { PostKindSelection } from './PostKindSelection'
import { CreatePostContext } from '../../../PostCreateProvider'
import { useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import { useParams } from 'react-router'
import { PostRouteParams } from '@typings/routing/Router'
import { CrossPostingContext } from '../CreationScreen'
import { ButtonPost } from 'src/turingo_components/buttons/button_post/ButtonPost'

// MARK: - Definition

namespace PostCreationFooter {
    export interface Props {
        focused: boolean
    }
}

// MARK: - Implementation

const PostCreationFooter: React.FC<PostCreationFooter.Props> = (props: PostCreationFooter.Props) => {
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const { consumer } = useContext(CrossPostingContext)

    const { form, setScreen, boards, editable, item, changeAttachments } = useContext(CreatePostContext)
    const kind = Form.useWatch(['form', consumer, 'kind'], form)
    const content = Form.useWatch(['form', consumer, 'content'], form)
    const topics = Form.useWatch(['form', consumer, 'topics'], form)
    const board = Form.useWatch(['form', consumer, 'board'], form)
    const board_item = boards.find((value) => value.publicKey == board)

    const items = [
        {
            tooltip: strings.screens.boards.discussion.post.kind.image.upload.multiple.title,
            title: strings.screens.boards.discussion.post.kind.image.name,
            action: () => {
                form.setFieldValue(['form', consumer, 'kind'], 'image')
                if (consumer != 'turingo') {
                    changeAttachments.set(true)
                }

                if (consumer == 'turingo' && !changeAttachments.get) {
                    form.setFieldValue(['form', 'linkedin', 'kind'], 'image')
                    form.setFieldValue(['form', 'X', 'kind'], 'image')
                }
            },
            highlightColor: 'var(--post-type-image-icon)',
            backgroundColor: 'var(--post-type-image-background)',
            iconPark: PictureOne,
            disabled: editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'image',
            selected: kind == 'image',
            compact: true,
            showText: false,
        },
        {
            tooltip: strings.screens.boards.discussion.post.kind.video.placeholder,
            title: strings.screens.boards.discussion.post.kind.video.name,
            action: () => {
                form.setFieldValue(['form', consumer, 'kind'], 'video')

                if (consumer != 'turingo') {
                    changeAttachments.set(true)
                }

                if (consumer == 'turingo' && !changeAttachments.get) {
                    form.setFieldValue(['form', 'linkedin', 'kind'], 'video')
                    form.setFieldValue(['form', 'X', 'kind'], 'video')
                }
            },
            highlightColor: 'var(--post-type-video-icon)',
            backgroundColor: 'var(--post-type-video-background)',
            iconPark: VideoTwo,
            disabled: editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'video',
            selected: kind == 'video',
            compact: true,
            showText: false,
        },
        {
            tooltip: strings.screens.boards.discussion.post.kind.poll.placeholder,
            title: strings.screens.boards.discussion.post.kind.poll.name,
            action: () => {
                setScreen('poll_creation')
            },
            highlightColor: 'var(--post-type-poll-icon)',
            backgroundColor: 'var(--post-type-poll-background)',
            iconPark: ChartHistogramTwo,
            disabled: consumer != 'turingo' || editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'poll',
            selected: kind == 'poll',
            compact: true,
            showText: false,
        },
        {
            tooltip: strings.screens.boards.discussion.post.kind.attachment.placeholder,
            title: strings.screens.boards.discussion.post.kind.attachment.name,
            action: () => {
                form.setFieldValue(['form', consumer, 'kind'], 'attachment')
            },
            highlightColor: 'var(--post-type-attachment-icon)',
            backgroundColor: 'var(--post-type-attachment-background)',
            iconAnt: FileTextOutlined,
            disabled: consumer != 'turingo' || editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'attachment',
            selected: kind == 'attachment',
            compact: true,
            showText: false,
        },
    ]
    const { cm_pk, b_pk, p_pk } = useParams<PostRouteParams>()

    const isFeed = !b_pk
    const scope = p_pk ? 'detail' : 'all'

    const isLoading = useAppSelector((state) => state.posts.all[isFeed ? 'feed' : b_pk]?.createState)?.loading

    const isEditing = editable ? useAppSelector((state) => state.posts[scope][isFeed ? 'feed' : b_pk].items[item?.publicKey]?.state?.loading) : undefined

    const toBoardSelection = () => {
        setScreen('board_final')
    }
    const toTopicSelection = () => {
        setScreen('topic_final')
    }

    if (!props.focused && isMobile && kind == 'basic') {
        return (
            <div
                style={{
                    marginTop: 8,
                    ...(isMobile && {
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        zIndex: 100,
                        backgroundColor: 'var(--background)',
                        width: '100%',
                        padding: 16,
                        alignItems: 'center',
                    }),
                }}
            >
                <Divider style={{ margin: isMobile ? '0px 0px 16px 0px' : '8px 0px' }}></Divider>

                <Row gutter={[24, 24]}>
                    {items.map((item) => (
                        <Col key={item.title} span={24}>
                            <PostKindSelection firstSelection showTooltip={false} {...item}></PostKindSelection>
                        </Col>
                    ))}
                </Row>
            </div>
        )
    }

    return (
        <div style={{ marginTop: 8 }}>
            <div style={{ padding: isMobile ? '0px 16px' : '16px 0px' }}>
                <Divider style={{ margin: 0 }}></Divider>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', padding: isMobile ? 16 : 0 }}>
                <div
                    style={{
                        width: isMobile ? 'calc(100% - 118px)' : 'unset',
                        maxWidth: isMobile ? 248 : 'unset',
                        display: 'grid',
                        gridAutoFlow: 'column',
                        gap: isMobile ? 0 : 24,
                        alignItems: 'center',
                    }}
                >
                    {/* {items.map((item) => (
                        <div key={item.title}>
                            <PostKindSelection {...item}></PostKindSelection>
                        </div>
                    ))} */}

                        <ButtonPost disabled={editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'image'} onClick={() => form.setFieldValue(['form', consumer, 'kind'], 'image')} kind={'image'}></ButtonPost>
                        <ButtonPost disabled={editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'video'} onClick={() => form.setFieldValue(['form', consumer, 'kind'], 'video')} kind={'video'}></ButtonPost>
                        <ButtonPost disabled={consumer != 'turingo' || editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'poll'} onClick={() => setScreen('poll_creation')} kind={'poll'}></ButtonPost>
                        <ButtonPost disabled={consumer != 'turingo' || editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'attachment'} onClick={() => form.setFieldValue(['form', consumer, 'kind'], 'attachment')} kind={'attachment'}></ButtonPost>
                </div>
                <Button
                    onClick={
                        !board
                            ? toBoardSelection
                            : (!topics || topics?.length == 0) && board_item?.topics?.length > 0 && !editable
                            ? toTopicSelection
                            : form.submit
                    }
                    type="primary"
                    disabled={(!content || content.trim() == '') && kind == 'basic'}
                    loading={editable ? isEditing : isLoading}
                    style={{ borderRadius: 4 }}
                >
                    {editable ? strings.general.save : strings.general.topost}
                </Button>
            </div>
        </div>
    )
}

export { PostCreationFooter }
