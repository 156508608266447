// Libraries

// Antd
import {} from 'antd'
import { PinIcon } from '@components/icons/PinIcon'
import strings from '@resources/localization'

// MARK: - Implementation

const PinHeader = () => {
    return (
        <div
            style={{
                height: 30,
                backgroundColor: 'var(--ant-primary-1)',
                width: '100%',
                borderRadius: '4px 4px 0px 0px',
                color: 'var(--ant-primary-6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0px 24px',
            }}
        >
            <PinIcon color="var(--ant-primary-6)" style={{ marginRight: 4 }} /> {strings.screens.markasread.title}
        </div>
    )
}

export { PinHeader }
