import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    height?: number
    width?: number
}

export const OutsideLinkIcon = (props: Props) => {
    return (
        <Icon
            component={() => {
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={'100%'}
                        viewBox="0 0 24 24"
                        fill={'currentColor'}
                    >
                        <path
                            d="M4 2C2.89543 2 2 2.89542 2 3.99998V20C2 21.1046 2.89544 22 4 22H20C21.1046 22 22 21.1046 22 20V12.5001C22 11.9478 21.5523 11.5001 21 11.5001C20.4477 11.5001 20 11.9478 20 12.5001V20H4V3.99998H11.5C12.0523 3.99998 12.5 3.55227 12.5 2.99999C12.5 2.44771 12.0523 2 11.5 2H4Z"
                            fill={'current'}
                        />
                        <path
                            d="M13 9.58581C13 9.58581 10.9536 11.6605 10.5859 11.9994C9.5 12.9999 10.9388 14.4743 11.9998 13.4139C12.3534 13.0605 14.42 10.9942 14.4143 10.9999L20 5.41426V6.99995C20 7.55223 20.4477 7.99994 21 7.99994C21.5523 7.99994 22 7.55223 22 6.99995V2.99999C22 2.44771 21.5523 2 21 2H17C16.4477 2 16 2.44771 16 2.99999C16 3.55227 16.4477 3.99998 17 3.99998H18.5859L13 9.58581Z"
                            fill={'current'}
                        />
                    </svg>
                )
            }}
            {...props}
        />
    )
}
