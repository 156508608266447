// Libraries

// React
import React, { CSSProperties, useContext } from 'react'

// Antd
import { Form } from 'antd'
import { CreatePostContext } from '../../../PostCreateProvider'
import { TopicAddButton } from '../../../topics/TopicAddButton'
import { TopicDeleteButton } from '../../../topics/TopicDeleteButton'
import strings from '@resources/localization'

// MARK: - Definition

namespace PostCreationTopics {
    export interface Props {
        style?: CSSProperties
        innerRef?: any
    }
}

// MARK: - Implementation



const PostCreationTopics: React.FC<PostCreationTopics.Props> = (props: PostCreationTopics.Props) => {
    const { style, innerRef } = props
    const { form, boards, setScreen } = useContext(CreatePostContext)
    const board = Form.useWatch(['form', 'turingo', 'board'], form)
    const topics = Form.useWatch(['form','turingo', 'topics'], form)
    const board_item = boards.find((value) => value.publicKey == board)
    return (
        <div ref={innerRef} style={{ display: 'flex', flexFlow: 'wrap', ...style }}>
            {topics?.map((topicRemove) => {
                return (
                    <TopicDeleteButton
                        key={topicRemove}
                        title={board_item?.topics?.find((value) => value.publicKey == topicRemove).name}
                        onClick={() =>
                            form.setFieldValue(
                                ['form', 'turingo', 'topics'],
                                topics.filter((topic) => topicRemove != topic)
                            )
                        }
                    />
                )
            })}
            <TopicAddButton
                disabled={!board || boards.find((b) => b.publicKey == board)?.topics?.length == 0}
                title={strings.screens.boards.discussion.post.topic.action}
                onClick={() => setScreen('topic_selection')}
            />
        </div>
    )
}

export { PostCreationTopics }
