import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface ApparenceIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const ApparenceIcon = (props: ApparenceIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill={props.color ? props.color : 'var(--neutral-13)'}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.09837 1.82705C3.62849 2.25454 1.75001 4.40786 1.75001 6.99992C1.75001 9.89942 4.10051 12.2499 7.00001 12.2499C9.59207 12.2499 11.7454 10.3714 12.1729 7.90156C11.3946 8.68732 10.315 9.17415 9.12121 9.17415C6.74889 9.17415 4.82578 7.25104 4.82578 4.87872C4.82578 3.68489 5.31261 2.6053 6.09837 1.82705ZM0.583344 6.99992C0.583344 3.45609 3.45618 0.583252 7.00001 0.583252C7.44472 0.583252 7.87947 0.628577 8.29965 0.71506C8.56523 0.769723 8.75813 1.00018 8.76518 1.27124C8.77224 1.54229 8.59159 1.78247 8.32921 1.85088C6.98438 2.2015 5.99245 3.42499 5.99245 4.87872C5.99245 6.6067 7.39323 8.00748 9.12121 8.00748C10.5749 8.00748 11.7984 7.01554 12.149 5.67073C12.2174 5.40835 12.4576 5.2277 12.7287 5.23475C12.9997 5.2418 13.2302 5.43469 13.2849 5.70027C13.3713 6.12045 13.4167 6.55521 13.4167 6.99992C13.4167 10.5437 10.5438 13.4166 7.00001 13.4166C3.45618 13.4166 0.583344 10.5437 0.583344 6.99992Z"
                    />
                </svg>
            )}
            {...props}
        />
    )
}
