import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

interface MenuToggleIcon {
    style?: CSSProperties
    className: string
    onClick?: () => void
    color?: string
    filled?: boolean
    height?: number
    width?: number
}

export const TeamsIcon = (props: MenuToggleIcon) => (
    <Icon
        component={() => {
            if (props.filled) {
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={'100%'}
               
                        viewBox="0 0 24 24"
                        fill={'currentColor'}
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21.077 13.8645C22.2214 14.4765 23 15.6832 23 17.0718C23 19.0792 21.3727 20.7065 19.3652 20.7065C18.8282 20.7065 18.3185 20.5901 17.8598 20.3811C16.2777 21.7172 14.2329 22.5226 12 22.5226C9.76708 22.5226 7.72228 21.7172 6.14023 20.3811C5.68152 20.5901 5.17175 20.7065 4.63478 20.7065C2.62735 20.7065 1 19.0792 1 17.0718C1 15.6832 1.77858 14.4766 2.92298 13.8645C2.91638 13.7224 2.91304 13.5794 2.91304 13.4356C2.91304 9.70939 5.15588 6.507 8.36523 5.10479V5.10596L8.36522 5.1139C8.36522 5.11103 8.36523 5.10883 8.36523 5.10596C8.37024 3.1028 9.99566 1.48047 12 1.48047C14.0039 1.48047 15.6291 3.10217 15.6348 5.1048C18.8441 6.50702 21.0869 9.7094 21.0869 13.4356C21.0869 13.5794 21.0836 13.7224 21.077 13.8645ZM13.7122 5.11525C13.7122 6.06085 12.9456 6.82742 12 6.82742C11.0544 6.82742 10.2878 6.06085 10.2878 5.11525C10.2878 4.16964 11.0544 3.40308 12 3.40308C12.9456 3.40308 13.7122 4.16964 13.7122 5.11525ZM15.1184 6.98374C14.483 8.04193 13.3242 8.75003 12 8.75003C10.6758 8.75003 9.51702 8.04193 8.88161 6.98373C6.48701 8.14324 4.83565 10.5966 4.83565 13.4356L4.83565 13.4424C6.74966 13.5467 8.26956 15.1317 8.26956 17.0718C8.26956 17.8313 8.03658 18.5364 7.63818 19.1196C8.84622 20.048 10.3586 20.6 12 20.6C13.6413 20.6 15.1538 20.048 16.3618 19.1196C15.9634 18.5364 15.7304 17.8313 15.7304 17.0718C15.7304 15.1317 17.2503 13.5467 19.1643 13.4424L19.1643 13.4356C19.1643 10.5966 17.513 8.14325 15.1184 6.98374ZM6.34695 17.0718C6.34695 18.0174 5.58039 18.7839 4.63478 18.7839C3.68917 18.7839 2.92261 18.0174 2.92261 17.0718C2.92261 16.1261 3.68917 15.3596 4.63478 15.3596C5.58039 15.3596 6.34695 16.1261 6.34695 17.0718ZM19.3652 18.7839C20.3108 18.7839 21.0774 18.0174 21.0774 17.0718C21.0774 16.1262 20.3108 15.3596 19.3652 15.3596C18.4196 15.3596 17.653 16.1262 17.653 17.0718C17.653 18.0174 18.4196 18.7839 19.3652 18.7839Z"
                            fill="current"
                        />
                        <path
                            d="M12 6.82742C12.9456 6.82742 13.7122 6.06085 13.7122 5.11525C13.7122 4.16964 12.9456 3.40308 12 3.40308C11.0544 3.40308 10.2878 4.16964 10.2878 5.11525C10.2878 6.06085 11.0544 6.82742 12 6.82742Z"
                            fill="current"
                        />
                        <path
                            d="M4.63478 18.7839C5.58039 18.7839 6.34695 18.0174 6.34695 17.0718C6.34695 16.1261 5.58039 15.3596 4.63478 15.3596C3.68917 15.3596 2.92261 16.1261 2.92261 17.0718C2.92261 18.0174 3.68917 18.7839 4.63478 18.7839Z"
                            fill="current"
                        />
                        <path
                            d="M21.0774 17.0718C21.0774 18.0174 20.3108 18.7839 19.3652 18.7839C18.4196 18.7839 17.653 18.0174 17.653 17.0718C17.653 16.1262 18.4196 15.3596 19.3652 15.3596C20.3108 15.3596 21.0774 16.1262 21.0774 17.0718Z"
                            fill="current"
                        />
                    </svg>
                )
            } else {
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={'100%'}
                        fill={'currentColor'}

                        viewBox="0 0 24 24"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21.077 13.8645C22.2214 14.4765 23 15.6832 23 17.0718C23 19.0792 21.3727 20.7065 19.3652 20.7065C18.8282 20.7065 18.3185 20.5901 17.8598 20.3811C16.2777 21.7172 14.2329 22.5226 12 22.5226C9.76708 22.5226 7.72228 21.7172 6.14023 20.3811C5.68152 20.5901 5.17175 20.7065 4.63478 20.7065C2.62735 20.7065 1 19.0792 1 17.0718C1 15.6832 1.77858 14.4766 2.92298 13.8645C2.91638 13.7224 2.91304 13.5794 2.91304 13.4356C2.91304 9.70939 5.15588 6.507 8.36523 5.10479V5.10596L8.36522 5.1139C8.36522 5.11367 8.36522 5.11412 8.36522 5.1139C8.36522 5.11103 8.36523 5.10883 8.36523 5.10596C8.37024 3.1028 9.99566 1.48047 12 1.48047C14.0039 1.48047 15.6291 3.10217 15.6348 5.1048C18.8441 6.50702 21.0869 9.7094 21.0869 13.4356C21.0869 13.5794 21.0836 13.7224 21.077 13.8645ZM13.7122 5.11525C13.7122 6.06085 12.9456 6.82742 12 6.82742C11.0544 6.82742 10.2878 6.06085 10.2878 5.11525C10.2878 4.16964 11.0544 3.40308 12 3.40308C12.9456 3.40308 13.7122 4.16964 13.7122 5.11525ZM15.1184 6.98374C14.483 8.04193 13.3242 8.75003 12 8.75003C10.6758 8.75003 9.51702 8.04193 8.88161 6.98373C6.48701 8.14324 4.83565 10.5966 4.83565 13.4356L4.83565 13.4424C6.74966 13.5467 8.26956 15.1317 8.26956 17.0718C8.26956 17.8313 8.03658 18.5364 7.63818 19.1196C8.84622 20.048 10.3586 20.6 12 20.6C13.6413 20.6 15.1538 20.048 16.3618 19.1196C15.9634 18.5364 15.7304 17.8313 15.7304 17.0718C15.7304 15.1317 17.2503 13.5467 19.1643 13.4424L19.1643 13.4356C19.1643 10.5966 17.513 8.14325 15.1184 6.98374ZM15.6348 5.1139C15.6348 5.11367 15.6348 5.11412 15.6348 5.1139V5.1139ZM6.34695 17.0718C6.34695 18.0174 5.58039 18.7839 4.63478 18.7839C3.68917 18.7839 2.92261 18.0174 2.92261 17.0718C2.92261 16.1261 3.68917 15.3596 4.63478 15.3596C5.58039 15.3596 6.34695 16.1261 6.34695 17.0718ZM19.3652 18.7839C20.3108 18.7839 21.0774 18.0174 21.0774 17.0718C21.0774 16.1262 20.3108 15.3596 19.3652 15.3596C18.4196 15.3596 17.653 16.1262 17.653 17.0718C17.653 18.0174 18.4196 18.7839 19.3652 18.7839Z"
                            fill="current"
                        />
                    </svg>
                )
            }
        }}
        {...props}
    />
)
