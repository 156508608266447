import { Alert, AlertProps } from 'antd'
import { ReactElement } from 'react'

export const AlertDashed = (props: AlertProps): ReactElement => {
    const { style, showIcon = true } = props

    return (
        <Alert
            className={'blue dashed'}
            style={{ borderStyle: 'dashed', width: '100%', ...style }}
            message={props.message}
            type={props.type}
            icon={props.icon}
            showIcon={showIcon}
        />
    )
}
