import useBreakpoints from '@hooks/useBreakpoints'
import strings from '@resources/localization'
import { Card } from 'antd'
import { useEffect } from 'react'
import { FooterModal } from './components/FooterModal'
import { InformationModalBody } from './information_modal/InformationModalBody'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { setErrorAction } from '@state/actions'
import { useLocation } from 'react-use'
import { useIsMount } from '@hooks/useIsMount'
import { Router } from '@Router'

interface Props {
    error: string
}

export const SystemError = (props: Props) => {
    const breakpoints = useBreakpoints()

    const { error } = props
    const isLogged = useAppSelector((state) => !!state.auth.authToken)
    const communities = useAppSelector((state) => state.auth?.communities)
    const isMobile = breakpoints.isMobile
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)

    const dispatch = useAppDispatch()
    const location = useLocation()
    const history = useHistory()

    const isMount = useIsMount()

    useEffect(() => {
        if (!isMount) {
            dispatch(setErrorAction())
        }
    }, [location.pathname])

    const onAction = () => {
        dispatch(setErrorAction())
        const community = location.pathname.split('/')[1]
        history.push(Router.Path.community_home({ cm_pk: currentCommunity }), { to: location.pathname })
    }

    const onBack = () => {
        dispatch(setErrorAction())
        const community = location.pathname.split('/')[1]
        history.push(Router.Path.community_home({ cm_pk: community }), { to: location.pathname })
    }

    if (error == 'access.notallowed') {
        if (isLogged) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexGrow: 1,
                        alignItems: 'center',
                        marginTop: isMobile ? 16 : 24,
                        marginBottom: isMobile ? 16 : 24,
                    }}
                >
                    <Card style={{ height: 'fit-content', maxWidth: 572, width: '100%' }}>
                        <InformationModalBody
                            kind={'warning'}
                            title={strings.system.access_error.title}
                            description={strings.system.access_error.description}
                        />
                        <FooterModal
                            primary={{
                                action: onAction,
                                customText: strings.system.access_error.action,
                            }}
                        />
                    </Card>
                </div>
            )
        }
    }

    if (error == 'application.community.notfound') {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignItems: 'center',
                    marginTop: isMobile ? 16 : 24,
                    marginBottom: isMobile ? 16 : 24,
                }}
            >
                <Card style={{ height: 'fit-content', maxWidth: 572, width: '100%' }}>
                    <InformationModalBody kind={'warning'} title={strings.system.not_found.title} description={strings.system.not_found.description} />
                    <FooterModal
                        primary={{
                            action: () => {
                                dispatch(setErrorAction())
                                history.push(Router.Path.root({}))
                            },
                            customText: strings.system.not_found.action,
                        }}
                    />
                </Card>
            </div>
        )
    }

    if (error == 'application.post.notfound' || error == 'application.board.notfound') {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignItems: 'center',
                    marginTop: isMobile ? 16 : 24,
                    marginBottom: isMobile ? 16 : 24,
                }}
            >
                <Card style={{ height: 'fit-content', maxWidth: 572, width: '100%' }}>
                    <InformationModalBody kind={'warning'} title={strings.system.not_found.title} description={strings.system.not_found.description} />
                    <FooterModal
                        primary={{
                            action: onBack,
                            customText: strings.system.not_found.action,
                        }}
                    />
                </Card>
            </div>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
                alignItems: 'center',
                marginTop: isMobile ? 16 : 24,
                marginBottom: isMobile ? 16 : 24,
            }}
        >
            <Card style={{ height: 'fit-content', maxWidth: 572, width: '100%' }}>
                <InformationModalBody kind={'error'} title={strings.system.api_error.title} description={strings.system.api_error.description} />

                <FooterModal
                    primary={{
                        action: onAction,
                        customText: strings.system.api_error.action,
                    }}
                />
            </Card>
        </div>
    )
}
