import { compact } from 'lodash'

type SingleClassType = string | undefined | null
type MultipleClassType = Array<SingleClassType>

const ClassNames = (...classes: MultipleClassType): string => {
    if (!classes || classes.length === 0 ) { return '' }
    if (classes instanceof Array) {
        return compact(classes).join(' ')
    }
    return classes
}

export default ClassNames