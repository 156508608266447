// Libraries

// React
import React, { CSSProperties } from 'react'

// Antd
import {} from 'antd'

// MARK: - Definition

namespace SilenceIcon {
    export interface Props {
        style?: CSSProperties
        color?: string
    }
}

// MARK: - Implementation

const SilenceIcon: React.FC<SilenceIcon.Props> = (props: SilenceIcon.Props) => {
    return (
        <svg style={{ display: 'flex',alignItems: 'center', ...props.style }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7496 10.998H11.3746V5.68555C11.3746 5.0695 11.2473 4.48333 11.0177 3.95185L10.1372 4.83228C10.2114 5.10692 10.2496 5.39322 10.2496 5.68555V10.998H3.97148L2.84648 12.123H5.24957C5.24957 13.0887 6.03394 13.873 6.99957 13.873C7.96519 13.873 8.74957 13.0887 8.74957 12.123H12.1246C12.1933 12.123 12.2496 12.0668 12.2496 11.998V11.498C12.2496 11.2215 12.0261 10.998 11.7496 10.998ZM6.99957 12.873C6.5855 12.873 6.24957 12.5371 6.24957 12.123H7.74957C7.74957 12.5371 7.41363 12.873 6.99957 12.873Z"
                fill={props.color || 'var(--neutral-13)'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.82735 12.1137L1.82568 12.113L1.30303 12.6357C1.27957 12.6591 1.24775 12.6723 1.21459 12.6723C1.18142 12.6723 1.14961 12.6591 1.12614 12.6357L0.458492 11.968C0.435046 11.9445 0.421875 11.9127 0.421875 11.8796C0.421875 11.8464 0.435046 11.8146 0.458492 11.7911C1.18063 11.0692 1.90264 10.3472 2.62457 9.62533V5.68555C2.62457 3.48086 4.25425 1.65898 6.37457 1.35586V0.748047C6.37457 0.402734 6.65425 0.123047 6.99957 0.123047C7.34488 0.123047 7.62457 0.402734 7.62457 0.748047V1.35586C8.47599 1.47758 9.24831 1.8442 9.86776 2.38216C10.4441 1.80591 11.0205 1.22967 11.597 0.65345C11.6086 0.641816 11.6224 0.632588 11.6376 0.626291C11.6528 0.619994 11.6691 0.616753 11.6855 0.616753C11.7019 0.616753 11.7182 0.619994 11.7334 0.626291C11.7486 0.632588 11.7624 0.641816 11.774 0.65345L12.4407 1.32097C12.4524 1.33259 12.4616 1.34639 12.4679 1.36158C12.4742 1.37677 12.4774 1.39305 12.4774 1.4095C12.4774 1.42594 12.4742 1.44222 12.4679 1.45741C12.4616 1.4726 12.4524 1.4864 12.4407 1.49802L10.6562 3.28258L10.6571 3.28399L9.83959 4.10149L9.83875 4.1L3.74957 10.1892V10.1915L1.82735 12.1137ZM6.99957 2.43555C7.76484 2.43555 8.48888 2.69743 9.07025 3.17956C7.85071 4.39896 6.63131 5.61843 5.41184 6.83797L3.74957 8.50033V5.68555C3.74957 4.8168 4.08707 4.00117 4.70113 3.38711C5.31519 2.77305 6.13082 2.43555 6.99957 2.43555Z"
                fill={props.color|| 'var(--neutral-13)'}
            />
        </svg>
    )
}

export { SilenceIcon }
