// Modules
import React, { useState } from 'react'
import { Form, Input, Radio } from 'antd'
import { useAppDispatch } from '@hooks/useStore'
import strings from '@resources/localization'
import { rateLessonCreate } from '@state/actions'
import { useRequestState } from '@api/requests/AsyncRequests'
import { InformationModal } from './information_modal/InformationModal'
import { FooterModal } from './components/FooterModal'
import Paragraph from 'antd/lib/typography/Paragraph'
import { PostRouteParams } from '@typings/routing/Router'
import { useParams } from 'react-router'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'

export const RateLessonModal = (props: { step: string; setStep(step: string): void }) => {
    const { step, setStep } = props
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()

    const { cm_pk, b_pk, p_pk } = useParams<PostRouteParams>()

    const onFinish = (res) => {
        setStep('success')
    }
    const [state, setState] = useRequestState(undefined, false, false)

    const submit = (values: { rate: number; content: string }) => {
        const { rate, content } = values

        void dispatch(
            rateLessonCreate({
                urlParams: { cm_pk, b_pk: b_pk, p_pk },
                bodyParams: {
                    rate,
                    content,
                },
                extraParams: {
                    scope: 'detail',
                },

                options: {
                    redux: {
                        stateHandler: setState,
                    },
                    alerts: {
                        error: strings.alerts.rate.create.failure,
                    },
                    then: {
                        action: onFinish,
                    },
                },
            })
        )
    }

    const [value, setValue] = useState<number>(undefined)

    const onChange = (e) => {
        setValue(e.target.value)
    }

    return (
        <>
            <InformationModal
                kind={'success'}
                open={step == 'success'}
                onClick={() => setStep(undefined)}
                title={'Gracias'}
                description={'La comunidad la construimos en conjunto, por lo que tu opinión es muy importante para Turingo.'}
            />
            <TuringoModal
                open={step == 'form'}
                width={572}
                title={'Calificación de la lección'}
                onCancel={() => setStep(undefined)}
                footer={[
                    <FooterModal
                        key={'footer_modal'}
                        primary={{
                            action: form.submit,
                            disabled: !value,
                            loading: state.editing,
                            customText: strings.general.send,
                        }}
                    />,
                ]}
            >
                <Form form={form} onFinish={submit} layout={'vertical'}>
                    <Form.Item name={['rate']} label={<Paragraph>{'Del 0 al 10 ¿qué tan probable es que recomiendes esta lección a otra persona?'}</Paragraph>}>
                        <Radio.Group onChange={onChange} prefixCls="radio-turingo" name="radiogroup">
                            {Array(11)
                                .fill(0)
                                .map((a, i: number) => {
                                    return (
                                        <Radio key={`rate#${i}`} prefixCls="radio-turingo" value={i}>
                                            {i}
                                        </Radio>
                                    )
                                })}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name={['content']} label={'Cuéntanos qué te pareció la lección'}>
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} showCount maxLength={500} />
                    </Form.Item>
                </Form>
            </TuringoModal>
        </>
    )
}
