// Libraries

// React
import React from 'react'

// Antd
import {} from 'antd'
import strings from '@resources/localization'
import { BoardView } from '@api/model/responses/board/BoardView'
import { Certificate, Peoples } from '@icon-park/react'
import { ClockCircleOutlined, PlayCircleOutlined, RocketOutlined } from '@ant-design/icons'
import { localizeSeconds } from '@util/localization/Time'
// MARK: - Definition

namespace BoardCardBody {
    export interface Props {
        item: BoardView
        isOnboarding?: boolean
        showCertificate?: boolean
    }
}

// MARK: - Implementation

const BoardCardBody: React.FC<BoardCardBody.Props> = (props: BoardCardBody.Props) => {
    const { item, isOnboarding, showCertificate } = props

    const dateRelease = new Date(item.dateCreated)
    const today = new Date()
    const moreThanOneMonth = today.getTime() - dateRelease.getTime() > 2592000000

    return (
        <div>
            <div style={{ marginBottom: 4, lineHeight: 1.2, display: 'flex', alignItems: 'center' }}>
                {item.memberCount < 99 && !moreThanOneMonth && !isOnboarding && (
                    <>
                        <RocketOutlined style={{ marginRight: 4, alignSelf: 'center' }} />
                        <span style={{ marginBottom: 0, fontWeight: 500 }}>{strings.general.newRelease} </span>
                    </>
                )}
                {item.memberCount < 99 && moreThanOneMonth && (
                    <>
                        <div style={{ height: 18 }}></div>
                    </>
                )}
                {item.memberCount > 99 && (
                    <>
                        {item.kind != 'story' && <Peoples style={{ marginRight: 4, alignSelf: 'center' }} />}
                        {item.kind == 'story' && <PlayCircleOutlined style={{ marginRight: 4, alignSelf: 'center' }} />}

                        <span style={{ marginRight: 4, marginBottom: 0, fontWeight: 500 }}>{strings.screens.boards[item.kind].subscribers} </span>
                        <span style={{ marginBottom: 0, wordBreak: 'break-word' }}>{item.memberCount}</span>
                    </>
                )}
            </div>
            {item.av?.duration && (
                <div style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}>
                    <ClockCircleOutlined style={{ marginRight: 4, alignSelf: 'center' }} />
                    <span style={{ marginRight: 4, marginBottom: 0, fontWeight: 500 }}>{strings.general.duration} </span>
                    <span style={{ marginBottom: 0, wordBreak: 'break-word' }}>{`${localizeSeconds(item.av?.duration)}`}</span>
                </div>
            )}

            {item.kind == 'course' && !isOnboarding && (
                <div style={{ marginBottom: 4, lineHeight: 1.2, display: 'flex', alignItems: 'center' }}>
                    <Certificate size={14} style={{ marginRight: 4, alignSelf: 'center' }} />
                    <span style={{ marginRight: 4, marginBottom: 0, fontWeight: 500 }}>{strings.general.certificate.title.singular} </span>
                    <span style={{ marginBottom: 0, wordBreak: 'break-word' }}>
                        {!showCertificate ? strings.general.certificate.notIncluded : strings.general.certificate.included}
                    </span>
                </div>
            )}
        </div>
    )
}

export { BoardCardBody }
