import useBreakpoints from '@hooks/useBreakpoints';
import { Card, Col } from 'antd';
import React from 'react'
import style from './FeaturesCard.scss';

interface Props {
    id?: string
    title: string
    items: Array<string>
}

export const FeaturesCard = (props: Props) => {
    const { title, items, id } = props
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    return (
            <Col span={24}>
              <Card id={id} title={title} bordered={false}>
                {items?.map((item, index) => {
                  if(item) return (
                       <div key={index} className={style.libox}> <div className={style.text} style={{fontSize: isMobile? 16 : 14}}> {item}</div></div>
                  );
                })}
              </Card>
            </Col>
    )
}


