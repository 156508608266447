import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface XIcon {
    className?: string
    color?: string
    style?: CSSProperties
    height?: number
    width?: number
}

export const XIcon = (props: XIcon) => {
    return (
        <Icon
            component={() => (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={props.width || '22'}
                    height={props.height || '22'}
                    viewBox="0 0 22 22"
                    fill={props.color ? props.color : 'var(--neutral-13)'}
                >
                    <path d="M12.7067 9.59055L19.385 1.82764H17.8024L12.0037 8.56807L7.3723 1.82764H2.03052L9.03412 12.0204L2.03052 20.161H3.61313L9.73672 13.0428L14.6278 20.161H19.9696L12.7063 9.59055H12.7067ZM10.5391 12.1102L9.8295 11.0952L4.18337 3.01901H6.61418L11.1707 9.53675L11.8803 10.5517L17.8032 19.0238H15.3724L10.5391 12.1105V12.1102Z" />
                </svg>
            )}
            {...props}
        />
    )
}
