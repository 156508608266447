import React from 'react'
import { Alert, Skeleton, Divider, Card, Row, Col, Avatar } from 'antd'
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout'
import useBreakpoints from '@hooks/useBreakpoints'
import { useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import { HeaderSkeleton } from './components/HeaderSkeleton/HeaderSkeleton'

interface IProps {
    error?: boolean
    mentors?: boolean
}

// TODO: Accept number prop to determine how many Skeleton items to have
export const EntityScreenSkeleton = (props: IProps) => {
    const { error, mentors } = props

    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme === 'dark'
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const isSmall = breakpoints.isSmall

    const errorAlert = error ? <Alert type="error" message="An Error occcured fetching data, please try again..." className="listings-skeleton__alert" /> : null
    return (
        <div className="listings-skeleton">
            {errorAlert}

            <ContentLayout
                left={
                    <div style={{ display: 'grid', rowGap: isMobile ? 16 : 24 }}>
                        <HeaderSkeleton breadcrumb />
                        <Card bordered={false} title={<Skeleton title active paragraph={null} style={{ maxWidth: 120 }} />}>
                            <Row>
                                <Skeleton title active paragraph={{ rows: 2 }} />
                            </Row>
                        </Card>
                        {mentors && (
                            <Card bordered={false} title={<Skeleton title active paragraph={null} style={{ maxWidth: 120 }} />}>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <Card bordered style={{ height: 200 }} bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Skeleton.Avatar active size={80} shape={'circle'} style={{marginBottom: 16}} />
                                            <Skeleton title active paragraph={null} style={{ maxWidth: '80%' }} />
                                        </Card>
                                    </Col>

                                    <Col span={8}>
                                        <Card bordered style={{ height: 200 }} bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Skeleton.Avatar active size={80} shape={'circle'} style={{marginBottom: 16}}/>
                                            <Skeleton title active paragraph={null} style={{ maxWidth: '80%' }} />
                                        </Card>
                                    </Col>

                                    <Col span={8}>
                                        <Card bordered style={{ height: 200 }} bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Skeleton.Avatar active size={80} shape={'circle'} style={{marginBottom: 16}}/>
                                            <Skeleton title active paragraph={null} style={{ maxWidth: '80%' }} />
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        )}
                    </div>
                }
                right={
                    <Row gutter={[isSmall ? 0 : 16, isMobile ? 16 : 24]}>
                        <Col span={24}>
                            <Card bordered={false} title={<Skeleton title active paragraph={null} style={{ maxWidth: 120 }} />}>
                                <Skeleton title={false} active paragraph={{ rows: 5 }} />
                                <Skeleton title={false} active paragraph={{ rows: 5 }} />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card bordered={false} title={<Skeleton title active paragraph={null} style={{ maxWidth: 120 }} />}>
                                <Skeleton title={false} active paragraph={{ rows: 5 }} />
                                <Skeleton title={false} active paragraph={{ rows: 5 }} />
                            </Card>
                        </Col>
                    </Row>
                }
            />
        </div>
    )
}
