// Libraries

// React
import React, { ReactNode, createContext } from 'react'

// Antd
import {} from 'antd'
import { WebSocketHook } from 'react-use-websocket/dist/lib/types'
import useWebSocket from 'react-use-websocket'
import { useAppSelector } from '@hooks/useStore'
import { NotificationHistoryView } from '@api/model/requests/Notification'

// MARK: - Definition

namespace SocketComponent {
    export interface Props {
        children: ReactNode
    }
}

// MARK: - Implementation

export const SocketContext = createContext<WebSocketHook>(null)
export const NotificationContext = createContext<number>(null)

const SocketComponent: React.FC<SocketComponent.Props> = (props: SocketComponent.Props) => {
    const currentToken = useAppSelector((state) => state.auth.authToken)
    const deviceId = useAppSelector((state) => state.auth.analyticsId)

    const general = useAppSelector((state) => state.notification.general)
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity)
    const notifications = general.aggregate?.[currentCommunity]?.notifications?.newly + general.aggregate?.[currentCommunity]?.messages?.newly

    const socket = useWebSocket(WS_DOMAIN, {
        queryParams: {
            auth: currentToken,
            device: deviceId,
        },
        onOpen: () => {},
        onMessage: () => {},
        onError: () => {},
        shouldReconnect: () => {
            return true
        },
        share: true,
        retryOnError: true,
        reconnectAttempts: 5,
        reconnectInterval: 15000,
    })

    return (
        <SocketContext.Provider value={socket}>
            <NotificationContext.Provider value={notifications}>{props.children}</NotificationContext.Provider>
        </SocketContext.Provider>
    )
}

export { SocketComponent }
