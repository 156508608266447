import React from 'react'
import { Alert, Skeleton, Divider, Card, Row, Col } from 'antd'

interface Props {
    error?: boolean
}

// TODO: Accept number prop to determine how many Skeleton items to have
export const PostSkeleton = ({ error = false }: Props) => {
    return (
        <Card bordered={false} style={{ marginTop: 16 }}>
            <div style={{ display: 'flex', alignContent: 'center', marginBottom: 8 }}>
                <Skeleton.Avatar active size={48} shape={'circle'} style={{ marginRight: 18 }} />
                <div style={{ display: 'grid', gap: 4 }}>
                    <Skeleton style={{ width: 150 }} title active paragraph={null} />
                    <Skeleton style={{ width: 110 }} title active paragraph={null} className={'small'} />
                    <Skeleton style={{ width: 80 }} title active paragraph={null} className={'small'} />
                </div>
            </div>
            <Skeleton style={{ width: 60, marginBottom: 16 }} title active paragraph={null} className={'tag-skeleton'} />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                <Skeleton style={{ marginBottom: 16 }} title active paragraph={null} className={'small'} />
                <Skeleton style={{ marginBottom: 16 }} title active paragraph={null} className={'small'} />
                <Skeleton title active paragraph={null} className={'small'} />
            </div>
        </Card>
    )
}
