// Libraries

// React
import React, { useContext } from 'react'

// Antd
import { EditOutlined } from '@ant-design/icons'
import { Button, Card, Form } from 'antd'
import { CreatePostContext } from '../../../../../PostCreateProvider'
import { Close } from '@icon-park/react'

// MARK: - Definition

namespace PreviewPoll {
    export interface Props {}
}

// MARK: - Implementation

const PreviewPoll: React.FC<PreviewPoll.Props> = (props: PreviewPoll.Props) => {
    const { form, screen, setScreen, editable } = useContext(CreatePostContext)
    const poll = Form.useWatch(['form', 'turingo', 'poll'], form)

    if (!poll) return <></>
    return (
        <div>
            <div style={{ position: 'relative', marginTop: 16 }}>
                {!editable && (
                    <Button
                        onClick={() => {
                            setScreen('poll_creation')
                        }}
                        shape="circle"
                        id="closeBtn"
                        icon={<EditOutlined />}
                        style={{ position: 'absolute', zIndex: 100, right: 48, top: 8 }}
                    ></Button>
                )}

                {!editable && (
                    <Button
                        onClick={() => {
                            form.setFieldValue(['form', 'turingo', 'kind'], 'basic')
                            form.setFieldValue(['form', 'turingo', 'poll'], undefined)
                        }}
                        shape="circle"
                        id="closeBtn"
                        icon={<Close style={{ display: 'flex', justifyContent: 'center' }} />}
                        style={{ position: 'absolute', zIndex: 100, right: 8, top: 8 }}
                    ></Button>
                )}
                <Card bodyStyle={{ backgroundColor: 'var(--neutral-3)' }}>
                    <p style={{ fontWeight: 500, textAlign: 'center', overflowWrap: 'break-word' }}>{poll.question}</p>

                    {poll.choices.map((item, index) => {
                        return (
                            <div
                                style={{
                                    marginBottom: 8,
                                    padding: 5,
                                    textAlign: 'center',
                                    backgroundColor: 'var(--poll_option_background)',
                                    border: `1px solid var(--border-button)`,
                                    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.016)',
                                    borderRadius: 4,
                                }}
                                key={`pollOptions#${index}`}
                            >
                                {item.answer}
                            </div>
                        )
                    })}
                </Card>
            </div>
        </div>
    )
}

export { PreviewPoll }
