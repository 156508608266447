import { useEffect } from 'react'
import { useAppDispatch } from '@hooks/useStore'
import { authSocial } from '@state/actions/index'
import { cleanAuth } from '@state/reducers/Auth/AuthReducer'
import { SocialButton } from '@components/buttons/social_button/SocialButton'
import { useOAuth } from '@hooks/oauth'
import { useLocation, useParams } from 'react-router'
import { CommunityRouteParams } from '@typings/routing/Router'

export type SocialKind = 'login' | 'signup'

interface SocialProps {
    kind: SocialKind
}

export const linkedInLogin = (onSuccess: (code) => void, onError: (error) => void) => {
    return useOAuth({
        provider: 'linkedin',
        clientId: OAUTH_LINKEDIN_ID,
        redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: onSuccess,
        onError: onError,
    })
}

export const GoogleLogin = (onSuccess: (code) => void, onError: (error) => void) => {
    return useOAuth({
        provider: 'google',
        clientId: OAUTH_GOOGLE_ID,
        redirectUri: `${window.location.origin}/google`,
        onSuccess: onSuccess,
        onError: onError,
    })
}

export const FacebookLogin = (onSuccess: (code) => void, onError: (error) => void) => {
    return useOAuth({
        provider: 'facebook',
        clientId: OAUTH_FACEBOOK_ID,
        redirectUri: `${window.location.origin}/facebook`,
        onSuccess: onSuccess,
        onError: onError,
    })
}

export const Social = (props: SocialProps) => {
    const { kind } = props
    const dispatch = useAppDispatch()
    const location = useLocation<{ token: string; email: string; name: string }>()
    const { cm_pk } = useParams<CommunityRouteParams>()

    useEffect(() => {
        dispatch(cleanAuth())
    }, [])

    const onSuccessLinkedIn = (code: string) => {
        void dispatch(
            authSocial({
                urlParams: { provider: 'linkedin' },
                bodyParams: {
                    token: code,
                    redirectUri: `${window.location.origin}/linkedin`,
                    action: location.state?.token
                        ? [
                              {
                                  kind: 'join:private',
                                  communityPk: cm_pk,
                                  invitationToken: location.state?.token,
                              },
                          ]
                        : undefined,
                },
            })
        )
    }

    const onSuccessFacebook = (code: string) => {
        void dispatch(
            authSocial({
                urlParams: { provider: 'facebook' },
                bodyParams: {
                    token: code,
                    redirectUri: `${window.location.origin}/facebook`,
                    action: location.state?.token
                        ? [
                              {
                                  kind: 'join:private',
                                  communityPk: cm_pk,
                                  invitationToken: location.state?.token,
                              },
                          ]
                        : undefined,
                },
            })
        )
    }

    const onSuccessGoogle = (code: string) => {
        void dispatch(
            authSocial({
                urlParams: { provider: 'google' },
                bodyParams: {
                    token: code,
                    redirectUri: `${window.location.origin}/google`,
                    action: location.state?.token
                        ? [
                              {
                                  kind: 'join:private',
                                  communityPk: cm_pk,
                                  invitationToken: location.state?.token,
                              },
                          ]
                        : undefined,
                },
            })
        )
    }

    const onError = (error: string) => {
        console.log(error)
    }

    const linkedIn = linkedInLogin(onSuccessLinkedIn, onError)
    const google = GoogleLogin(onSuccessGoogle, onError)
    const facebook = FacebookLogin(onSuccessFacebook, onError)
    return (
        <>
            <div>
                <SocialButton provider="google" kind={kind} onClick={google}></SocialButton>
                <SocialButton provider="facebook" kind={kind} onClick={facebook}></SocialButton>
                <SocialButton provider="linkedin" kind={kind} onClick={linkedIn}></SocialButton>
            </div>
        </>
    )
}
