import React from 'react'
import { Skeleton, Card, Row, Col } from 'antd'
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout'
import useBreakpoints from '@hooks/useBreakpoints'
import { PostSkeleton } from './components/PostSkeleton/PostSkeleton'

// TODO: Accept number prop to determine how many Skeleton items to have
export const PostScreenSkeleton = () => {
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const isSmall = breakpoints.isSmall

    return (
        <div className="listings-skeleton">
            <ContentLayout
                left={
                    <div style={{ display: 'grid', rowGap: isMobile ? 16 : 24 }}>
                        <PostSkeleton />
                    </div>
                }
                right={
                    <Row gutter={[isSmall ? 0 : 16, isMobile ? 16 : 24]}>
                        <Col span={24}>
                            <Card bordered={false} title={<Skeleton title active paragraph={{ rows: 0 }} />}>
                                <Skeleton title={false} active paragraph={{ rows: 3 }} />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card bordered={false} title={<Skeleton title active paragraph={{ rows: 0 }} />}>
                                <Skeleton title={false} active paragraph={{ rows: 3 }} />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card bordered={false} title={<Skeleton title active paragraph={{ rows: 0 }} />}>
                                <Skeleton title={false} active paragraph={{ rows: 3 }} />
                            </Card>
                        </Col>
                    </Row>
                }
            />
        </div>
    )
}
