/* Profile.tsx */

// Ant
import { Alert, Card } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import Paragraph from 'antd/lib/typography/Paragraph'

// React
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import Meta from 'react-meta-tags'

// Router
import { UserRouteParams } from 'src/typings/routing/Router'

// Components
import { CertificateListCard } from '@components/content/certificate_supplementary/CertificateListCard'
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout'
import useBreakpoints from '@hooks/useBreakpoints'
import { EntityScreenSkeleton } from '@components/skeletons/EntityScreenSkeleton'
import { communityMemberDetails, communityMemberSchema } from '@state/actions'
import { UserHeader } from '@components/content/entity/user_header/UserHeader'

// Localization
import strings from '@resources/localization'

// Socket
import { NotificationContext } from 'src/SocketComponent'

// Utils
import { dynamicTabName } from '@util/string/Functions'
import { hasError } from '@util/StateHandler'
import { communityOverrideRules } from '../../../service/override/OverrideRules'
import { useRequestState } from '@api/requests/AsyncRequests'
import { Category, CommunityMemberSchemaView } from '@api/model/responses/community/CommunityView'
import { PropertyPreview } from '../admin_panel/screens/profiles/components/PropertyPreview'
import { filterArchived, separateByPlacement } from '../admin_panel/screens/profiles/Utils'
import { UserModal } from '@components/content/entity/user_header/components/user_modal/UserModal'

namespace Profile {
    export interface Props {}
}

const Profile: React.FC<Profile.Props> = () => {
    const { cm_pk, u_pk } = useParams<UserRouteParams>()

    const user = useAppSelector((state) => state.user.items[u_pk])
    const [schema, setSchema] = useRequestState<CommunityMemberSchemaView>()

    const dispatch = useAppDispatch()
    const notifications = useContext(NotificationContext)

    useEffect(() => {
        void dispatch(communityMemberDetails({ urlParams: { cm_pk, u_pk } }))
        void dispatch(
            communityMemberSchema({
                urlParams: { cm_pk },
                searchParams: { archived: false },
                options: { redux: { stateHandler: setSchema, retrieveData: true } },
            })
        )
    }, [])

    const location = useLocation<{ from: string }>()
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const isSmall = breakpoints.isSmall
    // MARK: - Edit user methods

    const [visibleEditModal, setVisibleEditModal] = useState(false)
    const openEditModal = () => {
        setVisibleEditModal(true)
    }
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)
    const categoryHasValue = (category: Category) => {
        return category.properties.some((p) => user?.item?.memberProperties?.[p.id]?.value)
    }

    if (!user || user.state?.loading || !schema || schema.loading || !schema.data) {
        return <EntityScreenSkeleton />
    } else if (hasError([user.state])) {
        return <EntityScreenSkeleton error />
    } else {
        if (user.item) {
            const schemaByPlacement = separateByPlacement(filterArchived(schema.data.categories))
            const primeryCategory = schema.data.categories.find((c) => c.placement == 'primary')
            const isPrivate = user.item.visibility == 'private'
            const isLoggedUser = currentEntity?.publicKey == u_pk
            return (
                <>
                    <Meta>
                        <title>{dynamicTabName(strings.screens.profile.browserTitle, notifications)}</title>
                        <meta name="description" content={strings.screens.profile.browserDescription} />
                    </Meta>
                    <ContentLayout
                        left={
                            <div style={{ display: 'grid', rowGap: isMobile ? 16 : 24, gridTemplateColumns: 'repeat(1, minmax(0, 1fr))' }}>
                                {visibleEditModal && <UserModal schema={schema.data} setVisibility={setVisibleEditModal} open={visibleEditModal} />}

                                <Card bordered={false} bodyStyle={{ padding: 0 }}>
                                    <UserHeader
                                        openEditModal={openEditModal}
                                        showBreadcrumb
                                        from={
                                            location?.state?.from.includes('directory')
                                                ? 'directory'
                                                : location?.state?.from.includes('members')
                                                ? 'members'
                                                : undefined
                                        }
                                    ></UserHeader>
                                    <div style={{ padding: isSmall ? '0px 16px 16px 16px' : '0px 24px 16px 24px' }}>
                                        {primeryCategory?.properties
                                            .sort((a, b) => Number(b.highlight) - Number(a.highlight))
                                            .map((property, index) => {
                                                return (
                                                    <div key={property.id} style={{ marginBottom: index < primeryCategory.properties.length - 1 ? 16 : 0 }}>
                                                        {property && (
                                                            <PropertyPreview ownership={isLoggedUser ? 'self' : 'other'} user={user.item} property={property} />
                                                        )}
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </Card>

                                {schemaByPlacement.center?.map((category) => {
                                    if (category.placement != 'primary' && (isLoggedUser || categoryHasValue(category))) {
                                        return (
                                            <Card key={category.id} title={category.title}>
                                                {isLoggedUser && category.properties.length == 0 && (
                                                    <Paragraph className="turingo-text" style={{ color: 'var(--secondary-text)' }}>
                                                        {strings.screens.profile.notAdded}
                                                    </Paragraph>
                                                )}
                                                {category.properties.map((property, index) => {
                                                    if (property) {
                                                        return (
                                                            <PropertyPreview
                                                                key={property.id}
                                                                ownership={isLoggedUser ? 'self' : 'other'}
                                                                user={user.item}
                                                                style={{ marginBottom: index < category.properties.length - 1 ? 16 : 0 }}
                                                                property={property}
                                                            />
                                                        )
                                                    }
                                                })}
                                            </Card>
                                        )
                                    }
                                })}

                                {isMobile && schemaByPlacement.right?.map((category) => {
                                        if (isLoggedUser || categoryHasValue(category)) {
                                            return (
                                                <Card key={category.id} title={category.title}>
                                                    {isLoggedUser && category.properties.length == 0 && (
                                                        <Paragraph className="turingo-text" style={{ color: 'var(--secondary-text)' }}>
                                                            {strings.screens.profile.notAdded}
                                                        </Paragraph>
                                                    )}

                                                    {category.properties.map((property, index) => {
                                                        if (property) {
                                                            return (
                                                                <PropertyPreview
                                                                    key={property.id}
                                                                    ownership={isLoggedUser ? 'self' : 'other'}
                                                                    user={user.item}
                                                                    style={{ marginBottom: index < category.properties.length - 1 ? 16 : 0 }}
                                                                    property={property}
                                                                />
                                                            )
                                                        }
                                                    })}
                                                </Card>
                                            )
                                        }
                                    })}

                                {isPrivate && !isLoggedUser && currentEntity && (
                                    <Alert showIcon={true} message={'Esta cuenta es privada'} icon={<LockOutlined />} />
                                )}
                                {!isPrivate || !currentEntity || isLoggedUser ? (
                                    <>{isMobile && <CertificateListCard isPrivate={isPrivate} userPk={u_pk}></CertificateListCard>}</>
                                ) : null}
                            </div>
                        }
                        right={
                            !isPrivate || !currentEntity || isLoggedUser ? (
                                <div style={{ display: 'grid', rowGap: isMobile ? 16 : 24, columnGap: isSmall ? 0 : 16 }}>
                                    {schemaByPlacement.right?.map((category) => {
                                        if (isLoggedUser || categoryHasValue(category)) {
                                            return (
                                                <Card key={category.id} title={category.title}>
                                                    {isLoggedUser && category.properties.length == 0 && (
                                                        <Paragraph className="turingo-text" style={{ color: 'var(--secondary-text)' }}>
                                                            {strings.screens.profile.notAdded}
                                                        </Paragraph>
                                                    )}

                                                    {category.properties.map((property, index) => {
                                                        if (property) {
                                                            return (
                                                                <PropertyPreview
                                                                    key={property.id}
                                                                    ownership={isLoggedUser ? 'self' : 'other'}
                                                                    user={user.item}
                                                                    style={{ marginBottom: index < category.properties.length - 1 ? 16 : 0 }}
                                                                    property={property}
                                                                />
                                                            )
                                                        }
                                                    })}
                                                </Card>
                                            )
                                        }
                                    })}
                                    {!isMobile && <CertificateListCard isPrivate={isPrivate} userPk={u_pk}></CertificateListCard>}
                                </div>
                            ) : null
                        }
                    />
                </>
            )
        } else {
            return <></>
        }
    }
}

export { Profile }
