// Libraries

// React
import React, { useContext } from 'react'

// Antd
import { Alert, Button, Form, Input, Select, Tooltip } from 'antd'
import strings from '@resources/localization'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { CreatePostContext } from '../../PostCreateProvider'

const { Option } = Select

// MARK: - Definition

namespace PollScreen {
    export interface Props {}
}

// MARK: - Implementation

const PollScreen: React.FC<PollScreen.Props> = (props: PollScreen.Props) => {
    const { pollForm, setScreen, form } = useContext(CreatePostContext)
    const onFinish = (values) => {
        form.setFieldValue(['form','turingo', 'kind'], 'poll')
        form.setFieldValue(['form','turingo', 'poll'], {
            ...values,
            choices: values.choices.map((choice, index) => {
                return { choiceId: index, answer: choice }
            }),
        })
        setScreen('creation')
    }
    return (
        <div>
            <Form
                id="pollForm"
                form={pollForm}
                layout="vertical"
                initialValues={{
                    question: '',
                    choices: ['', ''],
                    dateFinish: 'week',
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name={['question']}
                    label={strings.screens.boards.discussion.post.kind.poll.form.question.title}
                    rules={[{ required: true, message: strings.screens.boards.discussion.post.kind.poll.form.question.validations.required }]}
                >
                    <Input placeholder={strings.screens.boards.discussion.post.kind.poll.form.question.placeholder} />
                </Form.Item>

                <Form.List
                    name={['choices']}
                    rules={[
                        {
                            validator: async (_, names: string[]) => {
                                if (!names || names.length < 2) {
                                    return Promise.reject(new Error('At least 2 options'))
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    className="pollForm"
                                    label={strings.screens.boards.discussion.post.kind.poll.form.option[index].title}
                                    required={index > 1 ? false : true}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                message: strings.screens.boards.discussion.post.kind.poll.form.option_validations.required,
                                            },
                                        ]}
                                        style={{ flexGrow: 1 }}
                                        noStyle
                                    >
                                        <Input
                                            placeholder={strings.screens.boards.discussion.post.kind.poll.form.option[index].placeholder}
                                            showCount
                                            maxLength={30}
                                        />
                                    </Form.Item>
                                    {index > 1 ? (
                                        <Tooltip title={strings.general.delete}>
                                            <Button style={{ marginLeft: 16 }} shape="circle" icon={<DeleteOutlined />} onClick={() => remove(field.name)} />
                                        </Tooltip>
                                    ) : null}
                                    {index == fields.length - 1 && fields.length != 4 && (
                                        <Button style={{ marginLeft: 16 }} shape="circle" onClick={() => add()} icon={<PlusOutlined />}></Button>
                                    )}
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item
                    name={['dateFinish']}
                    label={strings.screens.boards.discussion.post.kind.poll.form.duration.title}
                    rules={[{ required: true, message: 'Ingresa una duracion' }]}
                >
                    <Select defaultValue="week">
                        <Option value="day">{strings.screens.boards.discussion.post.kind.poll.form.duration.options.day}</Option>
                        <Option value="3day">{strings.screens.boards.discussion.post.kind.poll.form.duration.options['3day']}</Option>
                        <Option value="week">{strings.screens.boards.discussion.post.kind.poll.form.duration.options.week}</Option>
                        <Option value="2week">{strings.screens.boards.discussion.post.kind.poll.form.duration.options['2week']}</Option>
                    </Select>
                </Form.Item>

                <Alert className="blue" type="info" message={strings.screens.boards.discussion.post.kind.poll.form.description}></Alert>
            </Form>
        </div>
    )
}

export { PollScreen }
