import { LinkData } from '@api/model/responses/post/PostView'
import { Colors } from '@util/color/Colors'
import { capitalizeFirstLetter } from '@util/string/Functions'
import { Card } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import React from 'react'
import { Link } from 'react-router-dom'
import style from './PreviewCard.scss'

interface Props {
    data: LinkData
    isClickeable?: boolean
}

const PreviewContent = (props: Props) => {
    const { data, isClickeable } = props

    return (
        <div className={isClickeable ? style.previewCard : null}>
            {data.type != 'noOG' && data.image?.url && !data.image?.url.includes('lookaside.fbsbx.com') && (
                <div style={{ position: 'relative', paddingBottom: '66.6%' }}>
                    <div
                        style={{
                            position: 'absolute',
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${data.image?.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />

                    {/* <div
                    style={{
                        minHeight: 270,
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${data.image?.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                /> */}
                </div>
            )}

            <div style={{ padding: '8px 16px', backgroundColor: Colors('POSTED_POLL_BACKGROUND') }}>
                <div className={style.description}>{new URL(data.url).host.replace('www.', '')}</div>
                <div className={style.title}>{data.title ? data.title : new URL(data.url).host}</div>

                <Paragraph
                    className={style.description}
                    ellipsis={{
                        rows: 2,
                    }}
                >
                    {data.description}
                </Paragraph>
            </div>
        </div>
    )
}

export const PreviewCard = (props: Props) => {
    const { data, isClickeable } = props
    if (isClickeable)
        return (
            <a href={data.url} target={'_blank'} rel="noreferrer">
                <PreviewContent {...props}></PreviewContent>
            </a>
        )
    else return <PreviewContent {...props}></PreviewContent>
}
