// Modules
import React, { useContext, useEffect, useState } from 'react'

import { PreviewCard } from '@components/content/posts/components/preview_card/PreviewCard'
import { LinkData } from '@api/model/responses/post/PostView'
import { Button, Form } from 'antd'
import { Close } from '@icon-park/react'
import linkifyIt, { LinkifyIt } from 'linkify-it'
import tlds from 'tlds'
import { debounce } from 'lodash'
import { API } from '@api/API'
import { CreatePostContext } from '@components/content/posts/post_modal/PostCreateProvider'

const linkify: LinkifyIt = linkifyIt().tlds(tlds)

interface Props {}

export const PreviewLink = (props: Props) => {
    const { form, screen, setScreen } = useContext(CreatePostContext)

    const content = Form.useWatch(['form', 'turingo', 'content'], form)
    const kind = Form.useWatch(['form', 'turingo', 'kind'], form)
    const links = Form.useWatch(['form', 'turingo', 'links'], form)

    const [previewData, setPreviewData] = useState<LinkData>()
    const [urls, setUrls] = useState<Array<{ url: string }>>([])

    const fetchLink = async (link: string) => {
        try {
            const preview_text = await API.linkPreview({ searchParams: { url: encodeURI(link) } })
            setPreviewData(preview_text.data)
            form.setFieldValue(['form', 'turingo', 'kind'], 'preview')
            form.setFieldValue(['form', 'turingo', 'links'], [preview_text.data.id])
        } catch (error) {
            form.setFieldValue(['form', 'turingo', 'kind'], 'basic')
            form.setFieldValue(['form', 'turingo', 'links'], undefined)
            setPreviewData(undefined)
        }
    }
    useEffect(() => {
        const links = content ? linkify.match(content) : undefined
        const isChange =
            urls?.length > 0
                ? links &&
                  links.length > 0 &&
                  ((links as Array<{ url: string }>)[links.length - 1].url != urls[urls.length - 1].url ||
                      (links as Array<{ url: string }>)[links.length - 1].url != previewData?.url)
                : links && links.length > 0
        if (isChange) {
            debounce(function () {
                if (kind == 'basic' || kind == 'preview') {
                    setUrls(links)
                    void fetchLink((links as Array<{ url: string }>)[links.length - 1].url)
                }
            }, 2000)()
        }
    }, [content])

    if (!previewData || kind != 'preview') {
        return <></>
    } else {
        return (
            <div style={{ position: 'relative', marginTop: 16 }}>
                <Button
                    onClick={() => form.setFieldValue(['form', 'turingo', 'kind'], 'basic')}
                    shape="circle"
                    icon={<Close style={{ display: 'flex', justifyContent: 'center' }} />}
                    style={{ position: 'absolute', zIndex: 100, right: 8, top: 8 }}
                ></Button>
                <PreviewCard data={previewData} isClickeable={false} />
            </div>
        )
    }
}
