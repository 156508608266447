import { createSlice } from '@reduxjs/toolkit'
import {
    commentCreate,
    commentDelete,
    commentEdit,
    commentList,
    commentSection,
    postCreate,
    postDetails,
    postAdminDetails,
    postList,
    postReaction,
    postReactionDelete,
    replyCreate,
    replyList,
    communityPosts,
    postDelete,
    postEdit,
    postPin,
    postUnpin,
    postVote,
    rateLessonCreate,
    postReactionList,
    postOrder,
    postManyReaction,
    quizCreate,
    postAttachmentCreate,
    postLinkCreate,
    postAttachmentDelete,
    postLinkDelete,
    postLinkEdit,
    quizEdit,
    postRefresh,
} from '@state/actions'
import { PostView } from '@api/model/responses/post/PostView'
import { PostCommentView } from '@api/model/responses/post/PostCommentView'
import { ListItem } from '@state/types/ReducerTypes'

namespace postsSlice {
    export interface State {
        all: {
            [b_pk: string]: ListItem<PostView, PostCommentView>
        }
        detail: {
            [b_pk: string]: ListItem<PostView, PostCommentView>
        }
    }
}

const defaultState: postsSlice.State = {
    all: {},
    detail: {},
}

const postsSlice = createSlice({
    name: 'posts',
    initialState: defaultState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postDetails.pending, (state, { meta }) => {
            if (!state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk] = {
                    items: {
                        [meta.arg.urlParams.p_pk]: {
                            item: undefined,
                            state: {
                                loading: true,
                                error: undefined,
                            },
                            childrens: {
                                items: {},
                                listState: { loading: false, error: undefined },
                            },
                        },
                    },
                }
            } else {
                state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                    item: undefined,
                    state: {
                        loading: true,
                        error: undefined,
                    },
                    childrens: {
                        items: {},
                        listState: { loading: false, error: undefined },
                    },
                }
            }
        })

        builder.addCase(postDetails.rejected, (state, { meta, payload }) => {
            state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: undefined,
                state: {
                    loading: false,
                    error: payload,
                },
                childrens: {
                    items: {},
                    listState: { loading: false, error: undefined },
                },
            }
        })

        builder.addCase(postDetails.fulfilled, (state, { payload, meta }) => {
            state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: payload?.data,
                state: {
                    loading: false,
                    error: undefined,
                },
                childrens: {
                    items: {},
                    listState: { loading: false, error: undefined },
                },
            }
        })

        builder.addCase(postRefresh.pending, (state, { meta }) => {
            if (state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items?.[meta.arg.urlParams.p_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                    state: {
                        loading: false,
                        editing: true,
                        error: undefined,
                    },
                }
            }
        })

        builder.addCase(postRefresh.fulfilled, (state, { payload, meta }) => {
            if (state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items?.[meta.arg.urlParams.p_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                    item: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                        interactions: payload.data.interactions,
                    },
                    state: {
                        loading: false,
                        editing: false,
                        error: undefined,
                    },
                }
            }
        })

        builder.addCase(postAdminDetails.pending, (state, { meta }) => {
            if (!state.detail[meta.arg.urlParams.b_pk]) {
                state.detail[meta.arg.urlParams.b_pk] = {
                    items: {
                        [meta.arg.urlParams.p_pk]: {
                            item: undefined,
                            state: {
                                loading: true,
                                error: undefined,
                            },
                            childrens: {
                                items: {},
                                listState: { loading: false, error: undefined },
                            },
                        },
                    },
                }
            } else {
                state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                    item: undefined,
                    state: {
                        loading: true,
                        error: undefined,
                    },
                    childrens: {
                        items: {},
                        listState: { loading: false, error: undefined },
                    },
                }
            }
        })

        builder.addCase(postAdminDetails.rejected, (state, { meta, payload }) => {
            state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: undefined,
                state: {
                    loading: false,
                    error: payload,
                },
                childrens: {
                    items: {},
                    listState: { loading: false, error: undefined },
                },
            }
        })

        builder.addCase(postAdminDetails.fulfilled, (state, { payload, meta }) => {
            state.detail[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: payload?.data as PostView,
                state: {
                    loading: false,
                    error: undefined,
                },
                childrens: {
                    items: {},
                    listState: { loading: false, error: undefined },
                },
            }
        })

        builder.addCase(postReactionList.fulfilled, (state, { payload, meta }) => {
            if (state.all?.[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items?.[meta.arg.urlParams.target_pk]) {
                state.all[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk] = {
                    ...state.all[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk],
                    item: {
                        ...state.all[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item,
                        ...(meta.arg.searchParams.kind == 'react' && payload.total && { totalReactions: payload.total }),
                        ...(meta.arg.searchParams.kind == 'pin_read' && payload.total && { totalReads: payload.total }),
                        ...(meta.arg.searchParams.kind == 'pin_view' && payload.total && { totalViews: payload.total }),
                    },
                }
            }

            if (state.detail?.[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items?.[meta.arg.urlParams.target_pk]) {
                state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk] = {
                    ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk],
                    item: {
                        ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item,
                        ...(meta.arg.searchParams.kind == 'react' && payload.total && { totalReactions: payload.total }),
                        ...(meta.arg.searchParams.kind == 'pin_read' && payload.total && { totalReads: payload.total }),
                        ...(meta.arg.searchParams.kind == 'pin_view' && payload.total && { totalViews: payload.total }),
                    },
                }
            }
        })

        builder.addCase(postList.pending, (state, { meta }) => {
            if (meta.arg.headerParams?.page && meta.arg.headerParams?.page != 'first') {
                state.all[meta.arg.urlParams.b_pk] = {
                    items: state.all[meta.arg.urlParams.b_pk] ? state.all[meta.arg.urlParams.b_pk].items : {},
                    listState: {
                        loading: true,
                        ...(meta.arg.searchParams?.topic && { topic: meta.arg.searchParams?.topic }),
                    },
                    saved: {},
                }
            } else {
                state.all[meta.arg.urlParams.b_pk] = {
                    items: {},
                    listState: {
                        loading: true,
                    },
                    saved: {},
                }
            }
        })

        builder.addCase(postList.rejected, (state, { meta, payload }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                items: {},
                listState: {
                    loading: false,
                    error: payload,
                    ...(meta.arg.searchParams?.topic && { topic: meta.arg.searchParams?.topic }),
                },
                saved: {},
            }
        })

        builder.addCase(postOrder.fulfilled, (state, { payload, meta }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                items: {
                    ...payload.data.posts.reduce(
                        (a, v) => ({ ...a, [v.publicKey]: { item: state.all[meta.arg.urlParams.b_pk].items[v.publicKey].item, loading: false } }),
                        {}
                    ),
                },
                listState: {
                    loading: false,
                },
                saved: {},
            }
        })

        builder.addCase(postOrder.pending, (state, { meta }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                items: {
                    ...state.all[meta.arg.urlParams.b_pk].items,
                },
                listState: {
                    loading: false,
                },
                saved: {},
            }
        })

        builder.addCase(postOrder.rejected, (state, { meta, payload }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                items: {
                    ...state.all[meta.arg.urlParams.b_pk].items,
                },
                listState: {
                    loading: false,
                    error: payload,
                },
                saved: {},
            }
        })

        builder.addCase(postList.fulfilled, (state, { payload, meta }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                ...state.all[meta.arg.urlParams.b_pk],
                items: {
                    ...state.all[meta.arg.urlParams.b_pk].items,
                    ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                },
                listState: { loading: false, pagination: payload.pagination, ...(meta.arg.searchParams?.topic && { topic: meta.arg.searchParams?.topic }) },
                saved: {},
            }
        })

        builder.addCase(communityPosts.pending, (state, { meta }) => {
            if (meta.arg.headerParams?.page && meta.arg.headerParams?.page != 'first') {
                state.all['feed'] = {
                    items: state.all['feed'] ? state.all['feed'].items : {},
                    listState: {
                        loading: true,
                        ...(meta.arg.searchParams?.topic && { topic: meta.arg.searchParams?.topic }),
                    },
                    saved: {},
                }
            } else {
                state.all['feed'] = {
                    items: {},
                    listState: {
                        loading: true,
                        ...(meta.arg.searchParams?.topic && { topic: meta.arg.searchParams?.topic }),
                    },
                    saved: {},
                }
            }
        })

        builder.addCase(communityPosts.rejected, (state, { meta, payload }) => {
            state.all['feed'] = {
                items: {},
                listState: {
                    loading: false,
                    error: payload,
                    ...(meta.arg.searchParams?.topic && { topic: meta.arg.searchParams?.topic }),
                },
                saved: {},
            }
        })

        builder.addCase(communityPosts.fulfilled, (state, { payload, meta }) => {
            state.all['feed'] = {
                ...state.all['feed'],
                items: {
                    ...state.all['feed'].items,
                    ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                },
                listState: { loading: false, pagination: payload.pagination, ...(meta.arg.searchParams?.topic && { topic: meta.arg.searchParams?.topic }) },
                saved: {},
            }
        })

        builder.addCase(commentList.pending, (state, { meta }) => {
            if (!state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) {
                state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk] = {
                    items: {
                        [meta.arg.urlParams.p_pk]: {
                            item: undefined,
                            state: {
                                loading: true,
                                error: undefined,
                            },
                            childrens: {
                                ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens,
                                listState: { loading: true },
                            },
                        },
                    },
                }
            } else {
                if (!state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]) {
                    state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                        item: undefined,
                        state: {
                            loading: true,
                            error: undefined,
                        },
                        childrens: {
                            ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens,
                            listState: { loading: true },
                        },
                    }
                } else {
                    state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                        ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                        childrens: {
                            ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens,
                            listState: { loading: true },
                        },
                    }
                }
            }
        })

        builder.addCase(commentList.rejected, (state, { meta, payload }) => {
            state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                childrens: {
                    ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens,
                    listState: { loading: true, error: payload },
                },
            }
        })

        builder.addCase(commentList.fulfilled, (state, { payload, meta }) => {
            state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                childrens: {
                    items: {
                        ...state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens.items,
                        ...payload.data.reduce((a, v) => ({ ...a, [v.publicKey]: { item: v, loading: false } }), {}),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(commentSection.pending, (state, { meta }) => {
            if (!state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk] = {
                    items: {
                        [meta.arg.urlParams.p_pk]: {
                            item: undefined,
                            state: {
                                loading: false,
                                error: undefined,
                            },

                            childrens: {
                                items: {
                                    ...(state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items[
                                        meta.arg.urlParams.p_pk
                                    ].childrens?.items && {
                                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items[
                                            meta.arg.urlParams.p_pk
                                        ].childrens.items,
                                    }),
                                },
                                listState: { loading: true },
                            },
                        },
                    },
                }
            } else {
                if (!state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]) {
                    state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                        item: undefined,
                        state: {
                            loading: true,
                            error: undefined,
                        },
                        childrens: {
                            items: {
                                ...(state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                                    meta.arg.urlParams.p_pk
                                ].childrens?.items && {
                                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                                        meta.arg.urlParams.p_pk
                                    ].childrens.items,
                                }),
                            },
                            listState: { loading: true },
                        },
                    }
                } else {
                    state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                        childrens: {
                            items: {
                                ...(state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                                    meta.arg.urlParams.p_pk
                                ].childrens?.items && {
                                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                                        meta.arg.urlParams.p_pk
                                    ].childrens.items,
                                }),
                            },
                            listState: { loading: true },
                        },
                    }
                }
            }
        })

        builder.addCase(commentSection.rejected, (state, { meta, payload }) => {
            if (state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items[meta.arg.urlParams.p_pk]) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items[meta.arg.urlParams.p_pk],
                    childrens: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items[meta.arg.urlParams.p_pk]
                            .childrens,
                        listState: { loading: true, error: payload },
                    },
                }
            }
        })

        builder.addCase(commentSection.fulfilled, (state, { payload, meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                childrens: {
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]
                            ?.childrens.items,
                        ...payload.data.reduce(
                            (a, v) => ({
                                ...a,
                                [v.publicKey]: {
                                    item: v,
                                    loading: false,
                                    childrens: { items: {}, listState: { loading: false }, createState: { loading: false } },
                                },
                            }),
                            {}
                        ),
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(commentCreate.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                childrens: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens,
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]
                            .childrens.items,
                    },
                    createState: { loading: true },
                },
            }
        })

        builder.addCase(commentCreate.rejected, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                childrens: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens,
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]
                            .childrens.items,
                    },
                    createState: { loading: false, error: payload },
                },
            }
        })

        builder.addCase(commentCreate.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk],
                item: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                    totalComments:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item
                            .totalComments + 1,
                },
                childrens: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].childrens,
                    items: {
                        [payload.data.publicKey]: {
                            childrens: {
                                items: undefined,
                                listState: {
                                    loading: false,
                                },
                                createState: {
                                    loading: false,
                                },
                            },
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        },
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]
                            .childrens.items,
                    },
                    createState: { loading: false },
                },
            }
        })

        builder.addCase(commentDelete.pending, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.listState = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                        .childrens.listState,
                    loading: true,
                }
            } else if (meta.arg.extraParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: true,
                }
            }
        })

        builder.addCase(commentDelete.rejected, (state, { meta, payload }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.listState = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                        .childrens.listState,
                    loading: false,
                    error: payload,
                }
            } else if (meta.arg.extraParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: false,
                    error: payload,
                }
            }
        })

        builder.addCase(commentDelete.fulfilled, (state, { meta }) => {
            let numberOfReplies = 0
            if (meta.arg.extraParams.parents.length == 1) {
                // Is a comment (have one parent and any number of replies)
                numberOfReplies +=
                    state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.c_pk].item.totalComments
            }
            // Is a comment
            delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                .childrens.items[meta.arg.urlParams.c_pk]
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                meta.arg.extraParams.parents[0]
            ].item.totalComments -= 1 + numberOfReplies
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                meta.arg.extraParams.parents[0]
            ].childrens.listState = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.listState,
                loading: false,
            }
            if (meta.arg.extraParams.parents.length == 2) {
                // Is a reply (have two parent)
                delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk]

                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].item.totalComments -= 1
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: false,
                }
            }
        })

        builder.addCase(postDelete.pending, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 0) {
                // Is a post (have zero parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState,
                    loading: true,
                }
            } else if (meta.arg.extraParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.listState = {
                    loading: true,
                }
            } else if (meta.arg.extraParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: true,
                }
            }
        })

        builder.addCase(postDelete.rejected, (state, { meta, payload }) => {
            if (meta.arg.extraParams.parents.length == 0) {
                // Is a post (have zero parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState,
                    loading: false,
                    error: payload,
                }
            } else if (meta.arg.extraParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.listState = {
                    loading: false,
                    error: payload,
                }
            } else if (meta.arg.extraParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: false,
                    error: payload,
                }
            }
        })

        builder.addCase(postDelete.fulfilled, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 0) {
                // Is a post (have zero parent)
                delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]

                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].listState,
                    loading: false,
                }
            } else if (meta.arg.extraParams.parents.length == 1) {
                // Is a comment (have one parent)
                delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.urlParams.target_pk]

                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].item.totalComments -= 1
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.listState = {
                    loading: false,
                }
            } else if (meta.arg.extraParams.parents.length == 2) {
                // Is a reply (have two parent)
                delete state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                    .childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk]

                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].item.totalComments -= 1
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].childrens.listState = {
                    loading: false,
                }
            }
        })

        builder.addCase(commentEdit.pending, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.urlParams.c_pk] = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.c_pk],
                    state: {
                        loading: true,
                    },
                }
            } else if (meta.arg.extraParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk] = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                        .childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk],
                    state: {
                        loading: true,
                    },
                }
            }
        })

        builder.addCase(commentEdit.rejected, (state, { meta }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.urlParams.c_pk] = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.c_pk],
                    state: {
                        loading: false,
                    },
                }
            } else if (meta.arg.extraParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk] = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.parents[0]]
                        .childrens.items[meta.arg.extraParams.parents[1]].childrens.items[meta.arg.urlParams.c_pk],
                    state: {
                        loading: false,
                    },
                }
            }
        })

        builder.addCase(commentEdit.fulfilled, (state, { meta, payload }) => {
            if (meta.arg.extraParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens = {
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                            meta.arg.extraParams.parents[0]
                        ].childrens.items,
                        [payload.data.publicKey]: {
                            ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                                meta.arg.extraParams.parents[0]
                            ].childrens.items[payload.data.publicKey],
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        },
                    },
                    listState: { loading: false },
                }
            } else if (meta.arg.extraParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.extraParams.parents[0]
                ].childrens.items[meta.arg.extraParams.parents[1]].childrens = {
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                            meta.arg.extraParams.parents[0]
                        ].childrens.items[meta.arg.extraParams.parents[1]].childrens.items,
                        [payload.data.publicKey]: {
                            ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                                meta.arg.extraParams.parents[0]
                            ].childrens.items[meta.arg.extraParams.parents[1]].childrens.items[payload.data.publicKey].item,
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        },
                    },
                    listState: { loading: false },
                }
            }
        })

        builder.addCase(postPin.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                state: {
                    loading: true,
                },
            }
        })

        builder.addCase(postPin.fulfilled, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items,
                [meta.arg.urlParams.p_pk]: {
                    item: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,

                        pin: meta.arg.bodyParams.mandatory ? 'mandatory' : 'normal',
                    },
                    state: {
                        loading: false,
                    },
                },
            }
        })

        builder.addCase(postUnpin.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                state: {
                    loading: true,
                },
            }
        })

        builder.addCase(postUnpin.fulfilled, (state, { meta }) => {
            if (meta.arg.extraParams.highlight) {
                delete state[meta.arg.extraParams.scope]['feed'].items[meta.arg.urlParams.p_pk]
            } else {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items,
                    [meta.arg.urlParams.p_pk]: {
                        item: {
                            ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk]
                                .item,
                            pin: undefined,
                            totalReads: 0,
                            totalViews: 0,
                        },
                        state: {
                            loading: false,
                        },
                    },
                }
            }
        })

        builder.addCase(postEdit.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                state: {
                    loading: false,
                    editing: true,
                },
            }
        })

        builder.addCase(postEdit.rejected, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk] = {
                item: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item,
                state: {
                    loading: false,
                    editing: false,
                },
            }
        })

        builder.addCase(postEdit.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items,
                [payload.data.publicKey]: {
                    item: {
                        ...payload.data,
                        board: state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[payload.data.publicKey]
                            .item.board,
                    },
                    state: {
                        loading: false,
                        editing: false,
                    },
                },
            }
        })

        builder.addCase(quizEdit.pending, (state, { meta }) => {
            state.all[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.q_pk] = {
                item: state.all[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.q_pk].item,
                state: {
                    loading: true,
                },
            }
        })

        builder.addCase(quizEdit.rejected, (state, { meta }) => {
            state.all[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.q_pk] = {
                item: state.all[meta.arg.urlParams.b_pk].items[meta.arg.urlParams.q_pk].item,
                state: {
                    loading: false,
                },
            }
        })

        builder.addCase(quizEdit.fulfilled, (state, { meta, payload }) => {
            state.all[meta.arg.urlParams.b_pk].items = {
                ...state.all[meta.arg.urlParams.b_pk].items,
                [payload.data.publicKey]: {
                    item: {
                        ...payload.data,
                    },
                    state: {
                        loading: false,
                    },
                },
            }
        })

        builder.addCase(postManyReaction.fulfilled, (state, { meta }) => {
            for (const reference of meta.arg.bodyParams.postReferences) {
                if (state[meta.arg.extraParams.scope]?.[meta.arg.extraParams.feed ? 'feed' : reference.boardPk]?.items?.[reference.postPk]) {
                    // Is a post
                    state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : reference.boardPk].items[reference.postPk].item = {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : reference.boardPk]?.items?.[reference.postPk]?.item,

                        totalViews: state[meta.arg.extraParams.scope]?.[meta.arg.extraParams.feed ? 'feed' : reference.boardPk]?.items?.[reference.postPk]?.item
                            ?.totalViews
                            ? state[meta.arg.extraParams.scope]?.[meta.arg.extraParams.feed ? 'feed' : reference.boardPk]?.items?.[reference.postPk].item
                                  .totalViews + 1
                            : 1,

                        interactions: {
                            ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : reference.boardPk].items?.[reference.postPk]?.item
                                ?.interactions,

                            pinView: true,
                        },
                    }
                }
            }
        })

        builder.addCase(postReaction.pending, (state, { meta }) => {
            if (!meta.arg.bodyParams.parents) {
                // Is a post
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item,

                    ...(meta.arg.bodyParams.kind == 'react' && {
                        totalReactions:
                            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]
                                .item.totalReactions + 1,
                    }),

                    ...(meta.arg.bodyParams.kind == 'pin_read' && {
                        totalReads: state[meta.arg.extraParams.scope]?.[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items?.[
                            meta.arg.urlParams.target_pk
                        ]?.item.totalReads
                            ? state[meta.arg.extraParams.scope]?.[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items?.[
                                  meta.arg.urlParams.target_pk
                              ].item.totalReads + 1
                            : 1,
                    }),
                    ...(meta.arg.bodyParams.kind == 'pin_view' && {
                        totalViews: state[meta.arg.extraParams.scope]?.[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items?.[
                            meta.arg.urlParams.target_pk
                        ]?.item?.totalViews
                            ? state[meta.arg.extraParams.scope]?.[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk]?.items?.[
                                  meta.arg.urlParams.target_pk
                              ].item.totalViews + 1
                            : 1,
                    }),
                    interactions: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items?.[meta.arg.urlParams.target_pk]
                            ?.item?.interactions,

                        ...(meta.arg.bodyParams.kind == 'react' && { react: meta.arg.bodyParams.content as 'like' }),
                        ...(meta.arg.bodyParams.kind == 'pin_read' && { pinRead: true }),
                        ...(meta.arg.bodyParams.kind == 'pin_view' && { pinView: true }),
                    },
                }
            } else if (meta.arg.bodyParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.bodyParams.parents[0]
                ].childrens.items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                            .childrens.items[meta.arg.urlParams.target_pk].item.totalReactions + 1,
                    reaction: {
                        content: meta.arg.bodyParams.content,
                    },
                }
            } else if (meta.arg.bodyParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.bodyParams.parents[0]
                ].childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                            .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item.totalReactions + 1,
                    reaction: {
                        content: meta.arg.bodyParams.content,
                    },
                }
            }
        })

        builder.addCase(postReaction.rejected, (state, { meta }) => {
            if (!meta.arg.bodyParams.parents) {
                // Is a post
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item,

                    ...(meta.arg.bodyParams.kind == 'react' && {
                        totalReactions:
                            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]
                                .item.totalReactions - 1,
                    }),

                    interactions: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items?.[meta.arg.urlParams.target_pk]
                            ?.item?.interactions,

                        ...(meta.arg.bodyParams.kind == 'react' && { react: null }),
                        ...(meta.arg.bodyParams.kind == 'pin_read' && { pinRead: null }),
                        ...(meta.arg.bodyParams.kind == 'pin_view' && { pinView: null }),
                    },
                }
            } else if (meta.arg.bodyParams.parents.length == 1) {
                // Is a comment (have one parent)

                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.bodyParams.parents[0]
                ].childrens.items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                            .childrens.items[meta.arg.urlParams.target_pk].item.totalReactions - 1,
                    reaction: null,
                }
            } else if (meta.arg.bodyParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.bodyParams.parents[0]
                ].childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                            .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item.totalReactions - 1,
                    reaction: null,
                }
            }
        })

        builder.addCase(postVote.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item,
                poll: payload.data.poll,
            }
        })

        builder.addCase(postReactionDelete.pending, (state, { meta }) => {
            if (!meta.arg.bodyParams.parents) {
                // Is a post
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item
                            .totalReactions - 1,
                    interactions: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]
                            .item.interactions,

                        ...(meta.arg.bodyParams.kind == 'react' && { react: null }),
                        ...(meta.arg.bodyParams.kind == 'pin_read' && { pinRead: null }),
                        ...(meta.arg.bodyParams.kind == 'pin_view' && { pinView: null }),
                    },
                }
            } else if (meta.arg.bodyParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.bodyParams.parents[0]
                ].childrens.items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                            .childrens.items[meta.arg.urlParams.target_pk].item.totalReactions - 1,
                    reaction: undefined,
                }
            } else if (meta.arg.bodyParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.bodyParams.parents[0]
                ].childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                            .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item.totalReactions - 1,
                    reaction: undefined,
                }
            }
        })

        builder.addCase(postReactionDelete.rejected, (state, { meta }) => {
            if (!meta.arg.bodyParams.parents) {
                // Is a post
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk].item
                            .totalReactions + 1,

                    interactions: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.target_pk]
                            .item.interactions,

                        ...(meta.arg.bodyParams.kind == 'react' && { react: meta.arg.bodyParams.content as 'like' }),
                        ...(meta.arg.bodyParams.kind == 'pin_read' && { pinRead: true }),
                        ...(meta.arg.bodyParams.kind == 'pin_view' && { pinView: true }),
                    },
                }
            } else if (meta.arg.bodyParams.parents.length == 1) {
                // Is a comment (have one parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.bodyParams.parents[0]
                ].childrens.items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                            .childrens.items[meta.arg.urlParams.target_pk].item.totalReactions + 1,
                    reaction: {
                        content: meta.arg.bodyParams.content,
                    },
                }
            } else if (meta.arg.bodyParams.parents.length == 2) {
                // Is a reply (have two parent)
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.bodyParams.parents[0]
                ].childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item = {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                        .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item,
                    totalReactions:
                        state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.bodyParams.parents[0]]
                            .childrens.items[meta.arg.bodyParams.parents[1]].childrens.items[meta.arg.urlParams.target_pk].item.totalReactions + 1,
                    reaction: {
                        content: meta.arg.bodyParams.content,
                    },
                }
            }
        })

        builder.addCase(rateLessonCreate.fulfilled, (state, { meta }) => {
            state.detail[meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.lessonRate = {
                rate: meta.arg.bodyParams.rate,
                content: meta.arg.bodyParams.content,
            }
        })

        builder.addCase(postCreate.pending, (state, { meta }) => {
            state.all[meta.arg.extraParams?.feed ? 'feed' : meta.arg.urlParams.b_pk] = {
                ...state.all[meta.arg.extraParams?.feed ? 'feed' : meta.arg.urlParams.b_pk],
                createState: { loading: true },
            }
        })
        builder.addCase(postCreate.rejected, (state, { meta, payload }) => {
            state.all[meta.arg.extraParams?.feed ? 'feed' : meta.arg.urlParams.b_pk] = {
                ...state.all[meta.arg.extraParams?.feed ? 'feed' : meta.arg.urlParams.b_pk],
                createState: { loading: false, error: payload },
            }
        })

        builder.addCase(postCreate.fulfilled, (state, { meta, payload }) => {
            state.all[meta.arg.extraParams?.feed ? 'feed' : meta.arg.urlParams.b_pk] = {
                ...state.all[meta.arg.extraParams?.feed ? 'feed' : meta.arg.urlParams.b_pk],
                items: {
                    ...((!meta.arg.extraParams.position || meta.arg.extraParams.position == 'first') && {
                        [payload.data.publicKey]: {
                            item: {
                                ...payload.data,
                                ...(!meta.arg.extraParams?.feed && { board: undefined }),
                            },
                            state: {
                                loading: false,
                            },
                        },
                    }),
                    ...state.all[meta.arg.extraParams?.feed ? 'feed' : meta.arg.urlParams.b_pk].items,
                    ...(meta.arg.extraParams.position == 'last' && {
                        [payload.data.publicKey]: {
                            item: {
                                ...payload.data,
                                ...(!meta.arg.extraParams?.feed && { board: undefined }),
                            },
                            state: {
                                loading: false,
                            },
                        },
                    }),
                },
                createState: { loading: false },
            }
        })

        builder.addCase(quizCreate.pending, (state, { meta }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                ...state.all[meta.arg.urlParams.b_pk],
                createState: { loading: true },
            }
        })
        builder.addCase(quizCreate.rejected, (state, { meta, payload }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                ...state.all[meta.arg.urlParams.b_pk],
                createState: { loading: false, error: payload },
            }
        })

        builder.addCase(quizCreate.fulfilled, (state, { meta, payload }) => {
            state.all[meta.arg.urlParams.b_pk] = {
                ...state.all[meta.arg.urlParams.b_pk],
                items: {
                    ...((!meta.arg.extraParams.position || meta.arg.extraParams.position == 'first') && {
                        [payload.data.publicKey]: {
                            item: {
                                ...payload.data,
                                ...(!meta.arg.extraParams?.feed && { board: undefined }),
                            },
                            state: {
                                loading: false,
                            },
                        },
                    }),
                    ...state.all[meta.arg.urlParams.b_pk].items,
                    ...(meta.arg.extraParams.position == 'last' && {
                        [payload.data.publicKey]: {
                            item: {
                                ...payload.data,
                                ...(!meta.arg.extraParams?.feed && { board: undefined }),
                            },
                            state: {
                                loading: false,
                            },
                        },
                    }),
                },
                createState: { loading: false },
            }
        })

        builder.addCase(replyList.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[
                meta.arg.urlParams.c_pk
            ] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                    .items[meta.arg.urlParams.c_pk],
                childrens: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens,
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                            .childrens.items[meta.arg.urlParams.c_pk].childrens.items,
                    },
                    listState: { loading: true },
                },
            }
        })

        builder.addCase(replyList.rejected, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[
                meta.arg.urlParams.c_pk
            ] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                    .items[meta.arg.urlParams.c_pk],
                childrens: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens,
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                            .childrens.items[meta.arg.urlParams.c_pk].childrens.items,
                    },
                    listState: { loading: false, error: payload },
                },
            }
        })

        builder.addCase(replyList.fulfilled, (state, { payload, meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[
                meta.arg.urlParams.c_pk
            ] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                    .items[meta.arg.urlParams.c_pk],
                childrens: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens,
                    items: {
                        ...payload.data.reduce(
                            (a, v) => ({
                                ...a,
                                [v.publicKey]: {
                                    item: v,
                                    loading: false,
                                    childrens: { items: {}, listState: { loading: false }, createState: { loading: false } },
                                },
                            }),
                            {}
                        ),
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                            .childrens.items[meta.arg.urlParams.c_pk].childrens.items,
                    },
                    listState: { loading: false, pagination: payload.pagination },
                },
            }
        })

        builder.addCase(replyCreate.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[
                meta.arg.urlParams.c_pk
            ] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                    .items[meta.arg.urlParams.c_pk],
                childrens: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens,
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                            .childrens.items[meta.arg.urlParams.c_pk].childrens.items,
                    },
                    createState: { loading: true },
                },
            }
        })

        builder.addCase(replyCreate.rejected, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[
                meta.arg.urlParams.c_pk
            ] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                    .items[meta.arg.urlParams.c_pk],
                childrens: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens,
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                            .childrens.items[meta.arg.urlParams.c_pk].childrens.items,
                    },
                    createState: { loading: false, error: payload },
                },
            }
        })

        builder.addCase(replyCreate.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                meta.arg.bodyParams.parents[0]
            ].item.totalComments += 1
            if (meta.arg.bodyParams.parents[0] != meta.arg.urlParams.c_pk) {
                state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[
                    meta.arg.bodyParams.parents[0]
                ].childrens.items[meta.arg.urlParams.c_pk].item.totalComments += 1
            }
            state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens.items[
                meta.arg.urlParams.c_pk
            ] = {
                ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk].childrens
                    .items[meta.arg.urlParams.c_pk],
                childrens: {
                    ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                        .childrens.items[meta.arg.urlParams.c_pk].childrens,
                    items: {
                        ...state[meta.arg.extraParams.scope][meta.arg.extraParams.feed ? 'feed' : meta.arg.urlParams.b_pk].items[meta.arg.extraParams.p_pk]
                            .childrens.items[meta.arg.urlParams.c_pk].childrens.items,
                        [payload.data.publicKey]: {
                            item: payload.data,
                            state: {
                                loading: false,
                            },
                        },
                    },
                    createState: { loading: false },
                },
            }
        })

        builder.addCase(postAttachmentCreate.fulfilled, (state, { meta, payload }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.assets.attachments = payload.data
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false
        })

        builder.addCase(postAttachmentCreate.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true
        })

        builder.addCase(postLinkCreate.fulfilled, (state, { meta, payload }) => {
            if (state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links) {
                state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links = [
                    ...state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links,
                    ...payload.data,
                ]
            } else {
                state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links = payload.data
            }
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false
        })

        builder.addCase(postLinkCreate.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true
        })

        builder.addCase(postLinkEdit.fulfilled, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links[meta.arg.extraParams.index] = {
                ...state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links[meta.arg.extraParams.index],
                title: meta.arg.bodyParams.title,
                url: meta.arg.bodyParams.url,
            }
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false
        })

        builder.addCase(postLinkEdit.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true
        })

        builder.addCase(postAttachmentDelete.fulfilled, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.assets.attachments.splice(meta.arg.extraParams.index, 1)
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false
        })

        builder.addCase(postAttachmentDelete.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true
        })

        builder.addCase(postLinkDelete.fulfilled, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].item.links.splice(meta.arg.extraParams.index, 1)
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = false
        })

        builder.addCase(postLinkDelete.pending, (state, { meta }) => {
            state[meta.arg.extraParams.scope][meta.arg.urlParams.b_pk].items[meta.arg.urlParams.p_pk].state.loading = true
        })
    },
})

export { postsSlice }
