import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface AddImageIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const AddImageIcon = (props: AddImageIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width={'100%'} viewBox="0 0 24 24" fill={'currentColor'}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22 3H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V3H18C17.7348 3 17.4804 3.10536 17.2929 3.29289C17.1054 3.48043 17 3.73478 17 4C17 4.26522 17.1054 4.51957 17.2929 4.70711C17.4804 4.89464 17.7348 5 18 5H19V6C19 6.26522 19.1054 6.51957 19.2929 6.70711C19.4804 6.89464 19.7348 7 20 7C20.2652 7 20.5196 6.89464 20.7071 6.70711C20.8946 6.51957 21 6.26522 21 6V5H22C22.2652 5 22.5196 4.89464 22.7071 4.70711C22.8946 4.51957 23 4.26522 23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652 3 22 3ZM3.5 3H8H13.9999H14C14.5523 3 15 3.44772 15 4C15 4.55229 14.5523 5 14 5L13.9999 5H3.5C3.22386 5 3 5.22386 3 5.5V16.5L5.4 14.7C5.77496 14.4188 6.29493 14.4357 6.65079 14.7407L9.4839 17.1691L13.831 13.2567C14.2128 12.9132 14.7927 12.9146 15.1727 13.2601L19 16.7394V16V10C19 9.44772 19.4477 9 20 9C20.5523 9 21 9.44772 21 10V16V18.996V19.0046V20.5C21 21.8807 19.8807 23 18.5 23H3.5C2.1193 23 1 21.8807 1 20.5V18.5115V18.4901V5.5C1 4.11929 2.11929 3 3.5 3ZM3 19V20.5C3 20.7762 3.22385 21 3.5 21H18.5C18.7762 21 19 20.7762 19 20.5V19.4424L14.4966 15.3484L10.169 19.2433C9.79552 19.5794 9.23067 19.5862 8.84921 19.2593L5.95826 16.7813L3 19ZM4.5 10C4.5 8.06702 6.06702 6.5 8 6.5C9.93299 6.5 11.5 8.06702 11.5 10C11.5 11.933 9.93299 13.5 8 13.5C6.06702 13.5 4.5 11.933 4.5 10ZM8 8.5C7.17158 8.5 6.5 9.17159 6.5 10C6.5 10.8284 7.17158 11.5 8 11.5C8.82841 11.5 9.5 10.8284 9.5 10C9.5 9.17159 8.82841 8.5 8 8.5Z"
                    />
                </svg>
            )}
            {...props}
        />
    )
}
