import { Notification, NotificationHistoryView, NotificationView } from '@api/model/requests/Notification'
import { getLastNotifications, markNotificationsAsSeen } from '@state/actions'
import { createSlice } from '@reduxjs/toolkit'

const sortNotifications = (a: NotificationView, b: NotificationView) => {
    const dateA = new Date(a.dateLastOcurred)
    const dateB = new Date(b.dateLastOcurred)
    return dateB.getTime() - dateA.getTime()
}

namespace notificationSlice {
    export interface State {
        general: NotificationHistoryView.ListAndSummary
        entities: {
            [publicKey: string]: NotificationHistoryView.ListAndSummary
        }
        error: any
        loading: boolean
    }
}

const defaultState: notificationSlice.State = {
    general: {
        aggregate: {},
        notifications: [],
        messages: [],
    },
    entities: {},
    error: null,
    loading: false,
}
interface payloadPk {
    publicKey: string
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState: defaultState,
    reducers: {
        markChatAsRead: (state, { payload }: { payload: { cm_pk: string; r_pk: string } }) => {
            let count = 0
            state.general.messages = state.general.messages.map((noti: NotificationView) => {
                if (noti.state === 'new' && (noti.predicate as Notification.RoomReference).roomPk == payload.r_pk) {
                    count += noti.unseen
                    noti.state = 'seen'
                }
                return noti
            })
            if (state.general.aggregate[payload.cm_pk]) {
                state.general.aggregate[payload.cm_pk].messages.newly -= count
            } else {
                state.general.aggregate[payload.cm_pk] = {
                    notifications: { newly: 0, total: 0 },
                    messages: { newly: 0, total: 0 },
                }
            }
        },
        addChatNotification: (state, { payload }) => {
            if (state.general.aggregate[payload.predicate.communityPk]) {
                state.general.aggregate[payload.predicate.communityPk].messages.newly++
                state.general.aggregate[payload.predicate.communityPk].messages.total++
            } else {
                state.general.aggregate[payload.predicate.communityPk] = {
                    notifications: { newly: 0, total: 0 },
                    messages: { newly: 1, total: 1 },
                }
            }

            const noti = state.general.messages.find((n) => n.publicKey === payload.publicKey)
            if (noti) {
                const index = state.general.messages.indexOf(noti)
                if (index > -1) {
                    state.general.messages.splice(index, 1)
                }
            }

            state.general.messages = state.general.messages.concat(payload)
            state.general.messages.sort(sortNotifications)
        },

        addNotification: (state, { payload }) => {
            const noti = state.general.notifications.find((n) => n.publicKey === payload.publicKey)

            if (noti) {
                const index = state.general.notifications.indexOf(noti)
                if (index > -1) {
                    state.general.notifications.splice(index, 1)
                }
            } else {
                if (state.general.aggregate[payload.predicate.communityPk]) {
                    state.general.aggregate[payload.predicate.communityPk].notifications.newly++
                    state.general.aggregate[payload.predicate.communityPk].notifications.total++
                } else {
                    state.general.aggregate[payload.predicate.communityPk] = {
                        notifications: { newly: 1, total: 1 },
                        messages: { newly: 0, total: 0 },
                    }
                }
            }

            state.general.notifications = state.general.notifications.concat(payload)
            state.general.notifications.sort(sortNotifications)
        },
        addUpdate: (state, { payload }: { payload: payloadPk }) => {
            /*      if (state.entities[payload.publicKey]) {
                // TOFIX
                state.entities[payload.publicKey].aggregate[payload.publicKey].newly++
            } */
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLastNotifications.pending, (state) => {
            state.loading = true
            state.general = {
                aggregate: {},
                notifications: [],
                messages: [],
            }
            state.entities = {}
            state.error = undefined
        })
        builder.addCase(getLastNotifications.fulfilled, (state, { payload }) => {
            state.general = {
                aggregate: payload.data.general.aggregate,
                notifications: payload.data.general.notifications
                    .filter((u) => u.kind != 'usr.chat.message')
                    .sort(function (x, y) {
                        const firstDate = new Date(x.dateLastOcurred)
                        const SecondDate = new Date(y.dateLastOcurred)

                        if (firstDate > SecondDate) return -1
                        if (firstDate < SecondDate) return 1
                        return 0
                    }),
                messages: payload.data.general.notifications
                    .filter((u) => u.kind == 'usr.chat.message')
                    .sort(function (x, y) {
                        const firstDate = new Date(x.dateLastOcurred)
                        const SecondDate = new Date(y.dateLastOcurred)

                        if (firstDate > SecondDate) return -1
                        if (firstDate < SecondDate) return 1
                        return 0
                    }),
            }
            state.entities = payload.data.entities
            state.error = undefined
            state.loading = false
        })
        builder.addCase(getLastNotifications.rejected, (state) => {
            ;(state.general = {
                aggregate: {},
                notifications: [],
                messages: [],
            }),
                (state.entities = {}),
                (state.error = true)
            state.loading = false
        })
        builder.addCase(markNotificationsAsSeen.pending, (state) => {
            state.loading = true
        })
        builder.addCase(markNotificationsAsSeen.fulfilled, (state, { meta }) => {
            state.loading = false

            if (meta.arg.urlParams.notificationId == 'all' && !meta.arg.bodyParams.communityPk) {
                state.general.notifications = state.general.notifications.map((noti: NotificationView) => {
                    if (noti.state === 'new') {
                        noti.state = 'seen'
                    }
                    return noti
                })
            } else if (meta.arg.urlParams.notificationId == 'all' && meta.arg.bodyParams.communityPk) {
                state.general.notifications = state.general.notifications.map((noti: NotificationView) => {
                    if (noti.state === 'new') {
                        noti.state = 'seen'
                    }
                    return noti
                })
                if (state.general.aggregate[meta.arg.bodyParams.communityPk]) {
                    state.general.aggregate[meta.arg.bodyParams.communityPk].notifications.newly = 0
                } else {
                    state.general.aggregate[meta.arg.bodyParams.communityPk] = {
                        notifications: { newly: 0, total: 0 },
                        messages: { newly: 0, total: 0 },
                    }
                }
            }
            state.error = undefined
        })
        builder.addCase(markNotificationsAsSeen.rejected, (state) => {
            state.error = true
            state.loading = false
        })
    },
})

export const { addNotification, addUpdate, addChatNotification, markChatAsRead } = notificationSlice.actions
export { notificationSlice }
