// Libraries

// React
import React from 'react'

// Antd
import { ClockCircleOutlined, CalendarOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps, Space, Typography } from 'antd'
import { CalendarThree, Down, LocalTwo } from '@icon-park/react'
import dayjs from 'dayjs'
import { EventView } from '@api/model/responses/event/EventView'
import { useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import strings from '@resources/localization'

import { google, outlook, office365, yahoo, ics } from 'calendar-link'
import { useParams } from 'react-router'
import { EventRouteParams } from '@typings/routing/Router'
import SVG from '@components/assets/SVG'
import GoogleCalendar from '@bundle/assets/icons/google.svg'
import iCalendar from '@bundle/assets/icons/apple.svg'
import Yahoo from '@bundle/assets/icons/yahoo.svg'
import Outlook from '@bundle/assets/icons/outlook.svg'
import { API } from '@api/API'
import useBreakpoints from '@hooks/useBreakpoints'

require('dayjs/locale/es')
require('dayjs/locale/en')
// MARK: - Definition

namespace EventDetails {
    export interface Props {
        item: EventView
        live?: boolean
        compact?: boolean
    }
}

// MARK: - Implementation

const EventDetails: React.FC<EventDetails.Props> = (props: EventDetails.Props) => {
    const { item, live, compact } = props
    const { cm_pk, e_pk } = useParams<EventRouteParams>()
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const event = {
        title: item.title,
        description: item.description + `\n\n${strings.screens.events.detail.moreDetails} ${window.location.href}`,
        start: item.dateStart,
        end: item.dateEnd,
        location: item.place?.name,
        url: item.online?.join_url,
    }

    const language = useAppSelector((state) => state.ui.language)

    dayjs.locale(language)

    const getGMT = (timezone: number) => {
        const number = timezone / 60
        const sign = number > 0 ? '-' : '+'
        return `(GMT ${sign}${Math.abs(number)})`
    }

    const onClick = (shareMethod: 'internal' | 'outlook' | 'google' | 'yahoo' | 'local') => {
        void API.analyticsAction({
            bodyParams: {
                action: 'event.save',
                sender: 'event.detail.card',
                shareMethod: shareMethod,
                referenceCommunity: cm_pk,
                referenceEvent: e_pk,
            },
        })

        switch (shareMethod) {
            case 'google':
                window.open(google(event), isMobile ? '_self' : '_blank')
                break
            case 'outlook':
                window.open(outlook(event), isMobile ? '_self' : '_blank')
                break
            case 'yahoo':
                window.open(yahoo(event), isMobile ? '_self' : '_blank')
                break
            case 'local':
                window.open(ics(event), '_self')
                break
            default:
                break
        }
    }

    const items: MenuProps['items'] = [
        {
            label: 'Google calendar',
            key: '1',
            icon: <SVG style={{ width: '14px', height: '14px', display: 'flex' }} content={GoogleCalendar}></SVG>,
            onClick: () => {
                onClick('google')
            },
        },
        {
            label: 'Outlook calendar',
            key: '2',
            icon: <SVG style={{ width: '14px', height: '14px', display: 'flex' }} content={Outlook}></SVG>,

            onClick: () => {
                onClick('outlook')
            },
        },
        {
            label: 'Yahoo!',
            key: '3',
            icon: <SVG style={{ width: '14px', height: '14px', display: 'flex' }} content={Yahoo}></SVG>,

            onClick: () => {
                onClick('yahoo')
            },
        },
        {
            label: 'iCal',
            key: '4',
            icon: <SVG style={{ width: '14px', height: '14px', display: 'flex' }} content={iCalendar}></SVG>,

            onClick: () => {
                onClick('local')
            },
        },
    ]

    const menuProps = {
        items,
    }
    return (
        <>
            <div style={{ display: 'flex', marginBottom: compact ? 0 : e_pk ? 16 : 4 }}>
                <CalendarOutlined style={{ marginRight: 8, alignSelf: 'center', ...(live && { color: 'var(--ant-primary-color)' }) }} />
                {!live && <Typography.Paragraph>{dayjs(item.dateStart).format(strings.general.date)}</Typography.Paragraph>}
                {live && <Typography.Paragraph style={{ color: 'var(--ant-primary-color)' }}>{strings.screens.events.detail.rightNow}</Typography.Paragraph>}
            </div>

            <div style={{ display: 'flex', marginBottom: compact ? 0 : e_pk ? 16 : 4 }}>
                <ClockCircleOutlined style={{ marginRight: 8, alignSelf: 'center' }} />
                <Typography.Paragraph>
                    {dayjs(item.dateStart).format('h:mm a')} {/* {getGMT(new Date(item.dateStart).getTimezoneOffset())} */}
                </Typography.Paragraph>
            </div>
            <div style={{ display: 'flex', marginBottom: compact ? 0 : e_pk ? 16 : 16 }}>
                <LocalTwo style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} theme="outline" size="14" />
                <Typography.Paragraph>{item.kind == 'presential' ? item.place?.name : 'Online'} </Typography.Paragraph>
            </div>

            {e_pk && !compact && (
                <div>
                    <Dropdown menu={menuProps} trigger={['click']}>
                        <Button block style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CalendarOutlined style={{ marginRight: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />

                            <Space>{strings.screens.events.detail.addtoCalendar}</Space>
                            <Down style={{ marginLeft: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                        </Button>
                    </Dropdown>
                </div>
            )}
        </>
    )
}

export { EventDetails }
