// Libraries

// React
import React from 'react'

// Antd
import { Check } from '@icon-park/react'
import useBreakpoints from '@hooks/useBreakpoints'
import { useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import USM from '@bundle/assets/landing_b2b/usm.png'
import Pista8 from '@bundle/assets/landing_b2b/pista8.png'
import Dadneo from '@bundle/assets/landing_b2b/dadneo.png'
import Liquimoly from '@bundle/assets/landing_b2b/liqui.png'
import Innspiral from '@bundle/assets/landing_b2b/innspiral.png'
import ProChile from '@bundle/assets/landing_b2b/prochile.png'

import strings from '@resources/localization'
// MARK: - Definition

namespace DemoInfo {
    export interface Props {
        compact?: boolean
    }
}

// MARK: - Implementation

const DemoInfo: React.FC<DemoInfo.Props> = (props: DemoInfo.Props) => {
    const breakpoints = useBreakpoints()
    const isMobileLarge = breakpoints.xxs || breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.mdl
    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme == 'dark'
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
            <div>
                <div
                    style={{
                        fontSize: props.compact ? 30 : 38,
                        lineHeight: '46px',
                        fontWeight: 500,
                        maxWidth: breakpoints.isMobile ? 840 : 513,
                        alignSelf: 'center',
                        marginBottom: 16,
                    }}
                >
                    {strings.screens.landingb2b.footer[0]}
                    <div style={{ color: '#4cc1be', display: 'inline' }}>{strings.screens.landingb2b.footer[1]}</div> {strings.screens.landingb2b.footer[2]}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                    <Check theme="outline" size="24" fill="#4DC1BE" style={{ marginRight: 8 }} />
                    <div style={{ fontSize: 18 }}>{strings.screens.landingb2b.demo_highlights[0]}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                    <Check theme="outline" size="24" fill="#4DC1BE" style={{ marginRight: 8 }} />
                    <div style={{ fontSize: 18 }}>{strings.screens.landingb2b.demo_highlights[1]}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Check theme="outline" size="24" fill="#4DC1BE" style={{ marginRight: 8 }} />
                    <div style={{ fontSize: 18 }}>{strings.screens.landingb2b.demo_highlights[2]}</div>
                </div>
            </div>
            <div style={{ marginTop: props.compact ? 56 : 0 }}>
                <div style={{ marginBottom: 24, fontWeight: 500, fontSize: 18 }}>{strings.screens.landingb2b.trustus}</div>

                <div>
                    <div style={{ marginBottom: 24 }}>
                        <img src={Dadneo} alt="" style={{ marginRight: 32, height: props.compact ? 32 : 40 }} />
                        <img src={USM} alt="" style={{ marginRight: 32, height: props.compact ? 32 : 40 }} />
                        <img src={Liquimoly} alt="" style={{ height: props.compact ? 32 : 40 }} />
                    </div>
                    <div>
                        <img src={Pista8} alt="" style={{ marginRight: 32, height: props.compact ? 32 : 40 }} />
                        <img src={Innspiral} alt="" style={{ marginRight: 32, height: props.compact ? 16 : 24 }} />
                        <img src={ProChile} alt="" style={{ height: props.compact ?32 : 40 }} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export { DemoInfo }
