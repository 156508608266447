import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    filled?: boolean
    height?: number
    width?: number
}

export const CatalogueItem13 = (props: Props) => {
    return (
        <Icon
            component={() => {
                if (props.filled) {
                    return (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.6129 3.2097L8.70711 3.29289L12 6.585L15.2929 3.29289C15.6534 2.93241 16.2206 2.90468 16.6129 3.2097L16.7071 3.29289L22.7071 9.29289C23.0676 9.65338 23.0953 10.2206 22.7903 10.6129L22.7071 10.7071L12.7071 20.7071C12.5569 20.8573 12.3708 20.9497 12.1764 20.9844L12.059 20.9983H11.941C11.7051 20.9844 11.4731 20.8874 11.2929 20.7071L1.29289 10.7071C0.932409 10.3466 0.90468 9.77939 1.2097 9.3871L1.29289 9.29289L7.29289 3.29289C7.62334 2.96245 8.12751 2.91161 8.5114 3.14038L8.6129 3.2097ZM8 5.415L3.415 10L12 18.585L12.585 18L11.7929 17.2071C11.4024 16.8166 11.4024 16.1834 11.7929 15.7929C12.1534 15.4324 12.7206 15.4047 13.1129 15.7097L13.2071 15.7929L14 16.585L14.585 16L13.7929 15.2071C13.4024 14.8166 13.4024 14.1834 13.7929 13.7929C14.1534 13.4324 14.7206 13.4047 15.1129 13.7097L15.2071 13.7929L16 14.585L16.585 14L14 11.415L11.7071 13.7071C11.4121 14.0021 10.9691 14.0801 10.5953 13.9145L10.4855 13.8575L7.9855 12.3575L7.88841 12.2913L7.79289 12.2071C7.75571 12.1699 7.72208 12.1306 7.69198 12.0894L7.6109 11.958L7.56168 11.8459L7.53751 11.772L7.51593 11.6782L7.50264 11.5729L7.4999 11.4885L7.50266 11.4269L7.51443 11.3293L7.54489 11.2031L7.56251 11.1515C7.5974 11.0575 7.64638 10.9685 7.7097 10.8871L7.79289 10.7929L10.585 8L8 5.415Z" />
                        </svg>
                    )
                } else {
                    return (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.6129 3.2097L8.70711 3.29289L12 6.585L15.2929 3.29289C15.6534 2.93241 16.2206 2.90468 16.6129 3.2097L16.7071 3.29289L22.7071 9.29289C23.0676 9.65338 23.0953 10.2206 22.7903 10.6129L22.7071 10.7071L12.7071 20.7071C12.5569 20.8573 12.3708 20.9497 12.1764 20.9844L12.059 20.9983H11.941C11.7051 20.9844 11.4731 20.8874 11.2929 20.7071L1.29289 10.7071C0.932409 10.3466 0.90468 9.77939 1.2097 9.3871L1.29289 9.29289L7.29289 3.29289C7.62334 2.96245 8.12751 2.91161 8.5114 3.14038L8.6129 3.2097ZM8 5.415L3.415 10L12 18.585L12.585 18L11.7929 17.2071C11.4024 16.8166 11.4024 16.1834 11.7929 15.7929C12.1534 15.4324 12.7206 15.4047 13.1129 15.7097L13.2071 15.7929L14 16.585L14.585 16L13.7929 15.2071C13.4024 14.8166 13.4024 14.1834 13.7929 13.7929C14.1534 13.4324 14.7206 13.4047 15.1129 13.7097L15.2071 13.7929L16 14.585L16.585 14L14 11.415L11.7071 13.7071C11.4121 14.0021 10.9691 14.0801 10.5953 13.9145L10.4855 13.8575L7.9855 12.3575L7.88841 12.2913L7.79289 12.2071C7.75571 12.1699 7.72208 12.1306 7.69198 12.0894L7.6109 11.958L7.56168 11.8459L7.53751 11.772L7.51593 11.6782L7.50264 11.5729L7.4999 11.4885L7.50266 11.4269L7.51443 11.3293L7.54489 11.2031L7.56251 11.1515C7.5974 11.0575 7.64638 10.9685 7.7097 10.8871L7.79289 10.7929L10.585 8L8 5.415ZM16 5.415L10.113 11.301L10.844 11.74L13.2929 9.29289C13.6534 8.93241 14.2206 8.90468 14.6129 9.2097L14.7071 9.29289L18 12.585L20.585 10L16 5.415Z" />
                        </svg>
                    )
                }
            }}
            {...props}
        />
    )
}
