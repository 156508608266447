// Modules
import React from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import { userEdit } from '@state/actions'
import { FooterModal } from './components/FooterModal'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'
import { useHistory } from 'react-router'
import { Router } from '@Router'
import WhatsappVideo from '@bundle/video/whatsapp.mp4'
import ReactPlayer from 'react-player'
import useBreakpoints from '@hooks/useBreakpoints'
import strings from '@resources/localization'

export const WhatsAppModal = () => {
    const cm_pk = useAppSelector((state) => state.community.currentCommunity)
    const currentEntity = useAppSelector((state) => state.auth.currentEntity)

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const dispatch = useAppDispatch()
    const history = useHistory()

    const toProfile = () => {
        history.push(Router.Path.user_details({ cm_pk: cm_pk, u_pk: currentEntity.publicKey }))
    }

    const onSubmit = (redirect?: boolean) => {
        dispatch(
            userEdit({
                urlParams: {
                    cm_pk: cm_pk,
                },
                bodyParams: {
                    onboardTourWhatsapp: false,
                },
                extraParams: {
                    u_pk: currentEntity.publicKey,
                },
                ...(redirect && {
                    options: {
                        then: { action: toProfile },
                    },
                }),
            })
        )
    }

    return (
        <TuringoModal
            padding="0px"
            closable={false}
            width={572}
            open={currentEntity?.onboard?.tour?.whatsapp}
            footer={[
                <FooterModal
                style={{ padding: isMobile ? '0px 16px 16px 16px' : '0px 24px 16px 16px'  }}
                    key={'footer_modal'}
                    primary={{
                        action: () => {
                            onSubmit(false)
                        },
                        customText: 'Ok',
                    }}
                    secondary={{
                        action: () => {
                            onSubmit(true)
                        },
                        customText: strings.modals.whatsapp.toProfile,
                    }}
                />,
            ]}
        >
            <ReactPlayer playing={true} muted={true} controls width={'100%'} height={'100%'} url={WhatsappVideo}></ReactPlayer>

            <div style={{ padding: isMobile ? '16px 16px 0px 16px' : '16px 24px 0px 24px' }}>
                <p style={{ marginBottom: 8 }} className="turingo-title">
                    {strings.modals.whatsapp.title}
                </p>
                <p className="turingo-text">
                {strings.modals.whatsapp.description}
                </p>
            </div>
        </TuringoModal>
    )
}
