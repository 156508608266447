import React, { FC } from 'react'
import { Divider, Radio, Space } from 'antd'

import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { setCurrentTheme } from '@state/reducers/UserInterface/UserInterfaceReducer'
import { RootReducer } from '@state/reducers/Root'
import strings from '@resources/localization'
import useBreakpoints from '@hooks/useBreakpoints'
import { API } from '@api/API'

export const ThemeSelector: FC = () => {
    const theme = useAppSelector((state) => state.ui.theme)
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    const dispatch = useAppDispatch()

    const onChange = (e) => {
        dispatch(setCurrentTheme(e.target.value))
        void API.analyticsSetting({ bodyParams: { theme: e.target.value } })
    }
    return (
        <div>
            <Radio.Group style={{ ...(isMobile && { width: '100%' }) }} onChange={onChange} value={theme} className="turingo-radio-list">
                <Space direction="vertical" size={0}>
                    <Radio value="light">{strings.screens.settings.sections.theme.options.light}</Radio>
                    <Divider></Divider>
                    <Radio value="dark">{strings.screens.settings.sections.theme.options.dark}</Radio>
                </Space>
            </Radio.Group>
        </div>
    )
}
