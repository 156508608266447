import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface ArrowIcon {
    className?: string
    color?: string
    style?: CSSProperties
    height?: number
    width?: number
}

export const ArrowLeftIcon = (props: ArrowIcon) => {
    return (
        <Icon
            component={() => (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={'100%'}
                    viewBox="0 0 16 16"
                    fill={'currentColor'}
                >
                    <path d="M4.83296 7.52763C4.57261 7.78798 4.57261 8.21009 4.83296 8.47044L9.52861 13.1661C9.78896 13.4264 10.2111 13.4264 10.4714 13.1661C10.7318 12.9057 10.7318 12.4836 10.4714 12.2233L6.24717 7.99904L10.4714 3.77479C10.7318 3.51444 10.7318 3.09233 10.4714 2.83198C10.2111 2.57163 9.78896 2.57163 9.52861 2.83198L4.83296 7.52763Z" />
                </svg>
            )}
            {...props}
        />
    )
}
