// Libraries

// React
import React, { useContext, useEffect, useState } from 'react'

// Antd
import { Form, Input } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { SocketContext } from 'src/SocketComponent'
import { NotificationHistoryView } from '@api/model/requests/Notification'
import { CreatePostContext } from '../../PostCreateProvider'
import { AlanEditor } from './components/AlanEditor'
import useBreakpoints from '@hooks/useBreakpoints'
import strings from '@resources/localization'

// MARK: - Definition

namespace AlanScreen {
    export interface Props {}
}

// MARK: - Implementation

const AlanScreen: React.FC<AlanScreen.Props> = (props: AlanScreen.Props) => {
    const [value, setValue] = useState('')
    const socket = useContext(SocketContext)
    const { form, alanState } = useContext(CreatePostContext)
    const alanText = Form.useWatch(['form', 'turingo', 'alan'], form)
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    useEffect(() => {
        const lastMessage = socket.lastJsonMessage as NotificationHistoryView.SocketMessage
        if (lastMessage?.channel?.id == 'ai.chat.autocomplete') {
            const errorMessage = socket.lastJsonMessage as NotificationHistoryView.Error
            if (!errorMessage?.error) {
                const message = socket.lastJsonMessage as NotificationHistoryView.AlanMessage
                if (message.body.state == 'start') {
                    form.setFieldValue(['form', 'turingo', 'alan'], '')
                }
                if (message.body.state == 'running') {
                    form.setFieldValue(['form', 'turingo', 'alan'], alanText + message.body.content)
                }
            }
        }
    }, [socket.lastJsonMessage])

    return (
        <div>
            <Paragraph style={{ marginBottom: 16 }} className="turingo-text">
                {strings.screens.boards.discussion.post.alan.description}
            </Paragraph>
            <AlanEditor
                maxLength={250}
                showCount
                maxHeight={isMobile ? 143 : 81}
                minHeight={isMobile ? 143 : 81}
                value={value}
                placeholder={strings.screens.boards.discussion.post.alan.fieldText.placeholder}
                bordered={true}
                onAlanState={alanState.set}
                onChange={setValue}
            ></AlanEditor>

            <Input.TextArea
                style={{
                    backgroundColor: alanState.get.state != 'inactive' ? 'var(--background)' : 'var(--neutral-2)',
                    color: alanState.get.state != 'inactive' ? 'var(--neutral-13)' : 'var(--neutral-6)',
                    marginTop: 16,
                    maxHeight: isMobile ? '35vh' : 'unset',
                    minHeight: isMobile ? '35vh' : 106,
                    height: isMobile ? '35vh' : 'unset',
                }}
                value={alanText}
                onChange={(e) => form.setFieldValue(['form', 'turingo', 'alan'], e.target.value)}
                disabled={alanState.get.state == 'inactive' || alanState.get.state == 'running' || alanState.get.state == 'stoping'}
                autoComplete="off"
                placeholder={strings.screens.boards.discussion.post.alan.fieldResult.placeholder}
            />
        </div>
    )
}

export { AlanScreen }
