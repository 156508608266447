// Libraries

// React
import React from 'react'

type ConditionalWrapProps = {
    children: React.ReactElement
    condition: boolean
    wrap: (children: React.ReactElement) => JSX.Element
}
export const ConditionalWrap: React.FC<ConditionalWrapProps> = ({ condition, wrap, children }) => (condition ? wrap(children) : children)
