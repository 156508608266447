import strings from '@resources/localization'
import { trim } from '@util/string/Functions'
import { Form, FormInstance, Input } from 'antd'
import { FormContext } from 'antd/es/form/context'
import { NamePath } from 'antd/lib/form/interface'
import React, { useContext } from 'react'
import { Validations } from '../Validations'

interface Props {
    name: NamePath
    label: string | React.ReactNode
    validations?: Array<Validations>
    style?: React.CSSProperties
    className?: string
    placeholder?: string
    showCount?: boolean
    maxLength?: number
    optionalSurname?: boolean
    supportNumbers?: boolean
    autoUpperCase?: boolean
    customErrors?: {
        format?: string
        pattern?: string
        long?: string
        short?: string
        required?: string
    }
}

export const FormNameItem = (props: Props) => {
    const {
        name,
        label,
        validations,
        style,
        className,
        placeholder,
        showCount,
        maxLength,
        customErrors,
        optionalSurname = false,
        supportNumbers = false,
        autoUpperCase = true,
    } = props
    const regex =
        /^[a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ][a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]*(([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]* | \s{1})[a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]+)*$/
    const regexNumbers =
        /^[a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ][a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]*(([a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]* | \s{1})[a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]+)*$/

    const { form } = useContext(FormContext)

    const changeHandler = (e) => {
        if (Array.isArray(name)) {
            const users = form.getFieldValue('users')

            // Check If `guests` exist & guest is an array & it's not empty
            if (users && Array.isArray(users) && users.length) {
                // Check If firstName exists at that `index`
                if (users[name[0]][name[1]]) {
                    users[name[0]][name[1]] = toTitleCase(e.target.value)
                    form.setFieldsValue({ names: users })
                }
            }
        } else {
            form.setFieldValue(name, toTitleCase(e.target.value))
        }
    }

    const toTitleCase = (phrase: string) => {
        return phrase
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }

    return (
        <Form.Item
            style={style}
            className={className}
            name={name}
            label={label}
            rules={[
                ...(validations?.includes('required')
                    ? [
                          {
                              required: true,
                              message: customErrors?.required || strings.forms.name.validations.required,
                          },
                      ]
                    : []),

                ...(validations?.includes('format')
                    ? [
                          {
                              validator: (rule: any, value: string) => {
                                  const newValue = trim(value)
                                  try {
                                      if (supportNumbers) {
                                          if (!regexNumbers.test(newValue)) {
                                              throw new Error(customErrors?.pattern || strings.forms.name.validations.pattern)
                                          }
                                      } else {
                                          if (!regex.test(newValue)) {
                                              throw new Error(customErrors?.pattern || strings.forms.name.validations.pattern)
                                          }
                                      }

                                      if (!optionalSurname && newValue.split(' ').length < 2) {
                                          throw new Error(customErrors?.format || strings.forms.name.validations.format)
                                      }
                                      return Promise.resolve()
                                  } catch (err) {
                                      if (!value) {
                                          return Promise.resolve()
                                      }

                                      if (value?.length != 0) {
                                          return Promise.reject(err)
                                      } else {
                                          return Promise.resolve()
                                      }
                                  }
                              },
                          },
                      ]
                    : []),

                ...(validations?.includes('long')
                    ? [
                          {
                              max: 50,
                              message: customErrors?.long || strings.formatString(strings.forms.name.validations.long, 50) as string,
                          },
                      ]
                    : []),

                ...(validations?.includes('short')
                    ? [
                          {
                              min: 5,
                              message: customErrors?.short || strings.formatString(strings.forms.name.validations.short, 5)  as string,
                          },
                      ]
                    : []),
            ]}
        >
            <Input
                showCount={showCount}
                maxLength={maxLength}
                name="name"
                autoComplete="off"
                placeholder={placeholder}
                onChange={autoUpperCase ? changeHandler : undefined}
            />
        </Form.Item>
    )
}
