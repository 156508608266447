import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface AddVideoIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const AddVideoIcon = (props: AddVideoIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 24 24" fill={'currentColor' }>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22 3H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V3H18C17.7348 3 17.4804 3.10536 17.2929 3.29289C17.1054 3.48043 17 3.73478 17 4C17 4.26522 17.1054 4.51957 17.2929 4.70711C17.4804 4.89464 17.7348 5 18 5H19V6C19 6.26522 19.1054 6.51957 19.2929 6.70711C19.4804 6.89464 19.7348 7 20 7C20.2652 7 20.5196 6.89464 20.7071 6.70711C20.8946 6.51957 21 6.26522 21 6V5H22C22.2652 5 22.5196 4.89464 22.7071 4.70711C22.8946 4.51957 23 4.26522 23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652 3 22 3ZM8 3H3.5C2.11929 3 1 4.11929 1 5.5V20.5C1 21.8807 2.1193 23 3.5 23H18.5C19.8807 23 21 21.8807 21 20.5V16V10C21 9.44772 20.5523 9 20 9C19.4477 9 19 9.44772 19 10V16V20.5C19 20.7762 18.7762 21 18.5 21H3.5C3.22385 21 3 20.7762 3 20.5V9.5H6.47844C6.49221 9.50028 6.50597 9.50028 6.51972 9.5H8.8H15C15.5523 9.5 16 9.05229 16 8.5C16 7.94772 15.5523 7.5 15 7.5H10H8.36859L10.0353 5H11.5L14 5C14.5523 5 15 4.55229 15 4C15 3.44772 14.5523 3 14 3H12.7H8ZM5.96489 7.5H3V5.5C3 5.22386 3.22386 5 3.5 5H7.63155L5.96489 7.5ZM9.75 11.1027C9.4406 10.9241 9.0594 10.9241 8.75 11.1027C8.4406 11.2814 8.25 11.6115 8.25 11.9688V18.0309C8.25 18.3882 8.4406 18.7183 8.75 18.897C9.0594 19.0756 9.4406 19.0756 9.75 18.897L15 15.8659C15.3094 15.6872 15.5 15.3571 15.5 14.9999C15.5 14.6426 15.3094 14.3125 15 14.1338L9.75 11.1027ZM12.5 14.9999L10.25 16.2989V13.7008L12.5 14.9999Z"
                    />
                </svg>
            )}
            {...props}
        />
    )
}
