import { useMediaQuery } from 'react-responsive'

const useBreakpoints = () => {
    const isXXL = useMediaQuery({ minWidth: 1600 })
    const isXL = useMediaQuery({ maxWidth: 1599.9, minWidth: 1270 })
    const isLG = useMediaQuery({ maxWidth: 1269.9, minWidth: 992 })
    const isMDL = useMediaQuery({ maxWidth: 991.9, minWidth: 768 })
    const isMD = useMediaQuery({ maxWidth: 767.9, minWidth: 680 })
    const isSM = useMediaQuery({ maxWidth: 679.9, minWidth: 576 })
    const isXS = useMediaQuery({ maxWidth: 575.9, minWidth: 400 })
    const isXXS = useMediaQuery({ maxWidth: 400.9 })

    const isTuringoWidth = useMediaQuery({ minWidth: 1308 })

    return {
        xxs: isXXS,
        xxl: isXXL,
        xl: isXL,
        lg: isLG,
        md: isMD,
        mdl: isMDL,
        sm: isSM,
        xs: isXS,
        turingo: isTuringoWidth,
        isMobile: isXXS || isXS || isSM || isMD || isMDL,
        isTablet: isMD || isMDL,
        isSmall: isXXS || isXS || isSM,
    }
}

export default useBreakpoints
