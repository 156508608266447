// Libraries

// React
import React, { useState } from 'react'

// Antd
import { Button, Divider, Form, Select } from 'antd'
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useHistory } from 'react-router'
import { Router } from '@Router'
import { DebounceCommunitySelect } from '@components/form/DebounceCommunitySelect/DebounceCommunitySelect'
import { useForm } from 'antd/lib/form/Form'
import { DebounceGenericSelect } from '@components/form/DebounceGenericSelect/DebounceGenericSelect'
import { API } from '@api/API'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { debugEventEmail } from '@state/actions'
import { RootReducer } from '@state/reducers/Root'
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem'

// MARK: - Definition

namespace Event {
    export interface Props { }
}

// MARK: - Implementation

const Event: React.FC<Event.Props> = (props: Event.Props) => {
    const history = useHistory()
    const [form] = useForm()
    const community = Form.useWatch('community', form)

    const currentEntity = useAppSelector((state) => state.auth)
    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()
    const submit = (values) => {
        dispatch(
            debugEventEmail({
                bodyParams: {
                    communityPk: values.community.value,
                    eventPk: values.event.value,
                    kind: values.kind,
                    bypassUserSettings: true,
                    emails: [values.email],
                },
                options: {
                    alerts:{
                        success: 'Email enviado correctamente',
                        error: 'Ha sucedido un error al enviar el email'
                    },
                    then: {
                        action: ()=>{setLoading(false)},
                    },
                },
            })
        )
    }

    return (
        <div className="lateral-padding" style={{ paddingBottom: 16 }}>
            <div style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
                <GoBackButton
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}
                    type="default"
                    showText={false}
                    onClick={() => history.push(Router.Path.debug({}))}
                />
                <Paragraph strong className="turingo-text">
                    {'Event'}
                </Paragraph>
            </div>
            <Divider></Divider>

            <Paragraph style={{ marginBottom: 16 }}>{'Envia un email de prueba de un evento.'}</Paragraph>

            <Form  initialValues={{
                email: currentEntity.authEmail
            }} onFinish={submit} className={'scrollStyle'} form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical">
                <Form.Item
                    name={'community'}
                    label={'Selecciona una comunidad'}
                    rules={[
                        {
                            required: true,
                            message: 'Selecciona una comunidad',
                        },
                    ]}
                >
                    <DebounceCommunitySelect
                        onChange={() => {
                            form.setFieldValue('event', undefined)
                        }}
                        placeholder={'Selecciona una comunidad'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item name={'event'} label={'Selecciona un evento'}>
                    <DebounceGenericSelect
                        disabled={!community}
                        nameField="title"
                        valueField="publicKey"
                        dispatch={() =>
                            API.communityEventList({
                                urlParams: {
                                    cm_pk: community?.value,
                                },
                            })
                        }
                        placeholder={'Selecciona un evento'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item name="kind" label={'Tipo de email'}>
                    <Select
                        getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
                        dropdownStyle={{ zIndex: 2000 }}
                        options={[
                            {
                                label: 'Invitación al evento',
                                value: 'invite',
                            },
                            {
                                label: '24 horas antes del evento',
                                value: 'reminderDay',
                            },
                            {
                                label: 'Al iniciar el evento',
                                value: 'reminderStart',
                            },
                        ]}
                        placeholder={'Selecciona el tipo de email'}
                    ></Select>
                </Form.Item>
                <FormEmailItem
                    name={'email'}
                    placeholder={'Email'}
                    label={'Email (El email debe de tener cuenta de turingo)'}
                    validations={['format', 'long', 'short']}
                />

<div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
                <Button loading={loading} type='primary' onClick={form.submit}>Enviar</Button>

                </div>            </Form>
        </div>
    )
}

export { Event }
