import { LinkColor } from '@components/buttons/link_color/LinkColor';
import strings from '@resources/localization';
import React from 'react'

export const PostCommentsTag = (props: { totalComments: number; onClick: () => void }) => {
    if (props.totalComments == 0) return <span></span>
    return (
        <LinkColor style={{ fontSize: 14, lineHeight: '30px' }} onClick={props.onClick}>
            {props.totalComments == 1 && strings.formatString(strings.general.comments.one)}
            {props.totalComments != 1 && strings.formatString(strings.general.comments.nary, props.totalComments)}
        </LinkColor>
    )
}
