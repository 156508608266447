// Libraries

// React
import React, { useState } from 'react'

// Antd
import { Button, Divider, Form, Select } from 'antd'
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useHistory } from 'react-router'
import { Router } from '@Router'
import { DebounceCommunitySelect } from '@components/form/DebounceCommunitySelect/DebounceCommunitySelect'
import { useForm } from 'antd/lib/form/Form'
import { DebounceGenericSelect } from '@components/form/DebounceGenericSelect/DebounceGenericSelect'
import { API } from '@api/API'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { RootReducer } from '@state/reducers/Root'
import { debugDigestEmail } from '@state/actions'
import { valueToFrequency } from '@screens/community/admin_panel/screens/notifications/NotificationScreen'
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem'
import strings from '@resources/localization'

// MARK: - Definition

namespace Digest {
    export interface Props {}
}

// MARK: - Implementation

const Digest: React.FC<Digest.Props> = (props: Digest.Props) => {
    const history = useHistory()
    const [form] = useForm()
    const community = Form.useWatch('community', form)

    const currentEntity = useAppSelector((state) => state.auth)
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()
    const submit = (values) => {
        setLoading(true)
        const posts = values.posts?.map((i) => i.value)
        const rates = values.rates?.map((i) => i.value)
        const events = values.events?.map((i) => i.value)

        void dispatch(
            debugDigestEmail({
                bodyParams: {
                    frequency: valueToFrequency(values.frequency),
                    communityPk: values.community.value,
                    rates: rates || [],
                    posts: posts || [],
                    events: events || [],
                    bypassUserSettings: true,
                    emails: [values.email],
                },
                options: {
                    alerts: {
                        success: 'Email enviado correctamente',
                        error: 'Ha sucedido un error al enviar el email',
                    },
                    then: {
                        action: () => {
                            setLoading(false)
                        },
                    },
                },
            })
        )
    }

    return (
        <div className="lateral-padding" style={{ paddingBottom: 16 }}>
            <div style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
                <GoBackButton
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}
                    type="default"
                    showText={false}
                    onClick={() => history.push(Router.Path.debug({}))}
                />
                <Paragraph strong className="turingo-text">
                    {'Digest'}
                </Paragraph>
            </div>
            <Divider></Divider>

            <Paragraph style={{ marginBottom: 16 }}>{'Envia un email de prueba de un evento.'}</Paragraph>

            <Form
                initialValues={{
                    email: currentEntity.authEmail,
                }}
                onFinish={submit}
                className={'scrollStyle'}
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
            >
                <Form.Item
                    name={'community'}
                    label={'Selecciona una comunidad'}
                    rules={[
                        {
                            required: true,
                            message: 'Selecciona una comunidad',
                        },
                    ]}
                >
                    <DebounceCommunitySelect
                        onChange={() => {
                            form.setFieldValue('events', undefined)
                        }}
                        placeholder={'Selecciona una comunidad'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item name={'events'} label={'Selecciona eventos'}>
                    <DebounceGenericSelect
                        mode="multiple"
                        disabled={!community}
                        nameField="title"
                        valueField="publicKey"
                        dispatch={() =>
                            API.communityEventList({
                                urlParams: {
                                    cm_pk: community?.value,
                                },
                            })
                        }
                        placeholder={'Selecciona eventos'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item name={'posts'} label={'Selecciona publicaciones'}>
                    <DebounceGenericSelect
                        mode="multiple"
                        disabled={!community}
                        nameField="content"
                        valueField="publicKey"
                        dispatch={() =>
                            API.communityPosts({
                                urlParams: {
                                    cm_pk: community?.value,
                                },
                            })
                        }
                        placeholder={'Selecciona publicaciones'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item name={'rates'} label={'Selecciona valoraciones'}>
                    <DebounceGenericSelect
                        mode="multiple"
                        disabled={!community}
                        nameField="content"
                        valueField="publicKey"
                        dispatch={() =>
                            API.communityPosts({
                                urlParams: {
                                    cm_pk: community?.value,
                                },
                                searchParams: {
                                    kind: 'rate',
                                },
                            })
                        }
                        placeholder={'Selecciona valoraciones'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item name="frequency" label={'Selecciona la frequencia del digest'}>
                    <Select
                        getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
                        dropdownStyle={{ zIndex: 2000 }}
                        options={[
                            {
                                label: 'Semanal',
                                value: 'weekly',
                            },
                            {
                                label: 'Bisemanal',
                                value: 'biweekly',
                            },
                            {
                                label: 'Mensual',
                                value: 'monthly',
                            },
                        ]}
                        placeholder={'Selecciona la frequencia del digest'}
                    ></Select>
                </Form.Item>

                <FormEmailItem
                    name={'email'}
                    placeholder={'Email'}
                    label={'Email (El email debe de tener cuenta de turingo)'}
                    validations={['format', 'long', 'short']}
                />

                <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    <Button loading={loading} type="primary" onClick={form.submit}>
                        Enviar
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export { Digest }
