import { FileRejection, useDropzone } from 'react-dropzone'
import { Button, Form } from 'antd'
import { Colors } from '@util/color/Colors'
import strings from '@resources/localization'
import { CSSProperties, useCallback, useContext, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { AddImageIcon } from '@components/icons/AddImageIcon'
import { AddVideoIcon } from '@components/icons/AddVideoIcon'
import { AddAttachmentIcon } from '@components/icons/AddAttachmentIcon'
import { ButtonShape } from 'antd/lib/button'
import { NamePath } from 'antd/lib/form/interface'
import useBreakpoints from '@hooks/useBreakpoints'
import { API } from '@api/API'
import { ListItem } from './ListItem'
import TransparencyDark from '@bundle/assets/general/transparency-dark.png'
import TransparencyLight from '@bundle/assets/general/transparency-light.png'
import { useAppSelector } from '@hooks/useStore'

import { Tooltip, Alert } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { Close } from '@icon-park/react'
import Paragraph from 'antd/lib/typography/Paragraph'
import { EditImage } from '../UploadImage/components/EditImage'
import { FormContext } from 'antd/es/form/context'
import { ConversionView } from '@api/model/responses/upload/UploadIntent'
import { Scope } from '@api/model/requests/Upload'
import { formatMB } from '@util/string/Functions'
import { TuringoButton, TuringoButtonShape } from 'src/turingo_components/buttons/button/TuringoButton'

namespace AssetOptions {
    export type Assets = 'avatar' | 'banner' | 'single'
    export type CropShape = 'rect' | 'round'
}

export interface FileOptions {
    style?: CSSProperties
    showBackground?: boolean
    showTransparency?: boolean
}

export interface UploadArea {
    style?: CSSProperties
    btnShape?: TuringoButtonShape
    showDescription?: boolean
}
export interface UploadRules {
    ant?: Array<{
        required: boolean
        message: string
    }>
    dimensions?: {
        max?: {
            height?: number
            width: number
        }
        min?: {
            height?: number
            width: number
        }
    }
    maxSize?: number
    maxFiles: number
}

export type EditAspectRatios = '1:1' | '16:9' | '5:2' | '16:10' | 'letter'

export interface EditOptions {
    active: boolean
    aspect_ratio: EditAspectRatios
    beforeEdit?: () => void
    afterEdit?: () => void
    restrictPosition?: boolean
}

type UploadType = 'sync' | 'async'

interface Props {
    onClose?: () => void
    onError?: () => void
    shape?: AssetOptions.Assets
    fileListDefault?: any[]
    uploadType?: UploadType
    name: NamePath
    label?: React.ReactNode
    extra?: React.ReactNode
    type?: keyof typeof FILE_SUPPORT
    onChange?: (any: UploadFile[]) => void
    uploadArea?: UploadArea
    fileOptions?: FileOptions
    rules?: UploadRules
    showHelp?: boolean
    style?: CSSProperties
    isEditing?: boolean
    editOptions?: EditOptions
    setError?: React.Dispatch<React.SetStateAction<{ message?: string; new: boolean }>>
    scope?: Scope
    strongLabel?: boolean
    includeCompressFiles?: boolean
}

type FileStatus = 'error' | 'uploading' | 'success' | 'initial'

export interface UploadFile {
    file: File
    status: FileStatus
    percent: number
    preview?: string
    id?: string
    conversion?: ConversionView
}

export const TuringoUpload = (props: Props) => {
    const {
        strongLabel = true,
        shape,
        scope = 'attachment',
        showHelp = true,
        fileOptions,
        uploadArea,
        rules,
        type,
        name,
        label,
        style,
        onClose,
        isEditing,
        editOptions,
        onChange,
        uploadType = 'sync',
        extra,
        includeCompressFiles,
    } = props

    const acceptedFiles = {
        ...(type == 'image' && { 'image/*': ['.jpeg', '.png', '.gif', '.webp'] }),
        ...(type == 'video' && { 'video/*': ['.mp4', '.avi', '.mpeg', '.webm', '.mov'] }),
        ...(type == 'attachment' && { 'application/pdf': ['.pdf'] }),
        ...(type == 'attachment' && { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] }),
        ...(type == 'attachment' && { 'application/msword': ['.docx'] }),
        ...(type == 'attachment' && { 'application/vnd.ms-excel': ['.xls'] }),
        ...(type == 'attachment' && { 'application/msexcel': ['.xls'] }),
        ...(type == 'attachment' && { 'application/x-msexcel': ['.xls'] }),
        ...(type == 'attachment' && { 'application/x-ms-excel': ['.xls'] }),
        ...(type == 'attachment' && { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls'] }),
        ...(type == 'attachment' && { 'application/vnd.ms-powerpoint': ['.ppt'] }),
        ...(type == 'attachment' && { 'application/vnd.ms-excel.sheet.macroenabled.12': ['.xlsm'] }),
        ...(type == 'attachment' && { 'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'] }),
        ...(type == 'attachment' && { 'text/plain': ['.txt'] }),
        ...(type == 'attachment' && { 'text/csv': ['.csv'] }),
        ...(includeCompressFiles && { 'application/x-rar-compressed': ['.rar'] }),
        ...(includeCompressFiles && { 'application/zip': ['.zip'] }),
    }

    const extensions = [...new Set(Object.values(acceptedFiles).flat())].join(',').replace(/\./g, ' ')

    const { form } = useContext(FormContext)
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    const id = v4()
    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme == 'dark'

    const [editVisible, setEditVisible] = useState(false)
    const [editFile, setEditFile] = useState<string>(undefined)
    const [xhr, setXhr] = useState<XMLHttpRequest>(undefined)

    const [files, setFiles] = useState<{
        [id: string]: UploadFile
    }>(props.fileListDefault || form.getFieldValue(name) || {})
    let setError
    let error
    if (!props.setError) {
        const errorState = useState<{ message?: string; new: boolean }>({ message: undefined, new: false })
        setError = errorState[1]
        error = errorState[0]
    } else {
        setError = props.setError
    }

    function getBase64(file): Promise<{ base64: string; width: number; height: number }> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                const img = new Image()
                img.onload = function () {
                    return resolve({ base64: reader.result as string, width: img.width, height: img.height })
                }
                img.src = reader.result as string
            }
            reader.onerror = (error) => reject(error)
        })
    }
    const cancelUpload = () => {
        if (xhr) {
            // Cancela la solicitud XHR si existe
            xhr.abort()
            setFiles({})
        }
    }
    const uploadFile = async (id: string, uploadFile: UploadFile) => {
        if (uploadType == 'async') {
            const URLPresigned = await API.upload({
                bodyParams: {
                    scope: scope,
                    mimetype: uploadFile.file?.type,
                    size: uploadFile.file?.size,
                    name: uploadFile.file?.name,
                },
            })

            const formData = new FormData()

            formData.append('acl', URLPresigned.data.presigned.bucketACL || 'private')
            formData.append('X-Amz-Credential', URLPresigned.data.presigned.bucketAccessKey)
            formData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256')
            formData.append('X-Amz-Date', URLPresigned.data.presigned.bucketDate)
            formData.append('key', URLPresigned.data.presigned.bucketKey)
            formData.append('Policy', URLPresigned.data.presigned.bucketPolicy)
            formData.append('X-Amz-Signature', URLPresigned.data.presigned.bucketSignature)
            formData.append('file', uploadFile.file)

            const updateProgress = function (id: string, evt: ProgressEvent<XMLHttpRequestEventTarget>) {
                setFiles((prev) => {
                    const percent = Math.round((evt.loaded / evt.total) * 100)

                    return {
                        ...prev,
                        [id]: {
                            file: uploadFile.file,
                            percent: percent,
                            status: 'uploading',
                            preview: uploadFile.preview,
                        },
                    }
                })
            }

            void new Promise(function (resolve, reject) {
                const newXHR = new XMLHttpRequest()
                setXhr(newXHR)
                newXHR.open('POST', URLPresigned.data.presigned.bucketUrl)
                newXHR.onload = async function () {
                    if (this.status >= 200 && this.status < 300) {
                        const is_upload = await API.uploadConfirm({
                            urlParams: {
                                token: URLPresigned.data.id,
                            },
                        })

                        setFiles((prev) => {
                            return {
                                ...prev,
                                [id]: {
                                    file: uploadFile.file,
                                    percent: 100,
                                    status: 'success',
                                    preview: uploadFile.preview,
                                    id: is_upload.data.id,
                                    conversion: is_upload.data.conversion,
                                },
                            }
                        })

                        resolve(newXHR.response)
                    } else {
                        reject({
                            status: this.status,
                            statusText: newXHR.statusText,
                        })
                    }
                }
                newXHR.upload.addEventListener('progress', (evt: ProgressEvent<XMLHttpRequestEventTarget>) => updateProgress(id, evt), false)

                newXHR.onerror = function () {
                    reject({
                        status: this.status,
                        statusText: newXHR.statusText,
                    })
                }
                newXHR.send(formData)
            })
        } else {
            setFiles((prev) => {
                return {
                    ...prev,
                    [id]: {
                        file: uploadFile.file,
                        percent: 100,
                        status: 'success',
                        preview: uploadFile.preview,
                    },
                }
            })
        }
    }
    const readFile = (file: File): Promise<string> =>
        new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result as string), false)
            reader.readAsDataURL(file)
        })

    const onDrop = useCallback(async (acceptedFiles: Array<File>, fileRejections: FileRejection[]) => {
        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === 'file-too-large') {
                    if (type == 'image') {
                        setError({
                            message:
                                rules?.maxFiles == 1
                                    ? strings.screens.boards.discussion.post.kind.image.upload.single.errors.size
                                    : (strings.formatString(
                                          strings.screens.boards.discussion.post.kind.image.upload.multiple.errors.size,
                                          rules?.maxFiles,
                                          formatMB(rules?.maxSize) as any
                                      ) as string),
                            new: true,
                        })
                    } else if (type == 'video') {
                        setError({
                            message: strings.formatString(strings.screens.boards.discussion.post.kind.video.upload.errors.size, formatMB(rules?.maxSize)),
                            new: true,
                        })
                    } else if (type == 'attachment') {
                        setError({
                            message: strings.formatString(strings.screens.boards.discussion.post.kind.attachment.upload.errors.size, formatMB(rules?.maxSize)),
                            new: true,
                        })
                    }
                }

                if (err.code === 'file-invalid-type') {
                    if (type == 'image') {
                        setError({
                            message:
                                rules?.maxFiles == 1
                                    ? strings.screens.boards.discussion.post.kind.image.upload.single.errors.format
                                    : strings.screens.boards.discussion.post.kind.image.upload.multiple.errors.format,
                            new: true,
                        })
                    } else if (type == 'video') {
                        setError({ message: strings.screens.boards.discussion.post.kind.video.upload.errors.format, new: true })
                    } else if (type == 'attachment') {
                        setError({
                            message: strings.formatString(strings.screens.boards.discussion.post.kind.attachment.upload.errors.format, extensions),
                            new: true,
                        })
                    }
                }
            })
        })
        for (const newfile of acceptedFiles) {
            setError({ message: undefined, new: false })

            if (type == 'image') {
                if (editOptions?.active) {
                    const imageDataUrl = await readFile(newfile)
                    setEditFile(imageDataUrl)
                    setEditVisible(true)
                    break
                }

                const file: UploadFile = {
                    file: newfile,
                    preview: (await getBase64(newfile)).base64,
                    status: 'initial',
                    percent: 0,
                }
                await uploadFile(v4(), file)
            } else {
                const file: UploadFile = {
                    file: newfile,
                    status: 'initial',
                    percent: 0,
                }
                await uploadFile(v4(), file)
            }
        }
    }, [])

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        maxFiles: rules?.maxFiles || 1,
        maxSize: rules?.maxSize * 1000000 || 1000000,
        accept: acceptedFiles,
    })

    const gallery = {
        [1]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
        },
        [2]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 7',
        },
        [3]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 4',
            [2]: '4 / 7',
        },
        [4]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 3',
            [2]: '3 / 5',
            [3]: '5 / 7',
        },
    }

    const uploadAreaComponent = (
        <div
            style={{
                cursor: 'pointer',
                width: '100%',
                backgroundColor: Colors('FORM_INPUT_AREA_BACKGROUND'),
                height: 142,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                position: 'relative',
                ...uploadArea?.style,
            }}
        >
            <div style={{ marginTop: 2, display: 'flex', alignItems: 'center' }}>
                <TuringoButton
                    icon={type == 'image' ? <AddImageIcon /> : type == 'video' ? <AddVideoIcon /> : <AddAttachmentIcon />}
                    shape={uploadArea?.btnShape ? uploadArea?.btnShape : breakpoints.xs || breakpoints.xxs ? 'circle' : 'standard'}
                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    {uploadArea?.btnShape != 'circle' && !breakpoints.xs && !breakpoints.xxs && (
                        <span style={{ marginLeft: 0 }}>
                            {rules?.maxFiles == 1
                                ? type == 'image'
                                    ? strings.screens.boards.discussion.post.kind.image.upload.single.title
                                    : type == 'video'
                                    ? strings.screens.boards.discussion.post.kind.video.upload.title
                                    : strings.screens.boards.discussion.post.kind.attachment.upload.title
                                : type == 'image'
                                ? strings.screens.boards.discussion.post.kind.image.upload.multiple.title
                                : type == 'video'
                                ? strings.screens.boards.discussion.post.kind.video.upload.title
                                : strings.screens.boards.discussion.post.kind.attachment.upload.title}
                        </span>
                    )}
                </TuringoButton>
            </div>
            {!isMobile && uploadArea?.showDescription && (
                <div style={{ fontSize: 12 }}>
                    {rules?.maxFiles == 1
                        ? type == 'image'
                            ? strings.screens.boards.discussion.post.kind.image.upload.single.description
                            : type == 'video'
                            ? strings.screens.boards.discussion.post.kind.video.upload.description
                            : strings.screens.boards.discussion.post.kind.attachment.upload.description
                        : type == 'image'
                        ? strings.screens.boards.discussion.post.kind.image.upload.multiple.description
                        : type == 'video'
                        ? strings.screens.boards.discussion.post.kind.video.upload.description
                        : strings.screens.boards.discussion.post.kind.attachment.upload.description}
                </div>
            )}
        </div>
    )
    const isRequired = rules?.ant?.find((o) => o.required)
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e
        }
        return e?.fileList
    }

    const upload = async (key, files) => {
        await uploadFile(key, files)
    }

    useEffect(() => {
        setError(undefined)
        for (const key of Object.keys(files)) {
            if (files[key].status == 'initial') {
                void upload(key, files[key])
            }
        }

        if (onChange) {
            onChange(Object.values(files).slice(0, rules?.maxFiles))
        } else {
            form?.setFieldValue(name, Object.values(files).slice(0, rules?.maxFiles))
        }
    }, [files])

    return (
        <>
            {editOptions?.active && (
                <EditImage
                    restrictPosition={props.editOptions?.restrictPosition}
                    title={strings.screens.boards.discussion.post.kind.image.upload.edit}
                    open={editVisible}
                    onCancel={() => {
                        setEditVisible(false)
                        if (props.editOptions?.afterEdit) props.editOptions?.afterEdit()
                    }}
                    afterEdit={props.editOptions?.afterEdit}
                    file={editFile}
                    setFile={setFiles}
                    aspect_ratio={props.editOptions?.aspect_ratio}
                />
            )}
            {error?.message && isMobile && (
                <Alert
                    showIcon
                    type="error"
                    message={error.message}
                    style={{
                        borderRadius: 4,
                        marginBottom: 4,
                        width: '100%',
                    }}
                ></Alert>
            )}
            <Form.Item
                help={<></>}
                rules={[
                    ...(isRequired
                        ? [
                              {
                                  validator: (rule: any, value: string) => {
                                      if (value && value.length > 0 && value.length <= rules?.maxFiles) {
                                          return Promise.resolve()
                                      }
                                      if (!value) {
                                          return Promise.resolve()
                                      }

                                      if (error && error.new) {
                                          setError({ ...error, new: false })
                                          return Promise.reject(error)
                                      } else if (props.uploadType === 'async' && props.type === 'video') {
                                          // Clear the error but we're still not ready.
                                          setError(undefined)
                                          return Promise.reject()
                                      } else {
                                          setError({ message: isRequired.message, new: false })
                                          return Promise.reject(isRequired.message)
                                      }
                                  },
                              },
                          ]
                        : []),
                ]}
                className={'turingo-upload'}
                name={name}
                label={
                    label && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: (onClose && !isEditing) || extra ? 'space-between' : 'start',
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {isRequired && (
                                    <Paragraph style={{ color: '#FF4D2E', fontSize: 10, marginRight: 4 }} strong={strongLabel}>
                                        {'*'}
                                    </Paragraph>
                                )}

                                <Paragraph strong={strongLabel}>{label}</Paragraph>
                                {showHelp && label && (
                                    <Tooltip
                                        placement="right"
                                        title={
                                            rules?.maxFiles == 1
                                                ? type == 'image'
                                                    ? strings.formatString(
                                                          strings.screens.boards.discussion.post.kind.image.upload.single.help,
                                                          formatMB(rules?.maxSize)
                                                      )
                                                    : type == 'video'
                                                    ? strings.formatString(
                                                          strings.screens.boards.discussion.post.kind.video.upload.help,
                                                          formatMB(rules?.maxSize)
                                                      )
                                                    : strings.formatString(
                                                          strings.screens.boards.discussion.post.kind.attachment.upload.help,
                                                          extensions,
                                                          formatMB(rules?.maxSize)
                                                      )
                                                : type == 'image'
                                                ? strings.formatString(
                                                      strings.screens.boards.discussion.post.kind.image.upload.multiple.help,
                                                      rules?.maxFiles,
                                                      formatMB(rules?.maxSize) as any
                                                  )
                                                : type == 'video'
                                                ? strings.formatString(strings.screens.boards.discussion.post.kind.video.upload.help, formatMB(rules?.maxSize))
                                                : strings.formatString(
                                                      strings.screens.boards.discussion.post.kind.attachment.upload.help,
                                                      extensions,
                                                      formatMB(rules?.maxSize)
                                                  )
                                        }
                                    >
                                        <InfoCircleOutlined
                                            onClick={(event) => event.stopPropagation()}
                                            style={{ fontSize: isMobile ? 18 : 16, marginLeft: 4 }}
                                        />
                                    </Tooltip>
                                )}
                            </div>

                            {extra && extra}
                            {onClose && !isEditing && (
                                <Button
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        onClose()
                                    }}
                                    style={{ width: 30, height: 30 }}
                                    type="text"
                                    icon={<Close style={{ display: 'flex', justifyContent: 'center' }} />}
                                ></Button>
                            )}
                        </div>
                    )
                }
                valuePropName="list"
                getValueFromEvent={normFile}
                style={{ width: '100%', ...style }}
            >
                <div style={{ maxWidth: uploadArea?.style?.maxWidth ? uploadArea?.style?.maxWidth : 'none' }}>
                    <div id={id} {...getRootProps()}>
                        <input {...getInputProps()} multiple />
                        {Object.keys(files).length == 0 && uploadAreaComponent}
                    </div>

                    <div
                        style={{
                            background: fileOptions?.showTransparency
                                ? `url(${isDark ? TransparencyDark : TransparencyLight}) repeat`
                                : fileOptions?.showBackground
                                ? 'black'
                                : 'transparence',
                            width: '100%',
                            position: 'relative',
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                            columnGap: '8px',
                            rowGap: '8px',
                        }}
                    >
                        {files && Object.keys(files).length != 0 && Object.keys(files).length < rules?.maxFiles && !isEditing && (
                            <div
                                style={{
                                    position: 'relative',
                                    ...(Object.keys(files) && {
                                        gridColumn:
                                            type == 'attachment'
                                                ? '1 / 7'
                                                : gallery[files && Object.keys(files).length > rules?.maxFiles ? rules?.maxFiles : Object.keys(files).length][
                                                      'add'
                                                  ],
                                    }),
                                    display: 'flex',
                                    flexDirection: 'column',
                                    backgroundColor: Colors('FORM_INPUT_AREA_BACKGROUND'),
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    ...uploadArea?.style,
                                }}
                            >
                                <TuringoButton
                                    shape={breakpoints.xs || breakpoints.xxs ? 'circle' : 'standard'}
                                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    onClick={() => {
                                        document.getElementById(id).click()
                                    }}
                                    icon={type == 'image' ? <AddImageIcon /> : type == 'video' ? <AddVideoIcon /> : <AddAttachmentIcon />}
                                >
                                    {!breakpoints.xs && !breakpoints.xxs && (
                                        <span style={{ marginLeft: 0 }}>
                                            {rules?.maxFiles == 1
                                                ? type == 'image'
                                                    ? strings.screens.boards.discussion.post.kind.image.upload.single.title
                                                    : type == 'video'
                                                    ? strings.screens.boards.discussion.post.kind.video.upload.title
                                                    : strings.screens.boards.discussion.post.kind.attachment.upload.title
                                                : type == 'image'
                                                ? strings.screens.boards.discussion.post.kind.image.upload.multiple.title
                                                : type == 'video'
                                                ? strings.screens.boards.discussion.post.kind.video.upload.title
                                                : strings.screens.boards.discussion.post.kind.attachment.upload.title}
                                        </span>
                                    )}
                                </TuringoButton>
                                {!isMobile && uploadArea?.showDescription && type == 'attachment' && (
                                    <div style={{ fontSize: 12 }}>{strings.screens.boards.discussion.post.kind.attachment.upload.description}</div>
                                )}
                            </div>
                        )}

                        {files &&
                            Object.keys(files)
                                .slice(0, rules?.maxFiles)
                                .map((key, index) => {
                                    return (
                                        <ListItem
                                            uploadType={uploadType}
                                            cancelUpload={cancelUpload}
                                            uploadArea={uploadArea}
                                            showAdd={files && Object.keys(files).length != 0 && Object.keys(files).length < props.rules?.maxFiles && !isEditing}
                                            fileStyle={fileOptions?.style}
                                            key={key}
                                            maxFiles={rules?.maxFiles}
                                            index={index}
                                            file={files[key]}
                                            items={Object.values(files).slice(0, rules?.maxFiles)}
                                            onClose={() => {
                                                setFiles((prev) => {
                                                    delete prev[key]
                                                    return { ...prev }
                                                })
                                            }}
                                            filetype={type}
                                            isEditing={isEditing}
                                        />
                                    )
                                })}
                    </div>
                </div>
            </Form.Item>
            {error?.message && !isMobile && (
                <Alert
                    showIcon
                    type="error"
                    message={error.message}
                    style={{
                        borderRadius: 4,
                        marginBottom: 16,
                        width: '100%',
                    }}
                ></Alert>
            )}
        </>
    )
}
