import React from 'react'
import useBreakpoints from '@hooks/useBreakpoints'
import style from './style.scss'
import { PlusOutlined } from '@ant-design/icons'

export const TopicAddButton = (props: { title: string; onClick: () => void; disabled?: boolean }) => {
    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile

    return (
        <div
            style={{ marginRight: 4, height: 'fit-content' }}
            className={props.disabled ? (isMobile ? style.itemAddMobileDisabled : style.itemAddDisabled) : isMobile ? style.itemAddMobile : style.itemAdd}
            onClick={props.disabled ? undefined : props.onClick}
        >
            <PlusOutlined style={{ fontSize: isMobile ? 14 : 10 }} /> {props.title}
        </div>
    )
}
