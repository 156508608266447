// Libraries

// React
import React from 'react'

// Antd
import { } from 'antd'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { EntityModel } from '@model/index'
import Title from 'antd/lib/typography/Title'
import useBreakpoints from '@hooks/useBreakpoints'
import { TeamView } from '@api/model/responses/team/TeamView'
import { TuringoTag } from '@components/content/tag/TuringoTag'
import { capitalizeFirstLetter } from '@util/string/Functions'

// MARK: - Definition

namespace EntityName {
    export interface Props {
        visibility?: string
        name: string
        teams?: TeamView[]
    }
}

// MARK: - Implementation

const EntityName: React.FC<EntityName.Props> = (props: EntityName.Props) => {
    const { visibility, name, teams } = props
    const breakpoints = useBreakpoints()

    const teamWithBadge = teams?.find((t) => t.badge)


    return (
        <div style={{ display: 'flex', marginBottom: 8, alignItems: 'center', padding: breakpoints.isSmall ? '0px 16px' : '0px 24px' }}>
            {visibility == 'private' && <LockOutlined style={{ fontSize: 16, marginRight: 8 }} />}
            {visibility == 'public' && <UnlockOutlined style={{ fontSize: 16, marginRight: 8 }} />}

            <Title level={4} style={{ marginBottom: 0 }}>
                {name}
            </Title>

            {teamWithBadge && teamWithBadge.badge.title && (
                <TuringoTag kind="tag" color="neutral" size="small" key={teamWithBadge.badge.title} style={{  marginLeft: 8 }}>
                    {capitalizeFirstLetter(teamWithBadge.badge.title)}
                </TuringoTag>
            )}

        </div>
    )
}

export { EntityName }
