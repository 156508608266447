// Libraries

// React
import React, { CSSProperties } from 'react'

// Antd
import { } from 'antd'

// MARK: - Definition

namespace RatioImage {
    export interface Props {
        src?: string
        style?: CSSProperties
        maxWidth?: number,
        iconPark?: ({ props }: any) => JSX.Element
        iconAnt?: ({ props }: any) => JSX.Element
    }
}

// MARK: - Implementation

const RatioImage: React.FC<RatioImage.Props> = (props: RatioImage.Props) => {
    const { src, maxWidth, style } = props
    return (
        <div style={{ paddingTop: '56.2%', position: 'relative', maxWidth: maxWidth, ...style, }}>
            {src && (
                <img
                    alt="example"
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        borderRadius: 4,
                        
                    }}
                    src={src}
                    loading="eager"
                />
            )}
            {!src && (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        borderRadius: 4,
                        justifyContent: 'center',
                        backgroundColor: 'var(--neutral-3)',
                        ...style,
                    }}
                >
                    {props.iconAnt && <props.iconAnt style={{ fontSize: 56, alignSelf: 'center', color: 'var(--neutral-7)' }} />}

                    {props.iconPark && (
                        <props.iconPark
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            theme="outline"
                            size={56}
                            fill={'var(--neutral-7)'}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export { RatioImage }
