import { FileTextOutlined, FileZipOutlined, FilePdfOutlined, FileWordOutlined, FileOutlined, FileExcelOutlined, FilePptOutlined } from '@ant-design/icons'
import { formatBytes } from '@util/string/Functions'
import { Colors } from '@util/color/Colors'
import { Attachment } from '@api/model/responses/post/PostView'
import { Button, Spin } from 'antd'
import { Download } from '@icon-park/react'
import { RootReducer } from '@state/reducers/Root'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { pdfjs } from 'react-pdf'

/* pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()
 */
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import { Document, Page } from 'react-pdf'
import { SizeMe } from 'react-sizeme'
import { CSSProperties, useState } from 'react'
import Paragraph from 'antd/lib/typography/Paragraph'
import { ArrowRightIcon } from '@components/icons/ArrowRightIcon'
import { ArrowLeftIcon } from '@components/icons/ArrowLeftIcon'
import useBreakpoints from '@hooks/useBreakpoints'
interface Props {
    attachment: Attachment
    showPreview?: boolean
}

export const GetTypeIcon = (type: string, style: CSSProperties = { fontSize: 24, marginRight: 16, color: Colors('ICON') }) => {
    return type == 'application/pdf' ? (
        <FilePdfOutlined style={style} />
    ) : type == 'application/vnd.ms-excel' ||
      type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      type == 'application/msexcel' ||
      type == 'application/x-msexcel' ||
      type == 'application/x-ms-excel' ? (
        <FileExcelOutlined style={style} />
    ) : type == 'application/vnd.ms-powerpoint' || type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ? (
        <FilePptOutlined style={style} />
    ) : type == 'application/msword' || type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
        <FileWordOutlined style={style} />
    ) : type == 'text/plain' || type == 'text/csv' ? (
        <FileTextOutlined style={style} />
    ) : type == 'application/x-rar-compressed' || type == 'application/zip' ? (
        <FileZipOutlined style={style} />
    ) : (
        <FileOutlined style={style} />
    )
}

export const AttachmentComponent = (props: Props) => {
    const { attachment, showPreview = true } = props
    const type = attachment.mimeType

    const isLogged = useAppSelector((state) => !!state.auth.authToken)
    const dispatch = useAppDispatch()
    const [numPages, setNumPages] = useState(1)
    const breakpoints = useBreakpoints()
    const [page, setPage] = useState(1)

    function onDocumentLoadSuccess(pdf) {
        setNumPages(pdf?.numPages)
    }
    const hasNextPage = page < numPages
    const hasPreviousPage = page > 1

    const nextPage = () => {
        if (hasNextPage) setPage(page + 1)
    }
    const previousPage = () => {
        if (hasPreviousPage) setPage(page - 1)
    }

    if (type == 'application/pdf' && showPreview) {
        return (
            <div>
                <div style={{ backgroundColor: 'black', display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative' }}>
                    <div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
                        <Button
                            className={hasPreviousPage ? 'hover-svg' : 'disabled-svg'}
                            icon={<ArrowLeftIcon height={14} width={14} color="white" style={{ display: 'flex' }} />}
                            type="text"
                            disabled={!hasPreviousPage}
                            onClick={previousPage}
                        ></Button>
                        <Paragraph className="turingo-text" style={{ color: 'white' }}>{`${page}/${numPages}`}</Paragraph>
                        <Button
                            className={hasNextPage ? 'hover-svg' : 'disabled-svg'}
                            icon={<ArrowRightIcon color="white" style={{ display: 'flex' }} />}
                            type="text"
                            disabled={!hasNextPage}
                            onClick={nextPage}
                        ></Button>
                    </div>
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                            onClick={() => {
                                !isLogged && dispatch(modalAction({ modal: 'see_more', state: true }))
                            }}
                            type="text"
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            href={isLogged ? attachment.url : undefined}
                            icon={<Download style={{ display: 'flex', color: 'white' }} theme="outline" size="16" />}
                        ></Button>
                    </div>
                </div>
                <div style={{ padding: 16, backgroundColor: 'var(--neutral-8)' }}>
                    <div style={{ maxHeight: 600, maxWidth: !breakpoints.isMobile ? 584 : '100vw', overflowY: 'auto', boxShadow: '0px 3px 14px 0px' }}>
                        <SizeMe>
                            {({ size }) => (
                                <Document
                                    loading={
                                        <div style={{ justifyContent: 'center', display: 'flex', padding: 24, backgroundColor: 'white' }}>
                                            <Spin></Spin>
                                        </div>
                                    }
                                    file={attachment.url}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={page} renderAnnotationLayer={false} renderTextLayer={false} width={size.width ? size.width : 1} />
                                </Document>
                            )}
                        </SizeMe>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            style={{
                backgroundColor: Colors('POSTED_POLL_BACKGROUND'),
                minHeight: 68,
                padding: 16,
                marginBottom: 8,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {GetTypeIcon(type)}
            <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 50, flexGrow: 1, wordBreak: 'break-all' }}>
                <div style={{ fontWeight: 500, lineHeight: '22px' }}>{attachment.name}</div>
                {attachment.size && <div style={{ fontSize: 12, lineHeight: '20px' }}>{formatBytes(attachment.size)}</div>}
            </div>
            <div>
                <Button
                    onClick={() => {
                        !isLogged && dispatch(modalAction({ modal: 'see_more', state: true }))
                    }}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    href={isLogged ? attachment.url : undefined}
                    shape="circle"
                    icon={<Download style={{ display: 'flex' }} theme="outline" size="16" />}
                ></Button>
            </div>
        </div>
    )
}
