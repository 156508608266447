import { Plus } from '@icon-park/react'
import { EntityModel } from '@model/index'
import { rateList } from '@state/actions'
import { RootReducer } from '@state/reducers/Root'
import { BoardRouteParams } from '@typings/routing/Router'
import { Button, Card, Divider } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import { useParams } from 'react-router'
import { AlertDashed } from '@components/alerts/alert_dashed/AlertDashed'
import { StarFilled } from '@ant-design/icons'
import { PushpinOutlined } from '@ant-design/icons'
import { CompleteCourseModal } from '@components/content/certificate_supplementary/CompleteCourseModal'
import { Review } from '@components/content/posts/post_subtypes/reviews/Review'
import strings from '@resources/localization'
import { PostSkeleton } from '@components/skeletons/components/PostSkeleton/PostSkeleton'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ListPost } from '@components/content/posts/list_post/ListPosts'

interface Props {
    kind: EntityModel.Kind
    canRate: boolean
    id?: string
}

export const EntityReviews = (props: Props): ReactElement => {
    const { kind, canRate, id } = props
    const [visible, setVisible] = useState(false)
    const { cm_pk, b_pk } = useParams<BoardRouteParams>()

    const course = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk])

    const community = useAppSelector((state) => state.community.items[cm_pk])

    const handleShow = () => {
        setVisible(true)
    }
    const dispatch = useAppDispatch()
    const handleCancel = () => {
        setVisible(false)
    }
    const isLogged = useAppSelector((state) => !!state.auth.authToken)

    useEffect(() => {
        fetchData(false)
    }, [])

    const fetchData = (pagination: boolean) => {
        void dispatch(
            rateList({
                urlParams: { cm_pk, b_pk },
                headerParams: {
                    page: 'first',
                },
                searchParams: {
                    kind: 'rate',
                },
            })
        )
    }
    if (!course.rates || !course.rates.items || !course.rates.listState) return <></>
    return (
        <>
            <CompleteCourseModal open={visible} onCancel={handleCancel} />
            <Card
                bordered={false}
                id={id}
                bodyStyle={{ padding: 1 }}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {strings.rates.title}

                        {course.item.course?.rate?.total > 0 && course.item.course?.rate?.average > 0 && (
                            <div style={{ marginLeft: 8, marginRight: 8, lineHeight: '17px' }}>{'•'}</div>
                        )}

                        {course.item.course?.rate?.total > 0 && course.item.course?.rate?.average > 0 && (
                            <StarFilled style={{ color: '#13C2C2', fontSize: 16, marginRight: 4 }} />
                        )}

                        {course.item.course?.rate?.total > 0 && course.item.course?.rate?.average > 0 && course.item.course?.rate?.average.toFixed(1)}
                        {course.item.course?.rate?.total > 0 && (
                            <span style={{ color: 'var(--neutral-7)', marginLeft: 4 }}> {`(${course.item.course?.rate?.total})`} </span>
                        )}
                    </div>
                }
                extra={
                    <Button onClick={handleShow} disabled={!canRate || !isLogged}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Plus style={{ display: 'flex', marginRight: 4 }} /> {strings.rates.createAction}
                        </div>
                    </Button>
                }
            >
                {Object.keys(course.rates.items).length == 0 && (
                    <div key={'rates#empty'} style={{ padding: '16px 24px' }}>
                        <AlertDashed
                            message={strings.formatString(
                                strings.screens.boards[kind].rates.empty_subscriptor,
                                community?.item?.ui?.rename?.[kind]?.single?.toLowerCase() || strings.general[kind].singular.toLowerCase()
                            )}
                            icon={<PushpinOutlined />}
                        />
                    </div>
                )}

                <InfiniteScroll
                    dataLength={Object.keys(course.rates.items).length}
                    next={() => {
                        void dispatch(
                            rateList({
                                urlParams: { cm_pk, b_pk },
                                headerParams: {
                                    page: course.rates.listState?.pagination,
                                },
                                searchParams: {
                                    kind: 'rate',
                                },
                            })
                        )
                    }}
                    hasMore={(course.rates.listState.pagination && course.rates.listState.pagination != 'first') || course.rates.listState.loading}
                    loader={!isLogged ? null : <PostSkeleton />}
                >
                    <ListPost divider items={course.rates.items} />
                </InfiniteScroll>

                {/*   <InfiniteScroll
                    dataLength={Object.keys(course.rates.items).length}
                    next={() => {
                        dispatch(
                            rateList({
                                urlParams: { cm_pk, b_pk },
                                headerParams: {
                                    page: course.rates.listState?.pagination,
                                },
                                searchParams: {
                                    kind: 'rate',
                                },
                            })
                        )
                    }}
                    hasMore={(course.rates.listState.pagination && course.rates.listState.pagination != 'first') || course.rates.listState.loading}
                    loader={!isLogged ? null : <PostSkeleton />}
                >
                    {Object.keys(course.rates.items).map((key: string, index: number) => (
                        <span key={`review#${course.rates.items[key].item.publicKey}`}>
                            <Review review={course.rates.items[key].item} />
                            {index != Object.keys(course.rates.items).length - 1 && (
                                <Divider
                                    style={{
                                        width: '92%',
                                        minWidth: '92%',
                                        margin: '0px 0px 0px 4%',
                                    }}
                                ></Divider>
                            )}
                        </span>
                    ))}
                </InfiniteScroll> */}
            </Card>
        </>
    )
}
