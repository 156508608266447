import { Colors } from '@util/color/Colors'
import React, { CSSProperties } from 'react'

import Icon from '@ant-design/icons'

interface Props {
    style?: CSSProperties
    className?: string
    onClick?: () => void
    color?: string
    filled?: boolean
    height?: number
    width?: number
}

export const LikeIcon = (props: Props) => {
    return (
        <Icon
            component={() => {
                if (props.filled) {
                    return (
                        <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" width={'100%'} fill={'currentColor'}>
                            <path d="M17.4127 9.4814C17.7907 8.99687 18 8.40321 18 7.78554C18 6.80556 17.4352 5.87797 16.5262 5.3607C16.2922 5.22755 16.0258 5.15747 15.7545 5.15772H9.207L9.83025 2.30289C10.1265 -0.529093 6.33101 -0.837428 5.562 1.87075C4.79299 4.57894 3.62925 8.65857 3.62925 8.65857H3.6225V18H14.2492C14.4562 18 14.6587 17.9607 14.8455 17.8821C15.9165 17.4391 16.6073 16.4242 16.6073 15.298C16.6073 15.023 16.5667 14.7523 16.4858 14.4904C16.8637 14.0059 17.073 13.4122 17.073 12.7946C17.073 12.5196 17.0325 12.2489 16.9515 11.987C17.3295 11.5025 17.5387 10.9088 17.5387 10.2911C17.5342 10.0161 17.4937 9.74331 17.4127 9.4814ZM0 9.357V17.3016C0 17.6879 0.32175 18 0.72 18H2.1825V8.65857H0.72C0.32175 8.65857 0 8.97068 0 9.357Z" />
                        </svg>
                    )
                } else {
                    return (
                        <svg viewBox="0 1 18 20" width={'100%'} fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3463 10.4838C17.7213 9.9883 17.9289 9.38116 17.9289 8.74946C17.9289 7.74723 17.3687 6.79857 16.4669 6.26955C16.2347 6.13338 15.9704 6.06171 15.7012 6.06196H10.3486L10.4825 3.31865C10.5137 2.65571 10.2794 2.02624 9.82401 1.54633C9.60054 1.30979 9.33094 1.12159 9.03186 0.993354C8.73278 0.865122 8.41058 0.799587 8.08517 0.800798C6.92446 0.800798 5.89767 1.58205 5.58964 2.70035L3.67222 9.64232H0.786063C0.390974 9.64232 0.0717773 9.96151 0.0717773 10.3566V18.4816C0.0717773 18.8767 0.390974 19.1959 0.786063 19.1959H14.2079C14.4133 19.1959 14.6142 19.1557 14.7995 19.0753C15.862 18.6222 16.5472 17.5843 16.5472 16.4325C16.5472 16.1512 16.507 15.8745 16.4267 15.6066C16.8017 15.1111 17.0093 14.5039 17.0093 13.8722C17.0093 13.591 16.9691 13.3142 16.8887 13.0463C17.2637 12.5508 17.4713 11.9437 17.4713 11.312C17.4669 11.0307 17.4267 10.7517 17.3463 10.4838ZM1.67892 17.5887V11.2495H3.48696V17.5887H1.67892ZM15.8843 9.70928L15.3954 10.1334L15.7057 10.7004C15.8079 10.8871 15.8609 11.0968 15.8597 11.3097C15.8597 11.678 15.699 12.0285 15.4222 12.2695L14.9334 12.6937L15.2437 13.2606C15.3459 13.4474 15.3989 13.6571 15.3977 13.87C15.3977 14.2383 15.237 14.5887 14.9602 14.8298L14.4713 15.2539L14.7816 15.8209C14.8838 16.0077 14.9368 16.2174 14.9356 16.4303C14.9356 16.9303 14.641 17.3812 14.1856 17.5865H4.91553V11.178L7.13651 3.13116C7.19378 2.92491 7.31672 2.74294 7.4867 2.61284C7.65667 2.48274 7.86443 2.41159 8.07847 2.41017C8.24812 2.41017 8.41553 2.45928 8.54946 2.55973C8.77044 2.7249 8.88874 2.9749 8.87535 3.24053L8.66106 7.6691H15.6789C16.0762 7.91241 16.3218 8.32312 16.3218 8.74946C16.3218 9.11776 16.1611 9.46598 15.8843 9.70928Z" />
                        </svg>
                    )
                }
            }}
            {...props}
        />
    )
}
