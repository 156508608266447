import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface PinIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const PinIcon = (props: PinIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill={props.color ? props.color : 'var(--neutral-13)'}>
                    <path
                        d="M12.0081 5.36074L8.63938 1.99199C8.55052 1.90312 8.4343 1.85938 8.31809 1.85938C8.20188 1.85938 8.08567 1.90312 7.9968 1.99199L5.79426 4.1959C5.62747 4.17676 5.4593 4.16855 5.29114 4.16855C4.29036 4.16855 3.28958 4.49805 2.4679 5.15703C2.25735 5.3252 2.24094 5.64102 2.43098 5.83242L4.91516 8.3166L1.97024 11.2588C1.93415 11.2947 1.91188 11.3421 1.90735 11.3928L1.86087 11.9014C1.84856 12.0299 1.9511 12.1393 2.07825 12.1393C2.08509 12.1393 2.09192 12.1393 2.09876 12.1379L2.60735 12.0914C2.65794 12.0873 2.70579 12.0641 2.74134 12.0285L5.68626 9.08359L8.17044 11.5678C8.2593 11.6566 8.37551 11.7004 8.49173 11.7004C8.62434 11.7004 8.75559 11.643 8.84583 11.5309C9.61555 10.5697 9.93548 9.3666 9.80559 8.20312L12.0081 6.00059C12.1845 5.82559 12.1845 5.53848 12.0081 5.36074Z"
                    />
                </svg>
            )}
            {...props}
        />
    )
}
