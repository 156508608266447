// Modules
import React, { useState } from 'react'
import { Form, Modal } from 'antd'
import { useAppDispatch, useAppSelector } from '@hooks/useStore'
import strings from '@resources/localization'
import { modalAction } from '@state/reducers/Modal/ModalReducer'
import { RootReducer } from '@state/reducers/Root'
import { authAddEmail } from '@state/actions'
import { useRequestState } from '@api/requests/AsyncRequests'
import { InformationModal } from './information_modal/InformationModal'
import { useHistory } from 'react-router'
import { Router } from '@Router'
import { FooterModal } from './components/FooterModal'
import { InformationModalBody } from './information_modal/InformationModalBody'
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal'

export const SeeMoreModal = () => {
    const seeMoreModal = useAppSelector((state) => state.modal.see_more)

    const dispatch = useAppDispatch()

    const closeModal = () => {
        dispatch(modalAction({ modal: 'see_more', state: false }))
    }

    const onLogin = () => {
        history.push(Router.Path.login({}))
        dispatch(modalAction({ modal: 'see_more', state: false }))
    }

    const onSignup = () => {
        history.push(Router.Path.signup({}))
        dispatch(modalAction({ modal: 'see_more', state: false }))
    }

    const history = useHistory()

    return (
        <TuringoModal
            open={seeMoreModal}
            onCancel={closeModal}
            width={600}
            footer={[
                <FooterModal
                    key={'footer_modal'}
                    primary={{
                        action: onSignup,
                        customText: strings.screens.auth.signup.action,
                    }}
                    secondary={{
                        action: onLogin,
                        customText: strings.screens.auth.login.action,
                    }}
                />,
            ]}
        >
            <InformationModalBody
                kind={'warning'}
                title={'Únete a la comunidad'}
                description={'Podrás acceder a las publicaciones de otras personas emprendedoras.'}
            />
        </TuringoModal>
    )
}
