import { EntityAllView } from '../entity/EntityDetailsView'
import { UserView } from '../user/UserView'

/**
 * User Account (ONLY for authentication)
 *
 * This is different from a `User` which is a proper user that uses the platform.
 */
export interface AuthAccount {
    name: {
        first: string
        last: string
    }
    dateCreated: Date
}

export function instanceOfLogin(object: any): object is AuthLogin {
    return 'authToken' in object
}

/**
 * Login information
 */
export interface AuthLogin {
    user: UserView
    authEmail: string
    authValidation: string
    account: AuthAccount
    authToken: string
}

export interface OAuthEmailView {
    token: string
    userEmail: string
}

export interface OAuthList {
    password: boolean
    social: {
        facebook: boolean
        google: boolean
        linkedin: boolean
    }
}
export interface ValidationStatusView {
    authValidation: string
}
