// Libraries

// React
import React from 'react'

// Antd
import {} from 'antd'
import { BoardView } from '@api/model/responses/board/BoardView'
import { ProgressStep } from '../progress/ProgressStep'
import { useAppSelector } from '@hooks/useStore'
import { DegreeHat } from '@icon-park/react'

// MARK: - Definition

namespace BoardCardHeader {
    export interface Props {
        item: BoardView
        showProgressBar?: boolean
    }
}

// MARK: - Implementation

const BoardCardHeader: React.FC<BoardCardHeader.Props> = (props: BoardCardHeader.Props) => {
    const { item, showProgressBar } = props
    const theme = useAppSelector((state) => state.ui.theme)
    const isDark = theme == 'dark'
    const isCompleted =
        item?.progress?.lessonOverallCount &&
        item?.progress?.lessonCompletedCount &&
        item?.progress?.lessonOverallCount == item?.progress?.lessonCompletedCount &&
        item?.progress?.lessonOverallCount != 0
    const notStarted = !item?.progress?.lessonCompletedCount || item?.progress?.lessonCompletedCount == 0

    const getColorBackground = () => {
        if (isDark) {
            if (isCompleted) return '#112123'
            if (notStarted) return '#141414'
            return '#2B1D11'
        } else {
            if (isCompleted) return '#E6FFFB'
            if (notStarted) return '#F0F0F0'
            return '#FFF7E6'
        }
    }

    const getColorText = () => {
        if (isDark) {
            if (isCompleted) return '#58D1C9'
            if (notStarted) return '#F0F0F0'
            return '#F3B765'
        } else {
            if (isCompleted) return '#58D1C9'
            if (notStarted) return '#000000'
            return '#FA8C16'
        }
    }

    return (
        <div style={{ position: 'relative', paddingBottom: '56.2%', borderRadius: '4px 4px 0 0' }}>
            {item.avatarUrl && (
                <img
                    alt="example"
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        borderRadius: '4px 4px 0 0',

                        top: 0,
                        left: 0,
                    }}
                    src={item.avatarUrl}
                    loading="eager"
                />
            )}
            {!item.avatarUrl && (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        borderRadius: '4px 4px 0 0',
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'var(--neutral-3)',
                    }}
                >
                    <DegreeHat
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        theme="outline"
                        size={56}
                        fill={'var(--neutral-7)'}
                    />
                </div>
            )}

            {showProgressBar && (
                <div style={{ position: 'absolute', width: '100%', bottom: 0 }}>
                    <ProgressStep
                        colorText={getColorText()}
                        colorBackground={getColorBackground()}
                        total={item?.progress?.lessonOverallCount}
                        progress={item?.progress?.lessonCompletedCount}
                        kind={item?.kind}
                    />
                </div>
            )}
        </div>
    )
}

export { BoardCardHeader }
