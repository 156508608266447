import React from 'react'
import Paragraph from 'antd/lib/typography/Paragraph'
import { ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import useBreakpoints from '@hooks/useBreakpoints'
import { CheckOne, CloseOne } from '@icon-park/react'
import WelcomeIcon from '@bundle/assets/icons/welcome.svg'
import SVG from '@components/assets/SVG'

type ModalKind = 'success' | 'warning' | 'error' | 'welcome'

namespace InformationModalBody {
    export interface Props {
        kind: ModalKind
        title: string
        description: string
        subdescription?: string
        color?: string
        titleStyle?: React.CSSProperties
        descriptionStyle?: React.CSSProperties
        iconSize?: string
        forceLeftTextAlign?: boolean
    }
}

export const InformationModalBody = (props: InformationModalBody.Props) => {
    const { kind, title, description, subdescription, color, titleStyle, descriptionStyle, iconSize, forceLeftTextAlign } = props

    const breakpoints = useBreakpoints()
    const isMobile = breakpoints.isMobile
    return (
        <div style={{ textAlign: forceLeftTextAlign ? 'unset' : isMobile ? 'center' : 'unset' }}>
            {kind == 'welcome' && <SVG style={{ width: '64px', height: '64px' }} content={WelcomeIcon}></SVG>}
            {kind == 'success' && <CheckOne theme="outline" size={iconSize ?? '56'} fill={color ?? 'var(--modal-icon-success)'} />}
            {kind == 'warning' && <ExclamationCircleOutlined style={{ color: color ?? 'var(--modal-icon-warning)', fontSize: 56, margin: 4 }} />}
            {kind == 'error' && <CloseOne theme="outline" size="56" fill={color ?? 'var(--modal-icon-error)'} />}

            <Paragraph className="turingo-title" style={{ marginTop: 20, marginBottom: 16, ...titleStyle }} strong>
                {title}
            </Paragraph>

            {description && (
                <Paragraph className="turingo-text" style={{ marginBottom: subdescription ? 4 : 16, ...descriptionStyle }}>
                    {description}
                </Paragraph>
            )}
            {subdescription && (
                <Paragraph className="turingo-text" style={{ marginBottom: 16 }}>
                    {subdescription}
                </Paragraph>
            )}
        </div>
    )
}
