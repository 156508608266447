import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface UnpinIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const UnpinIcon = (props: UnpinIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill={props.color ? props.color : 'var(--neutral-13)'}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.00732 12.295L2.00899 12.2957L3.93121 10.3735V10.3711L10.0204 4.28195L10.0212 4.28344L10.8387 3.46594L10.8378 3.46453L12.6224 1.67997C12.634 1.66835 12.6432 1.65455 12.6495 1.63936C12.6558 1.62417 12.6591 1.60789 12.6591 1.59145C12.6591 1.57501 12.6558 1.55872 12.6495 1.54353C12.6432 1.52834 12.634 1.51454 12.6224 1.50292L11.9557 0.835402C11.944 0.823769 11.9302 0.81454 11.9151 0.808244C11.8999 0.801947 11.8836 0.798706 11.8671 0.798706C11.8507 0.798706 11.8344 0.801947 11.8192 0.808244C11.804 0.81454 11.7902 0.823769 11.7786 0.835402C11.2204 1.39343 10.6621 1.95149 10.1039 2.50958L8.87389 1.27953C8.77233 1.17797 8.63951 1.12797 8.5067 1.12797C8.37389 1.12797 8.24108 1.17797 8.13951 1.27953L5.62233 3.79828C5.4317 3.77641 5.23951 3.76703 5.04733 3.76703C3.90358 3.76703 2.75983 4.1436 1.82076 4.89672C1.7639 4.94238 1.71729 4.99951 1.68397 5.06438C1.65066 5.12926 1.63138 5.20042 1.6274 5.27324C1.62342 5.34606 1.63483 5.4189 1.66087 5.48702C1.68692 5.55514 1.72703 5.61701 1.77858 5.6686C2.63963 6.52965 3.50104 7.39034 4.36253 8.25094C3.1218 9.49173 1.88104 10.7325 0.640132 11.9731C0.616686 11.9965 0.603516 12.0283 0.603516 12.0615C0.603516 12.0947 0.616686 12.1265 0.640132 12.15L1.30778 12.8176C1.33125 12.8411 1.36306 12.8543 1.39623 12.8543C1.4294 12.8543 1.46121 12.8411 1.48467 12.8176L2.00732 12.295ZM5.158 7.45544C5.30103 7.3124 5.44407 7.16935 5.5871 7.02631C6.82781 5.78552 8.06853 4.54474 9.30935 3.30405L8.50826 2.50297L6.03639 4.97485L5.49889 4.91547C5.34889 4.89828 5.19733 4.89047 5.04733 4.89047C4.5942 4.89047 4.14889 4.96391 3.72389 5.11078C3.50514 5.18578 3.29576 5.27953 3.0942 5.39047L5.158 7.45544Z"
                    />
                    <path
                        d="M12.7239 5.12953L11.5845 3.99011L10.7899 4.78464L11.5005 5.49516L9.02701 7.9686L9.08639 8.5061C9.1795 9.33745 9.01361 10.1773 8.61139 10.9108L6.63815 8.93642L5.84386 9.73071C6.67539 10.5614 7.50684 11.3922 8.33795 12.2233C8.43951 12.3248 8.57233 12.3748 8.70514 12.3748C8.8567 12.3748 9.0067 12.3092 9.10983 12.1811C9.98951 11.0827 10.3551 9.70766 10.2067 8.37797L12.7239 5.86078C12.9255 5.66078 12.9255 5.33266 12.7239 5.12953Z"
                    />
                </svg>
            )}
            {...props}
        />
    )
}
