import Icon from '@ant-design/icons'
import { CSSProperties } from 'react'

// MARK: - Open/Close Icons

interface StopIcon {
    className?: string
    color?: string
    style?: CSSProperties
}

export const StopIcon = (props: StopIcon) => {
    return (
        <Icon
            component={() => (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill={props.color ? props.color : 'var(--neutral-13)'}>
                    <path d="M4 2C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2H4Z" />
                </svg>
            )}
            {...props}
        />
    )
}
