import { useContext, useState } from 'react'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PushpinOutlined } from '@ant-design/icons'
import strings from '@resources/localization'
import { ReportModal } from '@components/modals/report_modal/ReportModal'
import { DeleteModal } from './DeleteModal'
import { lockScroll, unlockScroll } from '@hooks/useBlockScroll'
import { MoreButton } from '@components/buttons/more_button/MoreButton'
import { PostModal } from '../post_modal/PostCreateProvider'
import { PinModal } from './PinModal'
import { UnpinModal } from './UnpinModal'
import { UnpinIcon } from '@components/icons/UnpinIcon'
import { PostContext } from '../Post'

export const PostMenu = () => {
    const [visible, setVisible] = useState(false)

    const [visibleEdit, setVisibleEdit] = useState(false)
    const [visiblePin, setVisiblePin] = useState(false)
    const [visibleUnpin, setVisibleUnpin] = useState(false)

    const { mask, post, isAdmin } = useContext(PostContext)

    const [visibleReport, setVisibleReport] = useState(false)

    const handleCancel = () => {
        unlockScroll()
        setVisible(false)
        setVisibleReport(false)
        setVisiblePin(false)
        setVisibleUnpin(false)
    }

    const handleReport = () => {
        lockScroll()

        setVisibleReport(true)
    }

    const handleEdit = () => {
        lockScroll()
        setVisibleEdit(true)
    }
    const handlePin = () => {
        lockScroll()
        setVisiblePin(true)
    }
    const handleUnpin = () => {
        lockScroll()
        setVisibleUnpin(true)
    }
    const handleDelete = () => {
        lockScroll()
        setVisible(true)
    }

    if (!post && post?.author) return <></>
    const belongsToUser = post?.author?.publicKey === mask.get?.publicKey
    const menuItems = [
        ...(isAdmin && post.kind != 'rate' && !post?.pin
            ? [
                  {
                      label: strings.screens.markasread.actions.highlight,
                      key: 'post#pin',
                      onClick: handlePin,
                      icon: <PushpinOutlined />,
                  },
              ]
            : []),

        ...(isAdmin && post.kind != 'rate' && post?.pin
            ? [
                  {
                      label: strings.screens.markasread.actions.unhighlight,
                      key: 'post#unpin',
                      onClick: handleUnpin,
                      icon: <UnpinIcon />,
                  },
              ]
            : []),

        ...(belongsToUser
            ? [
                  {
                      label: strings.general.edit,
                      key: 'post#edit',
                      onClick: handleEdit,
                      icon: <EditOutlined />,
                  },
              ]
            : []),
        ...(!belongsToUser
            ? [
                  {
                      label: strings.general.report,
                      key: 'post#report',
                      onClick: handleReport,
                      icon: <ExclamationCircleOutlined />,
                  },
              ]
            : []),
        ...(belongsToUser || isAdmin
            ? [
                  {
                      label: strings.general.delete,
                      key: 'post#delete',
                      onClick: handleDelete,
                      icon: <DeleteOutlined />,
                  },
              ]
            : []),
    ]

    return (
        <div style={{ paddingLeft: 16 }}>
            {visibleEdit && <PostModal defaultMask={mask.get} editable item={post} open={visibleEdit} setVisible={setVisibleEdit} />}
            {visible && <DeleteModal target={post} open={visible} onCancel={handleCancel} />}
            {visiblePin && <PinModal target={post} open={visiblePin} onCancel={handleCancel} />}
            {visibleUnpin && <UnpinModal target={post} open={visibleUnpin} onCancel={handleCancel} />}

            {visibleReport && (
                <ReportModal kind={'post'} reference={{ b_pk: post.boardPk, p_pk: post.publicKey }} open={visibleReport} onCancel={handleCancel} />
            )}

            <MoreButton size="medium" type="text" items={menuItems} />
        </div>
    )
}
