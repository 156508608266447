// Libraries

import { PostView } from '@api/model/responses/post/PostView'
import { AvatarTuringo } from '@components/avatar/Avatar'

// MARK: - Definition

namespace PostCardHeader {
    export interface Props {
        size?: 'large' | 'small'
        item: PostView
    }
}

// MARK: - Implementation

const PostCardHeader: React.FC<PostCardHeader.Props> = (props: PostCardHeader.Props) => {
    const { item, size = 'small' } = props

    const sizeNumber = size == 'large' ? 160 : 80
    const sizeFont = size == 'large' ? 64 : 32

    return (
        <div style={{ position: 'relative', paddingBottom: '56.2%', borderRadius: '4px 4px 0 0' }}>
            {!item.assets?.thumbnailLargeUrl && (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        borderRadius: '4px 4px 0 0',
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'var(--neutral-4)',
                    }}
                >
                    <AvatarTuringo
                        kind={'circle'}
                        name={item.title}
                        avatar={item.assets?.thumbnailSmallUrl}
                        size={sizeFont}
                        width={sizeNumber}
                        height={sizeNumber}
                        style={{ lineHeight: `${sizeNumber}px` }}
                    />
                </div>
            )}
            {item.assets?.thumbnailLargeUrl && (
                <img
                    alt="example"
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        borderRadius: '4px 4px 0 0',

                        top: 0,
                        left: 0,
                    }}
                    src={item.assets?.thumbnailLargeUrl}
                    loading="eager"
                />
            )}
        </div>
    )
}

export { PostCardHeader }
